import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { OptionProps } from 'react-select';
import { FournisseurWithTierViewModel } from "openapi-typescript-codegen";
import cs from 'classnames';

import { fournisseursApi } from 'api';
import { useDebounce } from "hooks";
import SelectComplete, { SelectTemplate } from "components/SelectComplete";
import HighlightSearch from "components/HighlightSearch";

type OptionType = SelectTemplate<string> & { fournisseur: FournisseurWithTierViewModel; };

const SearchBarPresentation: FunctionComponent = () => {
  let { code } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [fournisseurOptions, setFournisseurOptions] = useState<OptionType[]>([]);
  const [searchFournisseur, setSearchFournisseur] = useState<string>("");
  const debouceSearchFournisseur = useDebounce(searchFournisseur, 300);

  useEffect(() => {
    fetchGetFournisseurs(debouceSearchFournisseur);
  }, [debouceSearchFournisseur]);

  const fetchGetFournisseurs = async (search: string) => {
    const { data: { data } } = await fournisseursApi.v1FournisseursSearchGet(search, 1, 20);
    const options = (data || [])
      .filter((item) => !code || item.code !== code)
      .map((item): OptionType => ({
        fournisseur: item,
        value: item.code,
        filterKeys: [
          item.code,
          item.tier?.numTiersExt,
          item.tier?.raisonSociale,
          item.tier?.nomTiers,
          item.tier?.nomCourtTiers,
          item.tier?.siren,
          item.tier?.telTiers,
          item.tier?.emailTiers,
          item.tier?.adresseTiers,
          item.tier?.villeTiers
        ]
      }));

    setFournisseurOptions(options);
  };

  const searchWidth = "calc(100vw - calc(65px + 170px + calc(100vw / 24 * 2)) + 4px)";

  function handleNavigate(code: string) {
    navigate(location.pathname.split('/').slice(0, -2).join('/') + `/${code}`);
  }

  const MyOption = useCallback((props: OptionProps<OptionType>) => {
    const { innerProps, innerRef, isFocused, data: { fournisseur } } = props;
    return (
      <div ref={innerRef} {...innerProps}
        className={cs(
          "px-4 py-2 flex z-full top-8 left-0 bg-white-500 mb-px cursor-pointer",
          "focus:bg-store-primary/25 hover:bg-store-primary-light",
          { "bg-store-primary-light": isFocused }
        )}
        onClick={() => { handleNavigate(fournisseur.code); }}
        style={{
          width: searchWidth,
          minWidth: searchWidth,
          maxWidth: searchWidth,
          height: '42px'
        }}>
        <div className="h-full w-full">
          <div className="grid grid-cols-24 text-left self-center">
            <div className="col-span-3 px-2 text-ellipsis overflow-hidden whitespace-nowrap">
              <HighlightSearch str={fournisseur.code + (fournisseur.tier?.numTiersExt ? ` (${fournisseur.tier?.numTiersExt})` : '')} searchStr={debouceSearchFournisseur} />
            </div>
            {/* <div className="h-full px-2 overflow-hidden">
              <div className="aspect-square">
                <div
                  className="bg-local h-full w-full max-w-full bg-center bg-no-repeat bg-contain"
                  style={{ backgroundImage: `url(${fournisseur?.image ?? defaultImage})` }}
                  ></div>
              </div>
            </div> */}
            <div
              className="col-span-7 px-2 text-ellipsis overflow-hidden whitespace-nowrap"
              title={fournisseur.tier?.raisonSociale || ''}
            >
              <HighlightSearch str={fournisseur.tier?.raisonSociale ?? '...'} searchStr={debouceSearchFournisseur} />
            </div>
            <div
              className="col-span-4 px-2 text-ellipsis overflow-hidden whitespace-nowrap"
              title={fournisseur.tier?.nomTiers || ''}
            >
              <HighlightSearch str={fournisseur.tier?.nomTiers ?? '...'} searchStr={debouceSearchFournisseur} />
            </div>
            <div
              className="col-span-2 px-2 text-ellipsis overflow-hidden whitespace-nowrap"
              title={fournisseur.tier?.siren?.toString() || '...'}
            >
              <HighlightSearch str={fournisseur.tier?.siren?.toString() ?? '...'} searchStr={debouceSearchFournisseur} />
            </div>
            <div
              className="col-span-4 px-2 text-ellipsis overflow-hidden whitespace-nowrap"
              title={fournisseur.tier?.telTiers || '...'}
            >
              <HighlightSearch str={fournisseur.tier?.telTiers ?? '...'} searchStr={debouceSearchFournisseur} />
            </div>
            <div
              className="col-span-4 px-2 text-ellipsis overflow-hidden whitespace-nowrap"
              title={fournisseur.tier?.adresseTiers || '...'}
            >
              <HighlightSearch str={fournisseur.tier?.adresseTiers ?? '...'} searchStr={debouceSearchFournisseur} />
            </div>
          </div>
        </div>
      </div>
    );
  }, [debouceSearchFournisseur]);

  return (
    <div className="col-span-3 self-center h-full bg-white-500 flex items-center">
      <SelectComplete
        tabIndex={3}
        closeMenuOnSelect={true}
        options={fournisseurOptions}
        placeholder={code}
        placeholderStyles={{ color: "#000000" }}
        menuStyles={{
          marginLeft: "-8px",
          marginTop: "2px",
          zIndex: 3,
          overflow: 'hidden',
          backgroundColor: 'rgb(225 226 229 / 1)',
        }}
        className={cs(
          "w-full h-full px-2 bg-transparent text-center cursor-text",
          "focus:border-2 focus:border-store-primary",
          "hover:outline-1"
        )}
        indicator={false}
        components={{ Option: MyOption }}
        onInputChange={(newVal) => setSearchFournisseur(newVal)}
        onChange={(newVal) => newVal && handleNavigate(newVal.fournisseur.code)}
        isMulti={false}
      />
    </div>
  );
};

export default SearchBarPresentation;