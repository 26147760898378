import { FunctionComponent, useEffect, useState, useRef } from 'react';

import SelectComplete, { SelectTemplate } from 'components/SelectComplete';

import { paysApi, nomenclatureDouanieresApi } from 'api';
import { NomenclatureDouaniereViewModel, ArticleNomenclatureDouaniereViewModel } from 'openapi-typescript-codegen';

import { Delete } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { t } from 'i18next';
import ConfirmModal, { ConfirmEnum, ConfirmModalRef } from 'components/Modal/ConfirmModal';

interface Props {
  value: any;
  onChange: (e: any) => void;
  activate: boolean;
  paysSelected: (string | number | null | undefined)[];
}

const NomenclatureLine: FunctionComponent<Props> = ({ value, onChange, activate, paysSelected }) => {
  const [form, setForm] = useState<ArticleNomenclatureDouaniereViewModel>();
  const [nomenclature, setNomenclature] = useState<NomenclatureDouaniereViewModel>();
  const [pays, setPays] = useState<SelectTemplate<number>[]>([]);
  const [hasFocus, setFocus] = useState(false);
  const modalRef = useRef<ConfirmModalRef | null>(null);

  const fetchGetPays = async () => {
    const { data: { data } } = await paysApi.v1PaysGet(1, 90000);

    const tmp: SelectTemplate<number>[] = [
      { value: -1, label: t('TODO_lib_nomenclature_international') },
      ...(data || []).map(
        (item) => ({ value: item.id!, label: item.codePaysAlpha2 + " - " + item.nomPays })
      )
    ];

    setPays(tmp);
  };

  const fetchGetNomenclatureDouaniere = async (id: number) => {
    let { data: response } = await nomenclatureDouanieresApi.v1NomenclatureDouanieresIdGet(id);
    setNomenclature(response);
  };

  useEffect(() => {
    setForm(value);
  }, [value]);

  useEffect(() => {
    if (form?.fkNomenclatureDouaniere) fetchGetNomenclatureDouaniere(form.fkNomenclatureDouaniere);
  }, [form?.fkNomenclatureDouaniere]);

  useEffect(() => {
    fetchGetPays();
  }, []);

  const handleDelete = async () => {
    const validate = await modalRef.current!.showModal();
    if (!validate) {
      return;
    }
    onChange({ ...form, deleteData: true });
  };

  return (
    <div className="w-full flex"
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onMouseOver={() => setFocus(true)}
      onMouseOut={() => setFocus(false)}
    >
      <div className={`grid grid-cols-24 w-full gap-2 relative ${activate && 'pr-4'} transition-all`}>
        <label className="block col-span-3 mb-2 flex-1 pr-6 relative">
          <SelectComplete tabIndex={5}
            isDisabled={!activate}
            options={pays.filter(option => {
              return !paysSelected.includes(option.value);
            })}
            value={pays.filter(option => {
              return option.value === form?.fkPays;
            })}
            onChange={newValue => newValue && onChange({ ...form, fkPays: newValue.value })}
            classNameAdd={form?.fkPays === null ? 'border-black disabled:border-black' : ''}
            isMulti={false}
          />
          <svg className="h-3 mt-4h-3 absolute right-1 top-4"
            viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </label>
        <Tooltip title={"Modification impossible"} followCursor>
          <>
            <label className="block col-span-3 mb-2 flex-1 pr-6 relative">
              <input type="text" tabIndex={5}
                value={nomenclature?.codeNomenclatureSh?.toString()}
                disabled={true}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary store-disabled
                  ${form?.fkPays === null ? 'border-black disabled:border-black' : ''}
                `} />
              <svg className="h-3 absolute right-1 top-4"
                viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </label>
            <label className="block col-span-3 mb-2 flex-1 pr-6 relative">
              <input type="text" tabIndex={5}
                value={nomenclature?.codeNomenclatureCombinee?.toString()}
                disabled={true}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary store-disabled
                  ${form?.fkPays === null ? 'border-black disabled:border-black' : ''}
                `} />
              <svg className="h-3 absolute right-1 top-4"
                viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </label>
            <label className="block col-span-3 mb-2 flex-1 pr-6 relative">
              <input type="text" tabIndex={5}
                value={nomenclature?.codeTarifIntegre?.toString()}
                disabled={true}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary store-disabled
                  ${form?.fkPays === null ? 'border-black disabled:border-black' : ''}
                `} />
              <svg className="h-3 absolute right-1 top-4"
                viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </label>
            <label className="block col-span-3 mb-2 flex-1 pr-6 relative">
              <input type="text" tabIndex={5}
                value={nomenclature?.codeComplement?.toString()}
                disabled={true}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary store-disabled
                  ${form?.fkPays === null ? 'border-black disabled:border-black' : ''}
                `} />
              <svg className="h-3 absolute right-1 top-4"
                viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </label>
            <label className="block col-span-6 mb-2 flex-1 pr-6 relative">
              <textarea
                tabIndex={5}
                value={nomenclature?.nomNomenclature ?? ''}
                disabled={true}
                style={{ minHeight: '2rem' }}
                className={`h-8 mt-1 block w-full px-3 pt-1 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary store-disabled
                  ${form?.fkPays === null ? 'border-black disabled:border-black' : ''}
                `} />
            </label>
            <label className='block h-full col-span-3'>
              <input type="checkbox" tabIndex={5}
                checked={nomenclature?.flCertificatObligatoire ?? false}
                disabled={true}
                className={`h-4 w-4 mt-2.5 block bg-white border border-slate-300 rounded-md
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary store-disabled
                  ${form?.fkPays === null ? 'border-black disabled:border-black' : ''}
                `} />
            </label>
          </>
        </Tooltip>
        {activate &&
          <Tooltip title={"Supprimer"} followCursor>
            <span className="absolute top-0 right-0">
              <button type="button" tabIndex={5}
                disabled={!activate}
                className={`w-8 h-8 mt-1 border rounded border-slate-300 text-grey-200 bg-white-500
                  ${hasFocus ? 'opacity-100' : 'opacity-0 pointer-events-none'}
                  hover:text-white-500 hover:bg-red-500 focus:text-white-500 focus:bg-red-500
                `}
                onClick={() => handleDelete()}>
                <Delete />
              </button>
            </span>
          </Tooltip>
        }
        <ConfirmModal ref={modalRef} layout={ConfirmEnum.delete} />
      </div>
    </div>
  );
};

export default NomenclatureLine;