import { TypeEanViewModel } from "openapi-typescript-codegen";

type EanValidatorReturn = {
  parsedEAN?: string, errorMsg?: string, isRevised?: boolean;
};

export function eanValidator(
  eanToParse: string,
  typeEanId: number,
  eanTypes: TypeEanViewModel[]
): EanValidatorReturn {

  if (!eanToParse || eanToParse.match(/[^0-9]/)) {
    return { errorMsg: `L'EAN ne doit avoir que des chiffres.` };
  }

  const eanType = eanTypes.find(val => val.id === typeEanId);
  if (!eanType) {
    return { errorMsg: `Le type de l'EAN n'est pas reconnu.` };
  }
  if (typeof eanType.longueurMaxi !== 'number' ||
    typeof eanType.longueurMini !== 'number' ||
    typeof eanType.nbZero !== 'number' ||
    typeof eanType.flCtrlCle !== 'boolean'
  ) {
    return { errorMsg: `Manque de règles pour ce type d'EAN.` };
  }
  const ctrl = {
    longueurMini: eanType.longueurMini,
    longueurMaxi: eanType.longueurMaxi,
    nbZero: eanType.nbZero,
    flCtrlCle: eanType.flCtrlCle
  };

  if (ctrl.longueurMini > eanToParse.length || ctrl.longueurMaxi < eanToParse.length) {
    return { errorMsg: `L'EAN doit être compris entre ${ctrl.longueurMini} et ${ctrl.longueurMaxi} pour générer ou vérifier la clé.` };
  }

  if (ctrl.flCtrlCle === false)
    return { parsedEAN: eanToParse };

  const ean = getEanWithChecksumDigit(eanToParse, ctrl.nbZero);
  if (eanToParse !== ean) return { parsedEAN: ean, errorMsg: `Corrigé : ${ean}`, isRevised: true };

  return { parsedEAN: ean };
};

function getEanWithChecksumDigit(ean: string, nbZero: number): string {
  let newEan = ean.slice(0, -1);
  const checksumDigit = generateChecksumDigit(newEan);

  const zeroes = '0'.repeat(nbZero);
  if (nbZero !== 0) {
    newEan = newEan.slice(0, -nbZero) + zeroes;
  }

  const returnedEan = newEan + checksumDigit.toString();
  return returnedEan;
}

/**
 * Checksum digit calculation examples for EAN-8 code:
 * | position          | 7 | 6 | 5 | 4 | 3 | 2 | 1 |
 * | digits of barcode | 2 | 0 | 0 | 6 | 3 | 8 | 1 |
 * | weight            | 3 | 1 | 3 | 1 | 3 | 1 | 3 | // always end with 3
 * | partial sum       | 6 | 0 | 0 | 6 | 9 | 8 | 3 |
 * | checksum          |                         32
 * 40 - 32 = 8
 * Final: 2006 3818
 */
function generateChecksumDigit(partialEan: string): number {
  const multiply = partialEan.length % 2 === 0 ? [1, 3] : [3, 1];

  const digitArr = [...partialEan].map((char) => parseInt(char, 10));
  const checksum = digitArr.reduce((prev, curr, idx) => {
    return prev + (curr * multiply[idx % 2]);
  }, 0);

  return (10 - (checksum % 10)) % 10;
}