import { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

import AppsIcon from 'assets/icons/header/AppsIcon.svg';
import MenuIcon from 'assets/icons/header/MenuIcon.svg';
import ExportIcon from 'assets/icons/header/ExportIcon.svg';
import FolderIcon from 'assets/icons/header/FolderIcon.svg';
import ChatIcon from 'assets/icons/header/ChatIcon.svg';
import BellIcon from 'assets/icons/header/BellIcon.svg';

const Header: FunctionComponent = () => {
  const [network, setNetwork] = useState(0);
  const [currentTime, setCurrentTime] = useState(DateTime.now());

  useEffect(() => {
    setNetwork(Math.floor(Math.random() * 100));
    const inter = setInterval(() => {
      setNetwork(old => Math.min(Math.max(old + ((Math.random() * 2) - 1), 15), 100));
    }, 2000);
    return () => clearInterval(inter);
  }, []);

  useEffect(() => {
    const inter = setInterval(() => setCurrentTime(DateTime.now()), 5e3);
    return () => clearInterval(inter);
  }, []);

  return (
    <div className="header-bar">
      <div className="col-start-2 col-span-8 h-full flex items-center">
        <Link to={'/'}>
          <div className="h-full flex items-center transition-all hover:pl-2">
            <img
              className="h-5"
              src={AppsIcon}
              alt="Store One applications logo" />
            <div className="px-4">Apps Store1</div>
          </div>
        </Link>
      </div>
      <div className="col-end-15 col-span-14 flex flex-row gap-12 justify-end items-center">
        <div className="flex flex-row gap-4">
          <button
            // onClick=""
            className="rounded-lg h-8 w-8 flex justify-center items-center relative border border-transparent hover:border-white-100
            cursor-not-allowed">
            <img src={MenuIcon} alt="" />
            {/* <div className="bg-red-500 rounded-full h-2.5 w-2.5 border-store-primary border-2 absolute top-1 right-1
              animate-bounce"></div> */}
          </button>

          <button
            // onClick=""
            className="rounded-lg h-8 w-8 flex justify-center items-center relative border border-transparent hover:border-white-100
            cursor-not-allowed">
            <img src={ExportIcon} alt="" />
            {/* <div className="bg-red-500 rounded-full h-2.5 w-2.5 border-store-primary border-2 absolute top-1 right-1
              animate-bounce"></div> */}
          </button>

          <button
            // onClick=""
            className="rounded-lg h-8 w-8 flex justify-center items-center relative border border-transparent hover:border-white-100
            cursor-not-allowed">
            <img src={FolderIcon} alt="" />
            {/* <div className="bg-red-500 rounded-full h-2.5 w-2.5 border-store-primary border-2 absolute top-1 right-1
              animate-bounce"></div> */}
          </button>

          <button
            // onClick=""
            className="rounded-lg h-8 w-8 flex justify-center items-center relative border border-transparent hover:border-white-100
            cursor-not-allowed">
            <img src={ChatIcon} alt="" />
            <div className="bg-red-500 rounded-full h-2.5 w-2.5 border-store-primary border-2 absolute top-1 right-1
              animate-bounce"></div>
          </button>

          <button
            // onClick=""
            className="rounded-lg h-8 w-8 flex justify-center items-center relative border border-transparent hover:border-white-100
            cursor-not-allowed">
            <img src={BellIcon} alt="" />
            <div className="bg-red-500 rounded-full h-2.5 w-2.5 border-store-primary border-2 absolute top-1 right-1
              animate-bounce"></div>
          </button>

          <div className="h-max self-center gap-2 flex justify-end items-baseline text-sm font-semibold">
            <span className="-ml-4 w-12 tracking-wide text-end">{Math.ceil(network)}%</span>
            <div className="flex gap-1 items-end" style={{ height: '15px' }}>
              <div className={`rounded w-1 ${network >= 10 ? 'bg-green-500' : 'bg-white-500'}`} style={{ height: "10%" }}></div>
              <div className={`rounded w-1 ${network >= 20 ? 'bg-green-500' : 'bg-white-500'}`} style={{ height: "20%" }}></div>
              <div className={`rounded w-1 ${network >= 30 ? 'bg-green-500' : 'bg-white-500'}`} style={{ height: "30%" }}></div>
              <div className={`rounded w-1 ${network >= 40 ? 'bg-green-500' : 'bg-white-500'}`} style={{ height: "40%" }}></div>
              <div className={`rounded w-1 ${network >= 50 ? 'bg-green-500' : 'bg-white-500'}`} style={{ height: "50%" }}></div>
              <div className={`rounded w-1 ${network >= 60 ? 'bg-green-500' : 'bg-white-500'}`} style={{ height: "60%" }}></div>
              <div className={`rounded w-1 ${network >= 70 ? 'bg-green-500' : 'bg-white-500'}`} style={{ height: "70%" }}></div>
              <div className={`rounded w-1 ${network >= 80 ? 'bg-green-500' : 'bg-white-500'}`} style={{ height: "80%" }}></div>
              <div className={`rounded w-1 ${network >= 90 ? 'bg-green-500' : 'bg-white-500'}`} style={{ height: "90%" }}></div>
              <div className={`rounded w-1 ${network >= 100 ? 'bg-green-500' : 'bg-white-500'}`} style={{ height: "100%" }}></div>
            </div>
          </div>

          <div title={currentTime.zoneName} className="flex justify-end items-center text-sm tracking-wide font-semibold">
            {currentTime.toLocaleString(DateTime.DATETIME_SHORT)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
