import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  ArticleViewModel,
  CodeEtatViewModel,
  ConditionAchatParArticleInsertionModel,
  ConditionAchatParArticleViewModel,
  MarqueViewModel,
  ModeApprovisionnementViewModel,
  PrixAchatViewModelWithDivise,
  RelationFournisseurConditionnementViewModel,
  RemiseViewModel
} from "openapi-typescript-codegen";
import { ColDef, RowValueChangedEvent } from "ag-grid-community";
import { DateTime } from "luxon";

import BlockGrid, { CancelType, LayoutEnum } from 'components/BlockGrid';
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { RowDataType } from "components/AGGride/gridTypes";
import { GridType as DataProps } from "./index";
import { AgGridReact } from "ag-grid-react";
import { useGridController } from "hooks";
import { DateCellRenderer, DeviseCellRenderer, DeviseCellRendererProps, ImageCellRenderer, ImageCellRendererProps, ModalCellRendererChildrenProps, NumberCellRenderer } from "components/AGGride/CellRender";
import { DatePickerCellEditor, NumberCellEditor, NumberCellEditorProps, SelectorCellEditor, SelectorCellEditorProps, AutoCompleteCellEditor, AutoCompleteCellEditorProps } from "components/AGGride/CellEditor";
import { dateCellValidator, notEmptyCellValidator, notNilCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import { articlesApi, circuitLogistiquesApi, codeEtatApi, conditionAchatParArticlesApi, conditionAchatsApi, marquesApi, modeApprovisionnementsApi, relationFournisseurConditionnementApi, remisesApi } from "api";
import { editableIfFuture, editableIfNew } from "components/AGGride/cellEditable";
import { getISOEndDate, getISOStartDate } from "utils";
// import { GridController } from "hooks/useGridController";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
};

type GridType = {
  article?: ArticleViewModel;
  marque?: MarqueViewModel;
  codeEtat?: CodeEtatViewModel;
  relationFournisseurCond?: RelationFournisseurConditionnementViewModel;
  modeAppro?: ModeApprovisionnementViewModel;
  prixAchat?: PrixAchatViewModelWithDivise;
} & Partial<ConditionAchatParArticleViewModel>;

const DetailRemiseProduit: FC<ModalCellRendererChildrenProps<DataProps>> = ({ setIsOpen, agGridRow }) => {
  const { t } = useTranslation();
  const [remise, setRemise] = useState<RemiseViewModel | null>(null);
  // const parentGridController: GridController | undefined = agGridRow.context.gridController;
  const conditionAchatId = agGridRow.data?.id;

  const [typeAccord] = useState<{ value: string | null, label: string; }[]>([
    { value: 'A', label: t('enu_remise_type_accord_arriere') },
    { value: 'C', label: t('enu_remise_type_accord_arriere_conditionne') },
    { value: 'F', label: t('enu_remise_type_accord_sur_facture') },
  ]);
  const [typeSens] = useState<{ value: string | null, label: string; }[]>([
    { value: 'R', label: t('enu_remise_sens_remise') },
    { value: 'M', label: t('enu_remise_sens_majoration') },
  ]);
  const [typeRemise] = useState<{ value: string | null, label: string; }[]>([
    { value: 'V', label: t('enu_type_remise_montant') },
    { value: 'P', label: t('enu_type_remise_pourcentage') },
  ]);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData,
      fetchData: async () => {
        if (!conditionAchatId) return [];
        const { data } = await conditionAchatsApi.v1ConditionAchatsIdConditionAchatParArticlesGet(conditionAchatId);
        return await Promise.all(data.map(populateRowData));
      },
      postData: (cleanRow) => conditionAchatParArticlesApi.v1ConditionAchatParArticlesPost(cleanRow as ConditionAchatParArticleInsertionModel),
      putData: (cleanRow) => conditionAchatParArticlesApi.v1ConditionAchatParArticlesPut(cleanRow as ConditionAchatParArticleViewModel),
      deleteData: (dataId) => conditionAchatParArticlesApi.v1ConditionAchatParArticlesIdDelete(dataId),
      getNewModel: () => ({
        valeurRemise: 0,
        typeRemise: 'M',
        dateDebut: getISOStartDate(),
        dateFin: getISOEndDate(),
        fkConditionAchat: conditionAchatId,
      }),
      columnDefs: [
        {
          field: "article.image",
          headerName: t('lib_article_image'),
          headerTooltip: t('lib_article_image'),
          cellRenderer: ImageCellRenderer,
          cellRendererParams: {
            href: (value) => value,
            defaultHref: "/assets/images/default.png",
          } as ImageCellRendererProps
        },
        {
          field: "fkArticle",
          headerName: t('lib_article_code'),
          headerTooltip: t('lib_article'),
          valueFormatter: (params) => params.data?.article?.code || '',
          editable: editableIfNew,
          cellEditor: AutoCompleteCellEditor,
          cellEditorPopup: true,
          cellEditorParams: {
            searchData: async (search) => {
              const { data: { data } } = await articlesApi.v1ArticlesSearchGet(search, 1, 20);
              return (data || []).map(val => {
                const defaultDesignation = val.designationStandard || val.designationLong || val.designationCourt || val.designationCaisse;
                const filterValues = [
                  val.code, val.codeExterne, val.designationStandard,
                  val.designationLong, val.designationCourt, val.designationCaisse,
                  ...(val.codeEans || []), ...(val.refFours || [])
                ];
                return {
                  value: val.id,
                  prefix: defaultDesignation?.trim(),
                  label: val.code?.trim(),
                  filterKeyArr: filterValues,
                };
              });
            },
            render: ({ children, data }) => (<>
              <span className="opacity-70 text-sm font-bold">{children}</span>
              <span> - </span>
              {data.prefix}
            </>),
          } as AutoCompleteCellEditorProps,
        },
        {
          field: "article.designationStandard",
          headerName: t('lib_article_designation_standard'),
          headerTooltip: t('lib_article_designation_standard'),
        },
        {
          field: "marque.code",
          headerName: t('lib_marque_code'),
          headerTooltip: t('lib_marque_code'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueFormatter: (params) => params.data?.marque ? `${params.data.marque.nomMarque} (${params.data.marque.code})` : '',
        },
        {
          field: "codeEtat.code",
          headerName: t('lib_relation_fournisseur_statut'),
          headerTooltip: t('lib_relation_fournisseur_statut'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueFormatter: (params) => params.data?.codeEtat ? `${params.data.codeEtat.nomCodeEtat} (${params.data.codeEtat.code})` : '',
        },
        {
          field: "relationFournisseurCond.codeRfa",
          headerName: t('lib_relation_fournisseur_conditionnement_code_rfa'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_code_rfa'),
        },
        {
          field: "modeAppro.code",
          headerName: t('lib_flux_circuit'),
          headerTooltip: t('lib_flux_circuit'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueFormatter: (params) => params.data?.modeAppro ? `${params.data.modeAppro?.nomModeApprovisionnement} (${params.data.modeAppro?.code})` : '',
        },
        {
          field: "prixAchat.pxAchat",
          headerName: t('lib_prix_achat_px_achat'),
          headerTooltip: t('lib_prix_achat_px_achat'),
          tooltipValueGetter: (params) => params.value + ' ' + params.data?.prixAchat?.fkDeviseNavigation?.nomDevise,
          cellRenderer: DeviseCellRenderer,
          cellRendererParams: {
            devise: (_, params) => {
              if (!params.data?.prixAchat?.fkDeviseNavigation) return {};
              const { nomCourt, decPxAchat } = params.data.prixAchat.fkDeviseNavigation;
              return { decPxAchat, nomCourt };
            },
          } as DeviseCellRendererProps<GridType>,
        },
        {
          field: "typeRemise",
          headerName: t('lib_condition_achat_par_article_type_remise'),
          headerTooltip: t('lib_condition_achat_par_article_type_remise'),
          valueFormatter: (params) => typeRemise.find(val => val.value === params.data?.typeRemise)?.label || params.data?.typeRemise || '',
          editable: true,
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: typeRemise,
          } as SelectorCellEditorProps,
        },
        {
          field: "valeurRemise",
          headerName: t('lib_condition_achat_par_article_valeur_remise'),
          headerTooltip: t('lib_condition_achat_par_article_valeur_remise'),
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: (_, params) => params.data.typeRemise === 'P' ? 100 : undefined,
          } as NumberCellEditorProps<GridType>,
          valueValidator: [notNilCellValidator, numberCellValidator]
        },
        {
          field: "dateDebut",
          headerName: t('lib_condition_achat_par_article_dtdebut'),
          headerTooltip: t('lib_condition_achat_par_article_dtdebut'),
          editable: editableIfFuture,
          cellRenderer: DateCellRenderer,
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
            min: DateTime.now(),
          },
          valueValidator: [notEmptyCellValidator, dateCellValidator],
        },
        {
          field: "dateFin",
          headerName: t('lib_condition_achat_par_article_dtfin'),
          headerTooltip: t('lib_condition_achat_par_article_dtfin'),
          editable: (params) => editableIfNew(params) || params.data?.typeRemise === 'N',
          cellRenderer: DateCellRenderer,
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
            min: DateTime.now(),
          },
          valueValidator: [notEmptyCellValidator, dateCellValidator],
        },
      ],
    }), [t])
  );

  useEffect(() => {
    fetchRemise();
  }, []);

  async function fetchRemise() {
    if (!conditionAchatId) return;
    const { data: condAchat } = await conditionAchatsApi.v1ConditionAchatsIdGet(conditionAchatId);
    if (!condAchat.fkRemise) return;
    const { data: newRemise } = await remisesApi.v1RemisesIdGet(condAchat.fkRemise);
    setRemise(newRemise);
  }

  async function populateRowData(data: RowDataType<GridType>) {
    const newData = { ...data };
    if (data.fkArticle && data.fkArticle !== data.article?.id) {
      newData.article = undefined;
      newData.marque = undefined;
      newData.prixAchat = undefined;
      newData.relationFournisseurCond = undefined;
      newData.modeAppro = undefined;

      const { data: article } = await articlesApi.v1ArticlesIdGet(data.fkArticle);
      newData.article = article;

      if (article?.fkMarque) {
        try {
          const { data: marque } = await marquesApi.v1MarquesIdGet(article.fkMarque);
          newData.marque = marque;
        } catch (err) { }
      }
      if (article?.fkCodeEtat) {
        try {
          const { data: codeEtat } = await codeEtatApi.v1CodeEtatsIdGet(article.fkCodeEtat);
          newData.codeEtat = codeEtat;
        } catch (err) { }
      }

      const { data: relationFournisseurCond } = await articlesApi.v1ArticlesIdRelationFournisseurConditionnementsLowestPriorityGet(data.fkArticle);
      if (relationFournisseurCond?.fkCircuitLogistique) {
        try {
          const { data: prixAchat } = await relationFournisseurConditionnementApi.v1RelationFournisseurConditionnementsIdPrixAchatGet(relationFournisseurCond.id!);
          newData.prixAchat = prixAchat;
        } catch (err) { }
        try {
          const { data: fluxFour } = await circuitLogistiquesApi.v1CircuitLogistiquesIdFluxFournisseursDefaultGet(relationFournisseurCond.fkCircuitLogistique);
          const { data: modeAppro } = await modeApprovisionnementsApi.v1ModeApprovisionnementsIdGet(fluxFour.fkModeApprovisionnement!);
          newData.relationFournisseurCond = relationFournisseurCond;
          newData.modeAppro = modeAppro;
        } catch (err) { }
      }
    }
    return newData;
  }

  async function onRowValueChanged(event: RowValueChangedEvent<RowDataType<GridType>>) {
    await gridController.onRowValueChanged(event);

    if (event.data) {
      const rowData = await populateRowData(event.data);
      Object.entries(rowData).forEach(([key, val]) => (event.data as any)[key] = val);
      event.api.refreshCells({ rowNodes: [event.node!], force: true });
    }
  }

  async function handleCancel(type: CancelType) {
    if (type === 'btn') {
      await gridController.handleCancel();
      setTimeout(gridController.activateContextActionButtons, 50);
    } else {
      setIsOpen(false);
    }
  }

  return (
    <BlockGrid
      title={t('tit_conditions_achats') + ' - ' + t('enu_condition_achat_application_produit')}
      layout={LayoutEnum.modal}
      handleClick={() => {
        setTimeout(gridController.activateContextActionButtons, 50);
      }}
      toActivate={true}
      loading={gridController.isLoading}
      disableCreate={gridController.hasEmptyLines}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={handleCancel}
    >
      <div className="grid grid-cols-4 gap-4 mb-3">
        <div className="flex flex-col">
          <label className="text-grey-500 font-bold">{t('lib_condition_achat_fk_remise')}</label>
          <div>{remise?.code || '...'}</div>
        </div>
        <div className="flex flex-col">
          <label className="text-grey-500 font-bold">{t('lib_remise_nom_remise')}</label>
          <div>{remise?.nomRemise}</div>
        </div>
        <div>
          <label className="text-grey-500 font-bold">{t('lib_remise_type_accord')}</label>
          <div>{typeAccord.find(val => val.value === remise?.typeAccord)?.label}</div>
        </div>
        <div>
          <label className="text-grey-500 font-bold">{t('lib_remise_sens')}</label>
          <div>{typeSens.find(val => val.value === remise?.sens)?.label}</div>
        </div>
      </div>
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-modal mt-3">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={(ev) => {
            gridController.onFirstDataRendered(ev);
            setTimeout(gridController.activateContextActionButtons, 50);
          }}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default DetailRemiseProduit;