import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  ArticleTraitementChimiqueInsertionModel,
  ArticleTraitementChimiqueViewModel,
  TraitementChimiqueViewModel
} from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { useTranslation } from "react-i18next";

import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { articlesApi, articleTraitementChimiquesApi, traitementChimiquesApi } from 'api';
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { SelectorCellEditor } from 'components/AGGride/CellEditor';
import { LinkCellRenderer, LinkCellRendererProps } from 'components/AGGride/CellRender';
import { editableIfNew } from "components/AGGride/cellEditable";
import { notEmptyCellValidator } from "components/AGGride/cellValidator";
import { useArticle } from "context/Referencement";
import { useGridController } from "hooks";

import 'app/ag-grid-style.css';

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 90,
};

type GridType = Partial<ArticleTraitementChimiqueViewModel>;

const TraitementsChimiques: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const [activate, setActivate] = useState(false);
  const [articleTraitementsOptions, setArticleTraitementsOptions] = useState<TraitementChimiqueViewModel[]>([]);

  const traitementsOptions = useRef<TraitementChimiqueViewModel[]>([]);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {
        sortColId: "fkTraitementChimique",
        startEditingColId: "fkTraitementChimique",
      },
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.fkTraitementChimique,
      fetchData: async () => {
        if (!article?.id) return [];
        const data = await articlesApi.v1ArticlesIdTraitementChimiquesGet(article?.id).then(response => response.data);
        const options = traitementsOptions.current
          .filter(option => !data.some(traitement => traitement.fkTraitementChimique === option.id))
          .map(option => ({ ...option, label: `${option.nomTraitementChimique} (${option.code})`.toUpperCase() })) || [];
        setArticleTraitementsOptions(options);
        return data;
      },
      postData: (cleanRow) => articleTraitementChimiquesApi.v1ArticleTraitementChimiquesPost({ ...cleanRow, fkArticle: article?.id as number } as ArticleTraitementChimiqueInsertionModel),
      putData: (cleanRow) => articleTraitementChimiquesApi.v1ArticleTraitementChimiquesPut(cleanRow as ArticleTraitementChimiqueViewModel),
      deleteData: (dataId) => articleTraitementChimiquesApi.v1ArticleTraitementChimiquesIdDelete(dataId),
      getNewModel: () => ({
        fkTraitementChimique: articleTraitementsOptions.find(v => v.code === 'LOT')?.id || articleTraitementsOptions[0].id,
      }),
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'fkTraitementChimique',
          headerName: t('lib_traitement_chimique_code'),
          headerTooltip: t('lib_traitement_chimique_code'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          editable: editableIfNew,
          tooltipValueGetter: (params) => traitementsOptions.current.find(
            option => typeof option.id === 'number' && option.id === params.data?.fkTraitementChimique)?.code,
          valueFormatter: (params) => traitementsOptions.current.find(
            option => typeof option.id === 'number' && option.id === params.data?.fkTraitementChimique)?.code || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: articleTraitementsOptions.map(option => ({
              value: option.id, label: option.nomTraitementChimique ? option.code + ' - ' + option.nomTraitementChimique : option.code
            })),
          },
          valueValidator: [notEmptyCellValidator],
        },
        {
          field: 'ConstituantName',
          headerName: t('lib_article_traitement_chimique_nom_traitement_chimique'),
          headerTooltip: t('lib_article_traitement_chimique_nom_traitement_chimique'),
          cellRenderer: LinkCellRenderer,
          cellRendererParams: {
            href: (cellValue: string) => /^\d+/gmi.test(cellValue) ? `/app/referencement/articles/${cellValue}` : null,
            target: '_blank'
          } as LinkCellRendererProps,
          valueGetter: (param: ValueGetterParams) => param.data.fkTraitementChimique,
          valueFormatter: (params) => traitementsOptions.current.find(
            option => typeof option.id === 'number' && option.id === params.value)?.nomTraitementChimique || "",
        },
      ],
    }), [activate, articleTraitementsOptions, article, t])
  );

  useEffect(() => {
    loadOptions();
  }, []);

  async function loadOptions() {
    const { data: response } = await traitementChimiquesApi.v1TraitementChimiquesGet(1, 100);
    const { data } = response;
    traitementsOptions.current = data || [];
  }

  return (
    <BlockGrid
      title={t('tab_article_traitement_chimique')} sm={sm} md={md} lg={lg} xl={xl}
      doubleXl={doubleXl}
      loading={gridController.isLoading}
      handleClick={(res: boolean) => {
        setActivate(res);
        setTimeout(gridController.activateContextActionButtons, 50);
      }}
      toActivate={activate}
      disableCreate={gridController.hasEmptyLines || !articleTraitementsOptions.length}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default TraitementsChimiques;