import { FunctionComponent, useState, ChangeEvent } from 'react';
import DataTable from "react-data-table-component";

import toast from 'react-hot-toast';

import { Tooltip } from '@mui/material';
import { Save, Delete } from '@mui/icons-material';

interface Props {
}

const DataTableParams: FunctionComponent<Props> = () => {
  const [data, setData] = useState<any>([]);
  const [loading, _setLoading] = useState<boolean>(false);

  const columns = [
    {
      name: "ID",
      selector: (row: any) => row.id,
      sortable: true,
      cell: (row: any) => (
        <div className="w-8">{row.id}</div>
      )
    },
    {
      name: "Nom",
      selector: (row: any) => row.nomContact,
      sortable: true,
      cell: (row: any) => (
        <input type="text" className="w-full h-8 p-1 rounded border border-grey-150"
          value={row.nomContact}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            let newData = [...data];
            newData[data.indexOf(row)].nomContact = e.target.value;
            setData(newData);
          }}
        />
      )
    },
    {
      name: "Prenom",
      selector: (row: any) => row.prenomContact,
      sortable: true,
      cell: (row: any) => (
        <input type="text" className="w-full h-8 p-1 rounded border border-grey-150"
          value={row.prenomContact}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            let newData = [...data];
            newData[data.indexOf(row)].prenomContact = e.target.value;
            setData(newData);
          }}
        />
      )
    },
    {
      name: "Fonction",
      selector: (row: any) => row.fonction,
      sortable: true,
      cell: (row: any) => (
        <input type="text" className="w-full h-8 p-1 rounded border border-grey-150"
          value={row.fonction}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            let newData = [...data];
            newData[data.indexOf(row)].fonction = e.target.value;
            setData(newData);
          }}
        />
      )
    },
    {
      button: true,
      cell: (row: any) => (
        <div className="fill-store-primary w-full h-8 flex justify-center gap-2">
          {loading ?
            <Tooltip title="Chargement" followCursor>
              <svg className="h-11" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 100 100" enableBackground="new 0 0 0 0">
                <circle stroke="none" cx="6" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.1" />
                </circle>
                <circle stroke="none" cx="26" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.2" />
                </circle>
                <circle stroke="none" cx="46" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.3" />
                </circle>
              </svg>
            </Tooltip>
            :
            <>
              <Tooltip title="Sauvegarder" followCursor>
                <button
                  type="button"
                  className="bg-store-primary text-white-500 rounded w-9"
                  onClick={(_e) => handleSave(row.id)}
                >
                  <Save />
                </button>
              </Tooltip>
              <Tooltip title="Supprimer" followCursor>
                <button
                  type="button"
                  className="bg-red-500 text-white-500 rounded w-9"
                  onClick={
                    (_e) => globalThis.confirm("Etes-vous sur?") ? handleDelete(row.id) : toast("Action annulée")
                  }
                >
                  <Delete />
                </button>
              </Tooltip>
            </>
          }
        </div>
      )
    }
  ];

  function handleSave(_id: string | number) {
    toast.promise(new Promise((res) => setTimeout(res, 2000)), {
      loading: 'Chargement',
      success: 'Sauvegarde complète',
      error: (err) => `Erreur: ${err.toString()}`,
    });
  }

  function handleDelete(_id: string | number) {
    toast.promise(new Promise((res) => setTimeout(res, 2000)), {
      loading: 'Chargement',
      success: 'Supprimer avec succès',
      error: (err) => `Erreur: ${err.toString()}`,
    });
  }

  return (
    <DataTable
      columns={columns}
      data={data}
    />
  );
};

export default DataTableParams;