import { FunctionComponent, useEffect, useState } from 'react';
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { useTranslation } from "react-i18next";
import { circuitLogistiquesApi, temperatureFournisseursApi, temperaturesApi } from 'api';
import SelectComplete, { SelectTemplate } from 'components/SelectComplete';
import { Delete } from "@mui/icons-material";
import toast from 'react-hot-toast';
import { UpdateEnum, useUpdateFournisseur } from 'context/Referencement/updateFournisseur';
import { getColumnControl } from 'validators/ColumnControl/Controls';

const controls = [
  "temperature_fournisseur.fk_temperature"
].map(getColumnControl);

interface Props extends PropsBlock {
  identifier: number;
}

const Temperature: FunctionComponent<Props> = ({ sm, md, lg, xl, doubleXl, identifier }) => {
  const { t } = useTranslation();
  const [temperatureTypes, setTemperatureTypes] = useState<SelectTemplate<number>[]>([]);
  const [temperatureTypeWaiting, setTemperatureTypeWaiting] = useState<SelectTemplate<number>>();
  const [listTemperature, setListTemperature] = useState<({ id?: number; } & SelectTemplate<number>)[]>([]);
  const [selectOptions, setSelectOptions] = useState<SelectTemplate<number>[]>([]);
  const [activate, setActivate] = useState<boolean>(false);
  const [loading, setLoader] = useState<boolean>(true);
  const [completude, setCompletude] = useState<number>(0);
  const uF = useUpdateFournisseur();

  const getAllTemperatureTypes = async () => {
    if (!temperatureTypes.length) {
      const { data: response } = await temperaturesApi.v1TemperaturesGet(1, 10000);
      let types: SelectTemplate<number>[] = [];
      if (response.data && response.data.length) {
        types = [...response.data.map(item => ({ label: `${item.code} - ${item.nomTemperature}`, value: item.id }))];
      }
      setTemperatureTypes(types);
      return types;
    }
    return temperatureTypes;
  };

  const fetchGetTemperatures = async (id: number) => {
    const { data: response } = await circuitLogistiquesApi.v1CircuitLogistiquesIdTemperatureFournisseursGet(id);
    // Types are fetch again only if not already fetched:
    const types = temperatureTypes.length ? temperatureTypes : await getAllTemperatureTypes();
    let temperatures: ({ id: number; } & SelectTemplate<number>)[] = [];
    if (response && response.length > 0) {
      temperatures = [
        ...response.map(
          item => {
            const temperature = types.find((type) => type.value === item.fkTemperature)!;
            return ({ label: temperature.label, value: temperature.value, isDisabled: false, id: item.id });
          })
      ];
    }
    setListTemperature(temperatures);
    setLoader(false);
  };

  const fetchUpdateData = async () => {
    try {
      const dataToSent = listTemperature.map(async (item) => {
        if (item.isDisabled && item.value && item.id) {
          await temperatureFournisseursApi.v1TemperatureFournisseursIdDelete(item.id);
        } else if (!item.id) {
          await temperatureFournisseursApi.v1TemperatureFournisseursPost({
            fkCircuitLogistique: identifier,
            fkTemperature: item.value,
          });
        }
      });
      await Promise.all(dataToSent);
      toast.success(t('mes_validation_modification'));
      uF.triggerEvent(UpdateEnum.circuitLogistique, identifier);
      await fetchGetTemperatures(identifier);
      setActivate(false);
    } catch (error: any) {
      toast.error(t('mes_erreur_technique') + ": " + (error?.response?.data?.message || error));
    }
  };

  useEffect(() => {
    getAllTemperatureTypes();
  }, []);

  useEffect(() => {
    setSelectOptions(listTemperature ? temperatureTypes.filter((type) =>
      listTemperature.every((temperature) => temperature.value !== type.value || temperature.isDisabled)
    ) : temperatureTypes);
    setCompletude(
      controls?.find((i) => i.column === 'temperature_fournisseur.fk_temperature')?.required && !listTemperature.length ? 1 : 0
    );
  }, [listTemperature, temperatureTypes]);

  useEffect(() => {
    fetchGetTemperatures(identifier);
  }, [identifier]);

  function handleUpdate() {
    fetchUpdateData();
  }

  function handleCancel() {
    fetchGetTemperatures(identifier);
    setTemperatureTypeWaiting(undefined);
    setActivate(false);
  }

  return (
    <BlockGrid
      title={t('tab_temperature')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      toActivate={activate}
      handleClick={(activation: boolean) => { identifier && setActivate(activation); }}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      completude={completude}
      loading={loading}
    >
      <>
        <div
          className={`w-full flex gap-2 transition-all`}
          style={{ height: !activate && listTemperature.length > 0 ? '0px' : '40px', overflow: !activate && listTemperature.length > 0 ? 'hidden' : '' }}
        >
          <label className="block col-span-3 mb-2 flex-1 pr-6 relative">
            <SelectComplete tabIndex={3}
              isDisabled={false}
              options={selectOptions}
              value={temperatureTypes.filter(type => type.value === temperatureTypeWaiting?.value)}
              onChange={(element: any): void => setTemperatureTypeWaiting(element)}
              placeholder={t('tab_recherche')}
            />
          </label>
          <button type="button" tabIndex={5}
            disabled={false}
            className={`h-8 py-0.5 px-2 rounded bg-white-500 border border-store-primary text-store-primary text-xs whitespace-nowrap
            hover:bg-store-primary mt-1 hover:text-white-500 focus:bg-store-primary focus:text-white-500`}
            onClick={() => {
              if (temperatureTypeWaiting?.value) {
                const element = listTemperature.find(temperature => temperature.value === temperatureTypeWaiting.value);
                // If the element is not already in the list, it is added to it:
                if (!element) {
                  setListTemperature(old => ([...old, { ...temperatureTypeWaiting }]));
                }
                // Else, in the case the deletion has not been persisted to the database, we use an existing same element and change the isDisabled property:
                else {
                  setListTemperature(old => old.map(temperature => {
                    if (temperature.value === element.value) { temperature.isDisabled = false; }
                    return temperature;
                  }));
                }
                setTemperatureTypeWaiting({ value: -1 });
              }
            }}>Ajouter +</button>
        </div>
        <div className="max-h-[112px] overflow-auto">
          {
            listTemperature
              .filter((item) => { return !item.isDisabled; })
              .map((item, index) =>
                <TemperatureItem item={item} activate={activate} onChange={element => {
                  // If the new element has not been inserted into the database, it's directly removed from the list:
                  let temperatures;
                  if (!element.id) {
                    temperatures = listTemperature.filter(temperature => temperature.value !== element.value);
                  }
                  // Else, the "isDisabled" property is set to true:
                  else {
                    temperatures = listTemperature.map(temperature => {
                      if (temperature.value === element.value) { temperature.isDisabled = true; }
                      return temperature;
                    });
                  }
                  setListTemperature([...temperatures]);
                }} key={index} />
              )
          }
        </div>
      </>
    </BlockGrid>
  );
};

interface PropsList {
  item: SelectTemplate<number>,
  onChange: (e: any) => void,
  activate: boolean;
}

const TemperatureItem: FunctionComponent<PropsList> = ({ item, activate, onChange }) => {
  const [hasFocus, setFocus] = useState(false);
  return (
    <div className="w-full flex"
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onMouseOver={() => setFocus(true)}
      onMouseOut={() => setFocus(false)}
    >
      <div className={`flex w-full gap-2 relative ${activate && 'pr-12'} transition-all`}>
        <input type="text" tabIndex={5}
          value={item.label}
          disabled={true}
          className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
          disabled:border-slate-200 disabled:bg-white-500
          focus:border-store-primary `}
        />
        {activate &&
          <span className="absolute top-0 right-0">
            <button type="button" tabIndex={5}
              disabled={!activate}
              className={`w-8 h-8 mt-1 border rounded border-slate-300 text-grey-200 bg-white-500
                ${hasFocus ? 'opacity-100' : 'opacity-0 pointer-events-none'}
              hover:text-white-500 hover:bg-red-500 focus:text-white-500 focus:bg-red-500
              `}
              onClick={() => onChange(item)}>
              <Delete />
            </button>
          </span>
        }
      </div>
    </div>
  );
};

export default Temperature;