import qs from "qs";
import axios from "axios";
import { encode as base64encode } from "base64-arraybuffer";
import { env } from "utils";

export namespace OAuth {
  export type Config = {
    clientId: string;
    tokenEndpoint: string;
    authorizeEndpoint: string;
    logoutEndpoint: string;
    redirectUri: string;
    codePrefix: string;
    statePrefix: string;
    nextPrefix: string;
    scope: string;
  };

  export type Credentials = {
    scope: string;
    id_token: string;
    expires_in: number;
    token_type: string;
    access_token: string;
    refresh_token?: string;
    instancied_date: number;
  };

  export class Client {
    private _config: Config;

    constructor(config: any) {
      this._config = config;
    }

    async initLogin(nextPath?: string) {
      // 1- Generate a random clearCode and random state
      const clearCode = this._getRandomString(128);
      const state = this._getRandomString(32);

      // 2 - Generate challengeCode
      const codeChallenge = await this._computeSHA256Base64(clearCode);

      // 3 - save clearCode && state
      localStorage.setItem(this._config.codePrefix, clearCode);
      localStorage.setItem(this._config.statePrefix, state);
      if (nextPath)
        localStorage.setItem(this._config.nextPrefix, nextPath);

      // 4 - construct the authentication url
      const authURL = `${this._config.authorizeEndpoint}?${qs.stringify({
        response_type: "code",
        client_id: this._config.clientId,
        redirect_uri: this._config.redirectUri,
        access_type: "offline",
        scope: this._config.scope,
        state: state,
        code_challenge: codeChallenge,
        code_challenge_method: "S256",
      })}`;

      // 5 - open the authentication page
      return authURL;
    }

    async oauthRedirect(code: string, state: string) {
      const storedState = localStorage.getItem(this._config.statePrefix);

      if (storedState !== state)
        throw Error("Probable session hijacking attack!");

      const storedCode = localStorage.getItem(this._config.codePrefix);

      const postBody = {
        client_id: this._config.clientId,
        grant_type: "authorization_code",
        code: code,
        redirect_uri: this._config.redirectUri,
        code_verifier: storedCode,
      };

      return axios
        .post(this._config.tokenEndpoint, qs.stringify(postBody), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;",
          },
        })
        .then((res) => {
          // TODO: not store token in localStorage, use cookie with httpOnly + csrf-token
          const creds = res.data as Credentials;
          creds.instancied_date = new Date().getTime();
          const nextPath = localStorage.getItem(this._config.nextPrefix);

          return { credentials: creds, nextPath };
        });
    }

    private _getRandomString(
      length: number,
      wishlist = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    ) {
      return Array.from(crypto.getRandomValues(new Uint8Array(length)))
        .map((x) => wishlist[x % wishlist.length])
        .join("");
    }

    private async _computeSHA256Base64(clearCode: string) {
      const encoder = new TextEncoder();
      const data = encoder.encode(clearCode);
      const digest = await globalThis.crypto.subtle.digest("SHA-256", data);
      const toBase64 = base64encode(digest);
      return toBase64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
    }
  }
}

const devConfig = {
  clientId: "local_front",
  tokenEndpoint: "https://hydra-store-one.cote21.fr/core/oauth2/token",
  authorizeEndpoint: "https://hydra-store-one.cote21.fr/core/oauth2/auth",
  logoutEndpoint:
    "https://hydra-store-one.cote21.fr/core/oauth2/sessions/logout",
  redirectUri: "http://localhost:3000/callback",
  codePrefix: "oauth-code",
  storePrefix: "oauth-state",
  nextPrefix: "oauth-next",
  scope: "openid offline",
};

const remoteConfig = {
  clientId: "remote_front",
  tokenEndpoint: "https://hydra-store-one.cote21.fr/core/oauth2/token",
  authorizeEndpoint: "https://hydra-store-one.cote21.fr/core/oauth2/auth",
  logoutEndpoint:
    "https://hydra-store-one.cote21.fr/core/oauth2/sessions/logout",
  redirectUri: "https://front-store-one.rubrash.website/callback",
  codePrefix: "oauth-code",
  storePrefix: "oauth-state",
  nextPrefix: "oauth-next",
  scope: "openid offline",
};

const OAuthWrapper = new OAuth.Client(
  env.USE_DEV_OPENID_CONFIG ? devConfig : remoteConfig
);

export default OAuthWrapper;
