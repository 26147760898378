import { authApi } from "api";
import { useState } from "react";
import { getPrettyError } from "utils";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const loginBtnOnClick = async () => {
    setErrorMessage("");
    try {
      const { data } = await authApi.v1AuthLoginPost({
        username: username,
        password: password,
      })
      globalThis.localStorage.setItem('csrf', data.csrf);
      globalThis.location.replace('/');
    } catch(error: any) {
      const message = getPrettyError(error);
      setErrorMessage(message);
    }
  };

  return (
    <div className="relative h-full flex bg-[#f5f6fd] font-poppins">
      <div className="w-1/2 flex flex-col text-white bg-[linear-gradient(201.98deg,#3949ab_0%,#7272db_100.7%)]">
        <div className="flex items-center flex-wrap p-12 pb-0 text-xl font-semibold">
          <img className="w-12 mr-3" src="/assets/images/login-logo.webp" alt="Retail Services logo" />
          <h3>Retail Services</h3>
        </div>

        <h2 className="px-12 mb-2 text-4xl font-semibold">
          Managez votre retail de la production au rayon
        </h2>

        <div className="flex-1 bg-top bg-no-repeat bg-cover" style={{ backgroundImage: `url(/assets/images/login-bg.webp)` }}></div>
      </div>

      <div className="w-1/2 flex items-center justify-center">
        <div className="w-full max-w-lg flex flex-col px-4">

          <h1 className="mt-6 mb-12 text-[#2b3267] text-3xl font-medium">Se connecter</h1>

          <form>
            <div className="flex flex-col mt-2 mb-6">
              <label className="mb-2 text-[#2b3267] text-lg font-medium">Nom d’utilisateur*</label>
              <input
                className="bg-white border border-neutral-300 rounded-md px-3 py-1 placeholder:text-[#9ca3af]"
                name="identifier"
                type="text"
                onChange={event => setUsername(event.target.value)}
                placeholder="Nom d’utilisateur" />
            </div>

            <div className="flex flex-col mb-2">
              <label className="mb-2 text-[#2b3267] text-lg font-medium">Mot de passe*</label>
              <input
                className="bg-white border border-neutral-300 rounded-md px-3 py-1 placeholder:text-[#9ca3af]"
                name="password"
                type="password"
                onChange={event => setPassword(event.target.value)}
                placeholder="Mot de passe" />
            </div>

            <div className="mb-6 ml-1 text-[#7981b6] font-medium cursor-not-allowed">
              <input className="cursor-not-allowed" type="checkbox" disabled /> Se souvenir de moi
            </div>

            {!!errorMessage &&
              <div className="mb-3 text-red-500">{errorMessage}.</div>
            }

            <div className="flex justify-center">
              <button className="px-8 py-3 bg-[#7272db] rounded-md text-white text-center font-medium cursor-pointer" onClick={loginBtnOnClick} type="button">
                Connexion
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
