import { DetailedHTMLProps, Dispatch, FC, HTMLInputTypeAttribute, InputHTMLAttributes, ReactNode, SetStateAction, useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";

import SelectComplete, { SelectTemplate } from "components/SelectComplete";
import { fixFloating, getPrettyError } from "utils";

/* Common Input */

type DateInputProps<T> = {
  min?: DateTime;
  max?: DateTime;
} & BasicInputProps<T>;
export const DateInput = <T,>({ value, field, setValue, onChange: onChangeProps, min, max, ...props }: DateInputProps<T>) => {
  const rawValue = field ? value[field] : value;
  const finalValue = rawValue ? DateTime.fromISO(String(rawValue)).toISODate() || '' : '';
  const parseMin = (DateTime.isDateTime(min)) ? min.toISODate() : min;
  const parseMax = (DateTime.isDateTime(max)) ? max.toISODate() : max;

  function onChange(newValue: string) {
    if (field && setValue) {
      const newParseValue = DateTime.fromISO(newValue, { zone: 'utc' }).toISO({ suppressMilliseconds: true })?.replace('Z', '+00:00');
      setValue((old) => ({ ...old, [field]: newParseValue as any }));
    }
    onChangeProps?.(newValue);
  }

  return (<BasicInput {...props} type="date" value={finalValue} onChange={onChange} extraInputProps={{ min: parseMin, max: parseMax }} />);
};

type NumberInputProps<T> = {
  min?: number;
  max?: number;
  floating?: number;
} & BasicInputProps<T>;
export const NumberInput = <T,>({ value, field, setValue, onChange: onChangeProps, min, max, floating, ...props }: NumberInputProps<T>) => {
  const rawValue = field ? value[field] : value;
  const finalValue = parseFloat(rawValue as any) || 0;

  function onChange(newValue: string) {
    if (field && setValue) {
      let newParseValue = parseFloat(newValue) || 0;
      if (typeof min === 'number') newParseValue = Math.max(newParseValue, min);
      if (typeof max === 'number') newParseValue = Math.min(newParseValue, max);
      if (typeof floating === 'number') {
        newParseValue = fixFloating(newParseValue, floating);
      }
      setValue((old) => ({ ...old, [field]: newParseValue as any }));
    }
    onChangeProps?.(newValue);
  }

  return (<BasicInput {...props} type="number" value={finalValue} onChange={onChange} extraInputProps={{ min, max }} />);
};

type TextInputProps<T> = {} & BasicInputProps<T>;
export const TextInput = <T,>({ ...props }: TextInputProps<T>) => {
  return (<BasicInput {...props} />);
};

/* Base Input */

type BasicInputProps<T> = {
  value: T,
  field?: keyof T;
  setValue?: Dispatch<SetStateAction<T>>;
  onChange?: (newValue: string) => void;
  type?: HTMLInputTypeAttribute;
  label?: string,
  recommended?: boolean;
  required?: boolean;
  disabled?: boolean;
  labelClassAdd?: string;
  labelTextColor?: string
  inputClass?: string;
  inputClassAdd?: string;
  extraInputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
};
const BasicInput = <T,>({ value, field, setValue, onChange, type = 'text', label, recommended, required, disabled, labelClassAdd, labelTextColor, inputClass, inputClassAdd, extraInputProps }: BasicInputProps<T>) => {
  const rawValue = field ? value[field] : typeof value === "number" && required && !value ? "" : value;
  const finalValue = rawValue != null ? String(rawValue) : '';

  const initializeInput = () => (
    <input
      {...extraInputProps}
      title={finalValue}
      type={type}
      value={finalValue}
      onChange={(e) => {
        const newValue = e.currentTarget.value;
        if (field && setValue)
          setValue((old) => ({ ...old, [field]: newValue as any }));
        else if (setValue)
          setValue(newValue as any);
        onChange?.(newValue);
      }}
      disabled={disabled}
      className={inputClass || `h-8 mt-1 block w-full px-3 py-2 bg-white-500 border border-slate-300 rounded-md text-sm placeholder-slate-400
            disabled:text-gray-600 disabled:border-slate-200 disabled:bg-white-100 focus:border-store-primary
            ${required && !finalValue.length ? 'border-red-500 bg-red-500/10' : ''}
            ${recommended && !finalValue.length ? 'border-orange-500 bg-orange-500/10' : ''}
            ${inputClassAdd || ''}
      `} />
  );

  return (label ?
    <LabelInputWrapper label={label as string} recommended={recommended} required={required} classNameAdd={labelClassAdd} textColor={labelTextColor}>
      {initializeInput()}
    </LabelInputWrapper> : initializeInput());
};

/* File Input */

type FileInputProps<T> = { accept?: string; } & Omit<BasicInputProps<T>, 'type' | 'extraInputProps'>;
export const FileInput = <T,>({ value, field, setValue, onChange, label, recommended, required, disabled, labelClassAdd, accept }: FileInputProps<T>) => {
  const rawValue = field ? value[field] : value;
  const finalValue = rawValue != null ? String(rawValue) : '';
  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  });

  return (<LabelInputWrapper label={label as string} recommended={recommended} required={required} classNameAdd={labelClassAdd}>
    <div className="flex h-16 mt-1 w-full px-2 py-1 bg-white-500 border rounded-md border-slate-300 cursor-pointer">
      <input
        title={finalValue}
        type="file"
        value={finalValue}
        onChange={(e) => {
          const file = e.currentTarget.files?.[0];
          if (file) {
            setPreview((old) => {
              if (old) URL.revokeObjectURL(old);
              return URL.createObjectURL(file);
            });
          }

          const newValue = file?.name || '';
          if (field && setValue)
            setValue((old) => ({ ...old, [field]: newValue as any }));
          else if (setValue)
            setValue(newValue as any);

          onChange?.(newValue);
        }}
        accept={accept}
        disabled={disabled}
        className={`block self-center text-sm placeholder-slate-400
        disabled:text-gray-600 disabled:border-slate-200 disabled:bg-white-100 focus:border-store-primary
            ${required && !finalValue ? 'border-red-500 bg-red-500/10' : ''}
            ${recommended && !finalValue ? 'border-orange-500 bg-orange-500/10' : ''}
      `} />
      {!!preview &&
        <img
          className="object-contain flex-1"
          src={preview}
          alt="preview"
        />}
    </div>
  </LabelInputWrapper>);
};

/* Autocomplete Input */

type SelectInputProps<T, K, M> = {
  value: T,
  field?: K;
  setValue?: Dispatch<SetStateAction<T>>;
  onChange?: (newValue: M) => void;
  getPlaceholder: (value: any) => Promise<string>;
  searchData: (search: string) => Promise<SelectTemplate<M>[]>;
  label: string,
  recommended?: boolean;
  required?: boolean;
  disabled?: boolean;
  className?: string;
};
export const SelectInput = <T, K extends keyof T, M = K extends string ? T[K] : T,>({
  value, field, setValue, onChange, getPlaceholder, searchData, label, recommended, required, disabled, className
}: SelectInputProps<T, K, M>) => {
  const rawValue = field ? value[field] : value;
  const [options, setOptions] = useState<SelectTemplate<M>[]>([]);
  const [placeholder, setPlaceholder] = useState('...');
  const currentFetch = useRef<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState(0);

  useEffect(() => {
    fetchData(undefined, true);
  }, [searchData]);

  useEffect(() => {
    fetchPlaceholder();
  }, [rawValue]);

  const fetchData = async (search?: string, force = false) => {
    setIsLoading(old => old + 1);
    try {
      const data = await searchData(search || '');
      if (force || data.length) setOptions(data);
    } catch (err) {
      console.warn(getPrettyError(err));
    }
    setIsLoading(old => old - 1);
  };

  const fetchPlaceholder = async () => {
    if (rawValue == null) {
      setPlaceholder('---');
      return;
    }
    setIsLoading(old => old + 1);
    try {
      const data = await getPlaceholder(rawValue);
      setPlaceholder(data ?? '...');
    } catch (err) {
      console.warn(getPrettyError(err));
    }
    setIsLoading(old => old - 1);
  };

  const finalValue = options.find(option => option.value === rawValue as any);

  return (<LabelInputWrapper label={label} recommended={recommended} required={required} classNameAdd={className}>
    <span title={finalValue?.label ?? placeholder}>
      <SelectComplete
        options={options}
        value={finalValue}
        onChange={(e) => {
          if (!e) return;
          const newValue = e.value;
          if (setValue) {
            setValue((old: any) => {
              if (field) return { ...old, [field]: newValue };
              return newValue;
            });
          }
          onChange?.(newValue);
        }}
        onInputChange={(newValue, actionMeta) => {
          if (actionMeta.action !== 'input-change') return;
          if (currentFetch.current !== null) {
            clearTimeout(currentFetch.current);
          }
          currentFetch.current = setTimeout(() => fetchData(newValue), 500);
        }}
        placeholder={placeholder}
        isLoading={!!isLoading}
        placeholderStyles={{ color: 'black' }}
        isDisabled={disabled}
        classNameAdd={`
            ${!!disabled && 'text-gray-600 border-slate-200 bg-white-100'}
            ${!!required && !rawValue && 'border-red-500 bg-red-500/10'}
            ${!!recommended && !rawValue && 'border-orange-500 bg-orange-500/10'}
        `}
      />
    </span>
  </LabelInputWrapper>);
};

/* Label Wrapper */

export const LabelInputWrapper: FC<{
  label: string,
  recommended?: boolean;
  required?: boolean;
  classNameAdd?: string;
  textColor?: string;
  children?: ReactNode;
}> = ({ label, recommended, required, classNameAdd, textColor, children }) => {
  return (<label className={`block mb-2 self-end relative ${classNameAdd || ''}`}>
    <span className={`text-xs font-medium ${textColor || "text-grey-500"} w-full flex justify-between`}>
      <span className="break-all">
        {label}
        {required && <span className="text-red-500">**</span>}
        {recommended && <span className="text-orange-500">*</span>}
      </span>
    </span>
    {children}
  </label>);
};
