import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EanInsertionModel, EanViewModel, TypeEanViewModel } from "openapi-typescript-codegen";
import { ColDef } from "ag-grid-community";
import { DateTime } from "luxon";

import { eansApi, articlesApi, typeEansApi } from "api";
import { GridControllerProps, useGridController } from "hooks/useGridControllerV2";
import { useArticle } from "context/Referencement";
import BlockGrid, { PropsBlock } from "components/BlockGrid";
import { DatePickerCellEditor, EANCellEditor, SelectorCellEditor } from "components/AGGride/CellEditor";
import {
  RadioCellRenderer,
  CheckedStyledCellRenderer,
  TextCellRenderer,
  DateCellRenderer,
} from "components/AGGride/CellRender";
import { editableIfNew, editableIfTodayOrFuture } from "components/AGGride/cellEditable";
import { dateCellValidator, eanCellValidator, notEmptyCellValidator, notNilCellValidator } from "components/AGGride/cellValidator";
import ClientSideGrid from "components/AGGride/ClientSideGrid";
import { CustomColDef } from "components/AGGride/gridTypes";
import { getISOStartDate } from "utils";

import "app/ag-grid-style.css";

const defaultColDef: ColDef = {
  minWidth: 90,
  flex: 1,
};

type GridType = EanViewModel;

const EAN: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const [activate, setActivate] = useState(false);
  const [eanTypes, setEanTypes] = useState<TypeEanViewModel[]>([]);

  const gridControllerOptions = useMemo((): GridControllerProps<GridType> => ({
    colConfig: {
      sortColId: "flEanMaitre",
    },
    floatingAction: activate ? 'hover' : 'none',
    emptyRowCheck: (rowData) => !rowData.code,
    fetchData: async () => {
      if (!article?.id) return [];
      const { data } = await articlesApi.v1ArticlesIdEansGet(article.id);
      return data;
    },
    postData: (cleanRow) => eansApi.v1EansPost({ ...cleanRow, fkArticle: article?.id } as EanInsertionModel),
    putData: (cleanRow) => eansApi.v1EansPut(cleanRow as EanViewModel),
    deleteData: (dataId) => eansApi.v1EansIdDelete(dataId),
    getNewModel: () => ({
      code: '',
      dateRemontee: '',
      fkArticle: -1,
      flEanMaitre: false,
      fkTypeEan: 1,
      dateDebut: getISOStartDate(),
    }),
    onEditingStopped: () => setActivate(false),
  }), [activate, article]);
  const gridController = useGridController<GridType>(gridControllerOptions);

  const columnDefs = useMemo((): CustomColDef<GridType>[] => [
    {
      field: "fkTypeEan",
      headerName: t('lib_ean_type_ean'),
      headerTooltip: t('lib_ean_type_ean'),
      editable: editableIfNew,
      cellRenderer: TextCellRenderer,
      valueFormatter: (params) => eanTypes.find(
        (v) => typeof v.id === 'number' && v.id === params.data?.fkTypeEan
      )?.nomTypeEan || "",
      cellEditor: SelectorCellEditor,
      cellEditorParams: {
        values: eanTypes.map((a) => ({ value: a.id, label: a.nomTypeEan })),
      },
    },
    {
      field: "code",
      headerName: t('lib_ean_code'),
      headerTooltip: t('lib_ean_code'),
      editable: editableIfNew,
      cellRenderer: TextCellRenderer,
      cellEditor: EANCellEditor,
      cellEditorParams: {
        eanTypes: eanTypes
      },
      valueValidator: [notNilCellValidator, eanCellValidator('fkTypeEan', eanTypes)],
      headerClass: ["text-xs"],
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "flEanMaitre",
      headerName: t('lib_ean_fl_ean_maitre'),
      headerTooltip: t('lib_ean_fl_ean_maitre'),
      maxWidth: 80,
      minWidth: 80,
      sort: "desc",
      cellRenderer: activate ? RadioCellRenderer : CheckedStyledCellRenderer,
    },
    {
      field: "dateDebut",
      headerName: t('lib_ean_date_debut'),
      headerTooltip: t('lib_ean_date_debut'),
      // maxWidth: 150,
      editable: (params) => editableIfNew(params) || editableIfTodayOrFuture(params),
      cellRenderer: DateCellRenderer,
      cellEditor: DatePickerCellEditor,
      cellEditorParams: {
        min: DateTime.now()
      },
      valueValidator: [notEmptyCellValidator, dateCellValidator],
    },
    {
      field: "dateRemontee",
      headerName: t('lib_ean_date_remontee'),
      headerTooltip: t('lib_ean_date_remontee'),
      // maxWidth: 150,
      cellRenderer: DateCellRenderer,
    },
    // {
    //   field: "code",
    //   headerName: "CODE BARRES",
    //   flex: 1,
    //   minWidth: 230,
    //   cellRenderer: CodeBarCellRenderer,
    //   cellRendererParams: {
    //     renderClass: "justify-start",
    //   },
    // },
  ], [activate, eanTypes, t]);

  useEffect(() => {
    fetchEANTypes();
  }, []);

  async function fetchEANTypes() {
    const { data: res } = await typeEansApi.v1TypeEansGet(1, 100);
    setEanTypes(res.data || []);
  };

  return (
    <BlockGrid
      title={t('tab_liste_eans')} sm={sm} md={md} lg={lg} xl={xl}
      anchor="ean-article-list"
      doubleXl={doubleXl}
      loading={gridController.isLoading}
      handleClick={setActivate}
      toActivate={activate}
      disableCreate={gridController.hasEmptyLines}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <ClientSideGrid
        className="block-grid-md"
        gridController={gridController}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
      />
    </BlockGrid>
  );
};

export default EAN;