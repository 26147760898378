import {FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import {SousMarqueViewModel} from "openapi-typescript-codegen";
import {useTranslation} from "react-i18next";
import {typologiesApi} from 'api';
import {PropsBlock} from 'components/BlockGrid';
import {useGridController} from "hooks";
import {TextCellRenderer} from "components/AGGride/CellRender";
import MarquesContext from "../MarquesContext";
import BlockGridMarques from "../BlockGridMarques";
import {
    SousMarqueViewModelTypoNomenclatureDetail
} from "openapi-typescript-codegen/types/models/sous-marque-view-model-typo-nomenclature-detail";
import SousMarqueModal from "../modals/SousMarqueModal";

interface Props extends PropsBlock {
    sousMarques: SousMarqueViewModelTypoNomenclatureDetail[];
    onSousMarqueChange: (id: number | null) => void;
}

type GridType = SousMarqueViewModel;

const SousMarque: FunctionComponent<Props> = ({ sousMarques, onSousMarqueChange }) => {
    const { t } = useTranslation();
    const { selection, setRefresh } = useContext(MarquesContext);
    const [disableCreate, setDisableCreate] = useState(false);

    const gridController = useGridController<GridType>(
        useMemo(() => ({
            colConfig: {},
            autoFetch: true,
            emptyRowCheck: (rowData) => !rowData.id,
            fetchData: async () => sousMarques.map(({ item }) => item),
            deleteData: (id) => typologiesApi.v1TypologiesIdDelete(id).then(() => {
                id === selection.sousMarque ? onSousMarqueChange(null) : setRefresh(true);
            }),
            rowClassRules: {
                "hide-actions": params => {
                    const sousMarque = sousMarques.find(sousMarque => sousMarque.item.id === params.data?.id);
                    return !!(sousMarque && (sousMarque.childrenCount || sousMarque.articleCount));
                },
            },
            columnDefs: [
                {
                    field: 'code',
                    headerName: t('lib_sous_marque_code'),
                    headerTooltip: t('lib_sous_marque_code'),
                    cellRenderer: TextCellRenderer,
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 1
                },
                {
                    field: 'nomSousMarque',
                    headerName: t('lib_sous_marque_nom_sous_marque'),
                    headerTooltip: t('lib_sous_marque_nom_sous_marque'),
                    cellRenderer: TextCellRenderer,
                    valueFormatter: (params: any) => {
                        const sousMarque = sousMarques.find(sousMarque => sousMarque.item.id === params.data.id);
                        return sousMarque ? `${sousMarque.item.nomSousMarque} <${sousMarque.childrenCount}>` : String();
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 4
                },
                {
                    headerName: t('lib_sous_marque_nb_articles'),
                    headerTooltip: t('lib__sous_marque_nb_articles'),
                    cellRenderer: TextCellRenderer,
                    valueFormatter: (params: any) => {
                        const sousMarque = sousMarques.find(sousMarque => sousMarque.item.id === params.data.id);
                        return sousMarque ? sousMarque.articleCount.toString() : String();
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 1
                },
            ],
        }), [sousMarques, t])
    );

    useEffect(() => { gridController.fetchData(); }, [sousMarques]);
    useEffect(() => { setDisableCreate(!selection.marque); }, [selection.marque]);

    return <BlockGridMarques
        label={"tab_detail_marque"}
        gridController={gridController}
        onSelect={onSousMarqueChange}
        gridName={"sousMarque"}
        disableCreate={disableCreate}
    ><SousMarqueModal /></BlockGridMarques>;
};

export default SousMarque;