import { FC, useMemo, useState } from 'react';
import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import { Title } from "components/H2Title";
import { RemiseInsertionModel, RemiseViewModel } from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { notEmptyCellValidator } from "components/AGGride/cellValidator";
import { SelectorCellEditor } from 'components/AGGride/CellEditor';
import { TextCellRenderer } from 'components/AGGride/CellRender';

import { remisesApi } from 'api';
import BlockGrid, { PropsBlock, LayoutEnum } from 'components/BlockGrid';
import { useGridController } from "hooks";
import {CheckBoxColumnDef} from "../../components/AGGride/columnDef";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
};

type GridType = Partial<RemiseViewModel | RemiseInsertionModel>;

const Remise: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);
  const [typeAccord] = useState<{ value: string | null, label: string; }[]>([
    { value: null, label: "..." },
    { value: 'A', label: t('enu_remise_type_accord_arriere') },
    { value: 'C', label: t('enu_remise_type_accord_arriere_conditionne') },
    { value: 'F', label: t('enu_remise_type_accord_sur_facture') },
  ]);
  const [typeSens] = useState<{ value: string | null, label: string; }[]>([
    { value: null, label: "..." },
    { value: 'R', label: t('enu_remise_sens_remise') },
    { value: 'M', label: t('enu_remise_sens_majoration') },
  ]);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      defaultColDef: {
        flex: 1,
      },
      emptyRowCheck: (rowData) => !rowData.nomRemise,
      fetchData: async () => {
        const { data: { data } } = await remisesApi.v1RemisesGet(1, 1000);
        return data.sort((a, b) => a.code.localeCompare(b.code));
      },
      getNewModel: () => ({
        typeAccord: "F",
        sens: "R",
        code: '',
        nomRemise: '',
      }),
      postData: (cleanRow) => remisesApi.v1RemisesPost(cleanRow as RemiseInsertionModel),
      putData: (cleanRow) => remisesApi.v1RemisesPut(cleanRow as RemiseViewModel),
      deleteData: (dataId) => remisesApi.v1RemisesIdDelete(dataId),
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'code',
          headerName: t('lib_remise_code'),
          headerTooltip: t('lib_remise_code'),
          cellRenderer: TextCellRenderer,
          editable: true,
          maxWidth: 60,
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          filter: 'agTextColumnFilter',
        },
        {
          field: 'nomRemise',
          headerName: t('lib_remise_nom_remise'),
          headerTooltip: t('lib_remise_nom_remise'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'typeAccord',
          headerName: t('lib_remise_type_accord'),
          headerTooltip: t('lib_remise_type_accord'),
          maxWidth: 150,
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          cellRenderer: TextCellRenderer,
          tooltipValueGetter: (params) => {
            return typeAccord.find((v) => v.value === params.value)?.value;
          },
          valueFormatter: (params) => typeAccord.find((v) => v.value === params.value)?.label || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: typeAccord.map((a) => ({ value: a.value, label: a.value ? a.label : a.label })),
          },
          valueValidator: [notEmptyCellValidator],
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'sens',
          maxWidth: 90,
          headerName: t('lib_remise_sens'),
          headerTooltip: t('lib_remise_sens'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          cellRenderer: TextCellRenderer,
          tooltipValueGetter: (params) => {
            return typeSens.find((v) => v.value === params.value)?.value;
          },
          valueFormatter: (params) => typeSens.find((v) => v.value === params.value)?.label || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: typeSens.map((a) => ({ value: a.value, label: a.value ? a.label : a.label })),
          },
          valueValidator: [notEmptyCellValidator],
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
        CheckBoxColumnDef<GridType>("flModifPx1", "lib_taxe_fl_px1"),
        CheckBoxColumnDef<GridType>("flModifPx2", "lib_taxe_fl_px2"),
        CheckBoxColumnDef<GridType>("flModifPx3", "lib_taxe_fl_px3"),
        CheckBoxColumnDef<GridType>("flModifPx4", "lib_taxe_fl_px4"),
        CheckBoxColumnDef<GridType>("flModifPx5", "lib_taxe_fl_px5"),
        CheckBoxColumnDef<GridType>("flModifPx6", "lib_taxe_fl_px6"),
        CheckBoxColumnDef<GridType>("flModifPx7", "lib_taxe_fl_px7"),
        CheckBoxColumnDef<GridType>("flModifPx8", "lib_taxe_fl_px8"),
        CheckBoxColumnDef<GridType>("flModifPx9", "lib_taxe_fl_px9"),
        CheckBoxColumnDef<GridType>("flModifPx10", "lib_taxe_fl_px10"),
        CheckBoxColumnDef<GridType>("flModifPx11", "lib_taxe_fl_px11"),
        CheckBoxColumnDef<GridType>("flSrt", "lib_fl_SRP"),
      ],
    }), [activate, t])
  );

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: `${t('men_refe_fournisseurs')} - ${t('men_parametre')}`,
      link: `/app/referencement/fournisseurs/parametres`,
    },
    {
      name: "tit_remise",
    },
  ];

  const title: Title = {
    title: `${t('men_refe_fournisseurs')} - ${t('tab_remise')}`,
    icon: "fournisseurs",
  };

  return (
    <>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <BlockGrid
        sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
        layout={LayoutEnum.settings}
        handleClick={(res: boolean) => {
          setActivate(res);
          setTimeout(gridController.activateContextActionButtons, 50);
        }}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-params">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType={"fullRow"}
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </>
  );
};

export default Remise;