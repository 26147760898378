import { FC, useMemo, useState } from 'react';
import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import { Title } from "components/H2Title";
import { ModeTransmissionViewModel } from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { notEmptyCellValidator } from "components/AGGride/cellValidator";

import { modeTransmissionApi } from 'api';
import BlockGrid, { PropsBlock, LayoutEnum } from 'components/BlockGrid';
import { TextCellRenderer } from 'components/AGGride/CellRender';
import { useGridController } from "hooks";
import { SelectorCellEditor, TextCellEditor } from "components/AGGride/CellEditor";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

type GridType = ModeTransmissionViewModel;

const ModeTransmission: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);

  const options = {
    types: [
      { value: null, label: "..." },
      { value: 'M', label: t("enu_mode_transmission_type_mail") },
      { value: 'F', label: t("enu_mode_transmission_type_edi") },
      { value: 'P', label: t("enu_mode_transmission_type_papier") }
    ]
  };

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.nomModeTransmission,
      fetchData: async () => {
        const { data: { data } } = await modeTransmissionApi.v1ModeTransmissionsGet(1, 1000);
        return data.sort((a, b) => a.code.localeCompare(b.code));
      },
      postData: (cleanRow) => modeTransmissionApi.v1ModeTransmissionsPost(cleanRow),
      putData: (cleanRow) => modeTransmissionApi.v1ModeTransmissionsPut(cleanRow),
      deleteData: (dataId) => modeTransmissionApi.v1ModeTransmissionsIdDelete(dataId),

      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'code',
          headerName: t('lib_mode_transmission_code'),
          headerTooltip: t('lib_mode_transmission_code'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          cellEditor: TextCellEditor,
          cellEditorParams: {
            maxLength: 1,
            toUpperCase: true,
          },
          editable: true,
          filter: 'agTextColumnFilter',
          flex: 1
        },
        {
          field: 'nomModeTransmission',
          headerName: t('lib_mode_transmission_nom_mode_transmission'),
          headerTooltip: t('lib_mode_transmission_nom_mode_transmission'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 3
        },
        {
          field: 'typeTransmission',
          headerName: t('lib_mode_transmission_type_transmission'),
          headerTooltip: t('lib_mode_transmission_type_transmission'),
          cellRenderer: TextCellRenderer,
          editable: true,
          tooltipValueGetter: (params) => options.types.find(type => type.value === params.value)?.value,
          valueFormatter: (params) => options.types.find(type => type.value === params.value)?.label || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: { values: options.types },
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 3
        }
      ],
    }), [activate, t])
  );

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: `${t('men_refe_fournisseurs')} - ${t('men_parametre')}`,
      link: `/app/referencement/fournisseurs/parametres`,
    },
    {
      name: "tit_mode_transmission",
    },
  ];

  const title: Title = {
    title: `${t('men_refe_fournisseurs')} - ${t('tab_mode_transmission')}`,
    icon: "fournisseurs",
  };

  return (
    <>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <BlockGrid
        sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
        layout={LayoutEnum.settings}
        handleClick={(res: boolean) => {
          setActivate(res);
          setTimeout(gridController.activateContextActionButtons, 50);
        }}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-params">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType={"fullRow"}
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </>
  );
};

export default ModeTransmission;