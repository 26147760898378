import { FC, useRef } from 'react';
import { Modal as MuiModal, ModalProps } from "@mui/material";

const Modal: FC<ModalProps> = (props) => {
  const root = useRef(globalThis.document.getElementById('root') || globalThis.document.body);

  return (
    <MuiModal
      container={() => root.current}
      hideBackdrop
      {...props}
    >
      <>
        <div
          className="fixed flex items-center justify-center right-0 bottom-0 top-0 left-0 -z-[1]"
          style={{
            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }} />
        {props.children}
      </>
    </MuiModal>);
};

export default Modal;
