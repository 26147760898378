import { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'utils';

import { useArticle, useCompletudeArticle, useUpdateArticle } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateArticle';
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import SelectComplete, { SelectTemplate } from 'components/SelectComplete';

import { articlesApi, typologiesApi, surMarquesApi, marquesApi } from 'api';
import {
  SurMarqueViewModel,
  MarqueViewModel,
  SousMarqueViewModel,
  MarqueInsertionModel
} from 'openapi-typescript-codegen';

import { useTranslation } from "react-i18next";
import { ColumnControlArticle } from "validators";

const defaultImage = "/assets/images/default.png";

interface FormArray {
  id?: number,
  fkTypologie: number | null,
  fkSurMarque: number | null,
  fkMarque: number | null,
  fkSousMarque: number | null;
}

const Marques: FunctionComponent<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();

  const uA = useUpdateArticle();
  const [completude, setCompletude] = useState<number>(0);
  const [activate, setActivate] = useState<boolean>(false);
  const [hasFocus, setFocus] = useState(false);
  const [form, setForm] = useState<FormArray>({
    fkTypologie: null,
    fkSurMarque: null,
    fkMarque: null,
    fkSousMarque: null
  });
  const [selectedMarque, setSelectedMarque] = useState<Partial<MarqueInsertionModel | MarqueViewModel>>({});

  const [loading, setLoader] = useState<boolean>(true);

  // Options for the select:
  const [typologie, setTypologie] = useState<SelectTemplate[]>([]);
  const [surMarque, setSurMarque] = useState<SelectTemplate[]>([]);
  const [marque, setMarque] = useState<SelectTemplate[]>([]);
  const [sousMarque, setSousMarque] = useState<SelectTemplate[]>([]);

  const [searchTypo, setSearchTypo] = useState<string>('');

  // @TODO : Remove when the controls have been replaced by the ColumnControlArticle class~
  const { state: controls } = useCompletudeArticle();

  const checkCompletude = () => {
    setCompletude(ColumnControlArticle.checkMany([
      "article.fk_typologie",
      "article.fk_sur_marque",
      "article.fk_marque",
      "article.fk_sous_marque"
    ], form));
  }

  const validate = () => ColumnControlArticle.validateMany([
      "article.fk_typologie",
      "article.fk_sur_marque",
      "article.fk_marque",
      "article.fk_sous_marque"
    ], form);

  const fetchGetData = async (id: number) => {
    const response = await articlesApi.v1ArticlesIdGet(id);
    let tmp: FormArray = {
      id: response.data.id,
      fkTypologie: response.data.fkTypologie ?? null,
      fkSurMarque: response.data.fkSurMarque ?? null,
      fkMarque: response.data.fkMarque ?? null,
      fkSousMarque: response.data.fkSousMarque ?? null
    };
    if (response.status >= 200 && response.status < 300) setLoader(false);
    setForm(tmp);
  };

  const fetchUpdateData = async (id: number) => {
    const tmp: string = JSON.stringify({
      fkTypologie: form.fkTypologie,
      fkSurMarque: form.fkSurMarque,
      fkMarque: form.fkMarque,
      fkSousMarque: form.fkSousMarque
    });
    const response = await articlesApi.v1ArticlesIdPut(id, tmp);

    if (response.status >= 200 && response.status < 300) { //success
      toast.success(t('mes_validation_modification'));
      // reset dat with response.data ?
      uA.triggerEvent(UpdateEnum.marque);
      setActivate(false);
    }
    else
      toast.error(t('mes_erreur_technique') + ": " + response.status);
  };

  const fetchGetTypologie = async (search: string) => {
    const { data: response } = await typologiesApi.v1TypologiesSearchGet(search, 1, 8);

    let tmp: SelectTemplate[] = [];
    if (response.data && response.data.length > 0) {
      tmp = [
        { value: null, label: "..." },
        ...response.data.map(
          (item) => ({ value: item.id, label: item.code + " - " + item.nomTypologie })
        )
      ];
    }

    setTypologie(tmp);
  };
  useEffect(() => {
    fetchGetTypologie(searchTypo);
  }, [searchTypo]);

  const fetchGetSurMarque = async (id: number) => {
    const { data: response } = await typologiesApi.v1TypologiesIdSurmarquesGet(id);

    let tmp: SelectTemplate[] = [];
    if (response && response.length > 0) {
      tmp = [
        { value: null, label: "..." },
        ...response.map(
          (item: SurMarqueViewModel) => ({ value: item.id, label: item.code + " - " + item.nomSurMarque })
        )
      ];
    }

    setSurMarque(tmp);
  };

  const fetchGetMarque = async (id: number) => {
    const { data: response } = await surMarquesApi.v1SurMarquesIdMarquesGet(id);

    let tmp: SelectTemplate[] = [];
    if (response && response.length > 0) {
      tmp = [
        { value: null, label: "..." },
        ...response.map(
          (item: MarqueViewModel) => ({ value: item.id, label: item.code + " - " + item.nomMarque })
        )
      ];
    }

    setMarque(tmp);
  };

  const fetchGetSousMarque = async (id: number) => {
    const { data: response } = await marquesApi.v1MarquesIdSousmarquesGet(id);

    let tmp: SelectTemplate[] = [];
    if (response && response.length > 0) {
      tmp = [
        { value: null, label: "..." },
        ...response.map(
          (item: SousMarqueViewModel) => ({ value: item.id, label: item.code + " - " + item.nomSousMarque })
        )
      ];
    }

    setSousMarque(tmp);
  };

  const fetchGetSelectedMarque = async (id: number) => {
    const { data: response } = await marquesApi.v1MarquesIdGet(id);
    setSelectedMarque(response);
  };

  useEffect(() => {
    checkCompletude();
  }, [form]);

  useEffect(() => {
    if (article?.id) fetchGetData(article.id);
  }, [article?.id]);

  useEffect(() => {
    setForm({
      ...form,
      fkSurMarque: null,
      fkMarque: null,
      fkSousMarque: null,
    });
    if (form.fkTypologie) fetchGetSurMarque(form.fkTypologie);
    else {
      setSurMarque([]);
      setMarque([]);
      setSousMarque([]);
    }
  }, [form.fkTypologie]);

  useEffect(() => {
    setForm({
      ...form,
      fkMarque: null,
      fkSousMarque: null,
    });
    if (form.fkSurMarque) fetchGetMarque(form.fkSurMarque);
    else {
      setMarque([]);
      setSousMarque([]);
    }
  }, [form.fkSurMarque]);

  useEffect(() => {
    setForm({
      ...form,
      fkSousMarque: null,
    });
    if (form.fkMarque) {
      fetchGetSelectedMarque(form.fkMarque);
      fetchGetSousMarque(form.fkMarque);
    }
    else {
      setSelectedMarque({});
      setSousMarque([]);
    }
  }, [form.fkMarque]);

  function handleUpdate() {
    if (validate()
      // && checker()
      && form.id) fetchUpdateData(form.id);
  }

  function handleCancel() {
    if (article?.id) fetchGetData(article.id);
    setActivate(false);
  }

  function handleFocus(focus: boolean) {
    setFocus(focus);
  }

  return (
    <BlockGrid
      title={t('tab_article_marque')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      completude={completude}
      // img={defaultImage} // pour exemple completude -> items manquants
      loading={loading}
      handleClick={(res: boolean) => setActivate(res)}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      handleFocus={handleFocus}
    >
      <form className="flex flex-col justify-between">
        <div className="">
          <label className="block mb-2">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_article_fk_typologie')}
                { ColumnControlArticle.get("article.fk_typologie")?.getValidationLabelElement() }
              </span>
            </span>
            <SelectComplete tabIndex={5} isDisabled={!activate}
              options={typologie}
              value={typologie.filter(option => {
                return option.value === form.fkTypologie;
              })}
              onChange={(e: any): void => setForm({ ...form, fkTypologie: e.value })}
              onInputChange={(e: any): void => setSearchTypo(e)}
              classNameAdd={`
                  ${ loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200' }
                  ${ ColumnControlArticle.get("article.fk_typologie")?.getValidationInputClass(activate, hasFocus, form) }
                `}
            />
          </label>
          <div className="flex gap-4">
            <div className="w-2/3">
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_fk_sur_marque')}
                    {controls?.find((i) => i.colonneArticle === 'article.fk_sur_marque')?.required && <span className="text-red-500">**</span>}
                    {controls?.find((i) => i.colonneArticle === 'article.fk_sur_marque')?.recommended && <span className="text-orange-500">*</span>}
                  </span>
                </span>
                <SelectComplete tabIndex={5} isDisabled={!activate}
                  options={surMarque}
                  value={surMarque.filter(option => {
                    return option.value === form.fkSurMarque;
                  })}
                  onChange={(e: any): void => setForm({ ...form, fkSurMarque: e.value })}
                  classNameAdd={`
                      ${!!loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                      ${!!(controls?.find((i) => i.colonneArticle === 'article.fk_sur_marque')?.required && form.fkTypologie === null) && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                      ${!!(controls?.find((i) => i.colonneArticle === 'article.fk_sur_marque')?.recommended && form.fkTypologie === null) && 'border-orange-500 bg-orange-500/10'}
                    `}
                />
              </label>
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_fk_marque')}
                    {controls?.find((i) => i.colonneArticle === 'article.fk_marque')?.required && <span className="text-red-500">**</span>}
                    {controls?.find((i) => i.colonneArticle === 'article.fk_marque')?.recommended && <span className="text-orange-500">*</span>}
                  </span>
                </span>
                <SelectComplete tabIndex={5} isDisabled={!activate}
                  options={marque}
                  value={marque.filter(option => {
                    return option.value === form.fkMarque;
                  })}
                  onChange={(e: any): void => setForm({ ...form, fkMarque: e.value })}
                  classNameAdd={`
                      ${!!loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                      ${!!(controls?.find((i) => i.colonneArticle === 'article.fk_marque')?.required && form.fkTypologie === null) && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                      ${!!(controls?.find((i) => i.colonneArticle === 'article.fk_marque')?.recommended && form.fkTypologie === null) && 'border-orange-500 bg-orange-500/10'}
                    `}
                />
              </label>
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_fk_sous_marque')}
                    {controls?.find((i) => i.colonneArticle === 'article.fk_sous_marque')?.required && <span className="text-red-500">**</span>}
                    {controls?.find((i) => i.colonneArticle === 'article.fk_sous_marque')?.recommended && <span className="text-orange-500">*</span>}
                  </span>
                </span>
                <SelectComplete tabIndex={5} isDisabled={!activate}
                  options={sousMarque}
                  value={sousMarque.filter(option => {
                    return option.value === form.fkSousMarque;
                  })}
                  onChange={(e: any): void => setForm({ ...form, fkSousMarque: e.value })}
                  classNameAdd={`
                      ${!!loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                      ${!!(controls?.find((i) => i.colonneArticle === 'article.fk_sous_marque')?.required && form.fkTypologie === null) && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                      ${!!(controls?.find((i) => i.colonneArticle === 'article.fk_sous_marque')?.recommended && form.fkTypologie === null) && 'border-orange-500 bg-orange-500/10'}
                    `}
                />
              </label>
            </div>
            <div className={`w-1/3 max-h-36 flex mb-auto mt-[22px] p-2 justify-end bg-gray-100 border border-slate-200 rounded aspect-square 
                ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}`}>
              <div className="h-full w-full bg-center bg-no-repeat bg-contain"
                style={{ backgroundImage: `url(${selectedMarque?.logoMarque ?? defaultImage})` }}></div>
              {/* <img className=" object-contain"
                  src={
                    marque.find(item =>item.id === form.fkMarque)?.logoMarque ?? '/assets/images/default.png'
                  } /> */}
            </div>
          </div>
        </div>
      </form>
    </BlockGrid>
  );
};

export default Marques;