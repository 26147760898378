import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ColDef } from "ag-grid-community";

import { ImageCellRenderer, ImageCellRendererProps, LinkCellRenderer, LinkCellRendererProps } from "components/AGGride/CellRender";
import { CustomColDef } from "components/AGGride/gridTypes";
import { fournisseursApi } from "api/";
import { ViewArticleViewModel } from "openapi-typescript-codegen";
import { useFournisseur } from "context/Referencement";

import BlockGrid from 'components/BlockGrid';
import ServerSideGrid, { ServerSideGetRowsCallback } from "components/AGGride/ServerSideGrid";

const defaultColDef: ColDef = {
  flex: 1,
  minWidth: 80,
  sortable: true,
};

type GridType = ViewArticleViewModel;

const TabReferentiel: FC = () => {
  const { state: fournisseur } = useFournisseur();
  const { t } = useTranslation();

  const columnDefs = useMemo((): CustomColDef<GridType>[] => [
    {
      field: "image",
      headerName: t('lib_article_image'),
      headerTooltip: t('lib_article_image'),
      pinned: "left",
      maxWidth: 80,
      sortable: false,
      cellRenderer: ImageCellRenderer,
      cellRendererParams: {
        href: (val) => val,
        defaultHref: "/assets/images/default.png",
      } as ImageCellRendererProps,
    },
    {
      field: "code",
      headerName: t('lib_article_code'),
      headerTooltip: t('lib_article_code'),
      pinned: "left",
      maxWidth: 100,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: LinkCellRenderer,
      cellRendererParams: {
        href: (value) => `/app/referencement/articles/${value}`,
        target: '_blank',
      } as LinkCellRendererProps,
    },
    {
      field: "designationStandard",
      headerName: t("lib_article_designation_standard"),
      headerTooltip: t('lib_article_designation_standard'),
      minWidth: 250,
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "marqueLogo",
      headerName: t('lib_marque_logo'),
      headerTooltip: t('lib_marque_logo'),
      maxWidth: 80,
      sortable: false,
      cellRenderer: ImageCellRenderer,
      cellRendererParams: {
        href: (val) => val,
        defaultHref: "/assets/images/default.png",
      } as ImageCellRendererProps,
    },
    {
      field: "marqueNom",
      headerName: t("lib_article_fk_marque"),
      headerTooltip: t('lib_article_fk_marque'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "codeEtatNom",
      headerName: t("lib_article_fk_code_etat"),
      headerTooltip: t('lib_article_fk_code_etat'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "relationFournisseur.circuitLogistique.fournisseur.code",
      headerName: t("lib_relation_fournisseur_fk_fournisseur"),
      headerTooltip: t('lib_relation_fournisseur_fk_fournisseur'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "relationFournisseur.nom",
      headerName: t("lib_relation_fournisseur_nom_relation_fournisseur"),
      headerTooltip: t('lib_relation_fournisseur_nom_relation_fournisseur'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "fluxFournisseurCode",
      headerName: t("lib_flux_circuit"),
      headerTooltip: t('lib_flux_circuit'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
      valueFormatter: (params) => params.data?.fluxFournisseurCode && params.data?.fluxFournisseurNom
        ? `${params.data.fluxFournisseurCode} - ${params.data.fluxFournisseurNom}`
        : "",
    },
    {
      field: "stock", // A gérer plus tard selon la spec
      headerName: t("lib_article_fl_suivi_stock"),
      headerTooltip: t('lib_article_fl_suivi_stock'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "completude", // A gérer plus tard selon la spec
      headerName: t("lib_controle_completude_article_valeur"),
      headerTooltip: t('lib_controle_completude_article_valeur'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "prixAchat",
      headerName: t("lib_prix_achat_px_achat"),
      headerTooltip: t('lib_prix_achat_px_achat'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "prixVente",
      headerName: t("lib_prix_vente_px_vente"),
      headerTooltip: t('lib_prix_vente_px_vente'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "typeArticleNom",
      headerName: t("lib_article_fk_type_article"),
      headerTooltip: t('lib_article_fk_type_article'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
  ], [t]);

  const getRows: ServerSideGetRowsCallback<GridType> = useCallback(
    async (params, { page, amount: _amount, query: _query }) => {
      if (!fournisseur?.id) return { rowData: [], rowCount: 0 };
      page!;
      // TODO: create view with search options
      const { data: { data } } = await fournisseursApi.v1FournisseursIdViewArticlePost(fournisseur.id, /* page, amount, searchOption: query */);
      // @TODO: Temporary fix. It needs to be fixed properly:
      const cleanData = data
        .sort((a: any, b: any) => (a.code || '')?.localeCompare(b.code || ''));

      return {
        rowData: cleanData,
        rowCount: cleanData.length
      };
    }, [fournisseur]);

  return (
    <div className="h-full flex flex-wrap justify-between bg-grey-150 gap-px p-px">
      <BlockGrid
        sm={100} md={100} lg={100} xl={100} doubleXl={100}
        title={t('tab_listing_articles')}
        toActivate={false}
      >
        <ServerSideGrid
          className="w-full h-[45vh]"
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}

          getRows={getRows}
        />
      </BlockGrid>
    </div>
  );
};

export default TabReferentiel;