import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useScrollHandler() {
    const { pathname, hash, key } = useLocation();

    const handleScroll = () => {
        if (!hash) return;

        setTimeout(() => {
            const element = document.getElementById(hash.replace("#", ""));
            if (!element) return;

            element.scrollIntoView({ behavior: 'smooth' });
            // globalThis.scrollTo({
            //     behavior: element ? "smooth" : "auto",
            //     top: element?.offsetTop || 0
            // });
        }, 200);
    };

    useEffect(() => {
        handleScroll();
    }, [pathname, hash, key]);
}