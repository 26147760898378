import { useState, FC } from 'react';

import { useTranslation } from "react-i18next";

import { ToggleButtonGroup, ToggleButton, styled } from '@mui/material';

import { LieuFonctionInsertionModel, LieuFonctionViewModel } from 'openapi-typescript-codegen';

import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import Modal from 'components/Modal';

import { toast } from 'utils';

const ToggleButtonCustom = styled(ToggleButton)(() => ({
  '&.Mui-selected': {
    color: 'rgb(255 255 255)',
    backgroundColor: 'rgb(38 132 255)'
  },
  '&.Mui-selected:hover': {
    color: 'rgb(255 255 255)',
    opacity: '.8',
    backgroundColor: 'rgb(38 132 255)',
  },
  '&:hover': {
    color: 'rgb(0 0 0)',
    opacity: '.8',
    backgroundColor: 'rgb(235 235 235)',
  },
}));

interface Props {
  onCreate: (lieu: LieuFonctionInsertionModel) => void;
}

export const CreationLieuxLN: FC<Props> = ({ onCreate }) => {
  const { t } = useTranslation();

  const [lieuFonction, setLieuFonction] = useState<Partial<LieuFonctionInsertionModel | LieuFonctionViewModel>>({});

  const [open, setOpen] = useState<boolean>(false);

  function validate() {
    let isValid = true;
    if (!lieuFonction.code) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_lieu_fonction_code')}`);
      isValid = false;
    }
    if (!lieuFonction.nomLieuFonction) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_lieu_fonction_nom_lieu_fonction')}`);
      isValid = false;
    }
    return isValid;
  }

  const toCreate = () => {
    setOpen(true);
    setLieuFonction({ typeLieuFonction: "M", flAssujettiTva: true });
  };

  const handleCreate = () => {
    if (validate()) {
      onCreate && onCreate(lieuFonction as LieuFonctionInsertionModel);
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setLieuFonction({});
    setOpen(false);
  };

  return (
    <>
      <button tabIndex={5}
        onClick={() => toCreate()}
        className="bg-store-primary text-white-500 p-2 rounded m-2 text-sm">+ {t('btn_ajouter_lieu_fonction')}</button>
      <Modal
        open={open} tabIndex={-1}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <BlockGrid
          layout={LayoutEnum.modal}
          title={t('tit_creation_lieu_fonction')}
          informations={''}
          toActivate={true}
          handleUpdate={handleCreate}
          handleCancel={handleCancel}
        >
          <form className="flex flex-col justify-between">
            <div className="">

              <div className="py-4 my-4 grid grid-cols-2 gap-4">
                <div className="flex items-center gap-2">
                  <div>
                    {t('lib_lieu_fonction_code')}
                    <span className="text-red-500">**</span>
                  </div>
                  <div>
                    <input type="text" tabIndex={5}
                      value={lieuFonction.code ?? ''}
                      onChange={e => setLieuFonction({ ...lieuFonction, code: e.currentTarget.value })}
                      className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary`} />
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div>
                    {t('lib_lieu_fonction_nom_lieu_fonction')}
                    <span className="text-red-500">**</span>
                  </div>
                  <div>
                    <input type="text" tabIndex={5}
                      value={lieuFonction.nomLieuFonction ?? ''}
                      onChange={e => setLieuFonction({ ...lieuFonction, nomLieuFonction: e.currentTarget.value })}
                      className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary`} />
                  </div>
                </div>
              </div>
              <div className="h-px bg-slate-200 w-full col-span-full"></div>
              <div className="py-4 grid grid-cols-2 gap-4">
                <div className="flex items-center gap-2">
                  <div>
                    {t('lib_lieu_fonction_type_lieu_fonction')}
                    <span className="text-red-500">**</span>
                  </div>
                  <div>
                    <ToggleButtonGroup
                      value={lieuFonction.typeLieuFonction}
                      exclusive
                      onChange={(e, value) => value && setLieuFonction({ ...lieuFonction, typeLieuFonction: value })}
                      aria-label="text alignment"
                      size="small" >
                      <ToggleButtonCustom tabIndex={5} value="M" aria-label="left aligned">
                        {t('enu_lieu_fonction_type_mixte')}
                      </ToggleButtonCustom>
                      <ToggleButtonCustom tabIndex={5} value="C" aria-label="centered">
                        {t('enu_lieu_fonction_type_commande')}
                      </ToggleButtonCustom>
                      <ToggleButtonCustom tabIndex={5} value="R" aria-label="right aligned">
                        {t('enu_lieu_fonction_type_reglement')}
                      </ToggleButtonCustom>
                    </ToggleButtonGroup>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div>
                    {t('lib_lieu_fonction_fl_assujetti_tva')}
                  </div>
                  <div>
                    <input type="checkbox" tabIndex={5}
                      checked={lieuFonction?.flAssujettiTva}
                      onChange={(e) => setLieuFonction({ ...lieuFonction, flAssujettiTva: e.currentTarget.checked })}
                      className={`h-4 w-4 mt-1 block bg-white border border-slate-300 rounded-md
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary`} />
                  </div>
                </div>
              </div>
            </div>
          </form>

        </BlockGrid>
      </Modal>
    </>
  );
};

export default CreationLieuxLN;