import {FunctionComponent, useContext, useEffect, useMemo } from 'react';
import {TypologieViewModel} from "openapi-typescript-codegen";
import {useTranslation} from "react-i18next";
import {typologiesApi} from 'api';
import {PropsBlock} from 'components/BlockGrid';
import {useGridController} from "hooks";
import {TextCellRenderer} from "components/AGGride/CellRender";
import MarquesContext from "../MarquesContext";
import BlockGridMarques from "../BlockGridMarques";
import TypologieModal from "../modals/TypologieModal";
import { TypologieViewModelTypoNomenclatureDetail } from "openapi-typescript-codegen/types/models/typologie-view-model-typo-nomenclature-detail";

interface Props extends PropsBlock {
    typologies: TypologieViewModelTypoNomenclatureDetail[];
    onTypologieChange: (id: number | null) => void;
}

type GridType = TypologieViewModel;

const Typologie: FunctionComponent<Props> = ({ typologies, onTypologieChange }) => {
    const { t } = useTranslation();
    const { selection, setRefresh } = useContext(MarquesContext);

    const gridController = useGridController<GridType>(
        useMemo(() => ({
            colConfig: {},
            autoFetch: true,
            emptyRowCheck: (rowData) => !rowData.id,
            fetchData: async () => typologies.map(({ item }) => item),
            deleteData: (id) => typologiesApi.v1TypologiesIdDelete(id).then(() => {
                id === selection.typologie ? onTypologieChange(null) : setRefresh(true);
            }),
            rowClassRules: {
                "hide-actions": params => {
                    const typologie = typologies.find(typologie => typologie.item.id === params.data?.id);
                    return !!(typologie && (typologie.childrenCount || typologie.articleCount));
                },
            },
            columnDefs: [
                {
                    field: 'code',
                    headerName: t('lib_typologie_code'),
                    headerTooltip: t('lib_typologie_code'),
                    cellRenderer: TextCellRenderer,
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 1
                },
                {
                    field: 'nomTypologie',
                    headerName: t('lib_typologie_nom_typologie'),
                    headerTooltip: t('lib_typologie_nom_typologie'),
                    cellRenderer: TextCellRenderer,
                    valueFormatter: (params: any) => {
                        const typologie = typologies.find(typologie => typologie.item.id === params.data.id);
                        return typologie ? `${typologie.item.nomTypologie} <${typologie.childrenCount}>` : String();
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 4
                },
                {
                    headerName: t('lib_typologie_nb_articles'),
                    headerTooltip: t('lib_typologie_nb_articles'),
                    cellRenderer: TextCellRenderer,
                    valueFormatter: (params: any) => {
                        const typologie = typologies.find(typologie => typologie.item.id === params.data.id);
                        return typologie ? typologie.articleCount.toString() : String();
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 1
                },
            ],
        }), [typologies, t])
    );

    useEffect(() => { gridController.fetchData(); }, [typologies]);

    return <BlockGridMarques
        label={"tab_detail_typologie"}
        gridController={gridController}
        onSelect={onTypologieChange}
        gridName={"typologie"}
    ><TypologieModal/></BlockGridMarques>;
};

export default Typologie;