import { FunctionComponent, useEffect, useState } from 'react';
import SelectComplete, {SelectTemplate} from 'components/SelectComplete';

import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { CircuitLogistiqueViewModel } from 'openapi-typescript-codegen';
import { useTranslation } from 'react-i18next';
import { getColumnControl } from "validators/ColumnControl/Controls";
import { ColumnControl}  from "validators";

const controls = ["circuit_logistique.tri_doc"].map(getColumnControl);

interface Props extends PropsBlock {
  selectedCircuit: CircuitLogistiqueViewModel;
  onUpdateCircuit: (circuit: CircuitLogistiqueViewModel) => void;
}

const Impression: FunctionComponent<Props> = ({ sm, md, lg, xl, doubleXl, selectedCircuit, onUpdateCircuit }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState<boolean>(false);
  const [loading, setLoader] = useState<boolean>(true);
  const [completude, setCompletude] = useState<number>(0);
  const [circuitLogistique, setCircuitLogistique] = useState<CircuitLogistiqueViewModel>(selectedCircuit);

  const options: SelectTemplate[] = [
    { value: null, label: '---' },
    { value: '1', label: t('enu_circuit_logistique_tri_doc_article') },
    { value: '2', label: t('enu_circuit_logistique_tri_doc_ean') },
    { value: '3', label: t('enu_circuit_logistique_tri_doc_ref_four') }
  ];

  const checkCompletude =() => { setCompletude(ColumnControl.checkAll(circuitLogistique, controls)); };

  const validate = () => ColumnControl.validateAll(circuitLogistique, controls);

  const refreshCircuitLogistique = async () => {
    if (Object.keys(selectedCircuit).length) {
      setCircuitLogistique({ ...selectedCircuit });
      setLoader(false);
    }
  };

  function handleUpdate() {
    validate() && onUpdateCircuit(circuitLogistique);
    setActivate(false);
  }

  function handleCancel() { refreshCircuitLogistique().then(() => { setActivate(false); }); }

  useEffect(() => { checkCompletude(); }, [circuitLogistique]);

  useEffect(() => { selectedCircuit && refreshCircuitLogistique(); }, [selectedCircuit]);

  return (
    <BlockGrid
      title={t("tab_circuit_logistique_impression")}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      handleClick={(activation: boolean) => { setActivate(activation); }}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      completude={completude}
      loading={loading}
    >
      <form className="flex flex-col justify-between">
        <div className="">
          <label className="block mb-2 col-span-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_circuit_logistique_tri_doc")}
                {getColumnControl("circuit_logistique.tri_doc").getValidationLabelElement()}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={options}
              value={options.filter(option => option.value === circuitLogistique?.triDoc)}
              onChange={($event: any): void => setCircuitLogistique({ ...circuitLogistique, triDoc: $event.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("circuit_logistique.tri_doc").getValidationInputClass(activate, true, circuitLogistique)}
              `}
            />
          </label>
          <label className="h-12 mt-1 w-full px-3 border border-slate-300 rounded-md text-sm
          disabled:border-slate-200 disabled:bg-white-500 bg-white-500
          focus:border-store-primary flex">
            <input type="checkbox" tabIndex={5}
              onChange={$event => setCircuitLogistique({ ...circuitLogistique, flPaCde: $event.currentTarget.checked })}
              checked={circuitLogistique?.flPaCde}
              disabled={!activate}
              className="h-4 w-4 block bg-white  border border-black rounded-md  disabled:border-slate-200 disabled:bg-white-500 focus:border-black col-span-2 self-center" />
            <span className="font-medium text-grey-500 flex justify-between text-xs col-span-22 self-center ml-2"><span>{t("lib_circuit_logistique_fl_pa_cde")}</span></span>
          </label>
        </div>
      </form>
    </BlockGrid>
  );
};

export default Impression;