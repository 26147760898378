import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import SelectComplete from 'components/SelectComplete';
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { circuitLogistiquesApi } from 'api';
import { CircuitLogistiqueViewModel } from "openapi-typescript-codegen";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import { useGridController } from "hooks/useGridController";
import { NumberCellRenderer, TextCellRenderer } from "components/AGGride/CellRender";
import { NumberCellEditor } from "components/AGGride/CellEditor";
import { notNilCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { ColDef } from "ag-grid-community";
import { getColumnControl } from "validators/ColumnControl/Controls";


const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
};

interface Props extends PropsBlock {
  selectedCircuit: CircuitLogistiqueViewModel;
  onUpdateCircuit: (circuit: CircuitLogistiqueViewModel) => void;
}

type GridType = { uniteDePort?: string, minimum?: string | number | null; proName: keyof CircuitLogistiqueViewModel; };

const Transport: FunctionComponent<Props> = ({ sm, md, lg, xl, doubleXl, selectedCircuit, onUpdateCircuit }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState<boolean>(false);
  const [hasFocus, setFocus] = useState(false);
  const [loading, setLoader] = useState<boolean>(true);
  const [circuitLogistique, setCircuitLogistique] = useState<CircuitLogistiqueViewModel>(selectedCircuit);

  const [typeTransport] = useState([
    { value: null, label: "..." },
    { value: 'F', label: t('enu_circuit_logistique_type_port_franco') },
    { value: 'A', label: t('enu_circuit_logistique_type_port_avance') },
    { value: 'D', label: t('enu_circuit_logistique_type_port_du') }
  ]);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      fetchData: async () => {
        if (!selectedCircuit.id) return [];
        const { data } = await circuitLogistiquesApi.v1CircuitLogistiquesIdGet(selectedCircuit.id);
        return [
          { id: 1, uniteDePort: t('lib_circuit_logistique_mt_franco'), minimum: data.mtFranco, proName: 'mtFranco' },
          { id: 2, uniteDePort: t('lib_circuit_logistique_nb_article_franco'), minimum: data.nbArticleFranco, proName: 'nbArticleFranco' },
          { id: 3, uniteDePort: t('lib_circuit_logistique_nb_colis_franco'), minimum: data.nbColisFranco, proName: 'nbColisFranco' },
          { id: 4, uniteDePort: t('lib_circuit_logistique_nb_palette_franco'), minimum: data.nbPaletteFranco, proName: 'nbPaletteFranco' },
          { id: 5, uniteDePort: t('lib_circuit_logistique_poids_franco'), minimum: data.poidsFranco, proName: 'poidsFranco' },
          { id: 6, uniteDePort: t('lib_circuit_logistique_volume_franco'), minimum: data.volumeFranco, proName: 'volumeFranco' },
        ];
      },
      postData: async () => { },
      putData: async (row) => {
        if (!circuitLogistique.id) return;
        return await circuitLogistiquesApi.v1CircuitLogistiquesIdPut(selectedCircuit.id, { [row.proName]: row.minimum });
      },
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: "uniteDePort",
          headerName: t('lib_circuit_logistique_mt_franco'),
          headerTooltip: t('lib_circuit_logistique_mt_franco'),
          cellRenderer: TextCellRenderer,
        },
        {
          field: "minimum",
          headerName: t('lib_circuit_logistique_nb_article_franco'),
          headerTooltip: t('lib_circuit_logistique_nb_article_franco'),
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
          },
          valueValidator: [notNilCellValidator, numberCellValidator],
        }
      ],
    }), [selectedCircuit, t])
  );

  const refreshCircuitLogistique = async () => {
    if (Object.keys(selectedCircuit).length) {
      setCircuitLogistique(selectedCircuit);
      setLoader(false);
    }
  };

  async function handleUpdate() {
    onUpdateCircuit(circuitLogistique);
    await gridController.handleUpdate();
    setActivate(false);
  }

  function handleCancel() { refreshCircuitLogistique().then(() => { setActivate(false); }); }

  function handleFocus(focus: boolean) { setFocus(focus); }

  useEffect(() => {
    refreshCircuitLogistique();
    gridController.fetchData();
  }, [selectedCircuit]);

  return (
    <BlockGrid
      title={t('tab_transport_circuit_logistique')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      completude={0}
      loading={loading}
      handleClick={(activation: boolean) => { selectedCircuit && setActivate(activation); }}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      handleFocus={handleFocus}
    >
      <form className="flex flex-col justify-between">
        <div className="w-full">
          <div className="gap-2 flex">
            <label className="block mb-2">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_circuit_logistique_fk_type_transport")}
                  {getColumnControl("circuit_logistique.typeTransport")?.getValidationLabelElement()}
                </span>
              </span>
              <SelectComplete tabIndex={5}
                options={typeTransport}
                value={typeTransport.filter(option => {
                  return option.value === circuitLogistique?.typeTransport;
                })}
                onChange={($event: any): void => setCircuitLogistique({ ...circuitLogistique, typeTransport: $event.value })}
                classNameAdd={`
                  ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${getColumnControl("circuit_logistique.typeTransport")?.getValidationInputClass(activate, hasFocus, circuitLogistique)}
                `}
              />
            </label>
            <label className="block mb-2 flex-1 col-span-4">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_circuit_logistique_frais_franco")}
                  {getColumnControl("circuit_logistique.frais_franco")?.getValidationLabelElement()}
                </span>
              </span>
              <input type="number" tabIndex={5}
                min={0}
                step={0.0001}
                value={circuitLogistique?.fraisFranco ?? 0}
                onChange={$event => setCircuitLogistique({
                  ...circuitLogistique, fraisFranco: Number(Number($event.currentTarget.value).toFixed(4))
                })}
                disabled={!activate}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${getColumnControl("circuit_logistique.frais_franco")?.getValidationInputClass(activate, hasFocus, circuitLogistique)}
                `} />
            </label>
          </div>
          <div className="ag-theme-alpine ag-theme-custom pinned-right-actions h-[295px]">
            <AgGridReact
              ref={gridController.gridRef}
              rowData={gridController.defaultData}
              columnDefs={gridController.columnDefs}
              defaultColDef={defaultColDef}
              headerHeight={40}
              animateRows={true}
              editType={"fullRow"}
              getRowId={(params) => `${params.data.id}`}
              suppressHorizontalScroll={true}
              onGridReady={gridController.onGridReady}
              onFirstDataRendered={gridController.onFirstDataRendered}
              onCellValueChanged={gridController.onCellValueChanged}
              onRowValueChanged={gridController.onRowValueChanged}
              onRowEditingStopped={gridController.onRowEditingStopped}
              rowClassRules={gridController.rowClassRules}
              enableBrowserTooltips={true}
              tooltipShowDelay={0}
            />
          </div>
        </div>
      </form>
    </BlockGrid >
  );
};

export default Transport;