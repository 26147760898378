import { FunctionComponent } from "react";
// import RelationFournisseurConditionnement from "components/Article/RelationFournisseurConditionnement";
import ApprovisionnementTarifaireAchat from 'components/Article/ApprovisionnementTarifaireAchat'
import TaxesAchat from "components/Article/TaxesAchat";

interface Props {
}

const TabAchats: FunctionComponent<Props> = () => {
  return (
    <div id="achat" className="flex flex-wrap justify-between bg-grey-150 gap-px p-px">
      {/* <RelationFournisseurConditionnement sm={100} md={100} lg={100} xl={100} doubleXl={100} /> */}
      <ApprovisionnementTarifaireAchat sm={100} md={100} lg={100} xl={100} doubleXl={100} />
      <TaxesAchat sm={100} md={100} lg={100} xl={100} doubleXl={100} />
    </div>
  );
};

export default TabAchats;
