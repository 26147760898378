import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Tooltip } from "@mui/material";
import { useKeydown, useNotKeydown } from "hooks";

import { articlesApi, marquesApi, typologiesApi } from "api";
import {ArticleViewModel, MarqueInsertionModel, MarqueViewModel, TypologieViewModel} from "openapi-typescript-codegen";
import { useArticle, useUpdateArticle } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateArticle';

import CompletudePresentation from './CompletudePresentation';
import NomenclaturePresentation from './NomenclaturePresentation';
import ProduitPresentation from "./ProduitPresentation";
import SearchBarPresentation from "./SearchBarPresentation";

const defaultImage = "/assets/images/default.png";

interface Loader {
  article: boolean;
  marque: boolean;
  typologie: boolean;
}

const ArticlePresentation: FC = () => {
  const { state: article } = useArticle();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<Loader>({
    article: false,
    marque: false,
    typologie: false,
  });
  const [display, setDisplay] = useState<boolean>(true);
  const [blockKey, setBlockKey] = useState<boolean>(false);
  const [articleSelected, setArticleSelected] = useState<ArticleViewModel>();
  const [typologie, setTypologie] = useState<Partial<TypologieViewModel>>();
  const [marque, setMarque] = useState<Partial<MarqueInsertionModel | MarqueViewModel>>();

  const refresh = useCallback((evt: UpdateEnum) => {
    if (evt === UpdateEnum.designation || evt === UpdateEnum.produit ||
      evt === UpdateEnum.marque || evt === UpdateEnum.caisse) {
      if (article?.id) fetchGetArticle(article.id);
    }
  }, [article?.id, articleSelected]);
  useUpdateArticle(refresh);

  const fetchGetArticle = async (id: number) => {
    const { data: response } = await articlesApi.v1ArticlesIdGet(id);
    setArticleSelected(response);
    setLoading({ ...loading, article: false });
  };

  const fetchGetTypologie = async (id: number) => {
    const { data: response } = await typologiesApi.v1TypologiesIdGet(id);
    setTypologie(response);
  };

  const fetchGetMarque = async (id: number) => {
    const { data: response } = await marquesApi.v1MarquesIdGet(id);
    setMarque(response);
    setLoading({ ...loading, marque: false });
  };

  useEffect(() => {
    if (articleSelected?.fkTypologie) fetchGetTypologie(articleSelected.fkTypologie);
    else setTypologie({});
  }, [articleSelected?.fkTypologie]);

  useEffect(() => {
    if (articleSelected?.fkMarque) fetchGetMarque(articleSelected.fkMarque);
    else setMarque({});
  }, [articleSelected?.fkMarque]);

  useEffect(() => {
    if (article?.id) fetchGetArticle(article.id);
  }, [article?.id]);

  function handleDisplay() {
    if (!blockKey) setDisplay(!display);
  }

  function handleBlock() {
    setBlockKey(true);
  }

  useKeydown(" ", handleDisplay);
  useNotKeydown(" ", handleBlock);

  return (
    <div className="relative">
      <div className={`bg-grey-150 transition-all ${!display && "h-px"}`}></div>
      <button tabIndex={3} onClick={() => setDisplay(!display)} style={{ left: 'calc(50% - 16px)', zIndex: 2 }}
        className={`absolute -bottom-2 h-4 w-7 rounded-lg flex justify-center items-center
          border border-black
          stroke-black bg-white-500
          hover:bg-black hover:stroke-white-500
          focus:bg-black focus:stroke-white-500
          ${!!display && "rotate-180"} transition-all`}
      >
        <svg viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-2">
          <path d="M1 1L5.5 5.5L10 1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>

      <div id="presentation-article"
        style={{ height: display ? "170px" : "0px" }}
        className={`col-span-full w-full rounded-md border-grey-150 bg-grey-150 relative
          ${display ? "border" : "overflow-hidden"} transition-all flex gap-px relative`}
      >
        <div className="aspect-square">
          <div className="h-full w-full p-2 bg-white-500 relative flex justify-center rounded-l-md">
            {/* {loading.article ? <Loader /> : */}
            <div
              style={{ backgroundImage: `url(${articleSelected?.image || defaultImage})` }}
              className="bg-local h-full w-full max-w-full bg-center bg-no-repeat bg-contain"
            ></div>
            {/* } */}
            {marque?.logoMarque && (
              <div className="h-12 w-12 object-contain bottom-1 right-1 absolute rounded bg-white-500/80 p-1 shadow flex justify-center">
                {/* {loading.marque ? <Loader /> : */}
                <div
                  style={{ backgroundImage: `url(${marque?.logoMarque})` }}
                  className="bg-local h-full w-full max-w-full bg-center bg-no-repeat bg-contain"
                ></div>

                {/* } */}
              </div>
            )}
          </div>
        </div>

        <div className="w-full">
          <div className="grid grid-rows-4 h-full gap-px">
            <div className="row-span-1 grid grid-cols-24 gap-px">
              <SearchBarPresentation />
              <div className="col-span-21 h-full">
                <div className="grid grid-cols-24 h-full gap-px">
                  <div className="px-2 flex justify-left align-left col-span-16 bg-white-500 gap-2">
                    <Tooltip title={
                      articleSelected?.designationStandard ||
                      articleSelected?.designationLong ||
                      articleSelected?.designationCourt ||
                      articleSelected?.designationCaisse || ""
                    } followCursor>
                      <h4 className="self-center font-semibold text-ellipsis whitespace-nowrap w-fit overflow-hidden">
                        {articleSelected?.designationStandard ||
                          articleSelected?.designationLong ||
                          articleSelected?.designationCourt ||
                          articleSelected?.designationCaisse}
                      </h4>
                    </Tooltip>
                    {!!marque?.nomMarque && !!typologie?.code &&
                      <Tooltip title={(
                        (marque?.nomMarque ? `${marque?.nomMarque} ` : '')
                        + (typologie?.nomTypologie ? `( ${typologie?.nomTypologie} )` : ''))
                        || ''} followCursor >
                        <span className="self-center font-semibold text-ellipsis whitespace-nowrap overflow-hidden border-l border-gray-200 pl-2">
                          {marque?.nomMarque} {!!typologie?.code && `(${typologie?.code})`}
                        </span>
                      </Tooltip>
                    }
                  </div>
                  <CompletudePresentation />
                </div>
              </div>
            </div>
            <ProduitPresentation articleSelected={articleSelected} />
            <NomenclaturePresentation onClick={() => navigate("general#block-nomenclature-article")} />
          </div>
        </div>
      </div>
      {/* <button tabIndex={3} style={{left: 'calc(50% - 0.625rem)'}}
          className={`absolute -top-2 h-5 w-8 rounded-lg bg-black stroke-grey-200 hover:bg-white-500 p-2`}
          onClick={() => setDisplay(!display)}
        >
        <svg viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg"
          className={`${ display ? "" : "rotate-180" } transition-all h-2 items-align`}>
          <path d="M1 1L5.5 5.5L10 1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button> */}
    </div>
  );
};

export default ArticlePresentation;
