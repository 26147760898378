import { FunctionComponent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { cadencierFournisseursApi } from "api";
import {
  CadencierFournisseurViewModel, CadencierNomenclatureWithRelationsViewModel,
  EnumOrderCadenceType, SiteWithTiersViewModel
} from "openapi-typescript-codegen";

import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { TextInput } from "components/InputWrapper";
import { SelectTemplate } from "components/SelectComplete";

import { api } from "utils";

import { trimOrNullValidator } from "validators";

interface Props extends PropsBlock {
  selectedCadencier: CadencierFournisseurViewModel;
  onUpdateCadencier: (cadencier: CadencierFournisseurViewModel) => void;
}

const CodeCadencier: FunctionComponent<Props> = ({ selectedCadencier, onUpdateCadencier }) => {
  // Translation utility:
  const { t } = useTranslation();

  // BlockGrid related states:
  const [activate, setActivate] = useState<boolean>(false);
  const [loading, setLoader] = useState<boolean>(true);

  // States for the cadencier and the nomenclature:
  const [cadencier, setCadencier] = useState<CadencierFournisseurViewModel>(selectedCadencier);
  const [nomenclature, setNomenclature] = useState<CadencierNomenclatureWithRelationsViewModel>();
  const [sites, setSites] = useState<SiteWithTiersViewModel[]>([]);

  // Options for the select:
  const types: SelectTemplate[] = [
    { value: EnumOrderCadenceType.CircuitLogistique, label: t('enu_cadencier_fournisseur_circuit_logistique') },
    { value: EnumOrderCadenceType.Nomenclature, label: t('enu_cadencier_fournisseur_nomenclature') },
    { value: EnumOrderCadenceType.ListeArticle, label: t('enu_cadencier_fournisseur_liste_article') },
  ];

  /*
    * Refresh the data, and set the nomenclature if needed.
   */
  const refreshData = async () => {
    setLoader(true);
    if (Object.keys(selectedCadencier).length) {
      // Temporary: If the cadencier has no type, it is updated with the default one:
      const { type, flTousSites } = selectedCadencier;
      !(type && Object.values(EnumOrderCadenceType).includes(type)) && onUpdateCadencier({
        ...selectedCadencier,
        type: EnumOrderCadenceType.ListeArticle
      });
      setCadencier(selectedCadencier);
      if (type === EnumOrderCadenceType.Nomenclature) {
        const $nomenclature = await api.data.get<CadencierNomenclatureWithRelationsViewModel>(
          cadencierFournisseursApi.v1CadencierFournisseursIdNomenclatureGet(selectedCadencier.id as number)
        );
        setNomenclature($nomenclature);
      }
      if (!flTousSites) {
        const $sites = await api.dataset.get<SiteWithTiersViewModel>(cadencierFournisseursApi.v1CadencierFournisseursIdSitesGet(selectedCadencier.id as number));
        setSites($sites);
      }
    }
    setLoader(false);
  };

  /*
    * Validate the inputs.
   */
  function validate() {
    const fields = ["nomCadencierFournisseur"];
    return cadencier && fields.every(field => trimOrNullValidator(
      cadencier.nomCadencierFournisseur,
      'lib_cadencier_fournisseur_nom_cadencier_fournisseur',
      { state: cadencier, dispatch: setCadencier, field }
    )
    );
  }

  /*
    * Call the  function to update the cadencier.
    * Update is handled by the parent component.
   */
  function handleUpdate() {
    if (validate()) {
      onUpdateCadencier(cadencier as CadencierFournisseurViewModel);
      setActivate(false);
    }
  }

  /*
    * Refresh the data when cancelling the edition.
   */
  function handleCancel() {
    refreshData().then(() => setActivate(false));
  }

  useEffect(() => {
    refreshData();
  }, [selectedCadencier]);

  // @ts-ignore
  return (
    <BlockGrid
      title={t('lib_cadencier_fournisseur_code')}
      // completude={1}
      toActivate={activate}
      loading={loading}
      handleClick={(activation: boolean) => setActivate(activation)}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
    >
      <form className="flex flex-col">
        <div className="grid grid-cols-12 gap-4">
          <TextInput labelClassAdd="col-span-2 self-start"
            label={t("lib_cadencier_fournisseur_code")}
            value={cadencier!}
            field="code"
            required={true}
            disabled={true}
            inputClassAdd={"store-disabled"}
          />
          <TextInput labelClassAdd="col-span-4 self-start"
            label={t("lib_cadencier_fournisseur_nom_cadencier_fournisseur")}
            value={cadencier}
            field="nomCadencierFournisseur"
            setValue={setCadencier}
            disabled={!activate}
            required={true}
          />
          <TextInput labelClassAdd="col-span-4 self-start"
            label={t("lib_cadencier_fournisseur_type")}
            value={types.find((option) => cadencier && option.value === cadencier.type)?.label}
            disabled={true}
            required={true}
          />
          <label className="col-span-1 h-8 mt-[22px] w-full px-3 rounded-md text-sm bg-blue-store
            disabled:border-slate-200 disabled:bg-white-500
            focus:brightness-110 hover:brightness-110 flex">
            <input type="checkbox" tabIndex={5}
                   disabled={!activate}
                   onChange={$event => setCadencier({ ...cadencier, flActif: $event.currentTarget.checked })}
                   checked={cadencier?.flActif}
                   className="h-4 w-4 min-h-[1rem] min-w-[1rem] block bg-white-500 rounded-md self-center accent-white-500 disabled:accent-white-500" />
            <span className="font-medium text-white-500 flex justify-between text-xxs col-span-22 self-center ml-2 leading-4"><span>{t("lib_cadencier_fournisseur_fl_actif")}</span></span>
          </label>
          <label className="col-span-1 h-8 mt-[22px] w-full px-3 rounded-md text-sm bg-blue-store
            disabled:border-slate-200 disabled:bg-white-500  cursor-default pointer-events-none
            focus:brightness-110 hover:brightness-110 flex">
            <input type="checkbox" tabIndex={5}
                   disabled={true}
                   checked={cadencier?.flTousSites}
                   className="h-4 w-4 min-h-[1rem] min-w-[1rem] block bg-white-500 rounded-md self-center accent-white-500 disabled:accent-white-500 cursor-default" />
            <span className="font-medium text-white-500 flex justify-between text-xxs col-span-22 self-center ml-2 leading-4"><span>{t("lib_cadencier_fournisseur_fl_tous_sites")}</span></span>
          </label>
        </div>
        {(selectedCadencier.type === EnumOrderCadenceType.Nomenclature && nomenclature) && (
          <>
            <hr className="my-2" />
            <div className="col-span-12 items-center pt-3 pb-1 h-10">
              <div className="text-grey-500 font-bold text-sm">{t('lib_cadencier_fournisseur_nomenclature')}</div>
            </div>
            <div className="grid grid-cols-5 gap-4">
              <TextInput labelClassAdd="col-span-1 self-start"
                label={t("lib_cadencier_enseigne")}
                value={`${nomenclature.enseigne?.code} - ${nomenclature.enseigne?.nomEnseigne}`}
                disabled={true}
              />
              {!!nomenclature?.departement && (
                <TextInput labelClassAdd="col-span-1 self-start"
                  label={t("lib_cadencier_departement")}
                  value={`${nomenclature.departement?.code} - ${nomenclature.departement?.nomDepartement}`}
                  disabled={true}
                />
              )}
              {!!nomenclature?.rayon && (
                <TextInput labelClassAdd="col-span-1 self-start"
                  label={t("lib_cadencier_rayon")}
                  value={`${nomenclature.rayon?.code} - ${nomenclature.rayon?.nomRayon}`}
                  disabled={true}
                />
              )}
              {!!nomenclature?.famille && (
                <TextInput labelClassAdd="col-span-1 self-start"
                  label={t("lib_cadencier_famille")}
                  value={`${nomenclature.famille?.code} - ${nomenclature.famille?.nomFamille}`}
                  disabled={true}
                />
              )}
              {!!nomenclature?.sousFamille && (
                <TextInput labelClassAdd="col-span-1 self-start"
                  label={t("lib_cadencier_sous_famille")}
                  value={`${nomenclature.sousFamille?.code} - ${nomenclature.sousFamille?.nomSf}`}
                  disabled={true}
                />
              )}
            </div>
          </>
        )}
        { (!selectedCadencier.flTousSites && !!sites.length) && (
          <>
            <hr className="my-2" />
            <div className="col-span-12 items-center pt-3 pb-1 h-10">
              <div className="text-grey-500 font-bold text-sm">{t('lib_cadencier_fournisseur_affectation')}</div>
            </div>
            { sites.map((site) => (
              <TextInput value={`${site.tier?.nomTiers} - ${site.typeSite}`} disabled={true} />
            ))}
          </>
        )}
      </form>
    </BlockGrid>
  );
};

export default CodeCadencier;