import { useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColumnApi, GridApi, GridOptions, IServerSideDatasource, IServerSideGetRowsParams, IServerSideGetRowsRequest } from "ag-grid-community";
import { ColumnSearchRequestModel, Method } from "openapi-typescript-codegen";
import cs from "classnames";

import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { CustomColDef, FilterModel, RowDataType, ServerSortAlgo } from "components/AGGride/gridTypes";
import { showPrettyError } from "utils";

type ServerQueryType = Record<string, ColumnSearchRequestModel>;

export type ServerSideGetRowsParams<T> = {
  request: IServerSideGetRowsRequest;
  api: GridApi<T>;
  columnApi: ColumnApi;
  context: any;
};

export type ServerSideGetRowsCallback<T> =
  (params: ServerSideGetRowsParams<T>, options: { page: number, amount: number; query: ServerQueryType; }) => Promise<{ rowData: RowDataType<T>[]; rowCount?: number; }>;

export type ServerSideGetGroupRowsCallback<T> =
  (params: ServerSideGetRowsParams<T>, options: { rowGroupIds: string[], parentData: RowDataType<T> | null; }) => Promise<{ rowData: RowDataType<T>[]; rowCount?: number; }>;

type ServerSideGridProps<T> = {
  className?: string;
  getRows: ServerSideGetRowsCallback<RowDataType<T>>;
  getGroupRows?: ServerSideGetGroupRowsCallback<RowDataType<T>>;
  columnDefs: CustomColDef<T>[];
} & Omit<GridOptions<RowDataType<T>>, 'columnDefs'>;

const ServerSideGrid = <GridType,>({ className, getRows, getGroupRows, defaultColDef, ...props }: ServerSideGridProps<GridType>) => {
  const [defaultData] = useState<RowDataType<GridType>[]>([]);

  // https://www.ag-grid.com/react-data-grid/server-side-model-infinite-scroll/
  const datasource = useMemo((): IServerSideDatasource => ({
    getRows: async (params: IServerSideGetRowsParams<RowDataType<GridType>>) => {

      // Get groupes
      if (params.request.groupKeys.length) {
        if (!getGroupRows) {
          params.fail();
          return;
        }
        const rowGroupIds = params.request.rowGroupCols.map(v => v.id);
        try {
          const newRows = await getGroupRows(params, { rowGroupIds, parentData: params.parentNode?.data || null });
          params.success({ rowData: newRows.rowData, rowCount: newRows.rowCount });
        } catch (err) {
          showPrettyError(err);
          params.fail();
        }
        return;
      }

      const columnDefs = params.api.getColumnDefs() as CustomColDef<GridType>[] || [];
      const query: ServerQueryType = {};

      // Get filters
      const filterModel: FilterModel = params.request.filterModel || {};
      for (const [colId, filter] of Object.entries(filterModel)) {
        const columnDef = params.api.getColumnDef(colId) as CustomColDef<GridType> | null;
        
        const q = query[colId] || {};
        q.search = {
          method: columnDef?.customDefs?.serverFilterMethod ?? Method.Contain,
          value: filter.filter,
        };
        query[colId] = q;
      }

      // Get sorts
      let hasSort = false;
      for (const sort of params.request.sortModel) {
        hasSort = true;
        let colDef = columnDefs.find(v => v.colId === sort.colId);
        if (!colDef) colDef = columnDefs.find(v => v.field === sort.colId);
        const sortAlgo = colDef?.customDefs?.serverSortAlgo ?? ServerSortAlgo.String;

        const q = query[sort.colId] || {};
        q.order = sort.sort === 'desc' ? sortAlgo.desc : sortAlgo.asc;
        query[sort.colId] = q;
      }

      // Get default sort
      const defaultSort = columnDefs.find(v => v.customDefs?.defaultServerSort != null);
      if (!hasSort && defaultSort) {
        const q = query[defaultSort.colId || defaultSort.field!] || {};
        q.order = defaultSort.customDefs!.defaultServerSort;
        query[defaultSort.colId || defaultSort.field || 'unknown'] = q;
      }

      // Get pagination
      const { startRow = 0, endRow = 100 } = params.request;
      const amount = Math.max(endRow - startRow, 1);
      const page = Math.max((startRow / amount) + 1, 1);

      try {
        const newRows = await getRows(params, { page, amount, query });
        params.success({ rowData: newRows.rowData, rowCount: newRows.rowCount });
      } catch (err) {
        showPrettyError(err);
        params.fail();
      }
    },
  }), [getRows, getGroupRows]);

  const localDefaultColDef = useMemo((): CustomColDef<GridType> => {
    if (defaultColDef) return { ...AG_GRID_DEFAULT_COLUMN_NEW as CustomColDef<any>, ...defaultColDef };
    return AG_GRID_DEFAULT_COLUMN_NEW as CustomColDef<any>;
  }, [defaultColDef]);

  return (<div className={cs("ag-theme-alpine ag-theme-custom", className)}>
    <AgGridReact
      getRowId={(params) => `${params.data.id}`}
      rowData={defaultData}
      defaultColDef={localDefaultColDef}

      headerHeight={40}
      animateRows={true}
      suppressHorizontalScroll={true}
      enableBrowserTooltips={true}
      tooltipShowDelay={0}
      suppressClickEdit={true}

      rowModelType={'serverSide'}
      serverSideDatasource={datasource}
      serverSideInfiniteScroll={true}
      serverSideFilterOnServer={true}
      serverSideFilterAllLevels={true} // https://github.com/ag-grid/ag-grid/issues/5670
      serverSideSortAllLevels={true} // https://github.com/ag-grid/ag-grid/issues/5670
      cacheBlockSize={100}
      maxBlocksInCache={-1}

      {...props}
    />
  </div>);
};

export default ServerSideGrid;
