import H1Title, { Title } from "components/H1Title";
import BreadCrumb from "components/Breadcrumb";
import { FunctionComponent } from "react";

const Completude: FunctionComponent = () => {
  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: "Completude",
    },
  ];

  const title: Title = {
    title: "Complétude - Articles",
    icon: "completude",
  };

  return (
    <>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <div>Completude</div>
    </>
  );
};

export default Completude;
