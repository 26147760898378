import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  DeviseViewModel,
  FournisseurWithTierInsertionModel, FournisseurWithTierViewModel, PayViewModel,
  TierInsertionModel,
  TierViewModel
} from "openapi-typescript-codegen";

import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import { devisesApi, fournisseursApi, paysApi, typeFournisseursApi } from "api/";
import { api, env, showPrettyError, toast } from "utils/";
import { FileInput, LabelInputWrapper, SelectInput, TextInput } from "components/InputWrapper";
import Autocomplete from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";
import { trimString } from "utils";
import { getColumnControl } from "validators/ColumnControl/Controls";

interface FournisseurCreateProps {
  setIsOpen: (v: boolean) => void;
}

const FournisseurCreate: FC<FournisseurCreateProps> = ({ setIsOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tier, setTier] = useState<Partial<TierViewModel | TierInsertionModel>>({});
  const [fournisseur, setFournisseur] = useState<Partial<FournisseurWithTierInsertionModel | FournisseurWithTierViewModel>>({ code: '' });

  useEffect(() => {
    devisesApi.v1DevisesSearchPost(false, 1, 1, { code: 'EUR' })
      .then(({ data: { data } }) => {
        if (!data?.[0]?.id) return;
        setTier((old) => ({ ...old, fkDevise: data?.[0].id }));
      });
  }, []);

  /* Search callback for autocomplete */

  const fkTypeFournisseurSearchData = useCallback(async (search: string) => {
    const { data: { data } } = await typeFournisseursApi.v1TypeFournisseursSearchPost(
      true, 1, 20, { nomTypeFournisseur: search, code: search }
    );
    return (data || []).map(val => ({
      value: val.id!,
      label: `${val.nomTypeFournisseur} (${val.code})`,
      filterKeys: [val.nomTypeFournisseur, val.code]
    }));
  }, []);

  const fkDeviseSearchData = useCallback(async (search: string) => {
   const data = await api.dataset.get<DeviseViewModel>(
     devisesApi.v1DevisesSearchPost(true, 1, 20, {nomDevise: search, code: search})
   );
   return api.options.generate(data, { label: "#{nomDevise} (#{code})", filterKeys: ["nomDevise", "code"]}, false);
  }, []);

  const fkPaysSearchData = useCallback(async (search: string) => {
    const data = await api.dataset.get<PayViewModel>(
      paysApi.v1PaysSearchPost(true, 1, 20, { nomPays: search, codePaysAlpha3: search })
    );
    return api.options.generate(data, { label: "#{nomPays} (#{codePaysAlpha3})", filterKeys: ["nomPays", "codePaysAlpha3"]}, false);
  }, []);

  /*
    / Validate the inputs. Display the first error encountered (as a toast) if any.
   */
  function validate($tier?: Partial<TierViewModel>) {
    const validation$fournisseur = [
      "fournisseur.code",
      "fournisseur.fk_type_fournisseur"
    ].map((column) => getColumnControl(column)!.validateInput(fournisseur)).every(result => result);
    const validation$tier =  getColumnControl("tier.raison_sociale")!.validateInput($tier || tier);
    return validation$fournisseur && validation$tier;
  }

  async function handleCreate() {
    let success = false;
    const $tier = { ...tier };
    for (const [key, value] of Object.entries(tier)) {
      // @ts-ignore
      $tier[key] = trimString(value) || null;
    }
    setTier($tier);
    if (!validate($tier)) { return; }
    try {
      await fournisseursApi.v1FournisseursTierPost({
        ...fournisseur,
        code: trimString(fournisseur.code) as string,
        tier: $tier as TierInsertionModel,
      } as FournisseurWithTierInsertionModel);
      toast.success('mes_validation_creation');
      success = true;
    } catch (error) {
      console.error(error);
      showPrettyError(error, { columns: [{ field: "Tier.nomCourtTiers", label: "lib_tiers_nom_court_tiers" }] });
    }
    if (success) {
      navigate(`/app/referencement/fournisseurs/${fournisseur.code}/general`);
    }
  }

  function handleCancel() {
    setIsOpen(false);
  }

  const fetchPaysByName = async (country?: string) => {
    let response;
    if (country) { response = await paysApi.v1PaysNameNameGet(country); }
    else { return null; }
    if (response.status >= 200 && response.status < 300) { return response.data; }
    else { return null; }
  };

  const handlePlaceSelected = async (placeResult: any, refObject: any) => {
    if (!placeResult) return;
    const components: any = {};
    placeResult.address_components?.forEach((component: any) => {
      component.types.forEach((key: string) => { components[key] = component; });
    });
    const adresseTiers = `${components.street_number?.long_name || ""} ${components.route.long_name}`.trim();
    const codePostalTiers = components.postal_code?.long_name;
    const villeTiers = components.locality?.long_name || components.postal_town?.long_name;
    const pays = await fetchPaysByName(components.country.long_name);
    refObject.value = adresseTiers;
    setTier(previous => ({ ...previous, adresseTiers, codePostalTiers, villeTiers, fkPays: pays?.id }));
  };

  return (
    <BlockGrid
      title={t('tit_creation_fiche_fournisseur')}
      layout={LayoutEnum.modal}
      toActivate={true}
      handleUpdate={handleCreate}
      handleCancel={handleCancel}
    >
      <form onSubmit={ev => ev.preventDefault()} className="flex flex-col">
        <div className="grid grid-cols-12 gap-4">

          {/*First row--*/}
          <FileInput labelClassAdd="col-span-4" label={t("lib_tiers_logo")}
            value={tier} field="logo" setValue={() => { }}
            accept="image/*"
          />
          <div className="col-span-8" />

          {/*Second row--*/}
          <TextInput labelClassAdd="col-span-1" label={t("lib_fournisseur_code")}
            value={fournisseur} field="code" setValue={setFournisseur} required={true} />
          <TextInput labelClassAdd="col-span-1" label={t("lib_fournisseur_code_externe")}
            value={tier} field="numTiersExt" setValue={setTier} />
          <TextInput labelClassAdd="col-span-4" label={t("lib_tiers_raison_sociale")}
            value={tier} field="raisonSociale" setValue={setTier} required={true} />
          <TextInput labelClassAdd="col-span-2" label={t("lib_tiers_nom_tiers")}
            value={tier} field="nomTiers" setValue={setTier} />
          <TextInput labelClassAdd="col-span-2" label={t("lib_tiers_nom_court_tiers")}
            value={tier} field="nomCourtTiers" setValue={setTier} />

          <SelectInput className="col-span-2" label={t("lib_fournisseur_type_nom")}
            value={fournisseur} field="fkTypeFournisseur" setValue={setFournisseur}
            getPlaceholder={async (id) => {
              const { data } = await typeFournisseursApi.v1TypeFournisseursIdGet(id);
              return `${data.nomTypeFournisseur} (${data.code})`;
            }}
            searchData={fkTypeFournisseurSearchData}
            required={true}
          />

          {/*Third row--*/}
          <TextInput labelClassAdd="col-span-4" label={t("lib_tiers_tva_intra")}
            value={tier} field="tvaIntra" setValue={setTier} />
          <SelectInput className="col-span-2" label={t("lib_tiers_fk_devise")}
            value={tier} field="fkDevise" setValue={setTier}
            getPlaceholder={async (id) => {
              const { data } = await devisesApi.v1DevisesIdGet(id);
              return `${data.nomDevise} (${data.code})`;
            }}
            searchData={fkDeviseSearchData}
          />
          <div className="col-span-6" />

          {/*Fourth row--*/}
          <LabelInputWrapper classNameAdd={"col-span-6"} label={t("lib_tiers_adresse_tiers")}>
            <Autocomplete
              tabIndex={5}
              className={"h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400 disabled:border-slate-200 disabled:bg-white-500 focus:border-store-primary pr-8"}
              apiKey={env.GOOGLE_MAP_API_KEY}
              onPlaceSelected={(placeResult, refObject) => handlePlaceSelected(placeResult, refObject)}
              onChange={($event: ChangeEvent<HTMLInputElement>) => setTier({ ...tier, adresseTiers: $event.target.value })}
              options={{ types: ["address"], }}
              libraries={["places", "nearbySearch"]}
              placeholder={t('tab_recherche')}
            />
          </LabelInputWrapper>
          <TextInput labelClassAdd="col-span-2" label={t("lib_tiers_ville_tiers")}
            value={tier} field="villeTiers" setValue={setTier}
            type="text" />
          <TextInput labelClassAdd="col-span-2" label={t("lib_tiers_code_postal")}
            value={tier} field="codePostalTiers" setValue={setTier}
            type="text" />
          <SelectInput className="col-span-2" label={t("lib_tiers_fk_pays")}
            value={tier} field="fkPays" setValue={setTier}
            getPlaceholder={async (id) => {
              const { data } = await paysApi.v1PaysIdGet(id);
              return `${data.nomPays} (${data.codePaysAlpha3})`;
            }}
            searchData={fkPaysSearchData}
          />

          {/*Fifth row--*/}
          <TextInput labelClassAdd="col-span-4" label={t("lib_tiers_email_tiers")}
            value={tier} field="emailTiers" setValue={setTier}
            type="email" />
          <TextInput labelClassAdd="col-span-2" label={t("lib_tiers_tel_tiers")}
            value={tier} field="telTiers" setValue={setTier}
            type="tel" />
        </div>
      </form>
    </BlockGrid>
  );
};

export default FournisseurCreate;