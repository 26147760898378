import { useLocation } from 'react-router-dom'

import { useAuth, useCallOnce } from '.'
import { IAuth } from 'context/auth'

export type protectionCondition = ((auth: IAuth) => boolean) | boolean

export function useProtection(
    condition: protectionCondition = (auth: IAuth) => !auth.isLoggedIn || auth.isExpired,
    returnPathname?: string
) {
    const auth = useAuth()
    const location = useLocation()
    const needAuth = typeof condition === 'function' ? condition(auth) : condition

    useCallOnce(!!needAuth, () => auth.logIn(returnPathname ?? location.pathname))

    return !!needAuth
}