import {
  CircuitLogistiqueViewModel,
  FournisseurViewModel,
  LieuFonctionViewModel,
  TemperatureFournisseurViewModel,
  TierViewModel
} from "openapi-typescript-codegen";

import { ColumnControl, ColumnControlInterface } from "validators/ColumnControl";

const CircuitLogistiqueColumnControls: ColumnControlInterface<CircuitLogistiqueViewModel>[] = [
  new ColumnControl("circuit_logistique.code", "code", true),
  new ColumnControl("circuit_logistique.fk_lieu_fonction_commande", "fkLieuFonctionCommande", true),
  new ColumnControl("circuit_logistique.fk_lieu_fonction_reglement", "fkLieuFonctionReglement", true),
  new ColumnControl("circuit_logistique.nom_circuit", "nomCircuit", true),
  new ColumnControl("circuit_logistique.typeTransport", "typeTransport"),
  new ColumnControl("circuit_logistique.origine", "origine"),
  new ColumnControl("circuit_logistique.fk_algorithme_appro", "fkAlgorithmeAppro"),
  new ColumnControl("circuit_logistique.calcul_prix", "calculPrix"),
  new ColumnControl("circuit_logistique.flModifPcbCde", "flModifPcbCde"),
  new ColumnControl("circuit_logistique.flPaCde", "flPaCde"),
  new ColumnControl("circuit_logistique.flGratuit", "flGratuit"),
  new ColumnControl("circuit_logistique.flCdeMultiRay", "flCdeMultiRay"),
  new ColumnControl("circuit_logistique.fl_reliquat", "flReliquat"),
  new ColumnControl("circuit_logistique.fl_enlevement_marchandise", "flEnlevementMarchandise"),
  new ColumnControl("circuit_logistique.fl_remise", "flRemise"),
  new ColumnControl("circuit_logistique.controle_reception", "controleReception"),
  new ColumnControl("circuit_logistique.hors_planning", "horsPlanning"),
  new ColumnControl("circuit_logistique.tri_doc", "triDoc")
];

const FournisseurColumnControls: ColumnControlInterface<FournisseurViewModel>[] = [
  new ColumnControl("fournisseur.code", "code", true),
  new ColumnControl("fournisseur.fk_type_fournisseur", "fkTypeFournisseur", true),
  new ColumnControl("fournisseur.siren", "siren"),
  new ColumnControl("fournisseur.adresse_tiers", "adresseTiers"),
  new ColumnControl("fournisseur.fk_devise", "fkDevise"),
  new ColumnControl("fournisseur.tva_intra", "tvaIntra"),
];

const LieuFonctionColumnControls: ColumnControlInterface<LieuFonctionViewModel>[] = [
  new ColumnControl('lieu_fonction.nom_lieu_fonction', 'nomLieuFonction'),
  new ColumnControl('lieu_fonction.type_lieu_fonction', "typeLieuFonction"),
  new ColumnControl('lieu_fonction.adresse_lieu_fonction', "addresseLieuFonction"),
  new ColumnControl('lieu_fonction.ville_lieu_fonction', "villeLieuFonction"),
  new ColumnControl('lieu_fonction.pays', "fkPays"),
  new ColumnControl('lieu_fonction.code_fiscal', "codeFiscal"),
  new ColumnControl('lieu_fonction.siret', "siret"),
  new ColumnControl('lieu_fonction.gln', "gln"),
  new ColumnControl('lieu_fonction.type_litige', "typeLitige"),
  new ColumnControl('lieu_fonction.fk_condition_reglement', "fkConditionReglement"),
  new ColumnControl('lieu_fonction.fk_mode_reglement', "fkModeReglement"),
  new ColumnControl('lieu_fonction.agence_bancaire', "agenceBancaire"),
  new ColumnControl('lieu_fonction.iban', "iban"),
  new ColumnControl('lieu_fonction.bic', "bic"),
  new ColumnControl('lieu_fonction.fk_type_piece', "fkTypePiece"),
  new ColumnControl('lieu_fonction.fl_assujetti_tva', "flAssujettiTva"),
  new ColumnControl('lieu_fonction.fk_devise', "fkDevise"),
  new ColumnControl('lieu_fonction.type_escompte', "typeEscompte"),
  new ColumnControl('lieu_fonction.taux_ristourne', "tauxRistourne"),
  new ColumnControl('lieu_fonction.num_accise', "numAccise")
];

const TemperatureFournisseurColumnControls: ColumnControlInterface<TemperatureFournisseurViewModel>[] = [
  new ColumnControl('temperature_fournisseur.fk_temperature', "fkTemperature")
];

const TierColumnControls: ColumnControlInterface<TierViewModel>[] = [
  new ColumnControl("tier.raison_sociale", "raisonSociale", true),
  new ColumnControl("tier.num_tiers_ext", "numTiersExt"),
  new ColumnControl("tier.nom_tiers", "nomTiers"),
  new ColumnControl("tier.nom_court_tiers", "nomCourtTiers"),
  new ColumnControl("tier.grpe_actionnariat", "grpeActionnariat"),
]

const ColumnControls: ColumnControlInterface<any>[] = [
  CircuitLogistiqueColumnControls,
  FournisseurColumnControls,
  LieuFonctionColumnControls,
  TemperatureFournisseurColumnControls,
  TierColumnControls
].flat();

export function getColumnControl(column: string): ColumnControl<any> {
  const model = column.includes(".") ? column.split('.')[0] : String();
  return getColumnControlFromModel(column, model);
}

export function getColumnControlFromModel(column: string, model: string): ColumnControl<any> {
  let controls: ColumnControlInterface<any>[];
  switch (model) {
    case "circuit_logistique":
      controls = CircuitLogistiqueColumnControls;
      break;
    case "fournisseur":
      controls = FournisseurColumnControls;
      break;
    case "lieu_fonction":
      controls = LieuFonctionColumnControls;
      break;
    case "temperature_fournisseur":
      controls = TemperatureFournisseurColumnControls;
      break;
    case "tier":
      controls = TierColumnControls;
      break;
    default:
      controls = ColumnControls;
      break;
  }
  // @ts-ignore
  return ColumnControl.get(column, controls);
}