import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  ArticleConstituantInsertionModel,
  ArticleConstituantViewModel,
  ConstituantViewModel
} from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { useTranslation } from "react-i18next";

import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { articleConstituantsApi, articlesApi, constituantsApi } from 'api';
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { NumberCellEditor, SelectorCellEditor } from 'components/AGGride/CellEditor';
import { LinkCellRenderer, LinkCellRendererProps } from 'components/AGGride/CellRender';
import { editableIfNew } from "components/AGGride/cellEditable";
import {
  notEmptyCellValidator,
  notNilCellValidator,
  numberCellValidator,
  percentageCellValidator, ValidateCellFn
} from "components/AGGride/cellValidator";
import { useArticle } from "context/Referencement";
import { useGridController } from "hooks";

import 'app/ag-grid-style.css';

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 90,
};

type GridType = Partial<ArticleConstituantViewModel>;

const Constituants: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const [activate, setActivate] = useState(false);
  const [articleConstituantsOptions, setArticleConstituantsOptions] = useState<ConstituantViewModel[]>([]);
  const sumPercentage = useRef(0);

  const constituantsOptions = useRef<ConstituantViewModel[]>([]);

  const sumValidator: ValidateCellFn = () => {
    return sumPercentage.current > 100 ? { success: false, msg: 'err_constituant_pourcentage' } : { success: true };
  };

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {
        sortColId: "fkConstituant",
        startEditingColId: "fkConstituant",
      },
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.fkConstituant,
      fetchData: async () => {
        if (!article?.id) return [];
        const data = await articlesApi.v1ArticlesIdConstituantsGet(article?.id).then(response => response.data);
        const options = constituantsOptions.current
          .filter(option => !data.some(article => article.fkConstituant === option.id))
          .map(option => ({ ...option, label: `${option.nomConstituant} (${option.code})`.toUpperCase() })) || [];
        setArticleConstituantsOptions(options);
        sumPercentage.current = data.reduce((accumulator, current) => accumulator + Number(current.pourcentageComposition || 0), 0);
        return data;
      },
      postData: (cleanRow) => articleConstituantsApi.v1ArticleConstituantsPost({ ...cleanRow, fkArticle: article?.id as number } as ArticleConstituantInsertionModel),
      putData: (cleanRow) => articleConstituantsApi.v1ArticleConstituantsPut(cleanRow as ArticleConstituantViewModel),
      deleteData: async (dataId) => articleConstituantsApi.v1ArticleConstituantsIdDelete(dataId),
      getNewModel: () => ({
        pourcentageComposition: 1,
      }),
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'fkConstituant',
          headerName: t('lib_article_constituant_code'),
          headerTooltip: t('lib_article_constituant_code'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          editable: editableIfNew,
          tooltipValueGetter: (params) => constituantsOptions.current.find(
            option => typeof option.id === 'number' && option.id === params.data?.fkConstituant)?.code,
          valueFormatter: (params) => constituantsOptions.current.find(
            option => typeof option.id === 'number' && option.id === params.data?.fkConstituant)?.code || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: articleConstituantsOptions.map(option => ({
              value: option.id,
              label: option.nomConstituant ? option.code + ' - ' + option.nomConstituant : option.code
            })),
          },
          valueValidator: [notEmptyCellValidator],
        },
        {
          field: 'ConstituantName',
          headerName: t('lib_article_constituant_nom_constituant'),
          headerTooltip: t('lib_article_constituant_nom_constituant'),
          cellRenderer: LinkCellRenderer,
          cellRendererParams: {
            href: (cellValue: string) => /^\d+/gmi.test(cellValue) ? `/app/referencement/articles/${cellValue}` : null,
            target: '_blank'
          } as LinkCellRendererProps,
          valueGetter: (param: ValueGetterParams) => param.data.fkConstituant,
          valueFormatter: (params) => constituantsOptions.current.find(
            option => typeof option.id === 'number' && option.id === params.value)?.nomConstituant || "",
        },
        {
          field: 'pourcentageComposition',
          headerName: t('lib_article_constituant_pourcentage_constituant'),
          headerTooltip: t('lib_article_constituant_pourcentage_constituant'),
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 100,
          },
          onCellValueChanged: (params) => {
            sumPercentage.current += (params.newValue - params.oldValue);
          },
          valueValidator: [notNilCellValidator, numberCellValidator, percentageCellValidator, sumValidator],
        },
      ],
    }), [activate, articleConstituantsOptions, article, t])
  );

  useEffect(() => {
    loadOptions();
  }, []);

  async function loadOptions() {
    const { data: response } = await constituantsApi.v1ConstituantsGet(1, 100);
    const { data } = response;
    constituantsOptions.current = data || [];
  }

  return (
    <BlockGrid
      title={t('tab_article_constituant')} sm={sm} md={md} lg={lg} xl={xl}
      doubleXl={doubleXl}
      loading={gridController.isLoading}
      handleClick={(res: boolean) => {
        setActivate(res);
        setTimeout(gridController.activateContextActionButtons, 50);
      }}
      toActivate={activate}
      disableCreate={gridController.hasEmptyLines || !articleConstituantsOptions.length}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default Constituants;