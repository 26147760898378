import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export interface Title {
  title: string;
  icon?: string;
}

interface Props {
  content: Title;
}

const H1Title: FunctionComponent<Props> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row gap-2 items-center">
      <div className="flex flex-row w-10 h-10 bg-grey-100 rounded-full group-hover:rounded-lg transition-[borderRadius] duration-200">
        {!!content.icon &&
          <img
            className="w-8 h-8 m-auto"
            width="40px"
            height="40px"
            alt={content.icon}
            src={`/assets/icons/${content.icon}.png`}
          />
        }
      </div>
      <h1 className="text-grey-500 font-poppins not-italic font-bold text-2xl">
        {t(`${content.title}`)}
      </h1>
    </div>
  );
};

export default H1Title;
