import { FC, useEffect, useMemo, useState } from 'react';
import { TauxChangeViewModel } from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";

import { tauxChangesApi } from 'api';
import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import { useGridController } from "hooks";
import { TextCellRenderer, DateCellRenderer } from "components/AGGride/CellRender";
import { DatePickerCellEditor } from 'components/AGGride/CellEditor';
import { editableIfTodayOrFuture } from "components/AGGride/cellEditable";
import { DateTime } from "luxon";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

type GridTauxChangeType = TauxChangeViewModel;

const HistoriqueTauxDeChange: FC<{ deviseDepart: number | null; deviseArrivee: number | null; }> = ({ deviseDepart, deviseArrivee }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);

  const gridController = useGridController<GridTauxChangeType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: false,
      emptyRowCheck: (rowData) => !rowData.id,
      fetchData: async () => {
        if (!deviseDepart || !deviseArrivee) return [];
        const { data } = await tauxChangesApi.v1TauxChangesHistoryGet(deviseDepart, deviseArrivee);
        return (data);
      },
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'dateChange',
          headerName: t('lib_taux_change_date_change'),
          headerTooltip: t('lib_taux_change_date_change'),
          editable: editableIfTodayOrFuture,
          maxWidth: 210,
          cellRenderer: DateCellRenderer,
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
            min: DateTime.now(),
            max: DateTime.fromISO('2999-12-31T00:00:00')
          },
        },
        {
          field: 'tauxConversion',
          tooltipField: 'code',
          headerName: t('lib_taux_change_taux_conversion'),
          headerTooltip: t('lib_taux_change_taux_conversion'),
          cellRenderer: TextCellRenderer,
          editable: false,
          filter: 'agTextColumnFilter',
        },
        {
          field: 'base',
          tooltipField: 'code',
          headerName: t('lib_taux_change_base'),
          headerTooltip: t('lib_taux_change_base'),
          cellRenderer: TextCellRenderer,
          editable: false,
          filter: 'agTextColumnFilter',
        },
      ],
    }), [activate, t, deviseDepart, deviseArrivee])
  );

  useEffect(() => {
    if (deviseDepart && deviseArrivee) {
      gridController.fetchData();
    }
  }, [deviseArrivee]);

  return (
    <div className="flex-[2]">
      <div className="mb-2 text-grey-500 font-bold">{t('tab_histo_taux_change')}</div>
      <BlockGrid
        layout={LayoutEnum.settings}
        handleClick={(res: boolean) => {
          setActivate(res);
          setTimeout(gridController.activateContextActionButtons, 50);
        }}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-modal">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType={"fullRow"}
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </div>
  );
};

export default HistoriqueTauxDeChange;