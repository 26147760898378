import { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { GridOptions, RowClassRules } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import cs from "classnames";

import { CustomColDef, RowDataType } from "components/AGGride/gridTypes";
import { GridController } from "hooks/useGridControllerV2";
import { UpdateDeleteButtonsCellRenderer } from "./CellRender";
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";

type ClientSideGridProps<GridType> = {
  className?: string;
  gridController?: GridController<GridType>;
  columnDefs: CustomColDef<GridType>[];
} & Omit<GridOptions<RowDataType<GridType>>, 'columnDefs'>;

/** @description Experimental AG Grid client datatable */
const ClientSideGrid = <GridType,>(
  {
    className, gridController, columnDefs,
    rowClassRules, defaultColDef,
    onGridReady, onFirstDataRendered, onCellValueChanged, onCellEditingStopped, onRowEditingStopped,
    ...props
  }: ClientSideGridProps<GridType>
) => {
  const { t } = useTranslation();
  const [defaultData] = useState<RowDataType<GridType>[]>([]);

  const localColumnDefs = useMemo(() => {
    const _columnDefs = [...(columnDefs)];
    if (gridController?._options.floatingAction !== 'none')
      _columnDefs.push({
        field: "context_action_buttons",
        headerName: t("men_aggrid_extra_action"),
        headerTooltip: t("men_aggrid_extra_action"),
        width: 50,
        minWidth: 50,
        hide: gridController?._options.floatingAction === 'hover',
        cellRenderer: UpdateDeleteButtonsCellRenderer,
        cellRendererParams: {
          onClickDelete: (rowDataId?: number, rowId?: string) => rowDataId && rowId && gridController?.handleDelete(rowId, rowDataId),
        },
      });
    return _columnDefs;
  }, [columnDefs, gridController?._options, gridController?.handleDelete, t]);

  const localRowClassRules = useMemo((): RowClassRules<RowDataType<GridType>> => {
    return {
      'ag-row-warning': (params) => {
        const lastValidation = Object.values(params.data?._customDataProps?.validationSuccess || {});
        return lastValidation.some(val => val === false);
      },
      'ag-row-error': (params) => {
        return params.data?._customDataProps?.serverError === true;
      },
      ...(rowClassRules ?? {})
    };
  }, []);

  const localDefaultColDef = useMemo((): CustomColDef<GridType> => {
    if (defaultColDef) return { ...AG_GRID_DEFAULT_COLUMN_NEW as CustomColDef<any>, ...defaultColDef };
    return AG_GRID_DEFAULT_COLUMN_NEW as CustomColDef<any>;
  }, [defaultColDef]);

  useEffect(() => {
    if (gridController?.gridRef.current?.api && gridController._options.floatingAction === 'hover') {
      gridController.activateContextActionButtons();
    }
  }, [localColumnDefs]);

  return (<div className={cs("ag-theme-alpine ag-theme-custom pinned-right-actions", className)}>
    <AgGridReact
      ref={gridController?.gridRef}
      getRowId={(params) => `${params.data.id}`}
      rowData={defaultData}
      columnDefs={localColumnDefs}
      rowClassRules={localRowClassRules}
      defaultColDef={localDefaultColDef}

      headerHeight={40}
      animateRows={true}
      suppressHorizontalScroll={true}
      enableBrowserTooltips={true}
      tooltipShowDelay={0}

      onGridReady={(ev) => { gridController?.onGridReady(ev); onGridReady?.(ev); }}
      onFirstDataRendered={(ev) => { gridController?.onFirstDataRendered(ev); onFirstDataRendered?.(ev); }}
      onCellValueChanged={(ev) => { gridController?.onCellValueChanged(ev); onCellValueChanged?.(ev); }}
      onCellEditingStopped={(ev) => { gridController?.onCellEditingStopped(ev); onCellEditingStopped?.(ev); }}
      onRowEditingStopped={(ev) => { gridController?.onRowEditingStopped(ev); onRowEditingStopped?.(ev); }}

      {...props}
    />
  </div>);
};

export default ClientSideGrid;