import { DateTime } from "luxon";

import { RowDataAny, RowDataType } from "components/AGGride/gridTypes";

type CustomEditableCallbackParams = { data?: RowDataType, colDef?: { field?: string; }; };

export const editableIfNew = (params: CustomEditableCallbackParams): boolean => {
    return !!params.data?._customDataProps?.isNew;
};

export const editableIfTodayOrFuture = (params: CustomEditableCallbackParams, field?: string): boolean => {
    const data: unknown = (params.data as RowDataAny)?.[field ?? params?.colDef?.field!];
    if (!data
        || typeof data !== 'string'
        || params.data?._customDataProps?.modified?.[params?.colDef?.field!]) {
        return true;
    }

    const date = DateTime.fromISO(data);
    const now = DateTime.now();

    return !date.isValid
        || date.toISODate() === now.toISODate()
        || date.toMillis() >= now.toMillis();
};

export const editableIfFuture = (params: CustomEditableCallbackParams, field?: string): boolean => {
    const data: unknown = (params.data as RowDataAny)?.[field ?? params?.colDef?.field!];
    if (!data
        || typeof data !== 'string'
        || params.data?._customDataProps?.modified?.[params?.colDef?.field!]) {
        return true;
    }
    const date = DateTime.fromISO(data);
    const now = DateTime.now();

    return !date.isValid || date.toMillis() >= now.toMillis();
};