import { t } from "i18next";

import { CustomColDef, CustomColGroupDef } from "components/AGGride/gridTypes";
import { CheckedCellRenderer } from "./CellRender";
import { CheckboxFilter } from "./CellFilter";

export function CheckBoxColumnDef<GridType>(
    field: string,
    header: string,
    properties?: CustomColDef<GridType> | CustomColGroupDef<GridType>
): (CustomColDef<GridType> | CustomColGroupDef<GridType>) {
    return {
        field: field,
        headerName: t(header),
        headerTooltip: t(header),
        cellRenderer: CheckedCellRenderer,
        filter: 'agTextColumnFilter',
        floatingFilterComponent: CheckboxFilter,
        flex: 1,
        ...properties
    };
}
