import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import {
  SousFamilleInsertionModel,
  SousFamilleViewModel,
  TvaWithTauxViewModel
} from "openapi-typescript-codegen";
import {sousFamillesApi, tvasApi} from "api";
import BlockGrid, { LayoutEnum, PropsBlock } from 'components/BlockGrid';
import {DateInput, NumberInput, SelectInput, TextInput} from "components/InputWrapper";
import NomenclatureContext from "../NomenclatureContext";
import {api, showPrettyError, toast} from "utils";
import {SelectTemplate} from "../../../components/SelectComplete";

interface Props extends PropsBlock {
  setIsOpen?: (v: boolean) => void;
  isOpen?: boolean;
  data?: SousFamilleViewModel;
}

const RayonModal: FunctionComponent<Props> = ({ setIsOpen, isOpen, data }) => {
  const { t } = useTranslation();
  const { nomenclature, setNomenclature, setRefresh } = useContext(NomenclatureContext);
  const [sousFamille, setSousFamille] = useState<Partial<SousFamilleViewModel>>({});

  const [tvas, setTvas] = useState<SelectTemplate[]>([]);

  const getTvas = async () => {
    const data = await api.dataset.get<TvaWithTauxViewModel[]>(tvasApi.v1TvasTauxGet());
    return api.options.generate(data, { label: "#{code} - #{nomTva} (#{taux}%)" }, true);
  }

  useEffect(() => {
    if (isOpen) {
      data ? setSousFamille(data) : setSousFamille({ fkFamille: nomenclature.famille as number });
      !tvas.length && getTvas().then(setTvas);
    }
  }, [isOpen]);

  function isUpdate() { return sousFamille.hasOwnProperty("id"); }

  async function handleUpdate() {
    if (isUpdate()) {
      try {
        await sousFamillesApi.v1SousFamillesPut(sousFamille as SousFamilleViewModel);
        toast.success(t("mes_validation_modification"));
        setIsOpen && setIsOpen(false);
      } catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    } else { await handleCreate(); }
  }

  async function handleCreate() {
    try {
      const { data: $sousFamille } = await sousFamillesApi.v1SousFamillesPost(sousFamille as SousFamilleInsertionModel);
      setNomenclature({ ...nomenclature, sousFamille: $sousFamille.id, uniteBesoin: null });
      toast.success(t("mes_validation_creation"));
      setIsOpen && setIsOpen(false);
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  }

  return (
    <BlockGrid
      layout={LayoutEnum.modal}
      title={isUpdate() ? t('tit_modification_sous_famille') : t('tit_creation_sous_famille')}
      informations={''}
      toActivate={true}
      handleUpdate={async () => { await handleUpdate().then(() => { setRefresh(true); }); }}
      handleCancel={() => { setIsOpen && setIsOpen(false); }}
    >
      <form onSubmit={$event => $event.preventDefault()} className="flex flex-col">
        <div className="grid grid-cols-6 gap-4">
          <NumberInput labelClassAdd="col-span-1" label={t("lib_sous_famille_code")}
                       value={sousFamille} field="code" setValue={setSousFamille} required={true} min={1}
          />
          <TextInput labelClassAdd="col-span-5" label={t("lib_sous_famille_nom_sous_famille")}
                     value={sousFamille} field="nomSf" setValue={setSousFamille} required={true} />
          <SelectInput className="col-span-6" label={t("lib_sous_famille_fk_tva_principale_sous_famille")}
                       value={sousFamille} field="fkTvaPrincipaleSf" setValue={setSousFamille}
                       getPlaceholder={async () => tvas.length ?  tvas[0].label! : "---"}
                       required={false}
                       searchData={async () => tvas}
          />
          { isUpdate() &&
            <>
                <div className={"col-span-4"}></div>
                <DateInput labelClassAdd="col-span-2" label={t("lib_sous_famille_date_maj")}
                           value={sousFamille} field="dateMaj"  disabled={true} />
            </>
          }
        </div>
      </form>
    </BlockGrid>
  );
};

export default RayonModal;