import { ReactNode, FC, createContext, useContext, useState, useMemo, useEffect } from 'react';
// import { DateTime } from 'luxon';

import { useLocalStorage } from "hooks";
import OAuthWrapper, { OAuth } from 'api/oauth.wrapper';

export type Credentials = OAuth.Credentials;

type OnLogIn = (oauthData: { code: string, state: string; }, callback?: (nextPath: string) => void) => Promise<void>;

export interface IAuth {
  credentials: Credentials | null;
  isLoggedIn: boolean;
  isExpired: boolean;
  role: string;
  logIn: (nextPath: string) => Promise<void>;
  onLogIn: OnLogIn;
  logOut: () => void;
}

const AuthContext = createContext<IAuth>({} as IAuth);

const AuthProvider: FC<{ children?: ReactNode; }> = ({ children }) => {
  const [credentials, setCredentials] = useLocalStorage<Credentials>('credentials');
  const [state, setState] = useState({
    isLoggedIn: !!credentials?.access_token,
    role: 'test'
  });

  useEffect(() => {
    if (!!credentials?.access_token !== state.isLoggedIn) {
      setState(prevState => ({
        ...prevState,
        isLoggedIn: !!credentials?.access_token
      }));
    }
  }, [credentials]);

  const logIn = async (nextPath: string) => {
    const authURL = await OAuthWrapper.initLogin(nextPath);
    globalThis.location.replace(authURL);
  };

  const onLogIn: OnLogIn = async ({ code, state }, callback) => {
    const { nextPath, credentials } = await OAuthWrapper.oauthRedirect(code, state);
    setCredentials(credentials);
    if (callback) callback?.(nextPath || '/');
  };

  const logOut = () => {
    setCredentials(null);
  };

  const isExpired = useMemo(() => {
    if (!state.isLoggedIn)
      return true;
    return false;
    // TODO: manage expired token
    // const result = DateTime.fromISO(credentials.expires_in).diffNow('milliseconds').milliseconds <= 0;
    // return result;
  }, [state.isLoggedIn, credentials]);

  const context: IAuth = { credentials, ...state, isExpired, logIn, onLogIn, logOut };

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  return useContext(AuthContext);
}

export { AuthContext, useAuth };
export default AuthProvider;