import { FC, useState, useMemo, useEffect } from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { TaxeAchatInsertionModel, TaxeAchatViewModel, TaxeAchatViewModelTypeEnum, TaxeViewModel } from "openapi-typescript-codegen";

import { findTypeTaxeAchatTranslationKey } from "pages/Taxe/type";
import BlockGrid, { PropsBlock } from "components/BlockGrid";
import { DateCellRenderer, DateCellRendererProps, TextCellRenderer } from "components/AGGride/CellRender";
import { editableIfFuture, editableIfNew } from "components/AGGride/cellEditable";
import { AutoCompleteCellEditor, AutoCompleteCellEditorProps, DatePickerCellEditor, DatePickerCellEditorProps, NumberCellEditor, SelectorCellEditor } from "components/AGGride/CellEditor";
import { dateCellValidator, notEmptyCellValidator, notNilCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import { useArticle } from "context/Referencement";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { useGridController } from "hooks/";
import { articlesApi, taxesApi, taxeAchatsApi, sequenceDePrixApi } from "api/";

import 'app/ag-grid-style.css';
import { getISOEndDate, getISOStartDate } from "../../../utils";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 90,
};

interface $TaxeAchatViewModel extends TaxeAchatViewModel {
  nomRelationFour?: string;
  nomFournisseur?: string;
  codeFournisseur?: string;
}

type GridType = Partial<$TaxeAchatViewModel>;

const TaxesAchat: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const [activate, setActivate] = useState(false);
  const [taxeTypes, setTaxeTypes] = useState<TaxeViewModel[]>([]);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.fkTaxe,
      fetchData: async () => {
        if (!article?.id) return [];
        const { data: res } = await articlesApi.v1ArticlesIdTaxeAchatsGet(article.id);

        await Promise.all(
          res.map(async (val: GridType) => {
            const exraInfo = await fetchRelationFour(val.fkRelationFournisseurConditionnement);
            val.nomRelationFour = exraInfo.nomRelationFour;
            val.codeFournisseur = exraInfo.codeFournisseur;
            val.nomFournisseur = exraInfo.nomFournisseur;
          })
        );
        return res.sort((a, b) => a.dateDebut.localeCompare(b.dateDebut));
      },
      postData: (cleanRow) => taxeAchatsApi.v1TaxeAchatsPost(cleanRow as TaxeAchatInsertionModel),
      putData: (cleanRow) => taxeAchatsApi.v1TaxeAchatsPut(cleanRow as TaxeAchatViewModel),
      deleteData: (dataId) => taxeAchatsApi.v1TaxeAchatsIdDelete(dataId),
      getNewModel: () => ({
        type: 'M',
        valeur: 0,
        dateDebut: getISOStartDate(),
        dateFin: getISOEndDate(),
      }),
      onFocusChange: (isFocus) => setActivate(isFocus),
      rowClassRules: {
        "hide-actions": params => !editableIfNew(params) && !editableIfFuture(params, 'dateFin'),
      },
      columnDefs: [
        {
          field: "fkRelationFournisseurConditionnement",
          headerName: t('lib_taxe_achat_fk_relation_fournisseur_conditionnement'),
          headerTooltip: t('lib_taxe_achat_fk_relation_fournisseur_conditionnement'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          editable: editableIfNew,
          tooltipValueGetter: (params) => `${params.data?.nomFournisseur} (${params.data?.codeFournisseur}) - ${params.data?.nomRelationFour}`,
          valueFormatter: (params) => params.data?.nomFournisseur ? `${params.data?.nomFournisseur} (${params.data?.codeFournisseur}) - ${params.data?.nomRelationFour}` : '',
          cellRenderer: TextCellRenderer,
          cellEditor: AutoCompleteCellEditor,
          cellEditorPopup: true,
          cellEditorParams: {
            localSearchOnly: true,
            canBeEmpty: false,
            searchData: (async (_search) => {
              if (!article?.id) return [];
              const { data: res } = await sequenceDePrixApi.articlesIdViewApprovisionnementTarifaireGet(article.id);
              return res.map(val => {
                const nomFournisseur = val.approTarifaire?.fournisseur?.nomTiers;
                const codeFournisseur = val.approTarifaire?.fournisseur?.code;
                const nomRelationFour = val.approTarifaire?.nomRelationFournisseurConditionnement;
                return {
                  value: val.approTarifaire?.id,
                  prefix: `${nomFournisseur} (${codeFournisseur})`,
                  label: nomRelationFour,
                  filterKeyArr: [nomFournisseur, codeFournisseur, nomRelationFour]
                };
              });
            }),
          } as AutoCompleteCellEditorProps,
          valueValidator: [notEmptyCellValidator],
          onCellValueChanged: async (event) => {
            let exraInfo;
            if (event.newValue) {
              exraInfo = await fetchRelationFour(event.newValue);
            }
            event.data.nomRelationFour = exraInfo?.nomRelationFour;
            event.data.codeFournisseur = exraInfo?.codeFournisseur;
            event.data.nomFournisseur = exraInfo?.nomFournisseur;
            event.api.refreshCells({ rowNodes: [event.node!], force: true });
          },
        },
        {
          field: "fkTaxe",
          headerName: t('lib_taxe_achat_fk_taxe'),
          headerTooltip: t('lib_taxe_achat_fk_taxe'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          editable: editableIfNew,
          tooltipValueGetter: (params) => {
            const taxe = taxeTypes.find(
              (v) => typeof v.id === 'number' && v.id === params.data?.fkTaxe
            );
            return taxe ? taxe?.code + ' - ' + taxe?.nomTaxe : ' ';
          },
          valueFormatter: (params) => taxeTypes.find(
            (v) => typeof v.id === 'number' && v.id === params.data?.fkTaxe
          )?.code || " ",
          cellRenderer: TextCellRenderer,
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: taxeTypes.map((val) => ({
              value: val.id,
              label: val.nomTaxe ? val.code + ' - ' + val.nomTaxe : val.code,
              type: val.typeTaxe,
            })),
          },
          valueValidator: [notEmptyCellValidator],
        },
        {
          field: "taxeName",
          headerName: t('lib_taxe_nom_taxe'),
          headerTooltip: t('lib_taxe_nom_taxe'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueFormatter: (params) => {
            const taxe = taxeTypes.find(
              (v) => typeof v.id === 'number' && v.id === params.data?.fkTaxe
            );
            return taxe?.nomTaxe || taxe?.nomCourtTaxe || "";
          },
          cellRenderer: TextCellRenderer,
        },
        {
          field: "type",
          headerName: t('lib_taxe_achat_type'),
          headerTooltip: t('lib_taxe_achat_type'),
          editable: editableIfNew,
          valueFormatter: (params) => t(findTypeTaxeAchatTranslationKey(params.value)),
          cellRenderer: TextCellRenderer,
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: Object.values(TaxeAchatViewModelTypeEnum).map((typeTaxe) => ({
              value: typeTaxe,
              label: t(findTypeTaxeAchatTranslationKey(typeTaxe)),
            })),
          },
        },
        {
          field: "valeur",
          headerName: t('lib_taxe_achat_valeur_montant'),
          headerTooltip: t('lib_taxe_achat_valeur_montant'),
          editable: (params) => editableIfNew(params) || (editableIfFuture(params, 'dateDebut') && params.data?.type === "M"),
          valueFormatter: (params) => params.data?.type === "M" ? params.value : "",
          valueGetter: (params) => (params.data?.type === "M") ? params.data.valeur : 0,
          valueSetter: (params) => {
            if (params.data.type === "M") params.data.valeur = params.newValue;
            return true;
          },
          cellRenderer: TextCellRenderer,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 999999,
          },
          valueValidator: [notNilCellValidator, numberCellValidator],
        },
        {
          field: "valeur",
          headerName: t('lib_taxe_achat_valeur_pourcentage'),
          headerTooltip: t('lib_taxe_achat_valeur_pourcentage'),
          editable: (params) => editableIfNew(params) || (editableIfFuture(params, 'dateDebut') && params.data?.type === "P"),
          valueFormatter: (params) => params.data?.type === "P" ? `${params.value}%` : "",
          valueGetter: (params) => (params.data?.type === "P") ? params.data.valeur : 0,
          valueSetter: (params) => {
            if (params.data.type === "P") params.data.valeur = params.newValue;
            return true;
          },
          cellRenderer: TextCellRenderer,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 100,
          },
          valueValidator: [notNilCellValidator, numberCellValidator],
        },
        {
          field: "dateDebut",
          headerName: t('lib_taxe_achat_date_debut'),
          headerTooltip: t('lib_taxe_achat_date_debut'),
          tooltipField: "dateDebut",
          editable: editableIfNew,
          cellRenderer: DateCellRenderer,
          cellRendererParams: {
            dateFormat: 'datetime',
          } as DateCellRendererProps,
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
            min: DateTime.now(),
            roundCurrentTime: true,
          } as DatePickerCellEditorProps,
          valueValidator: [notEmptyCellValidator, dateCellValidator],
        },
        {
          field: "dateFin",
          headerName: t('lib_taxe_achat_date_fin'),
          headerTooltip: t('lib_taxe_achat_date_fin'),
          tooltipField: "dateFin",
          editable: editableIfNew,
          cellRenderer: DateCellRenderer,
          cellRendererParams: {
            dateFormat: 'datetime',
          } as DateCellRendererProps,
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
            min: DateTime.now(),
          } as DatePickerCellEditorProps,
          valueValidator: [notEmptyCellValidator, dateCellValidator],
        },
      ],
    }), [activate, taxeTypes, article, t])
  );

  useEffect(() => {
    fetchTaxeTypes();
  }, []);

  async function fetchTaxeTypes() {
    const { data: res } = await taxesApi.v1TaxesSearchPost(false, 1, 1000, { typeTaxe: 'N' });
    setTaxeTypes(res.data || []);
  };

  async function fetchRelationFour(relationFournisseurConditionnementId?: number | null) {
    const extraInfo: {
      nomRelationFour?: string,
      codeFournisseur?: string,
      nomFournisseur?: string,
    } = {};
    if (!relationFournisseurConditionnementId) return extraInfo;

    const { data } = await sequenceDePrixApi.relationFournisseurConditionnementsIdViewApprovisionnementTarifaireGet(relationFournisseurConditionnementId);
    extraInfo.nomRelationFour = data.approTarifaire?.nomRelationFournisseurConditionnement ?? undefined;
    extraInfo.codeFournisseur = data.approTarifaire?.fournisseur?.code ?? undefined;
    extraInfo.nomFournisseur = data.approTarifaire?.fournisseur?.nomTiers ?? undefined;

    return extraInfo;
  }

  return (
    <BlockGrid
      title={t('tab_taxes_approvisionnement')} sm={sm} md={md} lg={lg} xl={xl}
      doubleXl={doubleXl}
      handleClick={(res: boolean) => {
        setActivate(res);
        setTimeout(gridController.activateContextActionButtons, 50);
      }}
      toActivate={activate}
      loading={gridController.isLoading}
      disableCreate={gridController.hasEmptyLines}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          onGridReady={gridController.onGridReady}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default TaxesAchat;
