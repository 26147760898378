import { FunctionComponent } from 'react'
import NomenclatureDouaniere from 'components/Article/NomenclatureDouaniere'
import DonneesExport from 'components/Article/DonneesExport'
import Dangereux from 'components/Article/Dangereux'
import Constituants from 'components/Article/Constituants'
import TraitementsChimiques from 'components/Article/TraitementsChimiques'
import Certificats from 'components/Article/Certificats'
import Agrements from 'components/Article/Agrements'

interface Props {

}

const TabDroitsAgrement: FunctionComponent<Props> = () => {

  return (
    <div id="droits-agrements" className="flex flex-wrap justify-between bg-grey-150 gap-px p-px">

      <NomenclatureDouaniere sm={100} md={100} lg={100} xl={100} doubleXl={100} />
      <DonneesExport sm={100} md={100} lg={35} xl={35} doubleXl={35} />
      <Dangereux sm={100} md={100} lg={65} xl={65} doubleXl={65} />
      <Constituants sm={100} md={100} lg={100} xl={33} doubleXl={33} />
      <TraitementsChimiques sm={100} md={100} lg={100} xl={30} doubleXl={33} />
      <Certificats sm={100} md={100} lg={100} xl={33} doubleXl={33} />
      <Agrements sm={100} md={100} lg={100} xl={100} doubleXl={100} />

    </div>
  )
}

export default TabDroitsAgrement