// Old way, see this link for a basic example: https://blog.ag-grid.com/user-input-validation-with-ag-grid/

import { AgGridCommon } from "ag-grid-community/dist/lib/interfaces/iCommon";

import { RowDataType, RowDataAny, CustomColDef } from "components/AGGride/gridTypes";
import { TypeEanViewModel } from "openapi-typescript-codegen";
import { dateValidator, eanValidator } from "validators";

type ValidatorReturn = { success: boolean, msg?: string; };

export type ValidateCellFn<T = unknown> = (newValue: any, rowData: RowDataType<T>) => Promise<ValidatorReturn> | ValidatorReturn;

const _onWhileValidatingRow = (rowData: RowDataType, agParams: AgGridCommon<RowDataType>) => {
  if (!rowData._customDataProps) rowData._customDataProps = {};
  rowData._customDataProps.isValidating = true;

  agParams.api.applyTransaction({ update: [rowData] });
};

const _onFinishValidatingRow = (rowData: RowDataType, results: { success: boolean, colDef: CustomColDef; }[], agParams: AgGridCommon<RowDataType>) => {
  if (!rowData._customDataProps) rowData._customDataProps = {};
  rowData._customDataProps.isValidating = false;

  if (!rowData._customDataProps.validationSuccess) rowData._customDataProps.validationSuccess = {};
  for (const result of results) {
    if (!result.colDef.field) continue;
    rowData._customDataProps.validationSuccess[result.colDef.field] = result.success;
  }

  agParams.api.applyTransaction({ update: [rowData] });
};

async function runValidator(value: any, rowData: RowDataType, validator: ValidateCellFn) {
  return await validator(value, rowData);
}

async function cellValidator(value: any, rowData: RowDataType, validators: ValidateCellFn[]) {
  const resPromise = validators.map(
    async (validator) => await runValidator(value, rowData, validator)
  );
  const res = await Promise.all(resPromise);

  return {
    validatorResults: res,
    success: res.every(val => val.success)
  };
}

export default async function rowValidator(rowData: RowDataType, columnDefs: CustomColDef[], agParams: AgGridCommon<RowDataType>, dry = false) {
  const colsChecked = [];
  if (!dry)
    _onWhileValidatingRow(rowData, agParams);

  for (const colDef of columnDefs) {
    if (colDef.field && Array.isArray(colDef.valueValidator)) {
      const res = await cellValidator((rowData as RowDataAny)[colDef.field], rowData, colDef.valueValidator);

      colsChecked.push({ cell: res, colDef });
    }
  }

  if (!dry)
    _onFinishValidatingRow(rowData, colsChecked.map(({ cell, colDef }) => ({ success: cell.success, colDef })), agParams);

  return colsChecked;
}

/// AG utils validator

export const notEmptyCellValidator: ValidateCellFn = (newVal) => {
  return newVal ? { success: true } : { success: false, msg: 'err_champ_obligatoire' };
};

export const notNilCellValidator: ValidateCellFn = (newVal) => {
  return newVal != null ? { success: true } : { success: false, msg: 'err_champ_obligatoire' };
};

export const numberCellValidator: ValidateCellFn = (newVal) => {
  return /^\d+(?:(?:,|\.)\d+){0,1}$/gmi.test(newVal) ? { success: true } : { success: false, msg: 'err_champ_numerique' };
};

export const foreignKeyCellValidator: ValidateCellFn = (newVal) => {
  return typeof newVal === 'number' && newVal > 0 ? { success: true } : { success: false, msg: 'err_champ_cle_etrangere' };
};

export const percentageCellValidator: ValidateCellFn = (newVal) => {
  return newVal >= 0 && newVal <= 100 ? { success: true } : { success: false, msg: 'err_pourcentage_entre_0_et_100' };
};

export const dateCellValidator: ValidateCellFn = (newVal) => {
  return dateValidator(newVal) ? { success: true } : { success: false };
};

export const eanCellValidator = (typeEanField: string, eanTypes: TypeEanViewModel[]): ValidateCellFn => (newVal, rowData) => {
  const { errorMsg } = eanValidator(newVal, (rowData as RowDataAny)[typeEanField], eanTypes);
  return !errorMsg ? { success: true } : { success: false };
};