import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { DateTime, Interval } from "luxon";

import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { histoPrixVentesApi } from "api/";
import { useArticle } from "context/Referencement";
import { showPrettyError } from "utils/error";

function _getFakeData() {
  return Array(6).fill(true).map((_, idx) => ({
    name: `Day ${idx + 1}`,
    value: Math.floor(((Math.random() * 180) - 90) * 100) / 100,
  }));
}

function _getWeekPrices(rawData: { isoDate: string, price: number; }[]) {
  const intervals = rawData
    .sort()
    .map((val, idx, arr) => {
      const next = arr[idx + 1];
      const startDate = DateTime.fromISO(val.isoDate);
      const endDate = DateTime.fromISO(next?.isoDate ?? '3999-01-01');
      return {
        price: val.price || 0,
        inter: Interval.fromDateTimes(startDate, endDate)
      };
    });
  if (intervals.length) {
    const startDate = DateTime.fromISO('2000-01-01');
    intervals.unshift(
      {
        price: 0,
        inter: Interval.fromDateTimes(startDate, intervals[0].inter.start)
      }
    );
  }

  const weekPrices = [];
  const startWeek = DateTime.now().startOf('week');
  const endWeek = DateTime.now().startOf('week').plus({ weeks: 1 });
  let cursorDate = startWeek;

  while (cursorDate <= endWeek) {
    const localCursorDate = cursorDate.plus({ seconds: 0 });
    const dayPrice = intervals.find(val => val.inter.contains(localCursorDate));

    weekPrices.push({
      date: localCursorDate,
      inter: dayPrice?.inter,
      price: dayPrice?.price || 0,
    });
    cursorDate = cursorDate.plus({ days: 1 });
  }
  return weekPrices;
}

function getAllPrices(rawData: { isoDate: string, price: number; }[]) {
  const allPrices = rawData
    .map(val => {
      const date = DateTime.fromISO(val.isoDate);
      return {
        price: val.price || 0,
        date: date,
      };
    })
    .sort((a, b) => a.date.toMillis() - b.date.toMillis());

  return allPrices;
}

// Zoom example: https://recharts.org/en-US/examples/HighlightAndZoomLineChart

type GraphData = { name: string, timestamp: number, value: number; }[];

const PrixVenteGraph: FunctionComponent<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const [loading, setLoader] = useState<boolean>(true);
  const [data, setData] = useState<GraphData>([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    if (!article?.id) return;
    setLoader(true);
    try {
      const { data: { data: rawData } } = await histoPrixVentesApi.v1HistoPrixVentesSearchPost(false, 1, 500, { fkArticle: article.id });
      if (!rawData) return;

      // const weekPrices = getWeekPrices(
      //   rawData.filter(val => val.datePxVente).map(val => ({ isoDate: val.datePxVente!, price: val.pxVenteHisto || 0 }))
      // );
      const allPrices = getAllPrices(
        rawData.filter(val => val.datePxVente).map(val => ({ isoDate: val.datePxVente!, price: val.pxVenteHisto || 0 }))
      );
      setData(
        allPrices.map(val => ({
          name: val.date.toLocaleString(DateTime.DATE_SHORT),
          timestamp: val.date.toMillis(),
          value: val.price,
        }))
      );
    } catch (err) {
      showPrettyError(err);
    }
    setLoader(false);
  }

  return (
    <BlockGrid
      title={t('tab_evolution_prix_vente')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      toActivate={true}
      loading={loading}
    >
      <div className="block-grid-md select-none -ml-4" style={{ width: 'calc(100% + 2rem)' }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              left: -15,
              right: 30,
              bottom: -20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis xAxisId="0" dataKey="timestamp" type="number" interval="preserveStartEnd" tickSize={8} tickMargin={5}
              padding={{ left: 20, right: 20 }} domain={['dataMin', 'dataMax']}
              tickFormatter={value => DateTime.fromMillis(value).toLocaleString(DateTime.DATE_SHORT)}
            />
            <YAxis type="number" allowDecimals={false} interval="preserveStartEnd" tickMargin={5} />
            <Tooltip labelFormatter={value => DateTime.fromMillis(value).toLocaleString(DateTime.DATETIME_SHORT)} />
            <Legend />
            <ReferenceLine y={0} stroke="gray" />
            <Line dataKey="value" type="stepAfter" unit=' €' name={t('lib_histo_prix_vente_px_vente')} stroke="#1d6acc" strokeWidth={2} animationDuration={500} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </BlockGrid>
  );
};

export default PrixVenteGraph;