import { FC, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { ViewArticleViewModel } from "openapi-typescript-codegen";
import { ColDef } from "ag-grid-community";

import BlockGrid, { CancelType, LayoutEnum } from 'components/BlockGrid';
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { GridType as DataProps } from ".";
import { AgGridReact } from "ag-grid-react";
import { useGridController } from "hooks/";
import { ImageCellRenderer, ImageCellRendererProps, LinkCellRenderer, LinkCellRendererProps, ModalCellRendererChildrenProps } from "components/AGGride/CellRender";
import { circuitLogistiquesApi, fournisseursApi, relationFournisseurConditionnementApi } from "api/";
// import { GridController } from "hooks/useGridController";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 80,
  sortable: true,
};

type GridType = ViewArticleViewModel;

const ArticlesModal: FC<ModalCellRendererChildrenProps<DataProps>> = ({ setIsOpen, agGridRow }) => {
  const { t } = useTranslation();
  const fournisseurId = agGridRow.data?.id;
  const circuit = agGridRow.data?.circuit;
  // const parentGridController: GridController | undefined = agGridRow.context?.gridController;

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      fetchData: async () => {
        if (!circuit?.id || !fournisseurId) return [];
        // const { data: { data: relationFours } } = await relationFournisseurConditionnementApi.v1RelationFournisseurConditionnementsSearchPost(false, 1, 100, { fkCircuitLogistique: circuit.id });
        const { data: { data } } = await circuitLogistiquesApi.v1CircuitLogistiquesIdViewArticlePost(circuit.id, {
          page: 1,
          amount: 1000,
          primary: agGridRow.colDef?.field !== "circuit.articleSecondaryNb",
          secondary: agGridRow.colDef?.field !== "circuit.articlePrimaryNb",
          searchOption: {}
        });
        console.log(agGridRow);
        // @TODO: Temporary fix. It needs to be fixed properly:
        return data;
        // .filter((val: any) => relationFours.some(rela => rela.id === val.relationFournisseurConditionnement))
        // .filter((val: any, idx: any, arr: any) => idx === arr.findIndex((val2: any) => val2.id === val.id));
      },
      columnDefs: [
        {
          field: "image",
          headerName: t('lib_article_image'),
          headerTooltip: t('lib_article_image'),
          pinned: "left",
          maxWidth: 80,
          sortable: false,
          cellRenderer: ImageCellRenderer,
          cellRendererParams: {
            href: (val) => val,
            defaultHref: "/assets/images/default.png",
          } as ImageCellRendererProps,
        },
        {
          field: "code",
          headerName: t('lib_article_code'),
          headerTooltip: t('lib_article_code'),
          pinned: "left",
          maxWidth: 100,
          floatingFilter: true,
          filter: "agTextColumnFilter",
          cellRenderer: LinkCellRenderer,
          cellRendererParams: {
            href: (value) => `/app/referencement/articles/${value}`,
            target: '_blank',
          } as LinkCellRendererProps,
        },
        {
          field: "designationStandard",
          headerName: t("lib_article_designation_standard"),
          headerTooltip: t('lib_article_designation_standard'),
          minWidth: 250,
          floatingFilter: true,
          filter: "agTextColumnFilter",
        },
        {
          field: "marqueLogo",
          headerName: t('lib_marque_logo'),
          headerTooltip: t('lib_marque_logo'),
          maxWidth: 80,
          sortable: false,
          cellRenderer: ImageCellRenderer,
          cellRendererParams: {
            href: (val) => val,
            defaultHref: "/assets/images/default.png",
          } as ImageCellRendererProps,
        },
        {
          field: "marqueNom",
          headerName: t("lib_article_fk_marque"),
          headerTooltip: t('lib_article_fk_marque'),
          floatingFilter: true,
          filter: "agTextColumnFilter",
        },
        {
          field: "codeEtatNom",
          headerName: t("lib_article_fk_code_etat"),
          headerTooltip: t('lib_article_fk_code_etat'),
          floatingFilter: true,
          filter: "agTextColumnFilter",
        },
        {
          field: "relationFournisseur.circuitLogistique.fournisseur.code",
          headerName: t("lib_relation_fournisseur_fk_fournisseur"),
          headerTooltip: t('lib_relation_fournisseur_fk_fournisseur'),
          floatingFilter: true,
          filter: "agTextColumnFilter",
        },
        {
          field: "relationFournisseur.nom",
          headerName: t("lib_relation_fournisseur_nom_relation_fournisseur"),
          headerTooltip: t('lib_relation_fournisseur_nom_relation_fournisseur'),
          floatingFilter: true,
          filter: "agTextColumnFilter",
        },
        {
          field: "fluxFournisseurCode",
          headerName: t("lib_flux_circuit"),
          headerTooltip: t('lib_flux_circuit'),
          floatingFilter: true,
          filter: "agTextColumnFilter",
          valueFormatter: (params) => params.data?.fluxFournisseurCode && params.data?.fluxFournisseurNom
            ? `${params.data.fluxFournisseurCode} - ${params.data.fluxFournisseurNom}`
            : "",
        },
        {
          field: "stock", // A gérer plus tard selon la spec
          headerName: t("lib_article_fl_suivi_stock"),
          headerTooltip: t('lib_article_fl_suivi_stock'),
          floatingFilter: true,
          filter: "agTextColumnFilter",
        },
        {
          field: "completude", // A gérer plus tard selon la spec
          headerName: t("lib_controle_completude_article_valeur"),
          headerTooltip: t('lib_controle_completude_article_valeur'),
          floatingFilter: true,
          filter: "agTextColumnFilter",
        },
        {
          field: "prixAchat",
          headerName: t("lib_prix_achat_px_achat"),
          headerTooltip: t('lib_prix_achat_px_achat'),
          floatingFilter: true,
          filter: "agTextColumnFilter",
        },
        {
          field: "prixVente",
          headerName: t("lib_prix_vente_px_vente"),
          headerTooltip: t('lib_prix_vente_px_vente'),
          floatingFilter: true,
          filter: "agTextColumnFilter",
        },
        {
          field: "typeArticleNom",
          headerName: t("lib_article_fk_type_article"),
          headerTooltip: t('lib_article_fk_type_article'),
          floatingFilter: true,
          filter: "agTextColumnFilter",
        },
      ],
    }), [circuit, t])
  );

  async function handleCancel(type: CancelType) {
    if (type === 'btn') {
      await gridController.handleCancel();
      setTimeout(gridController.activateContextActionButtons, 50);
    } else {
      setIsOpen(false);
    }
  }

  return (
    <BlockGrid
      layout={LayoutEnum.modal}
      toActivate={true}
      disableCreate={true}
      handleCancel={handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-modal mt-3">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={(ev) => {
            gridController.onFirstDataRendered(ev);
            setTimeout(gridController.activateContextActionButtons, 50);
          }}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default ArticlesModal;