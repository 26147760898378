import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  ConditionAchatParPalierDetailInsertionModel,
  ConditionAchatParPalierDetailViewModel,
  ConditionAchatParPalierViewModel,
  RemiseViewModel
} from "openapi-typescript-codegen";
import { ColDef } from "ag-grid-community";

import BlockGrid, { CancelType, LayoutEnum } from 'components/BlockGrid';
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { GridType as DataProps } from "./index";
import { AgGridReact } from "ag-grid-react";
import { useGridController } from "hooks";
import { CheckedCellRenderer, ModalCellRendererChildrenProps, NumberCellRenderer } from "components/AGGride/CellRender";
import { NumberCellEditor } from "components/AGGride/CellEditor";
import { notNilCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import { SelectInput } from "components/InputWrapper";
import { conditionAchatsApi, remisesApi, conditionAchatParPaliersApi, conditionAchatParPalierDetailsApi } from "api";
import { showPrettyError } from "utils";
// import { GridController } from "hooks/useGridController";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
};

type GridType = Partial<ConditionAchatParPalierDetailViewModel>;

const DetailRemisePalier: FC<ModalCellRendererChildrenProps<DataProps>> = ({ setIsOpen, agGridRow }) => {
  const { t } = useTranslation();
  const [remise, setRemise] = useState<RemiseViewModel | null>(null);
  const [parPalier, setParPalier] = useState<Partial<ConditionAchatParPalierViewModel>>({});
  // const parentGridController: GridController | undefined = agGridRow.context.gridController;
  const conditionAchatId = agGridRow.data?.id;

  const [typeAccord] = useState<{ value: string | null, label: string; }[]>([
    { value: 'A', label: t('enu_remise_type_accord_arriere') },
    { value: 'C', label: t('enu_remise_type_accord_arriere_conditionne') },
    { value: 'F', label: t('enu_remise_type_accord_sur_facture') },
  ]);
  const [typeSens] = useState<{ value: string | null, label: string; }[]>([
    { value: 'R', label: t('enu_remise_sens_remise') },
    { value: 'M', label: t('enu_remise_sens_majoration') },
  ]);
  const [typePalier] = useState<{ value: string | null, label: string; }[]>([
    { value: 'A', label: t('enu_unite_mesure_type_unite_mesure_article') },
    { value: 'V', label: t('enu_unite_mesure_type_unite_mesure_kilo') },
    { value: 'L', label: t('enu_unite_mesure_type_unite_mesure_litre') },
    { value: 'M', label: t('enu_unite_mesure_type_unite_mesure_metre') },
    { value: 'C', label: t('enu_unite_mesure_type_unite_mesure_metre_carre') },
    { value: 'K', label: t('enu_unite_mesure_type_unite_mesure_metre_cube') },
  ]);
  const [typeRemise] = useState<{ value: string | null, label: string; }[]>([
    { value: 'M', label: t('enu_type_remise_montant') },
    { value: 'P', label: t('enu_type_remise_pourcentage') },
  ]);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData,
      fetchData: async () => {
        if (!conditionAchatId) return [];
        const { data: newParPalier } = await conditionAchatsApi.v1ConditionAchatsIdConditionAchatParPaliersGet(conditionAchatId);
        setParPalier(newParPalier);
        if (!newParPalier?.id) return [];
        const { data } = await conditionAchatParPaliersApi.v1ConditionAchatParPaliersIdDetailsGet(newParPalier.id);
        return data;
      },
      postData: (cleanRow) => conditionAchatParPalierDetailsApi.v1ConditionAchatParPalierDetailsPost({ ...cleanRow } as ConditionAchatParPalierDetailInsertionModel),
      putData: (cleanRow) => conditionAchatParPalierDetailsApi.v1ConditionAchatParPalierDetailsPut({ ...cleanRow } as ConditionAchatParPalierDetailViewModel),
      deleteData: (dataId) => conditionAchatParPalierDetailsApi.v1ConditionAchatParPalierDetailsIdDelete(dataId),
      getNewModel: () => ({
        fkConditionAchatParPalier: parPalier?.id,
        numPalier: 0,
      }),
      columnDefs: [
        {
          field: "valeurUnite",
          headerName: t('lib_condition_achat_par_palier_detail_valeur_unite'),
          headerTooltip: t('lib_condition_achat_par_palier_detail_valeur_unite'),
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
          },
          valueValidator: [notNilCellValidator, numberCellValidator],
        },
        {
          field: "valeurRemise",
          headerName: t('lib_condition_achat_par_palier_detail_valeur_remise'),
          headerTooltip: t('lib_condition_achat_par_palier_detail_valeur_remise'),
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
          },
          valueValidator: [notNilCellValidator, numberCellValidator],
        },
        {
          field: "flPalierDefaut",
          headerName: t('lib_condition_achat_par_palier_detail_fl_palier_defaut'),
          headerTooltip: t('lib_condition_achat_par_palier_detail_fl_palier_defaut'),
          cellRenderer: CheckedCellRenderer,
        },
        {
          field: "__expand__",
          headerName: '',
          flex: 1,
        },
      ],
    }), [t])
  );

  useEffect(() => {
    fetchRemise();
  }, []);

  async function fetchRemise() {
    if (!conditionAchatId) return;
    const { data: condAchat } = await conditionAchatsApi.v1ConditionAchatsIdGet(conditionAchatId);
    if (!condAchat.fkRemise) return;
    const { data: newRemise } = await remisesApi.v1RemisesIdGet(condAchat.fkRemise);
    setRemise(newRemise);
  }

  async function handleUpdate() {
    try {
      await conditionAchatParPaliersApi.v1ConditionAchatParPaliersPut({ ...parPalier } as ConditionAchatParPalierViewModel);
    } catch (err) {
      showPrettyError(err);
    }
    await gridController.handleUpdate();
  }

  async function handleCancel(type: CancelType) {
    if (type === 'btn') {
      await gridController.handleCancel();
      setTimeout(gridController.activateContextActionButtons, 50);
    } else {
      setIsOpen(false);
    }
  }

  return (
    <BlockGrid
      title={t('tit_conditions_achats') + ' - ' + t('enu_condition_achat_application_palier')}
      layout={LayoutEnum.modal}
      toActivate={true}
      loading={gridController.isLoading}
      disableCreate={gridController.hasEmptyLines}
      handleCreate={gridController.handleCreate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
    >
      <div className="grid grid-cols-4 gap-4 mb-3">
        {/* Line 1 */}
        <div className="flex flex-col">
          <label className="text-grey-500 font-bold">{t('lib_condition_achat_fk_remise')}</label>
          <div>{remise?.code || '...'}</div>
        </div>
        <div className="flex flex-col">
          <label className="text-grey-500 font-bold">{t('lib_remise_nom_remise')}</label>
          <div>{remise?.nomRemise}</div>
        </div>
        <div>
          <label className="text-grey-500 font-bold">{t('lib_remise_type_accord')}</label>
          <div>{typeAccord.find(val => val.value === remise?.typeAccord)?.label || remise?.typeAccord}</div>
        </div>
        <div>
          <label className="text-grey-500 font-bold">{t('lib_remise_sens')}</label>
          <div>{typeSens.find(val => val.value === remise?.sens)?.label || remise?.sens}</div>
        </div>
        {/* Line 2 */}
        <div>
          <SelectInput className="col-span-2" label={t("lib_condition_achat_par_palier_type_palier")}
            value={parPalier} field="typePalier" setValue={setParPalier}
            recommended
            getPlaceholder={async (typePal) => {
              return typePalier.find(val => val.value === typePal)?.label || typePal;
            }}
            searchData={async (search) => {
              return typePalier.filter(
                val => !search || val.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
              );
            }}
          />
        </div>
        <div>
          <SelectInput className="col-span-2" label={t("lib_condition_achat_par_palier_type_remise")}
            value={parPalier} field="typeRemise" setValue={setParPalier}
            recommended
            getPlaceholder={async (typeRem) => {
              return typeRemise.find(val => val.value === typeRem)?.label || typeRem;
            }}
            searchData={async (search) => {
              return typeRemise.filter(
                val => !search || val.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
              );
            }}
          />
        </div>
      </div>
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-modal mt-3">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={(ev) => {
            gridController.onFirstDataRendered(ev);
            setTimeout(gridController.activateContextActionButtons, 50);
          }}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default DetailRemisePalier;