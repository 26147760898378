import { useRef, FunctionComponent } from 'react';
import DeleteIcon from "@mui/icons-material/Delete";

import ConfirmModal, { ConfirmEnum, ConfirmModalRef } from "components/Modal/ConfirmModal";

interface Props {
  selectedItem: any;
  canBeDeleted: boolean;
  onDeleteItem: (id: number) => void;
  className?: string;
}

const DeletionItemLN: FunctionComponent<Props> = ({ selectedItem, canBeDeleted, onDeleteItem, className }) => {
  const modal = useRef<ConfirmModalRef | null>(null);

  const handleDelete = async () => {
    const validate = await modal.current!.showModal();
    (validate && onDeleteItem) && onDeleteItem(selectedItem.id);
  };

  return (
    canBeDeleted ? (
      <>
        <button
          onClick={handleDelete} tabIndex={5}
          className={className ||
            `text-lg h-full w-8 flex items-center justify-center float-right bg-store-primary
                            absolute top-0 right-0 transition-all text-white-500 focus:text-red-500 hover:text-red-500
                            border-l border-white-500 focus:bg-white-500 hover:bg-white-500 opacity-1`
          }
        >
          <DeleteIcon />
        </button>
        <ConfirmModal ref={modal} layout={ConfirmEnum.delete} />
      </>
    ) : null
  );
};

export default DeletionItemLN;