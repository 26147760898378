export function interpolate(value: string, model: { [key: string]: any; }): string {
  const pattern = /#\{(.*?)}/g;
  const matches = [...value.matchAll(pattern)];
  for (const match of matches) {
    value = value.replace(match[0], parse(match[1], model));
  }
  return value;
}

export function parse(match: string, model:  { [key: string]: any; }, keys?: string[]): any {
  const $keys: string[] = keys || match.split('.');
  const key: string = $keys.shift()!;
  return $keys.length ? parse(match, model[key], $keys) : model[key];
}