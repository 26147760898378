import { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ColDef } from "ag-grid-community";

import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import { ImageCellRenderer, ImageCellRendererProps, LinkCellRenderer, LinkCellRendererProps } from "components/AGGride/CellRender";
import { CustomColDef } from "components/AGGride/gridTypes";
import { viewArticlesApi } from "api/";
import { ViewArticleViewModel } from "openapi-typescript-codegen";
import CreationArticle from "components/CreationArticle";
import ServerSideGrid, { ServerSideGetRowsCallback } from "components/AGGride/ServerSideGrid";

const breadcrumb = [
  {
    name: "tit_applications",
    link: `/app`,
  },
  {
    name: "tab_referencement",
    link: `/app/referencement/`,
  },
  {
    name: "tab_listing_articles",
  },
];

const defaultColDef: ColDef = {
  flex: 1,
  minWidth: 80,
  sortable: true,
};

type GridType = ViewArticleViewModel;

const ListeArticles: FunctionComponent = () => {
  const { t } = useTranslation();

  const columnDefs = useMemo((): CustomColDef<GridType>[] => [
    {
      field: "image",
      headerName: t('lib_article_image'),
      headerTooltip: t('lib_article_image'),
      pinned: "left",
      maxWidth: 80,
      sortable: false,
      cellRenderer: ImageCellRenderer,
      cellRendererParams: {
        href: (val) => val,
        defaultHref: "/assets/images/default.png",
      } as ImageCellRendererProps,
    },
    {
      field: "code",
      headerName: t('lib_article_code'),
      headerTooltip: t('lib_article_code'),
      pinned: "left",
      maxWidth: 100,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: LinkCellRenderer,
      cellRendererParams: {
        href: (value) => `/app/referencement/articles/${value}`,
        target: '_blank',
      } as LinkCellRendererProps,
    },
    {
      field: "designationStandard",
      headerName: t("lib_article_designation_standard"),
      headerTooltip: t('lib_article_designation_standard'),
      minWidth: 250,
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "eanPrincipal",
      headerName: t("lib_ean_code"),
      headerTooltip: t('lib_ean_code'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "marqueLogo",
      headerName: t('lib_marque_logo'),
      headerTooltip: t('lib_marque_logo'),
      maxWidth: 80,
      sortable: false,
      cellRenderer: ImageCellRenderer,
      cellRendererParams: {
        href: (val) => val,
        defaultHref: "/assets/images/default.png",
      } as ImageCellRendererProps,
    },
    {
      field: "marqueNom",
      headerName: t("lib_article_fk_marque"),
      headerTooltip: t('lib_article_fk_marque'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "codeEtatNom",
      headerName: t("lib_article_fk_code_etat"),
      headerTooltip: t('lib_article_fk_code_etat'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "stock", // A gérer plus tard selon la spec
      headerName: t("lib_article_fl_suivi_stock"),
      headerTooltip: t('lib_article_fl_suivi_stock'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "relationFournisseur.circuitLogistique.fournisseur.code",
      headerName: t("lib_relation_fournisseur_fk_fournisseur"),
      headerTooltip: t('lib_relation_fournisseur_fk_fournisseur'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "relationFournisseur.nom",
      headerName: t("lib_relation_fournisseur_nom_relation_fournisseur"),
      headerTooltip: t('lib_relation_fournisseur_nom_relation_fournisseur'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      // TODO
      field: "priorite",
      headerName: t("lib_relation_fournisseur_conditionnement_priorite"),
      headerTooltip: t('lib_relation_fournisseur_conditionnement_priorite'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "fluxFournisseurCode",
      headerName: t("lib_flux_circuit"),
      headerTooltip: t('lib_flux_circuit'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
      tooltipValueGetter: (params) => `${params.data?.fluxFournisseurCode} - ${params.data?.fluxFournisseurNom}`,
    },
    {
      field: "completude", // A gérer plus tard selon la spec
      headerName: t("lib_controle_completude_article_valeur"),
      headerTooltip: t('lib_controle_completude_article_valeur'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "prixAchat",
      headerName: t("lib_prix_achat_px_achat"),
      headerTooltip: t('lib_prix_achat_px_achat'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "pa_dpr",// A gérer plus tard selon la spec
      headerName: t("todo_pa/dpr"),
      headerTooltip: t('todo_pa/dpr'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "prixVente",
      headerName: t("lib_prix_vente_px_vente"),
      headerTooltip: t('lib_prix_vente_px_vente'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "typeArticleNom",
      headerName: t("lib_article_fk_type_article"),
      headerTooltip: t('lib_article_fk_type_article'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
  ], [t]);

  const getRows: ServerSideGetRowsCallback<GridType> = useCallback(
    async (params, { page, amount, query: _query }) => {
      // TODO: create view with search options
      const { data: { data, total } } = await viewArticlesApi.v1ViewArticlesGet(page, amount, /* searchOption: query */);

      return {
        rowData: data
          // .filter((val, idx, arr) => idx === arr.findIndex(val2 => val.id === val2.id))
          .sort((a, b) => (a.code || '')?.localeCompare(b.code || '')),
        rowCount: total
      };
    }, []);

  return (
    <>
      <BreadCrumb content={breadcrumb} />

      <div className="w-full flex justify-between items-center mt-2 mb-3 h-10">
        <H1Title content={{ title: "tab_listing_articles", icon: "articles" }} />
        <CreationArticle />
      </div>

      <ServerSideGrid
        className="block-grid-list mb-4"
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={(params) => `${params.data.id}${params.data.relationFournisseurConditionnement}${params.data.fluxFournisseurCode}`}

        getRows={getRows}
      />
    </>
  );
};

export default ListeArticles;

