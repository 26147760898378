import { FunctionComponent, useState, InputHTMLAttributes, useEffect } from "react";
import cs from "classnames";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

type CheckBox = {
  onValueChange?: (value: boolean) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const CreateButton: FunctionComponent<CheckBox> = ({ className, onValueChange, checked, ...props }) => {
  const [isChecked, setIsChecked] = useState(!!checked);

  useEffect(() => {
    setIsChecked(!!checked);
  }, [checked]);

  useEffect(() => {
    onValueChange?.(isChecked);
  }, [isChecked]);

  return (
    <div className="h-4 w-4 bg-slate-50 rounded-[2px] relative cursor-pointer">
      <input
        {...props}
        checked={isChecked}
        className="toggle-switch opacity-0 w-0 h-0"
        type="checkbox"
        onChange={(ev) => {
          props.onChange?.(ev);
          setIsChecked(old => !old);
        }}
      />
      <span
        className={cs(
          'absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center',
          className
        )}>
        {isChecked ? <DoneRoundedIcon sx={{ color: "#2584ff", fontSize: '1rem' }} /> : ""}
      </span>
    </div>
  );
};

export default CreateButton;
