import { forwardRef, useImperativeHandle, useState } from 'react';
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import Modal from ".";
import { toast } from 'utils';
import { useFocus } from "hooks";

export type ConfirmModalRef = {
  showModal: () => Promise<boolean | null>;
  closeModal: (validate: boolean) => void;
};

export enum ConfirmEnum {
  'accept',
  'confirm',
  'delete',
}

interface Props {
  layout?: ConfirmEnum,
  title?: string,
  informations?: string;
}

const ConfirmModal = forwardRef<unknown, Props>(({ layout = ConfirmEnum.accept, title, informations }, ref) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [nextResolve, setNextResolve] = useState<{ callback: (val: boolean) => void; } | null>(null);
  const [validateRef] = useFocus<HTMLButtonElement>();

  useImperativeHandle(ref, (): ConfirmModalRef => {
    return {
      showModal,
      closeModal,
    };
  });

  async function showModal() {
    if (isOpen) return null;
    setIsOpen(true);
    // forceUpdate();

    const nextClose = new Promise((resolve, _reject) => {
      setNextResolve({ callback: resolve });
    });
    return await nextClose as boolean;
  }

  function closeModal(validate = false) {
    if (!isOpen) return;
    setIsOpen(false);
    // forceUpdate();

    if (!validate) toast.info("mes_action_annulee");
    if (nextResolve) nextResolve.callback(validate);
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => closeModal()}
    >
      <div className="absolute top-1/2 left-1/2 max-h-screen shadow-xl w-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="flex-1 rounded-lg border-2 border-transparent no-scrollbar pb-0 bg-white-500">
          <div className="-ml-[2px] -mt-[2px]" style={{ width: 'calc(100% + 4px)' }}>
            <div className="bg-blue-store text-white-500 w-full px-4 py-2 flex justify-between rounded-t-lg">
              <div className="self-center -ml-[2px] font-bold">
                {(layout === ConfirmEnum.confirm) ? (title ?? t('dia_confirmation_modification')) :
                  (layout === ConfirmEnum.delete) ? t('tit_confirmation_suppression') : t('tit_toast_header')}
              </div>
              <button onClick={() => closeModal()}
                className="text-lg rounded-lg h-8 w-8 flex items-center justify-center
                  border border-transparent focus:border-white-100 hover:border-white-100"
              ><Close /></button>
            </div>
          </div>
          <div className="w-full h-full  no-scrollbar text-grey-900 p-4 mt-3">
            <div className="flex items-center gap-2 text-base">
              {(layout === ConfirmEnum.delete) ? t('mes_confirmation_suppression') : informations}
            </div>
          </div>
          <div style={{ width: 'calc(100% + 4px)' }}
            className="w-full flex text-xs justify-end h-12 py-2 px-4
            bg-store-primary-light mt-3 -ml-[2px] -mb-[2px] rounded-b-lg">
            <div className="w-full h-full flex flex-row justify-end">
              <div className='flex gap-2'>
                {(layout === ConfirmEnum.accept) &&
                  <>
                    <button
                      ref={validateRef}
                      onClick={() => closeModal(true)} style={{ minWidth: '48px' }}
                      className="bg-blue-store hover:bg-blue-store focus:bg-blue-store text-white-500 rounded py-0.5 px-2">{t('lib_oui')}</button>
                  </>
                }
                {(layout === ConfirmEnum.confirm || layout === ConfirmEnum.delete) &&
                  <>
                    <button
                      onClick={() => closeModal()} style={{ minWidth: '48px' }}
                      className="text-store-primary underline outline-none focus:text-black hover:text-black capitalize">{t('lib_non')}</button>
                    <button
                      ref={validateRef}
                      onClick={() => closeModal(true)} style={{ minWidth: '48px' }}
                      className="bg-blue-store hover:bg-blue-store focus:bg-blue-store text-white-500 rounded py-0.5 px-2 capitalize">{t('lib_oui')}</button>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default ConfirmModal;