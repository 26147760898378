import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { tauxTvasApi, uniteMesuresApi, uniteVentesApi, articlesApi, techOriginesApi } from 'api'
import { ArticleViewModel, ConditionnementViewModel, EanViewModel, RelationFournisseurConditionnementViewModel, TauxTvaViewModel, TechOrigineViewModel, UniteMesureViewModel, UniteVenteViewModel } from 'openapi-typescript-codegen'

import { DataUsageRounded } from "@mui/icons-material";
import CodeBar from "components/CodeBar";

interface Props {
  articleSelected?: ArticleViewModel
}

interface Loader {
  uniteVente: boolean
  uniteMesure: boolean
  tauxTva: boolean
  ean: boolean
  origine: boolean
  fournisseur: boolean
  pcb: boolean
}

const ProduitPresentation: FunctionComponent<Props> = ({ articleSelected }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [uniteVente, setUniteVente] = useState<UniteVenteViewModel>();
  const [uniteMesure, setUniteMesure] = useState<UniteMesureViewModel>();
  const [tauxTva, setTauxTva] = useState<TauxTvaViewModel>();
  const [fournisseur, setFournisseur] = useState<RelationFournisseurConditionnementViewModel[]>();
  const [pcb, setPCB] = useState<ConditionnementViewModel[]>();
  const [origine, setOrigine] = useState<TechOrigineViewModel>();
  const [ean, setEan] = useState<EanViewModel[]>();
  const [loading, setLoading] = useState<Loader>({
    uniteVente: true,
    uniteMesure: true,
    tauxTva: true,
    ean: true,
    origine: true,
    fournisseur: true,
    pcb: true
  })

  const fetchGetUniteVente = async (id: number) => {
    const { data: response } = await uniteVentesApi.v1UniteVentesIdGet(id);
    setUniteVente(response);
    setLoading((prevState) => { return ({...prevState, uniteVente: false}) })
  };

  const fetchGetUniteMesure = async (id: number) => {
    const { data: response } = await uniteMesuresApi.v1UniteMesuresIdGet(id);
    setUniteMesure(response);
    setLoading((prevState) => { return ({...prevState, uniteMesure: false}) })
  };

  const fetchGetTva = async (id: number) => {
    const { data: response } = await tauxTvasApi.v1TauxTvasIdGet(id);
    setTauxTva(response);
    setLoading((prevState) => { return ({...prevState, tauxTva: false}) })
  };

  const fetchGetEan = async (id: number) => {
    const { data: response } = await articlesApi.v1ArticlesIdEansGet(id);
    setEan(response);
    setLoading((prevState) => { return ({...prevState, ean: false}) })
  };

  const fetchGetOrigine = async (id: number) => {
    const { data: tmp } = await articlesApi.v1ArticlesIdReglementairesGet(id);
    if (tmp[0]?.fkTechOrigine) {
      const { data: response } = await techOriginesApi.v1TechOriginesIdGet(tmp[0].fkTechOrigine);
      setOrigine(response);
    }
    setLoading((prevState) => { return ({...prevState, origine: false}) })
  };

  const fetchGetFournisseur = async (id: number) => {
    const { data: response } = await articlesApi.v1ArticlesIdRelationFournisseurConditionnementsGet(id);
    setFournisseur(response.sort((a, b) => {
      if (a?.priorite && b?.priorite) return a?.priorite - b?.priorite
      return 0
    }));
    setLoading((prevState) => { return ({...prevState, fournisseur: false}) })
  };

  const fetchGetPCB = async (id: number) => {
    const { data: response } = await articlesApi.v1ArticlesIdConditionnementsGet(id);
    setPCB(response);
    setLoading((prevState) => { return ({...prevState, pcb: false}) })
  };

  useEffect(() => {
    if (articleSelected?.id) fetchGetEan(articleSelected.id)
    if (articleSelected?.id) fetchGetOrigine(articleSelected.id)
    if (articleSelected?.id) fetchGetFournisseur(articleSelected.id)
    if (articleSelected?.id) fetchGetPCB(articleSelected.id)
  }, [articleSelected?.id])
  useEffect(() => {
    if (articleSelected?.fkTva) fetchGetTva(articleSelected.fkTva)
  }, [articleSelected?.fkTva])
  useEffect(() => {
    if (articleSelected?.fkUniteMesure) fetchGetUniteMesure(articleSelected.fkUniteMesure)
  }, [articleSelected?.fkUniteMesure])
  useEffect(() => {
    if (articleSelected?.fkUniteVente) fetchGetUniteVente(articleSelected.fkUniteVente)
  }, [articleSelected?.fkUniteVente])

  useEffect(() => {
    if (articleSelected?.fkUniteVente) fetchGetUniteVente(articleSelected.fkUniteVente)
    if (articleSelected?.fkUniteMesure) fetchGetUniteMesure(articleSelected.fkUniteMesure)
    if (articleSelected?.fkTva) fetchGetTva(articleSelected.fkTva)
    if (articleSelected?.id) fetchGetEan(articleSelected.id)
    if (articleSelected?.id) fetchGetOrigine(articleSelected.id)
    if (articleSelected?.id) fetchGetFournisseur(articleSelected.id)
    if (articleSelected?.id) fetchGetPCB(articleSelected.id)
  }, [])

  const Loader = () => {
    return (
      <DataUsageRounded className="animate-spin text-grey-150 self-center" />
    );
  };

  const flEanMaitre = ean?.find((item) => item.flEanMaitre === true)?.code;
  return (
    <div className="row-span-2 w-full">
      <div className="flex justify-around align-center h-full px-2 bg-white-500 grid-col-24">
        <div className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer" onClick={() => navigate("general#block-produit-article")}>
          <span className="text-xs">{t('lib_article_code_externe')}</span>
          <span>{articleSelected?.codeExterne || "---"}</span>
        </div>
        <div className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer" onClick={() => navigate("general#block-produit-article")}>
          <span className="text-xs">{t('lib_article_fk_unite_vente')}</span>
          <span>{uniteVente?.nomUniteVente || "---"}</span>
        </div>
        <div className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer" onClick={() => navigate("general#block-produit-article")}>
          <span className="text-xs">{t('lib_article_poids_unitaire')}</span>
          <span>{articleSelected?.poidsUnitaire ? `${articleSelected.poidsUnitaire}g` : "---"}</span>
        </div>
        <div className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer" onClick={() => navigate("general#block-produit-article")}>
          <span className="text-xs">{t('lib_article_valeur_mesure')}</span>
          <span>{articleSelected?.valeurMesure || "---"} {uniteMesure?.nomCourtUniteMesure || '...'}</span>
        </div>
        <div className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer" onClick={() => navigate("general#block-produit-article")}>
          <span className="text-xs">{t('lib_article_fk_tva')}</span>
          <span>{tauxTva?.taux ?  `${tauxTva.taux * 100}%` : "---"}</span>
        </div>
        <div className="col-span-2 overflow-hidden flex flex-col justify-center">
          <span className="text-xs">{t('lib_article_type_saisonnalite')}</span>
          <span>{
            articleSelected?.typeSaisonnalite === "P" ? t('enu_type_saisonnalite_permament') :
            articleSelected?.typeSaisonnalite === "S" ? t('enu_type_saisonnalite_saisonnier') : "---"
          }</span>
        </div>
        <div className="col-span-2 overflow-hidden flex flex-col justify-center">
          <span className="text-xs">{t('lib_article_agrement_fk_fournisseur')} ({fournisseur?.length})</span>
          <span>{loading.fournisseur ? ( <Loader /> ) :
            fournisseur ? (fournisseur[0]?.nomRelationFournisseurConditionnement || "---") : "---"}</span>
        </div>
        <div className="col-span-2 overflow-hidden flex flex-col justify-center">
          <span className="text-xs">{t('lib_conditionnement_pcb')}</span>
          <span>{pcb ? (pcb[0]?.pcb || "---") : "---"}</span>
        </div>
        <div className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer"  onClick={() => navigate("droits-agrements#block-donnees-export-article")}>
          <span className="text-xs">{t('lib_reglementaire_origine')}</span>
          <span>{origine?.nomTechOrigine || "---"}</span>
        </div>
        {/* <div className="overflow-hidden flex flex-col justify-center">
          <span className="text-xs">{t('lib_reglementaire_origine')}</span>
          <span>Import</span>
        </div> */}
        <div onClick={() => { navigate("general#block-ean-article-list") }}
          className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer"
        >
          <span className="text-xs">{t('lib_article_fk_ean')}: {t('lib_ean_fl_ean_maitre')} ({ean?.length})</span>
          <span>{flEanMaitre || "---"}</span>
        </div>
        <div className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer" onClick={() => { navigate("general#block-ean-article-list") }}>
          { !flEanMaitre ? ( <Loader /> ) : 
            <CodeBar
              height={45}
              width={1.5}
              value={flEanMaitre}
              displayValue={false}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default ProduitPresentation