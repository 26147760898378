import { FunctionComponent, useEffect, useState } from 'react';

import { fournisseursApi, tiersContactsApi } from "api";
import { LieuFonctionViewModel, TiersContactWithContactViewModel } from "openapi-typescript-codegen";

import Informations from 'components/Fournisseur/TabGeneral/Informations';
import Contacts from 'components/Fournisseur/Contacts';
import ListeLieuxFonctions from 'components/Fournisseur/TabGeneral/ListeLieuxFonctions';
import DivisionGrid from 'components/BlockGrid/DivisionGrid';

import { useFournisseur } from "context/Referencement";
import { api } from "../../../utils";

interface Props { }

type GeocodeRes = {
  results?: {
    address_components: any[],
    formatted_address: string,
    geometry: {
      location: { lat: number, lng: number; },
      location_type: string,
      viewport: { northeast: { lat: number, lng: number; }, southwest: { lat: number, lng: number; }; };
    },
    place_id: string;
    types: string[];
  }[];
  status: string;
};

const TabGeneral: FunctionComponent<Props> = () => {
  const { state: fournisseur } = useFournisseur();
  const [lieux, setLieux] = useState<(LieuFonctionViewModel & { position: google.maps.LatLng; })[]>([]);

  // Refresh the list of lieux when the fournisseur changes:
  useEffect(() => {
    fournisseur?.id && fetchData(fournisseur.id);
  }, [fournisseur?.id]);

  // Get the data for the block with the list of locations:
  const fetchData = async (id: number) => {
    const { data } = await fournisseursApi.v1FournisseursIdLieuFonctionsGet(id);
    const $lieux: typeof lieux = [];
    const promises = data.map(async item => {
      const geoRes: GeocodeRes = await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + item.adresseLieuFonction + "&key=AIzaSyAe7N3vsTjEdigk0gjvnbpXxUUSfVPHN4M")
        .then(response => response.json());
      if (geoRes.results?.length) {
        const point = geoRes.results?.[0]?.geometry.location;
        point?.lat && point?.lng && $lieux.push({ ...item, position: new google.maps.LatLng(point.lat, point.lng) });
      }
    });
    await Promise.allSettled(promises);
    setLieux($lieux);
  };

  const fetchTiersContacts = async () => await api.dataset.get<TiersContactWithContactViewModel>(fournisseursApi.v1FournisseursIdTiersContactsGet(fournisseur?.id as number));
  const updateContact = (id: number) => fournisseursApi.v1FournisseursIdContactContactIdPost(fournisseur?.id!, id);
  const deleteTiersContact = (id: number) => tiersContactsApi.v1TiersContactsIdDelete(id);

  return (
    <div id="general" className="h-full flex flex-wrap justify-between bg-grey-150 gap-px p-px">
      <Informations sm={100} md={100} lg={100} xl={40} doubleXl={33} />
      {/* <Adresse sm={100} md={50} lg={40} xl={30} doubleXl={30} /> */}
      <Contacts onFetch={fetchTiersContacts} onDelete={deleteTiersContact} onUpdate={updateContact} identifier={fournisseur?.id} sm={100} md={100} lg={100} xl={60} doubleXl={66} />
      {!!lieux.length && (
        <DivisionGrid sm={100} md={100} lg={100} xl={100} doubleXl={100} >
          <ListeLieuxFonctions lieux={lieux} />
        </DivisionGrid>
      )}
    </div>
  );
};

export default TabGeneral;