import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ICellRendererParams, ValueGetterParams, } from "ag-grid-community";

import BreadCrumb from "components/Breadcrumb";
import H1Title, { Title } from "components/H1Title";
import CreateButton from "components/CreateButton";
import { CustomColDef, RowDataType } from "components/AGGride/gridTypes";

import commandeImagefirst from "assets/images/list-commande-first.png";
import commandeImagesecond from "assets/images/list-commande-second.png";
import commandeImageblue from "assets/images/list-commande-blue.png";
import commandeImageyellow from "assets/images/list-commande-yellow.png";
import commandeImagelast from "assets/images/list-commande-last.png";
import ClientSideGrid from "components/AGGride/ClientSideGrid";

const ListeCommandes: FunctionComponent = () => {
  const { t } = useTranslation();
  const breadcrumb = [
    {
      name: "Applications",
      link: `/app`,
    },
    {
      name: "Appro, stock, précompt",
      link: `/app/approvisionnement`,
    },
    {
      name: "Liste des commandes",
    },
  ];

  const title: Title = {
    title: "Approvisionnement",
    icon: "approvisionnement",
  };

  const [commandes, setCommandes] = useState<RowDataType[]>([]);

  useEffect(() => {
    fetch(
      "https://app-028a9166-98f2-4964-bae5-2c9225d44dfd.cleverapps.io/articles"
    )
      .then((res) => res.json())
      .then((result) => setCommandes(result.articles));
  }, []);

  const columnDefs = useMemo((): CustomColDef[] => [
    {
      colId: "select-column",
      type: "select",
      pinned: "left",
      suppressMovable: true,
      lockPosition: true,
      width: 10,
    },
    {
      headerName: t("N° Cde"),
      pinned: "left",
      field: "code",
      width: 140,
      cellRenderer: (params: ICellRendererParams) => (
        <Link to={`${params.value}`}>{params.value}</Link>
      ),
    },
    {
      headerName: t("Date Cde"),
      pinned: "left",
      field: "code",
      width: 140,
      cellRenderer: (params: ICellRendererParams) => (
        <Link to={`${params.value}`}>{params.value}</Link>
      ),
    },
    {
      colId: "designation",
      headerName: t("Fournisseur"),
      field: "designationStandard",
      width: 300,
    },
    { headerName: t("Circuit"), field: "eanPrincipal", width: 140 },
    { headerName: t("Nb lig"), field: "eanPrincipal", width: 140 },

    { headerName: t("Dte récpt"), field: "marqueNom", width: 180 },
    {
      headerName: t("Mt Achat"),
      field: "codeEtatNom",
      width: 200,
    },
    { headerName: t("Mt Vente"), field: "" }, // A gérer plus tard selon la spec
    {
      colId: "code-fournisseur",
      headerName: t("Doc"),
      field: "relationFournisseur.circuitLogistique.fournisseur.code",
    },
    {
      headerName: t("Nb colis"),
      field: "relationFournisseur.nom",
    },
    {
      headerName: t("Nb pltte"),
      valueGetter: (params: ValueGetterParams) =>
        params.data.fluxFournisseurCode != null &&
          params.data.fluxFournisseurNom != null
          ? params.data.fluxFournisseurCode +
          " - " +
          params.data.fluxFournisseurNom
          : "",
    },
    { headerName: t("Etat"), field: "" }, //  A gérer plus tard selon la spec
    {
      headerName: t("Litige"),
      field: "prixAchat",
      width: 100,
    },
    { headerName: t("PA / DPR"), field: "" }, //  A gérer plus tard selon la spec
    {
      headerName: t("lib_prix_vente_px_vente"),
      field: "prixVente",
      width: 100,
    },
    {
      headerName: t("lib_article_fk_type_article"),
      field: "typeArticleNom",
      width: 220,
    },
  ], []);

  return (
    <>
      <div className="mt-5">{<H1Title content={title} />}</div>

      <BreadCrumb content={breadcrumb} />
      <CreateButton content={"commande"} />

      <div className="flex flex-row justify-between my-2">
        <img src={commandeImagefirst} alt="" />
        <img src={commandeImagesecond} alt="" />
        <img src={commandeImageblue} alt="" />
        <img src={commandeImageblue} alt="" />
        <img src={commandeImageyellow} alt="" />
        <img src={commandeImageyellow} alt="" />
        <img src={commandeImagelast} alt="" />
      </div>
      <span className="text-sm font-semibold text-store-primary ">
        388 résultats sur 450 éléments
      </span>
      <div style={{ height: "65vh", width: "100%" }}>
        <ClientSideGrid
          className="block-grid-adaptive"
          rowData={commandes}
          columnDefs={columnDefs}
        />
      </div>
    </>
  );
};

export default ListeCommandes;
