import {FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import { SousFamilleViewModel, } from "openapi-typescript-codegen";
import { useTranslation } from "react-i18next";
import { sousFamillesApi } from 'api';
import { PropsBlock} from 'components/BlockGrid';
import { useGridController } from "hooks";
import { TextCellRenderer } from "components/AGGride/CellRender";
import BlockGridNomenclature from "../BlockGridNomenclature";
import NomenclatureContext from "../NomenclatureContext";
import SousFamilleModal from "../modals/SousFamilleModal";
import { SousFamilleViewModelNomenclatureDetails } from "openapi-typescript-codegen/types/models/sous-famille-view-model-nomenclature-details";

interface Props extends PropsBlock {
  sousFamilles: SousFamilleViewModelNomenclatureDetails[];
  onSousFamilleChange: (id: number | null) => void;
}

type GridType = SousFamilleViewModel;

const SousFamille: FunctionComponent<Props> = ({ sousFamilles, onSousFamilleChange }) => {
  const { t } = useTranslation();
  const { nomenclature, setRefresh } = useContext(NomenclatureContext);
  const [disableCreate, setDisableCreate] = useState(false);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.id,
      fetchData: async () => sousFamilles.map(({ item }) => item),
      deleteData: (id) => sousFamillesApi.v1SousFamillesIdDelete(id).then(() => {
        id === nomenclature.sousFamille ? onSousFamilleChange(null) : setRefresh(true);
      }),
      rowClassRules: {
        "hide-actions": params => {
          const sousFamille = sousFamilles.find(sousFamille => sousFamille.item.id === params.data?.id);
          return !!(sousFamille && (sousFamille.childrenCount || sousFamille.articleCount));
        },
      },
      columnDefs: [
        {
          field: 'code',
          headerName: t('lib_sous_famille_code'),
          headerTooltip: t('lib_sous_famille_code'),
          cellRenderer: TextCellRenderer,
          editable: false,
          filter: 'agTextColumnFilter',
          flex: 1
        },
        {
          field: 'nomSf',
          headerName: t('lib_sous_famille_nom_sous_famille'),
          headerTooltip: t('lib_sous_famille_nom_sous_famille'),
          cellRenderer: TextCellRenderer,
          valueFormatter: (params: any) => {
            const sousFamille = sousFamilles.find(sousFamille => sousFamille.item.id === params.data.id);
            return sousFamille ? `${sousFamille.item.nomSf} <${sousFamille.childrenCount}>` : String();
          },
          editable: false,
          filter: 'agTextColumnFilter',
          flex: 4
        },
      ],
    }), [sousFamilles, t])
  );

  useEffect(() => { gridController.fetchData(); }, [sousFamilles]);
  useEffect(() => { setDisableCreate(!nomenclature.famille); }, [nomenclature.famille]);

  return <BlockGridNomenclature
    label={"tab_detail_sous_famille"}
    gridController={gridController}
    onSelect={onSousFamilleChange}
    gridName={"sousFamille"}
    disableCreate={disableCreate}
  ><SousFamilleModal /></BlockGridNomenclature>;
};

export default SousFamille;