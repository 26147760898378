import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {DepartementInsertionModel, DepartementViewModel } from "openapi-typescript-codegen";
import { departementsApi } from "api";
import BlockGrid, {LayoutEnum, PropsBlock} from 'components/BlockGrid';
import { showPrettyError, toast } from "utils";
import {DateInput, NumberInput, TextInput} from "../../../components/InputWrapper";
import NomenclatureContext from "../NomenclatureContext";

interface Props extends PropsBlock {
  setIsOpen?: (v: boolean) => void;
  isOpen?: boolean;
  data?: DepartementViewModel;
}

const DepartementModal: FunctionComponent<Props> = ({ setIsOpen, isOpen, data }) => {
  const { t } = useTranslation();
  const { nomenclature, setNomenclature, setRefresh } = useContext(NomenclatureContext);
  const [departement, setDepartement] = useState<Partial<DepartementViewModel>>({});

  useEffect(() => {
    if (isOpen) { data ? setDepartement(data) : setDepartement({ fkEnseigne: nomenclature.enseigne as number }); }
  }, [isOpen]);

  function isUpdate() { return departement.hasOwnProperty("id"); }

  async function handleUpdate() {
    if (isUpdate()) {
      try {
        await departementsApi.v1DepartementsPut(departement as DepartementViewModel);
        toast.success(t("mes_validation_modification"));
        setIsOpen && setIsOpen(false);
      } catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    } else { await handleCreate(); }
  }

  async function handleCreate() {
    try {
      const { data: $departement } = await departementsApi.v1DepartementsPost(departement as DepartementInsertionModel);
      toast.success(t("mes_validation_creation"));
      setNomenclature({ ...nomenclature, departement: $departement.id, rayon: null, famille: null, sousFamille: null, uniteBesoin: null });
      setIsOpen && setIsOpen(false);
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  }

  return (
    <BlockGrid
      layout={LayoutEnum.modal}
      title={isUpdate() ? t('tit_modification_departement') : t('tit_creation_departement')}
      informations={''}
      toActivate={true}
      handleUpdate={async () => { await handleUpdate().then(() => { setRefresh(true); }); }}
      handleCancel={() => { setIsOpen && setIsOpen(false); }}
    >
      <form onSubmit={$event => $event.preventDefault()} className="flex flex-col">
        <div className="grid grid-cols-6 gap-4">
          <NumberInput labelClassAdd="col-span-1" label={t("lib_departement_code")}
                       value={departement} field="code" setValue={setDepartement} required={true} min={1} />
          <TextInput labelClassAdd="col-span-5" label={t("lib_departement_nom_departement")}
                     value={departement} field="nomDepartement" setValue={setDepartement} required={true} />
          { isUpdate() &&
            <>
                <div className={"col-span-4"}></div>
                <DateInput labelClassAdd="col-span-2" label={t("lib_enseigne_date_maj")}
                           value={departement} field="dateMaj"  disabled={true} />
            </>
          }
        </div>
      </form>
    </BlockGrid>
  );
};

export default DepartementModal;