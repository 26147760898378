import { ReactNode } from "react";

import Header from "components/Header";
import SideBar from "components/SideBar";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => {
  const location = useLocation();

  const disabledFor = [
    '/login'
  ];

  const useLayout = () => {
    return !disabledFor.some(x => x === location.pathname);
  };

  return useLayout() ?
    (
      <>
        <SideBar />
        <Header />
        <main>
          <div className="col-start-2 col-span-22 flex flex-col">{children}</div>
        </main>
      </>
    ) :
    (<>
      <div style={{ height: '100vh' }}>{children}</div>
    </>);
};

export default Layout;
