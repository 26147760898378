import {FunctionComponent, useEffect, useState} from "react";
import H1Title, {Title} from "components/H1Title";
import {api} from "utils";
import BreadCrumb from "../../components/Breadcrumb";
import MarquesContext from "./MarquesContext";
import Typologie from "./grids/Typologie";
import SurMarque from "./grids/SurMarque";
import {TypoNomenclatureResult} from "openapi-typescript-codegen";
import {typologiesApi} from "../../api";
import {
  TypologieViewModelTypoNomenclatureDetail
} from "openapi-typescript-codegen/types/models/typologie-view-model-typo-nomenclature-detail";
import {
  SurMarqueViewModelTypoNomenclatureDetail
} from "openapi-typescript-codegen/types/models/sur-marque-view-model-typo-nomenclature-detail";
import {
  MarqueViewModelTypoNomenclatureDetail
} from "openapi-typescript-codegen/types/models/marque-view-model-typo-nomenclature-detail";
import {
  SousMarqueViewModelTypoNomenclatureDetail
} from "openapi-typescript-codegen/types/models/sous-marque-view-model-typo-nomenclature-detail";
import {TypologieMarqueViewSearch} from "openapi-typescript-codegen/types/models/typologie-marque-view-search";
import Marque from "./grids/Marque";
import SousMarque from "./grids/SousMarque";

const Marques: FunctionComponent = () => {
  const [typologies, setTypologies] = useState<TypologieViewModelTypoNomenclatureDetail[]>([]);
  const [surMarques, setSurMarques] = useState<SurMarqueViewModelTypoNomenclatureDetail[]>([]);
  const [marques, setMarques] = useState<MarqueViewModelTypoNomenclatureDetail[]>([]);
  const [sousMarques, setSousMarques] = useState<SousMarqueViewModelTypoNomenclatureDetail[]>([]);

  const [refresh, setRefresh] = useState<boolean>(false);
  const [selection, setSelection] = useState<{
    typologie: number | null,
    surMarque : number | null,
    marque: number | null,
    sousMarque: number | null
  }>({ typologie: null, surMarque: null, marque: null, sousMarque: null });

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: "Marques",
    },
  ];
  const title: Title = {
    title: "Marques",
    icon: "marques",
  };

  async function fetchMarques() {
    const data = await api.data.get<TypoNomenclatureResult>(typologiesApi.v1TypologiesViewMarquesPost(selection));
    console.log(data);
    setTypologies(data.typologies);
    setSurMarques(data.surMarques);
    setMarques(data.marques);
    setSousMarques(data.sousMarques);
    return data.selected;
  }

  function updateNomenclature(selected: TypologieMarqueViewSearch) {
    setSelection({ ...selection, ...selected });
    setRefresh(false);
  }

  // The data are refreshed only if the enseigne change or the refresh state is true:
  useEffect(() => { fetchMarques().then(updateNomenclature); }, []);
  useEffect(() => { refresh && fetchMarques().then(updateNomenclature); }, [refresh]);

  return (
    <MarquesContext.Provider value={ { refresh, setRefresh, selection, setSelection } }>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <div className="flex gap-1">
        <Typologie
          typologies={typologies}
          onTypologieChange={(id) => {
            setSelection({ ...selection, typologie: id, surMarque: null, marque: null, sousMarque: null });
            setRefresh(true);
          }}
        />
        <SurMarque
          surMarques={surMarques}
          onSurMarqueChange={(id) => {
            setSelection({ ...selection, surMarque: id, marque: null, sousMarque: null });
            setRefresh(true);
          }}
        />
        <Marque
          marques={marques}
          onMarqueChange={(id) => {
            setSelection({ ...selection, marque: id, sousMarque: null });
            setRefresh(true);
          }}
        />
        <SousMarque
          sousMarques={sousMarques}
          onSousMarqueChange={(id) => {
            setSelection({ ...selection, sousMarque: id });
          }}
        />
      </div>
    </MarquesContext.Provider>
  );
};

export default Marques;