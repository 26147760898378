import { FC } from 'react';

import Nomenclature from 'components/Article/Nomenclature';
import Libelles from 'components/Article/Libelles';
import Marques from 'components/Article/Marques';
import Caisses from 'components/Article/Caisses';
import Produit from 'components/Article/Produit';
import EAN from 'components/Article/EAN';
import LiaisonPere from 'components/Article/LiaisonPere';
import LiaisonFils from 'components/Article/LiaisonFils';

interface Props { }

const TabGeneral: FC<Props> = () => {
  return (
    <div id="general" className="flex flex-wrap justify-between bg-grey-150 gap-px p-px">
      {/**
        ** Usage sizing
        ** Total size = 100%
        ** 
      */}

      <Nomenclature sm={"full"} md={"full"} lg={"full"} xl={"full"} doubleXl={"full"} />
      <Libelles sm={100} md={33} lg={33} xl={33} doubleXl={33} />
      <Marques sm={100} md={33} lg={33} xl={33} doubleXl={33} />
      <Caisses sm={100} md={33} lg={33} xl={33} doubleXl={33} />
      <Produit sm={100} md={100} lg={50} xl={50} doubleXl={50} />
      <EAN sm={100} md={100} lg={35} xl={35} doubleXl={35} />
      <LiaisonPere sm={100} md={100} lg={50} xl={50} doubleXl={50} />
      <LiaisonFils sm={100} md={100} lg={50} xl={50} doubleXl={50} />
    </div>
  );
};

export default TabGeneral;