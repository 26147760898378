import { FunctionComponent } from 'react';

interface Props {

}

const TabMedias: FunctionComponent<Props> = () => {

  return (
    <div id="medias" className="flex flex-wrap justify-between bg-grey-150 gap-px p-px">
      <div className="text-2xl font-bold">Médias</div>
    </div>
  );
};

export default TabMedias;