import { useEffect, FunctionComponent } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

import { useAuth } from "hooks";

const OAuthRedirectCallback: FunctionComponent = () => {
  const { search } = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const { code, state } = qs.parse(search, { ignoreQueryPrefix: true });
    if (!code || !state)
      return;

    auth.onLogIn(
      { code: code.toString(), state: state.toString() },
      (nextPath) => navigate(nextPath, { replace: true })
    );
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      Loading...
    </div>
  );
};

export default OAuthRedirectCallback;