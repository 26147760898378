export const TYPE_FOURNISSEUR = "type-fournisseur";
export const MODE_TRANSMISSION = "mode-transmission";
export const MODE_APPROVISIONNEMENT = "mode-approvisionnement";
export const ALGORITHME_APPROVISIONNEMENT = "algorithme-approvisionnement";
export const TEMPERATURE = "temperature";
export const REMISE = "remise";
export const TYPE_PIECE = "type-piece";
export const MODE_REGLEMENT = "mode-reglement";
export const CONDITION_REGLEMENT = "condition-reglement";
export const TYPE_TRANSPORT = "type-transport";
export const TAILLE_CONTAINER = "taille-container";
export const CONTACT = "contact";
export const CLASSE = "classe";
export const CODE_ETAT = "code-etat";
export const ESPECE = "espece";
export const INCOTERM = "incoterm";
export const INDICATIF_EAN = "indicatif-ean";
export const MARQUE = "marques";
export const ORIGINE = "origine";
export const TAXE = "taxe-general";
export const TAXE_INDIRECTE = "taxe-indirecte";
export const TYPE_ARTICLE = "type-article";
export const TYPE_EAN = "type-ean";
export const TYPE_LIAISON = "type-liaison";
export const UNITE_MESURE = "unite-mesure";
export const UNITE_VENTE = "unite-vente";
export const VARIETE = "variete";
export const TYPE_CONDITIONNEMENT = "type-conditionnement";
export const CERTIFICAT = "certificat";
export const CONSTITUANT = "constituant";
export const TRAITEMENT_CHIMIQUE = "traitement-chimique";
export const SECTION = "section";
export const NOMENCLATURE_DOUANIERE = "nomenclature-douaniere";
export const COMMUNE = "commune";
export const DEVISE = "devise";
export const PAYS = "pays";
export const TAUX_TVA = "taux_tva";
export const HOME = "home";
export const STATUT_COMMANDE = "statut-commande";

export const PARAMETRES = "parametres";
export const PARAMETRES_GENERAL = "referentiel";
export const PARAMETRES_FOURNISSEUR = "fournisseur";
export const PARAMETRES_ARTICLE = "article";
export const PARAMETRES_TIERS = "tiers";
export const APPLICATIONS = "app";

export const PARAMETRES_GENERAL_ROUTE = `${PARAMETRES}/${PARAMETRES_GENERAL}`;
export const PARAMETRES_FOURNISSEUR_ROUTE = `${PARAMETRES}/${PARAMETRES_FOURNISSEUR}`;
export const PARAMETRES_ARTICLE_ROUTE = `${PARAMETRES}/${PARAMETRES_ARTICLE}`;
export const PARAMETRES_TIERS_ROUTE = `${PARAMETRES}/${PARAMETRES_TIERS}`;

export const REFERENCEMENT = "referencement";
export const APPROVISIONNEMENT = "approvisionnement";

export const REFERENCEMENT_NOMENCLATURE = "nomenclature";
export const REFERENCEMENT_NOMENCLATURE_ROUTE = `${REFERENCEMENT_NOMENCLATURE}`;

export const REFERENCEMENT_FOURNISSEURS = "fournisseurs";
export const REFERENCEMENT_FOURNISSEURS_ROUTE = `${REFERENCEMENT_FOURNISSEURS}`;

export const REFERENCEMENT_ARTICLES = "articles";
export const REFERENCEMENT_ARTICLES_ROUTE = `${REFERENCEMENT_ARTICLES}`;

export const REFERENCEMENT_REFERENTIEL = PARAMETRES_GENERAL;
export const REFERENCEMENT_REFERENTIEL_ROUTE = `${REFERENCEMENT_REFERENTIEL}`;

export const REFERENCEMENT_COMPLETUDE_ARTICLES = "completude-articles";
export const REFERENCEMENT_COMPLETUDE_ARTICLES_ROUTE = `${REFERENCEMENT_ARTICLES}/${REFERENCEMENT_COMPLETUDE_ARTICLES}`;

export const APPRO_APPROVISIONNEMENT = "commandes";

export const REFERENTIEL_PARAMETERS = {
  title: "tit_parametres_referentiel",
  icon: "referencement-icon",
  active: false,
  hidden: true,
  items: [
    {
      name: "men_para_commune",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        PARAMETRES_GENERAL +
        "/" +
        PARAMETRES +
        "/" +
        COMMUNE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_general_devise",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        PARAMETRES_GENERAL +
        "/" +
        PARAMETRES +
        "/" +
        DEVISE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_pays",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        PARAMETRES_GENERAL +
        "/" +
        PARAMETRES +
        "/" +
        PAYS,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_tva",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        PARAMETRES_GENERAL +
        "/" +
        PARAMETRES +
        "/" +
        TAUX_TVA,
      icon: "parametre",
      hasParameters: false,
    },
  ],
};

export const ARTICLES_PARAMETERS = {
  title: "tit_parametres_article",
  icon: "referencement-icon",
  active: false,
  hidden: true,
  items: [
    {
      name: "men_para_classe",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        CLASSE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_code_etat",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        CODE_ETAT,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_espece",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        ESPECE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_incoterm",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        INCOTERM,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_indicatif_ean",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        INDICATIF_EAN,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_origine",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        ORIGINE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_taxe_generale",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        TAXE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_taxe_indirecte",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        TAXE_INDIRECTE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_type_article",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        TYPE_ARTICLE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_type_conditionnement",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        TYPE_CONDITIONNEMENT,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_type_ean",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        TYPE_EAN,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_type_liaison",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        TYPE_LIAISON,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_unite_mesure",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        UNITE_MESURE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_unite_vente",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        UNITE_VENTE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_variete",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        VARIETE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_certificat",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        CERTIFICAT,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_constituant",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        CONSTITUANT,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_traitement_chimique",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        TRAITEMENT_CHIMIQUE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_section",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        SECTION,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_nomenclature_douaniere",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_ARTICLES +
        "/" +
        PARAMETRES +
        "/" +
        NOMENCLATURE_DOUANIERE,
      icon: "parametre",
      hasParameters: false,
    },
  ],
};

export const FOURNISSEURS_PARAMETERS = {
  title: "tit_parametres_fournisseur",
  icon: "referencement-icon",
  active: false,
  hidden: true,
  items: [
    {
      name: "men_contact",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_FOURNISSEURS +
        "/" +
        PARAMETRES +
        "/" +
        CONTACT,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_type_fournisseur",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_FOURNISSEURS +
        "/" +
        PARAMETRES +
        "/" +
        TYPE_FOURNISSEUR,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_mode_transmission",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_FOURNISSEURS +
        "/" +
        PARAMETRES +
        "/" +
        MODE_TRANSMISSION,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_mode_approvisionnement",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_FOURNISSEURS +
        "/" +
        PARAMETRES +
        "/" +
        MODE_APPROVISIONNEMENT,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_algorithme_appro",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_FOURNISSEURS +
        "/" +
        PARAMETRES +
        "/" +
        ALGORITHME_APPROVISIONNEMENT,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_temperature",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_FOURNISSEURS +
        "/" +
        PARAMETRES +
        "/" +
        TEMPERATURE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_fournisseur_remise",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_FOURNISSEURS +
        "/" +
        PARAMETRES +
        "/" +
        REMISE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_type_piece",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_FOURNISSEURS +
        "/" +
        PARAMETRES +
        "/" +
        TYPE_PIECE,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_mode_reglement",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_FOURNISSEURS +
        "/" +
        PARAMETRES +
        "/" +
        MODE_REGLEMENT,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_condition_reglement",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_FOURNISSEURS +
        "/" +
        PARAMETRES +
        "/" +
        CONDITION_REGLEMENT,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_type_transport",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_FOURNISSEURS +
        "/" +
        PARAMETRES +
        "/" +
        TYPE_TRANSPORT,
      icon: "parametre",
      hasParameters: false,
    },
    {
      name: "men_para_taille_container",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        REFERENCEMENT +
        "/" +
        REFERENCEMENT_FOURNISSEURS +
        "/" +
        PARAMETRES +
        "/" +
        TAILLE_CONTAINER,
      icon: "parametre",
      hasParameters: false,
    },
  ],
};

export const COMMANDES_PARAMETRES = {
  title: "tit_parametres_fournisseur",
  icon: "approvisionnement-icon",
  active: false,
  hidden: true,
  items: [
    {
      name: "men_para_statut_commande",
      url:
        "/" +
        APPLICATIONS +
        "/" +
        APPROVISIONNEMENT +
        "/" +
        APPRO_APPROVISIONNEMENT +
        "/" +
        PARAMETRES +
        "/" +
        STATUT_COMMANDE,
      icon: "parametre",
      hasParameters: false,
    },
  ],
};
