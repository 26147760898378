import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { MarqueInsertionModel,  MarqueViewModel } from "openapi-typescript-codegen";
import BlockGrid, {LayoutEnum, PropsBlock} from 'components/BlockGrid';
import { showPrettyError, toast } from "utils";
import { TextInput } from "../../../components/InputWrapper";
import MarquesContext from "../MarquesContext";
import { marquesApi } from "../../../api";

interface Props extends PropsBlock {
  setIsOpen?: (v: boolean) => void;
  isOpen?: boolean;
  data?: MarqueViewModel;
}

const MarqueModal: FunctionComponent<Props> = ({ setIsOpen, isOpen, data }) => {
  const { t } = useTranslation();
  const { selection, setSelection, setRefresh } = useContext(MarquesContext);
  const [marque, setMarque] = useState<Partial<MarqueViewModel>>({});

  useEffect(() => {
    if (isOpen) { data ? setMarque(data) : setMarque({ fkSurMarque: selection.surMarque as number }); }
  }, [isOpen]);

  async function handleUpdate() {
    if (marque.hasOwnProperty("id")) {
      try {
        await marquesApi.v1MarquesPut(marque as MarqueViewModel);
        toast.success(t("mes_validation_modification"));
        setIsOpen && setIsOpen(false);
      } catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    } else { await handleCreate(); }
  }

  async function handleCreate() {
    try {
      const { data: $marque } = await marquesApi.v1MarquesPost(marque as MarqueInsertionModel);
      toast.success(t("mes_validation_creation"));
      setSelection({ ...selection, marque: $marque.id, sousMarque: null });
      setIsOpen && setIsOpen(false);
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  }

  return (
    <BlockGrid
      layout={LayoutEnum.modal}
      title={marque.hasOwnProperty("id") ? t('tit_modification_marque') : t('tit_creation_marque')}
      informations={''}
      toActivate={true}
      handleUpdate={async () => { await handleUpdate().then(() => { setRefresh(true); }); }}
      handleCancel={() => { setIsOpen && setIsOpen(false); }}
    >
      <form onSubmit={$event => $event.preventDefault()} className="flex flex-col">
        <div className="grid grid-cols-6 gap-4">
          <TextInput labelClassAdd="col-span-1" label={t("lib_marque_code")}
                     value={marque} field="code" setValue={setMarque} required={true} />
          <TextInput labelClassAdd="col-span-5" label={t("lib_marque_nom_marque")}
                     value={marque} field="nomMarque" setValue={setMarque} required={true} />
          {/*<FileInput labelClassAdd="col-span-2" label={t("lib_marque_logo_url")}*/}
          {/*           value={marque} field="logoMarque" setValue={() => { }}*/}
          {/*           accept="image/*"*/}
          {/*/>*/}
        </div>
      </form>
    </BlockGrid>
  );
};

export default MarqueModal;