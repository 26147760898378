import { AxiosResponse } from "axios";

export const isPromiseFulfilled = <T>(x: PromiseSettledResult<T>): x is PromiseFulfilledResult<T> => x.status === 'fulfilled';

export const isPromiseRejected = (x: PromiseSettledResult<any>): x is PromiseRejectedResult => x.status === 'rejected';

export const isAxiosResponse = <T = any, D = any>(x: any): x is AxiosResponse<T, D> =>
    Object.hasOwn(x, 'data') && Object.hasOwn(x, 'status') && Object.hasOwn(x, 'statusText') &&
    Object.hasOwn(x, 'headers') && Object.hasOwn(x, 'config');

export const isNumberOrNaN = (x: any): x is number => typeof x === 'number';

/**
 * Alternative to Array.isArray that correctly narrows the type for readonly arrays.
 */
export const isArray = <T>(arg: unknown): arg is readonly T[] => Array.isArray(arg);