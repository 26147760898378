import { FunctionComponent } from 'react';

import BlockGrid from 'components/BlockGrid';

interface Props {

}

const TabHistorique: FunctionComponent<Props> = () => {
  return (
    <div id="historique" className="h-full flex flex-wrap justify-between bg-grey-150 gap-px p-px">
      {/**
        ** Usage sizing
        ** Total size = 100%
        ** 
      */}

      {/* <Nomenclature sm={"full"} md={"full"} lg={"full"} xl={"full"} doubleXl={"full"} />
      <Libelles sm={100} md={55} lg={60} xl={60} doubleXl={60} />
      <Marques sm={100} md={45} lg={40} xl={40} doubleXl={40} />
      <Yuka sm={50} md={30} lg={30} xl={30} doubleXl={20} />
      <Caisses sm={50} md={30} lg={30} xl={30} doubleXl={20} /> */}

      <BlockGrid
        title="Historique"
        sm={50} md={30} lg={30} xl={30} doubleXl={20}
        img="http://app-dev.store1.fr/assets/open-food-facts-logo.svg"
        toActivate={false}>
        historique
      </BlockGrid>
    </div>
  );
};

export default TabHistorique;