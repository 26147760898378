import {
  ARTICLES_PARAMETERS,
  COMMANDES_PARAMETRES,
  FOURNISSEURS_PARAMETERS,
  MARQUE,
  REFERENTIEL_PARAMETERS,
} from "./applications";

export const HOME = "home";

export const PARAMETRES = "parametres";
export const PARAMETRES_GENERAL = "referentiel";
export const PARAMETRES_FOURNISSEUR = "fournisseurs";
export const PARAMETRES_ARTICLE = "articles";
export const PARAMETRES_COMMANDE = "commandes";
export const PARAMETRES_TIERS = "tiers";
export const APPLICATIONS = "applications";
export const DASHBOARD = "dashboard";
export const LOGIN = "login";

export const PARAMETRES_GENERAL_ROUTE = `${PARAMETRES_GENERAL}/${PARAMETRES}`;
export const PARAMETRES_FOURNISSEUR_ROUTE = `${PARAMETRES_FOURNISSEUR}/${PARAMETRES}`;
export const PARAMETRES_ARTICLE_ROUTE = `${PARAMETRES_ARTICLE}/${PARAMETRES}`;

export const PARAMETRES_COMMANDE_ROUTE = `${PARAMETRES_COMMANDE}/${PARAMETRES}`;

export const REFERENCEMENT = "referencement";

export const REFERENCEMENT_NOMENCLATURE = "nomenclature";
export const REFERENCEMENT_NOMENCLATURE_ROUTE = `${REFERENCEMENT_NOMENCLATURE}`;

export const REFERENCEMENT_FOURNISSEURS = "fournisseurs";
export const REFERENCEMENT_FOURNISSEURS_ROUTE = `${REFERENCEMENT_FOURNISSEURS}`;

export const REFERENCEMENT_ARTICLES = "articles";
export const REFERENCEMENT_ARTICLES_ROUTE = `${REFERENCEMENT_ARTICLES}`;

export const REFERENCEMENT_COMPLETUDE_ARTICLES = "completude-articles";
export const REFERENCEMENT_COMPLETUDE_ARTICLES_ROUTE = `${REFERENCEMENT_COMPLETUDE_ARTICLES}`;

export const APPRO_APPROVISIONNEMENT = "commandes";
export const APPRO_COMMANDE_ROUTE = `${APPRO_APPROVISIONNEMENT}`;

export const ROUTES: Map<string, string> = new Map([
  [HOME, HOME],
  [APPLICATIONS, APPLICATIONS],
  [DASHBOARD, DASHBOARD],
  [PARAMETRES_GENERAL_ROUTE, PARAMETRES_GENERAL_ROUTE],
  [REFERENCEMENT_NOMENCLATURE, REFERENCEMENT_NOMENCLATURE],
  [REFERENCEMENT_FOURNISSEURS, REFERENCEMENT_FOURNISSEURS],
  [REFERENCEMENT_COMPLETUDE_ARTICLES, REFERENCEMENT_COMPLETUDE_ARTICLES],
  [APPRO_APPROVISIONNEMENT, APPRO_APPROVISIONNEMENT],
  [REFERENCEMENT_ARTICLES, REFERENCEMENT_ARTICLES],
]);

export const REFERENCEMENT_MENU = {
  title: "tab_referencement",
  url: "referencement",
  icon: "referencement-icon",
  active: false,
  hidden: false,
  items: [
    {
      name: "men_refe_referentiel",
      // url: null,
      parametersUrl: PARAMETRES_GENERAL_ROUTE,
      icon: "referentiel",
      hasParameters: true,
      parameters: REFERENTIEL_PARAMETERS,
    },
    {
      name: "men_refe_nomenclature",
      url: REFERENCEMENT_NOMENCLATURE_ROUTE,
      icon: "nomenclature",
      hasParameters: false,
    },
    {
      name: "men_refe_fournisseurs",
      url: REFERENCEMENT_FOURNISSEURS_ROUTE,
      parametersUrl: PARAMETRES_FOURNISSEUR_ROUTE,
      icon: "fournisseurs",
      hasParameters: true,
      parameters: FOURNISSEURS_PARAMETERS,
    },
    {
      name: "men_refe_articles",
      url: REFERENCEMENT_ARTICLES_ROUTE,
      parametersUrl: PARAMETRES_ARTICLE_ROUTE,
      icon: "articles",
      hasParameters: true,
      parameters: ARTICLES_PARAMETERS,
    },
    {
      name: "men_refe_marques",
      url: MARQUE,
      icon: "marques",
      hasParameters: false,
    },
    {
      name: "men_para_completude_article",
      url: REFERENCEMENT_COMPLETUDE_ARTICLES_ROUTE,
      icon: "completude",
      hasParameters: false,
    },
  ],
};

export const APPROVISIONNEMENT_MENU = {
  title: "tab_appro",
  url: "approvisionnement",
  icon: "appro-icon",
  active: false,
  hidden: false,
  items: [
    {
      name: "men_appro_commandes",
      url: APPRO_COMMANDE_ROUTE,
      parametersUrl: PARAMETRES_COMMANDE_ROUTE,
      icon: "approvisionnement",
      hasParameters: true,
      parameters: COMMANDES_PARAMETRES,
    },
  ],
};

export const FAVORIS = {
  hidden: false,
  title: "tab_favoris",
  icon: "favoris-icon",
  active: false,
  items: [],
};

export const RECENTS = {
  hidden: false,
  title: "tab_recents",
  icon: "recent-icon",
  active: false,
  items: [],
};
