import { FunctionComponent } from "react";

export interface Title {
  title: string;
  icon?: string;
}

interface Props {
  content: Title;
}

const H2Title: FunctionComponent<Props> = ({ content }) => {
  return (
    <div className="flex flex-row items-end">
      <div>
        <h2 className="text-store-primary font-poppins not-italic font-bold text-lg text-left">
          {content.title}
        </h2>
      </div>
    </div>
  );
};

export default H2Title;
