
import { useState, useEffect, FC } from 'react';
import cs from 'classnames';

import { PropsBlock } from ".";

interface PropsDivision extends PropsBlock {
    width?: string;
    classNameAdd?: string;
}

export const DivisionGrid: FC<PropsDivision> = (
    { children, sm, md, lg, xl, doubleXl, width, classNameAdd }
) => {
    const [minW, setMinW] = useState<number>();
    const [winWidth, setWinWidth] = useState(window.innerWidth);
    const detectSize = () => { setWinWidth(window.innerWidth); };

    useEffect(() => {
        window.addEventListener('resize', detectSize);
        if (winWidth <= 640) setMinW(sm === "full" ? 100 : sm); //sm
        else if (winWidth <= 768) setMinW(md ? (md === "full" ? 100 : md) : (sm === "full" ? 100 : sm)); //md
        else if (winWidth <= 1024) setMinW(lg === "full" ? 100 : lg); //lg
        else if (winWidth <= 1280) setMinW(xl ? (xl === "full" ? 100 : xl) : (lg === "full" ? 100 : lg)); //xl
        else setMinW(doubleXl ? (doubleXl === "full" ? 100 : doubleXl) : ((xl ? (xl === "full" ? 100 : xl) : (lg === "full" ? 22 : lg)))); //2xl

        return () => {
            window.removeEventListener('resize', detectSize);
        };
    }, [winWidth, sm, md, lg, xl, doubleXl]);


    let sizeStyle;
    if (width) sizeStyle = { width: `calc(${width} - 1px)`, minWidth: `calc(${width} - 1px)`, maxWidth: `calc(${width} - 1px)` };
    else sizeStyle = { minWidth: `calc(${minW}% - 1px)` };

    return (
        <div
            style={sizeStyle}
            className={cs(classNameAdd, "flex-1 rounded-lg flex flex-wrap justify-between gap-px")}
        >
            {children}
        </div>
    );
};

export default DivisionGrid;