import { FunctionComponent } from "react";

import { Delete } from "@mui/icons-material";

import { SelectTemplate } from "components/SelectComplete";

interface PropsList {
  item: SelectTemplate,
  onDelete: (e: any) => void,
  activate?: boolean;
}

const SiteListElement: FunctionComponent<PropsList> = ({ item, onDelete, activate = true }) => {

  return (
    <div className="col-span-1">
      <div className={`flex w-full gap-2 relative ${activate && 'pr-12'} transition-all`}>
        <input type="text" tabIndex={5}
          value={item.label}
          disabled={true}
          className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
            disabled:border-slate-200 disabled:bg-white-500
            focus:border-store-primary `}
        />
        {activate &&
          <span className="absolute top-0 right-0">
            <button type="button" tabIndex={5}
              disabled={!activate}
              className="w-8 h-8 mt-1 border rounded border-slate-300 text-grey-200 bg-white-500 opacity-100
                    hover:text-white-500 hover:bg-red-500 focus:text-white-500 focus:bg-red-500"
              onClick={() => onDelete(item)}>
              <Delete />
            </button>
          </span>
        }
      </div>
    </div>
  );
};

export default SiteListElement;