import { FC, useMemo } from "react";

type HighlightSearchProps = {
  str: string;
  searchStr: string;
};

const HighlightSearch: FC<HighlightSearchProps> = ({ str, searchStr }) => {
  return useMemo(() => {
    const strLower = str.toLowerCase();
    const searchStrLower = searchStr.toUpperCase();
    if (!searchStrLower.length || !strLower.length)
      return (<>{str}</>);

    const firstOccurrence = str.indexOf(searchStr);

    if (firstOccurrence === -1)
      return (<>{str}</>);

    const prefix = str.slice(0, firstOccurrence);
    const sufix = str.slice(firstOccurrence + searchStr.length, undefined);

    return (<>{prefix}<b>{searchStr}</b>{sufix}</>);
  }, [str, searchStr]);
};

export default HighlightSearch;