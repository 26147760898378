import {FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import { UniteBesoinViewModel } from "openapi-typescript-codegen";
import { useTranslation } from "react-i18next";

import { uniteBesoinsApi} from 'api';
import { PropsBlock } from 'components/BlockGrid';
import { useGridController } from "hooks";
import { TextCellRenderer } from "components/AGGride/CellRender";
import BlockGridNomenclature from "../BlockGridNomenclature";
import NomenclatureContext from "../NomenclatureContext";
import UniteBesoinModal from "../modals/UniteBesoinModal";
import { UniteBesoinViewModelNomenclatureDetails } from "openapi-typescript-codegen/types/models/unite-besoin-view-model-nomenclature-details";

interface Props extends PropsBlock {
  uniteBesoins: UniteBesoinViewModelNomenclatureDetails[];
  onUniteBesoinChange: (id: number | null) => void;
}

type GridType = UniteBesoinViewModel;

const UniteBesoin: FunctionComponent<Props> = ({ uniteBesoins, onUniteBesoinChange }) => {
  const { t } = useTranslation();
    const { nomenclature, setRefresh } = useContext(NomenclatureContext);
    const [disableCreate, setDisableCreate] = useState(false);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.id,
      fetchData: async () => uniteBesoins.map(({ item }) => item),
      deleteData: (id) => uniteBesoinsApi.v1UniteBesoinsIdDelete(id).then(() => {
        id === nomenclature.uniteBesoin ? onUniteBesoinChange(null) : setRefresh(true);
      }),
      rowClassRules: {
        "hide-actions": params => {
          const uniteBesoin = uniteBesoins.find(uniteBesoin => uniteBesoin.item.id === params.data?.id);
          return !!(uniteBesoin && uniteBesoin.articleCount);
        },
      },
      columnDefs: [
        {
          field: 'code',
          headerName: t('lib_unite_besoin_code'),
          headerTooltip: t('lib_unite_besoin_code'),
          cellRenderer: TextCellRenderer,
          editable: false,
          filter: 'agTextColumnFilter',
          flex: 1
        },
        {
          field: 'nomUb',
          headerName: t('lib_unite_besoin_nom_ub'),
          headerTooltip: t('lib_unite_besoin_nom_ub'),
          cellRenderer: TextCellRenderer,
          valueFormatter: (params: any) => params.data.nomUb || String(),
          editable: false,
          filter: 'agTextColumnFilter',
          flex: 4
        },
        {
          headerName: t('lib_unite_besoin_nb_articles'),
          headerTooltip: t('lib_unite_besoin_nb_articles'),
          cellRenderer: TextCellRenderer,
          valueFormatter: (params: any) => {
            const uniteBesoin = uniteBesoins.find(uniteBesoin => uniteBesoin.item.id === params.data.id);
            return uniteBesoin ? uniteBesoin.articleCount.toString() : String();
          },
          editable: false,
          filter: 'agTextColumnFilter',
          flex: 1
        },
      ],
    }), [uniteBesoins, t])
  );

  useEffect(() => { gridController.fetchData(); }, [uniteBesoins]);
  useEffect(() => { setDisableCreate(!nomenclature.sousFamille); }, [nomenclature.sousFamille]);

  return <BlockGridNomenclature
    label={"tab_detail_unite_besoin"}
    gridController={gridController}
    onSelect={onUniteBesoinChange}
    gridName={"uniteBesoin"}
    disableCreate={disableCreate}
  ><UniteBesoinModal /></BlockGridNomenclature>;
};

export default UniteBesoin;