import { FunctionComponent, useEffect, useState } from 'react';
import SelectComplete, { SelectTemplate } from 'components/SelectComplete';
import { getColumnControl } from 'validators/ColumnControl/Controls';

import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { algorithmeApprovisionnementsApi } from 'api';
import { AlgorithmeApprovisionnementViewModel, CircuitLogistiqueViewModel } from "openapi-typescript-codegen";
import { useTranslation } from "react-i18next";
import CheckboxBouton from "components/CreateButton/Checkbox";
import { ColumnControl } from "validators";
import { api } from "utils";


const controls = [
  "circuit_logistique.origine",
  "circuit_logistique.fk_algorithme_appro",
  "circuit_logistique.calcul_prix",
  "circuit_logistique.flModifPcbCde",
  "circuit_logistique.flPaCde",
  "circuit_logistique.flGratuit",
  "circuit_logistique.flCdeMultiRay",
  "circuit_logistique.fl_reliquat",
  "circuit_logistique.fl_enlevement_marchandise",
  "circuit_logistique.fl_remise",
  "circuit_logistique.controle_reception",
  "circuit_logistique.hors_planning"
].map(getColumnControl);

interface Props extends PropsBlock {
  selectedCircuit: CircuitLogistiqueViewModel;
  onUpdateCircuit: (circuit: CircuitLogistiqueViewModel) => void;
}

const ModeApprovisionnement: FunctionComponent<Props> = ({ sm, md, lg, xl, doubleXl, selectedCircuit, onUpdateCircuit }) => {
  const { t } = useTranslation();
  const [completude, setCompletude] = useState<number>(0);
  const [activate, setActivate] = useState<boolean>(false);
  const [hasFocus, setFocus] = useState(false);
  const [loading, setLoader] = useState<boolean>(true);
  const [circuitLogistique, setCircuitLogistique] = useState<CircuitLogistiqueViewModel>(selectedCircuit);
  const [algorithmes, setAlgorithmes] = useState<SelectTemplate[]>([]);

  const origines = [
    { value: null, label: "---" },
    { value: 'L', label: t('enu_circuit_logistique_origine_local') },
    { value: 'I', label: t('enu_circuit_logistique_origine_import') }
  ];
  const commandes = [
    { value: null, label: "---" },
    { value: '1', label: t('enu_circuit_logistique_hors_planning_acceptee') },
    { value: '2', label: t('enu_circuit_logistique_hors_planning_rejetee') },
    { value: '3', label: t('enu_circuit_logistique_hors_planning_reportee') }
  ];
  const calculs = [
    { value: null, label: "---" },
    { value: 'E', label: t('enu_circuit_logistique_calcul_prix_entree') },
    { value: 'C', label: t('enu_circuit_logistique_calcul_prix_commande') }
  ];
  const controles = [
    { value: null, label: "---" },
    { value: '1', label: t('enu_circuit_logistique_controle_reception_obligatoire') },
    { value: '2', label: t('enu_circuit_logistique_controle_reception_sondage') }
  ];

  const validate = () => ColumnControl.validateAll(circuitLogistique, controls);

  const refreshCircuitLogistique = async () => {
    if (Object.keys(selectedCircuit).length) {
      setCircuitLogistique({ ...selectedCircuit });
      setLoader(false);
    }
  };

  const getAlgorithmes = async () => await api.dataset.get<AlgorithmeApprovisionnementViewModel>(algorithmeApprovisionnementsApi.v1AlgorithmeApprovisionnementsGet(1, 10000));

  useEffect(() => { selectedCircuit && refreshCircuitLogistique(); }, [selectedCircuit]);

  useEffect(() => {
    getAlgorithmes().then(data => {
      const options = api.options.generate(data, { label: "#{code} - #{nomAlgorithmeApprovisionnement}" }, true);
      setAlgorithmes(options);
    });
  }, []);

  function _checkCompletude() { setCompletude(ColumnControl.checkAll(circuitLogistique, controls)); }

  function handleUpdate() {
    validate() && onUpdateCircuit(circuitLogistique);
    setActivate(false);
  }

  function handleCancel() { refreshCircuitLogistique().then(() => { setActivate(false); }); }

  function handleFocus(focus: boolean) { setFocus(focus); }

  return (
    <BlockGrid
      title={t('tit_mode_approvisionnement')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      completude={completude}
      loading={loading}
      handleClick={(activation: boolean) => { selectedCircuit && setActivate(activation); }}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      handleFocus={handleFocus}
    >
      <form className="flex flex-col justify-between">
        <div className="w-full gap-2 grid grid-flow-col grid-rows-4 grid-cols-3">

          <label className="block mb-2" style={{ gridArea: '1 / 1 / 2 / 2' }}>
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_circuit_logistique_origine")}
                {getColumnControl("circuit_logistique.origine").getValidationLabelElement()}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={origines}
              value={origines.filter(option => {
                return option.value === circuitLogistique?.origine;
              })}
              onChange={($event: any): void => setCircuitLogistique({ ...circuitLogistique, origine: $event.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("circuit_logistique.origine").getValidationInputClass(activate, hasFocus, circuitLogistique)}}
              `}
            />
          </label>
          <label className="block mb-2" style={{ gridArea: '2 / 1 / 3 / 2' }}>
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_circuit_logistique_fk_algorithme_appro")}
                {getColumnControl("circuit_logistique.fk_algorithme_appro").getValidationLabelElement()}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={algorithmes}
              value={algorithmes.filter(option => {
                return option.value === circuitLogistique?.fkAlgorithmeAppro;
              })}
              onChange={($event: any): void => setCircuitLogistique({ ...circuitLogistique, fkAlgorithmeAppro: $event.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("circuit_logistique.fk_algorithme_appro").getValidationInputClass(activate, hasFocus, circuitLogistique)}}
              `}
            />
          </label>
          <label className="block mb-2" style={{ gridArea: '3 / 1 / 4 / 2' }}>
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_circuit_logistique_calcul_prix")}
                {getColumnControl("circuit_logistique.calcul_prix").getValidationLabelElement()}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={calculs}
              value={calculs.filter(option => {
                return option.value === circuitLogistique?.calculPrix;
              })}
              onChange={($event: any): void => setCircuitLogistique({ ...circuitLogistique, calculPrix: $event.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("circuit_logistique.calcul_prix").getValidationInputClass(activate, hasFocus, circuitLogistique)}
              `}
            />
          </label>
          <label className="block mb-2" style={{ gridArea: '4 / 1 / 5 / 2' }}>
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_circuit_logistique_controle_reception")}
                {getColumnControl("circuit_logistique.controle_reception").getValidationLabelElement()}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={controles}
              value={controles.filter(option => {
                return option.value === circuitLogistique?.controleReception;
              })}
              onChange={($event: any): void => setCircuitLogistique({ ...circuitLogistique, controleReception: $event.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("circuit_logistique.calcul_prix").getValidationInputClass(activate, hasFocus, circuitLogistique)}
              `}
            />
          </label>

          <label className="h-8 mt-[22px] w-full px-3 rounded-md text-sm bg-blue-store order-6
            disabled:border-slate-200 disabled:bg-white-500
            focus:brightness-110 hover:brightness-110 flex items-center
            grid grid-cols-24"
            style={{ gridArea: '1 / 2 / 2 / 3' }}>
            <CheckboxBouton
              className="col-span-4"
              onValueChange={val => setCircuitLogistique({ ...circuitLogistique, flModifPcbCde: val })}
              checked={circuitLogistique?.flModifPcbCde}
            />
            <span className="font-medium text-white-500 flex justify-between text-xs w-full col-span-20 self-center ml-4 leading-4"><span>{t("lib_circuit_logistique_fl_modif_pcb_cde")}</span></span>
          </label>
          <label className="h-8 mt-[22px] w-full px-3 rounded-md text-sm bg-blue-store order-6
            disabled:border-slate-200 disabled:bg-white-500
            focus:brightness-110 hover:brightness-110 flex items-center
            grid grid-cols-24"
            style={{ gridArea: '2 / 2 / 3 / 3' }}>
            <CheckboxBouton
              className="col-span-4"
              onValueChange={val => setCircuitLogistique({ ...circuitLogistique, flMajPrixCde: val })}
              checked={circuitLogistique?.flMajPrixCde}
            />
            <span className="font-medium text-white-500 flex justify-between text-xs w-full col-span-20 self-center ml-4 leading-4"><span>{t("lib_circuit_logistique_fl_maj_prix_cde")}</span></span>
          </label>
          <label className="h-8 mt-[22px] w-full px-3 rounded-md text-sm bg-blue-store order-7
            disabled:border-slate-200 disabled:bg-white-500
            focus:brightness-110 hover:brightness-110 flex items-center
            grid grid-cols-24"
            style={{ gridArea: '3 / 2 / 4 / 3' }}>
            <CheckboxBouton
              className="col-span-4"
              onValueChange={val => setCircuitLogistique({ ...circuitLogistique, flGratuit: val })}
              checked={circuitLogistique?.flGratuit}
            />
            <span className="font-medium text-white-500 flex justify-between text-xs w-full col-span-20 self-center ml-4 leading-4"><span>{t("lib_circuit_logistique_fl_gratuit")}</span></span>
          </label>
          <label className="h-8 mt-[22px] w-full px-3 rounded-md text-sm bg-blue-store order-8
            disabled:border-slate-200 disabled:bg-white-500
            focus:brightness-110 hover:brightness-110 flex items-center
            grid grid-cols-24"
            style={{ gridArea: '4 / 2 / 5 / 3' }}>
            <CheckboxBouton
              className="col-span-4"
              onValueChange={val => setCircuitLogistique({ ...circuitLogistique, flCdeMultiRay: val })}
              checked={circuitLogistique?.flCdeMultiRay}
            />
            <span className="font-medium text-white-500 flex justify-between text-xs w-full col-span-20 self-center ml-4 leading-4"><span>{t("lib_circuit_logistique_fl_cde_multi_ray")}</span></span>
          </label>

          <label className="h-8 mt-[22px] w-full px-3 rounded-md text-sm bg-blue-store order-9
            disabled:border-slate-200 disabled:bg-white-500
            focus:brightness-110 hover:brightness-110 flex items-center
            grid grid-cols-24"
            style={{ gridArea: '1 / 3 / 2 / 4' }}>
            <CheckboxBouton
              className="col-span-4"
              onValueChange={val => setCircuitLogistique({ ...circuitLogistique, flReliquat: val })}
              checked={circuitLogistique?.flReliquat}
            />
            <span className="font-medium text-white-500 flex justify-between text-xs w-full col-span-20 self-center ml-4 leading-4"><span>{t("lib_circuit_logistique_fl_reliquat")}</span></span>
          </label>
          <label className="h-8 mt-[22px] w-full px-3 rounded-md text-sm bg-blue-store order-10
            disabled:border-slate-200 disabled:bg-white-500
            focus:brightness-110 hover:brightness-110 flex items-center
            grid grid-cols-24"
            style={{ gridArea: '2 / 3 / 3 / 4' }}>
            <CheckboxBouton
              className="col-span-4"
              onValueChange={val => setCircuitLogistique({ ...circuitLogistique, flEnlevementMarchandise: val })}
              checked={circuitLogistique?.flEnlevementMarchandise}
            />
            <span className="font-medium text-white-500 flex justify-between text-xs w-full col-span-20 self-center ml-4 leading-4"><span>{t("lib_circuit_logistique_enlevement_marchandise")}</span></span>
          </label>
          <label className="h-8 mt-[22px] w-full px-3 rounded-md text-sm bg-blue-store order-11
            disabled:border-slate-200 disabled:bg-white-500
            focus:brightness-110 hover:brightness-110 flex items-center
            grid grid-cols-24"
            style={{ gridArea: '3 / 3 / 4 / 4' }}>
            <CheckboxBouton
              className="col-span-4"
              onValueChange={val => setCircuitLogistique({ ...circuitLogistique, flRemise: val })}
              checked={circuitLogistique?.flRemise}
            />
            <span className="font-medium text-white-500 flex justify-between text-xs w-full col-span-20 self-center ml-4 leading-4"><span>{t("lib_circuit_logistique_fl_remise")}</span></span>
          </label>

          <label className="block mb-2 flex-1 col-span-4" style={{ gridArea: '4 / 3 / 5 / 4' }}>
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_circuit_logistique_hors_planning")}
                {getColumnControl("circuit_logistique.hors_planning").getValidationLabelElement()}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={commandes}
              value={commandes.filter(option => {
                return option.value === circuitLogistique?.horsPlanning;
              })}
              onChange={($event: any): void => setCircuitLogistique({ ...circuitLogistique, horsPlanning: $event.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("circuit_logistique.hors_planning").getValidationInputClass(activate, hasFocus, circuitLogistique)}
              `}
            />
          </label>

        </div>
      </form>
    </BlockGrid>
  );
};

export default ModeApprovisionnement;