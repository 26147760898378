import { FunctionComponent, useEffect, useState } from "react";
import H1Title, { Title } from "components/H1Title";
import BreadCrumb from "components/Breadcrumb";
import Enseigne from "./grids/Enseigne";
import Departement from "./grids/Departement";
import { EnseigneNomenclatureResult, SiteEnseigneWithEnseigneViewModel } from "openapi-typescript-codegen";
import {  enseignesApi, sitesApi } from "api";
import { useSite } from "../../context/site";
import { api } from "utils";
import Rayon from "./grids/Rayon";
import Famille from "./grids/Famille";
import SousFamille from "./grids/SousFamille";
import UniteBesoin from "./grids/UniteBesoin";
import NomenclatureContext from "pages/Nomenclature/NomenclatureContext";
import { DepartementViewModelNomenclatureDetails } from "openapi-typescript-codegen/types/models/departement-view-model-nomenclature-details";
import { RayonViewModelNomenclatureDetails } from "openapi-typescript-codegen/types/models/rayon-view-model-nomenclature-details";
import { FamilleViewModelNomenclatureDetails } from "openapi-typescript-codegen/types/models/famille-view-model-nomenclature-details";
import { SousFamilleViewModelNomenclatureDetails } from "openapi-typescript-codegen/types/models/sous-famille-view-model-nomenclature-details";
import { UniteBesoinViewModelNomenclatureDetails } from "openapi-typescript-codegen/types/models/unite-besoin-view-model-nomenclature-details";
import { EnseigneNomenclatureViewSearch } from "openapi-typescript-codegen/types/models/enseigne-nomenclature-view-search";


const Nomenclature: FunctionComponent = () => {
  const { state: site } = useSite();
  const [siteEnseignes, setSiteEnseignes] = useState<SiteEnseigneWithEnseigneViewModel[]>([]);
  const [departements, setDepartements] = useState<DepartementViewModelNomenclatureDetails[]>([]);
  const [rayons, setRayons] = useState<RayonViewModelNomenclatureDetails[]>([]);
  const [familles, setFamilles] = useState<FamilleViewModelNomenclatureDetails[]>([]);
  const [sousFamilles, setSousFamilles] = useState<SousFamilleViewModelNomenclatureDetails[]>([]);
  const [uniteBesoins, setUniteBesoins] = useState<UniteBesoinViewModelNomenclatureDetails[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [nomenclature, setNomenclature] = useState<{
    enseigne: number | null,
    departement : number | null,
    rayon: number | null,
    famille: number | null,
    sousFamille: number | null,
    uniteBesoin: number | null
  }>({ enseigne: null, departement: null, rayon: null, famille: null, sousFamille: null, uniteBesoin: null });

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: "Nomenclature",
    },
  ];

  const title: Title = {
    title: "Nomenclature",
    icon: "nomenclature",
  };

  async function fetchSiteEnseignes() {
    const data = await api.dataset.get<SiteEnseigneWithEnseigneViewModel>(sitesApi.v1SitesIdSiteEnseignesGet(site?.id as number));
    setSiteEnseignes(data);
    const principal = data.find(siteEnseigne => siteEnseigne.flPrincipal)?.enseigne?.id || 0;
    setNomenclature({ ...nomenclature, enseigne: principal || data[0].enseigne.id });
  }

  async function fetchNomenclature() {
    if (nomenclature.enseigne) {
      const { enseigne, ...$nomenclature } = nomenclature;
      const data = await api.data.get<EnseigneNomenclatureResult>(enseignesApi.v1EnseignesEnseigneIdViewNomenclaturePost(enseigne, $nomenclature));
      setDepartements(data.departements);
      setRayons(data.rayons);
      setFamilles(data.familles);
      setSousFamilles(data.sousFamilles);
      setUniteBesoins(data.uniteBesoins);
      return data.selected;
    }
    return { departement: null, rayon: null, famille: null, sousFamille: null, uniteBesoin: null };
  }

  function updateNomenclature(selected: EnseigneNomenclatureViewSearch) {
    setNomenclature({ ...nomenclature, ...selected });
    setRefresh(false);
  }

  useEffect(() => { site?.id && fetchSiteEnseignes(); }, []);
  useEffect(() => { site?.id && fetchSiteEnseignes(); }, [site?.id]);
  // The data are refreshed only if the enseigne change or the refresh state is true:
  useEffect(() => { nomenclature.enseigne && fetchNomenclature().then(updateNomenclature); }, [nomenclature.enseigne]);
  useEffect(() => { refresh && fetchNomenclature().then(updateNomenclature); }, [refresh]);

  return (
    <NomenclatureContext.Provider value={ { refresh, setRefresh, nomenclature, setNomenclature } }>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <div className="flex gap-1">
        <Enseigne
            siteEnseignes={siteEnseignes}
            onEnseigneChange={(id) => {
              setNomenclature({ enseigne: id, departement: null, rayon: null, famille: null, sousFamille: null, uniteBesoin: null });
            }}
            updateSiteEnseignes={fetchSiteEnseignes}
        />
        <Departement
            departements={departements}
            onDepartementChange={(id) => {
              setNomenclature({ ...nomenclature, departement: id, rayon: null, famille: null, sousFamille: null, uniteBesoin: null });
              setRefresh(true);
            }}
        />
        <Rayon
          rayons={rayons}
          onRayonChange={(id) => {
            setNomenclature({ ...nomenclature, rayon: id, famille: null, sousFamille: null, uniteBesoin: null });
            setRefresh(true);
          }}
        />
        <Famille
          familles={familles}
          onFamilleChange={(id) => {
            setNomenclature({ ...nomenclature, famille: id, sousFamille: null, uniteBesoin: null });
            setRefresh(true);
          }}
        />
        <SousFamille
          sousFamilles={sousFamilles}
          onSousFamilleChange={(id) => {
            setNomenclature({ ...nomenclature, sousFamille: id, uniteBesoin: null });
            setRefresh(true);
          }}
        />
        <UniteBesoin
          uniteBesoins={uniteBesoins}
          onUniteBesoinChange={(id) => {
            setNomenclature({ ...nomenclature, uniteBesoin: id });
          }}
        />
      </div>
    </NomenclatureContext.Provider>
  );
};

export default Nomenclature;