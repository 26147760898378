import {FunctionComponent, useCallback, useEffect, useMemo, useState} from 'react';

import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';

import { useTranslation } from "react-i18next";

import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";

import { ContactViewModel } from "openapi-typescript-codegen";
import { contactsApi } from 'api';

import {AxiosResponse} from "axios";

import { useGridController } from "hooks";

import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { ImageCellRenderer, ImageCellRendererProps, ModalCellRenderer } from "components/AGGride/CellRender";
import ContactSearchAndSelect from "components/Fournisseur/Contacts/ContactSearchAndSelect";
import Modal from "components/Modal";

import MailTelModal from "pages/Contact/MailTelModal";

import { useUpdateFournisseur } from "context/Referencement";
import { UpdateEnum } from "context/Referencement/updateFournisseur";

import 'app/ag-grid-style.css';

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 90,
};

interface DataTypeWithContact {
  id: number
  contact: ContactViewModel
}

interface Props extends PropsBlock {
  onDelete?: (id: number) => Promise<unknown>;
  onFetch: () => Promise<DataTypeWithContact[]>;
  onUpdate: (id: number) => Promise<AxiosResponse<any>>;
  identifier?: number;
}

type GridType = ContactViewModel;

const Contacts: FunctionComponent<Props>  = ({ onDelete, onFetch, onUpdate, identifier, sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activate, setActivate] = useState(false);
  const [contactKeys, setContactKeys] = useState<number[]>([]);
  const [dataset, setDataSet] = useState<DataTypeWithContact[]>([]);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {
        sortColId: "nomContact",
        startEditingColId: "nomContact",
      },
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.id,
      fetchData: async () => {
        if (!identifier) { return []; }
        const data = await onFetch();
        setDataSet(data);
        const contacts = data?.map(tierContact => tierContact.contact);
        setContactKeys(contacts.map(contact => contact.id));
        gridController.activateContextActionButtons();
        return contacts;
      },
      postData: async() => {},
      putData: (row) =>  contactsApi.v1ContactsPut(row as ContactViewModel),
      deleteData: (id) => {
        const $id = dataset.find(dataset => dataset.contact.id === id)?.id;
        return $id && onDelete ? onDelete($id) : Promise.resolve();
      },
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'photo',
          headerName: t('lib_contact_photo'),
          headerTooltip: t('lib_contact_photo'),
          maxWidth: 80,
          cellRenderer: ImageCellRenderer,
          flex: 1,
          cellRendererParams: {
            defaultHref: '/assets/images/default.png',
          } as ImageCellRendererProps
        },
        {
          field: 'nomContact',
          headerName: t('lib_contact_prenom_contact'),
          headerTooltip: t('lib_contact_prenom_contact'),
          editable: true,
          flex: 2
        },
        {
          field: 'prenomContact',
          headerName: t('lib_contact_nom_contact'),
          headerTooltip: t('lib_contact_nom_contact'),
          editable: true,
          flex: 2
        },
        {
          field: 'fonction',
          headerName: t('lib_contact_fonction'),
          headerTooltip: t('lib_contact_fonction'),
          editable: true,
          flex: 2
        },
        {
          field: 'contactMails',
          headerName: t('lib_contact_mail_email'),
          headerTooltip: t('lib_contact_mail_email'),
          valueFormatter: (params) => {
            const mails = (params.value) || [];
            return mails.length ? `${mails[0]?.email} ${mails.length - 1 ? `[+${mails.length - 1}]` : String()}` : `[+] ${t("btn_ajouter")}`;
          },
          cellRendererSelector: () => {
            return {
              component: ModalCellRenderer,
              params: {
                children: MailTelModal,
              },
            };
          },
          flex: 3
        },
        {
          field: 'contactTels',
          headerName: t('lib_contact_tel_num_tel'),
          headerTooltip: t('lib_contact_tel_num_tel'),
          valueFormatter: (params) => {
            const telephones = (params.value) || [];
            return telephones.length ? `${telephones[0]?.numTel} ${telephones.length - 1 ? `[+${telephones.length - 1}]` : String()}` : `[+] ${t("btn_ajouter")}`;
          },
          cellRendererSelector: () => {
            return {
              component: ModalCellRenderer,
              params: {
                children: MailTelModal,
              },
            };
          },
          flex: 2
        },
      ],
    }), [identifier, contactKeys, t])
  );

  const refresh = useCallback(($event: UpdateEnum) => {
    $event === UpdateEnum.contacts && gridController.fetchData();
  }, [gridController.fetchData]);
  useUpdateFournisseur(refresh);

  useEffect(() => {
    identifier && gridController.fetchData();
  }, [identifier]);

  return (
    <BlockGrid
      title={t('tab_liste_contact')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      loading={gridController.isLoading}
      handleClick={(activation) => {
        setActivate(activation);
        setTimeout(() => gridController.activateContextActionButtons(), 200);
      }}
      toActivate={activate}
      disableCreate={gridController.hasEmptyLines}
      handleCreate={() => setIsModalOpen(true)}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-adaptive">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
          context={{ gridController }}
        ></AgGridReact>
      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div>
          <ContactSearchAndSelect setIsOpen={setIsModalOpen} isOpen={isModalOpen} keys={contactKeys} onUpdate={onUpdate} />
        </div>
      </Modal>
    </BlockGrid>
  );
};

export default Contacts;