import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { EnseigneViewModel } from "openapi-typescript-codegen";
import { enseignesApi } from "api";
import BlockGrid, {LayoutEnum, PropsBlock} from 'components/BlockGrid';
import { SelectTemplate } from 'components/SelectComplete';
import { checkDate, getISOEndDate, getISOStartDate, getStartDate, showPrettyError, toast } from "utils";
import {DateInput, NumberInput, SelectInput, TextInput} from "../../../components/InputWrapper";
import { useSite } from "../../../context/site";
import NomenclatureContext from "../NomenclatureContext";

interface Props extends PropsBlock {
  setIsOpen?: (v: boolean) => void;
  isOpen?: boolean;
  data?: EnseigneViewModel;
}

const EnseigneModal: FunctionComponent<Props> = ({ setIsOpen, isOpen, data }) => {
  const { t } = useTranslation();
  const { state: site } = useSite();
  const { setNomenclature, setRefresh } = useContext(NomenclatureContext);
  const [enseigne, setEnseigne] = useState<Partial<EnseigneViewModel>>({});

  useEffect(() => {
    if (isOpen) { data ? setEnseigne(data) : setEnseigne({ dtDebut: getISOStartDate(), dtFin: getISOEndDate() }); }
  }, [isOpen]);

  const types: SelectTemplate[] = [
    { value: null, label: "---" },
    { value: "P", label: t("enu_enseigne_type_principal") },
    { value: "S", label: t("enu_enseigne_type_secondaire") },
    { value: "W", label: t("enu_enseigne_type_web") },
    { value: "A", label: t("enu_enseigne_type_archive") },
    { value: "C", label: t("enu_enseigne_type_caisse") },
  ];

  function isUpdate() { return enseigne.hasOwnProperty("id"); }

  async function handleUpdate() {
    if (isUpdate()) {
      try {
        await enseignesApi.v1EnseignesPut(enseigne as EnseigneViewModel);
        toast.success(t("mes_validation_modification"));
        setRefresh(true);
        setIsOpen && setIsOpen(false);
      } catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    } else { await handleCreate(); }
  }

  async function handleCreate() {
    try {
      const { data: $enseigne } = await enseignesApi.v1EnseignesSiteEnseigneIdPost(site?.id as number,enseigne as EnseigneViewModel);
      setNomenclature({ enseigne: $enseigne.id, departement: null, rayon: null, famille: null, sousFamille: null, uniteBesoin: null });
      toast.success(t("mes_validation_creation"));
      setIsOpen && setIsOpen(false);
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  }

  return (
    <BlockGrid
      layout={LayoutEnum.modal}
      title={isUpdate() ? t('tit_modification_enseigne') : t('tit_creation_enseigne')}
      informations={''}
      toActivate={true}
      handleUpdate={handleUpdate}
      handleCancel={() => { setIsOpen && setIsOpen(false); }}
    >
      <form onSubmit={$event => $event.preventDefault()} className="flex flex-col">
        <div className="grid grid-cols-6 gap-4">
          <NumberInput labelClassAdd="col-span-1" label={t("lib_enseigne_code")}
                       value={enseigne} field="code" setValue={setEnseigne} required={true} min={1} />
          <TextInput labelClassAdd="col-span-1" label={t("lib_enseigne_code_externe")}
                     value={enseigne} field="codeExterne" setValue={setEnseigne} />
          <TextInput labelClassAdd="col-span-4" label={t("lib_enseigne_nom_enseigne")}
                     value={enseigne} field="nomEnseigne" setValue={setEnseigne} required={true} />
          <SelectInput className="col-span-2" label={t("lib_enseigne_type")}
                       value={enseigne} field="type" setValue={setEnseigne}
                       getPlaceholder={async () => types[0].label! }
                       required={false}
                       searchData={async () => types}
          />
          <DateInput labelClassAdd="col-span-2" label={t("lib_enseigne_dt_debut")}
                     value={enseigne} field="dtDebut" setValue={setEnseigne} required={true} min={getStartDate()} disabled={checkDate.isPast(enseigne.dtDebut as string)} />
          <DateInput labelClassAdd="col-span-2" label={t("lib_enseigne_dt_fin")}
                     value={enseigne} field="dtFin" setValue={setEnseigne} required={true} min={getStartDate().plus({ day: 1 })} />
          { isUpdate() &&
            <>
                <div className={"col-span-4"}></div>
                <DateInput labelClassAdd="col-span-2" label={t("lib_enseigne_date_maj")}
                           value={enseigne} field="dateMaj"  disabled={true} />
            </>

          }
          {/*<FileInput labelClassAdd="col-span-2" label={t("lib_enseigne_logo_url")}*/}
          {/*           value={enseigne} field="logoUrl" setValue={() => { }}*/}
          {/*           accept="image/*"*/}
          {/*/>*/}
        </div>
      </form>
    </BlockGrid>
  );
};

export default EnseigneModal;