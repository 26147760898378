import { FC, ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { FournisseurViewModel } from "openapi-typescript-codegen";

import { fournisseursApi } from "api";

type State = FournisseurViewModel | null;
type Dispatch = (newState: State) => void;

const FournisseurContext = createContext<{ state: State; dispatch: Dispatch; } | undefined>(undefined);

const FournisseurProvider: FC<{ children?: ReactNode; fournisseurCode?: string; }> = ({ children, fournisseurCode }) => {
  const [state, dispatch] = useState<State>(null);
  const value = { state, dispatch };

  useEffect(() => {
    if (fournisseurCode) fetchFournisseur(fournisseurCode);
  }, [fournisseurCode]);

  async function fetchFournisseur(isCode: string) {
    const { data: response } = await fournisseursApi.v1FournisseursCodesCodeGet(isCode);
    dispatch(response);
  }

  return <FournisseurContext.Provider value={value}>{children}</FournisseurContext.Provider>;
};

function useFournisseur() {
  const context = useContext(FournisseurContext);
  if (context === undefined) {
    throw new Error('useFournisseur must be used within a FournisseurProvider');
  }

  return context;
};

export { FournisseurProvider, useFournisseur }; 