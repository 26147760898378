import { FC, useState } from 'react';

import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import { GridType as DataProps } from ".";
import { ModalCellRendererChildrenProps } from "components/AGGride/CellRender";
import HistoriqueTauxDeChange from "./TableauxHistoriqueTauxDeChange";
import TauxChange from "./TableauxTauxChange";
import { useTranslation } from "react-i18next";

const ModalDevise: FC<ModalCellRendererChildrenProps<DataProps>> = ({ setIsOpen, agGridRow }) => {
  const { t } = useTranslation();
  const [deviseArrivee, setDeviseArrivee] = useState<number | null>(null);
  const [deviseDepart, setDeviseDepart] = useState<number | null>(null);
  const deviseId = agGridRow.data?.id;

  function handleCancel() {
    setIsOpen(false);
  }

  return (
    <BlockGrid
      title={t('tit_modification_taux_change')}
      layout={LayoutEnum.modal}
      toActivate={true}
      handleCancel={handleCancel}
    >
      <div className="flex gap-2">
        <TauxChange
          onTauxChange={(deviseDepart, deviseArrivee) => {
            setDeviseDepart(deviseDepart);
            setDeviseArrivee(deviseArrivee);
          }}
          deviseId={deviseId ?? undefined}
        />
        <HistoriqueTauxDeChange deviseDepart={deviseDepart} deviseArrivee={deviseArrivee} />
      </div>
    </BlockGrid>
  );
};

export default ModalDevise;