import { useState, useEffect, useMemo, SetStateAction, Dispatch, FunctionComponent } from 'react';

import { useTranslation } from "react-i18next";

import {
  CircuitLogistiqueInsertionModel,
  FluxFournisseurInsertionModel,
  LieuFonctionViewModel,
  ModeApprovisionnementViewModel
} from 'openapi-typescript-codegen';
import { lieuFonctionApi, modeApprovisionnementsApi } from 'api';

import { useFournisseur } from "context/Referencement";

import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import Modal from 'components/Modal';
import SelectComplete, { SelectTemplate } from 'components/SelectComplete';

import { toast } from 'utils';

interface Props {
  onCreate?: (circuit: CircuitLogistiqueInsertionModel, flux: FluxFournisseurInsertionModel) => void;
}

const CreationCircuitsLN: FunctionComponent<Props> = ({ onCreate }) => {
  const { t } = useTranslation();
  const { state: fournisseur } = useFournisseur();
  const [circuitLogistique, setCircuitLogistique] = useState<Partial<CircuitLogistiqueInsertionModel>>({});
  const [lieuxCommande, setLieuxCommande] = useState<LieuFonctionViewModel[]>([]);
  const [lieuxRegelement, setLieuxReglement] = useState<LieuFonctionViewModel[]>([]);
  const [modesApprovisionnement, setModesApprovisionnement] = useState<ModeApprovisionnementViewModel[]>([]);
  const [defaultFlux, setDefaultFlux] = useState<Partial<FluxFournisseurInsertionModel>>({ flFluxDefaut: true });
  const [open, setOpen] = useState<boolean>(false);

  function validate() {
    let isValid = true;
    if (!circuitLogistique.code) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_circuit_logistique_code')}`);
      isValid = false;
    }
    if (!circuitLogistique.nomCircuit) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_circuit_logistique_nom_circuit')}`);
      isValid = false;
    }
    if (!circuitLogistique.fkLieuFonctionCommande) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_circuit_logistique_fk_lieu_fonction_commande')}`);
      isValid = false;
    }
    if (!circuitLogistique.fkLieuFonctionReglement) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_circuit_logistique_fk_lieu_fonction_reglement')}`);
      isValid = false;
    }
    if (!circuitLogistique.typeTransport) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_circuit_logistique_fk_type_transport')}`);
      isValid = false;
    }
    if (!defaultFlux.fkModeApprovisionnement) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_flux_circuit')}`);
      isValid = false;
    }
    return isValid;
  }

  const toCreate = () => {
    if (lieuxCommande.length && lieuxRegelement.length) {
      setOpen(true);
      setCircuitLogistique({ fkLieuFonctionCommande: lieuxCommande[0].id, fkLieuFonctionReglement: lieuxRegelement[0].id });
    } else { toast.warning(t("mes_circuit_logistique_creation_impossible")); }

  };

  const handleCreate = () => {
    if (validate()) {
      onCreate && onCreate(circuitLogistique as CircuitLogistiqueInsertionModel, defaultFlux as FluxFournisseurInsertionModel);
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setCircuitLogistique({});
    setOpen(false);
  };


  const fetchLieuxCommande = async () => {
    const { data: { data: responseM } } = await lieuFonctionApi.v1LieuFonctionsSearchPost(false, 1, 1000, { fkFournisseur: fournisseur?.id, typeLieuFonction: "M" });
    const { data: { data: responseC } } = await lieuFonctionApi.v1LieuFonctionsSearchPost(false, 1, 1000, { fkFournisseur: fournisseur?.id, typeLieuFonction: "C" });
    return [...(responseC || []), ...(responseM || [])];
  };

  const fetchLieuxRegelement = async () => {
    const { data: { data: responseM } } = await lieuFonctionApi.v1LieuFonctionsSearchPost(false, 1, 1000, { fkFournisseur: fournisseur?.id, typeLieuFonction: "M" });
    const { data: { data: responseR } } = await lieuFonctionApi.v1LieuFonctionsSearchPost(false, 1, 1000, { fkFournisseur: fournisseur?.id, typeLieuFonction: "R" });

    return [...(responseR || []), ...(responseM || [])];
  };

  const fetchModesApprovisionnement = async () => {
    const { data: { data: response } } = await modeApprovisionnementsApi.v1ModeApprovisionnementsGet(1, 1000);

    return [...(response || [])];
  };

  useEffect(() => {
    if (open) {
      fetchModesApprovisionnement().then((data) => {
        setModesApprovisionnement(data);
      });
    }
  }, [open]);

  useEffect(() => {
    fetchLieuxCommande().then((data) => {
      setLieuxCommande(data);
    });
    fetchLieuxRegelement().then((data) => {
      setLieuxReglement(data);
    });
  }, []);

  const lieuxCommandeOptions = useMemo(() => {
    const options = lieuxCommande.map((item): SelectTemplate => {
      return {
        value: item.id,
        filterKey: [item.nomLieuFonction, item.code].filter(element => element).join(' '),
        label: `${item.code} - ${item.nomLieuFonction}` ?? ''
      };
    });
    options.length === 1 && setCircuitLogistique({ ...circuitLogistique, fkLieuFonctionCommande: options[0].value as number });
    return options;
  }, [lieuxCommande]);

  const lieuxReglementOptions = useMemo(() => {
    const options = lieuxRegelement.map((item): SelectTemplate => {
      return {
        value: item.id,
        filterKey: [item.nomLieuFonction, item.code].filter(element => element).join(' '),
        label: `${item.code} - ${item.nomLieuFonction}` ?? ''
      };
    });
    options.length === 1 && setCircuitLogistique({ ...circuitLogistique, fkLieuFonctionReglement: options[0].value as number });
    return options;
  }, [lieuxRegelement]);

  const modesApprovisionnementOptions = useMemo(() => {
    const options = modesApprovisionnement.map((item): SelectTemplate => {
      return {
        value: item.id,
        label: `${item.code} - ${item.nomModeApprovisionnement}` ?? ''
      };
    });
    options.length === 1 && setDefaultFlux({ ...defaultFlux, fkModeApprovisionnement: options[0].value as number });
    return options;
  }, [modesApprovisionnement]);

  const typesTransportOptions = [
    {
      value: 'F',
      filterKey: t('enu_circuit_logistique_type_port_franco'),
      label: t('enu_circuit_logistique_type_port_franco')
    },
    {
      value: 'A',
      filterKey: t('enu_circuit_logistique_type_port_avance'),
      label: t('enu_circuit_logistique_type_port_avance')
    },
    {
      value: 'D',
      filterKey: t('enu_circuit_logistique_type_port_du'),
      label: t('enu_circuit_logistique_type_port_du')
    },
  ];

  const initializeSelect = (
    options: SelectTemplate[],
    setData: Dispatch<SetStateAction<any>>,
    data: any,
    key: any) => {
    return (
      <SelectComplete
        onChange={$event => setData({ ...data, [key]: $event?.value as number })}
        options={options}
        inputValue={options.length === 1 ? options[0].label : ""}
        components={options.length > 1 ? {} : {
          Menu: () => null,
          MenuList: () => null,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          CrossIcon: () => null,
          IndicatorsContainer: () => null,
        }}
      />
    );
  };

  return (
    <>
      <button tabIndex={5}
        onClick={() => toCreate()}
        className="bg-store-primary text-white-500 p-2 rounded m-2 text-sm">+ {t('btn_ajouter_circuit_logistique')}
      </button>
      <Modal
        open={open} tabIndex={-1}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <BlockGrid
          layout={LayoutEnum.modal}
          title={t('tit_creation_circuit_logistique')}
          informations={''}
          toActivate={true}
          handleUpdate={handleCreate}
          handleCancel={handleCancel}
        >
          <form className="flex flex-col justify-between">
            <div className="">

              <div className="py-4 grid grid-cols-2">
                <div className="flex items-center gap-2">
                  <div>
                    {t('lib_circuit_logistique_code')}
                    <span className="text-red-500">**</span>
                  </div>
                  <div>
                    <input type="text"
                      value={circuitLogistique.code ?? ''}
                      onChange={e => setCircuitLogistique({ ...circuitLogistique, code: e.currentTarget.value })}
                      className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary`} />
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div>
                    {t('lib_circuit_logistique_nom_circuit')}
                    <span className="text-red-500">**</span>
                  </div>
                  <div>
                    <input type="text"
                      value={circuitLogistique.nomCircuit ?? ''}
                      onChange={e => setCircuitLogistique({ ...circuitLogistique, nomCircuit: e.currentTarget.value })}
                      className={`h-8 mt-1 block w-full py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary`} />
                  </div>
                </div>
              </div>
              <div className="h-px bg-slate-200 w-full col-span-full"></div>
              <div className={`py-4 grid w-full gap-2 relative transition-all`} style={{ gridTemplateColumns: `repeat(2, minmax(0, 1fr))` }}>
                <div>
                  <label className="block col-span-4 flex-1">
                    <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_circuit_logistique_fk_lieu_fonction_commande')}<span className="text-red-500">**</span></span></span>
                  </label>
                  <div className='w-300'>
                    {initializeSelect(lieuxCommandeOptions, setCircuitLogistique, circuitLogistique, "fkLieuFonctionCommande")}
                  </div>
                </div>
                <div>
                  <label className="block col-span-4 flex-1">
                    <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_circuit_logistique_fk_lieu_fonction_reglement')}<span className="text-red-500">**</span></span></span>
                  </label>
                  <div className='w-300'>
                    {initializeSelect(lieuxReglementOptions, setCircuitLogistique, circuitLogistique, "fkLieuFonctionReglement")}
                  </div>
                </div>
              </div>
              <div className={`grid w-full gap-2 relative transition-all`} style={{ gridTemplateColumns: `repeat(2, minmax(0, 1fr))` }}>
                <div>
                  <label className="block col-span-4 flex-1">
                    <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_circuit_logistique_fk_type_transport')}<span className="text-red-500">**</span></span></span>
                  </label>
                  <div className='w-300'>
                    {initializeSelect(typesTransportOptions, setCircuitLogistique, circuitLogistique, "typeTransport")}
                  </div>
                </div>
                <div>
                  <label className="block col-span-4 flex-1">
                    <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_flux_circuit')}<span className="text-red-500">**</span></span></span>
                  </label>
                  <div className='w-300'>
                    {initializeSelect(modesApprovisionnementOptions, setDefaultFlux, defaultFlux, "fkModeApprovisionnement")}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </BlockGrid>
      </Modal>
    </>
  );
};

export default CreationCircuitsLN;