import {FunctionComponent} from "react";

import { Delete } from "@mui/icons-material";

import { SelectTemplate } from "components/SelectComplete";
import {TextInput} from "../../InputWrapper";

interface PropsList {
  item: SelectTemplate,
  onDelete: (e: any) => void,
  activate?: boolean;
}

const ContactListElement: FunctionComponent<PropsList> = ({ item, onDelete }) => {


  return (
      <div className={`grid grid-cols-16 gap-4`}>
        <TextInput
          disabled={true}
          value={item.data?.prenomContact ?? String()}
          inputClassAdd="col-span-3"
        />
        <TextInput
          disabled={true}
          value={item.data?.nomContact ?? String()}
          inputClassAdd="col-span-3"
        />
        <TextInput
          disabled={true}
          value={item.data?.fonction ?? String()}
          inputClassAdd="col-span-3"
        />

        <TextInput
          disabled={true}
          value={item.data?.contactTels.length ? item.data.contactTels[0]?.email : String()}
          inputClassAdd="col-span-3"
        />
        <TextInput
          disabled={true}
          value={item.data?.contactMails.length ? item.data.contactMails[0]?.email : String()}
          inputClassAdd="col-span-3"
        />
        <span className=" col-span-1">
            <button type="button" tabIndex={5}
                    className="w-8 h-8 mt-1 border rounded border-slate-300 text-grey-200 bg-white-500 opacity-100
                    hover:text-white-500 hover:bg-red-500 focus:text-white-500 focus:bg-red-500"
                    onClick={() => onDelete(item)}>
              <Delete />
            </button>
          </span>
      </div>
  );
};

export default ContactListElement;