export function normalizeDecimal(value: string, decimal: number) {
  const t = value;
  value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), (decimal + 1))) : t;
  return value;
}

export function fixFloating(value: unknown, floating?: number | null) {
  let parseValue = 0;
  if (typeof value === 'number') parseValue = value;
  if (typeof floating !== 'number' || floating < 0) floating = 3;

  return Math.trunc(parseValue * (10 ** floating)) / (10 ** floating);
}

export function trimString<T>(value: T) {
  if (typeof value !== 'string')
    return value;

  return value.trim();
}

export function removeSpaces<T>(value: T) {
  if (typeof value !== "string")
    return value;

  return value.replaceAll(" ", "");
}