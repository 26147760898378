import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { UniteBesoinInsertionModel, UniteBesoinViewModel } from "openapi-typescript-codegen";
import { uniteBesoinsApi } from "api";
import BlockGrid, { LayoutEnum, PropsBlock } from 'components/BlockGrid';
import {DateInput, NumberInput, TextInput} from "components/InputWrapper";
import NomenclatureContext from "../NomenclatureContext";
import { showPrettyError, toast } from "utils";

interface Props extends PropsBlock {
  setIsOpen?: (v: boolean) => void;
  isOpen?: boolean;
  data?: UniteBesoinViewModel;
}

const UniteBesoinModal: FunctionComponent<Props> = ({ setIsOpen, isOpen, data }) => {
  const { t } = useTranslation();
  const { nomenclature, setNomenclature, setRefresh } = useContext(NomenclatureContext);
  const [uniteBesoin, setUniteBesoin] = useState<Partial<UniteBesoinViewModel>>({});

  useEffect(() => {
    if (isOpen) { data ? setUniteBesoin(data) : setUniteBesoin({ fkSousFamille: nomenclature.sousFamille as number }); }
  }, [isOpen]);

  function isUpdate() { return uniteBesoin.hasOwnProperty("id"); }

  async function handleUpdate() {
    if (isUpdate()) {
      try {
        await uniteBesoinsApi.v1UniteBesoinsPut(uniteBesoin as UniteBesoinViewModel);
        toast.success(t("mes_validation_modification"));
        setIsOpen && setIsOpen(false);
      } catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    } else { await handleCreate(); }
  }

  async function handleCreate() {
    try {
      const { data: $uniteBesoin } = await uniteBesoinsApi.v1UniteBesoinsPost(uniteBesoin as UniteBesoinInsertionModel);
      toast.success(t("mes_validation_creation"));
      setNomenclature({ ...nomenclature, uniteBesoin: $uniteBesoin.id });
      setIsOpen && setIsOpen(false);
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  }

  return (
    <BlockGrid
      layout={LayoutEnum.modal}
      title={isUpdate() ? t('tit_modification_unite_besoin') : t('tit_creation_unite_besoin')}
      informations={''}
      toActivate={true}
      handleUpdate={async () => { await handleUpdate().then(() => { setRefresh(true); }); }}
      handleCancel={() => { setIsOpen && setIsOpen(false); }}
    >
      <form onSubmit={$event => $event.preventDefault()} className="flex flex-col">
        <div className="grid grid-cols-6 gap-4">
          <NumberInput labelClassAdd="col-span-1" label={t("lib_unite_besoin_code")}
                       value={uniteBesoin} field="code" setValue={setUniteBesoin} required={true} min={1} />
          <TextInput labelClassAdd="col-span-5" label={t("lib_unite_besoin_nom_ub")}
                     value={uniteBesoin} field="nomUb" setValue={setUniteBesoin} required={true} />
          { isUpdate() &&
              <>
                <div className={"col-span-4"}></div>
                <DateInput labelClassAdd="col-span-2" label={t("lib_unite_besoin_date_maj")}
                value={uniteBesoin} field="dateMaj"  disabled={true} />
              </>
          }
        </div>
      </form>
    </BlockGrid>
  );
};

export default UniteBesoinModal;