import { FC, useEffect, useState } from "react";
import { useParams, Routes, Route, Navigate } from "react-router-dom";

import BreadCrumb from "components/Breadcrumb";
import FournisseurPresentation from "components/Fournisseur/FournisseurPresentation";
import FournisseurTabs from "components/Fournisseur/FournisseurTabs";
import TabGeneral from "components/Fournisseur/TabGeneral";
import TabLieux from "components/Fournisseur/TabLieuFonction";
import TabCircuit from "components/Fournisseur/TabCircuit";
import TabConditions from "components/Fournisseur/TabConditions";
import TabGED from "components/Fournisseur/TabGED";
import TabReferentiel from "components/Fournisseur/TabReferentiel";
import TabHistorique from "components/Fournisseur/TabHistorique";

import { FournisseurProvider, useFournisseur, UpdateFournisseurProvider } from "context/Referencement";
import { TierViewModel } from "openapi-typescript-codegen";
import { tiersApi } from "api";

const FournisseurWithContext: FC = () => {
  const { code } = useParams();
  const { state: fournisseur } = useFournisseur();
  const [tiers, setTiers] = useState<Partial<TierViewModel>>({});

  const fetchGetTier = async (id: number) => {
    const { data: response } = await tiersApi.v1TiersIdGet(id);
    setTiers(response);
  };

  useEffect(() => {
    if (fournisseur?.fkTiers) fetchGetTier(fournisseur.fkTiers);
  }, [fournisseur?.fkTiers]);

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: "tab_listing_fournisseurs",
      link: `/app/referencement/fournisseurs`,
    },
    {
      name: `${code} - ${tiers?.raisonSociale || tiers?.nomTiers || tiers?.nomCourtTiers}`,
    },
  ];

  if (!fournisseur?.id) return (<></>);
  return (
    <div key={fournisseur?.id} className="relative flex flex-col" style={{ height: 'calc(100vh - 35px)' }}>
      <div className="w-full" role="application">
        <BreadCrumb content={breadcrumb} />
        <FournisseurPresentation />
        <FournisseurTabs />
      </div>
      <div className="min-h-[32rem] pb-24 rounded-b-lg overflow-y-scroll fiche-space">
        <Routes>
          <Route path="general" element={<TabGeneral />} />
          <Route path="lieux" element={<TabLieux />} />
          <Route path="circuit" element={<TabCircuit />} />
          <Route path="conditions" element={<TabConditions />} />
          <Route path="ged" element={<TabGED />} />
          <Route path="referentiel" element={<TabReferentiel />} />
          <Route path="historique" element={<TabHistorique />} />
          <Route path="*" element={<Navigate to="general" replace />} />
        </Routes>
      </div>
    </div>
  );
};

const Fournisseur: FC = () => {
  let { code } = useParams();

  return (
    <FournisseurProvider fournisseurCode={code}>
      <UpdateFournisseurProvider>
        <FournisseurWithContext />
      </UpdateFournisseurProvider>
    </FournisseurProvider>
  );
};


export default Fournisseur;
