import { FC } from 'react';

import DataTableParams from 'components/DataTableParams';

const TestTab: FC = () => {

  return (
    <div className="flex gap-4 flex-col w-full">
      <div className="flex gap-4 flex-col justify-center items-center w-2/3" style={{ margin: '0 auto' }}>
        <h1 className="font-lg font-bold pt-8">Test tableau et api:</h1>

        <DataTableParams />

      </div>
    </div>
  );
};

export default TestTab;