import { FC, useState } from 'react';
import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import { Title } from "components/H2Title";
import { useTranslation } from "react-i18next";
import TableauxTaxeIndirecte from "./TableauxTaxeIndirecte";
import TableauxTaxe from "./TableauxTaxe";

const TaxeGeneral: FC = () => {
  const [taxeId, setTaxeId] = useState<number | null>(null);

  const { t } = useTranslation();
  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: `${t('men_refe_articles')} - ${t('men_parametre')}`,
      link: `/app/referencement/articles/parametres`,
    },
    {
      name: "tit_taxe_indirecte",
    },
  ];

  const title: Title = {
    title: `${t('men_refe_articles')} - ${t('tab_taxe_indirecte')}`,
    icon: "articles",
  };

  return (
    <>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <div className="flex">
        <TableauxTaxe onTaxeChange={(taxe) => {
          setTaxeId(taxe);
        }} />
        <TableauxTaxeIndirecte taxeId={taxeId} />
      </div>
    </>
  );
};

export default TaxeGeneral;