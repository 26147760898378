import {FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useEnseigne, useArticle, useCompletudeArticle, useUpdateArticle } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateArticle';
import { getTraductionCode } from 'utils/Article/completude';


import { articlesApi } from 'api';
import {CompletudeArticleResultModel, ViewNomenclatureArticleViewModel} from 'openapi-typescript-codegen';

import { DataUsageRounded } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { api } from "utils";

const CompletudePresentation: FunctionComponent = () => {
  const { t } = useTranslation();
  const { state: enseigne } = useEnseigne();
  const { state: article } = useArticle();
  const { state: controled } = useCompletudeArticle();
  const [completude, setCompletude] = useState<Partial<CompletudeArticleResultModel>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [nomenclatures, setNomenclatures] = useState<({isPrincipal?: boolean } & ViewNomenclatureArticleViewModel)[]>([]);

  const refresh = useCallback((_evt: UpdateEnum) => {
    if (article?.id && enseigne?.id) fetchGetCompletude(article.id, enseigne.id);
  }, [article?.id, enseigne?.id]);
  useUpdateArticle(refresh);

  const refreshNomenclatures = async () => {
    let $nomenclatures: ViewNomenclatureArticleViewModel[] = [];
    if (article && article.id) {
      $nomenclatures = await api.dataset.get<ViewNomenclatureArticleViewModel>(articlesApi.v1ArticlesIdNomenclatureArticlesGet(article?.id));
    }
    setNomenclatures($nomenclatures);
  };

  const fetchGetCompletude = async (id: number, enseigne: number) => {
    const { data: response } = await articlesApi.v1ArticlesIdCompletudeGet(id, enseigne);
    setCompletude(response);
    setLoading(false);
  };

  const fetchPatchCompletude = async (id: number) => {
    const enseigneId = (nomenclatures.find((nomenclature) => nomenclature.fkEnseigne === enseigne?.id) || nomenclatures[0])?.fkEnseigne;
    if (enseigneId) {
      const $completude = await api.data.get<CompletudeArticleResultModel>(articlesApi.v1ArticlesIdCompletudePatch(id, { enseigneId }));
      setCompletude($completude);
      setLoading(false);
    }
  };

  useEffect(() => {
    (enseigne?.id && article?.id) && refreshNomenclatures();
  }, [enseigne?.id, article?.id]);

  useEffect(() => {
    (enseigne?.id && article?.id) && fetchPatchCompletude(article.id);
  }, [nomenclatures]);

  const Loader = () => {
    return (
      <DataUsageRounded className="animate-spin text-grey-150 self-center" />
    );
  };

  return (
    <>
      <Tooltip title={
        <div>
          {controled?.filter((i) => i.required && i.isEmpty)
            .sort((a, b) => {
              if (a.ongletArticle && b.ongletArticle)
                return a.ongletArticle > b.ongletArticle ? 1 : -1;
              return 1;
            })
            .map((i, key) => <div key={key}>- {t(getTraductionCode(i.colonneArticle))} ({i.ongletArticle})</div>)}
        </div>} followCursor>
        <div className="flex justify-center align-center col-span-6 bg-white-500 px-2">
          <div className="w-full flex flex-col justify-center">
            <div className="w-full h-3 flex justify-between items-center">
              <div className="h-2 bg-grey-150 rounded-full self-center w-4/5 mr-2 flex">
                <div
                  className={`loading-bar 
                  ${completude.general === 100 ? "succeed" :
                      completude.general && completude.general < 50 ? "error" : "warning"
                    }`}
                  style={{
                    width: `${completude.general && completude.general > 2
                      ? completude.general
                      : "2"
                      }%`,
                  }}
                ></div>
              </div>
              <span className="self-center text-sm">
                {loading ? (<Loader />) : (`${completude.general && Math.floor(completude.general)}%`)}
              </span>
            </div>
            {completude.missing !== 0 &&
              completude.missing !== undefined && (
                <div className="w-full text-xs h-1/3">
                  {completude.missing} {t('mes_completude_champ_manquant')}
                </div>
              )}
          </div>
        </div>
      </Tooltip>
      <button type="button" onClick={() => alert("Ouvre feature: completude modal")}
        className={`flex justify-center items-center col-span-2 text-xs text-white-500 rounded-tr-md
        ${loading ? "bg-white-500" :
            completude.actif ? "bg-green-500" : "bg-orange-400"}
        `}>
        {loading ? (<Loader />) :
          completude.actif ? ("Actif") : ("Inactif")}
      </button>
    </>
  );
};

export default CompletudePresentation;