import { FC } from 'react'
import { ReactComponent as CircleLoading } from 'assets/icons/CircleLoading.svg';

const Loading: FC = () => {
  return (
    <div className="flex justify-center items-center w-full h-screen flex-col fill-store-primary text-store-primary gap-4">
      Loading
      <div className="w-[24px] h-[24px] fill-grey-150 animate-spin">
        <CircleLoading />
      </div>
    </div>
  )
}

export default Loading