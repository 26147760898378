import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { env, toast } from 'utils';
import Autocomplete from 'react-google-autocomplete';
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import SelectComplete, { SelectTemplate } from 'components/SelectComplete';
import { paysApi, lieuFonctionApi } from 'api';
import {LieuFonctionInsertionModel, LieuFonctionViewModel} from 'openapi-typescript-codegen';
import { useTranslation } from 'react-i18next';
import { getColumnControl } from 'validators/ColumnControl/Controls';
import { useUpdateFournisseur, UpdateEnum } from 'context/Referencement/updateFournisseur';

const controls = [
  "lieu_fonction.nom_lieu_fonction",
  "lieu_fonction.type_lieu_fonction",
  "lieu_fonction.adresse_lieu_fonction",
  "lieu_fonction.ville_lieu_fonction",
  "lieu_fonction.ville_lieu_fonction",
  "lieu_fonction.pays",
  "lieu_fonction.code_fiscal",
  "lieu_fonction.siret",
  "lieu_fonction.gln"
].map(getColumnControl);

interface Props extends PropsBlock {
  identifier: number;
}

const IdentificationLieu: FunctionComponent<Props> = ({ sm, md, lg, xl, doubleXl, identifier }) => {
  const { t } = useTranslation();
  const [completude, setCompletude] = useState<number>(0);
  const [activate, setActivate] = useState<boolean>(false);
  const [hasFocus, setFocus] = useState(false);
  const [loading, setLoader] = useState<boolean>(true);
  const uF = useUpdateFournisseur();
  const [type] = useState([
    { value: null, label: "..." },
    { value: 'C', label: t('enu_lieu_fonction_type_commande') },
    { value: 'R', label: t('enu_lieu_fonction_type_reglement') },
    { value: 'M', label: t('enu_lieu_fonction_type_mixte') }
  ]);
  const [lieuFonction, setLieuFonction] = useState<Partial<LieuFonctionViewModel>>();
  const [pays, setPays] = useState<SelectTemplate[]>([]);

  function checkCompletude() {
    setCompletude(controls.reduce((accumulator, current) =>
      accumulator + (current.isValueMissing(lieuFonction!) ? 1 : 0), 0));
  }

  useEffect(() => {
    checkCompletude();
  }, [lieuFonction]);

  const fetchGetLieu = async (id: number) => {
    const { data: response } = await lieuFonctionApi.v1LieuFonctionsIdGet(id);
    setLieuFonction(response);
    // if (response.fkPays) {
    //   const { data: namer } = await paysApi.v1PaysIdGet(response.fkPays)
    //   setCountryName(namer?.nomPays || '')
    // }
    fetchGetPays();
    setLoader(false);
  };

  const fetchGetPays = async () => {
    const { data: response } = await paysApi.v1PaysGet(1, 90000);

    let tmp: SelectTemplate[] = [];
    if (response.data && response.data.length > 0) {
      tmp = [
        { value: null, label: '...' },
        ...response.data.map(
          (item) => ({ value: item.id, label: item.codePaysAlpha2 + " - " + item.nomPays })
        )
      ];
    }

    setPays(tmp);
  };

  const fetchUpdateData = async (id?: number) => {
    try {
      if (!id) await lieuFonctionApi.v1LieuFonctionsPost(lieuFonction as LieuFonctionInsertionModel);
      else await lieuFonctionApi.v1LieuFonctionsPut(lieuFonction as LieuFonctionViewModel);
      toast.success(t('mes_validation_modification'));
      uF.triggerEvent(UpdateEnum.lieuFonction, identifier);
      setActivate(false);
    } catch (err) {
      toast.error(t('mes_erreur_technique'));
    }
  };

  const googleAddress = async (google: google.maps.places.PlaceResult, inputRef: any) => {
    if (!google) return;
    const country = google.address_components?.find((i) => i.types.includes("country"))?.short_name;
    const city = google.address_components?.find((i) => i.types.includes("locality") || i.types.includes("postal_town"))?.long_name;
    const cp = google.address_components?.find((i) => i.types.includes("postal_code"))?.long_name;
    const address = google.formatted_address?.split(',')[0];

    const resultCountry = await paysApi.v1PaysSearchGet(country, 1, 100).then(({ data: res }) => {
      let result = res.data?.find((i) => i.codePaysAlpha2 === country);
      return result?.id;
    });

    inputRef.value = address;

    setLieuFonction((prevState) => {
      return ({
        ...prevState,
        addresseLieuFonction: address ?? null,
        villeLieuFonction: city ?? null,
        codePostal: cp ?? null,
        fkPays: resultCountry ?? null
      } as LieuFonctionViewModel);
    });
  };

  useEffect(() => {
    if (!lieuFonction?.adresseLieuFonction) {
      const $lieuFonction = { ...lieuFonction };
      delete $lieuFonction?.villeLieuFonction;
      delete $lieuFonction?.codePostal;
      delete $lieuFonction?.fkPays;
      setLieuFonction( $lieuFonction);
    }
  }, [lieuFonction?.adresseLieuFonction]);

  useEffect(() => {
    fetchGetLieu(identifier);
  }, [identifier]);

  function validator() {
    return controls.every((control) => control.validateInput(lieuFonction!));
  }

  function handleUpdate() {
    if (validator()) {
      fetchUpdateData(lieuFonction?.id);
    }
  }

  function handleCancel() {
    fetchGetLieu(identifier);
    setActivate(false);
  }

  function handleFocus(focus: boolean) {
    setFocus(focus);
  }

  return (
    <BlockGrid
      title={t('tab_lieu_fonction_circuit_logistique')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      anchor="identification-lieu-fonction"
      completude={completude}
      loading={loading}
      handleClick={(res: boolean) => setActivate(res)}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      handleFocus={handleFocus}
    >
      <form className="flex justify-between flex-wrap">
        <div className="mb-1 w-full grid grid-cols-8 gap-2">
          <label className="block mb-2 flex-1 col-span-2">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_lieu_fonction_code')}
                {getColumnControl("lieu_fonction.code")?.getValidationLabelElement()}
              </span>
            </span>
            <input type="text" tabIndex={5}
              value={lieuFonction?.code ?? ''}
              disabled={true}
              className="h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 bg-grey-150 rounded-md text-sm placeholder-slate-400
              focus:border-store-primary store-disabled"/>
          </label>
          <label className="block mb-2 flex-1 col-span-4">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_lieu_fonction_nom_lieu_fonction')}
                {getColumnControl("lieu_fonction.nom_lieu_fonction")?.getValidationLabelElement()}
              </span>
            </span>
            <input type="text" tabIndex={5}
              value={lieuFonction?.nomLieuFonction ?? ''}
              onChange={e =>
                setLieuFonction({ ...lieuFonction, nomLieuFonction: e.currentTarget.value })
              }
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("lieu_fonction.nom_lieu_fonction")?.getValidationInputClass(activate, hasFocus, lieuFonction!)}
              `} />
          </label>
          <label className="block mb-2 flex-1 col-span-2">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_lieu_fonction_type_lieu_fonction')}
                {getColumnControl("lieu_fonction.type_lieu_fonction")?.getValidationLabelElement()}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={type}
              value={type.filter(option => {
                return option.value === lieuFonction?.typeLieuFonction;
              })}
              onChange={(e: any): void => setLieuFonction({ ...lieuFonction, typeLieuFonction: e.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("lieu_fonction.type_lieu_fonction")?.getValidationInputClass(activate, hasFocus, lieuFonction!)}
              `}
            />
          </label>
        </div>
        <div className="mb-1 w-full justify-between gap-2 grid grid-cols-8 border-slate-300">
          <label className="block mb-2 col-span-8">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_lieu_fonction_adresse_lieu_fonction')}
                {getColumnControl("lieu_fonction.adresse_lieu_fonction")?.getValidationLabelElement()}
              </span>
            </span>
            {/*@ts-ignore*/}
            <Autocomplete
              tabIndex={5}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary pr-8
                    ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                    ${getColumnControl("lieu_fonction.addresse_lieu_fonction")?.getValidationInputClass(activate, hasFocus, lieuFonction!)}
                  `}
              defaultValue={lieuFonction?.adresseLieuFonction?.toString()}
              apiKey={env.GOOGLE_MAP_API_KEY}
              onPlaceSelected={(finder, inputRef) => googleAddress(finder, inputRef)}
              onChange={($event: ChangeEvent<HTMLInputElement>) => setLieuFonction({ ...lieuFonction, adresseLieuFonction: $event.target.value })}
              options={{
                types: ["address"],
              }}
              libraries={["places", "nearbySearch"]}
              placeholder={t('tab_recherche')}
            />
          </label>
        </div>
        <div className="mb-1 w-full justify-between gap-2 grid grid-cols-8">
          <label className="block mb-2 col-span-4">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_lieu_fonction_ville_lieu_fonction')}
                {getColumnControl("lieu_fonction.ville_lieu_fonction")?.getValidationLabelElement()}
              </span>
            </span>
            <input type="text" tabIndex={5}
              value={lieuFonction?.villeLieuFonction ?? ''}
              onChange={e =>
                setLieuFonction({ ...lieuFonction, villeLieuFonction: e.currentTarget.value })
              }
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("lieu_fonction.ville_lieu_fonction")?.getValidationInputClass(activate, hasFocus, lieuFonction!)}
              `} />
          </label>
          <label className="block mb-2 col-span-4">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_lieu_fonction_code_postal')}
                {getColumnControl("lieu_fonction.code_postal")?.getValidationLabelElement()}
              </span>
            </span>
            <input type="text" tabIndex={5}
              value={lieuFonction?.codePostal ?? ''}
              onChange={e =>
                setLieuFonction({ ...lieuFonction, codePostal: e.currentTarget.value })
              }
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("lieu_fonction.code_postal")?.getValidationInputClass(activate, hasFocus, lieuFonction!)}
              `} />
          </label>
        </div>
        <div className="mb-1 w-full justify-between gap-2 grid grid-cols-8">
          <label className="block mb-2 col-span-4">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_lieu_fonction_fk_pays')}
                {controls?.find((i) => i.column === 'lieu_fonction.fk_pays')?.required && <span className="text-red-500">**</span>}
                {controls?.find((i) => i.column === 'lieu_fonction.fk_pays')?.recommended && <span className="text-orange-500">*</span>}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={pays}
              value={pays.filter(option => {
                return option.value === lieuFonction?.fkPays;
              })}
              onChange={(e: any): void => setLieuFonction({ ...lieuFonction, fkPays: e.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("lieu_fonction.fk_pays")?.getValidationInputClass(activate, hasFocus, lieuFonction!)}
              `}
            />
          </label>
          <label className="block mb-2 flex-1 col-span-4">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_lieu_fonction_code_fiscal')}
                {getColumnControl("lieu_fonction.code_fiscal")?.getValidationLabelElement()}
              </span>
            </span>
            <input type="text" tabIndex={5}
              value={lieuFonction?.codeFiscal ?? ''}
              onChange={e => setLieuFonction({ ...lieuFonction, codeFiscal: e.currentTarget.value })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
              ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${getColumnControl("lieu_fonction.code_fiscal")?.getValidationInputClass(activate, hasFocus, lieuFonction!)}
            `} />
          </label>
        </div>
        <div className='mb-1 w-full justify-between gap-2 grid grid-cols-8'>
          <label className="block mb-2 col-span-4">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_lieu_fonction_siret')}
                {getColumnControl("lieu_fonction.siret")?.getValidationLabelElement()}
              </span>
            </span>
            <input type="text" tabIndex={5}
              value={lieuFonction?.siret ?? ''}
              onChange={e => setLieuFonction({ ...lieuFonction, siret: e.currentTarget.value })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
              ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${getColumnControl("lieu_fonction.siret")?.getValidationInputClass(activate, hasFocus, lieuFonction!)}
            `} />
          </label>
          <label className="block mb-2 col-span-4">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_lieu_fonction_gln')}
                {getColumnControl("lieu_fonction.gln")?.getValidationLabelElement()}
              </span>
            </span>
            <input type="text" tabIndex={5}
              value={lieuFonction?.gln ?? ''}
              onChange={e => setLieuFonction({ ...lieuFonction, gln: e.currentTarget.value })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
              ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${getColumnControl("lieu_fonction.gln")?.getValidationInputClass(activate, hasFocus, lieuFonction!)}
            `} />
          </label>
        </div>
      </form>
    </BlockGrid>
  );
};

export default IdentificationLieu;