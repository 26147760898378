import { FC } from "react";
import { useParams, Routes, Route, Navigate } from "react-router-dom";

import BreadCrumb from "components/Breadcrumb";
import ArticlePresentation from "components/Article/ArticlePresentation";
import ArticleTabs from "components/Article/ArticleTabs";
import TabGeneral from "components/Article/TabGeneral";
import TabAchats from "components/Article/TabAchats";
import TabVentes from "components/Article/TabVentes";
import TabLogistique from "components/Article/TabLogistique";
import TabDroitsAgrement from "components/Article/TabDroitsAgrements";
import TabApprovisionnement from "components/Article/TabApprovisionnement";
import TabNomemclature from "components/Article/TabNomemclature";
import TabAssortiment from "components/Article/TabAssortiment";
import TabECommerce from "components/Article/TabECommerce";
import TabMedias from "components/Article/TabMedias";

import { ArticleProvider, useArticle, CompletudeArticleProvider, UpdateArticleProvider } from "context/Referencement";

const ArticleWithContext: FC = () => {
  let { code } = useParams();
  const { state: article } = useArticle();

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: "tab_listing_articles",
      link: `/app/referencement/articles`,
    },
    {
      name: `${code} - ${article?.designationStandard ||
        article?.designationLong ||
        article?.designationCourt ||
        article?.designationCaisse || "..."
        }`,
      translate: false,
    },
  ];

  if (!article?.id) return (<></>);
  return (
    <div key={article?.id} className="relative flex flex-col" style={{ height: "calc(100vh - 35px)" }}>
      <div className="w-full" role="application">
        <BreadCrumb content={breadcrumb} />
        <ArticlePresentation />
        <ArticleTabs />
      </div>
      <div className="rounded-b-lg overflow-y-scroll pb-24 fiche-space">
        <Routes>
          <Route path="general" element={<TabGeneral />} />
          <Route path="achats" element={<TabAchats />} />
          <Route path="ventes" element={<TabVentes />} />
          <Route path="logistique" element={<TabLogistique />} />
          <Route path="droits-agrements" element={<TabDroitsAgrement />} />
          <Route path="approvisionnement" element={<TabApprovisionnement />} />
          <Route path="nomenclature" element={<TabNomemclature />} />
          <Route path="assortiment" element={<TabAssortiment />} />
          <Route path="e-commerce" element={<TabECommerce />} />
          <Route path="medias" element={<TabMedias />} />
          <Route path="*" element={<Navigate to="general" replace />} />
        </Routes>
      </div>
    </div>
  );
};

const Article: FC = () => {
  let { code } = useParams();

  return (
    <ArticleProvider articleCode={code}>
      <UpdateArticleProvider>
        <CompletudeArticleProvider>
          <ArticleWithContext />
        </CompletudeArticleProvider>
      </UpdateArticleProvider>
    </ArticleProvider>
  );
};

export default Article;
