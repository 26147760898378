import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {TypologieInsertionModel, TypologieViewModel} from "openapi-typescript-codegen";
import BlockGrid, {LayoutEnum, PropsBlock} from 'components/BlockGrid';
import { showPrettyError, toast } from "utils";
import { TextInput } from "../../../components/InputWrapper";
import MarquesContext from "../MarquesContext";
import {typologiesApi} from "api";

interface Props extends PropsBlock {
  setIsOpen?: (v: boolean) => void;
  isOpen?: boolean;
  data?: TypologieViewModel;
}

const TypologieModal: FunctionComponent<Props> = ({ setIsOpen, isOpen, data }) => {
  const { t } = useTranslation();
  const { setSelection, setRefresh } = useContext(MarquesContext);
  const [typologie, setTypologie] = useState<Partial<TypologieViewModel>>({});

  useEffect(() => {
    if (isOpen) { data ? setTypologie(data) : setTypologie({}); }
  }, [isOpen]);

  async function handleUpdate() {
    if (typologie.hasOwnProperty("id")) {
      try {
        await typologiesApi.v1TypologiesPut(typologie as TypologieViewModel);
        toast.success(t("mes_validation_modification"));
        setIsOpen && setIsOpen(false);
      } catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    } else { await handleCreate(); }
  }

  async function handleCreate() {
    try {
      const { data: $typologie } = await typologiesApi.v1TypologiesPost(typologie as TypologieInsertionModel);
      toast.success(t("mes_validation_creation"));
      setSelection({ typologie: $typologie.id, surMarque: null, marque: null, sousMarque: null });
      setIsOpen && setIsOpen(false);
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  }

  return (
    <BlockGrid
      layout={LayoutEnum.modal}
      title={typologie.hasOwnProperty("id") ? t('tit_modification_typologie') : t('tit_creation_typologie')}
      informations={''}
      toActivate={true}
      handleUpdate={async () => { await handleUpdate().then(() => { setRefresh(true); }); }}
      handleCancel={() => { setIsOpen && setIsOpen(false); }}
    >
      <form onSubmit={$event => $event.preventDefault()} className="flex flex-col">
        <div className="grid grid-cols-6 gap-4">
          <TextInput labelClassAdd="col-span-1" label={t("lib_typologie_code")}
                     value={typologie} field="code" setValue={setTypologie} required={true} />
          <TextInput labelClassAdd="col-span-5" label={t("lib_typologie_nom_typologie")}
                     value={typologie} field="nomTypologie" setValue={setTypologie} required={true} />
        </div>
      </form>
    </BlockGrid>
  );
};

export default TypologieModal;