import { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'utils';

import { useArticle, useCompletudeArticle, useUpdateArticle } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateArticle';
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import SelectComplete, { SelectTemplate } from 'components/SelectComplete';
import NomenclatureLine from './NomenclatureLine';

import { articlesApi, nomenclatureDouanieresApi, articleNomenclatureDouanieresApi } from 'api';
import {
  ArticleNomenclatureDouaniereInsertionModel,
  ArticleNomenclatureDouaniereViewModel
} from 'openapi-typescript-codegen';

import { useTranslation } from "react-i18next";

interface FormArray extends Partial<ArticleNomenclatureDouaniereViewModel> {
  newData?: boolean;
  deleteData?: boolean;
}

const NomenclatureDouaniere: FunctionComponent<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: controled } = useCompletudeArticle();
  // const [completude, setCompletude] = useState<number>(0);
  const { state: article } = useArticle();
  const [loading, setLoader] = useState<boolean>(true);
  const updater = useUpdateArticle();
  const [activate, setActivate] = useState<boolean>(false);
  const [form, setForm] = useState<FormArray[]>([]);
  const [nomenclatureList, setNomenclatureList] = useState<SelectTemplate[]>([]);
  const [nomenclatureWaiting, setNomenclatureWaiting] = useState<SelectTemplate>({ value: null });

  const fetchUpdateData = async () => {
    try {
      const dataToSent = form
        .map((item) => {
          if (item.deleteData && item.id) return articleNomenclatureDouanieresApi.v1ArticleNomenclatureDouanieresIdDelete(item.id); // activer quand suppression active et faire les tests
          else if (item.id) return articleNomenclatureDouanieresApi.v1ArticleNomenclatureDouanieresIdPut(item.id, item);
          else return articleNomenclatureDouanieresApi.v1ArticleNomenclatureDouanieresPost(item as ArticleNomenclatureDouaniereInsertionModel);
        });
      await Promise.all(dataToSent);
      updater.triggerEvent(UpdateEnum.nomenclatureDouaniere);
      toast.success(t('mes_validation_modification'));
      if (article?.id) fetchGetArticleNomenclatureDouaniere(article.id);
      setActivate(false);
    } catch (error: any) {
      toast.error(t('mes_erreur_technique') + ": " + (error?.response?.data?.message || error));
    }
  };

  const fetchGetNomenclatureDouaniereList = async () => {
    let { data: response } = await nomenclatureDouanieresApi.v1NomenclatureDouanieresGet(1, 300);
    let tmp: SelectTemplate[] = [];
    if (response.data && response.data.length > 0) {
      tmp = [
        { value: null, label: t('tab_recherche'), isDisabled: true },
        ...response.data.map(
          (item) => ({ ...item,
            value: item.id,
            label: item.codeNomenclatureSh + " - " + item.codeNomenclatureCombinee + " - " + item.codeTarifIntegre + " - " + item.codeComplement + " - " + item.nomNomenclature,
            data: item
          })
        )
      ];
    }
    setNomenclatureList(tmp);
    setLoader(false);
  };

  const fetchGetArticleNomenclatureDouaniere = async (id: number) => {
    let { data: response } = await articlesApi.v1ArticlesIdNomenclatureDouanieresGet(id);
    if (response.length !== 0) {
      setForm(response);
    }
  };

  useEffect(() => {
    if (article?.id) fetchGetArticleNomenclatureDouaniere(article.id);
    fetchGetNomenclatureDouaniereList();
  }, []);

  // function checkMinimal() {
  //   if (controled?.article_nomenclature_douaniere.fk_nomenclature_douaniere?.required) {
  //     const valid = form.filter((i) => { return i.deleteData !== true; });
  //     if (valid.length > 0) return true;
  //     else return false;
  //   }
  //   return true
  // }

  function validator() {
    let tmp: number[] = [];
    return form
      .filter((i) => { return i.deleteData !== true; })
      .every((item) => {
        let myPays = item.fkPays === null || item.fkPays === undefined ? -1 : item.fkPays;
        if (tmp.includes(myPays)) {
          toast.error(t('err_unique_nomenclature_douaniere'));
          return false;
        } else tmp.push(myPays);
        if (!item.fkArticle || !item.fkNomenclatureDouaniere) {
          toast.error("Merci de remplir tous les champs requis");
          return false;
        }
        else return true;
      });
  }

  function handleUpdate() {
    // if (checkMinimal()) {
    if (validator() && form) fetchUpdateData();
    // } else toast.error("Validation impossible: un article doit posséder une nomenclature");
  }

  function handleCancel() {
    if (article?.id) fetchGetArticleNomenclatureDouaniere(article.id);
    setActivate(false);
  }

  function isSelectedPays() {
    const selectedPays: number[] = [];
    form
      .filter((i) => i.deleteData !== true)
      .forEach((item: FormArray) => {
        if (item.fkPays && !selectedPays.includes(item.fkPays))
          selectedPays.push(item.fkPays);
      });
    return selectedPays;
  }

  return (
    <BlockGrid
      title={t('tab_nomenclature_douaniere')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      completude={controled?.find((i) => i.colonneArticle === 'article_nomenclature_douaniere.fk_nomenclature_douaniere')?.required && form.length < 1 ? 1 : 0}
      loading={loading}
      handleClick={(res: boolean) => setActivate(res)}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
    >
      <form className="flex justify-between flex-nowrap flex-col">
        <div
          className={`w-full flex gap-2 transition-all`}
          style={{ height: !activate && form.length > 0 ? '0px' : '62px', overflow: !activate && form.length > 0 ? 'hidden' : '' }}
        >
          <div className="grid grid-cols-24 w-full">
            <label className="block col-span-12 mb-2 flex-1 pr-6 relative">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {"Recherche"}
                </span>
              </span>
              <SelectComplete tabIndex={5}
                isDisabled={!activate}
                options={nomenclatureList}
                value={nomenclatureWaiting}
                onChange={(e: any): void => setNomenclatureWaiting(e)}
              />
            </label>
            <label className="block mb-2 flex-1 pr-6 relative">
              <button type="button" tabIndex={5}
                disabled={!activate}
                className={`h-8 py-0.5 px-2 mt-[22px] rounded bg-white-500 border border-store-primary text-store-primary text-xs whitespace-nowrap
                hover:bg-store-primary  hover:text-white-500 focus:bg-store-primary focus:text-white-500`}
                onClick={() => {
                  if (nomenclatureWaiting?.value !== null) {
                    setForm([...form, {
                      fkArticle: article?.id as number,
                      fkNomenclatureDouaniere: nomenclatureWaiting?.data?.id as number,
                      fkPays: nomenclatureWaiting?.data?.fkPays as number,
                    }]);
                    setNomenclatureWaiting({ value: null });
                  }
                }}>Ajouter +</button>
            </label>
          </div>
        </div>
        {form.length > 0 &&
          <div className="w-full flex gap-2">
            <div className={`grid w-full gap-2 relative ${activate && 'pr-4'} transition-all`} style={{ gridTemplateColumns: `repeat(24, minmax(0, 1fr))` }}>
              <label className="block col-span-3 flex-1">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_nomenclature_douaniere_fk_pays')}</span></span>
              </label>
              <label className="block col-span-3 flex-1">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_nomenclature_douaniere_code_nomenclature_sh')}</span></span>
              </label>
              <label className="block col-span-3 mb-2 flex-1">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_nomenclature_douaniere_code_nomenclature_combinee')}</span></span>
              </label>
              <label className="block col-span-3 mb-2 flex-1">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_nomenclature_douaniere_code_tarif_integre')}</span></span>
              </label>
              <label className="block col-span-3 mb-2 flex-1">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_nomenclature_douaniere_code_complement')}</span></span>
              </label>
              <label className="block mb-2 col-span-6 flex-1">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_nomenclature_douaniere_nom_nomenclature')}</span></span>
              </label>
              <label className="block col-span-3 mb-2 flex-1">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_nomenclature_douaniere_fl_certificat_obligatoire')}</span></span>
              </label>
            </div>
          </div>
        }

        {!!form && !!form.length &&
          form
            .filter((item) => { return item.deleteData !== true; })
            .sort((a) => a.fkPays === null ? -1 : 1)
            .map((item: FormArray, key) =>
              <NomenclatureLine
                key={key}
                activate={activate}
                value={item}
                onChange={(e: any) => {
                  let newData = [...form];
                  newData[form.indexOf(item)] = e;
                  setForm(newData.filter((i) => !(i.deleteData === true && !i.id)));
                }}
                paysSelected={isSelectedPays()}
              />
            )
        }

      </form>
    </BlockGrid>
  );
};

export default NomenclatureDouaniere;