import { FunctionComponent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import SelectComplete, { SelectTemplate } from "components/SelectComplete";
import SiteListElement from "components/Fournisseur/TabCircuit/Navigation/Interaction/CreationCadencierLN/SiteListElement";

interface Props {
  sitesOptions: SelectTemplate<number>[];
  onUpdate: (sites: SelectTemplate<number>[]) => void;
}

const SitesSelection: FunctionComponent<Props> = ({ sitesOptions, onUpdate }) => {
  const { t } = useTranslation();
  // Ongoing site:
  const [selectedOption, setSelectedOption] = useState<SelectTemplate<number>>();
  // List of all selected sites:
  const [selectedSites, setSelectedSites] = useState<SelectTemplate<number>[]>([]);
  // List of remaining options:
  const [availableOptions, setAvailableOptions] = useState<SelectTemplate<number>[]>([]);

  useEffect(() => {
    // Filter the options by removing the selected sites:
    setAvailableOptions(selectedSites ? sitesOptions.filter((option) => selectedSites.every((site) => site.value !== option.value)) : sitesOptions);
  }, [selectedSites, sitesOptions]);

  useEffect(() => {
    // Update the parent component with the selected sites:
    onUpdate(selectedSites);
  }, [selectedSites]);

  return (
    <>
      <hr className="my-2" />
      <div className="col-span-12 items-center pt-3 pb-1 h-10">
        <div className="text-grey-500 font-bold text-sm">{t('lib_cadencier_fournisseur_affectation')}</div>
      </div>
      <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
        <span>{t('lib_site_site_type')}
          <span className="text-red-500">**</span>
        </span>
      </span>
      <div className="grid grid-cols-16 gap-4">
        <label className="block col-span-7 flex-1">
          <SelectComplete
            options={availableOptions}
            value={sitesOptions.filter(option => option.value === selectedOption?.value)}
            onChange={(option: any) => { setSelectedOption(option); }}
            placeholder={t('tab_recherche')}
          />
        </label>
        <button type="button" tabIndex={5}
          disabled={false}
          className={`col-span-1 h-8 py-0.5 px-2 rounded bg-white-500 border border-store-primary text-store-primary text-xs whitespace-nowrap
              hover:bg-store-primary mt-1 hover:text-white-500 focus:bg-store-primary focus:text-white-500`}
          onClick={() => {
            if (selectedOption?.value) {
              const element = selectedSites.find(site => site.value === selectedOption.value);
              if (!element) { setSelectedSites([...selectedSites, selectedOption]); }
              setSelectedOption({ value: 0 });
            }
          }}>
          +
        </button>
        <div className="col-span-8 grid grid-cols-1 gap-4">
          {
            selectedSites
              .map(item =>
                <SiteListElement item={item} onDelete={element => {
                  const sites = selectedSites.filter(site => site.value !== element.value);
                  setSelectedSites([...sites]);
                }} key={item.value} />
              )
          }
        </div>
      </div>
    </>
  );
};

export default SitesSelection;