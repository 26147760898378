import {FunctionComponent, useEffect, useState} from 'react';

import { useArticle, useUpdateArticle } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateArticle';

import BlockGrid, {PropsBlock} from 'components/BlockGrid';
import SelectComplete, {SelectTemplate} from 'components/SelectComplete';

import {
  articlesApi,
  codeEtatApi,
  tvasApi,
  typeArticlesApi,
  uniteMesuresApi,
  uniteVentesApi
} from 'api';

import { useTranslation } from "react-i18next";

import { api, toast, normalizeDecimal } from 'utils';
import { ColumnControlArticle } from "validators";

interface FormArray {
  id?: number,
  fkTypeArticle: number | null,
  fkTva: number | null,
  codeExterne: string,
  fkUniteVente: number | null,
  fkUniteMesure: number | null,
  poidsUnitaire: string,
  poidsEgoutte: string,
  valeurMesure: string,
  dureeVieArticle: string,
  longueur: string,
  largeur: string,
  hauteur: string,
  fkCodeEtat: number | null,
}

const Produit: FunctionComponent<PropsBlock> = ({sm, md, lg, xl, doubleXl}) => {
  const {t} = useTranslation();
  const {state: article} = useArticle();
  const updater = useUpdateArticle();
  const [completude, setCompletude] = useState<number>(0);
  const [activate, setActivate] = useState<boolean>(false);
  const [hasFocus, setFocus] = useState(false);
  const [form, setForm] = useState<FormArray>({
    fkTypeArticle: null,
    fkTva: null,
    codeExterne: '',
    fkUniteVente: null,
    fkUniteMesure: null,
    poidsUnitaire: '',
    poidsEgoutte: '',
    valeurMesure: '',
    // typeEtiquette: 'electronique',
    // nombre: '',
    dureeVieArticle: '',
    longueur: '',
    largeur: '',
    hauteur: '',
    fkCodeEtat: null
  });
  const [loading, setLoader] = useState<boolean>(true);

  // Options for the selects:
  const [options, setOptions] = useState<{ [key: string]: SelectTemplate[] }>({
    codeEtat: [], tva: [], uniteMesures: [], typeArticle: [], uniteMesure: [], uniteVente: []
  });

  // The options are all fetched at the first render:
  useEffect(() => {
    api.options.retrieve([
      {
        key: "codeEtat",
        promise: codeEtatApi.v1CodeEtatsGet(1, 200),
        configuration: { label: "#{code} - #{nomCodeEtat}" },
        nullable: true
      },
      {
        key: "tva",
        promise: tvasApi.v1TvasTauxGet(),
        configuration: { label: "#{code} - #{nomTva} (#{taux}%)" },
        nullable: true
      },
      {
        key: "typeArticle",
        promise: typeArticlesApi.v1TypeArticlesGet(1, 200),
        configuration: { label: "#{code} - #{nomTypeArticle}" },
        nullable: true
      },
      {
        key: "uniteMesure",
        promise: uniteMesuresApi.v1UniteMesuresGet(1, 200),
        configuration: { label: "#{code} - #{nomUniteMesure}" },
        nullable: true
      },
      {
        key: "uniteVente",
        promise: uniteVentesApi.v1UniteVentesGet(1, 200),
        configuration: { label: "#{code} - #{nomUniteVente}" },
        nullable: true
      }
    ]).then(setOptions);
  }, []);

  function checkCompletude() {
    const $completude = ColumnControlArticle.checkMany([
      "article.fk_type_article",
      "article.fk_tva",
      "article.code_externe",
      "article.fk_unite_vente",
      "article.fk_unite_mesure",
      "article.poids_unitaire",
      "article.poids_egoutte",
      "article.valeur_mesure",
      "article.duree_vie_article",
      "article.longueur",
      "article.largeur",
      "article.hauteur",
    ], form);
    setCompletude($completude);
  }

  const fetchGetData = async (id: number) => {
    const response = await articlesApi.v1ArticlesIdGet(id);
    let tmp = {
      id: response.data.id,
      fkTypeArticle: response.data.fkTypeArticle ?? null,
      fkTva: response.data.fkTva ?? null,
      codeExterne: response.data.codeExterne ?? '',
      fkUniteVente: response.data.fkUniteVente ?? null,
      fkUniteMesure: response.data.fkUniteMesure ?? null,
      poidsUnitaire: response.data.poidsUnitaire === 0 ? '0' :
        typeof response.data.poidsUnitaire === 'number' ? response.data.poidsUnitaire.toString() : '',
      poidsEgoutte: response.data.poidsEgoutte === 0 ? '0' :
        typeof response.data.poidsEgoutte === 'number' ? response.data.poidsEgoutte.toString() : '',
      valeurMesure: response.data.valeurMesure === 0 ? '0' :
        typeof response.data.valeurMesure === 'number' ? response.data.valeurMesure.toString() : '',
      dureeVieArticle: response.data.dureeVieArticle === 0 ? '0' :
        typeof response.data.dureeVieArticle === 'number' ? response.data.dureeVieArticle.toString() : '',
      longueur: response.data.longueur === 0 ? '0' :
        typeof response.data.longueur === 'number' ? response.data.longueur.toString() : '',
      largeur: response.data.largeur === 0 ? '0' :
        typeof response.data.largeur === 'number' ? response.data.largeur.toString() : '',
      hauteur: response.data.hauteur === 0 ? '0' :
        typeof response.data.hauteur === 'number' ? response.data.hauteur.toString() : '',
      fkCodeEtat: response.data.fkCodeEtat ?? null,
    };
    if (response.status >= 200 && response.status < 300) setLoader(false);
    setForm(tmp);
  };

  const fetchUpdateData = async (id: number) => {
    const tmp: string = JSON.stringify({
      fkTypeArticle: form.fkTypeArticle,
      fkTva: form.fkTva,
      codeExterne: form.codeExterne,
      fkUniteVente: form.fkUniteVente,
      fkUniteMesure: form.fkUniteMesure,
      poidsUnitaire: form.poidsUnitaire !== '' ? parseFloat(form.poidsUnitaire) : null,
      poidsEgoutte: form.poidsEgoutte !== '' ? parseFloat(form.poidsEgoutte) : null,
      valeurMesure: form.valeurMesure !== '' ? parseFloat(form.valeurMesure) : null,
      dureeVieArticle: form.dureeVieArticle !== '' ? parseFloat(form.dureeVieArticle) : null,
      longueur: form.longueur !== '' ? parseFloat(form.longueur) : null,
      largeur: form.largeur !== '' ? parseFloat(form.largeur) : null,
      hauteur: form.hauteur !== '' ? parseFloat(form.hauteur) : null,
      fkCodeEtat: form.fkCodeEtat
    });
    const response = await articlesApi.v1ArticlesIdPut(id, tmp);

    if (response.status >= 200 && response.status < 300) { //success
      toast.success(t('mes_validation_modification'));
      // reset dat with response.data ?
      updater.triggerEvent(UpdateEnum.produit);
      setActivate(false);
    } else
      toast.error(t('mes_erreur_technique') + ": " + response.status);
  };

  useEffect(() => {
    checkCompletude();
  }, [form]);

  useEffect(() => {
    if (article?.id) fetchGetData(article?.id);
  }, [article?.id]);

  function validator() {
    return ColumnControlArticle.validateMany([
      "article.fk_type_article",
      "article.fk_tva",
      "article.code_externe",
      "article.fk_unite_vente",
      "article.fk_unite_mesure",
      "article.poids_unitaire",
      "article.poids_egoutte",
      "article.valeur_mesure",
      "article.duree_vie_article",
      "article.longueur",
      "article.largeur",
      "article.hauteur",
    ], form);
  }

  function handleUpdate() {
    if (validator()
      // && checker()
      && form.id) fetchUpdateData(form.id);
  }

  function handleCancel() {
    if (article?.id) fetchGetData(article?.id);
    setActivate(false);
  }

  function handleFocus(focus: boolean) {
    setFocus(focus);
  }

  return (
    <BlockGrid
      title={t('tab_article_produit')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      anchor="produit-article"
      completude={completude}
      loading={loading}
      handleClick={(res: boolean) => setActivate(res)}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      handleFocus={handleFocus}
    >
      <form className="flex flex-col justify-between">
        <div className="">
          <div className="flex gap-4">
            <div className="flex-1">
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_fk_type_article')}
                    { ColumnControlArticle.get("article.fk_type_article")?.getValidationLabelElement() }
                  </span>
                </span>
                <SelectComplete tabIndex={5} isDisabled={!activate}
                                options={options.typeArticle}
                                value={options.typeArticle.filter(option => option.value === form.fkTypeArticle)}
                                onChange={(option: any): void => setForm({...form, fkTypeArticle: option.value})}
                                classNameAdd={`
                      ${ loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200' }
                      ${ ColumnControlArticle.get("article.fk_type_article")?.getValidationInputClass(activate, hasFocus, form) }
                    `}
                />
              </label>
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_fk_unite_vente')}
                    { ColumnControlArticle.get("article.fk_unite_vente")?.getValidationLabelElement() }
                  </span>
                </span>
                <SelectComplete tabIndex={5} isDisabled={!activate}
                                options={options.uniteVente}
                                value={options.uniteVente.filter(option => option.value === form.fkUniteVente)}
                                onChange={(e: any): void => setForm({...form, fkUniteVente: e.value})}
                                classNameAdd={`
                    ${ loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200' }
                    ${ ColumnControlArticle.get("article.fk_unite_vente")?.getValidationInputClass(activate, hasFocus, form) }
                  `}
                />
              </label>
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_fk_unite_mesure')}
                    { ColumnControlArticle.get("article.fk_unite_mesure")?.getValidationLabelElement() }
                  </span>
                </span>
                <SelectComplete tabIndex={5} isDisabled={!activate}
                                options={options.uniteMesure}
                                value={options.uniteMesure.filter(option => option.value === form.fkUniteMesure)}
                                onChange={(option: any): void => setForm({...form, fkUniteMesure: option.value})}
                                classNameAdd={`
                      ${ loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200' }
                      ${ ColumnControlArticle.get("article.fk_unite_mesure")?.getValidationInputClass(activate, hasFocus, form) }
                    `}
                />
              </label>
              <label className="block mb-2">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t('lib_article_fk_code_etat')}
                  {ColumnControlArticle.get("article.codeEtat")?.getValidationLabelElement()}
                </span>
              </span>
                <SelectComplete tabIndex={5} isDisabled={!activate}
                                options={options.codeEtat}
                                value={options.codeEtat.filter(option => option.value === form.fkCodeEtat)}
                                onChange={(option: any) => setForm({...form, fkCodeEtat: option.value })}
                                classNameAdd={`
                    ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                    ${ColumnControlArticle.get("article.codeEtat")?.getValidationInputClass(activate, hasFocus, form)}
                  `}
                />
              </label>
            </div>

            <div className="flex-1">
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_fk_tva')}
                    { ColumnControlArticle.get("article.fk_tva")?.getValidationLabelElement() }
                  </span>
                </span>
                <SelectComplete tabIndex={5} isDisabled={!activate}
                                options={options.tva}
                                value={options.tva.filter(option => option.value === form.fkTva)}
                                onChange={(option: any): void => setForm({...form, fkTva: option.value})}
                                classNameAdd={`
                      ${ loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200' }
                      ${ ColumnControlArticle.get("article.fk_tva")?.getValidationInputClass(activate, hasFocus, form) }
                    `}
                />
              </label>
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_poids_unitaire')}
                    { ColumnControlArticle.get("article.lib_article_poids_unitaire")?.getValidationLabelElement() }
                  </span>
                </span>
                <input type="number" tabIndex={5}
                       min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                       value={form.poidsUnitaire}
                       onChange={e => setForm({...form, poidsUnitaire: normalizeDecimal(e.currentTarget.value, 2)})}
                       disabled={!activate}
                       className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200' }
                      ${ ColumnControlArticle.get("article.poids_unitaire")?.getValidationInputClass(activate, hasFocus, form) }
                    `}/>
              </label>
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_valeur_mesure')}
                    { ColumnControlArticle.get("article.valeur_mesure")?.getValidationLabelElement() }
                  </span>
                </span>
                <input type="number" tabIndex={5}
                       min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                       value={form.valeurMesure}
                       onChange={e => setForm({...form, valeurMesure: normalizeDecimal(e.currentTarget.value, 2)})}
                       disabled={!activate}
                       className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200' }
                      ${ ColumnControlArticle.get("article.valeur_mesure")?.getValidationInputClass(activate, hasFocus, form) }
                    `}/>
              </label>

            </div>

            <div className="flex-1">
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_code_externe')}
                    { ColumnControlArticle.get("article.code_externe")?.getValidationLabelElement() }
                  </span>
                </span>
                <input type="text" tabIndex={5}
                       value={form.codeExterne}
                       onChange={e => setForm({...form, codeExterne: e.currentTarget.value})}
                       disabled={!activate}
                       className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200' }
                      ${ ColumnControlArticle.get("article.code_externe")?.getValidationInputClass(activate, hasFocus, form) }
                    `}/>
              </label>

              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_poids_egoutte')}
                    { ColumnControlArticle.get("article.poids_egoutte")?.getValidationLabelElement() }
                  </span>
                </span>
                <input type="number" tabIndex={5}
                       min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                       value={form.poidsEgoutte}
                       onChange={e => setForm({...form, poidsEgoutte: normalizeDecimal(e.currentTarget.value, 2)})}
                       disabled={!activate}
                       className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200' }
                      ${ ColumnControlArticle.get("article.poids_egoutte")?.getValidationInputClass(activate, hasFocus, form) }
                    `}/>
              </label>
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_duree_vie_article')}
                    { ColumnControlArticle.get("article.duree_vie_article")?.getValidationLabelElement() }
                  </span>
                </span>
                <input type="number" tabIndex={5}
                       min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                       value={form.dureeVieArticle}
                       onChange={e => setForm({...form, dureeVieArticle: normalizeDecimal(e.currentTarget.value, 2)})}
                       disabled={!activate}
                       className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200' }
                      ${ ColumnControlArticle.get("article.duree_vie_article")?.getValidationInputClass(activate, hasFocus, form) }
                    `}/>
              </label>
            </div>
            <div className="flex-1">
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_longueur')}
                    { ColumnControlArticle.get("article.longueur")?.getValidationLabelElement() }
                  </span>
                </span>
                <input type="number" tabIndex={5}
                       min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                       value={form.longueur}
                       onChange={e => setForm({...form, longueur: normalizeDecimal(e.currentTarget.value, 2)})}
                       disabled={!activate}
                       className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200' }
                      ${ ColumnControlArticle.get("article.longueur")?.getValidationInputClass(activate, hasFocus, form) }
                    `}/>
              </label>
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_largeur')}
                    { ColumnControlArticle.get("article.largeur")?.getValidationLabelElement() }
                  </span>
                </span>
                <input type="number" tabIndex={5}
                       min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                       value={form.largeur}
                       onChange={e => setForm({...form, largeur: normalizeDecimal(e.currentTarget.value, 2)})}
                       disabled={!activate}
                       className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200' }
                      ${ ColumnControlArticle.get("article.largeur")?.getValidationInputClass(activate, hasFocus, form) }
                    `}/>
              </label>
              <label className="block mb-2">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_article_hauteur')}
                    { ColumnControlArticle.get("article.hauteur")?.getValidationLabelElement() }
                  </span>
                </span>
                <input type="number" tabIndex={5}
                       min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                       value={form.hauteur}
                       onChange={e => setForm({...form, hauteur: normalizeDecimal(e.currentTarget.value, 2)})}
                       disabled={!activate}
                       className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200' }
                      ${ ColumnControlArticle.get("article.hauteur")?.getValidationInputClass(activate, hasFocus, form) }
                    `}/>
              </label>
            </div>
          </div>
        </div>
      </form>
    </BlockGrid>
  );
};

export default Produit;