import { FC } from 'react';

import Logistique from 'components/Article/Logistique';
import ApprovisionnementTarifaireLogistique from "components/Article/ApprovisionnementTarifaireLogistique";

const TabLogistique: FC = () => {

  return (
    <div id="logistique" className="flex flex-wrap justify-between bg-grey-150 gap-px p-px">
      <Logistique sm={100} md={100} lg={100} xl={100} doubleXl={100} />
      <ApprovisionnementTarifaireLogistique sm={100} md={100} lg={100} xl={100} doubleXl={100} />
    </div>
  );
};

export default TabLogistique;