import { FC, useEffect, useMemo, useState } from 'react';
import { PayViewModel, TvaViewModel } from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";

import { tvasApi, paysApi } from 'api';
import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import { CheckedCellRenderer, TextCellRenderer } from 'components/AGGride/CellRender';
import { useGridController } from "hooks";
import { notEmptyCellValidator } from "components/AGGride/cellValidator";
import { SelectorCellEditor, SelectorCellEditorProps } from "components/AGGride/CellEditor";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

type GridType = TvaViewModel;

const TableauxTva: FC<{ onTvaChange?: (tvaId: number) => void; }> = ({ onTvaChange }) => {
  const { t } = useTranslation();

  const [pays, setPays] = useState<PayViewModel[]>([]);
  const [activate, setActivate] = useState(false);


  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      onFocusChange: (isFocus) => setActivate(isFocus),
      fetchData: async () => {
        const { data: { data } } = await tvasApi.v1TvasGet(1, 1000);
        return data.sort((a, b) => a.code.localeCompare(b.code));
      },
      postData: (cleanRow) => tvasApi.v1TvasPost(cleanRow),
      putData: (cleanRow) => tvasApi.v1TvasPut(cleanRow),
      deleteData: (dataId) => tvasApi.v1TvasIdDelete(dataId),
      columnDefs: [
        {
          field: 'fkPays',
          headerName: t('lib_tva_fk_pays'),
          headerTooltip: t('lib_tva_fk_pays'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          editable: true,
          tooltipValueGetter: (params) => {
            return pays.find((v) => v.id === params.data?.fkPays)?.nomPays;
          },
          valueFormatter: (params) => pays.find((v) => v.id === params.data?.fkPays)?.nomPays || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: [
              { value: undefined, label: '...' },
              ...pays.map((a) => ({ value: a.id, label: a.nomPays ? (a.codePaysAlpha2 + ' - ' + a.nomPays) : a.codePaysAlpha2 })),
            ]
          } as SelectorCellEditorProps,
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          filter: 'agTextColumnFilter',
        },
        {
          field: 'nomTva',
          headerName: t('lib_tva_nom_tva'),
          headerTooltip: t('lib_tva_nom_tva'),
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          valueValidator: [notEmptyCellValidator],
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
        },
        {
          field: 'code',
          headerName: t('lib_tva_code'),
          headerTooltip: t('lib_tva_code'),
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
        },
        {
          field: 'flActif',
          headerName: t('lib_tva_fl_actif'),
          headerTooltip: t('lib_tva_fl_actif'),
          cellRenderer: CheckedCellRenderer,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
      ],
    }), [activate, t, pays])
  );

  async function fetchPays() {
    const { data: res } = await paysApi.v1PaysGet(1, 1000);
    const newData = res.data?.map(v => ({ ...v, label: `${v.nomPays} (${v.codeIsoRegion})`.toUpperCase() }));
    setPays(newData || []);
  };

  function onRowSelected(params: RowSelectedEvent<GridType>) {
    if (params.node.isSelected() && params.data?.id)
      onTvaChange?.(params.data.id);
  };

  useEffect(() => {
    fetchPays();
  }, []);


  return (
    <div className="flex-[3]">
      <div className="mb-2 text-grey-500 font-bold">{t('tab_tableau_tva')}</div>
      <BlockGrid
        layout={LayoutEnum.settings}
        handleClick={(res: boolean) => {
          setActivate(res);
          setTimeout(gridController.activateContextActionButtons, 50);
        }}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-params">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType="fullRow"
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
            rowSelection="single"
            onRowSelected={onRowSelected}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </div>
  );
};

export default TableauxTva;