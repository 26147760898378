import { FC, useMemo, useState } from 'react';
import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import { Title } from "components/H2Title";
import { PayViewModel } from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import {
  notEmptyCellValidator,
  notNilCellValidator,
  numberCellValidator,
} from "components/AGGride/cellValidator";

import { paysApi } from 'api';
import BlockGrid, { PropsBlock, LayoutEnum } from 'components/BlockGrid';
import { TextCellRenderer } from 'components/AGGride/CellRender';
import { useGridController } from "hooks";
import { NumberCellEditor, TextCellEditor } from "components/AGGride/CellEditor";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

type GridType = PayViewModel;

const Pays: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.nomPays,
      fetchData: async () => {
        const { data: { data } } = await paysApi.v1PaysGet(1, 1000);
        return data.sort(function (a, b) { return a.codePaysNumeric3 - b.codePaysNumeric3; });
      },
      postData: (cleanRow) => paysApi.v1PaysPost(cleanRow),
      putData: (cleanRow) => paysApi.v1PaysPut(cleanRow),
      deleteData: (dataId) => paysApi.v1PaysIdDelete(dataId),
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'codePaysNumeric3',
          headerName: t('lib_pays_code_pays_numeric3'),
          headerTooltip: t('lib_pays_code_pays_numeric3'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notNilCellValidator, numberCellValidator],
          cellEditor: NumberCellEditor,
          editable: true,
          filter: 'agTextColumnFilter',
          cellEditorParams: {
            min: 0,
            max: 999,
          },
          flex: 1
        },
        {
          field: 'codePaysAlpha2',
          headerName: t('lib_pays_code_pays_alpha2'),
          headerTooltip: t('lib_pays_code_pays_alpha2'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          cellEditor: TextCellEditor,
          cellEditorParams: {
            maxLength: 2,
            toUpperCase: true
          },
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 1
        },
        {
          field: 'codePaysAlpha3',
          headerName: t('lib_pays_code_pays_alpha3'),
          headerTooltip: t('lib_pays_code_pays_alpha3'),
          cellRenderer: TextCellRenderer,
          cellEditor: TextCellEditor,
          cellEditorParams: {
            maxLength: 3,
            toUpperCase: true
          },
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 1
        },
        {
          field: 'codeIsoRegion',
          headerName: t('lib_pays_code_iso_region'),
          headerTooltip: t('lib_pays_code_iso_region'),
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 2
        },
        {
          field: 'nomPays',
          headerName: t('lib_pays_nom_pays'),
          headerTooltip: t('lib_pays_nom_pays'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 3
        },
        {
          field: 'nomIso',
          headerName: t('lib_pays_nom_iso'),
          headerTooltip: t('lib_pays_nom_iso'),
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 3
        },
        {
          field: 'drapeau',
          headerName: t('lib_pays_drapeau'),
          headerTooltip: t('lib_pays_drapeau'),
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 1
        },
      ],
    }), [activate, t])
  );

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: `${t('men_refe_referentiel')} - ${t('men_parametre')}`,
      link: `/app/referencement/referentiel/parametres`,
    },
    {
      name: "tit_pays",
    },
  ];

  const title: Title = {
    title: `${t('men_refe_referentiel')} - ${t('tab_pays')}`,
    icon: "referentiel",
  };

  return (
    <>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <BlockGrid
        sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
        layout={LayoutEnum.settings}
        handleClick={(res: boolean) => {
          setActivate(res);
          setTimeout(gridController.activateContextActionButtons, 50);
        }}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-params">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType={"fullRow"}
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </>
  );
};

export default Pays;