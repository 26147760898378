import {FunctionComponent, useState, useEffect, useMemo, useCallback} from 'react';

import {
  LieuFonctionContactWithContactViewModel,
  LieuFonctionInsertionModel,
  LieuFonctionViewModel,
} from "openapi-typescript-codegen";
import { fournisseursApi, lieuFonctionApi, lieuFonctionsContactsApi } from "api";

import DivisionGrid from 'components/BlockGrid/DivisionGrid';
import LateralNavigation from 'components/Fournisseur/LateralNavigation';
import { CreationLieuxLN } from 'components/Fournisseur/LateralNavigation/Interaction/CreationLieuLN';
import Comptabilite from 'components/Fournisseur/Comptabilite';
import IdentificationLieu from 'components/Fournisseur/IdentificationLieu';
import Interface from "components/Fournisseur/Interface";
import DeletionItemLN from "components/Fournisseur/LateralNavigation/Interaction/DeletionItemLN";
import Contacts from "components/Fournisseur/Contacts";

import {useFournisseur, useUpdateFournisseur} from "context/Referencement";

import { useTranslation } from "react-i18next";

import { api, showPrettyError, toast } from "utils";
import {UpdateEnum} from "../../../context/Referencement/updateFournisseur";

interface Props { }

const TabLieux: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const [lieux, setLieux] = useState<LieuFonctionViewModel[]>([]);
  const [identifier, setIdentifier] = useState<number>(0);
  const { state: fournisseur } = useFournisseur();

  const selectedLieu = useMemo(() => lieux.find(lieu => lieu.id === identifier), [lieux, identifier]);

  const refreshLieux = async (reset?: boolean) => {
    const data = fournisseur?.id ? await api.dataset.get<LieuFonctionViewModel>(fournisseursApi.v1FournisseursIdLieuFonctionsGet(fournisseur.id)) : [];
    data.sort((lieu$1, lieu$2) => lieu$1.code?.localeCompare(lieu$2.code || '') || 0);
    setLieux(data);
    reset && setIdentifier(data[0]?.id || 0);
  }

  const refresh = useCallback(($event: UpdateEnum) => {
    if ($event === UpdateEnum.lieuFonction) { refreshLieux(); }
  }, [fournisseur?.id]);
  useUpdateFournisseur(refresh);

  useEffect(() => { refreshLieux(); }, []);
  useEffect(() => { refreshLieux(); }, [fournisseur?.id]);

  useEffect(() => {
    !identifier && setIdentifier(lieux[0]?.id || 0);
  }, [lieux]);

  const handleSelectLieu = (id: number) => { setIdentifier(id); };

  const handleCreateLieu = async (lieu: LieuFonctionInsertionModel) => {
    try {
      lieu.fkFournisseur = fournisseur?.id as number;
      const { data } = await lieuFonctionApi.v1LieuFonctionsPost(lieu);
      toast.success(t('mes_validation_modification'));
      refreshLieux().then(() => { setIdentifier(data.id || 0); });
    } catch (error) {
      console.error(error);
      showPrettyError(error, { fallback: "err_unicite_lieu_fonction_code_lieu_fonction" });
    }
  };

  const handleDeleteLieu = async (id: number) => {
    try {
      await lieuFonctionApi.v1LieuFonctionsIdDelete(id);
      toast.success(t('mes_validation_suppression'));
      await refreshLieux(true);
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  };

  const getLieuFonctionsContacts = async () => await api.dataset.get<LieuFonctionContactWithContactViewModel>(lieuFonctionApi.v1LieuFonctionsIdLieuFonctionContactsGet(identifier));

  const updateContact = (id: number) => lieuFonctionsContactsApi.v1LieuFonctionContactsPost({ fkContact: id, fkLieuFonction: identifier, rang: 1 });

  const deleteLieuFonctionContact = (id: number) => lieuFonctionsContactsApi.v1LieuFonctionContactsIdDelete(id);

  return (
    <div id="lieu-fonction" className="h-full flex flex-wrap justify-between bg-grey-150 gap-px p-px">

      {/* <LateralNavigationLieu sm={20} md={20} lg={20} xl={20} doubleXl={20} onSelected={handleSelected} selected={lieuFonction} /> */}
      <LateralNavigation
        dataSet={lieux}
        selectedItem={selectedLieu}
        onSelectItem={handleSelectLieu}
        createItemButton={<CreationLieuxLN onCreate={handleCreateLieu} />}
        deleteItemButton={
          <DeletionItemLN
            selectedItem={selectedLieu}
            canBeDeleted={true}
            onDeleteItem={handleDeleteLieu}
          />
        }
        width={'280px'}
        display={{
          code: 'code',
          name: 'nomLieuFonction',
          type: 'typeLieuFonction'
        }}
      />
      {!!identifier &&
        <DivisionGrid key={identifier} width={'calc(100% - 280px)'}>
          <IdentificationLieu key={identifier} sm={40} md={40} lg={40} xl={40} doubleXl={40} identifier={identifier} />
          <DivisionGrid sm={40} md={40} lg={40} xl={40} doubleXl={40}>
            <Comptabilite key={identifier} sm={100} md={100} lg={100} xl={100} doubleXl={100} selectedLieu={selectedLieu as LieuFonctionViewModel} />
          </DivisionGrid>
          <Interface sm={100} md={100} lg={100} xl={40} doubleXl={33} selected={identifier} />
          <Contacts onFetch={getLieuFonctionsContacts} onDelete={deleteLieuFonctionContact} onUpdate={updateContact} identifier={identifier} sm={100} md={100} lg={100} xl={60} doubleXl={66} />
        </DivisionGrid>
      }
    </div>
  );
};

export default TabLieux;