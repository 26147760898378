import { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { NavLink } from "react-router-dom";
import { Tooltip } from '@mui/material';
import { useArticle, useCompletudeArticle, useUpdateArticle } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateArticle';
import { getTraductionCode } from 'utils/Article/completude';
import { useTranslation } from 'react-i18next';

import General from 'assets/icons/tabs/general.svg';
import Achats from 'assets/icons/tabs/achats.svg';
import Ventes from 'assets/icons/tabs/sales.svg';
import Logistique from 'assets/icons/tabs/logistique.svg';
import DroitsAgrement from 'assets/icons/tabs/droits-taxes.svg';
import Approvisionnement from 'assets/icons/tabs/approvisionnement.svg';
import Nomenclature from 'assets/icons/tabs/nomenclature.svg';
import Assortiment from 'assets/icons/tabs/assortiment.svg';
import ECommerce from 'assets/icons/tabs/e-commerce.svg';
import Medias from 'assets/icons/tabs/media.svg';

interface TabProps {
  id: string,
  title: string,
  icon: string,
  tab: string;
}

const Tab: FunctionComponent<TabProps> = ({ id, title, icon, tab }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const { state: controled } = useCompletudeArticle();
  const [completude, setCompletude] = useState<number>(100);
  const [required, setRequired] = useState<number>(0);

  const calculateCompletude = () => {
    let tmp = controled?.filter((i) => i.ongletArticle === tab && i.required && i.isEmpty);
    let total = controled?.filter((i) => i.ongletArticle === tab && i.required);
    let calc: number = 100;
    if (tmp && tmp?.length > 0 && total && total?.length > 0) {
      calc = Math.floor(100 - (tmp.length / total.length * 100));
    } else calc = 100;
    setCompletude(calc);
    setRequired(tmp?.length ?? 0);
  };

  const refresh = useCallback((evt: UpdateEnum) => {
    if (evt !== UpdateEnum.patch) {
      calculateCompletude();
    }
  }, [article?.id]);
  useUpdateArticle(refresh);

  useEffect(() => {
    calculateCompletude();
  }, [controled]);

  return (
    <NavLink to={id} tabIndex={4} end
      // tabIndex={active ? -1 : 4}
      className={({ isActive }) => {
        const isClass = ' justify-center px-4 flex items-center transition-all cursor-pointer outline-offset-[-1px]';
        if (isActive) return 'h-14 rounded-t-lg border border-b-0 border-grey-150 text-store-primary drop-shadow bg-white-500' + isClass;
        else return 'h-12 border border-b-0 border-transparent mb-px' + isClass;
      }}>
      <Tooltip title={
        <div>
          {t('men_para_completude_article')}: {completude}%<br /><br />
          {required} {t('mes_completude_champ_manquant')}:<br />
          {
            controled?.filter((i) => i.ongletArticle === tab && i.required && i.isEmpty)
              .map((i, key) => <div key={key}>- {t(getTraductionCode(i.colonneArticle))}</div>)
          }
        </div>} followCursor>
        <div className="flex gap-1 h-7 border-b-2 border-purple-150 relative">
          <span>
            <img alt={`${title} icon`} className="h-4 w-4" style={{ minWidth: '1rem' }} src={icon} />
          </span>
          <span className="whitespace-nowrap">{t(`${title}`)}</span>
          <div className={`absolute -bottom-0.5 left-0 h-0.5 rounded-full
              ${completude === 100 ? 'bg-green-500' : completude < 50 ? 'bg-red-500' : 'bg-orange-400'}`}
            style={{ width: `${completude > 2 ? completude : '2'}%` }}></div>
        </div>
      </Tooltip>
    </NavLink>
  );
};

const TabsList = [
  { id: 'general', title: 'ong_fiche_article_general', icon: General, tab: '1' },
  { id: 'achats', title: 'ong_fiche_article_achats', icon: Achats, tab: '2' },
  { id: 'ventes', title: 'ong_fiche_article_ventes', icon: Ventes, tab: '3' },
  { id: 'logistique', title: 'ong_fiche_article_logistique', icon: Logistique, tab: '4' },
  { id: 'droits-agrements', title: 'ong_fiche_article_droits_taxes', icon: DroitsAgrement, tab: '5' },
  { id: 'approvisionnement', title: 'ong_fiche_article_approvisionnement', icon: Approvisionnement, tab: '6' },
  { id: 'nomenclature', title: 'ong_fiche_article_nomenclature', icon: Nomenclature, tab: '7' },
  { id: 'assortiment', title: 'ong_fiche_article_assortiment', icon: Assortiment, tab: '8' },
  { id: 'e-commerce', title: 'ong_fiche_article_ecommerce', icon: ECommerce, tab: '9' },
  { id: 'medias', title: 'ong_fiche_article_medias', icon: Medias, tab: '10' }
];

interface Props {
}

const ArticleTabs: FunctionComponent<Props> = () => {
  return (
    <div id="product-tabs" className="col-span-full w-full mt-2 h-16 pt-2 flex items-end relative">
      <div className="border rounded-t-lg border-b-0 border-grey-150 h-12 bg-slate-100 flex items-end absolute bottom-0 left-0 w-full"></div>
      <div className="w-full overflow-x-auto no-scrollbar mt-4 h-14 flex items-end text-xs font-bold relative">
        {TabsList.map((tab) => (
          <Tab key={tab.id} id={tab.id}
            title={tab.title}
            icon={tab.icon}
            tab={tab.tab}
          />
        ))}
      </div>
      <div className="absolute w-screen -bottom-px left-0 h-px bg-grey-150"></div>
      <div className="absolute w-screen -bottom-px right-0 h-px bg-grey-150"></div>
    </div>
  );
};

export default ArticleTabs;