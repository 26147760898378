import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  AgEvent,
  CellValueChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  RowClassRules,
  RowEditingStoppedEvent,
  RowValueChangedEvent
} from "ag-grid-community";
import { AgGridCommon } from "ag-grid-community/dist/lib/interfaces/iCommon";
import { ToastOptions } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { generateRandomUint, getPrettyError, isPromiseFulfilled, isPromiseRejected, showPrettyError, toast, isAxiosResponse } from "utils";
import { CustomColDef, CustomColGroupDef, RowDataType, RowNodeType } from "components/AGGride/gridTypes";
import rowValidator from "components/AGGride/cellValidator";
import { UpdateDeleteButtonsCellRenderer } from "components/AGGride/CellRender";

/* Types */

export type GridController = ReturnType<typeof useGridController>;

/* GridController */

export function useGridController<T>(
  options: {
    columnDefs: (CustomColDef<T> | CustomColGroupDef<T>)[],
    rowClassRules?: RowClassRules<RowDataType<T>>;
    colConfig: {
      sortColId?: string;
      startEditingColId?: string;
      autoResize?: boolean;
      floatingAction?: 'none' | 'hover' | 'always';
    },
    autoFetch?: boolean,
    isDebug?: boolean,
    fetchData: () => Promise<RowDataType<T>[]>;
    postData?: (cleanRow: T) => Promise<unknown>;
    putData?: (cleanRow: T) => Promise<unknown>;
    deleteData?: (dataId: number) => Promise<unknown>;
    onFetchData?: (api: GridApi) => void;
    getNewModel?: () => Omit<T, 'id'>;
    emptyRowCheck?: (rowData: RowDataType<T>) => boolean;
    onFocusChange?: (isFocus: boolean) => void;
  }
) {
  const gridRef = useRef<AgGridReact<RowDataType<T>> | null>(null);
  const { t } = useTranslation();
  const [isGridReady, setIsGridReady] = useState(false);
  const componentMounted = useRef(true);
  const [defaultData] = useState<RowDataType<T>[]>([]);
  const [isLoading, setIsLoading] = useState(0);
  const [_isFocus, setIsFocus] = useState(false);
  const [hasEmptyLines, setHasEmptyLines] = useState(false);
  const toastErrorConfig: ToastOptions = { duration: 8000 };

  /* Options */

  const _options = useMemo(() => {
    const colDefs = options.columnDefs.filter((col: CustomColDef<T>) => col.field) as CustomColDef<T>[];
    const startEditingColId = options.colConfig.startEditingColId ?? colDefs[0]?.field ?? '';
    const floatingAction = options.colConfig.floatingAction ?? 'hover';
    const autoFetch = !!options.autoFetch;
    const isDebug = !!options.isDebug;
    const emptyRowCheck = options.emptyRowCheck || (() => false);

    return {
      ...options,
      colConfig: {
        ...options.colConfig,
        startEditingColId,
        floatingAction,
      },
      autoFetch,
      isDebug,
      emptyRowCheck
    };
  }, [options]);

  const columnDefs = useMemo(() => [
    ...(_options.columnDefs),
    {
      field: "context_action_buttons",
      headerName: t("men_aggrid_extra_action"),
      headerTooltip: t("men_aggrid_extra_action"),
      width: 50,
      minWidth: 50,
      hide: _options.colConfig.floatingAction !== 'always',
      cellRenderer: UpdateDeleteButtonsCellRenderer,
      cellRendererParams: {
        onClickDelete: (rowDataId?: number, rowId?: string) => rowDataId && rowId && handleDelete(rowId, rowDataId),
      },
    },
  ], [_options, t]);

  const rowClassRules = useMemo((): RowClassRules<RowDataType<T>> => {
    return {
      'ag-row-warning': (params) => {
        const lastValidation = Object.values(params.data?._customDataProps?.validationSuccess || {});
        return lastValidation.some(val => val === false);
      },
      'ag-row-error': (params) => {
        return params.data?._customDataProps?.serverError === true;
      },
      ..._options.rowClassRules
    };
  }, [_options.rowClassRules]);

  /* Effect */

  useEffect(() => {
    componentMounted.current = true;
    return () => { componentMounted.current = false; };
  }, []);

  useEffect(() => {
    if (!isGridReady) return;
    if (_options.autoFetch) {
      fetchData();
    }
  }, [isGridReady]);

  useEffect(() => {
    if (!isGridReady) return;
    if (!!isLoading) {
      gridRef.current?.api?.showLoadingOverlay();
    }
  }, [isLoading]);

  /* Wrappers */

  const onFocusChangeWrapper = useCallback((isFocus: boolean) => {
    setIsFocus(isFocus);
    _options.onFocusChange?.(isFocus);
    if (!isFocus) gridRef.current!.api.stopEditing(true);
  }, [gridRef, _options]);

  /* Utils */

  const getAgGridCommonsParams = useCallback((): AgGridCommon<RowDataType<T>> => {
    return {
      api: gridRef.current!.api,
      columnApi: gridRef.current!.columnApi,
      context: gridRef.current!.context,
    };
  }, [gridRef]);

  const getRowNode = useCallback((rowId: string) => {
    const node = gridRef.current!.api.getRowNode(rowId);
    if (!node) return null;
    return node;
  }, [gridRef]);

  const getAllRowsNode = useCallback(() => {
    const nodes: RowNodeType<T>[] = [];
    gridRef.current!.api.forEachLeafNode(node => nodes.push(node));
    return nodes;
  }, [gridRef]);

  const getModifiedRows = useCallback(() => {
    return getAllRowsNode().filter(row => row.data!._customDataProps?.isModified);
  }, [getAllRowsNode]);

  const _getEmptyRows = useCallback(() => {
    return getAllRowsNode().filter(row => _options.emptyRowCheck(row.data!));
  }, [_options, getAllRowsNode]);

  const getNewRows = useCallback(() => {
    return getAllRowsNode().filter(row => row.data!._customDataProps?.isNew);
  }, [getAllRowsNode]);

  const getNewEmptyRows = useCallback(() => {
    return getNewRows().filter(row => _options.emptyRowCheck(row.data!));
  }, [_options, getNewRows]);

  const getModifiedOrNewRows = useCallback(() => {
    return getAllRowsNode().filter(row => row.data!._customDataProps?.isModified || row.data!._customDataProps?.isNew);
  }, [_options, getNewRows]);

  /*
  const getPrettyCellValue = useCallback((row: RowNodeType<T>, col: CustomColDef<T>) => {
    let fieldValue = (row.data as RowDataAny)[col.field!];
    if (typeof col.valueFormatter === 'function') {
      fieldValue = col.valueFormatter({
        ...getAgGridCommonsParams(),
        colDef: col,
        node: row,
        data: row.data,
        column: gridRef.current!.columnApi.getColumn(col)!,
        value: fieldValue,
      });
    }
    if (fieldValue === null || fieldValue === undefined || fieldValue === '')
      fieldValue = 'empty';
    return fieldValue;
  }, [gridRef, getAgGridCommonsParams]);
  */

  const isCellEditable = useCallback((row: RowNodeType<T>, col: CustomColDef<T>) => {
    let isEditable = col.editable === true;
    if (typeof col.editable === 'function') {
      isEditable = col.editable({
        ...getAgGridCommonsParams(),
        colDef: col,
        node: row,
        data: row.data,
        column: gridRef.current!.columnApi.getColumn(col)!,
      });
    }
    return isEditable;
  }, [gridRef, getAgGridCommonsParams]);

  /* Actions */

  const refreshSort = useCallback((sort: "asc" | "desc") => {
    if (!_options.colConfig.sortColId) {
      console.warn('gridController: "colConfig.sortColId" in options is undefined');
      return;
    }
    [null, sort].forEach(val =>
      gridRef.current!.columnApi.applyColumnState({
        state: [{ colId: _options.colConfig.sortColId!, sort: val }],
        defaultState: { sort: null },
      })
    );
  }, [gridRef, _options]);

  const runValidator = useCallback(async (row: RowNodeType<T>, showToast = false, dry = false) => {
    let isValid = true;
    const agParams = getAgGridCommonsParams();
    const cols = columnDefs.filter(col => isCellEditable(row, col));

    const colsChecked = await rowValidator(row.data!, cols as CustomColDef[], agParams as AgGridCommon<RowDataType<any>>, dry);

    for (const colChecked of colsChecked) {
      if (colChecked.cell.success) continue;
      isValid = false;
      const msg = colChecked.cell.validatorResults.filter(({ success }) => !success)[0].msg;
      if (!showToast || !msg) continue;
      const col = colChecked.colDef;
      const rowIndex = typeof row.rowIndex === 'number' ? row.rowIndex + 1 : '?';
      // const fieldValue = getPrettyCellValue(row, col);
      toast.error(
        `"${col.headerName || col.field}" ${t('err_ligne')} ${rowIndex}: ${t(msg)}`,
        { ...toastErrorConfig, icon: '⚠️' }
      );
    }
    return isValid;
  }, [/*getPrettyCellValue,*/ getAgGridCommonsParams, isCellEditable, columnDefs]);

  /* Datas */

  const fetchData = useCallback(async () => {
    if (!isGridReady) return;
    setIsLoading(old => old + 1);
    let success = false;
    let newData: RowDataType<T>[] = [];
    try {
      newData = await _options.fetchData();
      success = true;
    } catch (err) {
      console.error(err);
      showPrettyError(err);
    }
    if (!componentMounted.current)
      return;
    gridRef.current!.api.setRowData(newData);
    setHasEmptyLines(false);
    setIsLoading(old => old - 1);
    if (success && _options.onFetchData)
      _options.onFetchData(gridRef.current!.api);
  }, [gridRef, _options, isGridReady, setHasEmptyLines]);

  /* Events */

  const eventLogger = useCallback((event: AgEvent) => {
    if (!_options.isDebug) return;
    console.log(`%cGridController Event: ${event.type}`, 'font-weight: bold;color: grey;');
  }, [_options.isDebug]);

  const onGridReady = useCallback(async (_event: GridReadyEvent) => {
    setIsGridReady(true);
  }, []);

  const onCellValueChanged = useCallback(async (event: CellValueChangedEvent) => {
    eventLogger(event);
    const data: RowDataType<T> = event.data;
    if (!data._customDataProps) data._customDataProps = {};
    if (!data._customDataProps.modified) data._customDataProps.modified = {};
    // Add cell modified flag 
    data._customDataProps.modified[event.colDef.field!] = true;
    // Add row modified flag
    data._customDataProps.isModified = true;
  }, [eventLogger]);

  const onRowValueChanged = useCallback(async (event: RowValueChangedEvent) => {
    eventLogger(event);
  }, [eventLogger]);

  /** 
   * @param useNewRows - False by default
   * @description It is used to prevent the addition of a new row, while a new one has still not being validated but is not empty.
   */
  const onRowEditingStopped = useCallback(async (event: RowEditingStoppedEvent, useNewRows?: boolean) => {
    eventLogger(event);
    if (event.node.group) return;
    setHasEmptyLines(useNewRows ? !!getNewRows().length : !!getNewEmptyRows().length);

    if (!isLoading)
      await runValidator(event.node);
  }, [eventLogger, getNewEmptyRows, isLoading, runValidator]);

  const onFirstDataRendered = useCallback((event: FirstDataRenderedEvent) => {
    eventLogger(event);
    if (_options.colConfig.autoResize) gridRef.current!.api.sizeColumnsToFit();
    event.columnApi.moveColumn("Hidden", 0);
  }, [eventLogger, _options]);

  /* Lifecycle */

  const handleCreate = useCallback(() => {
    let newData = { ..._options.getNewModel?.(), id: generateRandomUint(), _customDataProps: { isNew: true } };

    const res = gridRef.current!.api.applyTransaction({
      add: [newData as RowDataType<T>],
    });
    const newRow = res?.add.pop();
    if (!newRow) return;

    setTimeout(() => {
      if (typeof newRow.rowIndex !== 'number')
        return;
      gridRef.current!.api.ensureIndexVisible(newRow.rowIndex, "bottom");
      gridRef.current!.api.setFocusedCell(newRow.rowIndex, _options.colConfig.startEditingColId);
      gridRef.current!.api.startEditingCell({
        rowIndex: newRow.rowIndex,
        colKey: _options.colConfig.startEditingColId,
      });
    }, 50);

    setHasEmptyLines(true);
    onFocusChangeWrapper(true);
  }, [gridRef, _options, onFocusChangeWrapper]);

  const handleUpdate = useCallback(async () => {
    gridRef.current!.api.stopEditing(false);
    await new Promise(resolve => setTimeout(resolve, 100));
    if (!_options.postData || !_options.putData) {
      console.warn('gridController: "postData()" or "putData()" in options is undefined');
      return false;
    }

    const modifiedRows = getModifiedOrNewRows();
    for (const row of modifiedRows) {
      if (!row.rowIndex || await runValidator(row, true, true))
        continue;
      gridRef.current!.api.ensureIndexVisible(row.rowIndex, "bottom");
      gridRef.current!.api.setFocusedCell(row.rowIndex, _options.colConfig.startEditingColId);
      gridRef.current!.api.startEditingCell({
        rowIndex: row.rowIndex,
        colKey: _options.colConfig.startEditingColId,
      });
      return false;
    }

    const promises: { row: RowNodeType<T>; req: Promise<unknown>; res?: PromiseSettledResult<unknown>; isNew: boolean; }[] = [];
    try {
      for (const row of modifiedRows) {
        const { _customDataProps: _, id, ...cleanRowData } = row.data!;
        const isNew = !!row.data!._customDataProps?.isNew;
        const req = isNew
          ? _options.postData({ ...(cleanRowData as T) })
          : _options.putData({ ...(cleanRowData as T), id });
        promises.push({ req, row, isNew });
      }

      const allRes = await Promise.allSettled(promises.map(val => val.req));
      allRes.forEach(
        (val, idx) => { promises[idx].res = val; }
      );

      for (const promise of promises) {
        const rowToUpdate = { ...promise.row.data!, _customDataProps: {} };

        if (isPromiseRejected(promise.res!)) {
          rowToUpdate._customDataProps = { ...(promise.row.data!._customDataProps || {}), serverError: true };
          const rowIndex = typeof promise.row.rowIndex === 'number' ? promise.row.rowIndex + 1 : '?';
          showPrettyError(promise.res.reason, { prefix: `${i18next.t('err_ligne')} ${rowIndex} : `, columns: gridRef.current?.props?.columnDefs! });
        }

        gridRef.current!.api.applyTransactionAsync({
          update: [rowToUpdate]
        });
      }

      if (allRes.every(res => isPromiseFulfilled(res))) {
        if (promises.some(prom => prom.isNew))
          toast.success(t('mes_validation_creation'));
        if (promises.some(prom => !prom.isNew))
          toast.success(t('mes_validation_modification'));

        onFocusChangeWrapper(false);
        await fetchData();
        return true;
      }
    } catch (err) {
      showPrettyError(err);
      console.error(err);
    }
    return false;
  }, [gridRef, _options, t, getModifiedRows, onFocusChangeWrapper, fetchData]);

  const handleDelete = useCallback(async (rowId?: string, rowDataId?: number, showToast = true) => {
    if (!rowDataId || !rowId) return;
    if (!_options.deleteData) {
      console.warn('gridController: "deleteData()" in options is undefined');
      return;
    }

    const row = getRowNode(rowId);
    const isNew = !!row?.data?._customDataProps?.isNew;
    let resMsg = 'mes_validation_suppression';

    if (!isNew) {
      try {
        const res = await _options.deleteData(rowDataId);
        if (res && isAxiosResponse(res) && typeof res.data === 'string' && res.data)
          resMsg = res.data;
      } catch (error) {
        console.error(error);
        const prettyError = getPrettyError(error);
        const rowIndex = typeof row?.rowIndex === 'number' ? row.rowIndex + 1 : '?';
        toast.error(`${i18next.t('err_ligne')} ${rowIndex} : ${prettyError}`, toastErrorConfig);
        return;
      }
    }

    const _transaction = gridRef.current!.api.applyTransaction({
      remove: [{ id: rowDataId } as RowDataType<T>],
    });
    if (showToast) toast.success(t(resMsg));
    if (isNew) {
      setHasEmptyLines(!!getNewEmptyRows().length);
    } else {
      await fetchData();
    }
  }, [gridRef, _options, t, getRowNode, getNewEmptyRows, fetchData]);

  const handleCancel = useCallback(async () => {
    onFocusChangeWrapper(false);
    await fetchData();
  }, [gridRef, onFocusChangeWrapper, fetchData]);

  const activateContextActionButtons = useCallback(() => {
    if (_options.colConfig.floatingAction !== 'hover') {
      console.warn('gridController: "colConfig.floatingAction" is not set on "hover"');
      return;
    }
    const contextActionCol = gridRef.current!.columnApi.getColumns()?.find(
      col => col.getColDef().field === 'context_action_buttons'
    );

    if (!contextActionCol) return;

    contextActionCol.setActualWidth(0);
    if (_options.colConfig.autoResize) gridRef.current!.api.sizeColumnsToFit();
    contextActionCol.setPinned("right");
    contextActionCol.setVisible(true);
    gridRef.current!.columnApi.moveColumn("Hidden", 0);
  }, [gridRef, _options]);

  return {
    gridRef, defaultData, columnDefs, hasEmptyLines, isLoading: !!isLoading, rowClassRules, refreshSort, fetchData,
    onGridReady, onCellValueChanged, onRowValueChanged, onRowEditingStopped, onFirstDataRendered,
    handleCreate, handleUpdate, handleDelete, handleCancel, activateContextActionButtons
  };
}
