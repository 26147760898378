import { toast } from 'utils'
import i18n from 'i18next';

export function CheckInput(item: string | number | boolean | null, controller: any, title?: string) {
  if (!controller?.required) return true
  else if (controller?.required && ((typeof item === 'number') && item === null)) return true
  else if (controller?.required && ((typeof item === 'number') || (typeof item === 'boolean'))) return true
  else if (controller?.required && (typeof item === 'string') && item !== '') {
    if (controller?.length && controller.length < item.length) {
      toast.error((title ? (i18n.t(title) + ': ') : '') + item.length + '/' + controller?.length)
      return false
    }
  } else {
    toast.error(i18n.t('err_champ_obligatoire') + (title ? (':\n' + i18n.t(title)) : ''))
    return false
  }
  return true
}

export const isMissing = (item: string | number | boolean | null, control: any) =>
  control?.required && (!item || !String(item).trim());

export const getTraductionCode = (str?: string | null) => {
  if (typeof str !== "string") return '';
  let result;
  let tmp;

  if (str[0] === '#') tmp = str.split('#')[1].split('=')[0];
  else tmp = str;

  tmp = tmp.split('.')
  result = `lib_${tmp[0]}_${tmp[1]}`

  return result;
}