import { FunctionComponent, useState, useEffect } from 'react';
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import SelectComplete, { SelectTemplate } from 'components/SelectComplete';
import { fournisseursApi } from 'api';
import { CircuitLogistiqueViewModel } from 'openapi-typescript-codegen';
import { useTranslation } from 'react-i18next';
import { getColumnControl } from 'validators/ColumnControl/Controls';
import { useFournisseur } from 'context/Referencement';

const controls = [
  "circuit_logistique.code",
  "circuit_logistique.nom_circuit",
  "circuit_logistique.fk_lieu_fonction_commande",
  "circuit_logistique.fk_lieu_fonction_reglement"
].map(getColumnControl);

interface Props extends PropsBlock {
  selectedCircuit: CircuitLogistiqueViewModel;
  onUpdateCircuit: (circuit: CircuitLogistiqueViewModel) => void;
}

const IdentificationCircuit: FunctionComponent<Props> = ({ sm, md, lg, xl, doubleXl, selectedCircuit, onUpdateCircuit }) => {
  const { t } = useTranslation();
  const { state: fournisseur } = useFournisseur();
  const [completude, setCompletude] = useState<number>(0);
  const [activate, setActivate] = useState<boolean>(false);
  const [hasFocus, setFocus] = useState(false);
  const [loading, setLoader] = useState<boolean>(true);
  const [circuitLogistique, setCircuitLogistique] = useState<CircuitLogistiqueViewModel>(selectedCircuit);
  const [lieuFonctions, setLieuFonctions] = useState<SelectTemplate[]>([]);

  function checkCompletude() {
    setCompletude(controls.reduce((accumulator, current) =>
      accumulator + (current.isValueMissing(circuitLogistique!) ? 1 : 0), 0));
  }

  const refreshData = async () => {
    setCircuitLogistique({ ...selectedCircuit });
  };

  useEffect(() => {
    checkCompletude();
  }, [circuitLogistique]);

  const fetchLieux = async (id: number) => {
    const { data } = await fournisseursApi.v1FournisseursIdLieuFonctionsGet(id);
    let lieux: SelectTemplate[] = [];
    if (data && data.length > 0) {
      lieux = [
        { value: null, label: "..." },
        ...data.map(lieu => ({ value: lieu.id, label: lieu.code + " - " + lieu.nomLieuFonction }))
      ];
    }
    setLieuFonctions(lieux);
  };

  useEffect(() => {
    (circuitLogistique && lieuFonctions.length) && setLoader(false);
  }, [circuitLogistique, lieuFonctions]);

  useEffect(() => {
    refreshData();
  }, [selectedCircuit]);

  useEffect(() => {
    fournisseur?.id && fetchLieux(fournisseur.id);
  }, [fournisseur?.id]);

  function validator() {
    return controls.every((control) => control.validateInput(circuitLogistique!));
  }

  function handleUpdate() {
    validator() && onUpdateCircuit(circuitLogistique);
    setActivate(false);
  }

  function handleCancel() {
    refreshData().then(() => setActivate(false));
  }

  function handleFocus(focus: boolean) {
    setFocus(focus);
  }

  return (
    <BlockGrid
      title={t('tab_circuit_logistique_identification')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}

      completude={completude}
      loading={loading}
      handleClick={(activation: boolean) => { setActivate(activation); }}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      handleFocus={handleFocus}
    >
      <form className="flex flex-col my-2">
        <div className="grid grid-cols-2 gap-4">
          <label className="col-span-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_circuit_logistique_code")}
                {controls?.find((i) => i.column === 'circuit_logistique.code')?.required && <span className="text-red-500">**</span>}
                {controls?.find((i) => i.column === 'circuit_logistique.code')?.recommended && <span className="text-orange-500">*</span>}
              </span>
            </span>
            <input type="text" tabIndex={5}
              value={circuitLogistique?.code ?? ''}
              // onChange={e => setCircuitLogistique({ ...circuitLogistique, code: e.currentTarget.value })}
              disabled={true}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary store-disabled
                ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${(controls?.find((i) => i.column === 'circuit_logistique.code')?.required && circuitLogistique.code === '') && `${(activate || (!activate && hasFocus)) && 'border-red-500'} ${activate && 'bg-red-500/10'} ${hasFocus && 'disabled:border-red-500'}`}
                ${(controls?.find((i) => i.column === 'circuit_logistique.code')?.recommended && circuitLogistique.code === '') && 'border-orange-500 bg-orange-500/10'}
              `} />
          </label>
          <label className="col-span-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_circuit_logistique_nom_circuit")}
                {controls?.find((i) => i.column === 'circuit_logistique.nom_circuit')?.required && <span className="text-red-500">**</span>}
                {controls?.find((i) => i.column === 'circuit_logistique.nom_circuit')?.recommended && <span className="text-orange-500">*</span>}
              </span>
            </span>
            <input type="text" tabIndex={5}
              value={circuitLogistique?.nomCircuit ?? ''}
              onChange={$event => setCircuitLogistique({ ...circuitLogistique, nomCircuit: $event.currentTarget.value })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${(controls?.find((i) => i.column === 'circuit_logistique.nom_circuit')?.required && circuitLogistique.nomCircuit === '') && `${(activate || (!activate && hasFocus)) && 'border-red-500'} ${activate && 'bg-red-500/10'} ${hasFocus && 'disabled:border-red-500'}`}
                ${(controls?.find((i) => i.column === 'circuit_logistique.nom_circuit')?.recommended && circuitLogistique.nomCircuit === '') && 'border-orange-500 bg-orange-500/10'}
              `} />
          </label>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <label className="col-span-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_circuit_logistique_fk_lieu_fonction_commande")}
                {controls?.find((i) => i.column === 'circuit_logistique.fk_lieu_fonction_commande')?.required && <span className="text-red-500">**</span>}
                {controls?.find((i) => i.column === 'circuit_logistique.fk_lieu_fonction_commande')?.recommended && <span className="text-orange-500">*</span>}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={lieuFonctions}
              value={lieuFonctions.filter(option => {
                return option.value === circuitLogistique.fkLieuFonctionCommande;
              })}
              onChange={($event: any): void => setCircuitLogistique({ ...circuitLogistique, fkLieuFonctionCommande: $event.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${(controls?.find((i) => i.column === 'circuit_logistique.fk_lieu_fonction_commande')?.required && circuitLogistique.fkLieuFonctionCommande === null) && `${(activate || (!activate && hasFocus)) && 'border-red-500'} ${activate && 'bg-red-500/10'} ${hasFocus && 'disabled:border-red-500'}`}
                ${(controls?.find((i) => i.column === 'circuit_logistique.fk_lieu_fonction_commande')?.recommended && circuitLogistique.fkLieuFonctionCommande === null) && 'border-orange-500 bg-orange-500/10'}
              `}
            />
          </label>
          <label className="col-span-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_circuit_logistique_fk_lieu_fonction_reglement")}
                {controls?.find((i) => i.column === 'circuit_logistique.fk_lieu_fonction_reglement')?.required && <span className="text-red-500">**</span>}
                {controls?.find((i) => i.column === 'circuit_logistique.fk_lieu_fonction_reglement')?.recommended && <span className="text-orange-500">*</span>}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={lieuFonctions}
              value={lieuFonctions.filter(option => {
                return option.value === circuitLogistique.fkLieuFonctionReglement;
              })}
              onChange={($event: any): void => setCircuitLogistique({ ...circuitLogistique, fkLieuFonctionReglement: $event.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${(controls?.find((i) => i.column === 'circuit_logistique.fk_lieu_fonction_reglement')?.required && circuitLogistique.fkLieuFonctionReglement === null) && `${(activate || (!activate && hasFocus)) && 'border-red-500'} ${activate && 'bg-red-500/10'} ${hasFocus && 'disabled:border-red-500'}`}
                ${(controls?.find((i) => i.column === 'circuit_logistique.fk_lieu_fonction_reglement')?.recommended && circuitLogistique.fkLieuFonctionReglement === null) && 'border-orange-500 bg-orange-500/10'}
              `}
            />
          </label>
        </div>
      </form>
    </BlockGrid>
  );
};

export default IdentificationCircuit;