import { FunctionComponent, useEffect, useState } from 'react';
import { api, removeSpaces, showPrettyError, toast } from 'utils';
import { useTranslation } from "react-i18next";
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import SelectComplete, { SelectTemplate } from 'components/SelectComplete';
import {
  lieuFonctionApi,
  conditionReglementApi,
  modeReglementApi,
  typePiecesApi,
  devisesApi,
} from 'api';
import { normalizeDecimal } from 'utils/normalize';
import { LieuFonctionViewModel } from 'openapi-typescript-codegen';
import { getColumnControl } from "validators/ColumnControl/Controls";
import { ColumnControl } from "../../../validators";

const controls = [
  "lieu_fonction.type_litige",
  "lieu_fonction.fk_condition_reglement",
  "lieu_fonction.fk_mode_reglement",
  "lieu_fonction.agence_bancaire",
  "lieu_fonction.iban",
  "lieu_fonction.bic",
  "lieu_fonction.fk_type_piece",
  "lieu_fonction.fl_assujetti_tva",
  "lieu_fonction.fk_devise",
  "lieu_fonction.type_escompte",
  "lieu_fonction.taux_ristourne",
  "lieu_fonction.num_accise",
].map(getColumnControl);

interface Props extends PropsBlock {
  selectedLieu: LieuFonctionViewModel;
}

const Comptabilite: FunctionComponent<Props> = ({ sm, md, lg, xl, doubleXl, selectedLieu }) => {
  const { t } = useTranslation();
  const [completude, setCompletude] = useState<number>(0);
  const [activate, setActivate] = useState<boolean>(false);
  const [hasFocus, setFocus] = useState(false);
  const [loading, setLoader] = useState<boolean>(true);
  const [lieuFonction, setLieuFonction] = useState<Partial<LieuFonctionViewModel>>(selectedLieu);

  const litiges = [
    { value: null, label: "---" },
    { value: 'D', label: t("enu_lieu_fonction_type_litige_note_debit") },
    { value: 'A', label: t("enu_lieu_fonction_type_litige_demande_avoir") }
  ];

  // Static options for the selects:
  const [options, setOptions] = useState<{ [key: string]: SelectTemplate[]; }>({
    conditionReglement: [], modeReglement: [], typePiece: [], devise: []
  });

  const escomptes = [
    { value: null, label: t("enu_lieu_fonction_type_escompte_sans") },
    { value: '1', label: t("enu_lieu_fonction_type_escompte_dans_facture") },
    { value: '2', label: t("enu_lieu_fonction_type_escompte_hors_facture") },
  ];

  // The static options are all fetched at the first render:
  useEffect(() => {
    api.options.retrieve([
      {
        key: "conditionReglement",
        promise: conditionReglementApi.v1ConditionReglementsGet(1, 1000),
        configuration: { label: "#{code} - #{nomConditionReglement}" },
        nullable: true
      },
      {
        key: "modeReglement",
        promise: modeReglementApi.v1ModeReglementsGet(1, 1000),
        configuration: { label: "#{code} - #{nomModeReglement}" },
        nullable: true
      },
      {
        key: "typePiece",
        promise: typePiecesApi.v1TypePiecesGet(1, 1000),
        configuration: { label: "#{code} - #{nomTypePiece}" },
        nullable: true
      },
      {
        key: "devise",
        promise: devisesApi.v1DevisesGet(1, 1000),
        configuration: { label: "#{code} - #{nomDevise}" },
        nullable: true
      }
    ]).then(setOptions);
  }, []);

  useEffect(() => { checkCompletude(); }, [lieuFonction]);

  useEffect(() => { refreshLieuFonction(); }, [selectedLieu]);

  function checkCompletude() { setCompletude(ColumnControl.checkAll(lieuFonction, controls)); }

  const refreshLieuFonction = async () => {
    setLieuFonction(selectedLieu);
    setLoader(false);
  }

  const updateLieuFonction = async () => {
    try {
      await lieuFonctionApi.v1LieuFonctionsPut({ ...lieuFonction, bic: removeSpaces(lieuFonction.bic || "") } as LieuFonctionViewModel);
      toast.success(t('mes_validation_modification'));
    } catch (error) {
      console.error(error);
      showPrettyError(error, { model: "lieu_fonction" });
    }
  };

  function validate() {
    let isValid = true;
    if (lieuFonction?.typeEscompte && !lieuFonction?.tauxRistourne) {
      isValid = false;
      toast.error(t('err_champ_obligatoire') + t('lib_lieu_fonction_taux_ristourne'));
    }
    if (IbanError) {
      isValid = false;
      toast.error(t('err_iban_invalide'));
    }
    return isValid && ColumnControl.validateAll(lieuFonction, controls);
  }

  useEffect(() => {
    if (typeof lieuFonction?.tauxRistourne === 'number') {
      if (lieuFonction?.tauxRistourne > 100) setLieuFonction({ ...lieuFonction, tauxRistourne: 100 });
      else if (lieuFonction?.tauxRistourne < 0) setLieuFonction({ ...lieuFonction, tauxRistourne: 0 });
    }
  }, [lieuFonction?.tauxRistourne]);

  function handleUpdate() {
    validate() && updateLieuFonction();
    setActivate(false);
  }

  function handleCancel() { refreshLieuFonction().then(() => setActivate(false));  }

  function handleFocus(focus: boolean) { setFocus(focus); }

  const IbanError = lieuFonction?.iban && !/^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/g.test(lieuFonction.iban);

  return (
    <BlockGrid
      title={t("tit_modification_comptabilite_lieu_fonction")}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      anchor="comptabilite-lieu-fonction"
      completude={completude}
      loading={loading}
      handleClick={(activation: boolean) => { setActivate(activation); }}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      handleFocus={handleFocus}
    >
      <form className="flex justify-between flex-wrap">
        <div className="flex mb-1 w-full justify-between gap-2">
          <label className="block mb-2 flex-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_lieu_fonction_type_litige")}
                {getColumnControl("lieu_fonction.type_litige").getValidationLabelElement()}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={litiges}
              value={litiges.filter(option => option.value === lieuFonction?.typeLitige)}
              onChange={(option: any): void => setLieuFonction({ ...lieuFonction, typeLitige: option.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("lieu_fonction.type_litige").getValidationInputClass(activate, hasFocus, lieuFonction)}
              `}
            />
          </label>
          <label className="block mb-2 flex-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_lieu_fonction_fk_mode_reglement")}
                {getColumnControl("lieu_fonction.fk_mode_reglement").getValidationLabelElement()}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={options.modeReglement}
              value={options.modeReglement.filter(option => option.value === lieuFonction?.fkModeReglement)}
              onChange={(option: any): void => setLieuFonction({ ...lieuFonction, fkModeReglement: option.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("lieu_fonction.fk_mode_reglement").getValidationInputClass(activate, hasFocus, lieuFonction)}
              `}
            />
          </label>
          <label className="block mb-2 flex-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_lieu_fonction_fk_condition_reglement")}
                {getColumnControl("lieu_fonction.fk_condition_reglement").getValidationLabelElement()}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={options.conditionReglement}
              value={options.conditionReglement.filter(option => option.value === lieuFonction?.fkConditionReglement)}
              onChange={(option: any): void => setLieuFonction({ ...lieuFonction, fkConditionReglement: option.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("lieu_fonction.fk_condition_reglement").getValidationInputClass(activate, hasFocus, lieuFonction)}
              `}
            />
          </label>
        </div>
        <div className="flex mb-1 w-full justify-between gap-2">
          <label className="block mb-2 flex-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_lieu_fonction_agence_bancaire")}
                {getColumnControl("lieu_fonction.agence_bancaire").getValidationLabelElement()}
              </span>
            </span>
            <input type="text" tabIndex={5}
              min={0} step={0.01}
              value={lieuFonction?.agenceBancaire ?? String()}
              onChange={($event: any): void => setLieuFonction({ ...lieuFonction, agenceBancaire: $event.target.value })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("lieu_fonction.agence_bancaire").getValidationInputClass(activate, hasFocus, lieuFonction)}
              `} />
          </label>
          <label className="block mb-2 flex-2">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_lieu_fonction_bic")}
                {getColumnControl("lieu_fonction.bic").getValidationLabelElement()}
              </span>
            </span>
            <input type="text" tabIndex={5}
              min={0} step={0.01}
              value={removeSpaces(lieuFonction.bic || "")}
              onChange={($event: any): void => setLieuFonction({ ...lieuFonction, bic: removeSpaces($event.target.value) })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("lieu_fonction.bic").getValidationInputClass(activate, hasFocus, lieuFonction)}
              `} />
          </label>
        </div>
        <div className="flex mb-1 w-full justify-between gap-2">
          <label className="block mb-2 flex-1 relative">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_lieu_fonction_iban")}
                {getColumnControl("lieu_fonction.iban").getValidationLabelElement()}
              </span>
              <span className='text-red-500 text-xs'>{!!IbanError && t('err_iban_invalide')}</span>
            </span>
            <input type="text" tabIndex={5}
              min={0} step={0.01}
              value={lieuFonction?.iban ?? String()}
              onChange={($event: any): void => setLieuFonction({ ...lieuFonction, iban: $event.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("lieu_fonction.iban").getValidationInputClass(activate, hasFocus, lieuFonction)}
              `} />
          </label>
        </div>
        <div className="flex mb-1 w-full justify-between gap-2">
          <label className="block mb-2 flex-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_lieu_fonction_fk_type_piece")}
                {getColumnControl("lieu_fonction.fk_type_piece").getValidationLabelElement()}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={options.typePiece}
              value={options.typePiece.filter(option => option.value === lieuFonction?.fkTypePiece)}
              onChange={($event: any): void => setLieuFonction({ ...lieuFonction, fkTypePiece: $event.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("lieu_fonction.fk_type_piece").getValidationInputClass(activate, hasFocus, lieuFonction)}
              `}
            />
          </label>
          <label className="block mb-2 flex-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_lieu_fonction_fl_assujetti_tva")}
                {getColumnControl("lieu_fonction.fl_assujetti_tva").getValidationLabelElement()}
              </span>
            </span>
            <input type="checkbox" tabIndex={5}
              checked={lieuFonction?.flAssujettiTva}
              onChange={$event => setLieuFonction({ ...lieuFonction, flAssujettiTva: $event.currentTarget.checked })}
              disabled={!activate}
              className={`h-4 w-4 mt-1 block bg-white border border-slate-300 rounded-md
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}`} />
          </label>
          <label className="block mb-2 flex-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_lieu_fonction_fk_devise")}
                {getColumnControl("lieu_fonction.fk_devise").getValidationLabelElement()}
              </span>
            </span>
            <SelectComplete
              maxMenuHeight={250}
              tabIndex={5}
              options={options.devise}
              menuPlacement="auto"
              value={options.devise.filter(option => option.value === lieuFonction?.fkDevise)}
              onChange={($event: any): void => setLieuFonction({ ...lieuFonction, fkDevise: $event.value })}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                 ${getColumnControl("lieu_fonction.fk_devise").getValidationInputClass(activate, hasFocus, lieuFonction)}
              `}
            />
          </label>
        </div>
        <div className="flex mb-1 w-full justify-between gap-2">
          <label className="block mb-2 flex-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_lieu_fonction_type_escompte")}
                {getColumnControl("lieu_fonction.type_escompte").getValidationLabelElement()}
              </span>
            </span>
            <SelectComplete tabIndex={5}
              options={escomptes}
              value={escomptes.filter(option => option.value === lieuFonction?.typeEscompte)}
              onChange={(option: any): void => {
                if (!option.value) {
                  const $lieuFonction = { ...lieuFonction, tauxEscompte: 0 };
                  delete $lieuFonction.typeEscompte;
                  setLieuFonction($lieuFonction);
                }
                else setLieuFonction({ ...lieuFonction, typeEscompte: option.value });
              }}
              classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                 ${getColumnControl("lieu_fonction.type_escompte").getValidationInputClass(activate, hasFocus, lieuFonction)}
              `}
            />
          </label>
          <label className="block mb-2 flex-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_lieu_fonction_taux_ristourne")}
                {getColumnControl("lieu_fonction.taux_ristourne").getValidationLabelElement()}
              </span>
            </span>
            <input type="number" tabIndex={5}
              min={0} max={100} step={0.01} pattern="^\d*(\.\d{0,2})?$"
              value={lieuFonction?.tauxRistourne ?? String()}
              onChange={($event: any): void => {
                if (parseFloat($event.target.value) > 100) { setLieuFonction({ ...lieuFonction, tauxRistourne: 100 }); }
                else { setLieuFonction({ ...lieuFonction, tauxRistourne: parseFloat(normalizeDecimal($event.target.value, 2)) }); }
              }}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                 ${getColumnControl("lieu_fonction.taux_ristourne").getValidationInputClass(activate, hasFocus, lieuFonction)}
              `} />
          </label>
          <label className="block mb-2 flex-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t("lib_lieu_fonction_num_accise")}
                {getColumnControl("lieu_fonction.num_accise").getValidationLabelElement()}
              </span>
            </span>
            <input type="text" tabIndex={5}
              min={0} step={0.01}
              value={lieuFonction?.numAccise ?? String()}
              onChange={($event: any): void => setLieuFonction({ ...lieuFonction, numAccise: $event.target.value })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("lieu_fonction.num_accise").getValidationInputClass(activate, hasFocus, lieuFonction)}
              `} />
          </label>
        </div>
      </form>
    </BlockGrid>
  );
};

export default Comptabilite;