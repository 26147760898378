import BreadCrumb from "components/Breadcrumb";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

const Commande: FunctionComponent = () => {
  let { code } = useParams();

  const name = "Commande";

  const breadcrumb = [
    {
      name: "Applications",
      link: `/app`,
    },
    {
      name: "Appro, stock, précompt",
      link: `/app/approvisionnement`,
    },
    {
      name: "Liste des commandes",
      link: `/app/approvisionnement/commande`,
    },
    {
      name: `${name} ${code}`,
    },
  ];
  return (
    <>
      <BreadCrumb content={breadcrumb} />
      <div>Commande</div>
    </>
  );
};

export default Commande;
