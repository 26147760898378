import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  FamilleInsertionModel,
  FamilleViewModel,
  TvaWithTauxViewModel
} from "openapi-typescript-codegen";
import {famillesApi, tvasApi} from "api";
import BlockGrid, { LayoutEnum, PropsBlock } from 'components/BlockGrid';
import {DateInput, NumberInput, SelectInput, TextInput} from "components/InputWrapper";
import NomenclatureContext from "../NomenclatureContext";
import { SelectTemplate } from "components/SelectComplete";
import {api, showPrettyError, toast} from "utils";

interface Props extends PropsBlock {
  setIsOpen?: (v: boolean) => void;
  isOpen?: boolean;
  data?: FamilleViewModel;
}

const RayonModal: FunctionComponent<Props> = ({ setIsOpen, isOpen, data }) => {
  const { t } = useTranslation();
  const { nomenclature, setNomenclature, setRefresh } = useContext(NomenclatureContext);
  const [famille, setFamille] = useState<Partial<FamilleViewModel>>({});
  const [tvas, setTvas] = useState<SelectTemplate[]>([]);

  const getTvas = async () => {
    const data = await api.dataset.get<TvaWithTauxViewModel[]>(tvasApi.v1TvasTauxGet());
    return api.options.generate(data, { label: "#{code} - #{nomTva} (#{taux}%)" }, false);
  }

  useEffect(() => {
    if (isOpen) {
      data ? setFamille(data) : setFamille({ fkRayon: nomenclature.rayon as number });
      !tvas.length && getTvas().then(setTvas);
    }
  }, [isOpen]);

  function isUpdate() { return famille.hasOwnProperty("id"); }

  async function handleUpdate() {
    if (isUpdate()) {
      try {
        await famillesApi.v1FamillesPut(famille as FamilleViewModel);
        toast.success(t("mes_validation_modification"));
        setIsOpen && setIsOpen(false);
      } catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    } else { await handleCreate(); }
  }

  async function handleCreate() {
    try {
      const { data: $famille } = await famillesApi.v1FamillesPost(famille as FamilleInsertionModel);
      setNomenclature({ ...nomenclature, famille: $famille.id, sousFamille: null, uniteBesoin: null });
      toast.success(t("mes_validation_creation"));
      setIsOpen && setIsOpen(false);
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  }

  return (
    <BlockGrid
      layout={LayoutEnum.modal}
      title={isUpdate() ? t('tit_modification_famille') : t('tit_creation_famille')}
      informations={''}
      toActivate={true}
      handleUpdate={async () => { await handleUpdate().then(() => { setRefresh(true); }); }}
      handleCancel={() => { setIsOpen && setIsOpen(false); }}
    >
      <form onSubmit={$event => $event.preventDefault()} className="flex flex-col">
        <div className="grid grid-cols-6 gap-4">
          <NumberInput labelClassAdd="col-span-1" label={t("lib_famille_code")}
                     value={famille} field="code" setValue={setFamille} required={true} min={1} />
          <TextInput labelClassAdd="col-span-5" label={t("lib_famille_nom_famille")}
                     value={famille} field="nomFamille" setValue={setFamille} required={true} />
          <SelectInput className="col-span-4" label={t("lib_famille_fk_tva_principale_famille")}
                       value={famille} field="fkTvaPrincipaleFamille" setValue={setFamille}
                       getPlaceholder={async () => tvas.length ?  tvas[0].label! : "---"}
                       required={true}
                       searchData={async () => tvas}
          />
          <NumberInput labelClassAdd="col-span-2" label={t("lib_famille_delai_agrement")}
                       value={famille} field="delaiAgrement" setValue={setFamille}
                       min={0} max={999} />
          { isUpdate() &&
            <>
                <div className={"col-span-4"}></div>
                <DateInput labelClassAdd="col-span-2" label={t("lib_famille_date_maj")}
                           value={famille} field="dateMaj"  disabled={true} />
            </>
          }
        </div>
      </form>
    </BlockGrid>
  );
};

export default RayonModal;