import { FunctionComponent, useState, useEffect, ChangeEvent, FC } from 'react';

// Example: Button Component 
interface ButtonProps {
  myFunction: (data: boolean) => void;
}

const ButtonExample: FunctionComponent<ButtonProps> = ({ myFunction }) => {
  return (
    <button
      className="bg-indigo-500 text-white p-2 rounded"
      onClick={() => myFunction(true)}>
      Press!
    </button>
  );
};

// Example: Input Component 
interface InputProps {
  text: string,
  changeText: (data: string) => void;
}

const InputExample: FunctionComponent<InputProps> = ({ text, changeText }) => {
  const [data, setData] = useState<string>("");

  useEffect(() => {
    changeText(data);
  }, [data]);

  // const callBackText = useCallback((data) => {
  //   changeText(data);
  // }, [changeText])

  // useEffect(() => {
  //   callBackText(data)
  // }, [callBackText, data])

  return (
    <input
      className="border-2 p-2 rounded"
      onChange={(e: ChangeEvent<HTMLInputElement>): void => setData(e.currentTarget.value)} value={text} />
  );
};


// Example Function
const Example: FC = () => {
  const [example, setExample] = useState<string>("");

  function changeNameButton(): void {
    setExample("Button pressed !");
  }

  function changeNameInput(text: string): void {
    setExample(text);
  }

  useEffect(() => {
    setExample("Example here");
  }, []);

  return (
    <div className="flex w-full h-screen justify-center items-center flex-col gap-4">
      <h1 className="font-bold text-2xl">{example}</h1>
      <ButtonExample myFunction={() => changeNameButton()} />
      <InputExample changeText={text => changeNameInput(text)} text={example} />
    </div>
  );
};

export default Example;