import {FunctionComponent, useEffect, useMemo} from 'react';
import { useTranslation } from "react-i18next";
import { CellValueChangedEvent, FirstDataRenderedEvent, RowDataUpdatedEvent } from "ag-grid-community";
import { enseignesApi, siteEnseignesApi } from 'api';
import { useGridController } from "hooks";
import { EnseigneViewModel, SiteEnseigneWithEnseigneViewModel } from "openapi-typescript-codegen";
import { ImageCellRenderer, ImageCellRendererProps, TextCellRenderer, UniqueRadioCellRenderer} from 'components/AGGride/CellRender';
import { PropsBlock } from 'components/BlockGrid';
import BlockGridNomenclature from "../BlockGridNomenclature";
import EnseigneModal from "../modals/EnseigneModal";


interface Props extends PropsBlock {
    siteEnseignes: SiteEnseigneWithEnseigneViewModel[];
    onEnseigneChange: (id: number) => void;
    updateSiteEnseignes: () => void;
}

type GridType = EnseigneViewModel & { departements: number, flPrincipal: boolean };

const Enseigne: FunctionComponent<Props> = ({ siteEnseignes, onEnseigneChange, updateSiteEnseignes }) => {
    const { t } = useTranslation();

    const gridController = useGridController<GridType>(
        useMemo(() => ({
            colConfig: {},
            autoFetch: true,
            fetchData: async () => siteEnseignes.map((siteEnseigne) => ({
                ...siteEnseigne.enseigne,
                departements: siteEnseigne.departements,
                flPrincipal: siteEnseigne.flPrincipal,
            })),
            deleteData: (id) => enseignesApi.v1EnseignesIdDelete(id).then(() => {
                updateSiteEnseignes();
            }),
            rowClassRules: {
                "hide-actions": params => !!params.data?.flPrincipal || !!params.data?.departements,
            },
            columnDefs: [
                {
                    field: 'code',
                    headerName: t('lib_enseigne_code'),
                    headerTooltip: t('lib_enseigne_code'),
                    cellRenderer: TextCellRenderer,
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 1
                },
                {
                    field: 'nomEnseigne',
                    headerName: t('lib_enseigne_nom_enseigne'),
                    headerTooltip: t('lib_enseigne_nom_enseigne'),
                    cellRenderer: TextCellRenderer,
                    editable: false,
                    valueFormatter: (params: any) => `${params.data?.nomEnseigne} <${params.data?.departements}>` || String(),
                    filter: 'agTextColumnFilter',
                    flex: 4
                },
                {
                    headerName: t("lib_principal"),
                    headerTooltip: t('lib_principal'),
                    field: "flPrincipal",
                    floatingFilter: true,
                    cellRenderer: UniqueRadioCellRenderer,
                    flex: 1,
                },
                {
                    field: "enseigne.logoUrl",
                    headerName: t('lib_enseigne_logo_url'),
                    headerTooltip: t('lib_enseigne_logo_url'),
                    floatingFilter: false,
                    cellRenderer: ImageCellRenderer,
                    cellRendererParams: {
                        href: (value) => value,
                        defaultHref: "/assets/images/default.png",
                    } as ImageCellRendererProps,
                    flex: 1
                }
            ],
        }), [siteEnseignes, t])
    );

    useEffect(() => { gridController.fetchData(); }, [siteEnseignes]);

    async function  updatePrincipal(event: CellValueChangedEvent) {
      const siteEnseigne = siteEnseignes.find((siteEnseigne) => siteEnseigne.enseigne.id === event.data.id);
      if (siteEnseigne) {
          siteEnseigne.flPrincipal = event.data.flPrincipal;
          await siteEnseignesApi.v1SiteEnseignesPut(siteEnseigne);
      }
    }

    function selectRow(event: FirstDataRenderedEvent | RowDataUpdatedEvent) {
        const principal = siteEnseignes.find((siteEnseigne) => siteEnseigne.flPrincipal)?.enseigne.id;
        let hasPrincipal = false;
        event.api.forEachLeafNode((node: any) => {
            if (!hasPrincipal && node.data.id === principal) {
                hasPrincipal = true;
                node.setSelected(true);
            }
        });
        if (!hasPrincipal) {
            const node = event.api.getDisplayedRowAtIndex(event.api.getFirstDisplayedRow());
            node?.setSelected(true);
        }
    }

    return <BlockGridNomenclature
      label={"tab_detail_enseigne"}
      gridController={gridController}
      onSelect={onEnseigneChange}
      onDataChange={selectRow}
      gridName={"enseigne"}
      onCellValueChanged={updatePrincipal}
    ><EnseigneModal /></BlockGridNomenclature>;
};

export default Enseigne;