import React, { FunctionComponent, useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { DateTime } from "luxon";

import {
  CadencierFournisseurDetailInsertionModel,
  CadencierFournisseurDetailViewModel,
  EnumCadencierDetailTypeSemaine,
} from "openapi-typescript-codegen";

import { DateInput, TextInput } from "components/InputWrapper";
import { PropsBlock } from 'components/BlockGrid';
import SelectComplete, { SelectTemplate } from "components/SelectComplete";
import ConfirmModal, { ConfirmEnum, ConfirmModalRef } from "components/Modal/ConfirmModal";

import { Delete } from "@mui/icons-material";
import {
  getISOStartDate,
  getISOEndDate,
  getEndDate,
  checkDate
} from 'utils';

interface Props extends PropsBlock {
  detail: CadencierFournisseurDetailViewModel | Partial<CadencierFournisseurDetailViewModel>;
  onUpdateDetail?: (detail: CadencierFournisseurDetailViewModel) => void;
  onCreateDetail?: (detail: CadencierFournisseurDetailInsertionModel) => void;
  onDeleteDetail?: (id: number) => void;
  onFocus?: (detail: CadencierFournisseurDetailViewModel) => void;
  hasFocus?: boolean;
  activate: boolean;
  isNew?: boolean;
}

const LigneCommande: FunctionComponent<Props> = ({ detail, onUpdateDetail, onCreateDetail, onDeleteDetail, onFocus, hasFocus, activate, isNew = false }) => {
  // Translation utility:
  const { t } = useTranslation();

  // Deletion validation modal:
  const modal = useRef<ConfirmModalRef | null>(null);

  // Main state:
  const [cadencierFournisseurDetail, setCadencierFournisseurDetail] = useState<Partial<CadencierFournisseurDetailViewModel>>({});

  // Minimum end date for the commande:
  const [minimumEndDate, setMinimumEndDate] = useState<DateTime>();

  // Options for the select:
  const typeOptions = useRef<SelectTemplate[]>([
    { value: EnumCadencierDetailTypeSemaine.PasDeCde, label: t('enu_cadencier_fournisseur_detail_type_semaine_pas_de_cde') },
    { value: EnumCadencierDetailTypeSemaine.Toutes, label: t('enu_cadencier_fournisseur_detail_type_semaine_toutes') },
    { value: EnumCadencierDetailTypeSemaine.Paires, label: t('enu_cadencier_fournisseur_detail_type_semaine_pair') },
    { value: EnumCadencierDetailTypeSemaine.Impaires, label: t('enu_cadencier_fournisseur_detail_type_semaine_impair') },
    { value: EnumCadencierDetailTypeSemaine.PremierMois, label: t('enu_cadencier_fournisseur_detail_type_semaine_premier_mois') },
    { value: EnumCadencierDetailTypeSemaine.DeuxiemeMois, label: t('enu_cadencier_fournisseur_detail_type_semaine_deuxième_mois') },
    { value: EnumCadencierDetailTypeSemaine.TroisiemeMois, label: t('enu_cadencier_fournisseur_detail_type_semaine_troisième_mois') },
    { value: EnumCadencierDetailTypeSemaine.QuatriemeMois, label: t('enu_cadencier_fournisseur_detail_type_semaine_quatrième_mois') },
    { value: EnumCadencierDetailTypeSemaine.UneSurTrois, label: t('enu_cadencier_fournisseur_detail_type_semaine_semaine_une_sur_trois') },
  ]);


  // Only one line can be focused at once (and get a different styling). It is managed by the parent component:
  const handleFocus = () => {
    onFocus && onFocus(cadencierFournisseurDetail as CadencierFournisseurDetailViewModel);
  };

  // When the component is not new, it can be deleted:
  const handleDelete = async () => {
    const validate = await modal.current!.showModal();
    (validate && onDeleteDetail) && onDeleteDetail(cadencierFournisseurDetail.id! as number);
  };

  useEffect(() => {
    // We update the line, on change, if there is an id, or create it if there is not:
    if (cadencierFournisseurDetail.id) {
      // We check if the line has been modified:
      const isModified = Object.entries(detail).sort().toString() !== Object.entries(cadencierFournisseurDetail).sort().toString();
      (isModified && onUpdateDetail) && onUpdateDetail(cadencierFournisseurDetail as CadencierFournisseurDetailViewModel);
    } else { onCreateDetail && onCreateDetail(cadencierFournisseurDetail as CadencierFournisseurDetailInsertionModel); }
  }, [cadencierFournisseurDetail]);

  useEffect(() => {
    // The default value is set for typeSemaine:
    setCadencierFournisseurDetail({
      ...detail,
      dtdebutCadencier: getISOStartDate(detail.dtdebutCadencier || String()),
      dtfinCadencier: getISOEndDate(detail.dtfinCadencier || String()),
      typeSemaine: detail.typeSemaine || EnumCadencierDetailTypeSemaine.Toutes
    });
  }, [detail]);

  // Helper function to get the minimum end date:
  const getMinimumEndDate = () => {
    // We set the minimum end date one day before the start date or today at the end of the day (if the start date is in the past).
    const isPast = cadencierFournisseurDetail.dtdebutCadencier && checkDate.isPast(cadencierFournisseurDetail.dtdebutCadencier);
    const minimumEndDate = cadencierFournisseurDetail.dtdebutCadencier && !isPast ?
      getEndDate(cadencierFournisseurDetail.dtdebutCadencier).plus({ days: 1 }) :
      getEndDate();
    // If the minimum end date is before the minimum date, we set it back to it:
    if (isNew && cadencierFournisseurDetail.dtfinCadencier && getEndDate(cadencierFournisseurDetail.dtfinCadencier) < minimumEndDate) {
      setCadencierFournisseurDetail({ ...cadencierFournisseurDetail, dtfinCadencier: minimumEndDate.toISODate() });
    }
    return minimumEndDate;
  };

  // Helper function to know if the starting date is in the future:
  const isFuture = () => cadencierFournisseurDetail.dtdebutCadencier && checkDate.isFuture(cadencierFournisseurDetail.dtdebutCadencier);

  // We set the minimum end date when the start date changes:
  useEffect(() => {
    setMinimumEndDate(getMinimumEndDate());
  }, [cadencierFournisseurDetail.dtdebutCadencier]);

  // @ts-ignore
  return (
    <div className={`grid grid-cols-24 gap-4 ${hasFocus && "p-1 my-2 rounded bg-store-primary-light"}`} onFocus={handleFocus} >
      <DateInput inputClassAdd="col-span-3 self-start my-1 py-1"
        value={cadencierFournisseurDetail} setValue={setCadencierFournisseurDetail}
        field="dtdebutCadencier"
        disabled={!(activate && (isFuture() || isNew))}
        required={true}
        min={DateTime.now()}
      />
      <DateInput inputClassAdd="col-span-3 self-start my-1 py-1"
        value={cadencierFournisseurDetail} setValue={setCadencierFournisseurDetail}
        field="dtfinCadencier"
        disabled={!activate}
        required={true}
        min={minimumEndDate || getMinimumEndDate()}
      />
      <TextInput inputClassAdd="col-span-5 self-start my-1 py-1"
        value={cadencierFournisseurDetail} setValue={setCadencierFournisseurDetail}
        field="nomCadencierDetail"
        disabled={!activate}
        required={true}
      />
      <label className="col-span-6">
        <SelectComplete
          tabIndex={5}
          options={typeOptions.current}
          value={typeOptions.current.filter(option => option.value === cadencierFournisseurDetail.typeSemaine)}
          onChange={(option: any) => { setCadencierFournisseurDetail({ ...cadencierFournisseurDetail, typeSemaine: option.value }); }}
          isDisabled={!activate}
          classNameAdd={"my-1 py-1"}
        />
      </label>
      <TextInput inputClassAdd="col-span-3 self-start my-1 py-1"
        value={cadencierFournisseurDetail} setValue={setCadencierFournisseurDetail}
        field="decalage"
        disabled={!activate}
        required={false}
      />
      <TextInput inputClassAdd="col-span-3 self-start my-1 py-1"
        value={cadencierFournisseurDetail} setValue={setCadencierFournisseurDetail}
        field="nbJCouvStock"
        disabled={!activate}
        required={false}
      />
      {(!isNew && isFuture()) &&
        <label className="col-span-1">
          <button type="button" tabIndex={5}
            disabled={!activate}
            className="w-8 h-8 mt-1 border rounded border-slate-300 text-grey-200 bg-white-500 opacity-100
                        hover:text-white-500 hover:bg-red-500 focus:text-white-500 focus:bg-red-500"
            onClick={handleDelete}>
            <Delete />
            <ConfirmModal ref={modal} layout={ConfirmEnum.delete} />
          </button>
        </label>
      }
    </div>
  );
};

export default LigneCommande;