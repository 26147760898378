import { FC, ReactNode, createContext, useContext, useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { generateRandomUint } from 'utils';

export enum UpdateEnum {
  'fournisseur',
  'lieuFonction',
  'circuitLogistique',
  'conditionAchat',
  'contacts',
  'patch',
}

type callbackEvent = (evt: UpdateEnum, id?: number) => void;
type callbackPool = { [key: string]: callbackEvent | undefined; };

const UpdateFournisseurContext = createContext<{ triggerEvent: callbackEvent, setListener: Dispatch<SetStateAction<callbackPool>>; } | undefined>(undefined);

const UpdateFournisseurProvider: FC<{ children?: ReactNode; }> = ({ children }) => {
  // const { state: fournisseur } = useFournisseur();
  // const { state: enseigne } = useEnseigne();
  const [listens, setListener] = useState<callbackPool>({});

  // const fetchPatchCompletude = async (id: number) => {
  //   if (enseigne?.id) await fournisseursApi.v1ArticlesIdCompletudePatch(id, {enseigneId: enseigne.id})
  // }

  const triggerEvent: callbackEvent = async (event, id) => {
    // try {
    //   if (fournisseur?.id) await fetchPatchCompletude(fournisseur.id)
    // } catch (err) { console.error(err) }
    for (const key in listens) {
      const callback = listens[key];
      if (!callback) continue;
      callback(event, id);
    }
  };
  const value = { triggerEvent, setListener };

  return <UpdateFournisseurContext.Provider value={value}>{children}</UpdateFournisseurContext.Provider>;
};

function useUpdateFournisseur(onEvent?: callbackEvent) {
  const context = useContext(UpdateFournisseurContext);
  if (context === undefined) {
    throw new Error('useFournisseur must be used within a UpdateFournisseurProvider');
  }
  const listenerIndex = useRef(generateRandomUint());

  useEffect(() => {
    if (onEvent) {
      context.setListener((prevState) => ({ ...prevState, [listenerIndex.current]: onEvent }));
    }
    return () => {
      context.setListener((prevState) => ({ ...prevState, [listenerIndex.current]: undefined }));
    };
  }, [onEvent]);

  return {
    triggerEvent: context.triggerEvent
  };
}

export { UpdateFournisseurProvider, useUpdateFournisseur };
