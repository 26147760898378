import React, { FunctionComponent, useState, useEffect, SetStateAction, Dispatch } from 'react';

import { useTranslation } from 'react-i18next';

import { DateTime } from "luxon";

import { cadencierFournisseursApi, cadencierFournisseurDetailsApi } from "api";
import {
  CadencierFournisseurDetailInsertionModel,
  CadencierFournisseurDetailViewModel,
  CadencierFournisseurViewModel
} from "openapi-typescript-codegen";


import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import DivisionGrid from 'components/BlockGrid/DivisionGrid';
import LigneCommande from "components/Fournisseur/TabCircuit/Cadencier/PlanningCommande/LigneCommande";
import SelectComplete from "components/SelectComplete";
import PlanningHebdomadaire from "components/Fournisseur/TabCircuit/Cadencier/PlanningCommande/PlanningHebdomadaire";

import { showPrettyError, toast } from "utils";

import { trimOrNullValidator } from "validators";

interface CadencierFournisseurDetailViewModelEditable extends CadencierFournisseurDetailViewModel {
  modified?: boolean;
  created?: boolean;
}

interface Props extends PropsBlock {
  selectedCadencier: CadencierFournisseurViewModel;
  setCadencierCanBeDeleted: Dispatch<SetStateAction<boolean>>;
}

enum EnumPeriodOrder {
  All = "lib_periode_toutes",
  Past = "lib_periode_passees",
  NowAndFuture = "lib_periode_en_cours_et_futurs"
}

const PlanningCommande: FunctionComponent<Props> = ({ selectedCadencier, setCadencierCanBeDeleted }) => {
  // Translation utility:
  const { t } = useTranslation();

  // Grid related states:
  const [activate, setActivate] = useState<boolean>(false);
  const [isCreationDisabled, setIsCreationDisabled] = useState<boolean>(false);
  const [loading, setLoader] = useState<boolean>(true);

  // Lines keep the state synced with the server:
  const [cadencierFournisseurDetails, setCadencierFournisseurDetails] = useState<CadencierFournisseurDetailViewModel[]>([]);
  // Lines keep the current state:
  const [lines, setLines] = useState<CadencierFournisseurDetailViewModelEditable[]>([]);

  // States for the new commande:
  const [newLine, setNewLine] = useState<any>(null);
  const [newCadencierFournisseurDetail, setNewCadencierFournisseurDetail] = useState<Partial<CadencierFournisseurDetailInsertionModel>>({});

  // States to keep track of the selected period...
  const [selectedPeriod, setSelectedPeriod] = useState<EnumPeriodOrder>();
  // ...and the selected detail:
  const [selectedDetail, setSelectedDetail] = useState<Partial<CadencierFournisseurDetailViewModel>>();

  // Options for the select:
  const periods = [
    { value: EnumPeriodOrder.All, label: t(EnumPeriodOrder.All) },
    { value: EnumPeriodOrder.Past, label: t(EnumPeriodOrder.Past) },
    { value: EnumPeriodOrder.NowAndFuture, label: t(EnumPeriodOrder.NowAndFuture) }
  ];

  /*
    / Only refresh the lines if cancelling, else, also fetch the data:
   */
  const refreshData = async (cancel?: boolean) => {
    setLoader(true);
    // If cancel is true, we don't need to fetch the data again:
    if (cancel) { setLines(cadencierFournisseurDetails.sort(sortLines)); }
    else if (Object.keys(selectedCadencier).length) {
      try {
        const { data } = selectedCadencier.id ? await cadencierFournisseursApi.v1CadencierFournisseursIdDetailsGet(selectedCadencier.id) : { data: [] };
        setCadencierFournisseurDetails(data);
      } catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    }
    setLoader(false);
  };

  /*
    * Validate the property "nomCadencierDetail" and display an error if invalid.
   */
  const validateName = (input: string) => trimOrNullValidator(input, "lib_cadencier_fournisseur_detail_nom_cadencier_detail");

  /*
    * Create or update a detail.
   */
  const handleCreateAndUpdate = async () => {
    // We can create a new line...
    if (Object.keys(newCadencierFournisseurDetail).length) { await handleCreate(); }
    // ... and update the existing ones at the same time:
    await handleUpdate();
  };

  /*
  * Handle the update of a detail.
 */
  const handleUpdate = async () => {
    let hasBeenUpdated = false;
    for (const detail of lines) {
      // If the line has not been modified, we skip it:
      if (!detail.modified) { continue; }
      // We delete the extra key, not fitting the model:
      delete detail.modified;
      detail.nomCadencierDetail = validateName(detail.nomCadencierDetail || String()) as string;
      if (detail.nomCadencierDetail) {
        const cadencierFournisseurDetail: CadencierFournisseurDetailViewModel = detail;
        try {
          await cadencierFournisseurDetailsApi.v1CadencierFournisseurDetailsPut(cadencierFournisseurDetail);
          toast.success(t('mes_validation_modification'));
          setActivate(false);
          hasBeenUpdated = true;
        } catch (error) {
          console.error(error);
          showPrettyError(error);
        }
      }
    }
    // We fetch the data outside the loop, only if data has been updated, to avoid multiple calls:
    try {
      hasBeenUpdated && await refreshData();
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  };

  /*
    * Handle the creation of a detail.
  */
  const handleCreate = async () => {
    newCadencierFournisseurDetail.nomCadencierDetail = validateName(newCadencierFournisseurDetail.nomCadencierDetail || String()) as string;
    if (newCadencierFournisseurDetail.nomCadencierDetail) {
      try {
        const { data } = await cadencierFournisseurDetailsApi.v1CadencierFournisseurDetailsPost(newCadencierFournisseurDetail as CadencierFournisseurDetailInsertionModel);
        toast.success(t('mes_validation_creation'));
        // We add the new line to the existing ones, without fetching the data again:
        setCadencierFournisseurDetails([...cadencierFournisseurDetails, data]);
        setNewCadencierFournisseurDetail({});
        setNewLine(null);
        setActivate(false);
        setIsCreationDisabled(false);
        setSelectedDetail(data);
      } catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    }
  };

  /*
  * Update a new detail, to be created, with the up-to-date data.
  */
  const handleCreateDetail = (createdDetail: CadencierFournisseurDetailInsertionModel) => {
    // The new detail get the id of the selected cadencier as a foreign key:
    setNewCadencierFournisseurDetail({ ...createdDetail, fkCadencierFournisseur: selectedCadencier.id });
  };

  /*
  * Update an existing detail, to be updated, with the up-to-date data.
  */
  const handleUpdateDetail = (updatedDetail: CadencierFournisseurDetailViewModel) => {
    // The updated detail get the modified key:
    setLines(lines.map(detail => updatedDetail.id === detail.id ? { ...updatedDetail, modified: true } : detail));
  };


  /*
    * Handle the deletion of a detail.
  */
  const handleDeleteDetail = async (id: number) => {
    // We delete the detail by its id:
    try {
      await cadencierFournisseurDetailsApi.v1CadencierFournisseurDetailsIdDelete(id);
      toast.success(t('mes_validation_suppression'));
      setCadencierFournisseurDetails(cadencierFournisseurDetails.filter(detail => detail.id !== id));
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  };

  /*
    * Cancel the edition and refresh the data.
   */
  const handleCancel = () => {
    // Reset the states and refresh the lines:
    setNewLine(null);
    setIsCreationDisabled(false);
    refreshData(true).then(() => setActivate(false));
  };

  /*
    * Handle the change of the select.
   */
  const handleLineFocus = (detail: CadencierFournisseurDetailViewModel) => { setSelectedDetail(detail); };

  /*
    * Handle the addition of a new line to a grid.
   */
  const handleAddLine = () => {
    setNewLine(<LigneCommande detail={{}} activate={activate} onCreateDetail={handleCreateDetail} isNew={true} />);
    // Only one line can be created at a time:
    setIsCreationDisabled(true);
  };

  /*
  * Sort two lines according to the property "dtdebutCadencier".
 */
  const sortLines = (line$1: CadencierFournisseurDetailViewModel, line$2: CadencierFournisseurDetailViewModel) => {
    const date$1 = DateTime.fromISO(line$1.dtdebutCadencier!);
    const date$2 = DateTime.fromISO(line$2.dtdebutCadencier!);
    return date$1 > date$2 ? 1 : -1;
  };

  /*
    * Filter the lines according to the selected period.
   */
  const filterLines = (period?: EnumPeriodOrder) => {
    let filteredData;
    switch (period || selectedPeriod) {
      case EnumPeriodOrder.All:
        setLines(cadencierFournisseurDetails.sort(sortLines));
        setSelectedDetail(cadencierFournisseurDetails.length ? cadencierFournisseurDetails[0] : {});
        break;
      case EnumPeriodOrder.Past:
        filteredData = cadencierFournisseurDetails.filter(detail => detail.dtfinCadencier && DateTime.fromISO(detail.dtfinCadencier) < DateTime.now());
        setSelectedDetail(filteredData.length ? filteredData[0] : {});
        setLines(filteredData.sort(sortLines));
        break;
      case EnumPeriodOrder.NowAndFuture:
        filteredData = cadencierFournisseurDetails.filter(detail => detail.dtfinCadencier && DateTime.fromISO(detail.dtfinCadencier) >= DateTime.now());
        setSelectedDetail(filteredData.length ? filteredData[0] : {});
        setLines(filteredData.sort(sortLines));
        break;
      default:
        setLines(cadencierFournisseurDetails.sort(sortLines));
        break;
    }
  };

  useEffect(() => {
    // When the selected period changes, we filter the lines:
    filterLines();
  }, [selectedPeriod]);

  useEffect(() => {
    // Update the lines when we fetch or update the data according to the selected period:
    selectedPeriod ? filterLines() : setSelectedPeriod(EnumPeriodOrder.NowAndFuture);
    setCadencierCanBeDeleted(!cadencierFournisseurDetails.length);
  }, [cadencierFournisseurDetails]);

  useEffect(() => {
    setCadencierCanBeDeleted(false);
    refreshData();
    setIsCreationDisabled(false);
  }, [selectedCadencier]);

  // @ts-ignore
  return (
    <>
      <DivisionGrid sm={100} md={100} lg={100} xl={100} doubleXl={100} classNameAdd={"h-fit"}>
        <BlockGrid
          classNameAdd={`${!!cadencierFournisseurDetails.length && "rounded-lg-top"}`}
          title={t('lib_cadencier_fournisseur_details')}
          // completude={1}
          toActivate={activate}
          loading={loading}
          handleClick={(activation) => setActivate(activation)}
          handleUpdate={handleCreateAndUpdate}
          handleCancel={handleCancel}
          handleCreate={handleAddLine}
          disableCreate={isCreationDisabled}
        >
          <div className="grid grid-cols-2 gap-4">
            <label className="col-span-1">
              <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_periode")}
                </span>
              </span>
              <SelectComplete
                tabIndex={5}
                options={periods}
                value={periods.filter(period => period.value === selectedPeriod)}
                onChange={option => { setSelectedPeriod(option?.value as EnumPeriodOrder); }}
                isDisabled={!activate}
              />
            </label>
          </div>

          <form className="flex flex-col my-2">
            {(lines.length || newLine) && (
              <div className="grid grid-cols-24 gap-4">
                <label className="col-span-3">
                  <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_cadencier_fournisseur_detail_dtdebut_cadencier")}<span className="text-red-500">**</span>
                    </span>
                  </span>
                </label>
                <label className="col-span-3">
                  <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_cadencier_fournisseur_detail_dtfin_cadencier")}<span className="text-red-500">**</span>
                    </span>
                  </span>
                </label>
                <label className="col-span-5">
                  <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_cadencier_fournisseur_detail_nom_cadencier_detail")}<span className="text-red-500">**</span>
                    </span>
                  </span>
                </label>
                <label className="col-span-6">
                  <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_cadencier_fournisseur_detail_type_semaine")}
                    </span>
                  </span>
                </label>
                <label className="col-span-3">
                  <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_cadencier_fournisseur_detail_decalage")}
                    </span>
                  </span>
                </label>
                <label className="col-span-3">
                  <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_cadencier_fournisseur_detail_nb_j_couv_stock")}
                    </span>
                  </span>
                </label>
              </div>
            )}
            {lines.map(detail => <LigneCommande key={detail.id} detail={detail} activate={activate} onUpdateDetail={handleUpdateDetail} onDeleteDetail={handleDeleteDetail} onFocus={handleLineFocus} hasFocus={detail.id === selectedDetail?.id} />)}
            {newLine}
          </form>
        </BlockGrid>
        {!!cadencierFournisseurDetails.length && <PlanningHebdomadaire selectedDetail={selectedDetail || {}} />}
      </DivisionGrid>
    </>
  );
};

export default PlanningCommande;