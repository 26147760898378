import { FC, useEffect, useMemo, useState } from 'react';
import { DeviseViewModel, TaxeIndirecteViewModel } from "openapi-typescript-codegen";
import { ColDef, EditableCallbackParams } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS } from "app/ag-grid-options";
import { CustomColDef, RowDataAny, RowDataType } from "components/AGGride/gridTypes";

import { taxesApi, devisesApi, taxeIndirectesApi } from 'api';
import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import { GridControllerProps, useGridController } from "hooks/useGridControllerV2";
import { TextCellRenderer, DateCellRenderer, NumberCellRenderer } from "components/AGGride/CellRender";
import {
  DatePickerCellEditor,
  NumberCellEditor,
  NumberCellEditorProps,
  SelectorCellEditor,
  TextCellEditor
} from 'components/AGGride/CellEditor';
import { dateCellValidator, notEmptyCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import { DateTime } from "luxon";

import { SelectTemplate } from "components/SelectComplete";
import { api, getISOEndDate, getISOStartDate } from "utils";
import { useSite } from "context/site";
import { editableIfTodayOrFuture } from "components/AGGride/cellEditable";
import ClientSideGrid from "components/AGGride/ClientSideGrid";

type EditableIfTodayOrFutureCustomParams = (params: EditableCallbackParams, field?: string, dtDebut?: string) => boolean;

const editableIfTodayOrFutureCustom: EditableIfTodayOrFutureCustomParams = (params, field?: string) => {
  const rowData = params.data as RowDataType;
  const data = (rowData as RowDataAny)[field ?? params.colDef.field!];
  if (data) {
    if (!data || typeof data !== 'string' || rowData._customDataProps?.modified?.[field ?? params.colDef.field!]) return true;

    const date = DateTime.fromISO(data);
    const now = DateTime.now();

    return date.toISODate() === now.toISODate() || date.toMillis() >= now.toMillis();
  } else {
    return true;
  }
};

const defaultColDef: ColDef = {
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

type GridTaxeIndirecteType = TaxeIndirecteViewModel;

const TableauxTaxeIndirecte: FC<{ taxeId: number | null; }> = ({ taxeId }) => {
  const { t } = useTranslation();
  const [devises, setDevises] = useState<{ data: DeviseViewModel[], options: SelectTemplate[]; }>({ data: [], options: [] });
  const [activate, setActivate] = useState(false);

  const { state: site } = useSite();

  const options = {
    applications: [
      { value: null, label: "..." },
      { value: 'M', label: t('enu_taxe_indirecte_application_montant') },
      { value: 'P', label: t('enu_taxe_indirecte_application_pourcentage') }
    ],
    unites: [
      { value: null, label: "..." },
      { value: 'P', label: t('enu_taxe_indirecte_unite_piece') },
      { value: 'K', label: t('enu_taxe_indirecte_unite_kilo') },
      { value: 'L', label: t('enu_taxe_indirecte_unite_litre') }
    ]
  };

  const gridControllerOptions = useMemo((): GridControllerProps<GridTaxeIndirecteType> => ({
    floatingAction: activate ? 'hover' : 'none',
    disableAutoFetch: true,
    emptyRowCheck: (rowData) => !rowData.code,
    fetchData: async () => {
      if (!taxeId) return [];
      const { data } = await taxesApi.v1TaxesIdTaxeIndirecteGet(taxeId);
      return (data);
    },
    getNewModel: () => {
      return {
        code: '',
        nomTaxeIndirecte: '',
        fkDevise: null,
        fkTaxe: taxeId,
        application: 'P',
        dtdebut: getISOStartDate(),
        base: 1,
        dtfin: getISOEndDate(),
        siteId: -1,
        unite: 'K',
        valeur: 0,
      };
    },
    postData: (cleanRow) => taxeIndirectesApi.v1TaxeIndirectesPost(cleanRow as TaxeIndirecteViewModel),
    putData: (cleanRow) => taxeIndirectesApi.v1TaxeIndirectesPut({ ...cleanRow, siteId: site?.id } as TaxeIndirecteViewModel),
    deleteData: (dataId) => taxeIndirectesApi.v1TaxeIndirectesIdDelete(dataId),
    onEditingStopped: () => setActivate(false),
  }), [activate, taxeId, site]);
  const gridController = useGridController(gridControllerOptions);

  const columnDefs = useMemo((): CustomColDef<GridTaxeIndirecteType>[] => [
    {
      field: 'code',
      tooltipField: 'code',
      headerName: t('lib_taxe_indirecte_code'),
      headerTooltip: t('lib_taxe_indirecte_code'),
      cellRenderer: TextCellRenderer,
      cellEditor: TextCellEditor,
      cellEditorParams: {
        maxLength: 4,
        toUpperCase: true
      },
      editable: (params) => editableIfTodayOrFutureCustom(params, 'dtfin', 'dtdebut'),
      filter: 'agTextColumnFilter',
      valueValidator: [notEmptyCellValidator],
      headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
    },
    {
      field: 'nomTaxeIndirecte',
      tooltipField: 'nomTaxeIndirecte',
      headerName: t('lib_taxe_indirecte_nom_taxe_indirecte'),
      headerTooltip: t('lib_taxe_indirecte_nom_taxe_indirecte'),
      editable: (params) => editableIfTodayOrFutureCustom(params, 'dtfin', 'dtdebut'),
      valueValidator: [notEmptyCellValidator],
      headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
      cellRenderer: TextCellRenderer,
      filter: 'agTextColumnFilter',
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: 'application',
      headerName: t('lib_taxe_indirecte_application'),
      headerTooltip: t('lib_taxe_indirecte_application'),
      editable: (params) => editableIfTodayOrFutureCustom(params, 'dtfin', 'dtdebut'),
      valueValidator: [notEmptyCellValidator],
      headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
      cellRenderer: TextCellRenderer,
      tooltipValueGetter: (params) => options.applications.find(option => option.value === params.value)?.value,
      valueFormatter: (params) => options.applications.find(option => option.value === params.value)?.label || "",
      cellEditor: SelectorCellEditor,
      cellEditorParams: { values: options.applications },
      onCellValueChanged: (params: any) => {
        const dec_px_devise = 10 ** (devises.data.find(option => option.id === params.data?.fkDevise)?.decPxAchat as number) - 1;
        if (params.newValue === "P" && params.data.valeur > 100) {
          params.data.valeur = 100;
        } else if (params.newValue === "M" && params.data.valeur > dec_px_devise) {
          params.data.valeur = dec_px_devise;
        }
        params.api.refreshCells({ rowNodes: [params.node!], force: true });
      },
      filter: 'agTextColumnFilter',
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: 'valeur',
      tooltipField: 'valeur',
      headerName: t('lib_taxe_indirecte_valeur'),
      headerTooltip: t('lib_taxe_indirecte_valeur'),
      cellRenderer: TextCellRenderer,
      cellEditor: NumberCellEditor,
      cellEditorParams: {
        min: 0,
        max: (value, params) => {
          if (params.data.application === 'P') {
            return (100);
          } else if (params.data.application === 'M') {
            if (params.data.fkDevise) {
              return (10 ** (devises.data.find(option => option.id === params.data?.fkDevise)?.decPxAchat as number) - 1);
            }
            return (10 ** (devises.data.find(option => option.id === site?.tier.fkDevise)?.decPxAchat as number) - 1);
          }
          return (null);
        },
      } as NumberCellEditorProps<GridTaxeIndirecteType>,
      valueValidator: [numberCellValidator],
      headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
      editable: (params) => editableIfTodayOrFutureCustom(params, 'dtfin', 'dtdebut'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'fkDevise',
      headerName: t('lib_indicatif_ean_fk_devise'),
      headerTooltip: t('lib_indicatif_ean_fk_devise'),
      editable: (params) => editableIfTodayOrFutureCustom(params, 'dtfin', 'dtdebut') && params.data?.application !== 'P',
      tooltipValueGetter: (params) => devises.options.find(option => option.value === params.data?.fkDevise)?.value,
      valueFormatter: (params) => params.data?.application !== 'P' ? devises.options.find(option => option.value === params.data?.fkDevise)?.label || String() : '',
      cellEditor: SelectorCellEditor,
      cellEditorParams: { values: devises.options },
      cellRenderer: TextCellRenderer,
      onCellValueChanged: (params: any) => {
        const dec_px_devise = 10 ** (devises.data.find(option => option.id === params.newValue)?.decPxAchat as number) - 1;
        if (params.data.valeur > dec_px_devise) {
          params.data.valeur = dec_px_devise;
          params.api.refreshCells({ rowNodes: [params.node!], force: true });
        }
      },
      filter: 'agTextColumnFilter',
    },
    {
      field: 'unite',
      headerName: t('lib_taxe_indirecte_unite'),
      headerTooltip: t('lib_taxe_indirecte_unite'),
      cellRenderer: TextCellRenderer,
      tooltipValueGetter: (params) => options.unites.find(option => option.value === params.value)?.value,
      valueFormatter: (params) => params.data?.application !== 'P' ? options.unites.find(option => option.value === params.value)?.label || "" : '',
      cellEditor: SelectorCellEditor,
      cellEditorParams: { values: options.unites },
      editable: (params) => editableIfTodayOrFutureCustom(params, 'dtfin', 'dtdebut') && params.data?.application !== 'P',
      filter: 'agTextColumnFilter',
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: 'base',
      tooltipField: 'base',
      headerName: t('lib_taxe_indirecte_base'),
      headerTooltip: t('lib_taxe_indirecte_base'),
      valueFormatter: (params) => params.data?.application === 'M' ? params.value : '',
      cellRenderer: NumberCellRenderer,
      cellRendererSelector: (params) => params.data?.application === 'M' ? { component: NumberCellRenderer } : { component: null },
      cellEditor: NumberCellEditor,
      cellEditorParams: { min: 1, floating: 0 } as NumberCellEditorProps,
      valueValidator: [notEmptyCellValidator, numberCellValidator],
      editable: (params) => editableIfTodayOrFutureCustom(params, 'dtfin', 'dtdebut') && params.data?.application === 'M',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'dtdebut',
      tooltipField: 'dtdebut',
      headerName: t('lib_taxe_indirecte_dtdebut'),
      headerTooltip: t('lib_taxe_indirecte_dtdebut'),
      cellRenderer: DateCellRenderer,
      cellEditor: DatePickerCellEditor,
      valueValidator: [notEmptyCellValidator, dateCellValidator],
      headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
      editable: editableIfTodayOrFuture,
      filter: 'agTextColumnFilter',
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: 'dtfin',
      tooltipField: 'dtfin',
      headerName: t('lib_taxe_indirecte_dtfin'),
      headerTooltip: t('lib_taxe_indirecte_dtfin'),
      cellRenderer: DateCellRenderer,
      cellEditor: DatePickerCellEditor,
      editable: (params) => editableIfTodayOrFutureCustom(params, 'dtfin', 'dtdebut'),
      valueValidator: [notEmptyCellValidator, dateCellValidator],
      headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
      filter: 'agTextColumnFilter',
      cellStyle: {
        textAlign: "left",
      },
    },
  ], [devises, options, site, t]);

  useEffect(() => {
    if (taxeId !== null)
      gridController.fetchData();
  }, [taxeId]);

  useEffect(() => {
    getDevises().then(data => {
      const options: SelectTemplate[] = data.map((devise) => ({
        value: devise.id,
        label: devise.nomDevise ? `${devise.code} - ${devise.nomDevise}` : devise.code || String()
      }));
      options.unshift({ value: null, label: "..." });
      setDevises({ data, options: options });
    });
  }, []);

  async function getDevises() {
    return api.dataset.get<DeviseViewModel>(devisesApi.v1DevisesGet(1, 1000));
  }

  return (
    <div className="flex-1">
      <BlockGrid
        layout={LayoutEnum.settings}
        handleClick={setActivate}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <ClientSideGrid
          className="block-grid-params"
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridController={gridController}
        />
      </BlockGrid>
    </div>
  );
};

export default TableauxTaxeIndirecte;