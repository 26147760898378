import { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from 'react';
import Autocomplete from "react-google-autocomplete";

import SelectComplete, { SelectTemplate } from 'components/SelectComplete';
import BlockGrid, { PropsBlock } from 'components/BlockGrid';

import { tiersApi, paysApi, fournisseursApi, devisesApi, typeFournisseursApi } from 'api';
import {TierViewModel, FournisseurViewModel, PayViewModel, TierInsertionModel} from 'openapi-typescript-codegen';

import { env, showPrettyError, toast } from 'utils';

import { useTranslation } from "react-i18next";

import { useFournisseur, useUpdateFournisseur } from "context/Referencement";
import { UpdateEnum } from 'context/Referencement/updateFournisseur';

import { getColumnControl } from 'validators/ColumnControl/Controls';

const controls = [
  "tier.num_tiers_ext",
  "tier.raison_sociale",
  "tier.nom_tiers",
  "tier.nom_court_tiers",
  "tier.grpe_actionnariat",
  "fournisseur.fk_type_fournisseur",
  "fournisseur.siren",
  "fournisseur.adresse_tiers",
  "fournisseur.fk_devise",
  "fournisseur.tva_intra"
].map(getColumnControl);

const Informations: FunctionComponent<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();

  const { state: context } = useFournisseur();
  const updateFournisseur = useUpdateFournisseur();

  const [completude, setCompletude] = useState<number>(0);

  const [activate, setActivate] = useState<boolean>(false);
  const [hasFocus, setFocus] = useState(false);
  const [loading, setLoader] = useState<boolean>(true);

  // States for the Fournisseur and the Tiers:
  const [fournisseur, setFournisseur] = useState<Partial<FournisseurViewModel>>();
  const [tiers, setTiers] = useState<Partial<TierViewModel | TierInsertionModel>>({});

  // State for the Adresse, including all its sub-parts::
  const [adresse, setAdresse] = useState<{ adresse: string, codePostal: string, ville: string, pays: Partial<PayViewModel>; }>({
    adresse: String(),
    codePostal: String(),
    ville: String(),
    pays: { codePaysNumeric3: Number(), codePaysAlpha2: String(), nomPays: String() }
  });

  // State for the pays:
  const [pays, setPays] = useState<PayViewModel[]>([]);

  // Options for the select:
  const options = useRef<{ devises: SelectTemplate[], types: SelectTemplate[], pays: SelectTemplate[]; }>({
    devises: [], types: [], pays: []
  });

  // Check the completude:
  function checkCompletude() {
    setCompletude(controls.reduce((accumulator, current) => {
      if (current.column.includes("tier.")) {
        return accumulator + (current.isValueMissing(tiers!) ? 1 : 0);
      } else if (current.column.includes("fournisseur.")) {
        return accumulator + (current.isValueMissing(fournisseur!) ? 1 : 0);
      }
      return accumulator;
    }, 0));
  }

  // Validate the fields, according to the defined controls:
  function validator() {
    return controls.every((control) => {
      if (control.column.includes("tier.")) return control.validateInput(tiers!);
      if (control.column.includes("fournisseur.")) return control.validateInput(fournisseur!);
      return false;
    });
  }

  // Refresh the Fournisseur, then the Tiers:
  const refreshData = async (id: number) => {
    if (id) {
      const { data } = await fournisseursApi.v1FournisseursIdGet(id);
      setFournisseur(data);
      data.fkTiers && await refreshTiers(data.fkTiers);
    }
  };

  // Refresh the Tiers, then the Adresse:
  const refreshTiers = async (id: number) => {
    const { data } = await tiersApi.v1TiersIdGet(id);
    setTiers(data);
    setAdresse({
      adresse: data.adresseTiers || String(),
      codePostal: data.codePostalTiers || String(),
      ville: data.villeTiers || String(),
      pays: (await refreshAndGetPays(data.fkPays as number)) as PayViewModel
    });
  };

  // Refresh the Pays, including the options, only if necessary, then return the current Pays:
  const refreshAndGetPays = async (identifier: number) => {
    let $options = options.current;
    let $options$pays: SelectTemplate[] = options.current.pays.length ? options.current.pays : [];
    let $pays: PayViewModel[] = pays.length ? pays : [];
    let $current: Partial<PayViewModel> = { codePaysNumeric3: Number(), codePaysAlpha2: String(), nomPays: String() };
    // We refresh the data and the options only if necessary:
    if (!$options$pays.length || !$pays.length) {
      const { data: response } = await paysApi.v1PaysGet(1, 90000);
      if (response.data && response.data.length > 0) {
        $options$pays = [
          { value: null, label: '...' },
          ...response.data.map(
            ({ id, codePaysAlpha2, nomPays }) => ({ value: id, label: codePaysAlpha2 + " - " + nomPays })
          )
        ];
        $pays = response.data;
        $options = { ...options.current, pays: $options$pays };
      }
    }
    $current = $pays.find(({ id }) => identifier === id) || $current;
    setPays($pays);
    options.current = $options;
    setLoader(false);

    if (!Object.entries($current).length && identifier) {
      const isFound = $pays.find(({ id }) => identifier === id);
      if (isFound) return isFound;
      return await paysApi.v1PaysIdGet(identifier).then((response) => response.data);
    }

    return $current;
  };

  // Refresh the Pays:
  const refreshPays = async (identifier: number) => {
    setAdresse({ ...adresse, pays: (await refreshAndGetPays(identifier)) as PayViewModel });
  };

  useEffect(() => {
    context && refreshData(context.id as number);
  }, [context]);

  useEffect(() => {
    checkCompletude();
  }, [fournisseur, tiers]);

  // Get and return the options for the Devises:
  const getDevises = async () => {
    const { data: response } = await devisesApi.v1DevisesGet(1, 10000);
    let $options: SelectTemplate[] = [];
    if (response.data && response.data.length > 0) {
      $options = [
        { value: null, label: "..." },
        ...response.data.map(
          (item) => ({ value: item.id, label: item.code + " - " + item.nomDevise })
        )
      ];
    }
    return $options;
  };

  // Get and return the options for the Types:
  const getTypes = async () => {
    const { data: response } = await typeFournisseursApi.v1TypeFournisseursGet(1, 10000);
    let $options: SelectTemplate[] = [];
    if (response.data && response.data.length > 0) {
      $options = [
        { value: null, label: "---" },
        ...response.data.map(
          (item) => ({ value: item.id, label: item.code + " - " + item.nomTypeFournisseur })
        )
      ];
    }
    return $options;
  };

  useEffect(() => {
    getDevises().then($options => {
      options.current = { ...options.current, devises: $options };
    });
    getTypes().then($options => {
      options.current = { ...options.current, types: $options };
    });
  }, []);

  // Update the Tiers:
  const handleUpdateTiers = async () => {
    try {
      tiers.hasOwnProperty("id") ? await tiersApi.v1TiersPut(tiers as TierViewModel) : await tiersApi.v1TiersPost(tiers as TierInsertionModel);
      toast.success("mes_validation_modification");
      updateFournisseur.triggerEvent(UpdateEnum.fournisseur);
      setActivate(false);
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  };

  // Update the Fournisseur:
  const handleUpdateFournisseur = async () => {
    try {
      await fournisseursApi.v1FournisseursPut(fournisseur as FournisseurViewModel);
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  };

  // Update the Tiers and Fournisseur if valid:
  function handleUpdate() {
    validator() && handleUpdateTiers().then(() => {
      fournisseur?.id && handleUpdateFournisseur();
    });
  }

  // Cancel and refresh the data:
  function handleCancel() {
    fournisseur?.id && refreshData(fournisseur.id);
    setActivate(false);
  }

  // When the Adresse is updated, we update the corresponding field in the Tiers:

  useEffect(() => {
    setTiers({
      ...tiers,
      adresseTiers: adresse.adresse,
      codePostalTiers: adresse.codePostal,
      villeTiers: adresse.ville,
      fkPays: adresse.pays?.id
    });
  }, [adresse]);


  // Get and return a Pays by its name:
  const getPaysByName = async (country?: string) => {
    let response;
    if (country) {
      response = await paysApi.v1PaysNameNameGet(country);
      return (response.status >= 200 && response.status < 300) ? response.data :
        { codePaysNumeric3: Number(), codePaysAlpha2: String(), nomPays: String() };
    } else {
      return {};
    }
  };

  // Handle the selection of the place using the Google Maps API:
  const handlePlaceSelected = async (placeResult: any, refObject: any) => {
    if (!placeResult) return;
    const components: any = {};
    // The result is split into components:
    placeResult.address_components?.forEach((component: any) => {
      component.types.forEach((key: string) => {
        components[key] = component;
      });
    });
    const $adresse = `${components.street_number?.long_name || ""} ${components.route.long_name}`.trim();
    const $codePostal = components.postal_code?.long_name;
    const $ville = components.locality?.long_name || components.postal_town?.long_name;
    const $pays = await getPaysByName(components.country.long_name);
    refObject.value = $adresse;
    setAdresse({ ...adresse, adresse: $adresse, codePostal: $codePostal, ville: $ville, pays: $pays as PayViewModel });
  };

  function handleFocus(focus: boolean) {
    setFocus(focus);
  }

  return (
    <BlockGrid
      title={t("tit_modification_general_fiche_fournisseur")}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      anchor="informations-fournisseur"
      completude={completude}
      loading={loading}
      handleClick={(res: boolean) => setActivate(res)}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      handleFocus={handleFocus}
    >
      <form className="flex flex-col justify-between">
        <div className="flex flex-wrap w-full">

          <div className="flex w-full gap-4">
            <div className="block mb-2 w-1/2">
              <div className="flex w-full gap-4">
                <label className="block mb-2 w-1/2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_fournisseur_code")}
                      {getColumnControl("fournisseur.code")?.getValidationLabelElement()}
                    </span>
                  </span>
                  <input type="text" tabIndex={5}
                    value={fournisseur?.code ?? String()}
                    onChange={$event => setFournisseur({ ...fournisseur, code: $event.currentTarget.value })}
                    disabled={true}
                    className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary store-disabled
                      ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                      ${getColumnControl("fournisseur.code")?.getValidationInputClass(activate, hasFocus, fournisseur)}
                    `} />
                </label>
                <label className="block mb-2 w-1/2">
                  <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_tiers_num_tiers_ext")}
                      {getColumnControl("tier.num_tiers_ext")?.getValidationLabelElement()}
                    </span>
                  </span>
                  <input type="text" tabIndex={5}
                    value={tiers.numTiersExt ?? String()}
                    onChange={$event => {
                      setTiers({ ...tiers, numTiersExt: $event.currentTarget.value });
                    }}
                    disabled={!activate}
                    className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                      ${getColumnControl("tier.num_tiers_ext")?.getValidationInputClass(activate, hasFocus, tiers)}
                    `} />
                </label>
              </div>
            </div>
            <label className="block mb-2 w-1/2">
              <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_type_fournisseur_nom")}
                  {getColumnControl("fournisseur.fk_type_fournisseur")?.getValidationLabelElement()}
                </span>
              </span>
              <SelectComplete tabIndex={5}
                options={options.current.types}
                value={options.current.types.filter(option => option.value === fournisseur?.fkTypeFournisseur)}
                onChange={option => fournisseur && setFournisseur({
                  ...fournisseur,
                  fkTypeFournisseur: option?.value as number
                })}
                classNameAdd={`
                  ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${getColumnControl("fournisseur.fk_type_fournisseur")?.getValidationInputClass(activate, hasFocus, fournisseur)}
                `}
              />
            </label>
          </div>

          <div className="flex w-full gap-4">
            <label className="block mb-2 w-full">
              <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_tiers_raison_sociale")}
                  {getColumnControl("tier.raison_sociale")?.getValidationLabelElement()}
                </span>
              </span>
              <input type="text" tabIndex={5}
                value={tiers.raisonSociale ?? String()}
                onChange={$event => {
                  setTiers({ ...tiers, raisonSociale: $event.currentTarget.value });
                }}
                disabled={!activate}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${getColumnControl("tier.raison_sociale")?.getValidationInputClass(activate, hasFocus, tiers)}
                `} />
            </label>
          </div>

          <div className="flex w-full gap-4">
            <label className="block mb-2 w-1/2">
              <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_tiers_nom_tiers")}
                  {getColumnControl("tier.nom_tiers")?.getValidationLabelElement()}
                </span>
              </span>
              <input type="text" tabIndex={5}
                value={tiers.nomTiers ?? String()}
                onChange={$event => {
                  setTiers({ ...tiers, nomTiers: $event.currentTarget.value });
                }}
                disabled={!activate}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("tier.nom_tiers")?.getValidationInputClass(activate, hasFocus, tiers)}
                `} />
            </label>
            <label className="block mb-2 w-1/2">
              <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_tiers_nom_court_tiers")}
                  {getColumnControl("tier.nom_court_tiers")?.getValidationLabelElement()}
                </span>
              </span>
              <input type="text" tabIndex={5}
                value={tiers.nomCourtTiers ?? String()}
                onChange={$event => {
                  setTiers({ ...tiers, nomCourtTiers: $event.currentTarget.value });
                }}
                disabled={!activate}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${getColumnControl("tier.nom_court_tiers")?.getValidationInputClass(activate, hasFocus, tiers)}
                `} />
            </label>
          </div>

          <div className="flex w-full gap-4">
            <label className="block mb-2 w-1/2">
              <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_tiers_fk_devise")} {getColumnControl("tier.fk_devise")?.getValidationLabelElement()}
                </span>
              </span>
              <SelectComplete tabIndex={5}
                options={options.current.devises}
                value={options.current.devises.filter(option => option.value === tiers?.fkDevise)}
                onChange={option => {
                  setTiers({ ...tiers, fkDevise: option?.value as number });
                }}
                classNameAdd={`
              ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${getColumnControl("tier.fk_devise")?.getValidationInputClass(activate, hasFocus, tiers)}
              `}
              />
            </label>
            <label className="block mb-2 w-1/2">
              <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_tiers_tva_intra")}
                  {getColumnControl("tier.tva_intra")?.getValidationLabelElement()}
                </span>
              </span>
              <input type="text" tabIndex={5}
                value={tiers.tvaIntra ?? String()}
                min={0}
                onChange={$event => {
                  setTiers({ ...tiers, tvaIntra: $event.currentTarget.value });
                }}
                disabled={!activate}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
              ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${getColumnControl("tier.tva_intra")?.getValidationInputClass(activate, hasFocus, tiers)}
              `} />
            </label>
          </div>

          <div className="flex w-full gap-4">
            <label className="block mb-2 w-full">
              <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t('lib_tiers_siren')}
                  {getColumnControl("tier.tier.siren")?.getValidationLabelElement()}
                </span>
              </span>
              <input type="number" tabIndex={5}
                value={tiers.siren ?? String()}
                min={0}
                onChange={$event => {
                  setTiers({ ...tiers, siren: parseInt($event.currentTarget.value) });
                }}
                disabled={!activate}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                    ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                    ${getColumnControl("tier.tier.siren")?.getValidationInputClass(activate, hasFocus, tiers)}
                  `} />
            </label>
            <label className="block mb-2 w-1/2">
              <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_tiers_grpe_actionnariat")}
                  {getColumnControl("tier.grpe_actionnariat")?.getValidationLabelElement()}
                </span>
              </span>
              <input type="text" tabIndex={5}
                value={tiers.grpeActionnariat ?? String()}
                onChange={$event => {
                  setTiers({ ...tiers, grpeActionnariat: $event.currentTarget.value });
                }}
                disabled={!activate}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                  ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${getColumnControl("tier.grpe_actionnariat")?.getValidationInputClass(activate, hasFocus, tiers)}
                `} />
            </label>
          </div>

          <div className="flex w-full gap-4">
            <label className="block mb-2 w-full">
              <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_tiers_adresse_tiers")}
                  {getColumnControl("tier.adresse_tiers")?.getValidationLabelElement()}
                </span>
              </span>
              <div className='h-full relative'>
                <Autocomplete
                  tabIndex={5}
                  className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                    ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                    ${getColumnControl("tier.adresse_tiers")?.getValidationInputClass(activate, hasFocus, tiers)}
                  `}
                  defaultValue={tiers?.adresseTiers?.toString()}
                  apiKey={env.GOOGLE_MAP_API_KEY}
                  onLoad={() => {
                  }}
                  onPlaceSelected={
                    (placeResult, refObject) => handlePlaceSelected(placeResult, refObject)
                  }
                  onChange={($event: ChangeEvent<HTMLInputElement>) => setAdresse({
                    ...adresse,
                    adresse: $event.currentTarget.value
                  })}
                  options={{ types: ["address"] }}
                  libraries={["places", "nearbySearch"]}
                  placeholder={t('tab_recherche')}
                />
              </div>
            </label>
            {/*// 5 - Second column~*/}
            <label className="block mb-2 w-1/2">
              <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_tiers_code_postal")}
                </span>
              </span>
              <input type="text" tabIndex={5}
                value={adresse.codePostal ?? String()}
                onChange={$event => setAdresse({ ...adresse, codePostal: $event.currentTarget.value })}
                className="h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary"/>
            </label>
          </div>

          <div className="flex w-full gap-4">
            <label className="block mb-2 w-1/2">
              <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_tiers_ville_tiers")}
                </span>
              </span>
              <input type="text" tabIndex={5}
                value={adresse.ville ?? String()}
                onChange={$event => setAdresse({ ...adresse, ville: $event.currentTarget.value })}
                className="h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary"/>
            </label>
            <label className="block mb-2 w-1/2">
              <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t("lib_tiers_fk_pays")}
                  {getColumnControl("tier.fk_pays")?.getValidationLabelElement()}
                </span>
              </span>
              <SelectComplete tabIndex={5}
                options={options.current.pays}
                value={options.current.pays.filter(option => option.value === adresse.pays?.id)}
                onChange={option => refreshPays(option?.value as number)}
                classNameAdd={`
                ${loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${getColumnControl("tier.fk_pays")?.getValidationInputClass(activate, hasFocus, tiers)}
              `}
              />
            </label>
          </div>
        </div>
      </form>
    </BlockGrid>
  );
};

export default Informations;