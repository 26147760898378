import { useState, useEffect, FC, ReactNode, useRef } from 'react';
import { Close } from "@mui/icons-material";
import ConfirmModal, { ConfirmEnum, ConfirmModalRef } from 'components/Modal/ConfirmModal';
import cs from 'classnames';

import { useTranslation } from "react-i18next";

import { useKeydown } from 'hooks';

import { ReactComponent as CircleLoading } from 'assets/icons/CircleLoading.svg';
import { ReactComponent as CircleStatic } from 'assets/icons/CircleStatic.svg';
import { ReactComponent as CircleStop } from 'assets/icons/CircleStop.svg';

export interface PropsBlock {
  sm?: 5 | 10 | 15 | 20 | 25 | 30 | 33 | 35 | 40 | 45 | 50 | 55 | 60 | 65 | 66 | 70 | 75 | 80 | 85 | 90 | 95 | 100 | "full",
  md?: 5 | 10 | 15 | 20 | 25 | 30 | 33 | 35 | 40 | 45 | 50 | 55 | 60 | 65 | 66 | 70 | 75 | 80 | 85 | 90 | 95 | 100 | "full",
  lg?: 5 | 10 | 15 | 20 | 25 | 30 | 33 | 35 | 40 | 45 | 50 | 55 | 60 | 65 | 66 | 70 | 75 | 80 | 85 | 90 | 95 | 100 | "full",
  xl?: 5 | 10 | 15 | 20 | 25 | 30 | 33 | 35 | 40 | 45 | 50 | 55 | 60 | 65 | 66 | 70 | 75 | 80 | 85 | 90 | 95 | 100 | "full",
  doubleXl?: 5 | 10 | 15 | 20 | 25 | 30 | 33 | 35 | 40 | 45 | 50 | 55 | 60 | 65 | 66 | 70 | 75 | 80 | 85 | 90 | 95 | 100 | "full",

  children?: ReactNode;
}

export enum LayoutEnum {
  'default',
  'settings',
  'modal',
}

export type CancelType = 'btn' | 'cross' | 'esc';

interface Props extends PropsBlock {
  layout?: LayoutEnum,
  anchor?: string,
  title?: string,
  img?: string,
  completude?: number,

  loading?: boolean,
  warning?: boolean,
  informations?: string,
  toActivate: boolean,
  classNameAdd?: string;
  handleClick?: (data: boolean) => void,
  handleFocus?: (data: boolean) => void;

  handleCreate?: () => void,
  handleUpdate?: () => void,
  handleCancel?: (type: CancelType) => void;
  disableCreate?: boolean,
  disableUpdate?: boolean,
  disableCancel?: boolean,
  hideCreate?: true,
  hideUpdate?: true,
  hideCancel?: true,
}

const BlockGrid: FC<Props> = ({
  sm = 100,
  md,
  lg = 100,
  xl,
  doubleXl,
  children,
  layout = LayoutEnum.default,
  anchor,
  title,
  img,
  completude,
  loading = false,
  warning,
  informations,
  toActivate,
  classNameAdd,
  handleClick,
  handleFocus,
  handleCreate,
  handleUpdate,
  handleCancel,
  disableCreate,
  disableUpdate,
  disableCancel,
  hideCreate,
  hideUpdate,
  hideCancel,
}) => {
  const { t } = useTranslation();
  const [openModification, setOpenModification] = useState(false);
  const [minW, setMinW] = useState(0);
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  const [hasFocus, setFocus] = useState(false);
  const [hasOver, setOver] = useState(false);
  const blockRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<ConfirmModalRef | null>(null);
  const detectSize = () => { setWinWidth(window.innerWidth); };

  const isCreateShow = !!handleCreate && !hideCreate;
  const isUpdateShow = !!handleUpdate && !hideUpdate;
  const isCancelShow = !!handleCancel && !hideCancel;

  useKeydown('Enter', handleEnter);
  useKeydown('Escape', () => toCancel('esc'));

  function handleEnter() {
    if (handleUpdate && toActivate && hasFocus && openModification && hasFocus) {
      setFocus(false);
      handleUpdate?.();
    } else if (handleUpdate && !toActivate && hasFocus) {
      toClick(true);
    }
  }

  async function toCancel(type: CancelType) {
    if (type === 'esc' && (!openModification || !hasFocus))
      return;
    if (layout !== LayoutEnum.modal) {
      const validate = await modalRef.current!.showModal();
      if (!validate) return;
    }

    if (handleCancel) handleCancel?.(type);
    setFocus(false);
  }

  function toClick(res: boolean) {
    setOpenModification(true);
    if (handleUpdate || handleCreate || handleCancel)
      handleClick?.(res);
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize);
    if (winWidth <= 640) setMinW(sm === "full" ? 100 : sm); //sm
    else if (winWidth <= 768) setMinW(md ? (md === "full" ? 100 : md) : (sm === "full" ? 100 : sm)); //md
    else if (winWidth <= 1024) setMinW(lg === "full" ? 100 : lg); //lg
    else if (winWidth <= 1280) setMinW(xl ? (xl === "full" ? 100 : xl) : (lg === "full" ? 100 : lg)); //xl
    else setMinW(doubleXl ? (doubleXl === "full" ? 100 : doubleXl) : ((xl ? (xl === "full" ? 100 : xl) : (lg === "full" ? 22 : lg)))); //2xl

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [winWidth, sm, md, lg, xl, doubleXl]);

  useEffect(() => {
    if (handleFocus)
      handleFocus((hasFocus || hasOver));
  }, [hasFocus, hasOver]);

  useEffect(() => {
    if (layout === LayoutEnum.modal)
      blockRef.current?.focus();
  }, []);

  const blockGrid = (
    <div
      tabIndex={5}
      ref={blockRef}
      onClick={() => !toActivate && toClick(true)}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onMouseEnter={() => !hasOver && setOver(true)}
      onMouseLeave={() => hasOver && setOver(false)}
      style={{
        minWidth: `calc(${minW}% - 1px)`
      }}
      className={cs(
        classNameAdd,
        'flex flex-col relative flex-1 rounded-lg border-2 border-transparent no-scrollbar',
        toActivate && handleUpdate && layout === LayoutEnum.default ? 'shadow-lg bg-slate-100' : 'bg-white-500',
        {
          'outline-store-primary hover:border-store-primary': handleUpdate && layout === LayoutEnum.default,
          'outline-gray-500 hover:border-gray-500': !handleUpdate && layout === LayoutEnum.default,
          'cursor-pointer hover:shadow-inner shadow-store-primary': !toActivate && handleUpdate,
          'border-orange-500': warning,
          'border-red-500 outline-red-500 hover:border-red-500': completude && (hasOver || hasFocus),
          'bg-transparent px-0': layout === LayoutEnum.settings,
          'max-h-screen border-0': layout === LayoutEnum.modal,
        }
      )}
    >
      <ConfirmModal
        ref={modalRef}
        layout={ConfirmEnum.confirm}
        title={t('tit_confirmation_annulation')}
        informations={t('mes_confirmation_annulation')}
      />

      {/* Block content */}
      {(layout === LayoutEnum.default || layout === LayoutEnum.settings) &&
        <>
          <div
            id={anchor ? 'block-' + anchor : undefined}
            className={cs('w-full h-full text-grey-900', { 'px-4': layout !== LayoutEnum.settings, 'pointer-events-none': !toActivate })}
          >
            {(!!title || !!img || !!completude) &&
              <div className="w-full flex justify-between items-center pt-3 pb-1 h-10">
                <div className="text-grey-500 font-bold">{title}</div>

                {!!completude && !loading
                  ? <span className="text-red-500 self-center text-xs text-right">
                    {completude} {t('mes_completude_champ_manquant')}
                  </span>
                  : !!img && <img className="h-4 w-auto" src={img} alt={`${title ?? 'cellule'} logo`} />
                }
              </div>
            }

            <div className={cs('w-full h-full no-scrollbar ', { 'pt-4': !title && !img && layout === LayoutEnum.default })}>
              {children}
            </div>
          </div>
        </>
      }
      {layout === LayoutEnum.modal &&
        <>
          <div className="bg-blue-store text-white w-full px-4 py-2 flex justify-between rounded-t-lg">
            <div className="self-center font-bold">{title}</div>
            <button
              onClick={() => toCancel('cross')}
              className="text-lg rounded-lg h-8 w-8 flex items-center justify-center border border-transparent focus:border-white-100 hover:border-white-100"
              tabIndex={5}
            >
              <Close />
            </button>
          </div>
          {!!informations && <div className="bg-slate-100 self-center w-full px-4 py-2">{informations}</div>}
          <div className="w-full h-full text-grey-900 px-4 py-4">{children}</div>
        </>
      }

      <div className={cs('w-full flex justify-end h-12 py-2 px-4 text-xs', { 'bg-store-primary-light rounded-b-lg': layout === LayoutEnum.modal })}>
        {/* Action Btn */}
        {toActivate &&
          <div className="w-full h-full flex justify-between">
            <div className="flex gap-2">
              {isCreateShow &&
                <button
                  tabIndex={5}
                  title={disableCreate ? t('bul_ligne_vide_deja_existante') : t('bul_nouveau')}
                  onClick={() => handleCreate?.()}
                  disabled={disableCreate}
                  className={cs("border rounded py-0.5 px-2",
                    disableCreate ?
                      "border-gray-400 bg-gray-400 text-white cursor-default" :
                      "border-store-primary text-store-primary hover:text-white-500 hover:bg-store-primary focus:text-white focus:bg-store-primary"
                  )}
                >
                  {t('btn_nouveau')} +
                </button>
              }
            </div>
            <div className="flex gap-2">
              {isCancelShow &&
                <button
                  tabIndex={5}
                  title={disableCancel ? t('bul_ou_appuyez_echap') : t('bul_ou_appuyez_echap')}
                  onClick={() => toCancel('btn')}
                  disabled={disableCancel}
                  className={cs("",
                    disableCancel ?
                      " text-gray-600 cursor-default" :
                      "text-store-primary underline outline-none focus:text-black hover:text-black"
                  )}
                >
                  {t('btn_annuler')}
                </button>
              }
              {isUpdateShow &&
                <button
                  tabIndex={5}
                  title={disableUpdate ? t('bul_validation_impossible') : t('bul_ou_appuyez_entree')}
                  onClick={() => handleUpdate?.()}
                  disabled={disableUpdate}
                  className={cs("rounded py-0.5 px-2",
                    disableUpdate ?
                      " bg-gray-400 text-white cursor-default" :
                      "text-white bg-blue-store hover:bg-blue-store focus:bg-blue-store"
                  )}
                >
                  {t('btn_valider')}
                </button>
              }
            </div>
          </div>
        }
        {/* Bottom Status */}
        {!toActivate &&
          <div className="h-full flex items-center">
            {loading &&
              <div className="w-[19px] h-[19px] fill-grey-150 animate-spin">
                <CircleLoading />
              </div>
            }
            {!loading && isUpdateShow &&
              <div title={t('bul_cliquez_pour_modifier')} className="w-[19px] h-[19px] fill-grey-150">
                <CircleStatic />
              </div>
            }
            {!loading && !isUpdateShow && !isCancelShow && !isCreateShow &&
              <div title={t('bul_element_non_modifiable')} className="h-full flex items-center gap-2">
                {openModification && <span className="text-grey-500">{t('bul_element_non_modifiable')}</span>}
                {!loading &&
                  <div className="w-[19px] h-[19px] fill-grey-150">
                    <CircleStop />
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    </div>
  );

  if (layout === LayoutEnum.modal) {
    return (
      <div className="absolute top-1/2 left-1/2 max-h-screen shadow-xl w-3/4 -translate-x-1/2 -translate-y-1/2">
        {blockGrid}
      </div>
    );
  }
  return blockGrid;
};

export default BlockGrid;