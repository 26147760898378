import { useState, FunctionComponent, useEffect } from 'react';

import { useTranslation } from "react-i18next";

import { sitesApi } from "api";
import {
  CadencierFournisseurInsertionModel, CadencierNomenclatureInsertionModel,
  EnumOrderCadenceType, SiteWithTiersViewModel
} from "openapi-typescript-codegen";
import { CircuitLogistiqueWithCadenciersViewModel } from 'openapi-typescript-codegen';

import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import SelectComplete, { SelectTemplate } from 'components/SelectComplete';
import Modal from 'components/Modal';
import { TextInput } from "components/InputWrapper";
import SitesSelection from "components/Fournisseur/TabCircuit/Navigation/Interaction/CreationCadencierLN/SitesSelection";
import NomenclatureSelection from "components/Fournisseur/TabCircuit/Navigation/Interaction/CreationCadencierLN/NomenclatureSelection";

import { trimOrNullValidator } from "validators";
import {api, toast} from "utils";

interface Props {
  selectedCircuit?: CircuitLogistiqueWithCadenciersViewModel;
  onCreateCadencier: (cadencier: CadencierFournisseurInsertionModel, sites: number[], nomenclature?: CadencierNomenclatureInsertionModel) => void;
}

const CreationCadencierLN: FunctionComponent<Props> = ({ selectedCircuit, onCreateCadencier }) => {
  // Translation utility:
  const { t } = useTranslation();

  // State for the cadencier to be created, using Partial:
  const [cadencier, setCadencier] = useState<Partial<CadencierFournisseurInsertionModel>>({});

  // State for the nomenclature to be created, which can be null:
  const [nomenclature, setNomenclature] = useState<CadencierNomenclatureInsertionModel | null>(null);

  // State for the affectation:
  const [affectation, setAffectation] = useState(1);

  // Dynamic options for the selects:
  const [sitesOptions, setSitesOptions] = useState<SelectTemplate<number>[]>([]);
  const [selectedSites, setSelectedSites] = useState<SelectTemplate<number>[]>([]);

  // State for the modal:
  const [open, setOpen] = useState<boolean>(false);

  // Static options for the select:
  const options = {
    types: [
      {
        value: EnumOrderCadenceType.CircuitLogistique,
        filterKey: t('enu_cadencier_fournisseur_circuit_logistique'),
        label: t('enu_cadencier_fournisseur_circuit_logistique')
      },
      {
        value: EnumOrderCadenceType.Nomenclature,
        filterKey: t('enu_cadencier_fournisseur_nomenclature'),
        label: t('enu_cadencier_fournisseur_nomenclature')
      },
      {
        value: EnumOrderCadenceType.ListeArticle,
        filterKey: t('enu_cadencier_fournisseur_liste_article'),
        label: t('enu_cadencier_fournisseur_liste_article')
      }
    ],
    affectation: [
      {
        value: 1,
        filterKey: t('enu_cadencier_fournisseur_affectation_tous_les_sites'),
        label: t('enu_cadencier_fournisseur_affectation_tous_les_sites'),
      },
      {
        value: 2,
        filterKey: t('enu_cadencier_fournisseur_affectation_tous_les_sites'),
        label: t('enu_cadencier_fournisseur_affectation_site'),
      }
    ],
  };

  /*
    * Open the modal and reset the (default) states.
   */
  function openModal() {
    setOpen(true);
    // Reset:
    setCadencier({ type: EnumOrderCadenceType.ListeArticle });
    setAffectation(1);
    setSelectedSites([]);
    setNomenclature(null);
  }

  /*
    * Validate the inputs.
   */
  const validate = () => {
    let isValid: boolean;
    const fields: { field: string, label: string, value: string; }[] = [
      { field: "code", label: "lib_cadencier_fournisseur_code", value: cadencier.code || String() },
      {
        field: "nomCadencierFournisseur",
        label: "lib_cadencier_fournisseur_nom_cadencier_fournisseur",
        value: cadencier.nomCadencierFournisseur || String()
      }
    ];
    isValid = !!cadencier && fields.every(({ field, label, value }) =>
      trimOrNullValidator(value, label, { state: cadencier, dispatch: setCadencier, field }));
    if (affectation === 2 && !selectedSites.length) {
      toast.error(`${t("lib_site_site_type")}: ${t('err_champ_obligatoire')}`);
      isValid = false;
    }
    return isValid;
  };

  /*
    * Set the sites and call the creation function (including them).
    * Creation is handled by the parent component.
   */
  const handleCreate = async () => {
    if (validate()) {
      let sites: number[] = [];
      // The list of identifiers for the sites are sent with the cadencier:
      if (affectation === 1) {
        sites = sitesOptions.map(site => site.value);
        cadencier.flTousSites = true;
      }
      else if (affectation === 2) {
        sites = selectedSites.map(site => site.value);
        cadencier.flTousSites = false;
      }
      selectedCircuit?.id && (
        cadencier.type === EnumOrderCadenceType.Nomenclature && nomenclature ?
          onCreateCadencier(cadencier as CadencierFournisseurInsertionModel, sites, nomenclature) :
          onCreateCadencier(cadencier as CadencierFournisseurInsertionModel, sites)
      );
      setOpen(false);
    }
  };

  /*
    * Close the modal on cancellation.
   */
  const handleCancel = () => { setOpen(false); };

  /*
    * Update the nomenclature state.
   */
  const handleUpdateNomenclature = (nomenclature: CadencierNomenclatureInsertionModel) => { setNomenclature(nomenclature); };
  /*
  * Update the selected sites.
 */
  const handleUpdateSites = (selectedSites: SelectTemplate<number>[]) => { setSelectedSites(selectedSites); };

  /*
    * Get all the options for the sites and return them.
   */
  const getSitesOptions = async () => {
    if (!sitesOptions.length) {
      const data = await api.dataset.get<SiteWithTiersViewModel>(sitesApi.v1SitesTiersGet(1, 1000));
      return api.options.generate(data, { label: "#{tier.nomTiers} - #{typeSite}"}, false);
    }
    return sitesOptions;
  };

  // The options for the sites are only fetched and set once:
  useEffect(() => {
    open && getSitesOptions().then($options => { setSitesOptions($options); });
  }, [open]);

  return (
    <>
      <button tabIndex={5}
        onClick={() => openModal()}
        className="bg-store-primary mt-0.5 text-white-500 p-1 text-sm w-full border-t-white-500">+ {t('btn_ajouter_cadencier_fournisseur')}
      </button>
      <Modal
        open={open} tabIndex={-1}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <BlockGrid
          layout={LayoutEnum.modal}
          title={t('tit_creation_cadencier_fournisseur')}
          informations={''}
          toActivate={true}
          handleUpdate={handleCreate}
          handleCancel={handleCancel}
        >
          <form onSubmit={ev => ev.preventDefault()} className="flex flex-col">

            <div className="grid grid-cols-4 gap-4">
              <TextInput labelClassAdd="col-span-1" label={t("lib_cadencier_fournisseur_code")}
                value={cadencier} field="code" setValue={setCadencier} required={true} />
              <TextInput labelClassAdd="col-span-1" label={t("lib_cadencier_fournisseur_nom_cadencier_fournisseur")}
                value={cadencier} field="nomCadencierFournisseur" setValue={setCadencier} required={true} />
              <label className="block col-span-1 flex-1">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>{t('lib_cadencier_fournisseur_type')}
                    <span className="text-red-500">**</span>
                  </span>
                </span>
                <SelectComplete
                  onChange={option => setCadencier({ ...cadencier, type: option?.value })}
                  options={options.types}
                  defaultValue={options.types.find($option => $option.value === cadencier?.type)}
                />
              </label>
              <label className="block col-span-1 flex-1">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>{t('lib_cadencier_fournisseur_affectation')}
                    <span className="text-red-500">**</span>
                  </span>
                </span>
                <SelectComplete
                  onChange={option => option?.value != null && setAffectation(option.value)}
                  options={options.affectation}
                  defaultValue={options.affectation.find(option => option.value === 1)}
                />
              </label>
            </div>
            {(!!cadencier && cadencier.type === EnumOrderCadenceType.Nomenclature) && <NomenclatureSelection onUpdate={handleUpdateNomenclature} />}
            {affectation === 2 && <SitesSelection sitesOptions={sitesOptions} onUpdate={handleUpdateSites} />}
          </form>
        </BlockGrid>
      </Modal>
    </>
  );
};

export default CreationCadencierLN;