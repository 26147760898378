import { createContext, Dispatch, SetStateAction } from "react";

const NomenclatureContext = createContext<{
  refresh: boolean,
  setRefresh: Dispatch<SetStateAction<boolean>>,
  nomenclature: {
    enseigne: number | null,
    departement : number | null,
    rayon: number | null,
    famille: number | null,
    sousFamille: number | null,
    uniteBesoin: number | null
  },
  setNomenclature: Dispatch<SetStateAction<{
    enseigne: number | null,
    departement : number | null,
    rayon: number | null,
    famille: number | null,
    sousFamille: number | null,
    uniteBesoin: number | null
  }>>
}>({
  refresh: false,
  setRefresh: () => {},
  nomenclature: {
    enseigne: null,
    departement: null,
    rayon: null,
    famille: null,
    sousFamille: null,
    uniteBesoin: null
  },
  setNomenclature: () => {}
});

export default NomenclatureContext;