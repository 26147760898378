import axios from "axios";
import { t } from "i18next";
import {camelToSnake, toast} from 'utils';

const getLabel = (table: string, field: string) => {
    field = camelToSnake(field);
    return `lib_${table}_${field}`;
}

export function getPrettyError(error: unknown, fallback?: string) {
    let prettyError = String(error);
    if (axios.isAxiosError(error)) {
        const data = error.response?.data as any;
        if (data?.title) {
            prettyError = data?.title;
        } else if (data?.Message) {
            prettyError = t(data.Message);
            console.log(prettyError);
            prettyError = fallback && prettyError === data.Message ? t(fallback) : prettyError;
        } else {
            prettyError = t('mes_erreur_technique');
        }
    }
    return prettyError;
}

export function getPrettyErrors(error: unknown, columns: any[], model: string): string[] {
    let prettyErrors: string[] = [];
    if (axios.isAxiosError(error)) {
        const data = error.response?.data as any;
        if (data?.errors) {
            for(const [key, value] of Object.entries(data.errors)) {
                // Generic errors stats with "err_champ_". If the error is generic, we add the column name:
                if (String(value).startsWith("err_champ_")) {
                    // The column name must be sent manually in the options if the concerned field is not a cell of an AGGrid table with a "field" and a "label" properties:
                    const column = columns.find((column: any) => column.field.toUpperCase() === key.toUpperCase());
                    const label = column?.label || column?.headerName || model ? getLabel(model, key) : `(${t("lib_champ_brut")}:) ${key}`;
                    prettyErrors.push(`${t(label)}:\n${t(String(value))}`);
                }
                // If the error is not generic, we use the error message as it is:
                else { prettyErrors.push(t(String(value))); }
            }
        }
    }
    return prettyErrors;
}

export function showPrettyError(error: unknown, options?: { prefix?: string, fallback?: string, columns?: any[], model?: string }) {
    let prettyError = getPrettyError(error, options?.fallback);
    // In the case of one or more validation errors occurred, the error data contains a list of errors:
    let prettyErrors: string[] = getPrettyErrors(error, options?.columns || [], options?.model || "");
    prettyErrors.length || toast.error(options?.prefix ? options.prefix + prettyError : prettyError);
    for (const error of prettyErrors) {
        toast.error(options?.prefix ? options.prefix + error : error);
    }
    return prettyError;
}