import { toast } from "utils";
import { t } from "i18next";

const expression = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w]{2,4}$/);

export const mailValidator = (value: string | null | undefined) => {
    value = value ? value.trim() : null;
    const isValid = value && expression.test(String(value).toLowerCase());
    !isValid && toast.error(`${t('err_champ_email_invalide')}${value ? `: ${value}` : String()}`);
    return isValid;
};