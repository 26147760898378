import { CellClassParams, ColDef } from "ag-grid-community";

import { CustomColDef, RowDataType } from "components/AGGride/gridTypes";

/* Ag grid utils */

/** @deprecated use AG_GRID_DEFAULT_COLUMN_NEW */
export const AG_GRID_DEFAULT_COLUMN: ColDef = {
  headerClass: ["text-xs"],
  cellClass: "text-left",
  filter: true,
  suppressMovable: true,
  sortable: true,
  resizable: true,
  enableValue: false,
  enablePivot: false,
};

export const AG_GRID_DEFAULT_COLUMN_HEADER_CLASS = ["text-xs", "text-center", "nowrap"];

export const AG_GRID_DEFAULT_COLUMN_NEW = {
  editable: false,
  resizable: false,
  suppressMenu: true,
  headerClass: ["text-xs", "text-center", "nowrap"],
  cellStyle: {
    textAlign: "center",
  },
  cellClassRules: {
    'ag-cell-not-valid': (params: CellClassParams<RowDataType, unknown>) => {
      return params.data?._customDataProps?.validationSuccess?.[params.colDef.field || ''] === false;
    },
    'ag-cell-editable': (params: CellClassParams<RowDataType, unknown>) => {
      return params.colDef.editable === true
        || (typeof params.colDef.editable === 'function' && params.colDef.editable(params));
    },
  },
};

// TODO: DEFAULT_COLUMN_NEW type check, replace with 'satisfies' when typescript 4.9 is out
const _AG_GRID_DEFAULT_COLUMN_NEW = AG_GRID_DEFAULT_COLUMN_NEW as CustomColDef;

// function maximize(id: string): MenuItemDef {
//   return {
//     icon: "X",
//     name: "Afficher en plein écran",
//     action: () => {
//       // eslint-disable-next-line @typescript-eslint/no-explicit-any
//       const elem = document.getElementById(id) as any;
//       if (elem.requestFullscreen) {
//         elem.requestFullscreen();
//       } else if (elem.mozRequestFullScreen) {
//         /* Firefox */
//         elem.mozRequestFullScreen();
//       } else if (elem.webkitRequestFullscreen) {
//         /* Chrome, Safari and Opera */
//         elem.webkitRequestFullscreen();
//       } else if (elem.msRequestFullscreen) {
//         /* IE/Edge */
//         elem.msRequestFullscreen();
//       }
//     },
//   };
// }

// function minimize(): MenuItemDef {
//   return {
//     icon: "X",
//     name: "Afficher par default",
//     action: () => {
//       document.exitFullscreen();
//     },
//   };
// }