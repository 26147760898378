import {FunctionComponent, useEffect, useState} from 'react';
import { toast } from 'utils';

import BlockGrid, { PropsBlock } from 'components/BlockGrid';

import NomenclatureLine from './NomenclatureLine';
import NomenclatureLineLoader from './NomenclatureLineLoader';

import { sitesApi, articlesApi, nomenclatureArticlesApi } from 'api';
import { SiteEnseigneViewModel, NomenclatureArticleViewModel, ViewNomenclatureArticleViewModel, NomenclatureArticleInsertionModel } from 'openapi-typescript-codegen';
import { useArticle, useCompletudeArticle, useUpdateArticle } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateArticle';

import { useTranslation } from "react-i18next";
import { useSite } from "context/site";

interface FormArray extends Partial<ViewNomenclatureArticleViewModel> {
  fkDepartement?: number | null,
  fkRayon?: number | null,
  newData?: boolean;
  deleteData?: boolean;
}

const Nomenclature: FunctionComponent<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: controled } = useCompletudeArticle();
  const { state: site } = useSite();
  const { state: article } = useArticle();
  const uA = useUpdateArticle();
  const [activate, setActivate] = useState<boolean>(false);
  const [form, setForm] = useState<FormArray[]>([]);
  const [enseignePrincipal, setEnseignePrincipale] = useState<number>(0);

  const fetchUpdateData = async () => {
    try {
      const dataToSent = form.map((item) => {
        if (item.deleteData && item.id) { return nomenclatureArticlesApi.v1NomenclatureArticlesIdDelete(item.id) }
        let data: (NomenclatureArticleInsertionModel | NomenclatureArticleViewModel) = {
          fkArticle: (item.fkArticle || article?.id) as number,
          fkSousFamille: item.fkSousFamille as number,
          fkUniteBesoin: item.fkUniteBesoin as number,
        }
        if (item.id) {
          data = { ...data,  id: item.id } as NomenclatureArticleViewModel;
          return nomenclatureArticlesApi.v1NomenclatureArticlesPut(data as NomenclatureArticleViewModel);
        } else {
          return nomenclatureArticlesApi.v1NomenclatureArticlesPost(data as NomenclatureArticleInsertionModel);
        }
      });
      await Promise.all(dataToSent);
      toast.success(t('mes_validation_modification'));
      if (article?.id) fetchGetNomenclature(article.id);
      uA.triggerEvent(UpdateEnum.nomenclature);
      setActivate(false);
    } catch (error: any) {
      toast.error(t('mes_erreur_technique') + ": " + (error?.response?.data?.message || error));
    }
  };

  const fetchGetNomenclature = async (id: number) => {
    let { data: response } = await articlesApi.v1ArticlesIdNomenclatureArticlesGet(id);
    if (response.length !== 0) {
      const tmp: FormArray[] = response.map((item) => ({
        ...item,
        newData: false
      }));
      setForm(tmp);
    } else {
      setForm([{}]);
    }
  };

  const fetchEnseignePrincipal = async () => {
    if (site?.id) {
      const { data: response } = await sitesApi.v1SitesIdSiteEnseignesGet(site.id);
      const tmp = response.find((item: SiteEnseigneViewModel) => {
        return item.flPrincipal === true;
      })?.fkEnseigne;
      setEnseignePrincipale(tmp ?? 0);
    }
  };

  useEffect(() => {
    if (article?.id) fetchGetNomenclature(article.id);
  }, [article?.id]);

  useEffect(() => {
    fetchEnseignePrincipal();
  }, []);

  function handleNewElement() {
    let tmp = [...form];
    tmp.push({
      fkArticle: article?.id,
      fkEnseigne: null,
      fkDepartement: null,
      fkRayon: null,
      fkFamille: null,
      fkSousFamille: null,
      fkUniteBesoin: null,
      newData: true
    });
    setForm(tmp);
  }

  function checkMinimal() {
    if (controled?.find((i) => i.colonneArticle === 'nomenclature_article.fk_enseigne')?.required) {
      const valid = form.filter((i) => { return i.deleteData !== true; });
      return valid.length;
    }
    return true;
  }

  function validator() {
    return form
      .filter((i) => { return i.deleteData !== true; })
      .every((item) => {
        return !(!item.fkEnseigne ||
          !item.fkDepartement ||
          !item.fkRayon ||
          !item.fkFamille ||
          !item.fkSousFamille
        );
      });
  }

  function handleUpdate() {
    if (!controled?.find((i) => i.colonneArticle === 'nomenclature_article.fk_enseigne')?.required) {
      if (validator()) fetchUpdateData();
      else toast.error("Merci de remplir tous les champs requis");
    } else if (controled?.find((i) => i.colonneArticle === 'nomenclature_article.fk_enseigne')?.required && checkMinimal()) {
      if (validator()) fetchUpdateData();
      else toast.error("Merci de remplir tous les champs requis");
    } else toast.error("Validation impossible: un article doit posséder une nomenclature");
  }

  function handleCancel() {
    if (article?.id) fetchGetNomenclature(article.id);
    setActivate(false);
  }

  function isSelectedEnseigne() {
    const selectedEnseignes: number[] = [];
    form
      .filter((i) => i.deleteData !== true)
      .forEach((item: FormArray) => {
        if (item.fkEnseigne && !selectedEnseignes.includes(item.fkEnseigne))
          selectedEnseignes.push(item.fkEnseigne);
      });
    return selectedEnseignes;
  }

  return (
    <BlockGrid
      title={t('tab_article_nomenclature')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      anchor="nomenclature-article"
      completude={controled?.find((i) => i.colonneArticle === 'nomenclature_article.fk_enseigne')?.required && form.length < 1 ? 1 : 0}
      loading={form.length < 1}
      handleClick={(res: boolean) => setActivate(res)}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      handleCreate={handleNewElement}
    >
      <form className="flex justify-between flex-nowrap flex-col">
        <div className="w-full flex gap-2">
          <div className={`grid w-full gap-2 relative ${activate && 'pr-4'} transition-all`} style={{ gridTemplateColumns: `repeat(24, minmax(0, 1fr))` }}>
            <label className="block col-span-4 flex-1">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_article_enseigne')}<span className="text-red-500">**</span></span></span>
            </label>
            <label className="block col-span-4 flex-1">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_article_departement')}<span className="text-red-500">**</span></span></span>
            </label>
            <label className="block col-span-4 flex-1">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_article_rayon')}<span className="text-red-500">**</span></span></span>
            </label>
            <label className="block col-span-4 flex-1">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_article_famille')}<span className="text-red-500">**</span></span></span>
            </label>
            <label className="block col-span-4 flex-1">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_article_sous_famille')}<span className="text-red-500">**</span></span></span>
            </label>
            <label className="block col-span-4 flex-1">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between"><span>{t('lib_article_unite_besoin')}</span></span>
            </label>
          </div>
        </div>
        {form.length < 1 && <NomenclatureLineLoader />}
        {form && form.length > 0 &&
          form
            .filter((item) => { return item.deleteData !== true; })
            .sort((a, b) => {
              return (a.fkEnseigne === b.fkEnseigne) ? 0 :
                a.fkEnseigne !== enseignePrincipal ? 1 : -1;
            })
            .map((item: FormArray, key) =>
              <NomenclatureLine
                key={key}
                activate={activate}
                value={item}
                onChange={(e: any) => {
                  let newData = [...form];
                  newData[form.indexOf(item)] = e;
                  setForm(newData
                    .filter((i) => {
                      return !(i.deleteData === true && !i.id);
                    })
                  );
                }}
                isPrincipal={enseignePrincipal === item.fkEnseigne}
                enseigneSelected={isSelectedEnseigne()}
              />
            )
        }
      </form>
    </BlockGrid>
  );
};

export default Nomenclature;