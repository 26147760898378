import { FunctionComponent } from "react";

import PrixVentePermanent from "components/Article/PrixVentePermanent";
import PrixVenteGraph from "components/Article/PrixVenteGraph";
import TaxesVente from "components/Article/TaxesVente";

interface Props { }

const TabVentes: FunctionComponent<Props> = () => {
  return (
    <div
      id="ventes"
      className="flex flex-wrap justify-between bg-grey-150 gap-px p-px"
    >
      <PrixVentePermanent
        sm={100}
        md={100}
        lg={100}
        xl={50}
        doubleXl={50}
      />
      <PrixVenteGraph
        sm={100}
        md={100}
        lg={100}
        xl={50}
        doubleXl={50}
      />
      <TaxesVente sm={100} md={100} lg={100} xl={100} doubleXl={100} />
    </div>
  );
};

export default TabVentes;
