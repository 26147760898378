import { FC, HTMLAttributeAnchorTarget, useRef, useState } from "react";
import { Delete, Edit, FileDownload, FileDownloadDone, SvgIconComponent } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import cs from 'classnames';

import { RowDataType } from "components/AGGride/gridTypes";
import CodeBar from "components/CodeBar";
import Modal from "components/Modal";
import ConfirmModal, { ConfirmModalRef, ConfirmEnum } from "components/Modal/ConfirmModal";
import { fixFloating } from "utils";
import { overwriteSetCellData } from "./CellEditor";

import CheckedIcon from "assets/icons/ag-grid/checked.svg";

/* Types */

type RenderCallbackFunction<R = string, TData = any, TValue = any> =
  R |
  ((cellValue: TValue, params: ICellRendererParams<TData, TValue>) => R);

/* CellRenders */

type TextCellRendererProps = {};
export const TextCellRenderer: FC<TextCellRendererProps & ICellRendererParams> = (props) => {
  const cellValue = getCellValue(props);

  return (
    <span>{cellValue}</span>
  );
};

export type NumberCellRendererProps<TData = any> = { floating?: RenderCallbackFunction<number, TData>; };
export const NumberCellRenderer: FC<NumberCellRendererProps & ICellRendererParams> = (props) => {
  let cellValue = getCellValue(props);

  if (typeof cellValue === 'number' && typeof props.floating === 'number') {
    cellValue = fixFloating(cellValue, props.floating);
  }

  return (
    <span>{cellValue || 0}</span>
  );
};

export type LinkCellRendererProps = { href?: RenderCallbackFunction; target?: HTMLAttributeAnchorTarget; };
export const LinkCellRenderer: FC<LinkCellRendererProps & ICellRendererParams> = (props) => {
  const cellValue = getCellValue(props);
  const href = typeof props.href === 'function' ? props.href(cellValue, props) : props.href ?? cellValue;

  return (
    typeof href === 'string' && cellValue?.toString().length > 0
      ? <Link className="text-store-primary underline cursor-pointer p-2" target={props.target} to={href} rel="noreferrer">
        {cellValue}
      </Link>
      : <span>{cellValue}</span>
  );
};

export type DateFormats = 'date' | 'time' | 'datetime';
export type DateCellRendererProps = { placeholder?: string; dateFormat?: DateFormats; };
export const DateCellRenderer: FC<DateCellRendererProps & ICellRendererParams> = (props) => {
  const formatList: { [key in DateFormats]: Intl.DateTimeFormatOptions } = {
    date: DateTime.DATE_SHORT,
    time: DateTime.TIME_24_SIMPLE,
    datetime: DateTime.DATETIME_SHORT,
  };
  const dateFormat = props.dateFormat ? formatList[props.dateFormat] : formatList.date;

  const cellValue = getCellValue(props);
  const date = cellValue === 'now' ? DateTime.now() : DateTime.fromISO(cellValue);

  let parsedDate = '-';
  if (date?.isValid) {
    parsedDate = date.toLocaleString(dateFormat);
  } else if (typeof props.placeholder === 'string') {
    parsedDate = props.placeholder;
  }

  return (<span className="tracking-wide">{parsedDate}</span>);
};

export const CheckedCellRenderer: FC<ICellRendererParams> = (props) => {
  const cellValue = getCellValue(props);

  return (
    <div className="flex flex-row h-full justify-center items-center">
      <input type="checkbox" className="cursor-pointer" checked={!!cellValue} onChange={(event) => {
        const checked = event.target.checked;
        overwriteSetCellData(checked, { ...props, colDef: props.colDef!, column: props.column! });
      }} />
    </div>
  );
};

export const CheckedStyledCellRenderer: FC<{ isPointer?: boolean; } & ICellRendererParams> = (props) => {
  const cellValue = getCellValue(props);

  return (
    <div className="flex flex-row justify-center mt-2">
      {!!cellValue && (
        <img className={cs("w-5 h-5", { "cursor-pointer": props.isPointer !== false })} alt="check icon" src={CheckedIcon} />
      )}
    </div>
  );
};

export const RadioCellRenderer: FC<ICellRendererParams> = (props) => {
  const cellValue = !!getCellValue(props);

  function onChange(newValue: boolean) {
    props.api.forEachNode(node => {
      if (props.node.rowIndex === node.rowIndex || !props.colDef?.field || !node.data?.[props.colDef.field]) return;
      node.data[props.colDef.field] = false;
    });

    overwriteSetCellData(newValue, { ...props, colDef: props.colDef!, column: props.column! });
  }

  return (
    <div className="flex flex-row h-full justify-center items-center">
      <input type="radio" className="cursor-pointer" checked={cellValue} onChange={(event) => {
        const checked = event.target.checked;
        onChange(checked);
      }} />
    </div>
  );
};

/**
 * @deprecated The component can be used for small lists of items, but for large lists it is recommended to implement the logic in the back-end.
 */
export const UniqueRadioCellRenderer: FC<ICellRendererParams> = (props) => {
  const cellValue = !!getCellValue(props);

  function onChange(newValue: boolean) {
    props.api.forEachNode(node => {
      if (props.node.rowIndex === node.rowIndex || !props.colDef?.field || !node.data?.[props.colDef.field]) return;
      node.data[props.colDef.field] = false;
      node.data._customDataProps = {};
      node.data._customDataProps.isModified = true;
    });

    overwriteSetCellData(newValue, { ...props, colDef: props.colDef!, column: props.column! });
  }

  return (
    <div className="flex flex-row h-full justify-center items-center">
      <input type="radio" className="cursor-pointer" checked={cellValue} onChange={(event) => {
        const checked = event.target.checked;
        onChange(checked);
      }} />
    </div>
  );
};

type IconCellRendererProps = {
  href?: RenderCallbackFunction;
  alt?: string;
  position?: 'left' | 'right';
};
export const IconCellRenderer: FC<IconCellRendererProps & ICellRendererParams> = (props) => {
  const cellValue = getCellValue(props);
  const href = typeof props.href === 'function' ? props.href(cellValue, props) : props.href ?? cellValue;
  const postition = props.position || 'left';

  return (
    <span>
      {postition === 'left' && <img src={href} alt={props.alt} className="inline mr-2" />}
      <span>{cellValue}</span>
      {postition === 'right' && <img src={href} alt={props.alt} className="inline ml-2" />}
    </span>
  );
};

export type ImageCellRendererProps = {
  href?: RenderCallbackFunction;
  alt?: string;
  placeholder?: string,
  defaultHref?: string;
  shape?: 'square' | 'round';
  icon?: SvgIconComponent;
};
export const ImageCellRenderer: FC<ImageCellRendererProps & ICellRendererParams> = (props) => {
  const cellValue = getCellValue(props);
  const href = typeof props.href === 'function' ? props.href(cellValue, props) : props.href;
  const isRound = props.shape === 'round';

  let render = (
    <div className={`flex justify-center items-center aspect-square h-full m-auto ${isRound && 'rounded-full'} bg-store-primary`}>
      <span className="text-white-500 font-bold text-sm">{props.placeholder}</span>
    </div>
  );
  if (typeof href === 'string' || props.defaultHref) {
    render = (
      <div className={`flex justify-center items-center h-full ${isRound && 'rounded-full'} overflow-hidden`}>
        <img
          className="w-full h-full object-contain text-xs overflow-x-hidden text-ellipsis"
          src={href || props.defaultHref}
          title={props.alt}
          alt={props.alt}
        />
      </div>
    );
  } else if (props.icon) {
    render = (
      <div className="flex justify-center items-center h-full overflow-hidden">
        <props.icon />
      </div>
    );
  }

  return (
    <div className="w-full h-full p-[1px]">
      {render}
    </div>
  );
};

export type FileCellRendererProps = {
  name: string;
  accept: string;
  disabled?: boolean;
};
export const FileCellRenderer: FC<FileCellRendererProps & ICellRendererParams> = (props) => {
  const cellValue = getCellValue(props);

  return (
    <Tooltip title="inactif">
      <div className="w-full h-full p-[1px] flex items-center justify-center">
        <div className={`flex justify-center items-center overflow-hidden relative h-8 w-8`}>
          <div className={`rounded border flex justify-center items-center h-full w-full ${cellValue ? 'text-green-500 border-green-500' : ''}`}>
            {cellValue ? <><FileDownloadDone /></> : <FileDownload />}
          </div>
          <input
            type="file"
            name={props.name}
            accept={props.accept}
            disabled={props.disabled}
            className="h-full w-full opacity-0 cursor-pointer absolute"
          />
        </div>
      </div>
    </Tooltip>
  );
};

export type DeviseCellRendererProps<TData = any, TValue = any> = {
  devise: RenderCallbackFunction<{ nomCourt?: string | null, decPxAchat?: number | null; }, TData, TValue>;
};
export const DeviseCellRenderer: FC<DeviseCellRendererProps & ICellRendererParams> = (props) => {
  let cellValue = getCellValue(props);
  const devise = typeof props.devise === 'function' ? props.devise(cellValue, props) : props.devise;

  if (typeof cellValue === 'number' && typeof devise.decPxAchat === 'number') {
    cellValue = cellValue.toFixed(devise.decPxAchat);
  }

  return (
    <span>{cellValue || 0} {devise.nomCourt || ''}</span>
  );
};

type CodeBarCellRendererProps = { renderClass?: string; };
export const CodeBarCellRenderer: FC<CodeBarCellRendererProps & ICellRendererParams> = (props) => {
  const cellValue = getCellValue(props);

  return (
    <div
      className={`flex items-center h-full p-0.5 ${props.renderClass || ""}`}
    >
      <CodeBar value={cellValue} height={40} displayValue={false} />
    </div>
  );
};

export interface ModalCellRendererChildrenProps<T = unknown> {
  setIsOpen: (v: boolean) => void;
  agGridRow: ICellRendererParams<RowDataType<T>>;
}
export type ModalCellRendererProps = {
  innerRenderer?: FC<ICellRendererParams>,
  innerRendererParams?: {},
  children?: FC<ModalCellRendererChildrenProps>;
};
export const ModalCellRenderer: FC<ModalCellRendererProps & ICellRendererParams> =
  ({ children: Children, innerRenderer: InnerRenderer, innerRendererParams = {}, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (<div className="w-full h-full">
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <>
          {!!Children && <Children agGridRow={props} setIsOpen={setIsOpen} />}
        </>
      </Modal>
      <div
        className="w-full h-full cursor-pointer text-store-primary underline overflow-hidden text-ellipsis"
        onClick={() => setIsOpen(true)}
      >
        {
          InnerRenderer
            ? <InnerRenderer  {...innerRendererParams} {...props} />
            : <span>
              {props.valueFormatted ?? props.value}
            </span>
        }
      </div>
    </div>);
  };

type UpdateDeleteButtonsCellRendererProps = { onClickUpdate?: Function; onClickDelete?: Function; };
export const UpdateDeleteButtonsCellRenderer: FC<UpdateDeleteButtonsCellRendererProps & ICellRendererParams<RowDataType>> =
  (props) => {
    const modalRef = useRef<ConfirmModalRef | null>(null);

    const onClickUpdate = () => {
      props.onClickUpdate?.(props.node.data?.id, props.node.id);
    };

    const onClickDelete = async () => {
      const validate = await modalRef.current!.showModal();
      if (!validate) {
        return;
      }
      props.onClickDelete?.(props.node.data?.id, props.node.id);
    };

    return (
      <div className="h-full flex flex-row justify-center items-center gap-2 text-slate-400">
        {!!props.onClickUpdate && (
          <Tooltip title="Sauvegarder" followCursor>
            <button
              tabIndex={5}
              type="button"
              className="rounded px-2 flex items-center
                        hover:bg-store-primary hover:text-white-500 focus:bg-store-primary focus:text-white-500 transition"
              onClick={onClickUpdate}
            >
              <Edit />
            </button>
          </Tooltip>
        )}
        {!!props.onClickDelete && (
          <Tooltip title="Supprimer" followCursor>
            <button
              tabIndex={5}
              type="button"
              className="rounded px-2 flex items-center
                        hover:bg-red-500 hover:text-white-500 focus:bg-red-500 focus:text-white-500 transition"
              onClick={onClickDelete}
            >
              <Delete />
            </button>
          </Tooltip>
        )}
        <ConfirmModal ref={modalRef} layout={ConfirmEnum.delete} />
      </div>
    );
  };

/* Utils */

function getCellValue(props: ICellRendererParams) {
  const cellValue = props.valueFormatted ?? props.value;
  if (typeof cellValue === 'string')
    return (cellValue ?? '').trim();
  return cellValue;
}