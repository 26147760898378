import {FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import {MarqueViewModel} from "openapi-typescript-codegen";
import {useTranslation} from "react-i18next";
import {marquesApi} from 'api';
import {PropsBlock} from 'components/BlockGrid';
import {useGridController} from "hooks";
import {TextCellRenderer} from "components/AGGride/CellRender";
import MarquesContext from "../MarquesContext";
import BlockGridMarques from "../BlockGridMarques";
import { MarqueViewModelTypoNomenclatureDetail } from "openapi-typescript-codegen/types/models/marque-view-model-typo-nomenclature-detail";
import MarqueModal from "../modals/MarqueModal";

interface Props extends PropsBlock {
    marques: MarqueViewModelTypoNomenclatureDetail[];
    onMarqueChange: (id: number | null) => void;
}

type GridType = MarqueViewModel;

const Marque: FunctionComponent<Props> = ({ marques, onMarqueChange }) => {
    const { t } = useTranslation();
    const { selection, setRefresh } = useContext(MarquesContext);
    const [disableCreate, setDisableCreate] = useState(false);

    const gridController = useGridController<GridType>(
        useMemo(() => ({
            colConfig: {},
            autoFetch: true,
            emptyRowCheck: (rowData) => !rowData.id,
            fetchData: async () => marques.map(({ item }) => item),
            deleteData: (id) => marquesApi.v1MarquesIdDelete(id).then(() => {
                id === selection.marque ? onMarqueChange(null) : setRefresh(true);
            }),
            rowClassRules: {
                "hide-actions": params => {
                    const marque = marques.find(marque => marque.item.id === params.data?.id);
                    return !!(marque && (marque.childrenCount || marque.articleCount));
                },
            },
            columnDefs: [
                {
                    field: 'code',
                    headerName: t('lib_marque_code'),
                    headerTooltip: t('lib_marque_code'),
                    cellRenderer: TextCellRenderer,
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 1
                },
                {
                    field: 'nomMarque',
                    headerName: t('lib_marque_nom_marque'),
                    headerTooltip: t('lib_marque_nom_marque'),
                    cellRenderer: TextCellRenderer,
                    valueFormatter: (params: any) => {
                        const marque = marques.find(marque => marque.item.id === params.data.id);
                        return marque ? `${marque.item.nomMarque} <${marque.childrenCount}>` : String();
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 4
                },
                {
                    headerName: t('lib_marque_nb_articles'),
                    headerTooltip: t('lib_marque_nb_articles'),
                    cellRenderer: TextCellRenderer,
                    valueFormatter: (params: any) => {
                        const marque = marques.find(marque => marque.item.id === params.data.id);
                        return marque ? marque.articleCount.toString() : String();
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 1
                },
            ],
        }), [marques, t])
    );

    useEffect(() => { gridController.fetchData(); }, [marques]);
    useEffect(() => { setDisableCreate(!selection.surMarque); }, [selection.surMarque]);

    return <BlockGridMarques
        label={"tab_detail_marque"}
        gridController={gridController}
        onSelect={onMarqueChange}
        gridName={"marque"}
        disableCreate={disableCreate}
    ><MarqueModal/></BlockGridMarques>;
};

export default Marque;