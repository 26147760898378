import { DateTime } from "luxon";

/*
Get the current DateTime at the beginning of the day.
 */
const getCurrentLocalDate = () => DateTime.local().startOf("day");

/*
Get the current DateTime or convert a date from the ISO format to a DateTime at the beginning of the day.
 */
export const getStartDate = (date?: string) => date ?
  getCurrentLocalDate() : DateTime.local().startOf("day");

/*
Get a date at the 31/12/2999 or convert a date from the ISO format to a DateTime at the end of the day.
 */
export const getEndDate = (date?: string) => date ?
  DateTime.fromISO(date).endOf("day") : DateTime.local(2999, 12, 31).endOf("day");

/*
Get today's date or convert the data to the ISO format.
 */
export const getISOStartDate = (date?: string) => getStartDate(date).toISODate();

/*
Get a date at the 31/12/2999 or convert a date to the ISO format.
 */
export const getISOEndDate = (date?: string) => getEndDate(date).toISODate();

/*
Add days to a date in the ISO format.
 */
export const addDaysToISODate = (date: string, days: number) => DateTime.fromISO(date).plus({ days }).toISODate();

/*
Subtract days to a date in the ISO format.
 */
export const subtractDaysToISODate = (date: string, days: number) => DateTime.fromISO(date).minus({ days }).toISODate();

/*
  Get if a date is in the past, today or in the future.
 */
export const checkDate = {
    isFuture: (date: string) => getStartDate(date) > getCurrentLocalDate(),
    isToday: (date: string) => getStartDate(date).hasSame(getCurrentLocalDate(), "day"),
    isPast: (date: string) => getStartDate(date) < getCurrentLocalDate()
}