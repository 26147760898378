import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom'

import { useProtection } from 'hooks';

// function ProtectedRoute({ component: Component, ...restOfProps }) {
//   const isAuthenticated = localStorage.getItem("isAuthenticated")

//     < Route element = {< ProtectedRoute isAllowed = {!!user
// } />}>
//   < Route path = "home" element = {< Home />} />
//     < Route path = "dashboard" element = {< Dashboard />} />
//       < /Route>

// return (
//   <Route
//       { ...restOfProps }
//       render = {(props) =>
// isAuthenticated ? <Component { ...props } /> : <Redirect to="/signin" />
//       }
// />
//   )
// }

interface Props { needAuth: boolean, children?: ReactNode }

const ProtectedRoute: FC<Props> = ({ needAuth = true, children }) => {
  const isBlocked = useProtection((auth) => needAuth && (!auth.isLoggedIn || auth.isExpired))

  if (isBlocked) {
    return (<></>)
  }
  return (
    <>
      {!!children ? children : <Outlet />}
    </>
  )
};

export default ProtectedRoute