import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import { env } from "./utils";

const BASE_URL_TRANSLATION = env.BACK_TRANSLATION_URL;

export const defaultNS = 'translation';

export function i18nInit() {
  i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
      debug: false,
      lng: "fr",
      fallbackLng: "fr",
      ns: defaultNS,
      defaultNS: defaultNS,
      keySeparator: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: {
        loadPath: BASE_URL_TRANSLATION ? `${BASE_URL_TRANSLATION}/v1/Cultures/fr_FR/Translations` : '',
      },
    });
}
