import { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { useTranslation } from "react-i18next";

import { fournisseursApi, circuitLogistiquesApi, cadencierFournisseursApi, fluxFournisseursApi } from 'api';
import {
  CadencierFournisseurInsertionModel,
  CadencierFournisseurViewModel, CadencierNomenclatureInsertionModel, CircuitLogistiqueInsertionModel,
  CircuitLogistiqueInsertionModelTriDocEnum,
  CircuitLogistiqueViewModel,
  CircuitLogistiqueWithCadenciersViewModel, FluxFournisseurInsertionModel
} from "openapi-typescript-codegen";

import { useFournisseur } from "context/Referencement";

import DivisionGrid from 'components/BlockGrid/DivisionGrid';
import ModeApprovisionnement from 'components/Fournisseur/TabCircuit/ModeApprovisionnement';
import IdentificationCircuit from 'components/Fournisseur/TabCircuit/Identification';
import Temperature from 'components/Fournisseur/TabCircuit/Temperature';
import Impression from 'components/Fournisseur/TabCircuit/Impression';
import CodeCadencier from 'components/Fournisseur/TabCircuit/Cadencier/CodeCadencier';
import Flux from 'components/Fournisseur/TabCircuit/Flux';
import Transport from 'components/Fournisseur/TabCircuit/Transport';
import LateralNavigation from 'components/Fournisseur/LateralNavigation';
import NavigationCadencierItem from 'components/Fournisseur/TabCircuit/Navigation/NavigationCadencierItem';
import CreationCircuitsLN from 'components/Fournisseur/TabCircuit/Navigation/Interaction/CreationCircuitLN';
import DeletionItemLN from "components/Fournisseur/LateralNavigation/Interaction/DeletionItemLN";
import PlanningCommande from "components/Fournisseur/TabCircuit/Cadencier/PlanningCommande";

import { showPrettyError, toast } from "utils";

interface Props { }

const TabCircuit: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const [circuits, setCircuits] = useState<CircuitLogistiqueWithCadenciersViewModel[]>([]);
  const [cadenciers, setCadenciers] = useState<CadencierFournisseurViewModel[]>([]);
  const [identifiers, setIdentifiers] = useState<{ circuit: number, cadencier: number; }>({ circuit: 0, cadencier: 0 });
  const [circuitCanBeDeleted, setCircuitCanBeDeleted] = useState<boolean>(false);
  const [cadencierCanBeDeleted, setCadencierCanBeDeleted] = useState<boolean>(false);
  const { state: fournisseur } = useFournisseur();

  const selectedCircuit = useMemo(
    () => circuits.find(circuit => circuit.id === identifiers.circuit),
    [circuits, identifiers.circuit]
  );

  const selectedCadencier = useMemo(
    () => cadenciers.find(cadencier => cadencier.id === identifiers.cadencier),
    [cadenciers, identifiers.cadencier]
  );

  const refreshCircuits = async (reset?: boolean) => {
    if (fournisseur?.id) {
      try {
        let { data } = await fournisseursApi.v1FournisseursIdCircuitLogistiquesCadenciersGet(fournisseur.id);
        // Sort data by code:
        data = data.sort((circuit$1, circuit$2) => circuit$1.code?.localeCompare(circuit$2.code || '') || 0);
        setCircuits(data);
        // The selection, through the identifiers and the useEffect, is reset only if asked:
        reset && setIdentifiers({ circuit: data[0]?.id || 0, cadencier: 0 });
      } catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    }
  };

  useEffect(() => {
    refreshCircuits(true);
  }, []);

  useEffect(() => {
    refreshCircuits(true);
  }, [fournisseur?.id]);

  useEffect(() => {
    const $cadenciers = selectedCircuit?.cadencierFournisseurs?.sort(
      (cadencier$1, cadencier$2) =>
        cadencier$1.code?.localeCompare(cadencier$2.code || '') || 0) || [];
    setCircuitCanBeDeleted(!$cadenciers.length);
    identifiers.circuit && setCadenciers($cadenciers);
  }, [selectedCircuit?.cadencierFournisseurs]);

  const handleSelectCircuit = (id: number) => {
    setIdentifiers({ circuit: id, cadencier: 0 });
  };

  const handleSelectCadencier = (id: number) => {
    setIdentifiers({ ...identifiers, cadencier: id });
  };

  const handleCreateCircuit = async (circuit: CircuitLogistiqueInsertionModel, flux: FluxFournisseurInsertionModel) => {
    circuit.fkFournisseur = fournisseur?.id as number;
    // Default value:
    circuit.triDoc = CircuitLogistiqueInsertionModelTriDocEnum.Article;
    try {
      const { data } = await circuitLogistiquesApi.v1CircuitLogistiquesPost(circuit);
      if (data.id) {
        flux.fkCircuitLogistique = data.id;
        await fluxFournisseursApi.v1FluxFournisseursPost(flux);
      }
      toast.success(t('mes_validation_modification'));
      refreshCircuits().then(() => { setIdentifiers({ ...identifiers, circuit: data.id || 0 }); });
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  };

  const handleUpdateCircuit = async (circuit: CircuitLogistiqueViewModel) => {
    try {
      await circuitLogistiquesApi.v1CircuitLogistiquesPut(circuit);
      toast.success(t('mes_validation_modification'));
      // Refresh the circuits:
      await refreshCircuits();
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  };

  const handleDeleteCircuit = async (id: number) => {
    try {
      await circuitLogistiquesApi.v1CircuitLogistiquesIdDelete(id);
      toast.success(t('mes_validation_suppression'));
      await refreshCircuits(true);
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  };

  const handleCreateCadencier = async (cadencier: CadencierFournisseurInsertionModel, sites: number[], nomenclature?: CadencierNomenclatureInsertionModel) => {
    try {
      const $cadencier = {
        ...cadencier,
        fkCircuitLogistique: selectedCircuit?.id!,
        fkSites: sites,
        flActif: true,
      };
      // The nomenclature is only provided if the EnumOrderCadenceType is "Nomenclature":
      nomenclature && ($cadencier.cadencierNomenclature = nomenclature);
      const { data } = await cadencierFournisseursApi.v1CadencierFournisseursPost($cadencier);
      toast.success(t('mes_validation_creation'));
      await refreshCircuits().then(() => setIdentifiers({ ...identifiers, cadencier: data.id || 0 }));
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  };

  const handleUpdateCadencier = async (cadencier: CadencierFournisseurViewModel) => {
    try {
      await cadencierFournisseursApi.v1CadencierFournisseursPut(cadencier);
      toast.success(t('mes_validation_modification'));
      // Refresh the circuits, including the cadenciers:
      await refreshCircuits();
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  };

  const handleDeleteCadencier = async (id: number) => {
    try {
      await cadencierFournisseursApi.v1CadencierFournisseursIdDelete(id);
      toast.success(t('mes_validation_suppression'));
      setIdentifiers({ ...identifiers, cadencier: 0 });
      await refreshCircuits();
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  };

  return (
    <div id="circuit" className="h-full flex flex-wrap justify-between bg-grey-150 gap-px p-px">
      <LateralNavigation
        dataSet={circuits}
        selectedItem={selectedCircuit}
        onSelectItem={handleSelectCircuit}
        createItemButton={<CreationCircuitsLN onCreate={handleCreateCircuit} />}
        deleteItemButton={
          <DeletionItemLN
            selectedItem={selectedCircuit}
            canBeDeleted={circuitCanBeDeleted}
            onDeleteItem={handleDeleteCircuit}
          />
        }
        width={'280px'}
        display={{
          code: 'code',
          name: 'nomCircuit',
          type: 'nomCircuit'
        }}
      >
        <NavigationCadencierItem
          selectedCircuit={selectedCircuit}
          selectedCadencier={selectedCadencier}
          onSelectCadencier={handleSelectCadencier}
          onCreateCadencier={handleCreateCadencier}
          onDeleteCadencier={handleDeleteCadencier}
          canBeDeleted={cadencierCanBeDeleted}
        />
      </LateralNavigation>
      {!!identifiers.circuit &&
        <>
          {identifiers.cadencier ?
            <DivisionGrid key={identifiers.cadencier} width={'calc(100% - 280px)'}>
              <DivisionGrid sm={100} md={100} lg={100} xl={100} doubleXl={100} classNameAdd={"h-fit"}>
                <CodeCadencier selectedCadencier={selectedCadencier as CadencierFournisseurViewModel} onUpdateCadencier={handleUpdateCadencier} />
                <PlanningCommande selectedCadencier={selectedCadencier as CadencierFournisseurViewModel} setCadencierCanBeDeleted={setCadencierCanBeDeleted} />
              </DivisionGrid>
            </DivisionGrid> :
            <DivisionGrid key={identifiers.circuit} width={'calc(100% - 280px)'}>
              <DivisionGrid sm={100} md={100} lg={100} xl={100} doubleXl={100}>
                <IdentificationCircuit sm={100} md={50} lg={50} xl={50} doubleXl={25} selectedCircuit={selectedCircuit as CircuitLogistiqueViewModel} onUpdateCircuit={handleUpdateCircuit} />
                <Flux sm={100} md={50} lg={50} xl={50} doubleXl={40} identifier={identifiers.circuit} />
                <Transport sm={100} md={100} lg={100} xl={100} doubleXl={25} selectedCircuit={selectedCircuit as CircuitLogistiqueViewModel} onUpdateCircuit={handleUpdateCircuit} />
              </DivisionGrid>
              <ModeApprovisionnement sm={100} md={100} lg={100} xl={60} doubleXl={60} selectedCircuit={selectedCircuit as CircuitLogistiqueViewModel} onUpdateCircuit={handleUpdateCircuit} />
              <DivisionGrid sm={100} md={100} lg={100} xl={40} doubleXl={40}>
                <Temperature sm={100} md={100} lg={50} xl={100} doubleXl={100} identifier={identifiers.circuit} />
                <Impression sm={100} md={100} lg={50} xl={100} doubleXl={100} selectedCircuit={selectedCircuit as CircuitLogistiqueViewModel} onUpdateCircuit={handleUpdateCircuit} />
              </DivisionGrid>
            </DivisionGrid>
          }
        </>
      }

    </div>
  );
};

export default TabCircuit;
