import { FC, ReactNode, createContext, useContext, useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { generateRandomUint } from 'utils';

export enum UpdateEnum {
  'nomenclature',
  'designation',
  'marque',
  'caisse',
  'produit',
  'ean',
  'listeArticlePereFils',
  'approvisionnement',
  'taxesAchat',
  'prixVente',
  'taxesVente',
  'logistique',
  'nomenclatureDouaniere',
  'donneesExport',
  'dangereux',
  'constituants',
  'traitementsChimiques',
  'certificats',
  'agrements',
  'patch'
}

type callbackEvent = (evt: UpdateEnum) => void;
type callbackPool = { [key: string]: callbackEvent | undefined; };

const UpdateArticleContext = createContext<{ triggerEvent: callbackEvent, setListener: Dispatch<SetStateAction<callbackPool>>; } | undefined>(undefined);

const UpdateArticleProvider: FC<{ children?: ReactNode; }> = ({ children }) => {
  const [listens, setListener] = useState<callbackPool>({});

  const triggerEvent: callbackEvent = async (event) => {
    for (const key in listens) {
      const callback = listens[key];
      if (!callback) continue;
      callback(event);
    }
  };
  const value = { triggerEvent, setListener };

  return <UpdateArticleContext.Provider value={value}>{children}</UpdateArticleContext.Provider>;
};

function useUpdateArticle(onEvent?: callbackEvent) {
  const context = useContext(UpdateArticleContext);
  if (context === undefined) {
    throw new Error('useArticle must be used within a UpdateArticleProvider');
  }
  const listenerIndex = useRef(generateRandomUint());

  useEffect(() => {
    if (onEvent) {
      context.setListener((prevState) => ({ ...prevState, [listenerIndex.current]: onEvent }));
    }
    return () => {
      context.setListener((prevState) => ({ ...prevState, [listenerIndex.current]: undefined }));
    };
  }, [onEvent]);

  return {
    triggerEvent: context.triggerEvent
  };
}

export { UpdateArticleProvider, useUpdateArticle };
