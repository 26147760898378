import { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'utils';

import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import SelectComplete, { SelectTemplate } from 'components/SelectComplete';

import { articlesApi, reglementairesApi, paysApi, techNatureExportsApi, techOriginesApi } from 'api';
import {ReglementaireInsertionModel, ReglementaireViewModel} from 'openapi-typescript-codegen';

import { useTranslation } from "react-i18next";
import { useArticle, useCompletudeArticle, useUpdateArticle } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateArticle';
import { CheckInput, isMissing } from 'utils/Article/completude';

const defaultImage = "/assets/images/default.png";

const DonnesExport: FunctionComponent<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const uA = useUpdateArticle();
  const { state: controled } = useCompletudeArticle();
  const [completude, setCompletude] = useState<number>(0);
  const [activate, setActivate] = useState<boolean>(false);
  const [hasFocus, setFocus] = useState(false);
  const [form, setForm] = useState<Partial<ReglementaireViewModel>>({});
  const [loading, setLoader] = useState<boolean>(true);
  const [pays, setPays] = useState<SelectTemplate[]>([]);
  const [natureExports, setNatureExports] = useState<SelectTemplate[]>([]);
  const [origines, setOrigines] = useState<SelectTemplate[]>([]);

  const [searchPays, setSearchPays] = useState<string>('');
  const [searchNatureExport, setSearchNatureExport] = useState<string>('');
  const [searchOrigine, setSearchOrigine] = useState<string>('');

  function checkCompletude() {
    let tmp = 0;
    if (isMissing(form.ficheSecurite ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.fiche_securite'))) tmp++;
    if (isMissing(form.libelleInternational ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.libelle_internation'))) tmp++;
    if (isMissing(form.normeInco ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.norme_inco'))) tmp++;
    if (isMissing(form.labels ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.labels'))) tmp++;
    if (isMissing(form.conditionStockage ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.condition_stockage'))) tmp++;
    if (isMissing(form.conditionEnergetique ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.condition_energetique'))) tmp++;
    if (isMissing(form.fkPays ?? null, controled?.find((i) => i.colonneArticle === 'reglementaire.fk_pays'))) tmp++;
    if (isMissing(form.fkTechNature ?? null, controled?.find((i) => i.colonneArticle === 'reglementaire.nature_export'))) tmp++;
    if (isMissing(form.fkTechOrigine ?? null, controled?.find((i) => i.colonneArticle === 'reglementaire.origine'))) tmp++;
    if (isMissing(form.typologie ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.typologie'))) tmp++;
    setCompletude(tmp);
  }

  const fetchGetData = async (id: number) => {
    const response = await articlesApi.v1ArticlesIdReglementairesGet(id);

    if (response.status >= 200 && response.status < 300) setLoader(false);
    if (response.data.length !== 0) {
      setForm(response.data[0]);
      if (response.data[0].fkPays) fetchInitPays(response.data[0].fkPays);
      if (response.data[0].fkTechNature) fetchInitNatureExp(response.data[0].fkTechNature);
      if (response.data[0].fkTechOrigine) fetchInitOrigines(response.data[0].fkTechOrigine);
    } else {
      setForm({
        fkArticle: id,
        fkPays: null,
        fkTechNature: null,
        fkTechOrigine: null
      });
    }
  };

  const fetchInitPays = async (id: number) => {
    const { data: response } = await paysApi.v1PaysIdGet(id);
    const { data: responsePlus } = await paysApi.v1PaysSearchGet('', 1, 1000);

    let tmp: SelectTemplate[] = [];
    if (responsePlus.data && responsePlus.data.length > 0) {
      tmp = [
        { value: null, label: "..." },
        { ...response, value: response.id, label: response.codePaysAlpha2 + " - " + response.nomPays },
        ...responsePlus.data.filter((item) => item.id !== response.id).map(
          (item) => ({ value: item.id, label: item.codePaysAlpha2 + " - " + item.nomPays })
        )
      ];
    }
    setPays(tmp);
  };

  const fetchInitNatureExp = async (id: number) => {
    const { data: response } = await techNatureExportsApi.v1TechNatureExportsIdGet(id);
    const { data: responsePlus } = await techNatureExportsApi.v1TechNatureExportsSearchGet('', 1, 1000);

    let tmp: SelectTemplate[] = [];
    if (responsePlus.data && responsePlus.data.length > 0) {
      tmp = [
        { value: null, label: "..." },
        { ...response, value: response.id, label: response.code + " - " + response.nomTechNatureExport },
        ...responsePlus.data.filter((item) => item.id !== response.id).map(
          (item) => ({ value: item.id, label: item.code + " - " + item.nomTechNatureExport })
        )
      ];
    }
    setNatureExports(tmp);
  };

  const fetchInitOrigines = async (id: number) => {
    const { data: response } = await techOriginesApi.v1TechOriginesIdGet(id);
    const { data: responsePlus } = await techOriginesApi.v1TechOriginesSearchGet('', 1, 1000);

    let tmp: SelectTemplate[] = [];
    if (responsePlus.data && responsePlus.data.length > 0) {
      tmp = [
        { value: null, label: "..." },
        { ...response, value: response.id, label: response.code + " - " + response.nomTechOrigine },
        ...responsePlus.data.filter((item) => item.id !== response.id).map(
          (item) => ({ value: item.id, label: item.code + " - " + item.nomTechOrigine })
        )
      ];
    }
    setOrigines(tmp);
  };

  const fetchUpdateData = async (id?: number) => {
    let response;

    if (!id) response = await reglementairesApi.v1ReglementairesPost(form as ReglementaireInsertionModel);
    else response = await reglementairesApi.v1ReglementairesPut(form as ReglementaireViewModel);
    if (response.status >= 200 && response.status < 300) { //success
      toast.success(t('mes_validation_modification'));
      uA.triggerEvent(UpdateEnum.donneesExport);
      // reset dat with response.data ?
      setActivate(false);
    }
    else toast.error(t('mes_erreur_technique') + ": " + response.status);
  };

  const fetchGetOrigines = async (search: string) => {
    const { data: { data } } = await techOriginesApi.v1TechOriginesSearchGet(search, 1, 1000);
    const tmp: SelectTemplate[] = (data || []).map(
      (item) => ({ value: item.id, label: item.code + " - " + item.nomTechOrigine })
    );

    const needSelect = !!form.fkTechOrigine && (data || []).every(val => val.id !== form.fkTechOrigine);
    if (!!form.fkTechOrigine && needSelect) {
      const { data: responsePlus } = await techOriginesApi.v1TechOriginesIdGet(form.fkTechOrigine);
      tmp.unshift({
        value: responsePlus?.id, label: responsePlus?.code + " - " + responsePlus?.nomTechOrigine
      });
    }

    tmp.unshift({ value: null, label: "..." });
    setOrigines(tmp);
  };

  useEffect(() => {
    fetchGetOrigines(searchOrigine);
  }, [searchOrigine]);

  const fetchGetNatureExp = async (search: string) => {
    const { data: { data } } = await techNatureExportsApi.v1TechNatureExportsSearchGet(search, 1, 1000);
    const tmp: SelectTemplate[] = (data || []).map(
      (item) => ({ value: item.id, label: item.code + " - " + item.nomTechNatureExport })
    );

    const needSelect = !!form.fkTechNature && (data || []).every(val => val.id !== form.fkTechNature);
    if (!!form.fkTechNature && needSelect) {
      const { data: responsePlus } = await techNatureExportsApi.v1TechNatureExportsIdGet(form.fkTechNature);
      tmp.unshift({
        value: responsePlus?.id, label: responsePlus?.code + " - " + responsePlus?.nomTechNatureExport
      });
    }

    tmp.unshift({ value: null, label: "..." });
    setNatureExports(tmp);
  };

  useEffect(() => {
    fetchGetNatureExp(searchNatureExport);
  }, [searchNatureExport]);

  const fetchGetPays = async (search: string) => {
    const { data: { data } } = await paysApi.v1PaysSearchPost(true, 1, 1000, { nomPays: search, codePaysAlpha2: search, nomIso: search });
    const tmp: SelectTemplate[] = (data || []).map(
      (item) => ({ value: item.id, label: item.codePaysAlpha2 + " - " + item.nomPays })
    );

    const needSelect = !!form.fkPays && (data || []).every(val => val.id !== form.fkPays);
    if (!!form.fkPays && needSelect) {
      const { data: responsePlus } = await paysApi.v1PaysIdGet(form.fkPays);
      tmp.unshift({
        value: responsePlus?.id, label: responsePlus?.codePaysAlpha2 + " - " + responsePlus?.nomPays
      });
    }

    tmp.unshift({ value: null, label: "..." });
    setPays(tmp);
  };

  useEffect(() => {
    fetchGetPays(searchPays);
  }, [searchPays]);

  useEffect(() => {
    checkCompletude();
  }, [form]);

  useEffect(() => {
    if (article?.id) fetchGetData(article.id);
  }, [article?.id]);

  function validator() {
    let isValid = true;
    if (!CheckInput(form.ficheSecurite ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.fiche_securite'), 'lib_reglementaire_fiche_securite')) isValid = false;
    if (!CheckInput(form.libelleInternational ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.libelle_internation'), 'lib_reglementaire_libelle_internation')) isValid = false;
    if (!CheckInput(form.normeInco ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.norme_inco'), 'lib_reglementaire_norme_inco')) isValid = false;
    if (!CheckInput(form.labels ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.labels'), 'lib_reglementaire_labels')) isValid = false;
    if (!CheckInput(form.conditionStockage ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.condition_stockage'), 'lib_reglementaire_condition_stockage')) isValid = false;
    if (!CheckInput(form.conditionEnergetique ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.condition_energetique'), 'lib_reglementaire_condition_energetique')) isValid = false;
    if (!CheckInput(form.fkPays ?? null, controled?.find((i) => i.colonneArticle === 'reglementaire.fk_pays'), 'lib_reglementaire_fk_pays')) isValid = false;
    if (!CheckInput(form.fkTechNature ?? null, controled?.find((i) => i.colonneArticle === 'reglementaire.nature_export'), 'lib_reglementaire_nature_export')) isValid = false;
    if (!CheckInput(form.fkTechOrigine ?? null, controled?.find((i) => i.colonneArticle === 'reglementaire.origine'), 'lib_reglementaire_origine')) isValid = false;
    if (!CheckInput(form.typologie ?? '', controled?.find((i) => i.colonneArticle === 'reglementaire.typologie'), 'lib_reglementaire_typologie')) isValid = false;
    return isValid;
  }

  function handleUpdate() {
    if (validator()) fetchUpdateData(form.id);
  }

  function handleCancel() {
    if (article?.id) fetchGetData(article.id);
    setActivate(false);
  }

  function handleFocus(focus: boolean) {
    setFocus(focus);
  }

  return (
    <BlockGrid
      title={t('tab_article_export')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      anchor="donnees-export-article"
      img={defaultImage}
      completude={completude}
      loading={loading}
      handleClick={(res: boolean) => setActivate(res)}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      handleFocus={handleFocus}
    >
      <form className="flex flex-wrap">
        <div className="w-full flex gap-2">
          <label className="block mb-2 w-1/2 flex-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_reglementaire_libelle_internation')}
                {controled?.find((i) => i.colonneArticle === 'reglementaire.libelle_international')?.required && <span className="text-red-500">**</span>}
                {controled?.find((i) => i.colonneArticle === 'reglementaire.libelle_international')?.recommended && <span className="text-orange-500">*</span>}
              </span>
            </span>
            <input type="text" tabIndex={5}
              value={form.libelleInternational?.toString() ?? ''}
              onChange={e => setForm({ ...form, libelleInternational: e.currentTarget.value })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
                ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.libelle_international')?.required && form.libelleInternational === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.libelle_international')?.recommended && form.libelleInternational === '') && 'border-orange-500 bg-orange-500/10'}
              `} />
          </label>
          <label className="block mb-2 w-1/2 flex-1">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_reglementaire_fiche_securite')}
                {controled?.find((i) => i.colonneArticle === 'reglementaire.fiche_securite')?.required && <span className="text-red-500">**</span>}
                {controled?.find((i) => i.colonneArticle === 'reglementaire.fiche_securite')?.recommended && <span className="text-orange-500">*</span>}
              </span>
            </span>
            <input type="text" tabIndex={5}
              value={form.ficheSecurite?.toString() ?? ''}
              onChange={e => setForm({ ...form, ficheSecurite: e.currentTarget.value })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
                ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.fiche_securite')?.required && form.ficheSecurite === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.fiche_securite')?.recommended && form.ficheSecurite === '') && 'border-orange-500 bg-orange-500/10'}
              `} />
          </label>
        </div>
        <div className="w-full flex gap-2">
          <div className="block w-1/2 flex-1">
            <label className="block mb-2">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t('lib_reglementaire_commentaire1')}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.commentaire1')?.required && <span className="text-red-500">**</span>}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.commentaire1')?.recommended && <span className="text-orange-500">*</span>}
                </span>
              </span>
              <textarea tabIndex={5}
                onChange={e => setForm({ ...form, commentaire1: e.currentTarget.value })}
                disabled={!activate}
                className={`mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.commentaire1')?.required && form.commentaire1 === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.commentaire1')?.recommended && form.commentaire1 === '') && 'border-orange-500 bg-orange-500/10'}
                `}
                style={{ height: '156px' }}
                value={form.commentaire1?.toString() ?? ''} />
            </label>
          </div>
          <div className="block w-1/2 flex-1">
            <label className="block mb-2">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t('lib_reglementaire_norme_inco')}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.norme_inco')?.required && <span className="text-red-500">**</span>}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.norme_inco')?.recommended && <span className="text-orange-500">*</span>}
                </span>
              </span>
              <input type="text" tabIndex={5}
                value={form.normeInco?.toString() ?? ''}
                onChange={e => setForm({ ...form, normeInco: e.currentTarget.value })}
                disabled={!activate}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.norme_inco')?.required && form.normeInco === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.norme_inco')?.recommended && form.normeInco === '') && 'border-orange-500 bg-orange-500/10'}
                `} />
            </label>
            <label className="block mb-2">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t('lib_reglementaire_fk_pays')}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.fk_pays')?.required && <span className="text-red-500">**</span>}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.fk_pays')?.recommended && <span className="text-orange-500">*</span>}
                </span>
              </span>
              <SelectComplete tabIndex={5}
                isDisabled={!activate}
                options={pays}
                value={pays.filter(option => {
                  return option.value === form.fkPays;
                })}
                onChange={(e: any): void => {
                  setForm({ ...form, fkPays: e.value });
                  setSearchPays(' ');
                }}
                onInputChange={(e: any): void => setSearchPays(e)}
                classNameAdd={`
                  ${!!loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.fk_pays')?.required && form.fkPays === null) && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.fk_pays')?.recommended && form.fkPays === null) && 'border-orange-500 bg-orange-500/10'}
                `}
              />
            </label>
            <label className="block mb-2">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t('lib_reglementaire_nature_export')}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.nature_export')?.required && <span className="text-red-500">**</span>}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.nature_export')?.recommended && <span className="text-orange-500">*</span>}
                </span>
              </span>
              <SelectComplete tabIndex={5}
                isDisabled={!activate}
                options={natureExports}
                value={natureExports.filter(option => {
                  return option.value === form.fkTechNature;
                })}
                onChange={(e: any): void => {
                  setForm({ ...form, fkTechNature: e.value });
                  setSearchNatureExport(' ');
                }}
                onInputChange={(e: any): void => setSearchNatureExport(e)}
                classNameAdd={`
                  ${!!loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.nature_export')?.required && form.fkTechNature === null) && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.nature_export')?.recommended && form.fkTechNature === null) && 'border-orange-500 bg-orange-500/10'}
                `}
              />
            </label>

          </div>
        </div>
        <div className="w-full flex gap-2">
          <div className="block w-1/2 flex-1">
            <div className="flex mb-2">
              <label className="block flex-1">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_reglementaire_condition_stockage')}
                    {controled?.find((i) => i.colonneArticle === 'reglementaire.condition_stockage')?.required && <span className="text-red-500">**</span>}
                    {controled?.find((i) => i.colonneArticle === 'reglementaire.condition_stockage')?.recommended && <span className="text-orange-500">*</span>}
                  </span>
                </span>
                <input type="text" tabIndex={5}
                  value={form.conditionStockage?.toString() ?? ''}
                  onChange={e => setForm({ ...form, conditionStockage: e.currentTarget.value })}
                  disabled={!activate}
                  className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                    disabled:border-slate-200 disabled:bg-white-500
                    focus:border-store-primary
                    ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                    ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.condition_stockage')?.required && form.conditionStockage === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                    ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.condition_stockage')?.recommended && form.conditionStockage === '') && 'border-orange-500 bg-orange-500/10'}
                  `} />
              </label>
            </div>
            <div className="flex mb-2">
              <label className="block flex-1">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_reglementaire_condition_energetique')}
                    {controled?.find((i) => i.colonneArticle === 'reglementaire.condition_energetique')?.required && <span className="text-red-500">**</span>}
                    {controled?.find((i) => i.colonneArticle === 'reglementaire.condition_energetique')?.recommended && <span className="text-orange-500">*</span>}
                  </span>
                </span>
                <input type="text" tabIndex={5}
                  value={form.conditionEnergetique?.toString() ?? ''}
                  onChange={e => setForm({ ...form, conditionEnergetique: e.currentTarget.value })}
                  disabled={!activate}
                  className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                    disabled:border-slate-200 disabled:bg-white-500
                    focus:border-store-primary
                    ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                    ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.condition_energetique')?.required && form.conditionEnergetique === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                    ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.condition_energetique')?.recommended && form.conditionEnergetique === '') && 'border-orange-500 bg-orange-500/10'}
                  `} />
              </label>
            </div>
            <div className="flex mb-2">
              <label className="block flex-1">
                <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                  <span>
                    {t('lib_reglementaire_labels')}
                    {controled?.find((i) => i.colonneArticle === 'reglementaire.labels')?.required && <span className="text-red-500">**</span>}
                    {controled?.find((i) => i.colonneArticle === 'reglementaire.labels')?.recommended && <span className="text-orange-500">*</span>}
                  </span>
                </span>
                <input type="text" tabIndex={5}
                  value={form.labels?.toString() ?? ''}
                  onChange={e => setForm({ ...form, labels: e.currentTarget.value })}
                  disabled={!activate}
                  className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                    disabled:border-slate-200 disabled:bg-white-500
                    focus:border-store-primary
                    ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                    ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.labels')?.required && form.normeInco === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                    ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.labels')?.recommended && form.normeInco === '') && 'border-orange-500 bg-orange-500/10'}
                  `} />
              </label>
            </div>

          </div>
          <div className="block w-1/2 flex-1">
            <label className="block mb-2">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t('lib_reglementaire_typologie')}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.typologie')?.required && <span className="text-red-500">**</span>}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.typologie')?.recommended && <span className="text-orange-500">*</span>}
                </span>
              </span>
              <input type="text" tabIndex={5}
                value={form.typologie?.toString() ?? ''}
                onChange={e => setForm({ ...form, typologie: e.currentTarget.value })}
                disabled={!activate}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.typologie')?.required && form.typologie === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.typologie')?.recommended && form.typologie === '') && 'border-orange-500 bg-orange-500/10'}
                `} />
            </label>
            <label className="block mb-2">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t('lib_reglementaire_origine')}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.origine')?.required && <span className="text-red-500">**</span>}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.origine')?.recommended && <span className="text-orange-500">*</span>}
                </span>
              </span>
              <SelectComplete tabIndex={5}
                isDisabled={!activate}
                options={origines}
                value={origines.filter(option => {
                  return option.value === form.fkTechOrigine;
                })}
                onChange={(e: any): void => {
                  setForm({ ...form, fkTechOrigine: e.value });
                  setSearchOrigine(' ');
                }}
                onInputChange={(e: any): void => setSearchOrigine(e)}
                classNameAdd={`
                  ${!!loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.origine')?.required && form.fkTechOrigine === null) && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.origine')?.recommended && form.fkTechOrigine === null) && 'border-orange-500 bg-orange-500/10'}
                `}
              />
            </label>
            <label className="block mb-2">
              <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                <span>
                  {t('lib_reglementaire_indice_reparabilite')}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.indice_reparabilite')?.required && <span className="text-red-500">**</span>}
                  {controled?.find((i) => i.colonneArticle === 'reglementaire.indice_reparabilite')?.recommended && <span className="text-orange-500">*</span>}
                </span>
              </span>
              <input type="text" tabIndex={5}
                value={form.indiceReparabilite?.toString() ?? ''}
                onChange={e => setForm({ ...form, indiceReparabilite: e.currentTarget.value })}
                disabled={!activate}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.indice_reparabilite')?.required && form.indiceReparabilite === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                  ${!!(controled?.find((i) => i.colonneArticle === 'reglementaire.indice_reparabilite')?.recommended && form.indiceReparabilite === '') && 'border-orange-500 bg-orange-500/10'}
                `} />
            </label>
          </div>
        </div>
      </form>
    </BlockGrid>
  );
};

export default DonnesExport;