import { FC, ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { EnseigneViewModel } from "openapi-typescript-codegen";
import { useSite } from 'context/site';

import { enseignesApi, siteEnseignesApi } from "api";

type State = EnseigneViewModel | null;
type Dispatch = (newState: State) => void;

const EnseigneContext = createContext<{ state: State; dispatch: Dispatch; } | undefined>(undefined);

const EnseigneProvider: FC<{ children?: ReactNode; }> = ({ children }) => {
  const [state, dispatch] = useState<State>(null);
  const value = { state, dispatch };
  const { state: site } = useSite();

  useEffect(() => {
    if (site?.id) fetchEnseigne(site.id);
  }, [site?.id]);

  async function fetchEnseigne(siteId: number) {
    const { data: { data: siteEnseignes } } = await siteEnseignesApi.v1SiteEnseignesSearchPost(false, 1, 1, { fkSite: siteId, flPrincipal: true, });
    const siteEnseigne = siteEnseignes?.[0];
    if (!siteEnseigne?.fkEnseigne) return;
    const { data: enseigne } = await enseignesApi.v1EnseignesIdGet(siteEnseigne.fkEnseigne);
    dispatch(enseigne);
  }

  return <EnseigneContext.Provider value={value}>{children}</EnseigneContext.Provider>;
};

function useEnseigne() {
  const context = useContext(EnseigneContext);
  if (context === undefined) {
    throw new Error('useEnseigne must be used within a EnseigneProvider');
  }

  return context;
};

export { EnseigneProvider, useEnseigne };