import { FC, useMemo, useState } from 'react';
import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import { Title } from "components/H2Title";
import { UniteMesureInsertionModel, UniteMesureViewModel } from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { notEmptyCellValidator, notNilCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import { uniteMesuresApi } from 'api';
import BlockGrid, { PropsBlock, LayoutEnum } from 'components/BlockGrid';
import { TextCellRenderer, NumberCellRenderer } from 'components/AGGride/CellRender';
import { NumberCellEditor, NumberCellEditorProps, TextCellEditor } from "components/AGGride/CellEditor";
import { useGridController } from "hooks";
import { CheckBoxColumnDef } from "../../components/AGGride/columnDef";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

type GridType = Partial<UniteMesureViewModel & UniteMesureInsertionModel>;

const UniteMesures: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.code,
      fetchData: async () => {
        const { data: { data } } = await uniteMesuresApi.v1UniteMesuresGet(1, 1000);
        return data.sort((a, b) => a.code.localeCompare(b.code));
      },
      postData: (cleanRow) => { return uniteMesuresApi.v1UniteMesuresPost(cleanRow as UniteMesureInsertionModel); },
      putData: (cleanRow) => { return uniteMesuresApi.v1UniteMesuresPut(cleanRow as UniteMesureViewModel); },
      deleteData: (dataId) => uniteMesuresApi.v1UniteMesuresIdDelete(dataId),
      onFocusChange: (isFocus) => setActivate(isFocus),
      getNewModel: () => ({
        flAffichageContenance: true,
        flAffichagePoids: true,
        nbDec: 0,
        coeffDiviseur: 1
      }),
      columnDefs: [
        {
          field: 'code',
          headerName: t('lib_unite_mesure_code'),
          headerTooltip: t('lib_unite_mesure_code'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          cellEditor: TextCellEditor,
          cellEditorParams: { maxLength: 1, toUpperCase: true },
          editable: true,
          filter: 'agTextColumnFilter',
          flex: 1
        },
        {
          field: 'nomUniteMesure',
          headerName: t('lib_unite_mesure_nom_unite_mesure'),
          headerTooltip: t('lib_unite_mesure_nom_unite_mesure'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          flex: 3
        },
        {
          field: 'nomCourtUniteMesure',
          headerName: t('lib_unite_mesure_nom_court_unite_mesure'),
          headerTooltip: t('lib_unite_mesure_nom_court_unite_mesure'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          cellEditor: TextCellEditor,
          cellEditorParams: { maxLength: 2 },
          editable: true,
          filter: 'agTextColumnFilter',
          flex: 1
        },
        {
          field: 'nomInterprete',
          headerName: t('lib_unite_mesure_nom_interprete'),
          headerTooltip: t('lib_unite_mesure_nom_interprete'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          flex: 2
        },
        CheckBoxColumnDef("flPoids", "lib_unite_mesure_fl_poids"),
        CheckBoxColumnDef("flContenance", "lib_unite_mesure_fl_contenance"),
        {
          field: 'nbDec',
          headerName: t('lib_unite_mesure_nb_dec'),
          headerTooltip: t('lib_unite_mesure_nb_dec'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notNilCellValidator, numberCellValidator],
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: { min: 0, max: 9, floating: 0 } as NumberCellEditorProps,
          filter: 'agTextColumnFilter',
          flex: 1
        },
        CheckBoxColumnDef("flAffichagePoids", "lib_unite_mesure_fl_affichage_poids"),
        CheckBoxColumnDef("flAffichageContenance", "lib_unite_mesure_fl_affichage_contenance"),
        {
          field: 'coeffDiviseur',
          headerName: t('lib_unite_mesure_coeff_diviseur'),
          headerTooltip: t('lib_unite_mesure_coeff_diviseur'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator, numberCellValidator],
          cellEditor: NumberCellEditor,
          editable: true,
          cellEditorParams: { min: 1 } as NumberCellEditorProps,
          filter: 'agTextColumnFilter',
          flex: 1
        },
      ],
    }), [activate, t])
  );

  const breadcrumb = [
    {
      name: `${t('tit_section')}`,
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: `${t('men_refe_articles')} - ${t('men_parametre')}`,
      link: `/app/referencement/articles/parametres`,
    },
    {
      name: "tit_unite_mesure",
    },
  ];

  const title: Title = {
    title: `${t('men_refe_articles')} - ${t('tab_unite_mesure')}`,
    icon: "articles",
  };

  return (
    <>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <BlockGrid
        sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
        layout={LayoutEnum.settings}
        handleClick={(res: boolean) => {
          setActivate(res);
          setTimeout(gridController.activateContextActionButtons, 50);
        }}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-params">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType={"fullRow"}
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </>
  );
};

export default UniteMesures;
