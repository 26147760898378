import { FunctionComponent } from 'react';

const NomenclatureLineLoader: FunctionComponent = () => {
  return (
    <div className="w-full flex animate-pulse">
      <div className="grid w-full gap-2" style={{ gridTemplateColumns: 'repeat(24, minmax(0, 1fr))' }}>
        <label className="block col-span-4 mb-2 flex-1 pr-6 relative">
          <input disabled className={`h-8 mt-1 block w-full px-3 border border-slate-300 rounded-md text-sm placeholder-slate-400
            disabled:border-slate-200 disabled:bg-gray-200
            focus:border-store-primary`} />
          <svg className="h-3 mt-4h-3 absolute right-1 top-4"
            viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </label>
        <label className="block col-span-4 mb-2 flex-1 pr-6 relative">
          <input disabled className={`h-8 mt-1 block w-full px-3 border border-slate-300 rounded-md text-sm placeholder-slate-400
            disabled:border-slate-200 disabled:bg-gray-200
            focus:border-store-primary`} />
          <svg className="h-3 absolute right-1 top-4"
            viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </label>
        <label className="block col-span-4 mb-2 flex-1 pr-6 relative">
          <input disabled className={`h-8 mt-1 block w-full px-3 border border-slate-300 rounded-md text-sm placeholder-slate-400
            disabled:border-slate-200 disabled:bg-gray-200
            focus:border-store-primary`} />
          <svg className="h-3 absolute right-1 top-4"
            viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </label>
        <label className="block col-span-4 mb-2 flex-1 pr-6 relative">
          <input disabled className={`h-8 mt-1 block w-full px-3 border border-slate-300 rounded-md text-sm placeholder-slate-400
            disabled:border-slate-200 disabled:bg-gray-200
            focus:border-store-primary`} />
          <svg className="h-3 absolute right-1 top-4"
            viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </label>
        <label className="block col-span-4 mb-2 flex-1 pr-6 relative">
          <select disabled className={`h-8 mt-1 block w-full px-3 border border-slate-300 rounded-md text-sm placeholder-slate-400
          disabled:border-slate-200 disabled:bg-gray-200
          focus:border-store-primary`}></select>
          <svg className="h-3 absolute right-1 top-4"
            viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </label>
        <label className="block col-span-4 mb-2 flex-1 pr-6 relative">
          <input disabled className={`h-8 mt-1 block w-full px-3 border border-slate-300 rounded-md text-sm placeholder-slate-400
            disabled:border-slate-200 disabled:bg-gray-200
            focus:border-store-primary`} />
        </label>
      </div>
    </div>
  )
}

export default NomenclatureLineLoader