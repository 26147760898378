import { DateTime } from "luxon";

type DateType = 'now' | 'today' | string | null | undefined;

export function dateValidator(
  dateToParse: any,
  config?: {
    min?: DateType | DateType[],
    max?: DateType | DateType[];
  }
): boolean {
  if (typeof dateToParse !== 'string')
    return false;
  const date = dateToParse === 'now' ? DateTime.now() : DateTime.fromISO(dateToParse);

  if (!date.isValid)
    return false;

  if (config?.min) {
    const minValues = Array.isArray(config.min) ? config.min : [config.min];

    for (const minVal of minValues) {
      const min = parseDate(minVal);
      if (!min?.isValid) continue;

      if (date.toMillis() < min.toMillis())
        return false;
    }
  }

  if (config?.max) {
    const maxValues = Array.isArray(config.max) ? config.max : [config.max];

    for (const maxVal of maxValues) {
      let max = parseDate(maxVal);
      if (!max?.isValid) continue;

      if (maxVal === 'today')
        max = max.plus({ day: 1 });

      if (date.toMillis() > max.toMillis())
        return false;
    }
  }

  return true;
};

function parseDate(dateToParse: DateType) {
  switch (dateToParse) {
    case 'now':
      return DateTime.now();
    case 'today':
      return DateTime.now().startOf('day');
    default:
      return dateToParse ? DateTime.fromISO(dateToParse) : null;
  }
}

export function localTest() {
  console.assert(dateValidator('2020-01-01'), 'Test 1');

  console.assert(dateValidator(
    '2020-01-02',
    { min: '2020-01-01', max: '2020-01-03' }
  ), 'Test 2');

  console.assert(dateValidator(
    '2020-01-02',
    { min: '2020-01-02', max: '2020-01-02' }
  ), 'Test 3');

  console.assert(dateValidator(
    '2020-01-02',
    { min: '2020-01-01', max: '2020-01-03' }
  ), 'Test 4');

  console.assert(dateValidator(
    DateTime.now().toISO(),
    { min: 'today', max: 'today' }
  ), 'Test 5');

  console.assert(!dateValidator(
    '2020-01-01',
    { min: '2020-01-02' }
  ), 'Test 6');

  console.assert(!dateValidator(
    '2020-01-03',
    { max: '2020-01-02' }
  ), 'Test 7');
}