import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { SousMarqueInsertionModel, SousMarqueViewModel } from "openapi-typescript-codegen";
import BlockGrid, {LayoutEnum, PropsBlock} from 'components/BlockGrid';
import { showPrettyError, toast } from "utils";
import { TextInput } from "../../../components/InputWrapper";
import MarquesContext from "../MarquesContext";
import { sousMarquesApi}  from "api";

interface Props extends PropsBlock {
  setIsOpen?: (v: boolean) => void;
  isOpen?: boolean;
  data?: SousMarqueViewModel;
}

const SousMarqueModal: FunctionComponent<Props> = ({ setIsOpen, isOpen, data }) => {
  const { t } = useTranslation();
  const { selection, setSelection, setRefresh } = useContext(MarquesContext);
  const [sousMarque, setSousMarque] = useState<Partial<SousMarqueViewModel>>({});

  useEffect(() => {
    if (isOpen) { data ? setSousMarque(data) : setSousMarque({ fkMarque: selection.marque as number }); }
  }, [isOpen]);

  async function handleUpdate() {
    if (sousMarque.hasOwnProperty("id")) {
      try {
        await sousMarquesApi.v1SousMarquesPut(sousMarque as SousMarqueViewModel);
        toast.success(t("mes_validation_modification"));
        setIsOpen && setIsOpen(false);
      } catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    } else { await handleCreate(); }
  }

  async function handleCreate() {
    try {
      const { data: $sousMarque } = await sousMarquesApi.v1SousMarquesPost(sousMarque as SousMarqueInsertionModel);
      toast.success(t("mes_validation_creation"));
      setSelection({ ...selection, sousMarque: $sousMarque.id });
      setIsOpen && setIsOpen(false);
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  }

  return (
    <BlockGrid
      layout={LayoutEnum.modal}
      title={sousMarque.hasOwnProperty("id") ? t('tit_modification_sous_marque') : t('tit_creation_sous_marque')}
      informations={''}
      toActivate={true}
      handleUpdate={async () => { await handleUpdate().then(() => { setRefresh(true); }); }}
      handleCancel={() => { setIsOpen && setIsOpen(false); }}
    >
      <form onSubmit={$event => $event.preventDefault()} className="flex flex-col">
        <div className="grid grid-cols-6 gap-4">
          <TextInput labelClassAdd="col-span-1" label={t("lib__sous_marque_code")}
                     value={sousMarque} field="code" setValue={setSousMarque} required={true}  />
          <TextInput labelClassAdd="col-span-5" label={t("lib_sous_marque_nom_sous_marque")}
                     value={sousMarque} field="nomSousMarque" setValue={setSousMarque} required={true} />
          {/*<FileInput labelClassAdd="col-span-2" label={t("lib_marque_logo_url")}*/}
          {/*           value={marque} field="logoMarque" setValue={() => { }}*/}
          {/*           accept="image/*"*/}
          {/*/>*/}
        </div>
      </form>
    </BlockGrid>
  );
};

export default SousMarqueModal;