import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  content: string;
}

const CreateButton: FunctionComponent<Props> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <button className="p-3  text-xs mb-2 bg-store-primary rounded">
      <span className="text-white-500"> + {t(`btn_add_${content}`)}</span>
    </button>
  );
};

export default CreateButton;
