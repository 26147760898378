import React from "react";

import { snakeToCamel, toast } from "utils";
import { t } from "i18next";

export interface ColumnControlInterface<T> {
  column: string,
  field: string,
  required: boolean,
  recommended: boolean,
  typeValidation: string,
  getValidationInputClass: (isActive: boolean, hasFocus: boolean, model: T) => string,
  getValidationLabelElement: () => JSX.Element | null,
  validateInput: (model: T) => boolean,
  isValueMissing: (model: T) => boolean,
}

export class ColumnControl<T> implements ColumnControlInterface<T> {
  column: string;
  label: string;
  field: string;
  recommended: boolean;
  required: boolean;
  typeValidation: string;

  constructor(column: string, field: string, required?: boolean, recommended?: boolean, typeValidation?: string) {
    this.column = column;
    this.label = this.getLabel(column);
    this.field = field || column.includes(".") ? snakeToCamel(column.split(".")[1]) : snakeToCamel(column);
    this.required = required || false;
    this.recommended = recommended || false;
    this.typeValidation = typeValidation || "0";
  }

  private getLabel = (column: string) => {
    let [table, field] = column.split(".");
    switch (table) {
      case "tier":
      case "pay":
        table += "s";
        break;
      default:
        break;
    }
    return `lib_${table}_${field}`;
  }

  getValidationInputClass = (isActive: boolean, hasFocus: boolean, model: T) => {
    let className = String();
    // @ts-ignore
    const value: any = model && model[this.field];
    className += this.required ? this.getRequiredInputClass(isActive, hasFocus, value) : "";
    className += this.recommended ? this.getRecommendedInputClass(isActive, hasFocus, value) : "";
    return className;
  }

  private getRequiredInputClass = (isActive: boolean, hasFocus: boolean, value: any): string => {
    let className = String();
    return this.required && (!value || !String(value)?.trim().length) ? this.addClass(className, "orange", isActive, hasFocus) : className;
  };

  private getRecommendedInputClass = (isActive: boolean, hasFocus: boolean, value: any): string => {
    let className = String();
    return this.recommended && (!value || !String(value)?.trim().length) ? this.addClass(className, "orange", isActive, hasFocus) : className;
  }

  private addClass = (className: string, color: string, isActive?: boolean, hasFocus?: boolean) => {
    className += (isActive || (!isActive && hasFocus)) ? ` border-${color}-500 ` : "";
    className += isActive ? ` bg-${color}-500/10 ` : "";
    className += hasFocus ? ` disabled:bg-${color}-500/10 ` : "";
    return className;
  }

  getValidationLabelElement = () => {
    if (this.required || this.recommended) {
      let children = String();
      children += this.required ? "**" : "";
      children += this.recommended ? "*" : "";
      return React.createElement('span', {
        className: this.required ? "text-red-500" : this.recommended ? "text-orange-500" : "",
      }, children);
    }
    return null;
  }

  validateInput = (model: T, label?: string) => {
    this.label = label || this.label;
    if (this.isValueMissing(model)) {
      toast.error(t('err_champ_obligatoire') + (this.label ? (':\n' + t(this.label)) : ''))
      return false;
    } else {
      return true;
    }
  }

  isValueMissing = (model: T) => {
    if (this.required) {
      // @ts-ignore
      const value: any = model && model[this.field];
      return typeof value === "boolean" ? false : !value || !String(value)?.trim().length;
    } else {
      return false;
    }
  }

  static get = (column: string, controls: ColumnControl<any>[]) => controls.find(control => control.column === column);

  static getMany = (columns: string[], controls: ColumnControl<any>[]) => controls.filter(control => columns.includes(control.column));

  static checkMany = (columns: string[], model: any, controls: ColumnControl<any>[]) => ColumnControl
    .checkAll(model, ColumnControl.getMany(columns, controls));

  static checkAll = (model: any, controls: ColumnControl<any>[]) => controls
    .reduce((accumulator, current) => accumulator + Number(current?.isValueMissing(model)), 0);

  static validateMany = (columns: string[], model: any, controls: ColumnControl<any>[]) => ColumnControl
    .validateAll(model, ColumnControl.getMany(columns, controls));

  static validateAll = (model: any, controls: ColumnControl<any>[]) => controls
    // Reduce is used instead of every, to be sure to check all the inputs:
    .reduce((result, control) => control.validateInput(model) && result, true)
}