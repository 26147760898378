import {FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import {RayonViewModel} from "openapi-typescript-codegen";
import { useTranslation } from "react-i18next";

import { rayonsApi} from 'api';
import { PropsBlock } from 'components/BlockGrid';
import { useGridController } from "hooks";
import { TextCellRenderer } from "components/AGGride/CellRender";
import BlockGridNomenclature from "../BlockGridNomenclature";
import NomenclatureContext from "../NomenclatureContext";
import RayonModal from "../modals/RayonModal";
import {RayonViewModelNomenclatureDetails} from "openapi-typescript-codegen/types/models/rayon-view-model-nomenclature-details";

interface Props extends PropsBlock {
  rayons: RayonViewModelNomenclatureDetails[];
  onRayonChange: (id: number | null) => void;
}

type GridType = RayonViewModel;

const Rayon: FunctionComponent<Props> = ({ rayons, onRayonChange }) => {
  const { t } = useTranslation();
  const { nomenclature, setRefresh } = useContext(NomenclatureContext);
  const [disableCreate, setDisableCreate] = useState(false);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.id,
      fetchData: async () => rayons.map(({ item }) => item),
      deleteData: (id) => rayonsApi.v1RayonsIdDelete(id).then(() => {
        id === nomenclature.rayon ? onRayonChange(null) : setRefresh(true);
      }),
      rowClassRules: {
        "hide-actions": params => {
          const rayon = rayons.find(rayon => rayon.item.id === params.data?.id);
          return !!(rayon && rayon.childrenCount);
        },
      },
      columnDefs: [
        {
          field: 'code',
          headerName: t('lib_rayon_code'),
          headerTooltip: t('lib_rayon_code'),
          cellRenderer: TextCellRenderer,
          editable: false,
          filter: 'agTextColumnFilter',
          flex: 1
        },
        {
          field: 'nomRayon',
          headerName: t('lib_rayon_nom_rayon'),
          headerTooltip: t('lib_rayon_nom_rayon'),
          cellRenderer: TextCellRenderer,
          valueFormatter: (params: any) => {
            const rayon = rayons.find(rayon => rayon.item.id === params.data.id);
            return rayon ? `${rayon.item.nomRayon} <${rayon.childrenCount}>` : String();
          },
          editable: false,
          filter: 'agTextColumnFilter',
          flex: 4
        },
      ],
    }), [rayons, t])
  );

  useEffect(() => { gridController.fetchData(); }, [rayons]);
  useEffect(() => { setDisableCreate(!nomenclature.departement); }, [nomenclature.departement]);

  return <BlockGridNomenclature
    label={"tab_detail_rayon"}
    gridController={gridController}
    onSelect={onRayonChange}
    gridName={"rayon"}
    disableCreate={disableCreate}
  ><RayonModal /></BlockGridNomenclature>;
};

export default Rayon;