import { FC, useState, useMemo } from 'react';
import { AgGridReact } from "ag-grid-react";
import { ColDef, ICellRendererParams, GroupCellRenderer } from "ag-grid-community";
import { useTranslation } from "react-i18next";

import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { CustomColDef, RowDataType } from "components/AGGride/gridTypes";
import { useArticle } from "context/Referencement";
import { useGridController } from "hooks";
import { PartialViewApproTarifaire, SequenceDePrixPartialViewApproTarifaire } from "openapi-typescript-codegen";
import { sequenceDePrixApi } from "api/";
import { DateCellRenderer, LinkCellRenderer, LinkCellRendererProps, ModalCellRenderer, ModalCellRendererChildrenProps, TextCellRenderer } from "components/AGGride/CellRender";
import FournisseurEdit from "../RelationFournisseurConditionnement/FournisseurEdit";
import PrixAchatModal from "./PrixAchatModal";
import DetailCalculPrixAchatModal from "./DetailCalculPrixAchatModal";
import Modal from "components/Modal";
import { GridController } from "hooks/useGridController";
import { fixFloating } from "utils";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  maxWidth: 150,
};

export type GridType = { path: string[]; } & SequenceDePrixPartialViewApproTarifaire;

const FournisseurModal: FC<ModalCellRendererChildrenProps<GridType>> = ({ setIsOpen, agGridRow }) => {
  const parentGridController: GridController | undefined = agGridRow.context.gridController;

  return (<FournisseurEdit
    setIsOpen={setIsOpen}
    onUpdate={parentGridController!.fetchData}
    relationFournisseurConditionnementId={agGridRow.data?.approTarifaire?.id}
  />);
};

const ApprovisionnementTarifaireAchat: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const [activate, setActivate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {
        floatingAction: 'none',
      },
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData,
      fetchData: async () => {
        if (!article?.id) return [];
        const { data: res } = await sequenceDePrixApi.articlesIdViewApprovisionnementTarifaireGet(article.id);
        const circuitGroups: { [circuitId: string]: PartialViewApproTarifaire['conditionnementSite'][]; } = {};

        for (const { approTarifaire } of res) {
          const circuitId = String(approTarifaire?.circuit?.id);
          if (!circuitGroups[circuitId]) circuitGroups[circuitId] = [];
          circuitGroups[circuitId].push(approTarifaire?.conditionnementSite);
        };
        for (const circuitId in circuitGroups) {
          circuitGroups[circuitId].sort((a, b) => (a?.pcb || 0) - (b?.pcb || 0));
        }

        return res.map(sequence => ({
          ...sequence,
          id: sequence.approTarifaire?.id,
          path: circuitGroups[String(sequence.approTarifaire?.circuit?.id)][0]?.id === sequence.approTarifaire?.conditionnementSite?.id
            ? [String(sequence.approTarifaire?.circuit?.id)]
            : [String(sequence.approTarifaire?.circuit?.id), String(sequence.approTarifaire?.conditionnementSite?.pcb)]
        }));
      },
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: "approTarifaire.fournisseur.code",
          headerName: t('lib_fournisseur_code'),
          headerTooltip: t('lib_fournisseur_code'),
          // rowGroup: true,
          pinned: "left",
          maxWidth: 150,
          valueFormatter: (params) => {
            if (!params.value) return '';
            if (params.node?.group) {
              let rowData: RowDataType<GridType>;
              params.api.forEachNode((rowNode) => {
                if (rowNode.data && rowNode.data?.approTarifaire?.fournisseur?.code === params.value)
                  rowData = rowNode.data;
              });
              if (rowData!)
                return `${rowData.approTarifaire?.fournisseur?.raisonSociale} (${params.value})`;
            }
            return `${params.data?.approTarifaire?.fournisseur?.raisonSociale} (${params.value})`;
          },
          cellRenderer: LinkCellRenderer,
          cellRendererParams: {
            href: (_: any, props: ICellRendererParams) => `/app/referencement/fournisseurs/${props.value}`,
            target: '_blank'
          } as LinkCellRendererProps,
        },
        {
          field: "approTarifaire.flux.modeApprovisionnement.code",
          headerName: t('lib_flux_circuit'),
          headerTooltip: t('lib_flux_circuit'),
          pinned: "left",
          maxWidth: 60,
          tooltipValueGetter: (params) => {
            const nomModeApprovisionnement = params.data?.approTarifaire?.flux?.modeApprovisionnement?.nomModeApprovisionnement;
            return nomModeApprovisionnement ? `${nomModeApprovisionnement} (${params.value})` : '';
          },
          cellRenderer: TextCellRenderer,
        },
        {
          field: "approTarifaire.circuit.code",
          headerName: t('lib_relation_fournisseur_conditionnement_fk_circuit_logistique'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_fk_circuit_logistique'),
          pinned: "left",
          maxWidth: 100,
          cellRenderer: ModalCellRenderer,
          cellRendererParams: {
            children: FournisseurModal,
          }
        },
        {
          field: "approTarifaire.conditionnementSite.pcb",
          headerName: t('lib_conditionnement_pcb'),
          headerTooltip: t('lib_conditionnement_pcb'),
          maxWidth: 70,
          showRowGroup: true,
          cellRenderer: GroupCellRenderer,
          cellRendererParams: {
            suppressCount: true,
          },
        },
        {
          field: "approTarifaire.nomRelationFournisseurConditionnement",
          headerName: t('lib_relation_fournisseur_conditionnement_nom_relation_fournisseur'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_nom_relation_fournisseur'),
          maxWidth: 100,
          cellRenderer: TextCellRenderer,
        },
        {
          field: "approTarifaire.status",
          headerName: t('lib_relation_fournisseur_statut'),
          headerTooltip: t('lib_relation_fournisseur_statut'),
          maxWidth: 80,
          cellRenderer: TextCellRenderer,
          valueFormatter: (params) => {
            return params.value ? t('lib_actif') : t('lib_non_actif');
          },
          cellClass: ['capitalize'],
        },
        {
          field: "approTarifaire.priorite",
          headerName: t('lib_relation_fournisseur_conditionnement_priorite'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_priorite'),
          maxWidth: 80,
          cellRenderer: TextCellRenderer,
        },
        {
          field: "approTarifaire.conditionnementSite.nomConditionnement",
          headerName: t('lib_relation_fournisseur_conditionnement_fk_conditionnement_site'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_fk_conditionnement_site'),
          maxWidth: 120,
          cellRenderer: TextCellRenderer,
          valueFormatter: (params) => {
            return params.value ? `${params.value} (${params.data?.approTarifaire?.conditionnementSite?.code || ''})` : '';
          },
        },
        {
          field: "approTarifaire.conditionnementFournisseur.nomConditionnement",
          headerName: t('lib_relation_fournisseur_conditionnement_fk_conditionnement_fournisseur'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_fk_conditionnement_fournisseur'),
          maxWidth: 120,
          valueFormatter: (params) => {
            return params.value ? `${params.value} (${params.data?.approTarifaire?.conditionnementFournisseur?.code || ''})` : '';
          },
        },
        {
          field: "approTarifaire.codeRfa",
          headerName: t('lib_relation_fournisseur_conditionnement_code_rfa'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_code_rfa'),
          maxWidth: 120,
        },
        {
          field: "approTarifaire.dateDebut",
          headerName: t('lib_relation_fournisseur_conditionnement_date_debut'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_date_debut'),
          maxWidth: 110,
          cellRenderer: DateCellRenderer,
        },
        {
          field: "approTarifaire.dateFin",
          headerName: t('lib_relation_fournisseur_conditionnement_date_fin'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_date_fin'),
          maxWidth: 110,
          cellRenderer: DateCellRenderer,
        },
        {
          field: "approTarifaire.prixAchat.pxAchat",
          headerName: t('lib_prix_achat_px_achat'),
          headerTooltip: t('lib_prix_achat_px_achat'),
          maxWidth: 100,
          tooltipValueGetter: (params) => params.value + ' ' + params.data?.approTarifaire?.prixAchat?.devise?.nomCourt,
          valueFormatter: (params) => typeof params.value === 'number'
            ? fixFloating(params.value, params.data?.approTarifaire?.prixAchat?.devise?.decPxAchat).toString()
            : '',
          cellRenderer: ModalCellRenderer,
          cellRendererParams: {
            children: PrixAchatModal,
          },
        },
        ...[
          {
            field: 1,
            maxWidth: 90,
          },
          {
            field: 2,
            maxWidth: 90,
          },
          { field: 3 },
          { field: 4 },
          { field: 5 },
          { field: 6 },
          { field: 7 },
          { field: 8 },
          { field: 9 },
          { field: 10 },
          { field: 11 }
        ].map((val): CustomColDef<GridType> => ({
          headerName: t(`lib_taxe_fl_px${val.field}`),
          headerTooltip: t(`lib_taxe_fl_px${val.field}`),
          maxWidth: 70,
          tooltipValueGetter: (params) => params.value + ' ' + params.data?.approTarifaire?.prixAchat?.devise?.nomCourt,
          valueFormatter: (params) => typeof params.value === 'number'
            ? fixFloating(params.value, params.data?.approTarifaire?.prixAchat?.devise?.decPxAchat).toString()
            : '',
          cellRenderer: ModalCellRenderer,
          cellRendererParams: {
            children: DetailCalculPrixAchatModal
          },
          ...val,
          field: `sequenceDePrixResult.finalPrice.column.${val.field - 1}`,
        })),
        {
          field: "approTarifaire.refFour",
          headerName: t('lib_relation_fournisseur_conditionnement_ref_four'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_ref_four'),
          maxWidth: 120,
          cellRenderer: TextCellRenderer,
        },
      ],
    }), [activate, article, t])
  );

  return (
    <BlockGrid
      title={t('tab_approvisionnement')} sm={sm} md={md} lg={lg} xl={xl}
      doubleXl={doubleXl}
      loading={gridController.isLoading}
      handleClick={(res: boolean) => {
        setActivate(res);
      }}
      toActivate={activate}
      handleCreate={() => setIsModalOpen(true)}
      handleUpdate={gridController.handleCancel}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions tree-group block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={false}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
          context={{ gridController }}
          treeData={true}
          groupDefaultExpanded={-1}
          groupDisplayType="custom"
          getDataPath={(rowData) => rowData.path}
        ></AgGridReact>
      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div>
          <FournisseurEdit setIsOpen={setIsModalOpen} onUpdate={gridController.fetchData} />
        </div>
      </Modal>
    </BlockGrid>
  );
};

export default ApprovisionnementTarifaireAchat;