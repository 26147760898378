import {FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import {DepartementViewModel} from "openapi-typescript-codegen";
import {useTranslation} from "react-i18next";

import {departementsApi} from 'api';
import {PropsBlock} from 'components/BlockGrid';
import {useGridController} from "hooks";
import {TextCellRenderer} from "components/AGGride/CellRender";
import BlockGridNomenclature from "../BlockGridNomenclature";
import DepartementModal from "../modals/DepartementModal";
import NomenclatureContext from "../NomenclatureContext";
import { DepartementViewModelNomenclatureDetails } from "openapi-typescript-codegen/types/models/departement-view-model-nomenclature-details";

interface Props extends PropsBlock {
    departements: DepartementViewModelNomenclatureDetails[];
    onDepartementChange: (id: number | null) => void;
}

type GridType = DepartementViewModel;

const Departement: FunctionComponent<Props> = ({departements, onDepartementChange}) => {
    const { t } = useTranslation();
    const { nomenclature, setRefresh } = useContext(NomenclatureContext);
    const [disableCreate, setDisableCreate] = useState(false);

    const gridController = useGridController<GridType>(
        useMemo(() => ({
            colConfig: {},
            autoFetch: true,
            emptyRowCheck: (rowData) => !rowData.id,
            fetchData: async () => departements.map(({ item }) => item),
            deleteData: (id) => departementsApi.v1DepartementsIdDelete(id).then(() => {
                id === nomenclature.departement ? onDepartementChange(null) : setRefresh(true);
            }),
            rowClassRules: {
                "hide-actions": params => {
                    const departement = departements.find(departement => departement.item.id === params.data?.id);
                    return !!(departement && departement.childrenCount);
                },
            },
            columnDefs: [
                {
                    field: 'code',
                    headerName: t('lib_departement_code'),
                    headerTooltip: t('lib_departement_code'),
                    cellRenderer: TextCellRenderer,
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 1
                },
                {
                    field: 'nomDepartement',
                    headerName: t('lib_departement_nom_departement'),
                    headerTooltip: t('lib_departement_nom_departement'),
                    cellRenderer: TextCellRenderer,
                    valueFormatter: (params: any) => {
                        const departement = departements.find(departement => departement.item.id === params.data.id);
                        return departement ? `${departement.item.nomDepartement} <${departement.childrenCount}>` : String();
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 4
                },
            ],
        }), [departements, t])
    );

    useEffect(() => { gridController.fetchData(); }, [departements]);
    useEffect(() => { setDisableCreate(!nomenclature.enseigne); }, [nomenclature.enseigne]);

    return <BlockGridNomenclature
        label={"tab_detail_departement"}
        gridController={gridController}
        onSelect={onDepartementChange}
        gridName={"departement"}
        disableCreate={disableCreate}
    ><DepartementModal/></BlockGridNomenclature>;
};

export default Departement;