import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { Tooltip } from '@mui/material';
import { useKeydown, useNotKeydown } from 'hooks';

import { devisesApi, fournisseursApi, paysApi, tiersApi, typeFournisseursApi } from "api";
import { DeviseViewModel, FournisseurViewModel, PayViewModel, TierViewModel, TypeFournisseurViewModel } from "openapi-typescript-codegen";
import { useFournisseur, useUpdateFournisseur } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateFournisseur';

import { useTranslation } from "react-i18next";

import SearchBarPresentation from "./SearchBarPresentation";

const defaultImage = "/assets/images/default.png";

interface Loader {
  article: boolean;
  marque: boolean;
  typologie: boolean;
}

const FournisseurPresentation: FC = () => {
  const { t } = useTranslation();
  const { state: fournisseur } = useFournisseur();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<Loader>({
    article: false,
    marque: false,
    typologie: false,
  });
  const [display, setDisplay] = useState<boolean>(true);
  const [blockKey, setBlockKey] = useState<boolean>(false);
  const [fournisseurSelected, setFournisseurSelected] = useState<FournisseurViewModel>();
  const [tier, setTier] = useState<TierViewModel>();
  const [pays, setPays] = useState<PayViewModel>();
  const [devise, setDevise] = useState<Partial<DeviseViewModel>>();
  const [typeFournisseur, setTypeFournisseur] = useState<Partial<TypeFournisseurViewModel>>();


  const refresh = useCallback((evt: UpdateEnum) => {
    if (evt === UpdateEnum.fournisseur) {
      if (fournisseur?.id) fetchGetFournisseur(fournisseur.id);
    }
  }, [fournisseur?.id, fournisseurSelected]);
  useUpdateFournisseur(refresh);

  const fetchGetFournisseur = async (id: number) => {
    const { data: response } = await fournisseursApi.v1FournisseursIdGet(id);
    setFournisseurSelected(response);
    if (response?.fkTiers) {
      const { data: respTier } = await tiersApi.v1TiersIdGet(response.fkTiers);
      setTier(respTier);
    }
    setLoading({ ...loading, article: false });
  };

  const fetchGetPays = async (id: number) => {
    const { data: response } = await paysApi.v1PaysIdGet(id);
    setPays(response);
    // setLoading({ ...loading, marque: false });
  };
  useEffect(() => {
    if (tier?.fkPays) fetchGetPays(tier.fkPays);
    else setPays(undefined);
  }, [tier?.fkPays]);

  const fetchGetDevise = async (id: number) => {
    const { data: response } = await devisesApi.v1DevisesIdGet(id);
    setDevise(response);
    // setLoading({ ...loading, marque: false });
  };
  useEffect(() => {
    if (tier?.fkDevise) fetchGetDevise(tier.fkDevise);
    else setDevise({});
  }, [tier?.fkDevise]);

  const fetchGetType = async (id: number) => {
    const { data: response } = await typeFournisseursApi.v1TypeFournisseursIdGet(id);
    setTypeFournisseur(response);
    // setLoading({ ...loading, marque: false });
  };
  useEffect(() => {
    if (fournisseurSelected?.fkTypeFournisseur) fetchGetType(fournisseurSelected.fkTypeFournisseur);
    else setTypeFournisseur({});
  }, [fournisseurSelected?.fkTypeFournisseur]);

  useEffect(() => {
    if (fournisseur?.id) fetchGetFournisseur(fournisseur.id);
  }, [fournisseur?.id]);

  function handleDisplay() {
    if (!blockKey) setDisplay(!display);
  }

  function handleBlock() {
    setBlockKey(true);
  }

  useKeydown(' ', handleDisplay);
  useNotKeydown(' ', handleBlock);


  return (
    <div className="relative">
      <div className={`bg-grey-150 transition-all ${!display && "h-px"}`}></div>
      <button tabIndex={3} onClick={() => setDisplay(!display)} style={{ left: 'calc(50% - 16px)', zIndex: 2 }}
        className={`absolute -bottom-2 h-4 w-7 rounded-lg flex justify-center items-center
          border border-black
          stroke-black bg-white-500
          hover:bg-black hover:stroke-white-500
          focus:bg-black focus:stroke-white-500
          ${display && "rotate-180"} transition-all`}
      >
        <svg viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-2">
          <path d="M1 1L5.5 5.5L10 1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>

      <div id="presentation-fournisseur"
        style={{ height: display ? "170px" : "0px" }}
        className={`col-span-full w-full rounded-md border-grey-150 bg-grey-150 relative
          ${display ? "border" : "overflow-hidden"} transition-all flex gap-px relative`}
      >
        <div className="aspect-square">
          <div className="h-full w-full p-2 bg-white-500 relative flex justify-center rounded-l-md">
            {/* {loading.article ? <Loader /> : */}
            <div
              style={{ backgroundImage: `url(${tier?.logo || defaultImage})` }}
              className="bg-local h-full w-full max-w-full bg-center bg-no-repeat bg-contain"
            ></div>
            {/* } */}
          </div>
        </div>

        <div className="w-full">
          <div className="grid grid-rows-4 h-full gap-px">
            <div className="row-span-1 grid grid-cols-24 gap-px">
              <SearchBarPresentation />
              <div className="col-span-21 h-full">
                <div className="grid grid-cols-24 h-full gap-px">
                  <div className="px-2 flex justify-left align-left col-span-20 bg-white-500 gap-2">
                    <Tooltip title={tier?.nomTiers || tier?.nomCourtTiers || ""} followCursor>
                      <h4 className="self-center font-semibold text-ellipsis whitespace-nowrap w-fit overflow-hidden">
                        {tier?.nomTiers || tier?.nomCourtTiers}
                      </h4>
                    </Tooltip>
                    {(tier?.raisonSociale) &&
                      <Tooltip title={tier?.raisonSociale || ''} followCursor >
                        <span className="self-center font-semibold text-ellipsis whitespace-nowrap overflow-hidden border-l border-gray-200 pl-2">
                          {tier?.raisonSociale}
                        </span>
                      </Tooltip>
                    }
                  </div>
                  <button type="button" onClick={() => alert("Ouvre feature: completude modal")}
                    className={`flex w-full justify-center items-center col-span-4 text-xs text-white-500 rounded-tr-md
                    bg-green-500`}>
                    Actif
                  </button>
                </div>
              </div>
            </div>
            <div className="row-span-3 w-full grid grid-cols-24 gap-px">
              <div className="flex justify-around align-center h-full px-2 bg-white-500 col-span-16">
                <div className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer" onClick={() => navigate("general#block-produit-article")}>
                  <span className="text-xs">{t('lib_tiers_num_tiers_ext')}</span>
                  <span>{tier?.numTiersExt || '... '}</span>
                </div>

                <div className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer" onClick={() => navigate("general#block-produit-article")}>
                  <span className="text-xs">{t('lib_tiers_fk_pays')}</span>
                  <span>{pays?.nomPays || '... '}</span>
                </div>

                <div className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer" onClick={() => navigate("general#block-produit-article")}>
                  <span className="text-xs">{t('lib_tiers_fk_devise')}</span>
                  <span>{devise?.nomCourt || '... '}</span>
                </div>

                <div className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer" onClick={() => navigate("general#block-produit-article")}>
                  <span className="text-xs">{t('lib_type_fournisseur_nom')}</span>
                  <span>{typeFournisseur?.nomTypeFournisseur || '... '}</span>
                </div>

                <div className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer" onClick={() => navigate("general#block-produit-article")}>
                  <span className="text-xs">{t('lib_tiers_siren')}</span>
                  <span>{tier?.siren || '... '}</span>
                </div>

                <div className="col-span-2 overflow-hidden flex flex-col justify-center cursor-pointer" onClick={() => navigate("general#block-produit-article")}>
                  <span className="text-xs">{t('lib_tiers_tva_intra')}</span>
                  <span>{tier?.tvaIntra || '... '}</span>
                </div>

              </div>

              <div className="flex justify-around align-center items-center h-full px-2 bg-white-500 col-span-8 rounded-br-md">

                <div className='flex flex-row items-center h-20 mr-2 hover:cursor-pointer'>
                  <span className='px-1.5 bg-red-500 text-white-100 rounded-lg mr-2'>32</span>
                  <span className='text-xs'>Produits</span>
                </div>
                <div className='flex flex-row items-center h-20 hover:cursor-pointer'>
                  <span className='px-1.5 bg-red-500 text-white-100 rounded-lg mr-2'>12</span>
                  <span className='text-xs'>Commandes (en cours)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default FournisseurPresentation;