import {FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import { FamilleViewModel } from "openapi-typescript-codegen";
import { useTranslation } from "react-i18next";

import { famillesApi } from 'api';
import { PropsBlock } from 'components/BlockGrid';
import { useGridController } from "hooks";
import { TextCellRenderer } from "components/AGGride/CellRender";
import BlockGridNomenclature from "../BlockGridNomenclature";
import NomenclatureContext from "../NomenclatureContext";
import FamilleModal from "../modals/FamilleModal";
import { FamilleViewModelNomenclatureDetails } from "openapi-typescript-codegen/types/models/famille-view-model-nomenclature-details";

interface Props extends PropsBlock {
  familles: FamilleViewModelNomenclatureDetails[];
  onFamilleChange: (id: number | null) => void;
}

type GridType = FamilleViewModel;

const Famille: FunctionComponent<Props> = ({ familles, onFamilleChange }) => {
  const { t } = useTranslation();
    const { nomenclature, setRefresh } = useContext(NomenclatureContext);
    const [disableCreate, setDisableCreate] = useState(false);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.id,
      fetchData: async () => familles.map(({ item }) => item),
      deleteData: (id) => famillesApi.v1FamillesIdDelete(id).then(() => {
        id === nomenclature.famille ? onFamilleChange(null) : setRefresh(true);
      }),
      rowClassRules: {
        "hide-actions": params => {
          const famille = familles.find(famille => famille.item.id === params.data?.id);
          return !!(famille && famille.childrenCount);
        },
      },
      columnDefs: [
        {
          field: 'code',
          headerName: t('lib_famille_code'),
          headerTooltip: t('lib_famille_code'),
          cellRenderer: TextCellRenderer,
          editable: false,
          filter: 'agTextColumnFilter',
          flex: 1
        },
        {
          field: 'nomFamille',
          headerName: t('lib_famille_nom_famille'),
          headerTooltip: t('lib_famille_nom_famille'),
          cellRenderer: TextCellRenderer,
          valueFormatter: (params: any) => {
            const famille = familles.find(famille => famille.item.id === params.data.id);
            return famille ? `${famille.item.nomFamille} <${famille.childrenCount}>` : String();
          },
          editable: false,
          filter: 'agTextColumnFilter',
          flex: 4
        },
      ],
    }), [familles, t])
  );

  useEffect(() => { gridController.fetchData(); }, [familles]);
  useEffect(() => { setDisableCreate(!nomenclature.rayon); }, [nomenclature.rayon]);

  return <BlockGridNomenclature
    label={"tab_detail_famille"}
    gridController={gridController}
    onSelect={onFamilleChange}
    gridName={"famille"}
    disableCreate={disableCreate}
  ><FamilleModal /></BlockGridNomenclature>;
};

export default Famille;