import { Routes, Route } from "react-router-dom";
import "./App.css";

import { useScrollHandler } from "hooks";
import Layout from "components/Layout";
import ProtectedRoute from "components/ProtectedRoute";

import Home from "pages/Home";
import Example from "pages/Example";

import Article from "pages/Referencement/Article";
import Fournisseur from "pages/Referencement/Fournisseur";

import TestTab from "pages/TestTab";
import Pays from "pages/Pays";
import Commande from "./pages/Commande";
import Nomenclature from "./pages/Nomenclature";
import TypeEan from "./pages/TypeEan";
import ListeArticles from "./pages/ListeArticles";
import ListeCommandes from "./pages/ListeCommandes";
import Completude from "./pages/Completude";
import Marques from "./pages/Marques";
import ArticleParametres from "./pages/ArticleParametres";
import FournisseurParametres from "./pages/FournisseurParametres";
import GeneralParametres from "./pages/GeneralParametres";
import Referencement from "./pages/Referencement";
import ListeFournisseurs from "./pages/ListeFournisseurs";
import OAuthRedirectCallback from "./pages/OAuthRedirectCallback";
import Login from "./pages/Login";
import Certificat from "./pages/Certificat";
import AlgorithmeApprovisionnement from "./pages/AlgorithmeApprovisionnement";
import CodeEtat from "./pages/CodeEtat";
import Espece from "./pages/Espece";
import Commune from "./pages/Commune";
import ConditionReglement from "./pages/ConditionReglement";
import Constituant from "./pages/Constituant";
import Incoterm from "./pages/Incoterm";
import ModeReglement from "./pages/ModeReglement";
import Origine from "./pages/Origine";
import Section from "./pages/Section";
import Temperature from "./pages/Temperature";
import TraitementChimique from "./pages/TraitementChimique";
import TypeLiaison from "./pages/TypeLiaison";
import TypeArticle from "./pages/TypeArticle";
import Approvisionnement from "./pages/Approvisionnement";
import CommandeParametres from "./pages/CommandeParametres";
import Classe from "./pages/Classe";
import TypeFournisseur from "./pages/TypeFournisseur";
import ModeTransmission from "./pages/ModeTransmission";
import ModeApprovisionnement from "./pages/ModeApprovisionnement";
import TypeTransport from "./pages/TypeTransport";
import TypePiece from "./pages/TypePiece";
import TailleContainer from "./pages/TailleContainer";
import TypeConditionnement from "./pages/TypeConditionnement";
import UniteMesures from "./pages/UniteMesures";
import TaxeGeneral from "./pages/TaxeGeneral";
import TaxeIndirecte from "./pages/TaxeIndirecte";
import UniteVentes from "./pages/UniteVentes";
import Variete from "./pages/Variete";
import Devise from "./pages/Devise";
import Tva from "./pages/Tva";
import {
  APPLICATIONS,
  CONTACT,
  NOMENCLATURE_DOUANIERE,
  PARAMETRES,
  REFERENCEMENT,
  REFERENCEMENT_ARTICLES,
  REFERENCEMENT_FOURNISSEURS,
  REMISE,
  TYPE_CONDITIONNEMENT,
} from "./app/applications";
import Remise from "./pages/Remise";
import Contact from "./pages/Contact";
import NomenclatureDouaniere from "./pages/NomenclatureDouaniere";
import IndicatifEan from "./pages/IndicatifEan";

function App() {
  useScrollHandler();

  return (
    <>
      <Layout>
        <Routes>
          {/* TODO: force auth <Route element={<ProtectedRoute needAuth />}> */}
          <Route element={<ProtectedRoute needAuth={false} />}>
            <Route path="/" element={<Home />} />
            <Route path="/app" element={<Home />} />

            {/* Examples Pages */}
            <Route path="/example" element={<Example />} />
            <Route path="/test-api" element={<TestTab />} />

            {/* Applications Pages */}
            <Route path="app/referencement" element={<Referencement />} />
            <Route
              path="app/approvisionnement"
              element={<Approvisionnement />}
            />

            <Route
              path="app/approvisionnement/commandes/"
              element={<ListeCommandes />}
            />
            <Route
              path="app/approvisionnement/commandes/parametres"
              element={<CommandeParametres />}
            />
            <Route path="app/approvisionnement/commandes/parametres" />
            <Route
              path="app/approvisionnement/commandes/:code"
              element={<Commande />}
            />
            {/* Articles Paramètres Page */}
            <Route
              path="app/referencement/articles/parametres"
              element={<ArticleParametres />}
            />
            {/* Articles Pages */}
            {/* <Route path="articles" element={<Articles />} >
                <Route path=":code" element={<Example />} />
              </Route> */}
            <Route
              path="app/referencement/articles"
              element={<ListeArticles />}
            />
            <Route
              path="app/referencement/articles/parametres/type-ean"
              element={<TypeEan />}
            />
            <Route
              path="app/referencement/articles/parametres/certificat"
              element={<Certificat />}
            />
            <Route
              path="app/referencement/articles/parametres/origine"
              element={<Origine />}
            />
            <Route
              path="app/referencement/articles/parametres/section"
              element={<Section />}
            />
            <Route
              path="app/referencement/articles/parametres/taxe-general"
              element={<TaxeGeneral />}
            />
            <Route
              path="app/referencement/articles/parametres/taxe-indirecte"
              element={<TaxeIndirecte />}
            />
            <Route
              path="app/referencement/articles/parametres/constituant"
              element={<Constituant />}
            />
            <Route
              path="app/referencement/articles/parametres/incoterm"
              element={<Incoterm />}
            />
            <Route
              path="app/referencement/articles/parametres/unite-mesure"
              element={<UniteMesures />}
            />
            <Route
              path="app/referencement/articles/parametres/unite-vente"
              element={<UniteVentes />}
            />
            <Route
              path="app/referencement/referentiel/parametres/commune"
              element={<Commune />}
            />
            <Route
              path="app/referencement/articles/parametres/espece"
              element={<Espece />}
            />
            <Route
              path="app/referencement/articles/parametres/code-etat"
              element={<CodeEtat />}
            />
            <Route
              path="app/referencement/articles/parametres/variete"
              element={<Variete />}
            />
            <Route
              path="app/referencement/articles/parametres/indicatif-ean"
              element={<IndicatifEan />}
            />
            <Route
              path="app/referencement/articles/parametres/classe"
              element={<Classe />}
            />
            <Route
              path="app/referencement/articles/parametres/traitement-chimique"
              element={<TraitementChimique />}
            />
            <Route
              path="app/referencement/articles/parametres/type-liaison"
              element={<TypeLiaison />}
            />
            <Route
              // path="app/referencement/articles/parametres/type-conditionnement"
              path={`${APPLICATIONS}/${REFERENCEMENT}/${REFERENCEMENT_ARTICLES}/${PARAMETRES}/${TYPE_CONDITIONNEMENT}`}
              element={<TypeConditionnement />}
            />
            <Route
              path="app/referencement/articles/parametres/type-article"
              element={<TypeArticle />}
            />
            <Route
              path="app/referencement/fournisseurs/parametres/algorithme-approvisionnement"
              element={<AlgorithmeApprovisionnement />}
            />
            <Route
              path="app/referencement/fournisseurs/parametres/condition-reglement"
              element={<ConditionReglement />}
            />
            <Route
              path="app/referencement/fournisseurs/parametres/mode-reglement"
              element={<ModeReglement />}
            />
            <Route
              path="app/referencement/fournisseurs/parametres/type-piece"
              element={<TypePiece />}
            />
            <Route
              path="app/referencement/fournisseurs/parametres/type-transport"
              element={<TypeTransport />}
            />
            <Route
              path="app/referencement/fournisseurs/parametres/taille-container"
              element={<TailleContainer />}
            />
            <Route
              path="app/referencement/fournisseurs/parametres/mode-approvisionnement"
              element={<ModeApprovisionnement />}
            />
            <Route
              path="app/referencement/fournisseurs/parametres/type-fournisseur"
              element={<TypeFournisseur />}
            />
            <Route
              path="app/referencement/fournisseurs/parametres/temperature"
              element={<Temperature />}
            />
            <Route
              path="app/referencement/fournisseurs/parametres/mode-transmission"
              element={<ModeTransmission />}
            />
            <Route
              path="app/referencement/articles/:code/*"
              element={<Article />}
            />

            {/* Fournisseur Pages */}
            <Route
              path="app/referencement/fournisseurs"
              element={<ListeFournisseurs />}
            />
            <Route
              path="app/referencement/fournisseurs/:code/*"
              element={<Fournisseur />}
            />
            <Route
              path={`${APPLICATIONS}/${REFERENCEMENT}/${REFERENCEMENT_FOURNISSEURS}/${PARAMETRES}/${REMISE}`}
              element={<Remise />}
            />
            <Route
              path={`${APPLICATIONS}/${REFERENCEMENT}/${REFERENCEMENT_FOURNISSEURS}/${PARAMETRES}/${CONTACT}`}
              element={<Contact />}
            />
            <Route
              path={`${APPLICATIONS}/${REFERENCEMENT}/${REFERENCEMENT_ARTICLES}/${PARAMETRES}/${NOMENCLATURE_DOUANIERE}`}
              element={<NomenclatureDouaniere />}
            />
            {/* Nomenclature Page */}
            <Route
              path="app/referencement/nomenclature"
              element={<Nomenclature />}
            />

            {/* Marques Page */}
            <Route path="app/referencement/marques" element={<Marques />} />

            {/* Completude Page */}
            <Route
              path="app/referencement/completude-articles"
              element={<Completude />}
            />

            {/* Referentiel Paramètres Page */}
            <Route
              path="app/referencement/referentiel/parametres"
              element={<GeneralParametres />}
            />
            <Route
              path="app/referencement/referentiel/parametres/devise"
              element={<Devise />}
            />
            <Route
              path="app/referencement/referentiel/parametres/pays"
              element={<Pays />}
            />
            <Route
              path="app/referencement/referentiel/parametres/taux_tva"
              element={<Tva />}
            />

            {/* Fournisseurs Paramètres Page */}
            <Route
              path="app/referencement/fournisseurs/parametres"
              element={<FournisseurParametres />}
            />
          </Route>

          {/* OAuthCallback */}
          <Route path="callback" element={<OAuthRedirectCallback />} />

          {/* Login Page */}
          <Route path="login" element={<Login />} />

          {/* Errors Pages */}
          {/* <Route path="*" element={<404 />} /> */}

          <Route path="*" element={<>Error404</>} />
        </Routes>
      </Layout>
    </>
  );
}

export default App;
