import { FC, createRef, useEffect, useState } from 'react'
import JsBarcode from 'jsbarcode'

export interface Options {
    width?: number;
    height?: number;
    format?:
    | "CODE39"
    | "CODE128"
    | "EAN13"
    | "ITF14"
    | "MSI"
    | "pharmacode"
    | "codabar"
    | "upc";
    renderer?:
    | "svg"
    | "canvas"
    | "img"
    displayValue?: boolean;
    fontOptions?: string;
    font?: string;
    textAlign?: string;
    textPosition?: string;
    textMargin?: number;
    fontSize?: number;
    background?: string;
    lineColor?: string;
    margin?: number;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
}

export interface BarcodeProps extends Options {
    value: string;
}

const Barcode: FC<BarcodeProps> = (props) => {
    const [libProps, setLibProps] = useState<BarcodeProps | Options>({
        format: 'CODE128',
        renderer: 'svg',
        width: 2,
        height: 100,
        displayValue: true,
        fontOptions: '',
        font: 'monospace',
        textAlign: 'center',
        textPosition: 'bottom',
        textMargin: 2,
        fontSize: 20,
        background: '#ffffff',
        lineColor: '#000000',
        margin: 10,
    })
    const renderElementRef = createRef<any>();

    useEffect(() => {
        setLibProps(old => ({ ...old, ...props }))
    }, [props])

    useEffect(() => {
        const renderElement = renderElementRef.current;
        try {
            JsBarcode(renderElement, props.value, Object.assign({}, props));
        } catch (e) {
            // prevent stop the parent process
            console.error(e)
        }
    }, [libProps])

    if (props.renderer === 'canvas') {
        return (
            <canvas ref={renderElementRef} />
        )
    } else if (props.renderer === 'img') {
        return (
            <img ref={renderElementRef} alt={"barcode " + String(props.value)}  />
        )
    }
    return (
        <svg ref={renderElementRef} />
    )
}

export default Barcode