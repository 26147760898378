import { Dispatch } from "react";

import i18next from "i18next";

import { toast } from "utils";

export function trimOrNullValidator(value: string | null | undefined, label: string, setter?: { state: any, dispatch: Dispatch<any>, field: string; }): string | null {
  value = value ? value.trim() : null;
  !value && toast.error(`${i18next.t(label)}: ${i18next.t('err_champ_obligatoire')}`);
  setter && setter.dispatch({ ...setter.state, [setter.field]: value });
  return value;
}