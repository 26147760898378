import { FunctionComponent } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import General from 'assets/icons/tabs/general.svg';
import Lieux from 'assets/icons/tabs/droits-taxes.svg';
import Circuit from 'assets/icons/tabs/approvisionnement.svg';
import Conditions from 'assets/icons/tabs/achats.svg';
import GED from 'assets/icons/tabs/approvisionnement.svg';
import Referentiel from 'assets/icons/tabs/nomenclature.svg';
import Historique from 'assets/icons/tabs/assortiment.svg';

interface TabProps {
  id: string,
  title: string,
  icon: string,
}

const Tab: FunctionComponent<TabProps> = ({ id, title, icon }) => {
  const { t } = useTranslation();

  return (
    <NavLink to={id} tabIndex={4} end
      // tabIndex={active ? -1 : 4}
      className={({ isActive }) => {
        const isClass = ' justify-center px-4 flex items-center transition-all cursor-pointer outline-offset-[-1px]';
        if (isActive) return 'h-14 rounded-t-lg border border-b-0 border-grey-150 text-store-primary drop-shadow bg-white-500' + isClass;
        else return 'h-12 border border-b-0 border-transparent mb-px' + isClass;
      }} >
      {/* <Tooltip title={`Complétude: ${completude}%`} followCursor> */}
      <div className="flex gap-1 h-7 border-b-2 border-purple-150 relative">
        <span>
          <img className="h-4 w-4" style={{ minWidth: '1rem' }} src={icon} alt={t(title)} />
        </span>
        <span className="whitespace-nowrap">{t(title)}</span>
        {/* <div className={`absolute -bottom-0.5 left-0 h-0.5 rounded-full
              ${completude === 100 ? 'bg-green-500' : completude < 50 ? 'bg-red-500' : 'bg-orange-400'}`}
              style={{width: `${completude > 2 ? completude : '2'}%`}}></div> */}
        <div className={`absolute -bottom-0.5 left-0 h-0.5 rounded-full bg-store-primary w-full`}></div>
      </div>
      {/* </Tooltip> */}
    </NavLink>
  );
};

const TabsList = [
  { id: 'general', title: 'ong_fiche_fournisseur_general', icon: General, tab: "1" },
  { id: 'lieux', title: 'ong_fiche_fournisseur_lieu_fonction', icon: Lieux, tab: "2" },
  { id: 'circuit', title: 'ong_fiche_fournisseur_circuit_logistique', icon: Circuit, tab: "3" },
  { id: 'conditions', title: 'ong_fiche_fournisseur_condition_achat', icon: Conditions, tab: "4" },
  { id: 'ged', title: 'ong_fiche_fournisseur_ged', icon: GED, tab: "5" },
  { id: 'referentiel', title: 'ong_fiche_fournisseur_referentiel', icon: Referentiel, tab: "6" },
  { id: 'historique', title: 'ong_fiche_fournisseur_histo_commandes', icon: Historique, tab: "7" },
];

interface Props {

}

const FournisseurTabs: FunctionComponent<Props> = () => {

  return (
    <div id="product-tabs" className="col-span-full w-full mt-4 h-14 flex items-end relative">
      <div className="border rounded-t-lg border-b-0 border-grey-150 h-12 bg-slate-100 flex items-end absolute bottom-0 left-0 w-full"></div>
      <div className="w-full overflow-x-auto no-scrollbar mt-4 h-14 flex items-end text-xs font-bold relative">
        {TabsList.map((tab) => (
          <Tab key={tab.id} id={tab.id}
            title={tab.title}
            icon={tab.icon}
          />
        ))}
      </div>
      <div className="absolute w-screen -bottom-px left-0 h-px bg-grey-150"></div>
      <div className="absolute w-screen -bottom-px right-0 h-px bg-grey-150"></div>
    </div>
  );
};

export default FournisseurTabs;