import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ColDef } from "ag-grid-community";
import { Link } from "react-router-dom";

import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import { CheckedStyledCellRenderer, ModalCellRendererChildrenProps, NumberCellRenderer } from "components/AGGride/CellRender";
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { GridType as DataProps } from ".";
import { AgGridReact } from "ag-grid-react";
import { useGridController } from "hooks/";
import { fixFloating } from "utils/";
import { articlesApi, circuitLogistiquesApi, fournisseursApi, modeApprovisionnementsApi, sequenceDePrixApi, tiersApi } from "api/";
import {
  ArticleViewModel,
  FournisseurViewModel,
  ModeApprovisionnementViewModel,
  SdpTraceMatrice,
  SequenceDePrixComputeParamsModel,
  TierViewModel
} from "openapi-typescript-codegen";
import { findTypeAccordTranslationKey } from "pages/Remise/type-accord";
import { findSensTranslationKey } from "pages/Remise/sens";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  maxWidth: 130,
};

type GridType = SdpTraceMatrice;

const DetailCalculPrixAchatModal: FC<ModalCellRendererChildrenProps<DataProps>> = ({ setIsOpen, agGridRow }) => {
  const { t } = useTranslation();
  const [article, setArticle] = useState<ArticleViewModel | null>(null);
  const [fournisseur, setFournisseur] = useState<FournisseurViewModel | null>(null);
  const [tier, setTier] = useState<TierViewModel | null>(null);
  const [modeApprovisionnement, setModeApprovisionnement] = useState<ModeApprovisionnementViewModel | null>(null);
  const relationFour = agGridRow.data?.approTarifaire;

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {
        sortColId: "dateDebut",
        floatingAction: 'none',
      },
      autoFetch: true,
      fetchData: async () => {
        if (!relationFour?.fkArticle || !relationFour?.circuit?.id) return [];
        const { data: res } = await sequenceDePrixApi
          .detailsPost({ relationFournisseurId: relationFour.id, articleId: relationFour.fkArticle, circuitLogistiqueId: relationFour.circuit.id } as SequenceDePrixComputeParamsModel);
        return (res.remises || []);
      },
      columnDefs: [
        {
          field: "code",
          headerName: t('lib_remise_code'),
          headerTooltip: t('lib_remise_code'),
        },
        {
          field: "nomRemise",
          headerName: t('lib_remise_nom_remise'),
          headerTooltip: t('lib_remise_nom_remise'),
          tooltipValueGetter: (params) => params.value,
        },
        {
          field: "typeAccord",
          headerName: t('lib_remise_type_accord'),
          headerTooltip: t('lib_remise_type_accord'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueFormatter: (param) => t(findTypeAccordTranslationKey(param.value)),
        },
        {
          field: "ordreAchat",
          headerName: t('lib_condition_achat_ordre_achat'),
          headerTooltip: t('lib_condition_achat_ordre_achat'),
        },
        {
          field: "assiette",
          headerName: t('lib_condition_achat_assiette'),
          headerTooltip: t('lib_condition_achat_assiette'),
        },
        {
          field: "sens",
          headerName: t('lib_remise_sens'),
          headerTooltip: t('lib_remise_sens'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueFormatter: (param) => t(findSensTranslationKey(param.value)),
        },
        ...[
          { field: 1 },
          { field: 2 },
          { field: 3 },
          { field: 4 },
          { field: 5 },
          { field: 6 },
          { field: 7 },
          { field: 8 },
          { field: 9 },
          { field: 10 },
          { field: 11 }
        ].map(val => ({
          field: `column.${val.field - 1}`,
          headerName: t(`lib_remise_fl_modif_px${val.field}`),
          headerTooltip: t(`lib_remise_fl_modif_px${val.field}`),
          children: [
            {
              field: `column.${val.field - 1}.applicable`,
              headerName: t(`lib_remise_fl_modif_px${val.field}`),
              headerTooltip: t(`lib_remise_fl_modif_px${val.field}`),
              columnGroupShow: "open",
              cellRenderer: CheckedStyledCellRenderer,
              cellRendererParams: {
                isPointer: false,
              },
              width: 80,
            },
            {
              field: `column.${val.field - 1}.basePrice`,
              headerName: t("lib_prix_achat_px_achat_retenu"),
              headerTooltip: t('lib_prix_achat_px_achat_retenu'),
              columnGroupShow: "open",
              width: 110,
            },
            {
              field: `column.${val.field - 1}.remiseAmount`,
              headerName: t("lib_prix_achat_valeur"),
              headerTooltip: t('lib_prix_achat_valeur'),
              columnGroupShow: "open",
              width: 110,
            },
            {
              field: `column.${val.field - 1}.value`,
              headerName: t("lib_prix_final"),
              headerTooltip: t('lib_prix_final'),
              width: 110,
              cellRenderer: NumberCellRenderer,
              cellRendererParams: {
                floating: 3,
              },
            },
          ],
        })),
      ],
    }), [relationFour, t])
  );

  useEffect(() => {
    fetchArticle();
    fetchTier();
    fetchMode();
  }, [relationFour]);

  async function fetchArticle() {
    if (!relationFour?.fkArticle) return;
    const { data: article } = await articlesApi.v1ArticlesIdGet(relationFour.fkArticle);
    setArticle(article);
  }

  async function fetchTier() {
    if (!relationFour?.circuit?.id) return;
    const { data: circuit } = await circuitLogistiquesApi.v1CircuitLogistiquesIdGet(relationFour.circuit.id);
    if (!circuit.fkFournisseur) return;
    const { data: fournisseur } = await fournisseursApi.v1FournisseursIdGet(circuit.fkFournisseur);
    setFournisseur(fournisseur);
    if (!fournisseur.fkTiers) return;
    const { data: tier } = await tiersApi.v1TiersIdGet(fournisseur.fkTiers);
    setTier(tier);
  }

  async function fetchMode() {
    if (!relationFour?.circuit?.id) return;
    const { data: flux } = await circuitLogistiquesApi.v1CircuitLogistiquesIdFluxFournisseursDefaultGet(relationFour.circuit.id);
    if (!flux.fkModeApprovisionnement) return;
    const { data: modeApprovisionnement } = await modeApprovisionnementsApi.v1ModeApprovisionnementsIdGet(flux.fkModeApprovisionnement);
    setModeApprovisionnement(modeApprovisionnement);
  }

  function handleCancel() {
    setIsOpen(false);
  }

  return (
    <BlockGrid
      title={t('tab_detail_calcul_px_achat')}
      layout={LayoutEnum.modal}
      toActivate={true}
      handleCancel={handleCancel}
    >
      <div className="grid grid-cols-4 gap-4 mb-3">
        <div className="flex flex-col">
          <label className="text-grey-500 font-bold">{t('lib_remise_article')}</label>
          <div className="flex flex-col">
            <div>{article?.code}</div>
            <div>{article?.designationStandard}</div>
          </div>
        </div>
        <div>
          <label className="text-grey-500 font-bold">{t('lib_remise_fournisseur')}</label>
          <Link className="block text-store-primary underline cursor-pointer"
            target="_blank"
            to={`/app/referencement/fournisseurs/${fournisseur?.code}`}
            rel="noreferrer">
            {tier?.raisonSociale} ({fournisseur?.code})
          </Link>
        </div>
        <div>
          <label className="text-grey-500 font-bold">{t('lib_remise_circuit')}</label>
          <div>{modeApprovisionnement?.nomModeApprovisionnement} ({modeApprovisionnement?.code})</div>
        </div>
        <div>
          <label className="text-grey-500 font-bold">{t('lib_prix_achat_px_achat')}</label>
          <div>{fixFloating(relationFour?.prixAchat?.pxAchat, relationFour?.prixAchat?.devise?.decPxAchat)}</div>
        </div>
      </div>
      <div className="text-grey-500 font-bold mb-2">{t('tab_px_achat_remises')}</div>
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-modal">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default DetailCalculPrixAchatModal;