import { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useArticle, useUpdateArticle } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateArticle';

import { sitesApi, articlesApi, enseignesApi, departementsApi, rayonsApi, famillesApi, sousFamillesApi, uniteBesoinsApi } from 'api';
import { SiteEnseigneViewModel, ViewNomenclatureArticleViewModel, EnseigneViewModel, DepartementViewModel, RayonViewModel, FamilleViewModel, SousFamilleViewModel, UniteBesoinViewModel } from 'openapi-typescript-codegen';

import { DataUsageRounded } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { api } from "utils";

interface Props {
  onClick?: () => void;
}

interface Loader {
  enseigne: boolean;
  departement: boolean;
  rayon: boolean;
  famille: boolean;
  sousFamille: boolean;
  uniteBesoin: boolean;
}

const NomenclaturePresentation: FunctionComponent<Props> = ({ onClick }) => {
  const { state: article } = useArticle();
  const [enseignePrincipale, setEnseignePrincipale] = useState<number>();
  const [nomenclature, setNomenclature] = useState<ViewNomenclatureArticleViewModel>();
  const [enseigneSelected, setEnseigneSelected] = useState<EnseigneViewModel>();
  const [departementSelected, setDepartementSelected] = useState<DepartementViewModel>();
  const [rayonSelected, setRayonSelected] = useState<RayonViewModel>();
  const [familleSelected, setFamilleSelected] = useState<FamilleViewModel>();
  const [sousFamilleSelected, setSousFamilleSelected] = useState<SousFamilleViewModel>();
  const [uniteBesoinSelected, setUniteBesoinSelected] = useState<UniteBesoinViewModel>();
  const [loading, setLoading] = useState<Loader>({
    enseigne: true,
    departement: true,
    rayon: true,
    famille: true,
    sousFamille: true,
    uniteBesoin: true,
  });

  const refresh = useCallback(($event: UpdateEnum) => {
    if ($event === UpdateEnum.nomenclature) {
      (article?.id && enseignePrincipale) && refreshNomenclature(article.id, enseignePrincipale);
    }
  }, [article?.id, enseignePrincipale]);
  useUpdateArticle(refresh);

  const fetchEnseignePrincipal = async () => {
    const { data: response } = await sitesApi.v1SitesIdSiteEnseignesGet(1); // change value id with the site
    const tmp = response.find((item: SiteEnseigneViewModel) => {
      return item.flPrincipal === true;
    })?.fkEnseigne;
    setEnseignePrincipale(tmp);
  };

  const refreshNomenclature = async (id: number, enseigne: number) => {
    const nomenclatures = await api.dataset.get<ViewNomenclatureArticleViewModel>(articlesApi.v1ArticlesIdNomenclatureArticlesGet(id));
    const $nomenclature = nomenclatures.find(nomenclature=> nomenclature.fkEnseigne === enseigne) || nomenclatures[0];
    setNomenclature($nomenclature);
  };

  const fetchGetEnseigne = async (id: number) => {
    const { data: response } = await enseignesApi.v1EnseignesIdGet(id);
    setEnseigneSelected(response);
    setLoading((prevState) => { return ({ ...prevState, enseigne: false }); });
  };
  const fetchGetDepartement = async (id: number) => {
    const { data: response } = await departementsApi.v1DepartementsIdGet(id);
    setDepartementSelected(response);
    setLoading((prevState) => { return ({ ...prevState, departement: false }); });
  };
  const fetchGetRayon = async (id: number) => {
    const { data: response } = await rayonsApi.v1RayonsIdGet(id);
    setRayonSelected(response);
    setLoading((prevState) => { return ({ ...prevState, rayon: false }); });
  };
  const fetchGetFamille = async (id: number) => {
    const { data: response } = await famillesApi.v1FamillesIdGet(id);
    setFamilleSelected(response);
    setLoading((prevState) => { return ({ ...prevState, famille: false }); });
  };
  const fetchGetSousFamille = async (id: number) => {
    const { data: response } = await sousFamillesApi.v1SousFamillesIdGet(id);
    setSousFamilleSelected(response);
    setLoading((prevState) => { return ({ ...prevState, sousFamille: false }); });
  };
  const fetchGetUniteBesoin = async (id: number) => {
    const { data: response } = await uniteBesoinsApi.v1UniteBesoinsIdGet(id);
    setUniteBesoinSelected(response);
    setLoading((prevState) => { return ({ ...prevState, uniteBesoin: false }); });
  };

  useEffect(() => {
    if (nomenclature) {
      if (nomenclature?.fkEnseigne) fetchGetEnseigne(nomenclature.fkEnseigne);
      if (nomenclature?.fkDepartement) fetchGetDepartement(nomenclature.fkDepartement);
      if (nomenclature?.fkRayon) fetchGetRayon(nomenclature.fkRayon);
      if (nomenclature?.fkFamille) fetchGetFamille(nomenclature.fkFamille);
      if (nomenclature?.fkSousFamille) fetchGetSousFamille(nomenclature.fkSousFamille);
      if (nomenclature?.fkUniteBesoin) fetchGetUniteBesoin(nomenclature.fkUniteBesoin);
    }
  }, [nomenclature]);

  useEffect(() => {
    if (enseignePrincipale && article?.id) refreshNomenclature(article.id, enseignePrincipale);
  }, [enseignePrincipale, article?.id]);

  useEffect(() => {
    fetchEnseignePrincipal();
  }, []);

  const Loader = () => {
    return (
      <DataUsageRounded className="animate-spin text-grey-150 self-center" />
    );
  };

  return (
    <div className="row-span-1 w-full h-full bg-white-500 cursor-pointer rounded-br-md" onClick={() => { !!onClick && onClick(); }}>
      <div className="w-full h-full items-center justify-between mt-auto text-xs grid"
        style={{ gridTemplateColumns: "repeat(35, minmax(0, 1fr))" }}>
        <div className="py-1 px-4 gap-2 flex items-center col-span-5">
          {loading.enseigne && nomenclature?.fkEnseigne ? (
            <Loader />
          ) : enseigneSelected?.nomEnseigne ? (
            <>
              <div className="bg-store-primary rounded text-white-500 px-2 h-6 flex justify-center items-center"
                style={{ minWidth: "1.7rem" }} >
                {enseigneSelected.code}
              </div>
              <Tooltip title={enseigneSelected.code + " - " + enseigneSelected.nomEnseigne} followCursor>
                <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                  {enseigneSelected.nomEnseigne}
                </span>
              </Tooltip>
            </>
          ) : ("...")}
        </div>
        <svg
          className="h-3 col-span-1 justify-self-center"
          viewBox="0 0 7 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 10L5.5 5.5L1 1"
            stroke="#6C727F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="py-1 px-4 gap-2 flex items-center col-span-5">
          {loading.departement &&
            nomenclature?.fkDepartement ? (
            <Loader />
          ) : departementSelected?.nomDepartement ? (
            <>
              <div
                className="bg-store-primary rounded text-white-500 px-2 h-6 flex justify-center items-center"
                style={{ minWidth: "1.7rem" }}
              >
                {departementSelected.code}
              </div>
              <Tooltip
                title={
                  departementSelected.code + " - " + departementSelected.nomDepartement
                }
                followCursor
              >
                <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                  {departementSelected.nomDepartement}
                </span>
              </Tooltip>
            </>
          ) : ("...")}
        </div>
        <svg
          className="h-3 col-span-1 justify-self-center"
          viewBox="0 0 7 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 10L5.5 5.5L1 1"
            stroke="#6C727F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="py-1 px-4 gap-2 flex items-center col-span-5">
          {loading.rayon && nomenclature?.fkRayon ? (
            <Loader />
          ) : rayonSelected?.nomRayon ? (
            <>
              <div className="bg-store-primary rounded text-white-500 px-2 h-6 flex justify-center items-center"
                style={{ minWidth: "1.7rem" }}>
                {rayonSelected.code}
              </div>
              <Tooltip title={rayonSelected.code + " - " + rayonSelected.nomRayon} followCursor>
                <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                  {rayonSelected.nomRayon}
                </span>
              </Tooltip>
            </>
          ) : ("...")}
        </div>
        <svg
          className="h-3 col-span-1 justify-self-center"
          viewBox="0 0 7 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 10L5.5 5.5L1 1"
            stroke="#6C727F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="py-1 px-4 gap-2 flex items-center col-span-5">
          {loading.famille && nomenclature?.fkFamille ? (
            <Loader />
          ) : familleSelected?.nomFamille ? (
            <>
              <div className="bg-store-primary rounded text-white-500 px-2 h-6 flex justify-center items-center"
                style={{ minWidth: "1.7rem" }}>
                {familleSelected.code}
              </div>
              <Tooltip title={familleSelected.code + " - " + familleSelected.nomFamille} followCursor>
                <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                  {familleSelected.nomFamille}
                </span>
              </Tooltip>
            </>
          ) : ("...")}
        </div>
        <svg
          className="h-3 col-span-1 justify-self-center"
          viewBox="0 0 7 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 10L5.5 5.5L1 1"
            stroke="#6C727F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="py-1 px-4 gap-2 flex items-center col-span-5">
          {loading.sousFamille && nomenclature?.fkSousFamille ? (
            <Loader />
          ) : sousFamilleSelected?.nomSf ? (
            <>
              <div className="bg-store-primary rounded text-white-500 px-2 h-6 flex justify-center items-center"
                style={{ minWidth: "1.7rem" }}>
                {sousFamilleSelected.code}
              </div>
              <Tooltip title={sousFamilleSelected.code + " - " + sousFamilleSelected.nomSf} followCursor>
                <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                  {sousFamilleSelected.nomSf}
                </span>
              </Tooltip>
            </>
          ) : ("...")}
        </div>
        <svg
          className="h-3 col-span-1 justify-self-center"
          viewBox="0 0 7 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 10L5.5 5.5L1 1"
            stroke="#6C727F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="py-1 px-4 gap-2 flex items-center col-span-5">
          {loading.uniteBesoin && nomenclature?.fkUniteBesoin ? (
            <Loader />
          ) : uniteBesoinSelected?.nomUb ? (
            <>
              <div className="bg-store-primary rounded text-white-500 px-2 h-6 flex justify-center items-center"
                style={{ minWidth: "1.7rem" }}>
                {uniteBesoinSelected.code}
              </div>
              <Tooltip title={uniteBesoinSelected.code + " - " + uniteBesoinSelected.nomUb} followCursor>
                <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                  {uniteBesoinSelected.nomUb}
                </span>
              </Tooltip>
            </>
          ) : ("...")}
        </div>
      </div>
    </div>
  );
};

export default NomenclaturePresentation;