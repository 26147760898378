import { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'utils';

import BlockGrid, { PropsBlock } from 'components/BlockGrid';

import { articlesApi } from 'api';

import { useTranslation } from "react-i18next";
import { useArticle, useCompletudeArticle, useUpdateArticle } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateArticle';
import { CheckInput, isMissing } from 'utils/Article/completude';

interface FormArray {
  id?: number,
  designationStandard: string,
  designationCourt: string,
  designationLong: string,
  designationGondole: string,
  designationCaisse: string;
}
const defaultImage = "/assets/images/default.png";

const Libelles: FunctionComponent<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const uA = useUpdateArticle();
  const { state: controled } = useCompletudeArticle();
  const [completude, setCompletude] = useState<number>(0);
  const [activate, setActivate] = useState<boolean>(false);
  const [hasFocus, setFocus] = useState(false);
  const [form, setForm] = useState<FormArray>({
    designationStandard: "",
    designationCourt: "",
    designationLong: "",
    designationGondole: "",
    designationCaisse: ""
  });
  const [loading, setLoader] = useState<boolean>(true);
  const [warning, _setWarning] = useState<boolean>(false);
  // const [checker, setChecker] = useState<FormArray>({
  //   designationStandard: "",
  //   designationCourt: "",
  //   designationLong: "",
  //   designationGondole: "",
  //   designationCaisse: ""
  // })

  function checkCompletude() {
    let tmp = 0;
    if (isMissing(form.designationStandard, controled?.find((i) => i.colonneArticle === 'article.designation_standard'))) tmp++;
    if (isMissing(form.designationLong, controled?.find((i) => i.colonneArticle === 'article.designation_long'))) tmp++;
    if (isMissing(form.designationCourt, controled?.find((i) => i.colonneArticle === 'article.designation_court'))) tmp++;
    if (isMissing(form.designationCaisse, controled?.find((i) => i.colonneArticle === 'article.designation_caisse'))) tmp++;
    if (isMissing(form.designationGondole, controled?.find((i) => i.colonneArticle === 'article.designation_gondole'))) tmp++;
    setCompletude(tmp);
  }

  const fetchGetData = async (id: number) => {
    const response = await articlesApi.v1ArticlesIdGet(id);
    let tmp = {
      id: response.data.id,
      designationStandard: response.data.designationStandard ?? '',
      designationCourt: response.data.designationCourt ?? '',
      designationLong: response.data.designationLong ?? '',
      designationGondole: response.data.designationGondole ?? '',
      designationCaisse: response.data.designationCaisse ?? ''
    };
    setLoader(false);
    setForm(tmp);
    // setChecker(tmp)
  };

  const fetchUpdateData = async (id: number) => {
    const tmp: string = JSON.stringify({
      designationStandard: form.designationStandard,
      designationCourt: form.designationCourt,
      designationLong: form.designationLong,
      designationGondole: form.designationGondole,
      designationCaisse: form.designationCaisse
    });
    const response = await articlesApi.v1ArticlesIdPut(id, tmp);

    if (response.status >= 200 && response.status < 300) { //success
      toast.success(t('mes_validation_modification'));
      // reset dat with response.data ?
      uA.triggerEvent(UpdateEnum.designation);
      setActivate(false);
    }
    else
      toast.error(t('mes_erreur_technique') + ": " + response.status);
  };

  useEffect(() => {
    checkCompletude();
  }, [form]);

  useEffect(() => {
    if (article?.id) fetchGetData(article.id);
  }, [article?.id]);

  function validator() {
    let isValid = true;
    if (!CheckInput(form.designationStandard, controled?.find((i) => i.colonneArticle === 'article.designation_standard'), 'lib_article_designation_standard')) isValid = false;
    if (!CheckInput(form.designationLong, controled?.find((i) => i.colonneArticle === 'article.designation_long'), 'lib_article_designation_long')) isValid = false;
    if (!CheckInput(form.designationCourt, controled?.find((i) => i.colonneArticle === 'article.designation_court'), 'lib_article_designation_court')) isValid = false;
    if (!CheckInput(form.designationCaisse, controled?.find((i) => i.colonneArticle === 'article.designation_caisse'), 'lib_article_designation_caisse')) isValid = false;
    if (!CheckInput(form.designationGondole, controled?.find((i) => i.colonneArticle === 'article.designation_gondole'), 'lib_article_designation_gondole')) isValid = false;
    return isValid;
  }

  function handleUpdate() {
    if (validator()
      // && checker()
      && form.id) fetchUpdateData(form.id);
  }

  function handleCancel() {
    if (article?.id) fetchGetData(article.id);
    setActivate(false);
  }

  function handleFocus(focus: boolean) {
    setFocus(focus);
  }

  return (
    <BlockGrid
      title={t('tab_article_designation')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      img={defaultImage}
      completude={completude}
      warning={warning}
      loading={loading}
      handleClick={(res: boolean) => setActivate(res)}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      handleFocus={handleFocus}
    >
      <form className="flex flex-col justify-between">
        <div className="">
          <label className="block mb-2">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_article_designation_standard')}
                {controled?.find((i) => i.colonneArticle === 'article.designation_standard')?.required && <span className="text-red-500">**</span>}
                {controled?.find((i) => i.colonneArticle === 'article.designation_standard')?.recommended && <span className="text-orange-500">*</span>}
              </span>
              {/* <span>{form?.designationStandard.length}/{controled?.find((i) => i.colonneArticle === 'designationStandard')?.typeValidation ?? 200}</span> */}
              <span>{form?.designationStandard.length}/{200}</span>
            </span>
            <input type="text" tabIndex={5}
              value={form?.designationStandard}
              // maxLength={controled?.find((i) => i.colonneArticle === 'designationStandard')?.typeValidation ?? 200}
              maxLength={200}
              onChange={e => setForm({ ...form, designationStandard: e.currentTarget.value })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!(controled?.find((i) => i.colonneArticle === 'article.designation_standard')?.required && form.designationStandard === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                  ${!!(controled?.find((i) => i.colonneArticle === 'article.designation_standard')?.recommended && form.designationStandard === '') && 'border-orange-500 bg-orange-500/10'}
                `}
            />
          </label>
          <label className="block mb-2">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_article_designation_long')}
                {controled?.find((i) => i.colonneArticle === 'article.designation_long')?.required && <span className="text-red-500">**</span>}
                {controled?.find((i) => i.colonneArticle === 'article.designation_long')?.recommended && <span className="text-orange-500">*</span>}
              </span>
              {/* <span>{form?.designationLong.length}/{controled?.designation_long?.length ?? 200}</span> */}
              <span>{200}</span>
            </span>
            <input type="text" tabIndex={5}
              value={form?.designationLong}
              // maxLength={controled?.designation_long?.length ?? 200}
              maxLength={200}
              onChange={e => setForm({ ...form, designationLong: e.currentTarget.value })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!(controled?.find((i) => i.colonneArticle === 'article.designation_long')?.required && form.designationLong === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                  ${!!(controled?.find((i) => i.colonneArticle === 'article.designation_long')?.recommended && form.designationLong === '') && 'border-orange-500 bg-orange-500/10'}
                `}
            />
          </label>
          <label className="block mb-2">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_article_designation_court')}
                {controled?.find((i) => i.colonneArticle === 'article.designation_court')?.required && <span className="text-red-500">**</span>}
                {controled?.find((i) => i.colonneArticle === 'article.designation_court')?.recommended && <span className="text-orange-500">*</span>}
              </span>
              {/* <span>{form?.designationCourt.length}/{controled?.designation_court?.length ?? 100}</span> */}
              <span>{100}</span>
            </span>
            <input type="text" tabIndex={5}
              value={form?.designationCourt}
              // maxLength={controled?.designation_court?.length ?? 100}
              maxLength={100}
              onChange={e => setForm({ ...form, designationCourt: e.currentTarget.value })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!(controled?.find((i) => i.colonneArticle === 'article.designation_court')?.required && form.designationCourt === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                  ${!!(controled?.find((i) => i.colonneArticle === 'article.designation_court')?.recommended && form.designationCourt === '') && 'border-orange-500 bg-orange-500/10'}
                `}
            />
          </label>
          <label className="block mb-2">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_article_designation_caisse')}
                {controled?.find((i) => i.colonneArticle === 'article.designation_caisse')?.required && <span className="text-red-500">**</span>}
                {controled?.find((i) => i.colonneArticle === 'article.designation_caisse')?.recommended && <span className="text-orange-500">*</span>}
              </span>
              {/* <span>{form?.designationCaisse.length}/{controled?.designation_caisse?.length ?? 30}</span> */}
              <span>{form?.designationCaisse.length}/{30}</span>
            </span>
            <input type="text" tabIndex={5}
              value={form?.designationCaisse}
              // maxLength={controled?.designation_caisse?.length ?? 30}
              maxLength={30}
              onChange={e => setForm({ ...form, designationCaisse: e.currentTarget.value })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!(controled?.find((i) => i.colonneArticle === 'article.designation_caisse')?.required && form.designationCaisse === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                  ${!!(controled?.find((i) => i.colonneArticle === 'article.designation_caisse')?.recommended && form.designationCaisse === '') && 'border-orange-500 bg-orange-500/10'}
                `}
            />
          </label>
          <label className="block mb-2">
            <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
              <span>
                {t('lib_article_designation_gondole')}
                {controled?.find((i) => i.colonneArticle === 'article.designation_gondole')?.required && <span className="text-red-500">**</span>}
                {controled?.find((i) => i.colonneArticle === 'article.designation_gondole')?.recommended && <span className="text-orange-500">*</span>}
              </span>
              {/* <span>{form?.designationGondole.length}/{controled?.designation_gondole?.length ?? 50}</span> */}
              <span>{form?.designationGondole.length}/{50}</span>
            </span>
            <input type="text" tabIndex={5}
              value={form?.designationGondole}
              // maxLength={controled?.designation_gondole?.length ?? 50}
              maxLength={50}
              onChange={e => setForm({ ...form, designationGondole: e.currentTarget.value })}
              disabled={!activate}
              className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!(controled?.find((i) => i.colonneArticle === 'article.designation_gondole')?.required && form.designationGondole === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
                  ${!!(controled?.find((i) => i.colonneArticle === 'article.designation_gondole')?.recommended && form.designationGondole === '') && 'border-orange-500 bg-orange-500/10'}
                `}
            />
          </label>
        </div>
      </form>
    </BlockGrid>
  );
};

export default Libelles;