export const snakeToCamel = (value: string) =>
  value.toLowerCase().replace(/([-_][a-z])/g, replacer =>
    replacer
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );

export const camelToSnake = (value: string) =>
  value.toLowerCase().replace(/([A-Z])/g, replacer =>
    replacer.replace(/[A-Z]/, `_${replacer.toLowerCase()}`)
  );