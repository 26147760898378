
import { FC, useMemo } from 'react';
import { TaxeViewModel } from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";

import { taxesApi } from 'api';
import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import { TextCellRenderer } from 'components/AGGride/CellRender';
import { useGridController } from "hooks";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

type GridType = TaxeViewModel;

const TableauxTaxe: FC<{ onTaxeChange?: (taxeId: number) => void; }> = ({ onTaxeChange }) => {
  const { t } = useTranslation();

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      fetchData: async () => {
        const { data: { data } } = await taxesApi.v1TaxesSearchPost(true, 1, 1000, { typeTaxe: "T" });
        return data.sort((a, b) => a.code.localeCompare(b.code));
      },
      columnDefs: [
        {
          field: 'code',
          tooltipField: 'code',
          headerName: t('lib_taxe_code'),
          headerTooltip: t('lib_taxe_code'),
          maxWidth: 80,
          cellRenderer: TextCellRenderer,
          filter: 'agTextColumnFilter',
        },
        {
          field: 'nomTaxe',
          tooltipField: 'nomTaxe',
          headerName: t('lib_taxe_nom_taxe'),
          headerTooltip: t('lib_taxe_nom_taxe'),
          cellRenderer: TextCellRenderer,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
      ],
    }), [t])
  );

  function onRowSelected(params: RowSelectedEvent<GridType>) {
    if (params.node.isSelected() && params.data?.id)
      onTaxeChange?.(params.data.id);
  };

  return (
    <div className="w-80">
      <BlockGrid
        layout={LayoutEnum.settings}
        toActivate={true}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-params">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType="fullRow"
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
            rowSelection="single"
            onRowSelected={onRowSelected}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </ div>
  );
};

export default TableauxTaxe;