import { FunctionComponent, useEffect, useState } from 'react';

import SelectComplete, { SelectTemplate } from 'components/SelectComplete';

import { enseignesApi, departementsApi, rayonsApi, famillesApi, sousFamillesApi } from 'api';
import { Delete } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

interface Props {
  value: any;
  onChange: (e: any) => void;
  activate: boolean;
  enseigneSelected: (string | number | null | undefined)[];
  isPrincipal?: boolean;
}

interface FormArray {
  id?: number;
  fkEnseigne: number,
  fkDepartement: number,
  fkRayon: number,
  fkFamille: number,
  fkSousFamille: number,
  fkUniteBesoin: number,
}

const NomenclatureLine: FunctionComponent<Props> = ({ value, onChange, activate, enseigneSelected, isPrincipal }) => {
  const [form, setForm] = useState<FormArray>({
    fkEnseigne: 0,
    fkDepartement: 0,
    fkRayon: 0,
    fkFamille: 0,
    fkSousFamille: 0,
    fkUniteBesoin: 0,
  });
  const [enseigneList, setEnseigneList] = useState<SelectTemplate[]>([]);
  const [departement, setDepartement] = useState<SelectTemplate[]>([]);
  const [rayon, setRayon] = useState<SelectTemplate[]>([]);
  const [famille, setFamille] = useState<SelectTemplate[]>([]);
  const [sousFamille, setSousFamille] = useState<SelectTemplate[]>([]);
  const [uniteBesoin, setUniteBesoin] = useState<SelectTemplate[]>([]);
  const [hasFocus, setFocus] = useState(false);

  const fetchGetUniteBesoin = async (id: number) => {
    const { data: response } = await sousFamillesApi.v1SousFamillesIdUnitebesoinGet(id);

    let tmp: SelectTemplate[] = [];
    if (response && response.length > 0) {
      tmp = [
        { value: null, label: "..." },
        ...response.map(
          (item) => ({ value: item.id, label: item.code + " - " + item.nomUb })
        )
      ];
    }

    setUniteBesoin(tmp);
  };


  const fetchGetSousFamille = async (id: number) => {
    const { data: response } = await famillesApi.v1FamillesIdSousfamillesGet(id);

    let tmp: SelectTemplate[] = [];
    if (response && response.length > 0) {
      tmp = [
        { value: null, label: "..." },
        ...response.map(
          (item) => ({ value: item.id, label: item.code + " - " + item.nomSf })
        )
      ];
    }

    setSousFamille(tmp);
  };

  const fetchGetFamille = async (id: number) => {
    const { data: response } = await rayonsApi.v1RayonsIdFamillesGet(id);

    let tmp: SelectTemplate[] = [];
    if (response && response.length > 0) {
      tmp = [
        { value: null, label: "..." },
        ...response.map(
          (item) => ({ value: item.id, label: item.code + " - " + item.nomFamille })
        )
      ];
    }

    setFamille(tmp);
  };

  const fetchGetRayon = async (id: number) => {
    const { data: response } = await departementsApi.v1DepartementsIdRayonsGet(id);

    let tmp: SelectTemplate[] = [];
    if (response && response.length > 0) {
      tmp = [
        { value: null, label: "..." },
        ...response.map(
          (item) => ({ value: item.id, label: item.code + " - " + item.nomRayon })
        )
      ];
    }

    setRayon(tmp);
  };

  const fetchGetDepartement = async (id: number) => {
    const { data: response } = await enseignesApi.v1EnseignesIdDepartementsGet(id);

    let tmp: SelectTemplate[] = [];
    if (response && response.length > 0) {
      tmp = [
        { value: null, label: "..." },
        ...response.map(
          (item) => ({ value: item.id, label: item.code + " - " + item.nomDepartement })
        )
      ];
    }

    setDepartement(tmp);
  };

  const fetchGetEnseigne = async () => {
    const { data: { data } } = await enseignesApi.v1EnseignesGet(1, 50);

    if (!data || !data.length) {
      setEnseigneList([]);
      return;
    }
    setEnseigneList([
      { value: undefined, label: "..." },
      ...data.map(
        (item) => ({ value: item.id, label: item.code + " - " + item.nomEnseigne })
      )
    ]);
  };

  const onDelete = async (e: any) => {
    onChange(e);
  };

  useEffect(() => {
    fetchGetEnseigne();
  }, []);

  useEffect(() => {
    setForm(value);
  }, [value]);

  useEffect(() => {
    setUniteBesoin([]);
    if (form.fkSousFamille) fetchGetUniteBesoin(form.fkSousFamille);
  }, [form.fkSousFamille]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUniteBesoin([]);
    setSousFamille([]);
    if (form.fkFamille) fetchGetSousFamille(form.fkFamille);
  }, [form.fkFamille]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUniteBesoin([]);
    setSousFamille([]);
    setFamille([]);
    if (form.fkRayon) fetchGetFamille(form.fkRayon);
  }, [form.fkRayon]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUniteBesoin([]);
    setSousFamille([]);
    setFamille([]);
    setRayon([]);
    if (form.fkDepartement) fetchGetRayon(form.fkDepartement);
  }, [form.fkDepartement]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUniteBesoin([]);
    setSousFamille([]);
    setFamille([]);
    setRayon([]);
    setDepartement([]);
    if (form.fkEnseigne) fetchGetDepartement(form.fkEnseigne);
  }, [form.fkEnseigne]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full flex"
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onMouseOver={() => setFocus(true)}
      onMouseOut={() => setFocus(false)}
    >
      <div className={`grid grid-cols-24 w-full gap-2 relative ${activate && 'pr-4'} transition-all`}>
        <label className="block col-span-4 mb-2 flex-1 pr-6 relative">
          <SelectComplete tabIndex={5}
            isRequired={activate && form.fkEnseigne === null}
            isDisabled={!activate}
            options={enseigneList.filter((option: any) => !enseigneSelected.includes(option.value))}
            value={enseigneList.filter(option => {
              return option.value === form.fkEnseigne;
            })}
            onChange={(e: any): void => onChange(
              {
                ...form,
                fkEnseigne: e.value,
                fkDepartement: null,
                fkRayon: null,
                fkFamille: null,
                fkSousFamille: null,
                fkUniteBesoin: null
              })}
            classNameAdd={isPrincipal ? 'border-black' : ''}
          />
          <svg className="h-3 mt-4h-3 absolute right-1 top-4"
            viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </label>
        <label className="block col-span-4 mb-2 flex-1 pr-6 relative">
          <SelectComplete tabIndex={5}
            isRequired={activate && form.fkDepartement === null}
            isDisabled={!activate}
            options={departement}
            value={departement.filter(option => {
              return option.value === form.fkDepartement;
            })}
            onChange={(e: any): void => onChange(
              {
                ...form,
                fkDepartement: e.value,
                fkRayon: null,
                fkFamille: null,
                fkSousFamille: null,
                fkUniteBesoin: null
              })}
            classNameAdd={isPrincipal ? 'border-black' : ''}
          />
          <svg className="h-3 absolute right-1 top-4"
            viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </label>
        <label className="block col-span-4 mb-2 flex-1 pr-6 relative">
          <SelectComplete tabIndex={5}
            isRequired={activate && form.fkRayon === null}
            isDisabled={!activate}
            options={rayon}
            value={rayon.filter(option => {
              return option.value === form.fkRayon;
            })}
            onChange={(e: any): void => onChange(
              {
                ...form,
                fkRayon: e.value,
                fkFamille: null,
                fkSousFamille: null,
                fkUniteBesoin: null
              })}
            classNameAdd={isPrincipal ? 'border-black' : ''}
          />
          <svg className="h-3 absolute right-1 top-4"
            viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </label>
        <label className="block col-span-4 mb-2 flex-1 pr-6 relative">
          <SelectComplete tabIndex={5}
            isRequired={activate && form.fkFamille === null}
            isDisabled={!activate}
            options={famille}
            value={famille.filter(option => {
              return option.value === form.fkFamille;
            })}
            onChange={(e: any): void => onChange(
              {
                ...form,
                fkFamille: e.value,
                fkSousFamille: null,
                fkUniteBesoin: null
              })}
            classNameAdd={isPrincipal ? 'border-black' : ''}
          />
          <svg className="h-3 absolute right-1 top-4"
            viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </label>
        <label className="block col-span-4 mb-2 flex-1 pr-6 relative">
          <SelectComplete tabIndex={5}
            isRequired={activate && form.fkSousFamille === null}
            isDisabled={!activate}
            options={sousFamille}
            value={sousFamille.filter(option => {
              return option.value === form.fkSousFamille;
            })}
            onChange={(e: any): void => onChange(
              {
                ...form,
                fkSousFamille: e.value,
                fkUniteBesoin: null
              })}
            classNameAdd={isPrincipal ? 'border-black' : ''}
          />
          <svg className="h-3 absolute right-1 top-4"
            viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10L5.5 5.5L1 1" stroke="#6C727F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </label>
        <label className="block col-span-4 mb-2 flex-1 pr-6 relative">
          <SelectComplete tabIndex={5}
            isDisabled={!activate}
            options={uniteBesoin}
            value={uniteBesoin.filter(option => {
              return option.value === form.fkUniteBesoin;
            })}
            onChange={(e: any) => onChange({ ...form, fkUniteBesoin: e.value })}
            classNameAdd={isPrincipal ? 'border-black' : ''}
          />
        </label>
        {activate &&
          <Tooltip title={"Supprimer"} followCursor>
            <span className="absolute top-0 right-0">
              <button type="button" tabIndex={5}
                disabled={!activate}
                className={`w-8 h-8 mt-1 border rounded border-slate-300 text-grey-200 bg-white-500
                  ${hasFocus ? 'opacity-100' : 'opacity-0 pointer-events-none'}
                  hover:text-white-500 hover:bg-red-500 focus:text-white-500 focus:bg-red-500
                `}
                onClick={() => onDelete({ ...form, deleteData: true })}>
                <Delete />
              </button>
            </span>
          </Tooltip>
        }
      </div>
    </div>
  );
};

export default NomenclatureLine;