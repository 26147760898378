import { ColumnControl } from "validators";

interface ColumnControlArticleViewModel {
  colonneArticle: string;
  required?: boolean;
  recommended?: boolean;
  typeControle?: string;
  typeValidation: string;
  isEmpty?: boolean;
  ongletArticle?: string | null;
}

export class ColumnControlArticle extends ColumnControl<any> {
  static controls: ColumnControlArticle[] = [];
  isEmpty?: boolean;
  ongletArticle?: string | null;

  constructor(column: string, required: boolean, recommended: boolean, typeValidation: string, isEmpty?: boolean, ongletArticle?: string | null) {
    super(column, String(), required, recommended, typeValidation);
    this.isEmpty = isEmpty;
    this.ongletArticle = ongletArticle;
  }

  static initialize(controls: ColumnControlArticleViewModel[]) : ColumnControlArticle[] {
    const instances = controls.map(control =>
      new ColumnControlArticle(
          control.colonneArticle!,
          control.required || control.typeControle === "O",
          control.recommended || control.typeControle === "R",
          control.typeValidation,
          control.isEmpty,
          control.ongletArticle,
      )
    );
    ColumnControlArticle.controls = instances;
    return instances;
  }

  static get = (column: string, controls?: ColumnControlArticle[]) => {
    const instances = controls || ColumnControlArticle.controls;
    return super.get(column, instances);
  }

  static getMany = (columns: string[], controls?: ColumnControlArticle[]) => {
    const instances = controls || ColumnControlArticle.controls;
    return super.getMany(columns, instances);
  }

  static getAll = () => ColumnControlArticle.controls;

  static checkMany = (columns: string[], model: any, controls?: ColumnControlArticle[]) => {
    const instances = controls || ColumnControlArticle.controls;
    return super.checkMany(columns, model, instances);
  }

  static checkAll = (model: any, controls?: ColumnControlArticle[]) => {
    const instances = controls || ColumnControlArticle.controls;
    return super.checkAll(model, instances);
  }

  static validateMany = (columns: string[], model: any, controls?: ColumnControlArticle[]) => {
    const instances = controls || ColumnControlArticle.controls;
    return super.validateMany(columns, model, instances);
  }

  static validateAll = (model: any, controls?: ColumnControlArticle[]) => {
    const instances = controls || ColumnControlArticle.controls;
    return super.validateAll(model, instances);
  }
}