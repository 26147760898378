import {FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import {SurMarqueViewModel} from "openapi-typescript-codegen";
import {useTranslation} from "react-i18next";
import {surMarquesApi} from 'api';
import {PropsBlock} from 'components/BlockGrid';
import {useGridController} from "hooks";
import {TextCellRenderer} from "components/AGGride/CellRender";
import MarquesContext from "../MarquesContext";
import BlockGridMarques from "../BlockGridMarques";
import SurMaqueModal from "../modals/SurMarqueModal";
import {
    SurMarqueViewModelTypoNomenclatureDetail
} from "openapi-typescript-codegen/types/models/sur-marque-view-model-typo-nomenclature-detail";

interface Props extends PropsBlock {
    surMarques: SurMarqueViewModelTypoNomenclatureDetail[];
    onSurMarqueChange: (id: number | null) => void;
}

type GridType = SurMarqueViewModel;

const SurMarque: FunctionComponent<Props> = ({ surMarques, onSurMarqueChange }) => {
    const { t } = useTranslation();
    const { selection, setRefresh } = useContext(MarquesContext);
    const [disableCreate, setDisableCreate] = useState(false);

    const gridController = useGridController<GridType>(
        useMemo(() => ({
            colConfig: {},
            autoFetch: true,
            emptyRowCheck: (rowData) => !rowData.id,
            fetchData: async () => surMarques.map(({ item }) => item),
            deleteData: (id) => surMarquesApi.v1SurMarquesIdDelete(id).then(() => {
                id === selection.surMarque ? onSurMarqueChange(null) : setRefresh(true);
            }),
            rowClassRules: {
                "hide-actions": params => {
                    const surMarque = surMarques.find(surMarque => surMarque.item.id === params.data?.id);
                    return !!(surMarque && (surMarque.childrenCount || surMarque.articleCount));
                },
            },
            columnDefs: [
                {
                    field: 'code',
                    headerName: t('lib_sur_marque_code'),
                    headerTooltip: t('lib_sur_marque_code'),
                    cellRenderer: TextCellRenderer,
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 1
                },
                {
                    field: 'nomTypologie',
                    headerName: t('lib_sur_marque_nom_sur_marque'),
                    headerTooltip: t('lib_sur_marque_nom_sur_marque'),
                    cellRenderer: TextCellRenderer,
                    valueFormatter: (params: any) => {
                        const surMarque = surMarques.find(surMarque => surMarque.item.id === params.data.id);
                        return surMarque ? `${surMarque.item.nomSurMarque} <${surMarque.childrenCount}>` : String();
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 4
                },
                {
                    headerName: t('lib_sur_marque_nb_articles'),
                    headerTooltip: t('lib_sur_marque_nb_articles'),
                    cellRenderer: TextCellRenderer,
                    valueFormatter: (params: any) => {
                        const surMarque = surMarques.find(surMarque => surMarque.item.id === params.data.id);
                        return surMarque ? surMarque.articleCount.toString() : String();
                    },
                    editable: false,
                    filter: 'agTextColumnFilter',
                    flex: 1
                },
            ],
        }), [surMarques, t])
    );

    useEffect(() => { gridController.fetchData(); }, [surMarques]);
    useEffect(() => { setDisableCreate(!selection.typologie); }, [selection.typologie]);

    return <BlockGridMarques
        label={"tab_detail_sur_marque"}
        gridController={gridController}
        onSelect={onSurMarqueChange}
        gridName={"surMarque"}
        disableCreate={disableCreate}
    ><SurMaqueModal/></BlockGridMarques>;
};

export default SurMarque;