import { FunctionComponent, useState, useMemo, useEffect } from 'react';

import {
  FournisseurWithTierViewModel,
  IncotermViewModel,
  LieuEmbarquementCircuitInsertionModel,
  LieuEmbarquementCircuitViewModel,
  MatriceFraisWithNamesViewModel
} from 'openapi-typescript-codegen';

import 'app/ag-grid-style.css';
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';

import { useGridController } from "hooks";

import { useTranslation } from "react-i18next";

import {
  circuitLogistiquesApi, fournisseursApi,
  incotermsApi,
  lieuEmbarquementCircuitsApi,
  matriceFraisApi
} from "api";

import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import {
  TextCellRenderer,
  CheckedCellRenderer, UniqueRadioCellRenderer,
} from "components/AGGride/CellRender";
import { SelectTemplate } from "components/SelectComplete";
import { AutoCompleteCellEditor, AutoCompleteCellEditorProps, SelectorCellEditor } from "components/AGGride/CellEditor";
import { notNilCellValidator } from "components/AGGride/cellValidator";

import { api } from "utils";

import { DateTime } from "luxon";


interface PropsBlockExt extends PropsBlock {
  identifier?: number;
}


type GridType = Partial<LieuEmbarquementCircuitViewModel | LieuEmbarquementCircuitInsertionModel>;

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 90,
};

const LieuEmbarquement: FunctionComponent<PropsBlockExt> = ({ sm, md, lg, xl, doubleXl, identifier }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);

  const [lieux, setLieux] = useState<LieuEmbarquementCircuitViewModel[]>([]);

  // Data and options for the select:
  const [matrices, setMatrices] = useState<{ data: MatriceFraisWithNamesViewModel[], options: SelectTemplate<number>[]; }>({ data: [], options: [] });
  const [fournisseurs, setFournisseurs] = useState<SelectTemplate<number | null>[]>([]);
  const [incoterms, setIncoterms] = useState<SelectTemplate<number | null>[]>([]);

  const getMatrices = async () => await api.dataset.get<MatriceFraisWithNamesViewModel>(matriceFraisApi.v1MatriceFraisGet(1, 1000));
  const getFournisseurs = async () => await api.dataset.get<FournisseurWithTierViewModel>(fournisseursApi.v1FournisseursQualificationTiersGet("T"));
  const getIncoterms = async () => await api.dataset.get<IncotermViewModel>(incotermsApi.v1IncotermsGet(1, 1000));

  useEffect(() => {
    getMatrices().then(data => {
      let options: SelectTemplate<number>[] = data && data.length ? data.map(matrice => {
        const today = DateTime.now();
        const isDisabled = !(DateTime.fromISO(matrice.dateDebut) <= today && DateTime.fromISO(matrice.dateFin) >= today);
        const expiration = `(Expiration: ${DateTime.fromISO(matrice.dateFin).toLocaleString(DateTime.DATE_SHORT)})`;
        return {
          isDisabled,
          value: matrice.id,
          label: `${matrice.code} - ${matrice.nomMatriceFrais} ${isDisabled ? expiration : ""}`,
          model: matrice
        };
      }) : [];
      // Sort the options by value:
      options = options
        .sort((option$1, option$2) => option$1.value > option$2.value ? 1 : -1);
      setMatrices({ data, options });
    });
    getFournisseurs().then(data => {
      setFournisseurs(api.options.generate(data, { label: "#{code} - #{tier.raisonSociale}", value: "fkTiers" }, true));
    });
    getIncoterms().then(data => {
      setIncoterms(api.options.generate(data, { label: "#{code} - #{nomIncoterm}" }, true));
    });
  }, []);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      fetchData: async () => {
        if (!identifier) return [];
        const { data } = await circuitLogistiquesApi.v1CircuitLogistiquesIdLieuEmbarquementsGet(identifier);
        setLieux(data || []);
        gridController.activateContextActionButtons();
        return data || [];
      },
      postData: (cleanRow) => lieuEmbarquementCircuitsApi.v1LieuEmbarquementCircuitsPost(cleanRow as LieuEmbarquementCircuitInsertionModel),
      putData: (cleanRow) => lieuEmbarquementCircuitsApi.v1LieuEmbarquementCircuitsPut({
        ...cleanRow,
        fkCircuitLogistique: identifier
      } as LieuEmbarquementCircuitViewModel),
      deleteData: (dataId) => lieuEmbarquementCircuitsApi.v1LieuEmbarquementCircuitsIdDelete(dataId),
      onFocusChange: (isFocus) => setActivate(isFocus),
      getNewModel: () => ({
        flDefaut: !lieux.filter(lieu => lieu.flDefaut).length,
        fkCircuitLogistique: identifier,
      }),
      rowClassRules: { "hide-actions": params => { return !!params.data?.flDefaut && Number(params.node?.parent?.allChildrenCount) > 1; } },
      columnDefs: [
        {
          field: "fkMatriceFrais",
          headerName: t("lib_lieu_embarquement_circuit_fk_type_transport"),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          cellClass: (params) => {
            const current = matrices.options.find(option => option.value === params.data?.fkMatriceFrais);
            return current?.isDisabled ? "text-orange-500" : "";
          },
          valueFormatter: params => matrices.data.find(matrice => matrice.id === params.value)?.typeTransport || "...",
          cellRenderer: TextCellRenderer,
          valueValidator: [notNilCellValidator],
        },
        {
          field: "fkIncoterm",
          headerName: t("lib_lieu_embarquement_circuit_fk_incoterm"),
          flex: 2,
          editable: true,
          valueFormatter: params => incoterms.find(incoterm => incoterm.value === params.value)?.data?.code || "...",
          cellRenderer: TextCellRenderer,
          cellEditor: SelectorCellEditor,
          cellEditorParams: { values: incoterms },
        },
        {
          field: "fkTier",
          headerName: t("lib_lieu_embarquement_circuit_fk_tiers"),
          flex: 2,
          editable: true,
          valueFormatter: params => fournisseurs.find(fournisseur => fournisseur.value === params.value)?.label || "...",
          cellEditor: AutoCompleteCellEditor,
          cellEditorPopup: true,
          cellEditorParams: {
            canBeEmpty: false,
            searchData: (async () => {
              return fournisseurs.map(fournisseur => {
                return {
                  value: fournisseur.value,
                  label: fournisseur.label,
                  filterKeyArr: [fournisseur.label]
                };
              });
            }),
          } as AutoCompleteCellEditorProps,
        },
        {
          field: "fkMatriceFrais",
          headerName: t("lib_lieu_embarquement_circuit_fk_matrice_frais"),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          cellClass: (params) => {
            const current: SelectTemplate = matrices.options.find(option => option.value === params.data?.fkMatriceFrais)!;
            return current && current.isDisabled ? "bg-orange-500/10" : "";
          },
          flex: 2,
          editable: !!matrices.options.filter(option => !option.isDisabled).length,
          valueFormatter: params => matrices.options.find(option => option.value === params.value)?.label || "...",
          cellRenderer: TextCellRenderer,
          cellEditor: SelectorCellEditor,
          cellEditorParams: { values: matrices.options.filter(option => !option.isDisabled) },
          onCellValueChanged: (params) => {
            params.data.fkMatriceFrais = params.newValue;
            params.api.refreshCells({ rowNodes: [params.node!], force: true });
          },
          onCellClicked: (params) => {
            const current = matrices.options.find(option => option.value === params.data?.fkMatriceFrais)!;
            if (current?.isDisabled) {
              params.colDef.cellEditorParams = {
                values: [...matrices.options
                  .filter(option => !option.isDisabled), current]
                  .sort((option$1, option$2) => option$1.value > option$2.value ? 1 : -1)
              };
            } else { params.colDef.cellEditorParams = { values: matrices.options.filter(option => !option.isDisabled) }; }
          },
          valueValidator: [notNilCellValidator],
        },
        {
          field: "fkMatriceFrais",
          headerName: t("lib_lieu_embarquement_circuit_fk_taille_container"),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          cellClass: (params) => {
            const current: SelectTemplate = matrices.options.find(option => option.value === params.data?.fkMatriceFrais)!;
            return current && current.isDisabled ? "text-orange-500" : "";
          },
          valueFormatter: params => matrices.data.find(matrice => matrice.id === params.value)?.tailleContainer || "...",
          cellRenderer: TextCellRenderer,
        },
        {
          field: "fkMatriceFrais",
          headerName: t("lib_lieu_embarquement_circuit_fk_temperature"),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          cellClass: (params) => {
            const current: SelectTemplate = matrices.options.find(option => option.value === params.data?.fkMatriceFrais)!;
            return current && current.isDisabled ? "text-orange-500" : "";
          },
          valueFormatter: params => matrices.data.find(matrice => matrice.id === params.value)?.temperature || "...",
          cellRenderer: TextCellRenderer,
        },
        {
          field: "flCompletExterieur",
          headerName: t("lib_lieu_embarquement_circuit_fl_complet_exterieur"),
          cellRenderer: CheckedCellRenderer,
        },
        {
          field: "flDefaut",
          initialSort: 'desc',
          headerName: t("lib_lieu_embarquement_circuit_fl_defaut"),
          cellRenderer: UniqueRadioCellRenderer
        },
      ],
    }), [identifier, lieux, matrices, incoterms, fournisseurs, t])
  );

  useEffect(() => {
    gridController.fetchData();
  }, [identifier]);

  return (
    <BlockGrid
      title={t('tab_lieu_embarquement')} sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      loading={gridController.isLoading}
      disableCreate={gridController.hasEmptyLines}
      handleClick={(activation: boolean) => {
        setActivate(activation);
      }}
      toActivate={activate}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={($event) => gridController.onRowEditingStopped($event, true)}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        />
      </div>
    </BlockGrid>
  );
};

export default LieuEmbarquement;