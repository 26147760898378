import { FC, useEffect, useMemo, useState } from 'react';
import {
  LiaisonInsertionModel,
  LiaisonViewModel,
  LiaisonWithSubObjectsViewModel,
  TypeLiaisonViewModel
} from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { CellStyle, ColDef } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { articlesApi, liaisonsApi, typeLiaisonsApi } from 'api';
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { NumberCellEditor, DatePickerCellEditor, SelectorCellEditor, AutoCompleteCellEditor, AutoCompleteCellEditorProps } from 'components/AGGride/CellEditor';
import { CheckedCellRenderer, CheckedStyledCellRenderer, DateCellRenderer, LinkCellRenderer, LinkCellRendererProps, TextCellRenderer } from 'components/AGGride/CellRender';
import { editableIfNew, editableIfTodayOrFuture } from "components/AGGride/cellEditable";
import { dateCellValidator, notEmptyCellValidator, notNilCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import { useArticle } from "context/Referencement";
import { useGridController } from "hooks";

import 'app/ag-grid-style.css';
import { getISOEndDate, getISOStartDate } from "utils";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 90,
};

type GridType = Partial<LiaisonWithSubObjectsViewModel> & { articleCodeFils?: string | null; };

const LiaisonFils: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const [activate, setActivate] = useState(false);
  const [liaisonTypes, setLiaisonTypes] = useState<(TypeLiaisonViewModel & { label: string; })[]>([]);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {
        sortColId: "fkTypeLiaison",
        startEditingColId: "quantite",
      },
      emptyRowCheck: (rowData) => !rowData.fkArticleFils,
      autoFetch: true,
      fetchData: async () => {
        if (!article?.id) return [];
        const data = await articlesApi.v1ArticlesIdLiaisonsChildGet(article?.id).then(v => v.data);
        let tmp = await Promise.all(data.map(async (val) => {
          const { data: article } = await articlesApi.v1ArticlesIdGet(val.fkArticleFils!);
          return { ...val, articleCodeFils: article.code };
        }));
        return tmp;
      },
      postData: (cleanRow) => liaisonsApi.v1LiaisonsChildPost({ ...cleanRow, fkArticlePere: article?.id as number } as LiaisonInsertionModel),
      putData: (cleanRow) => liaisonsApi.v1LiaisonsPut(cleanRow as LiaisonViewModel),
      deleteData: (dataId) => liaisonsApi.v1LiaisonsIdDelete(dataId),
      getNewModel: () => ({
        fkTypeLiaison: liaisonTypes.find(v => v.code === 'LOT')?.id || liaisonTypes[0].id,
        quantite: 1,
        dateDebut: getISOStartDate(),
        dateFin: getISOEndDate(),
      }),
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'fkTypeLiaison',
          headerName: t('lib_liaison_fk_type_liaison'),
          headerTooltip: t('lib_liaison_fk_type_liaison'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          editable: editableIfNew,
          tooltipValueGetter: (params) => {
            return liaisonTypes.find(
              (v) => typeof v.id === 'number' && v.id === params.data?.fkTypeLiaison
            )?.label;
          },
          cellRenderer: TextCellRenderer,
          valueFormatter: (params) => liaisonTypes.find(
            (v) => typeof v.id === 'number' && v.id === params.data?.fkTypeLiaison
          )?.code || '',
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: liaisonTypes.map((a) => ({ value: a.id, label: a.label })),
          },
          valueValidator: [notEmptyCellValidator],
          maxWidth: 90,
        },
        {
          field: 'fkArticleFils',
          headerName: t('lib_liaison_fk_article'),
          headerTooltip: t('lib_liaison_fk_article'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          editable: editableIfNew,
          cellRenderer: LinkCellRenderer,
          cellRendererParams: {
            href: (cellValue: string) => /^\d+/gmi.test(cellValue) ? `/app/referencement/articles/${cellValue}` : null,
            target: '_blank'
          } as LinkCellRendererProps,
          valueFormatter: (params) => params.data?.articleCodeFils || (params.data?.fkArticleFils ? `Article(${params.data.fkArticleFils})` : ''),
          cellEditor: AutoCompleteCellEditor,
          cellEditorPopup: true,
          cellEditorParams: {
            searchData: (async (search) => {
              const { data: { data } } = await articlesApi.v1ArticlesSearchGet(search, 1, 20);
              return (data || []).map(val => {
                const defaultDesignation = val.designationStandard || val.designationLong || val.designationCourt || val.designationCaisse;
                const filterValues = [
                  val.code, val.codeExterne, val.designationStandard,
                  val.designationLong, val.designationCourt, val.designationCaisse,
                  ...(val.codeEans || []), ...(val.refFours || [])
                ];
                return {
                  value: val.id,
                  prefix: val.code?.trim(),
                  label: defaultDesignation?.trim(),
                  filterKeyArr: filterValues,
                };
              });
            }),
          } as AutoCompleteCellEditorProps,
          valueValidator: [notNilCellValidator, numberCellValidator],
          onCellValueChanged: async (event) => {
            if (event.newValue) {
              const { data: articleFils } = await articlesApi.v1ArticlesIdGet(event.newValue);
              event.data.articleCodeFils = articleFils.code;
              event.data.designationFils = articleFils.designationStandard;
            } else {
              event.data.articleCodeFils = undefined;
              event.data.designationFils = undefined;
            }
            event.api.refreshCells({ rowNodes: [event.node!], force: true });
          },
          maxWidth: 110,
        },
        {
          field: 'designationFils',
          headerName: t('lib_article_designation_standard'),
          headerTooltip: t('lib_article_designation_standard'),
          tooltipValueGetter: (params) => params.valueFormatted,
          cellRenderer: TextCellRenderer,
        },
        {
          field: 'quantite',
          headerName: t('lib_liaison_quantite'),
          headerTooltip: t('lib_liaison_quantite'),
          editable: true,
          cellRenderer: TextCellRenderer,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 1,
            max: 99,
            floating: 2,
          },
          valueValidator: [notNilCellValidator, numberCellValidator],
          maxWidth: 65,
        },
        {
          field: 'indice',
          headerName: t('lib_liaison_indice'),
          headerTooltip: t('lib_liaison_indice'),
          editable: true,
          cellRenderer: TextCellRenderer,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 99,
            floating: 2,
          },
          valueValidator: [notNilCellValidator, numberCellValidator],
          maxWidth: 65,
        },
        {
          field: 'flPrixAuto',
          headerName: t('lib_liaison_fl_prix_auto'),
          headerTooltip: t('lib_liaison_fl_prix_auto'),
          maxWidth: 65,
          minWidth: 40,
          cellRenderer: activate ? CheckedCellRenderer : CheckedStyledCellRenderer,
          cellStyle: {
            paddingLeft: "0",
            paddingRight: "0",
          } as CellStyle,
        },
        {
          field: 'dateDebut',
          headerName: t('lib_liaison_date_debut'),
          headerTooltip: t('lib_liaison_date_debut'),
          editable: editableIfTodayOrFuture,
          maxWidth: 110,
          cellRenderer: DateCellRenderer,
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
            min: DateTime.now(),
          },
          valueValidator: [notEmptyCellValidator, dateCellValidator],
        },
        {
          field: 'dateFin',
          headerName: t('lib_liaison_date_fin'),
          headerTooltip: t('lib_liaison_date_fin'),
          maxWidth: 110,
          editable: editableIfTodayOrFuture,
          cellRenderer: DateCellRenderer,
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
            min: DateTime.now(),
          },
          valueValidator: [notEmptyCellValidator, dateCellValidator],
        },
      ],
    }), [activate, liaisonTypes, article, t])
  );

  useEffect(() => {
    fetchLiaisonTypes();
  }, []);

  async function fetchLiaisonTypes() {
    const { data: res } = await typeLiaisonsApi.v1TypeLiaisonsGet(1, 100);
    const newData = res.data?.map(v => ({ ...v, label: `${v.nomTypeLiaison} (${v.code})`.toUpperCase() }));
    setLiaisonTypes(newData || []);
  };

  return (
    <BlockGrid
      title={t('tab_liaison_fils')} sm={sm} md={md} lg={lg} xl={xl}
      doubleXl={doubleXl}
      handleClick={(res: boolean) => {
        setActivate(res);
        setTimeout(gridController.activateContextActionButtons, 50);
      }}
      toActivate={activate}
      loading={gridController.isLoading}
      disableCreate={gridController.hasEmptyLines}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          onGridReady={gridController.onGridReady}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default LiaisonFils;