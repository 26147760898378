import H2Title, { Title } from "components/H2Title";
import { REFERENTIEL_PARAMETERS } from "app/applications";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "components/Breadcrumb";
import { useTranslation } from "react-i18next";
import AppMenu from "components/AppMenu";
import referencement from "assets/icons/menu/referencement-icon-active.svg";

const GeneralParametres: FunctionComponent = () => {
  const { t } = useTranslation();

  const title: Title = {
    title: "Référentiel - Paramètres",
  };

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: "Référentiel - Paramètres",
    },
  ];

  return (
    <>
      <div>
        <div className="flex flex-row justify-center">
          <div className="mb-3 xl:w-600">
            <div className="input-group relative flex flex-row flex-wrap items-stretch w-full mb-4 rounded">
              <div className="border border-solid border-gray-300 flex flex-row bg-white bg-clip-padding w-full items-center pl-1">
                <input
                  type="search"
                  className="
              form-control
              relative
              flex-auto
              min-w-0
              block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              rounded
              border-0
              transition
              ease-in-out
              m-0
              border-transparent
              focus:border-transparent focus:ring-0
            "
                  placeholder="Recherche"
                />
              </div>
              <span
                className="input-group-text flex items-center px-3 py-1.5 text-base font-normal text-gray-700 text-center whitespace-nowrap rounded"
                id="basic-addon2"
              ></span>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-center content-end items-end place-content-end">
          <div className="mr-4 mb-3 cursor-pointer h-16 flex flex-row gap-1 content-center p-2 items-center hover:text-store-primary group"></div>
        </div>
      </div>
      <AppMenu sectionTitle="tab_referencement" />
      <div className="self-center flex flex-col p-4 w-11/12 rounded-lg bg-white shadow-lg mb-10">
        <div className="flex flex-col">
          <div className="flex flex-row gap-1">
            <img src={referencement} alt={"Referencement"} />
            {<H2Title content={title} />}
          </div>
          <BreadCrumb content={breadcrumb} />
        </div>

        <div className="flex flex-row flex-wrap gap-x-5 gap-y-0">
          {REFERENTIEL_PARAMETERS.items.map((element) => {
            return (
              <div className="h-auto flex flex-col gap-2 mb-5 content-center p-2 items-center group">
                <div className="flex flex-col gap-1">
                  <div className="relative"></div>
                </div>
                <div className="flex flex-col flex-wrap gap-5">
                  <div className="flex flex-row w-14 h-14 bg-purple-100 rounded-full group-hover:rounded-lg transition-[borderRadius] duration-200 m-auto">
                    {element.url ? (
                      <Link
                        to={element.url}
                        className="h-10 w-10 mb-2 cursor-pointer m-auto"
                      >
                        <img
                          alt=""
                          width="40px"
                          height="40px"
                          src={`/assets/icons/${element.icon}.png`}
                        />
                      </Link>
                    ) : (
                      <img
                        className="h-10 w-10 mb-2 m-auto"
                        alt=""
                        width="40px"
                        height="40px"
                        src={`/assets/icons/${element.icon}.png`}
                      />
                    )}
                  </div>
                  <div className="flex justify-center items-center text-center">
                    <span className="text-xs w-22 no-underline hover:underline cursor-pointer">
                      {element.url ? (
                        <Link to={element.url}>{t(`${element.name}`)}</Link>
                      ) : (
                        t(`${element.name}`)
                      )}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default GeneralParametres;
