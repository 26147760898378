export enum TypeAccord {
  F = "SUR_FACTURE",
  A = "ARRIERE",
  C = "ARRIERE_CONDITIONNE",
}

export function findTypeAccordTranslationKey(data: string): string {
  const string = String(data) as keyof typeof TypeAccord;
  return TypeAccord[string] ? `enu_remise_type_accord_${TypeAccord[string]?.toLocaleLowerCase()}` : data;
}
