import { useState } from "react";

export function useCallOnce(condition: boolean, fun: Function, ...funArgs: any[]) {
  const [isFirstCall, setIsFirstCall] = useState(true)

  if (condition && isFirstCall) {
    setIsFirstCall(false)
    fun(...funArgs)
  }
};
