import { FC, ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { SiteWithTiersViewModel } from "openapi-typescript-codegen";

import { sitesApi } from "api";

type State = SiteWithTiersViewModel | null;
type Dispatch = (newState: State) => void;

const storeKey = 'context-site';
type StoreType = { id?: number; };

const SiteContext = createContext<{ state: State; dispatch: Dispatch; } | undefined>(undefined);

const SiteProvider: FC<{ children?: ReactNode; }> = ({ children }) => {
  const [state, dispatch] = useState<State>(null);

  useEffect(() => {
    const siteJSON = globalThis.localStorage.getItem(storeKey);

    let siteData: StoreType | undefined;
    try {
      siteData = JSON.parse(siteJSON || '{}');
    } catch (err) {
      globalThis.localStorage.removeItem(storeKey);
    }

    if (typeof siteData?.id === 'number') {
      fetchSite(siteData.id);
    } else {
      fetchSite();
    }
  }, []);

  useEffect(() => {
    if (state?.id) {
      const store: Required<StoreType> = { id: state.id };
      globalThis.localStorage.setItem(storeKey, JSON.stringify(store));
    } else {
      globalThis.localStorage.removeItem(storeKey);
    }
  }, [state]);

  async function fetchSite(id?: number) {
    if (id !== undefined) {
      const { data } = await sitesApi.v1SitesIdTiersGet(id);
      dispatch(data);
    } else {
      const { data: { data } } = await sitesApi.v1SitesTiersGet(1, 1);
      if (data?.[0])
        dispatch(data[0]);
    }
  }

  const value = { state, dispatch };
  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};

function useSite() {
  const context = useContext(SiteContext);
  if (context === undefined) {
    throw new Error('useSite must be used within a SiteProvider');
  }

  return context;
};

export { SiteProvider, useSite };