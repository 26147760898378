import { FC, useEffect, useMemo, useState } from 'react';
import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import { Title } from "components/H2Title";
import { IndicatifEanViewModel, DeviseViewModel, TypeEanViewModel } from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";

import { indicatifEansApi, devisesApi, typeEansApi } from 'api';
import BlockGrid, { PropsBlock, LayoutEnum } from 'components/BlockGrid';
import { NumberCellEditor, SelectorCellEditor, TextCellEditor } from 'components/AGGride/CellEditor';
import { TextCellRenderer } from 'components/AGGride/CellRender';
import { notEmptyCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import { useGridController } from "hooks";
import { SelectTemplate } from "components/SelectComplete";
import { api } from "utils";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

type GridType = IndicatifEanViewModel;

const IndicatifEan: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);
  const [devises, setDevises] = useState<{ data: DeviseViewModel[], options: SelectTemplate[]; }>({ data: [], options: [] });
  const [typeEans, setTypeEans] = useState<{ data: TypeEanViewModel[], options: SelectTemplate[]; }>({ data: [], options: [] });

  const typeGestionOptions: SelectTemplate[] = [
    { value: null, label: "..." },
    { value: 'P', label: t("enu_indicatif_ean_type_gestion_prix") },
    { value: 'K', label: t("enu_indicatif_ean_type_gestion_poids") },
    { value: 'A', label: t("enu_indicatif_ean_type_gestion_autre") }
  ];

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.code,
      fetchData: async () => {
        const { data: { data } } = await indicatifEansApi.v1IndicatifEansGet(1, 1000);
        return data.sort((a, b) => a.code.localeCompare(b.code));
      },
      postData: (cleanRow) => indicatifEansApi.v1IndicatifEansPost(cleanRow),
      putData: (cleanRow) => indicatifEansApi.v1IndicatifEansPut(cleanRow),
      deleteData: (dataId) => indicatifEansApi.v1IndicatifEansIdDelete(dataId),
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'code',
          headerName: t('lib_indicatif_ean_code'),
          headerTooltip: t('lib_indicatif_ean_code'),
          cellRenderer: TextCellRenderer,
          cellEditor: TextCellEditor,
          cellEditorParams: {
            maxLength: 3,
            toUpperCase: true,
          },
          editable: true,
          filter: 'agTextColumnFilter',
          valueValidator: [notEmptyCellValidator],
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          flex: 1
        },
        {
          field: 'fkDevise',
          headerName: t('lib_indicatif_ean_fk_devise'),
          headerTooltip: t('lib_indicatif_ean_fk_devise'),
          editable: true,
          tooltipValueGetter: (params) => devises.options.find(devise => devise.value === params.data?.fkDevise)?.value,
          valueFormatter: (params) => devises.options.find(devise => devise.value === params.data?.fkDevise)?.label || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: { values: devises.options },
          cellRenderer: TextCellRenderer,
          filter: 'agTextColumnFilter',
          flex: 2
        },
        {
          field: 'fkTypeEan',
          headerName: t('lib_indicatif_ean_type_ean'),
          headerTooltip: t('lib_indicatif_ean_type_ean'),
          valueValidator: [notEmptyCellValidator],
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          editable: true,
          tooltipValueGetter: (params) => typeEans.options.find(typeEan => typeEan.value === params.data?.fkTypeEan)?.value,
          valueFormatter: (params) => typeEans.options.find(typeEan => typeEan.value === params.data?.fkTypeEan)?.label || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: { values: typeEans.options },
          filter: 'agTextColumnFilter',
          flex: 2
        },
        {
          field: 'nbDec',
          headerName: t('lib_indicatif_ean_nb_dec'),
          headerTooltip: t('lib_indicatif_ean_nb_dec'),
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellEditor: NumberCellEditor,
          valueValidator: [numberCellValidator],
          cellEditorParams: {
            min: 0,
            max: 9
          },
          cellStyle: {
            textAlign: "left",
          },
          flex: 1
        },
        {
          field: 'nbZero',
          headerName: t('lib_indicatif_ean_nb_zero'),
          headerTooltip: t('lib_indicatif_ean_nb_zero'),
          cellRenderer: TextCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 9
          },
          valueValidator: [numberCellValidator],
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 1
        },
        {
          field: 'typeGestion',
          headerName: t('lib_indicatif_ean_type_gestion'),
          headerTooltip: t('lib_indicatif_ean_type_gestion'),
          cellRenderer: TextCellRenderer,
          tooltipValueGetter: (params) => typeGestionOptions.find(typeGestion => typeGestion.value === params.value)?.value,
          valueFormatter: (params) => typeGestionOptions.find(typeGestion => typeGestion.value === params.value)?.label || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: { values: typeGestionOptions },
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 2
        },
        {
          field: 'commentaire',
          headerName: t('lib_indicatif_ean_commentaire'),
          headerTooltip: t('lib_indicatif_ean_commentaire'),
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 2
        },
      ],
    }), [activate, typeGestionOptions, typeEans, devises, t])
  );
  async function getDevises() {
    return api.dataset.get<DeviseViewModel>(devisesApi.v1DevisesGet(1, 1000));
  }

  async function getTypeEans() {
    return api.dataset.get<TypeEanViewModel>(typeEansApi.v1TypeEansGet(1, 1000));
  }

  useEffect(() => {
    getDevises().then(data => {
      let options: SelectTemplate[] = data ? data.map((devise) => ({
        value: devise.id,
        label: devise.nomDevise ? `${devise.code} - ${devise.nomDevise}` : devise.code || String()
      })) : [];
      options.unshift({ value: null, label: "..." });
      setDevises({ data, options });
    });
    getTypeEans().then(data => {
      let options: SelectTemplate[] = data ? data.map((type) => ({
        value: type.id,
        label: type.code ? `${type.code} - ${type.nomTypeEan}` : type.nomTypeEan || String()
      })) : [];
      options.unshift({ value: null, label: "..." });
      setTypeEans({ data, options });
    });
  }, []);

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: `${t('men_refe_articles')} - ${t('men_parametre')}`,
      link: `/app/referencement/articles/parametres`,
    },
    {
      name: "tit_indicatif_ean",
    },
  ];

  const title: Title = {
    title: `${t('men_refe_articles')} - ${t('tab_indicatif_ean')}`,
    icon: "articles",
  };

  return (
    <>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <BlockGrid
        sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
        layout={LayoutEnum.settings}
        handleClick={(res: boolean) => {
          setActivate(res);
          setTimeout(gridController.activateContextActionButtons, 50);
        }}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-params">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType={"fullRow"}
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </>
  );
};

export default IndicatifEan;
