import { FC, ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { ArticleViewModel } from "openapi-typescript-codegen";

import { articlesApi } from "api";

type State = ArticleViewModel | null;

const ArticleContext = createContext<{ state: State; } | undefined>(undefined);

const ArticleProvider: FC<{ children?: ReactNode; articleCode?: string; }> = ({ children, articleCode }) => {
  const [state, dispatch] = useState<State>(null);

  useEffect(() => {
    if (articleCode) fetchArticle(articleCode);
  }, [articleCode]);

  async function fetchArticle(articleCode: string) {
    const { data: response } = await articlesApi.v1ArticlesCodesCodeGet(articleCode);
    dispatch(response);
  }

  const value = { state };
  return <ArticleContext.Provider value={value}>{children}</ArticleContext.Provider>;
};

function useArticle() {
  const context = useContext(ArticleContext);
  if (context === undefined) {
    throw new Error('useArticle must be used within a ArticleProvider');
  }

  return context;
};

export { ArticleProvider, useArticle };