import { FunctionComponent, useEffect, useState } from "react";
import {
  CadencierFournisseurInsertionModel,
  CadencierFournisseurViewModel, CadencierNomenclatureInsertionModel,
  CircuitLogistiqueWithCadenciersViewModel
} from "openapi-typescript-codegen";

import DeletionItemLN from "components/Fournisseur/LateralNavigation/Interaction/DeletionItemLN";
import CreationCadencierLN from "components/Fournisseur/TabCircuit/Navigation/Interaction/CreationCadencierLN";

interface Props {
  parentItem?: CircuitLogistiqueWithCadenciersViewModel;
  selectedCircuit?: CircuitLogistiqueWithCadenciersViewModel;
  selectedCadencier?: CadencierFournisseurViewModel;
  onSelectCadencier: (id: number) => void;
  onCreateCadencier: (cadencier: CadencierFournisseurInsertionModel, sites: number[], nomenclature?: CadencierNomenclatureInsertionModel) => void;
  onDeleteCadencier: (id: number) => void;
  canBeDeleted: boolean;
}

const NavigationCadencierItem: FunctionComponent<Props> = (
  {
    parentItem,
    selectedCircuit,
    selectedCadencier,
    onSelectCadencier,
    onCreateCadencier,
    onDeleteCadencier,
    canBeDeleted
  }) => {
  const [cadenciers, setCadenciers] = useState<CadencierFournisseurViewModel[]>(getSortedCadenciers());

  function getSortedCadenciers() {
    return parentItem?.cadencierFournisseurs?.sort(
      (cadencier$1, cadencier$2) =>
        cadencier$1.code?.localeCompare(cadencier$2.code || '') || 0) || [];
  }

  const handleSelectCadencier = (id: number) => {
    onSelectCadencier && onSelectCadencier(id);
  };

  const handleDeleteCadencier = (id: number) => {
    (canBeDeleted && onDeleteCadencier) && onDeleteCadencier(id);
  };

  useEffect(() => {
    setCadenciers(getSortedCadenciers());
  }, [parentItem]);

  if (selectedCircuit?.id !== parentItem?.id)
    return null;

  return (
    <ul className={`${cadenciers.length && 'mt-2'} flex flex-col justify-center font-semibold hover:cursor-default`}>
      {cadenciers.map((cadencier) => {
        return (
          <li
            key={cadencier.id}
            onClick={() => handleSelectCadencier(cadencier.id as number)}
            className={
              `mx-5 rounded-lg text-sm mb-2 pl-2 hover:cursor-pointer
              ${selectedCadencier?.id === cadencier.id ? 'text-white-500 bg-store-primary' : 'text-store-primary bg-white-500'}
            `}>
            [{cadencier.code}] {cadencier.nomCadencierFournisseur}

            {(canBeDeleted && selectedCadencier?.id === cadencier.id) &&
              <DeletionItemLN
                selectedItem={selectedCadencier}
                canBeDeleted={true}
                onDeleteItem={() => { handleDeleteCadencier(cadencier.id as number); }}
                className={`text-lg h-full w-8 flex items-center justify-center float-right bg-store-primary
                            transition-all text-white-500 focus:text-red-500 hover:text-red-500
                            border-l border-white-500 focus:bg-white-500 hover:bg-white-500 opacity-1`}
              />
            }
          </li>
        );
      })}
      <li><CreationCadencierLN selectedCircuit={selectedCircuit} onCreateCadencier={onCreateCadencier} /></li>
    </ul>
  );
};

export default NavigationCadencierItem;