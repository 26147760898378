import { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ResponsiveContainer, Tooltip, Legend, BarChart, CartesianGrid, XAxis, YAxis, Bar } from 'recharts';
import { DateTime } from "luxon";
import cs from "classnames";

import { REFERENCEMENT_MENU } from "app/parameters-routes";
import { APPLICATIONS, REFERENCEMENT } from "app/applications";
import { useSite } from "context/site";

import ArrowRightIcon from 'assets/icons/arrow-right.svg';
import MenuIcon from 'assets/icons/header/MenuIcon.svg';

const Card: FC<{ title: string, className?: string, childrenClassName?: string, children?: ReactNode; }> = ({ title, className, childrenClassName, children }) => {
  return (
    <div className={cs("flex flex-col p-4 rounded-lg bg-white shadow-lg", className)}>
      <div className="flex row gap-1">
        <div className="flex flex-row items-end">
          <div>
            <h2 className="text-store-primary font-poppins not-italic font-bold text-lg text-left">
              {title}
            </h2>
          </div>
        </div>
      </div>

      <div className={cs("relative", childrenClassName)}>
        {children}
      </div>
    </div>
  );
};

const AppIcon: FC<{ name: string, url: string, icon: string; }> = ({ name, url, icon }) => {
  const { t } = useTranslation();
  const fullUrl = `/${APPLICATIONS}/${REFERENCEMENT}/${url}`;

  return (
    <Link
      to={fullUrl}
      key={name}
      className="w-24 flex flex-col gap-2 group"
    >
      <div className="p-2 mx-auto bg-purple-100 rounded-full group-hover:rounded-lg transition-[borderRadius] duration-200">
        <img alt={icon} className="h-10 w-10" src={`/assets/icons/${icon}.png`} />
      </div>
      <div className="text-xs text-center cursor-pointer break-words group-hover:underline">
        {t(`${name}`)}
      </div>
    </Link>
  );
};

const ProfilePreview: FC<{ name: string, img: string, role: { name: string, color: string; }; }> = ({ name, img, role }) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      <div className="rounded-full h-12 w-12 bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${img})` }}></div>
      <div className="flex flex-col">
        <div>{name}</div>
        <div className="px-2 py-1 rounded-sm" style={{ backgroundColor: role.color }}>
          {role.name}
        </div>
      </div>
    </div>
  );
};

const TaskRow: FC<{ title: string, tags: { name: string, color: string; }[], by: { name: string, img: string, role: { name: string, color: string; }; }; }> = ({ title, tags, by }) => {
  return (
    <div className="flex flex-col p-4 border border-gray-300 rounded-md cursor-pointer">
      <div className="flex flex-row justify-between">
        <div className="text-store-primary font-poppins font-bold text-md">
          {title}
        </div>
        <ProfilePreview {...by} />
      </div>
      <div className="flex flex-row gap-2">
        {
          tags.map(tag => (
            <div key={tag.name} className="px-3 py-2 rounded-bl-lg rounded-tl-full rounded-r-full" style={{ backgroundColor: tag.color }}>
              {tag.name}
            </div>
          ))
        }
      </div>
    </div>
  );
};

const NotifyRow: FC<{ type: string, date: string, file: { title: string, lastUpdate: string, action: string; }, by: { name: string, img: string, role: { name: string, color: string; }; }; }> = ({ type, file, date, by }) => {
  return (
    <div className="flex flex-col border border-gray-200 rounded-t-xl rounded-b-md cursor-pointer overflow-hidden">
      <div className="flex flex-row justify-between px-3 py-1 text-white-500 bg-store-primary">
        <div>{type}</div>
        <div>{date}</div>
      </div>
      <div className="flex flex-col gap-2 px-4 py-2">
        <div className="flex flex-row justify-between">
          <div className="text-store-primary font-poppins font-bold text-md">{file.title}</div>
          <div className="text-sm text-gray-700">{file.lastUpdate}</div>
        </div>
        <div className="flex flex-row gap-2 text-sm text-gray-700">
          <img className="h-4 mt-1" src={MenuIcon} alt="menu-icon" />
          <pre>
            {file.action}
          </pre>
        </div>
        <ProfilePreview {...by} />
      </div>
    </div>
  );
};

const Home: FC = () => {
  const { t } = useTranslation();
  const { state: site } = useSite();
  const [currentTime, setCurrentTime] = useState(DateTime.now());

  useEffect(() => {
    const inter = setInterval(() => setCurrentTime(DateTime.now()), 5e3);
    return () => clearInterval(inter);
  }, []);

  return (
    <div className="flex flex-col" style={{ height: 'calc(100vh - 35px)' }}>

      <div className="my-5">
        <h1 className="text-grey-500 font-poppins not-italic font-bold text-3xl">
          {t('tit_dashboard')} {site?.tier?.nomTiers} !
        </h1>
        <span className="tracking-wide">{currentTime.toLocaleString(DateTime.DATETIME_SHORT)}</span>
      </div>

      <div className="w-full flex flex-1 gap-6 mb-6">
        <div className="flex flex-col gap-4 flex-1">
          <Card title={t('tit_applications_favorites')} childrenClassName="flex flex-col">
            <div className="flex flex-wrap gap-y-2 mt-4">
              {REFERENCEMENT_MENU.items.map((element) => {
                return <AppIcon key={element.name} name={element.name} icon={element.icon} url={element.url || '#'} />;
              })}
            </div>

            <Link to={`/${APPLICATIONS}/${REFERENCEMENT}/`} className="flex flex-row gap-2 self-end mt-4">
              <span>Toutes les applications</span>
              <img className="h-6" src={ArrowRightIcon} alt="arrow-right" />
            </Link>
          </Card>

          <Card title={'Tâches à régler'} className="flex-1" childrenClassName="flex flex-col gap-2 p-4">
            {
              [
                { title: 'Marges Négatives 26/03/2022', tags: [{ name: 'A gérer', color: '#e4feb7' }, { name: 'Attribuer à', color: '#fd999a' }], by: { name: 'Me', img: '/assets/images/profile.png', role: { name: 'Gestion', color: '#e4feb7' } } },
                { title: 'Analyse CA 24/03/2022', tags: [{ name: 'En cours', color: '#c8def8' }], by: { name: 'Me', img: '/assets/images/profile.png', role: { name: 'Director', color: '#dbdcfe' } } }
              ].map(val => <TaskRow key={val.title} title={val.title} tags={val.tags} by={val.by} />)
            }
          </Card>
        </div>
        <div className="flex flex-col gap-4 flex-1">
          <Card title={'Notifications'} className="flex-1" childrenClassName="flex flex-col gap-2 p-4">
            {
              [
                { type: 'Demande par intégration de fichiers', date: '13:43', file: { title: 'Test.txt', lastUpdate: '24/03/2022 17h11', action: '500 lignes\n450 créations / 50 modifications générales' }, by: { name: 'Me', img: '/assets/images/profile.png', role: { name: 'Referenciel', color: '#dbdcfe' } } },
              ].map(val => <NotifyRow key={val.type} type={val.type} date={val.date} file={val.file} by={val.by} />)
            }
          </Card>

          <Card title={'Indices de performance'} childrenClassName="h-96">
            <div className="flex flex-col h-full">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={[
                    {
                      name: 'Lu 24/11',
                      "Chiffre d’affaire": 21943,
                    }, {
                      name: 'Ma 25/11',
                      "Chiffre d’affaire": 18321,
                    }, {
                      name: 'Me 26/11',
                      "Chiffre d’affaire": 24391,
                    }, {
                      name: 'Jeu 27/11',
                      "Chiffre d’affaire": 26453,
                    }, {
                      name: 'Ve 28/11',
                      "Chiffre d’affaire": 19324,
                    }, {
                      name: 'Sa 29/11',
                      "Chiffre d’affaire": 30627,
                    }
                  ]}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 10,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Chiffre d’affaire" fill="rgb(38 132 255)" />
                </BarChart>
              </ResponsiveContainer>
              <div className="self-center text-store-primary font-poppins font-bold text-md">Chiffre d’affaire du jour</div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Home;
