import {FunctionComponent, useState, cloneElement, ReactElement, useContext} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";

import BlockGrid, { LayoutEnum, PropsBlock } from 'components/BlockGrid';
import Modal from "../../components/Modal";
import {
  CellDoubleClickedEvent, CellValueChangedEvent,
  ColDef,
  FirstDataRenderedEvent,
  RowDataUpdatedEvent,
  RowSelectedEvent
} from 'ag-grid-community';
import MarquesContext from "./MarquesContext";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

interface Props extends PropsBlock {
  label: string;
  gridController: any;
  onSelect: (id: number) => void;
  onDataChange?: (event: FirstDataRenderedEvent | RowDataUpdatedEvent) => void;
  onCellValueChanged?: (event: CellValueChangedEvent) => void;
  disableCreate?: boolean;
  gridName: ("typologie" | "surMarque" | "marque" | "sousMarque");
  children: ReactElement<any>;
}

const BlockGridNomenclature: FunctionComponent<Props> = ({ label, gridController, onSelect, onDataChange, onCellValueChanged, disableCreate, gridName, children }) => {
  const { t } = useTranslation();
  const { selection } = useContext(MarquesContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState<any | null>();


  function onRowSelected(params: RowSelectedEvent) {
    (params.node.isSelected() && params.data?.id !== selection[gridName]) && onSelect(params.data.id);
  }

  function selectFirstRow(event: FirstDataRenderedEvent | RowDataUpdatedEvent) {
    const node = event.api.getDisplayedRowAtIndex(event.api.getFirstDisplayedRow());
    node?.setSelected(true);
  }

  function selectRow(event: FirstDataRenderedEvent | RowDataUpdatedEvent) {
    event.api.forEachLeafNode((node: any) => {
      node.data.id === selection[gridName] && node.setSelected(true);
    });
  }

  function onFirstDataRendered(event: FirstDataRenderedEvent) {
    gridController.onFirstDataRendered(event);
    gridController.activateContextActionButtons();
    onDataChange ? onDataChange(event) : selection[gridName] ? selectRow(event) : selectFirstRow(event);
  }

  function onRowDataUpdated(event: RowDataUpdatedEvent) {
    gridController.activateContextActionButtons();
    onDataChange ? onDataChange(event) : selection[gridName] ? selectRow(event) : selectFirstRow(event);
  }

  function onCellDoubleClicked(event: CellDoubleClickedEvent) {
    setData(event.data);
    setIsModalOpen(true);
  }

  function handleCreate() {
    setData(null);
    setIsModalOpen(true);
  }

  return (
    <div className="flex-[2]">
      <div className="mb-2 text-grey-500 font-bold">{t(label)}</div>
      <BlockGrid
        layout={LayoutEnum.settings}
        toActivate={true}
        disableCreate={disableCreate || gridController.hasEmptyLines}
        handleCreate={handleCreate}
        hideCreate={disableCreate ? true : undefined}
        hideUpdate={true}
        hideCancel={true}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-params">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType="fullRow"
            getRowId={(params: any) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            onCellValueChanged={onCellValueChanged || gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            onRowDataUpdated={onRowDataUpdated}
            onCellDoubleClicked={onCellDoubleClicked}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
            rowSelection="single"
            onRowSelected={onRowSelected}
          ></AgGridReact>
        </div>
      </BlockGrid>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        { cloneElement(children, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, data }) }
      </Modal>
    </div>
  );
};

export default BlockGridNomenclature;