import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';

import {GoogleMap, InfoWindow, Marker,} from '@react-google-maps/api';

import {useTranslation} from "react-i18next";

import {LieuFonctionViewModel} from "openapi-typescript-codegen";

import BlockGrid from 'components/BlockGrid';

import "./ListeLieuxFonctions.css";

interface Props {
    lieux: (LieuFonctionViewModel & { position: google.maps.LatLng; })[];
}

const containerStyle = {
    width: '100%',
    height: '100%',
    minHeight: '400px',
};

const ListeLieuxFonctions: FunctionComponent<Props> = ({lieux}) => {
    const {t} = useTranslation();
    const map = useRef<google.maps.Map | null>(null);
    const [activeMarker, setActiveMarker] = useState(null);
    const [selectedLieu, setSelectedLieu] = useState<(LieuFonctionViewModel & { position: google.maps.LatLng }) | null>(null);
    const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null);

    const types = {
        "M": t("enu_lieu_fonction_type_mixte"),
        "C": t("enu_lieu_fonction_type_commande"),
        "R": t("enu_lieu_fonction_type_reglement"),
    }

    const onLoad = useCallback(function callback($map: google.maps.Map) {
        const $bounds = new google.maps.LatLngBounds();
        lieux.forEach(({position}) => $bounds.extend(position));
        setBounds($bounds);
        $map.fitBounds($bounds);
        map.current = $map;
    }, []);

    const handleActiveMarker = (marker: any) => { marker === activeMarker || setActiveMarker(marker); };

    const onUnmount = useCallback(() => { map.current = null;  }, []);

    useEffect(() => {
        if (selectedLieu && map.current) {
            map.current.setCenter(selectedLieu.position);
            map.current.setZoom(12);
        } else {
            (map.current && bounds) && map.current?.fitBounds(bounds);
        }
    }, [selectedLieu, lieux]);

    return (
        <BlockGrid
            title={t('tit_liste_lieux_fonctions')}
            toActivate={true}
        >
            <div className="flex flex-col justify-between w-full
      flex-1 rounded-lg pb-1 border-2 border-transparent no-scrollbar
    outline-store-primary shadow-store-primary bg-white-500 undefined px-4 pt-3 relative">
                <div className="flex flex-wrap w-full">
                    <div className="flex w-full gap-4">
                        <label className="block mb-2 h-full w-1/3">
                            {lieux.map(lieu => {
                                const isSelected = selectedLieu ? lieu.id === selectedLieu.id : false;
                                return (
                                    <button
                                        type="button"
                                        key={`${lieu.id}`}
                                        onClick={() => { setSelectedLieu(isSelected ? null : lieu); }}
                                        className={`gap-2 w-full mb-2 cursor-pointer ${isSelected ? "selected-container" : ""}`}
                                    >
                                        <div className="border border-slate-300 rounded-lg p-2 pl-4 grid grid-cols-8 gap-4">
                                            <div className={`col-span-2 justify-center cursor-pointer ${isSelected && "text-white-500"}`}>
                                                <h4 className={`text-xs ${isSelected ? "text-white-150ext-white-150" : "text-gray-500"}`}>{t('lib_lieu_fonction_code')}</h4>
                                                <span>{lieu.code}</span>
                                            </div>
                                            <div className={`col-span-4 justify-center cursor-pointer ${isSelected && "text-white-500"}`}>
                                                <h4 className={`text-xs ${isSelected ? "text-white-150" : "text-gray-500"}`}>{t('lib_lieu_fonction_nom_lieu_fonction')}</h4>
                                                <span>{lieu.nomLieuFonction}</span>
                                            </div>
                                            <div className={`col-span-2 justify-center cursor-pointer ${isSelected && "text-white-500"}`}>
                                                <h4 className={`text-xs ${isSelected ? "text-white-150" : "text-gray-500"}`}>{t('lib_lieu_fonction_type_lieu_fonction')}</h4>
                                                <span>{types[lieu.typeLieuFonction]}</span>
                                            </div>
                                        </div>
                                    </button>
                                );
                            })}
                        </label>
                        <label className="block mb-2 h-full w-2/3 rounded-md">
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                            >
                                {lieux.map(({id, nomLieuFonction, position}) => (
                                    <Marker
                                        key={id}
                                        position={position}
                                        onClick={() => handleActiveMarker(id)}
                                    >
                                        {activeMarker === id ? (
                                            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                                <div>{nomLieuFonction}</div>
                                            </InfoWindow>
                                        ) : null}
                                    </Marker>
                                ))}
                            </GoogleMap>
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15252.961035302129!2d-3.164986959844842!3d48.554752851473005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48118d2ab6ba8937%3A0x79dd91cdc45c2f2!2sE.Leclerc%20GUINGAMP%20CEDEX!5e0!3m2!1sfr!2sfr!4v1651079096756!5m2!1sfr!2sfr" className='w-full h-full rounded-md' loading="lazy" ></iframe> */}
                        </label>
                    </div>
                </div>
            </div>
        </BlockGrid>
    );
};

export default ListeLieuxFonctions;