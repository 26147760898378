import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColDef, GetServerSideGroupLevelParamsParams, GroupCellRenderer, GroupCellRendererParams, ServerSideGroupLevelParams } from "ag-grid-community";
import Tooltip from "@mui/material/Tooltip";
import { ViewFournisseurMainTableCircuit, ViewFournisseurMainTableModel } from "openapi-typescript-codegen";

import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import Modal from "components/Modal";
import { ImageCellRenderer, ImageCellRendererProps, LinkCellRenderer, LinkCellRendererProps, ModalCellRenderer, NumberCellRenderer, TextCellRenderer } from "components/AGGride/CellRender";
import { CustomColDef, ServerSortAlgo } from "components/AGGride/gridTypes";
import { viewFournisseursApi } from "api/";
import FournisseurCreate from "./FournisseurCreate";
import ArticlesModal from "./ArticlesModal";
import ServerSideGrid, { ServerSideGetGroupRowsCallback, ServerSideGetRowsCallback } from "components/AGGride/ServerSideGrid";

const breadcrumb = [
  {
    name: "tit_applications",
    link: `/app`,
  },
  {
    name: "tab_referencement",
    link: `/app/referencement/`,
  },
  {
    name: "tit_listing_fournisseurs",
  },
];

const defaultColDef: ColDef = {
  flex: 1,
  sortable: true,
};

export type GridType = { circuit?: ViewFournisseurMainTableCircuit; } & ViewFournisseurMainTableModel;

const ListeFournisseurs: FunctionComponent = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columnDefs = useMemo((): CustomColDef<GridType>[] => [
    {
      field: "logo",
      headerName: t('lib_tiers_logo'),
      headerTooltip: t('lib_tiers_logo'),
      pinned: "left",
      maxWidth: 80,
      sortable: false,
      cellRenderer: ImageCellRenderer,
      cellRendererParams: {
        href: (value) => value,
        defaultHref: "/assets/images/default.png",
      } as ImageCellRendererProps,
    },
    {
      field: "code",
      headerName: t('lib_fournisseur_code'),
      headerTooltip: t('lib_fournisseur_code'),
      pinned: "left",
      maxWidth: 100,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: LinkCellRenderer,
      cellRendererParams: {
        href: (value) => `/app/referencement/fournisseurs/${value}`,
        target: '_blank',
      } as LinkCellRendererProps,
      customDefs: {
        defaultServerSort: ServerSortAlgo.Number.asc,
        serverSortAlgo: ServerSortAlgo.Number,
      }
    },
    {
      field: "raisonSociale",
      headerName: t("lib_tiers_raison_sociale"),
      headerTooltip: t('lib_tiers_raison_sociale'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "nomTiers",
      headerName: t("lib_tiers_nom_tiers"),
      headerTooltip: t('lib_tiers_nom_tiers'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "nomTypeFournisseur",
      headerName: t("lib_fournisseur_fk_type_fournisseur"),
      headerTooltip: t('lib_fournisseur_fk_type_fournisseur'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "villeTiers",
      headerName: t("lib_tiers_commune"),
      headerTooltip: t('lib_tiers_commune'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "codePostalTiers",
      headerName: t("lib_tiers_code_postal"),
      headerTooltip: t('lib_tiers_code_postal'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "telTiers",
      headerName: t("lib_tiers_tel_tiers"),
      headerTooltip: t('lib_tiers_tel_tiers'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: 'id',
      rowGroup: true,
      showRowGroup: true,
      headerName: t("lib_circuit_logistique_nom_circuit"),
      headerTooltip: t('lib_circuit_logistique_nom_circuit'),
      filter: false,
      sortable: false,
      valueFormatter: (params) => params.data?.circuit?.nomCircuit || '',
      cellRendererSelector: (params) => {
        const childrenCount = params.node.allChildrenCount || 0;

        if (params.node.level === 0 && childrenCount <= 1)
          return { component: TextCellRenderer };
        return {
          component: GroupCellRenderer,
          params: { suppressPadding: true } as GroupCellRendererParams
        };
      },
    },
    {
      field: "circuit.flux.0.nomModeApprovisionnement",
      headerName: t("lib_mode_approvisionnement_nom"),
      headerTooltip: t('lib_mode_approvisionnement_nom'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "circuit.articleNb",
      headerName: t("lib_fournisseur_articles"),
      headerTooltip: t('lib_fournisseur_articles'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: ModalCellRenderer,
      cellRendererParams: {
        innerRenderer: NumberCellRenderer,
        children: ArticlesModal,
      },
    },
    {
      field: "circuit.articlePrimaryNb",
      headerName: t("lib_fournisseur_articles_principaux"),
      headerTooltip: t('lib_fournisseur_articles_principaux'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: ModalCellRenderer,
      cellRendererParams: {
        innerRenderer: NumberCellRenderer,
        children: ArticlesModal,
      },
    },
    {
      field: "circuit.articleSecondaryNb",
      headerName: t("lib_fournisseur_articles_secondaires"),
      headerTooltip: t('lib_fournisseur_articles_secondaires'),
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: ModalCellRenderer,
      cellRendererParams: {
        innerRenderer: NumberCellRenderer,
        children: ArticlesModal,
      },
    },
  ], [t]);

  const getServerSideGroupLevelParams = useCallback(
    (params: GetServerSideGroupLevelParamsParams): ServerSideGroupLevelParams =>
      ({ infiniteScroll: params.level === 0 ? true : false, })
    , []);

  const getRows: ServerSideGetRowsCallback<GridType> = useCallback(
    async (params, { page, amount, query }) => {
      const { data: { data, total } } = await viewFournisseursApi.v1ViewFournisseursMainTablePost({
        page,
        amount,
        searchOption: query
      });

      return {
        rowData: data.map((row): GridType => ({ ...row, circuit: row.circuits[0] })),
        rowCount: total
      };
    }, []);

  const getGroupRows: ServerSideGetGroupRowsCallback<GridType> = useCallback(
    async (params, { rowGroupIds, parentData }) => {
      if (rowGroupIds.length === 1 && rowGroupIds[0] === 'id') {
        if (!parentData) throw Error('Missing parent data');

        const rowData = (parentData?.circuits || []).slice(1).map((circuit): GridType => ({
          circuit: circuit,
          id: parentData?.id,
          code: '',
          logo: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
          circuits: [],
          codePostalTiers: '',
          nomCourtTiers: '',
          nomTiers: '',
          nomTypeFournisseur: '',
          numTiersExt: '',
          raisonSociale: '',
          telTiers: '',
          villeTiers: '',
        }));
        return ({ rowData, rowCount: rowData.length });
      }
      throw Error('Groups not implemented: ' + rowGroupIds.join(' -> '));
    }
    , []);

  return (
    <>
      <BreadCrumb content={breadcrumb} />

      <div className="w-full flex justify-between items-center mt-2 mb-3 h-10">
        <H1Title content={{ title: 'tab_listing_fournisseurs', icon: 'fournisseurs' }} />
        <Tooltip title={t('bul_nouveau')} followCursor>
          <button
            onClick={() => setIsModalOpen(true)}
            className="border border-store-primary hover:bg-store-primary focus:bg-store-primary text-store-primary hover:text-white-500 focus:text-white-500 rounded py-0.5 px-2">
            {t('btn_nouveau')} +
          </button>
        </Tooltip>
      </div>

      <ServerSideGrid
        className="block-grid-list mb-4"
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={(params) => `${params.data.id}${params.data.circuit?.id}`}
        getServerSideGroupLevelParams={getServerSideGroupLevelParams}
        getChildCount={((data: GridType) => data.circuits.length)}

        getRows={getRows}
        getGroupRows={getGroupRows}

        groupDisplayType="custom"
      />

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div>
          <FournisseurCreate setIsOpen={setIsModalOpen} />
        </div>
      </Modal>
    </>
  );
};

export default ListeFournisseurs;
