import { AxiosRequestConfig } from "axios";

export namespace Jwt {

    export const UnauthorizedResponseCode = 401;

    export class Helpers {
        static InjectCsrfTokenInHeader(config: AxiosRequestConfig<unknown>) {
            const csrfToken = globalThis.localStorage.getItem("csrf");

            if (csrfToken && config.headers) config.headers["csrf"] = csrfToken;

            return config;
        }
    }
}
