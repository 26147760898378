import { FC, useState, useMemo } from 'react';
import { AgGridReact } from "ag-grid-react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { useTranslation } from "react-i18next";

import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { DateCellRenderer, LinkCellRenderer, LinkCellRendererProps, ModalCellRenderer, ModalCellRendererChildrenProps, TextCellRenderer } from "components/AGGride/CellRender";
import Modal from "components/Modal";
import FournisseurEdit from "components/Article/RelationFournisseurConditionnement/FournisseurEdit";
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { RowDataType } from "components/AGGride/gridTypes";
import { useArticle } from "context/Referencement";
import { useGridController } from "hooks";
import { SequenceDePrixPartialViewApproTarifaire } from "openapi-typescript-codegen";
import { sequenceDePrixApi } from "api/";
import { GridController } from "hooks/useGridController";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
};

export type GridType = SequenceDePrixPartialViewApproTarifaire;

const FournisseurModal: FC<ModalCellRendererChildrenProps<GridType>> = ({ setIsOpen, agGridRow }) => {
  const parentGridController: GridController | undefined = agGridRow.context.gridController;

  return (<FournisseurEdit
    setIsOpen={setIsOpen}
    onUpdate={parentGridController!.fetchData}
    relationFournisseurConditionnementId={agGridRow.data?.approTarifaire?.id}
  />);
};

const ApprovisionnementTarifaireAchat: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const [activate, setActivate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lowerPriority, setLowerPriority] = useState<null | number>(null);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {
        floatingAction: 'none',
      },
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData,
      fetchData: async () => {
        if (!article?.id) return [];
        const { data: res } = await sequenceDePrixApi.articlesIdViewApprovisionnementTarifaireGet(article.id);

        let newLowerPriority: null | number = null;
        res.forEach(sequence => {
          const priority = sequence.approTarifaire?.priorite || 0;
          if (!newLowerPriority || newLowerPriority < priority)
            newLowerPriority = priority;
        });
        setLowerPriority(newLowerPriority);

        return res.map(sequence => ({ ...sequence, id: sequence.approTarifaire?.id, path: [] }));
      },
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: "approTarifaire.fournisseur.code",
          headerName: t('lib_fournisseur_code'),
          headerTooltip: t('lib_fournisseur_code'),
          pinned: "left",
          cellRenderer: LinkCellRenderer,
          cellRendererParams: {
            href: (_: any, props: ICellRendererParams) => `/app/referencement/fournisseurs/${props.value}`,
            target: '_blank'
          } as LinkCellRendererProps,
          valueFormatter: (params) => {
            if (!params.value) return '';
            if (params.node?.group) {
              let rowData: RowDataType<GridType>;
              params.api.forEachNode((rowNode) => {
                if (rowNode.data && rowNode.data?.approTarifaire?.fournisseur?.code === params.value)
                  rowData = rowNode.data;
              });
              if (rowData!)
                return `${rowData.approTarifaire?.fournisseur?.raisonSociale} (${params.value})`;
            }
            return `${params.data?.approTarifaire?.fournisseur?.raisonSociale} (${params.value})`;
          },
        },
        {
          field: "approTarifaire.nomRelationFournisseurConditionnement",
          headerName: t('lib_relation_fournisseur_conditionnement_nom_relation_fournisseur'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_nom_relation_fournisseur'),
          pinned: "left",
          cellRenderer: ModalCellRenderer,
          cellRendererParams: {
            children: FournisseurModal,
          }
        },
        {
          field: "approTarifaire.flux.modeApprovisionnement.code",
          headerName: t('lib_flux_circuit'),
          headerTooltip: t('lib_flux_circuit'),
          pinned: "left",
          tooltipValueGetter: (params) => {
            const nomModeApprovisionnement = params.data?.approTarifaire?.flux?.modeApprovisionnement?.nomModeApprovisionnement;
            return nomModeApprovisionnement ? `${nomModeApprovisionnement} (${params.value})` : '';
          },
          valueFormatter: (params) => {
            const nomModeApprovisionnement = params.data?.approTarifaire?.flux?.modeApprovisionnement?.nomModeApprovisionnement;
            return nomModeApprovisionnement ? `${nomModeApprovisionnement} (${params.value})` : '';
          },
          cellRenderer: TextCellRenderer,
        },
        {
          field: "approTarifaire.conditionnementFournisseur.code",
          headerName: t('lib_declinaison'),
          headerTooltip: t('lib_declinaison'),
          tooltipValueGetter: (params) => {
            const nomConditionnementFournisseur = params.data?.approTarifaire?.conditionnementFournisseur?.nomConditionnement;
            return nomConditionnementFournisseur ? `${nomConditionnementFournisseur} (${params.value})` : '';
          },
          valueFormatter: (params) => {
            const nomConditionnementFournisseur = params.data?.approTarifaire?.conditionnementFournisseur?.nomConditionnement;
            return nomConditionnementFournisseur ? `${nomConditionnementFournisseur} (${params.value})` : '';
          },
          cellRenderer: TextCellRenderer,
        },
        {
          field: "approTarifaire.status",
          headerName: t('lib_relation_fournisseur_statut'),
          headerTooltip: t('lib_relation_fournisseur_statut'),
          cellRenderer: TextCellRenderer,
          valueFormatter: (params) => {
            return params.value ? t('lib_actif') : t('lib_non_actif');
          },
          cellClass: ['capitalize'],
        },

        {
          field: "approTarifaire.priorite",
          headerName: t('lib_relation_fournisseur_conditionnement_priorite'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_priorite'),
          valueFormatter: (params) => {
            return params.value === lowerPriority ? t('lib_principal') : t('lib_secondaire');
          },
          cellRenderer: TextCellRenderer,
        },
        {
          field: "approTarifaire.refFour",
          headerName: t('lib_relation_fournisseur_conditionnement_ref_four'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_ref_four'),
          cellRenderer: TextCellRenderer,
        },
        {
          field: "approTarifaire.dateDebut",
          headerName: t('lib_relation_fournisseur_conditionnement_date_debut'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_date_debut'),
          cellRenderer: DateCellRenderer,
        },
        {
          field: "approTarifaire.dateFin",
          headerName: t('lib_relation_fournisseur_conditionnement_date_fin'),
          headerTooltip: t('lib_relation_fournisseur_conditionnement_date_fin'),
          cellRenderer: DateCellRenderer,
        },
        {
          field: "approTarifaire.prixAchat.pxAchat",
          headerName: t('lib_prix_achat_px_achat'),
          headerTooltip: t('lib_prix_achat_px_achat'),
          cellRenderer: TextCellRenderer,
        },
        {
          field: "approTarifaire.conditionnementSite.pcb",
          headerName: t('lib_conditionnement_pcb'),
          headerTooltip: t('lib_conditionnement_pcb'),
          cellRenderer: TextCellRenderer,
        },
      ],
    }), [activate, article, lowerPriority, t])
  );

  return (
    <BlockGrid
      title={t('tab_approvisionnement')} sm={sm} md={md} lg={lg} xl={xl}
      doubleXl={doubleXl}
      loading={gridController.isLoading}
      handleClick={(res: boolean) => {
        setActivate(res);
      }}
      toActivate={activate}
      handleCreate={() => setIsModalOpen(true)}
      handleUpdate={gridController.handleCancel}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={false}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
          context={{ gridController }}
        ></AgGridReact>
      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div>
          <FournisseurEdit setIsOpen={setIsModalOpen} onUpdate={gridController.fetchData} />
        </div>
      </Modal>
    </BlockGrid>
  );
};

export default ApprovisionnementTarifaireAchat;