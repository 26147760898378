
const _env_: { [key: string]: string | undefined; } = (globalThis.window as any)?._env_ || {};

function getEnvVar(key: string) {
  return process.env[key] || _env_[key] || '';
}

export const env = {
  VERSION_INFO: getEnvVar('REACT_APP_VERSION_INFO'),
  BACK_REFERENCE_URL: getEnvVar('REACT_APP_BACK_REFERENCE_URL'),
  BACK_TRANSLATION_URL: getEnvVar('REACT_APP_BACK_TRANSLATION_URL'),
  TIME_ZONE: getEnvVar('REACT_APP_TIME_ZONE'),
  USE_DEV_OPENID_CONFIG: getEnvVar('REACT_APP_USE_DEV_OPENID_CONFIG'),
  GOOGLE_MAP_API_KEY: getEnvVar('REACT_APP_GOOGLE_MAP_API_KEY'),
  AG_GRID_LICENSE_KEY: getEnvVar('REACT_APP_AG_GRID_LICENSE_KEY'),
  PUBLIC_URL: process.env.PUBLIC_URL,
  NODE_ENV: process.env.NODE_ENV,
};