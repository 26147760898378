import { FC, useEffect, useMemo, useState } from 'react';
import {
  ArticleAgrementInsertionModel,
  ArticleAgrementViewModel,
  PayViewModel,
  ViewFournisseurViewModel
} from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from "react-i18next";

import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { articleAgrementsApi, articlesApi, paysApi, viewFournisseursApi } from 'api';
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { AutoCompleteCellEditor, AutoCompleteCellEditorProps, DatePickerCellEditor, NumberCellEditor, SelectorCellEditor, SelectorCellEditorProps } from 'components/AGGride/CellEditor';
import { CheckedStyledCellRenderer, DateCellRenderer, RadioCellRenderer, FileCellRenderer } from 'components/AGGride/CellRender';
import {  editableIfTodayOrFuture } from "components/AGGride/cellEditable";
import { dateCellValidator, notEmptyCellValidator, notNilCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import { useArticle } from "context/Referencement";
import { useGridController } from "hooks";

import 'app/ag-grid-style.css';
import {getISOEndDate, getISOStartDate} from "../../../utils";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 90,
};

type GridType = Partial<ArticleAgrementViewModel>;

const Agrements: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const [activate, setActivate] = useState(false);
  const [fournisseurs, setFournisseurs] = useState<Partial<ViewFournisseurViewModel>[]>([]);
  const [isUnique, setIsUnique] = useState(false);
  const [pays, setPays] = useState<PayViewModel[]>([]);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {
        sortColId: "dtdebutAgrement",
        startEditingColId: "numAgrement",
      },
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.numAgrement || !rowData.fkFournisseur,
      fetchData: async () => {
        if (!article?.id) return [];
        return await articlesApi.v1ArticlesIdAgrementsGet(article?.id).then(response => {
          setIsUnique(response.data.length === 1);
          return response.data;
        });
      },
      postData: (cleanRow) => articleAgrementsApi.v1ArticleAgrementsPost({ ...cleanRow, fkArticle: article?.id as number } as ArticleAgrementInsertionModel),
      putData: (cleanRow) => articleAgrementsApi.v1ArticleAgrementsPut(cleanRow as ArticleAgrementViewModel),
      deleteData: (dataId) => articleAgrementsApi.v1ArticleAgrementsIdDelete(dataId),
      getNewModel: () => ({
        numAgrement: 1,
        fkPays: null,
        fkFournisseur: null,
        dtdebutAgrement: getISOStartDate(),
        dtfinAgrement: getISOEndDate(),
      }),
      onFocusChange: (isFocus) => setActivate(isFocus),
      rowClassRules: { "hide-actions": params => !!params.data?.flPrincipal && !isUnique },
      columnDefs: [
        {
          field: 'numAgrement',
          headerName: t('lib_article_agrement_fk_agrement'),
          headerTooltip: t('lib_article_agrement_fk_agrement'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 1
          },
          valueValidator: [notNilCellValidator, numberCellValidator],
          editable: (params) => { return !!params.data?._customDataProps?.isNew; },
        },
        {
          field: "flPrincipal",
          headerName: t('lib_article_agrement_fl_principal'),
          headerTooltip: t('lib_article_agrement_fl_principal'),
          maxWidth: 80,
          minWidth: 80,
          sort: "desc",
          cellRenderer: activate ? RadioCellRenderer : CheckedStyledCellRenderer,
        },
        {
          field: 'fkPays',
          headerName: t('lib_article_agrement_fk_pays'),
          headerTooltip: t('lib_article_agrement_fk_pays'),
          editable: true,
          tooltipValueGetter: (params) => {
            return pays.find(
              (v) => v.id === params.data?.fkPays
            )?.codePaysAlpha2;
          },
          valueFormatter: (params) => {
            const res = pays.find(
              (v) => v.id === params.data?.fkPays
            );
            return `${res?.codePaysAlpha2 ? res?.codePaysAlpha2 : '...'} ${res?.nomPays ? ' - ' + res?.nomPays : ''}` || "";
          },
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: [
              { value: undefined, label: '...' },
              ...pays.map((a) => ({ value: a.id, label: a.nomPays ? (a.codePaysAlpha2 + ' - ' + a.nomPays) : a.codePaysAlpha2 })),
            ]
          } as SelectorCellEditorProps,
          // valueValidator: [notEmptyCellValidator],
          // cellStyle: {
          //   textAlign: 'left',
          // } as CellStyle,
        },
        {
          field: 'fkFournisseur',
          headerName: t('lib_article_agrement_fk_fournisseur'),
          headerTooltip: t('lib_article_agrement_fk_fournisseur'),
          editable: true,
          tooltipValueGetter: (params) => {
            return fournisseurs.find(
              (v) => typeof v.id === 'number' && v.id === params.data?.fkFournisseur
            )?.code;
          },
          valueFormatter: (params) => {
            const res = fournisseurs.find(
              (v) => typeof v.id === 'number' && v.id === params.data?.fkFournisseur
            );
            return `${res?.code ? res?.code : '...'} ${res?.nomTiers ? ' - ' + res?.nomTiers : ''}` || "";
          },
          cellEditor: AutoCompleteCellEditor,
          cellEditorPopup: true,
          cellEditorParams: {
            searchData: (async (search) => {
              const { data: res } = await viewFournisseursApi.v1ViewFournisseursSearchPost(
                true, 1, 10000, { code: search, raisonSociale: search, nomTiers: search }
              );
              const data = res.data || [];
              return data.map(val => ({
                value: val.id,
                prefix: val.code,
                label: `${val.raisonSociale} - ${val.nomTiers} `,
                filterKeyArr: [val.code, val.raisonSociale]
              }));
            }),
          } as AutoCompleteCellEditorProps,
          // valueValidator: [notEmptyCellValidator],
        },
        {
          field: 'dtdebutAgrement',
          headerName: t('lib_article_agrement_dtdebut_agrement'),
          headerTooltip: t('lib_article_agrement_dtdebut_agrement'),
          editable: editableIfTodayOrFuture,
          maxWidth: 110,
          cellRenderer: DateCellRenderer,
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
            min: getISOStartDate(),
            max: getISOEndDate()
          },
          valueValidator: [notEmptyCellValidator, dateCellValidator],
        },
        {
          field: 'dtfinAgrement',
          headerName: t('lib_article_agrement_dtfin_agrement'),
          headerTooltip: t('lib_article_agrement_dtfin_agrement'),
          editable: editableIfTodayOrFuture,
          maxWidth: 110,
          cellRenderer: DateCellRenderer,
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
            min: getISOStartDate(),
            max: getISOEndDate()
          },
          valueValidator: [notEmptyCellValidator],
        },
        {
          field: 'document',
          headerName: t('lib_article_agrement_document'),
          headerTooltip: t('lib_article_agrement_document'),
          cellRenderer: activate ? FileCellRenderer : FileCellRenderer,
          cellRendererParams: {
            name: "test",
            accept: "application/pdf",
            disabled: true
          },
          editable: true,
        },
      ],
    }), [activate, pays, fournisseurs, isUnique, article, t])
  );

  useEffect(() => {
    fetchFournisseurs();
    fetchPays();
  }, []);

  async function fetchPays() {
    const { data: res } = await paysApi.v1PaysGet(1, 10000);
    const newData = res.data?.map(v => ({ ...v, label: `${v.nomPays} (${v.codePaysAlpha2})`.toUpperCase() }));
    setPays(newData || []);
  }

  async function fetchFournisseurs() {
    const { data: res } = await viewFournisseursApi.v1ViewFournisseursGet(1, 10000);
    const newData = res.data?.map(v => ({ ...v, label: `${v.nomTiers} (${v.code})`.toUpperCase() }));
    setFournisseurs([{ code: '...' }, ...(newData || [])]);
  }

  return (
    <BlockGrid
      title={t('tab_article_agrement')} sm={sm} md={md} lg={lg} xl={xl}
      doubleXl={doubleXl}
      loading={gridController.isLoading}
      handleClick={(res: boolean) => {
        setActivate(res);
        setTimeout(gridController.activateContextActionButtons, 50);
      }}
      toActivate={activate}
      disableCreate={gridController.hasEmptyLines}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={($event) => gridController.onRowEditingStopped($event, true)}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default Agrements;