import { useTranslation } from "react-i18next";
import { FC, useRef, useState } from 'react';

import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import { GridType as DataProps } from ".";
import { ModalCellRendererChildrenProps } from "components/AGGride/CellRender";
import { GridController } from "hooks/useGridController";
import { generateRandomUint } from "utils/random";
import { contactsApi } from "api/";
import { showPrettyError } from "utils/error";
import { Delete } from "@mui/icons-material";
import SelectComplete from "components/SelectComplete";
import ConfirmModal, { ConfirmEnum, ConfirmModalRef } from 'components/Modal/ConfirmModal';
import { ContactViewModel } from "openapi-typescript-codegen";
import { mailValidator } from "validators";
import { ContactMailInsertionModel } from "openapi-typescript-codegen/types/models/contact-mail-insertion-model";
import { ContactTelInsertionModel } from "openapi-typescript-codegen/types/models/contact-tel-insertion-model";
import { ContactMailViewModel } from "openapi-typescript-codegen/types/models/contact-mail-view-model";
import { ContactTelViewModel } from "openapi-typescript-codegen/types/models/contact-tel-view-model";

const MailTelModal: FC<ModalCellRendererChildrenProps<DataProps>> = ({ setIsOpen, agGridRow }) => {
  const { t } = useTranslation();
  const [mails, setMails] = useState<Partial<(ContactMailInsertionModel | ContactMailViewModel) & { id: number; }>[]>(
    agGridRow.data?.contactMails?.map(val => ({ ...val, id: generateRandomUint() })) || []
  );
  const [tels, setTels] = useState<Partial<(ContactTelInsertionModel | ContactTelViewModel) & { id: number; }>[]>(
    agGridRow.data?.contactTels?.map(val => ({ ...val, id: generateRandomUint() })) || []
  );
  const [telType] = useState([
    { value: 'Pro', label: t('enu_contact_tel_type_tel_pro') },
    { value: 'Perso', label: t('enu_contact_tel_type_tel_perso') },
    { value: 'Autre', label: t('enu_contact_tel_type_tel_autre') }
  ]);
  const [mailType] = useState([
    { value: 'Pro', label: t('enu_contact_mail_type_email_pro') },
    { value: 'Perso', label: t('enu_contact_mail_type_email_perso') },
    { value: 'Autre', label: t('enu_contact_mail_type_email_autre') }
  ]);
  const modalRef = useRef<ConfirmModalRef | null>(null);
  const parentGridController: GridController | undefined = agGridRow.context?.gridController;

  function validate() {
    return mails.map(mail => mail.email).every(mailValidator);
  }

  async function handleUpdate() {
    let success = false;
    if (validate()) {
      try {
        await contactsApi.v1ContactsPut({ ...agGridRow.data, contactMails: mails, contactTels: tels } as ContactViewModel);
        success = true;
      } catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    }

    if (success) {
      handleCancel();
      setTimeout(() => parentGridController?.fetchData?.(), 50);
    }
  }

  const handleDelete = async (type: 'mail' | 'tel', id?: number) => {
    if (!id) {
      return;
    }
    const validate = await modalRef.current!.showModal();
    if (!validate) {
      return;
    }
    if (type === 'mail') {
      setMails((old) => {
        const currentEmail = old.findIndex(val => val.id === id);
        if (currentEmail === -1) return old;
        old.splice(currentEmail, 1);
        return [...old];
      });
    } else if (type === 'tel') {
      setTels((old) => {
        const currentTels = old.findIndex(val => val.id === id);
        if (currentTels === -1) return old;
        old.splice(currentTels, 1);
        return [...old];
      });
    }
  };

  function handleCancel() {
    setIsOpen(false);
  }

  return (
    <BlockGrid
      title={t('tit_fiche_contact')}
      layout={LayoutEnum.modal}
      toActivate={true}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
    >
      <div className="mt-3 grid grid-cols-4 gap-4">
        <div className="col-span-2">
          <div className="grid grid-cols-9 gap-2">
            <span className="col-span-4">Type mail</span>
            <span className="col-span-4">Mail</span>
            <span className="col-span-1"></span>
          </div>
          <div>
            {mails.map((mail) => {
              const email = mail;
              return (
                <label className="block mb-2 self-end">
                  <div className="grid grid-cols-9 gap-2">
                    <div className="col-span-4 w-full">
                      <SelectComplete tabIndex={5}
                        options={mailType}
                        value={mailType.filter(option => {
                          return option.value === email?.typeEmail;
                        })}
                        onChange={(e) => {
                          const newValue = e?.value;
                          setMails((old) => {
                            const currentEmail = old.find(val => val.id === email.id);
                            if (!currentEmail) return old;
                            currentEmail.typeEmail = newValue;
                            return [...old];
                          });
                        }}
                      />
                    </div>
                    <div className="col-span-4 w-full">
                      <input
                        title={email.email || ''}
                        value={email.email || ''}
                        type="email"
                        onChange={(e) => {
                          const newValue = e.currentTarget.value;
                          setMails((old) => {
                            const currentEmail = old.find(val => val.id === email.id);
                            if (!currentEmail) return old;
                            currentEmail.email = newValue;
                            return [...old];
                          });
                        }}
                        className="col-span-1 h-8 mt-1 block px-2 py-2 bg-white-500 border border-slate-300 rounded-md text-sm placeholder-slate-400 disabled:text-gray-600 disabled:border-slate-200 disabled:bg-white-100 focus:border-store-primary"
                      />
                    </div>
                    <button
                      type="button"
                      className="col-span-1 w-full rounded flex justify-center items-center
                        hover:bg-red-500 hover:text-white-500 focus:bg-red-500 focus:text-white-500 transition"
                      onClick={() => handleDelete('mail', mail.id)}>
                      <Delete />
                    </button>
                  </div>
                </label>
              );
            })}
          </div>
          <div className="flex">
            <button tabIndex={5}
              className="my-2 border border-store-primary hover:bg-store-primary focus:bg-store-primary text-store-primary hover:text-white-500 focus:text-white-500 rounded py-0.5 px-2"
              aria-label={t("bul_nouveau")}
              data-mui-internal-clone-element="true"
              onClick={() => {
                setMails((old) => [...old, { id: generateRandomUint(), fkContact: agGridRow.data?.id, typeEmail: 'Pro' }]);
              }}>
              Nouveau +
            </button>
          </div>
        </div>
        <div className="col-span-2">
          <div className="grid grid-cols-9 gap-2">
            <span className="col-span-4">Type téléphone</span>
            <span className="col-span-4">Téléphone</span>
          </div>
          {tels.map((tel) => {
            const numTel = tel;
            return (
              <label className="block mb-2 self-end">
                <div className="grid grid-cols-9 gap-1">
                  <div className="col-span-4">
                    <SelectComplete tabIndex={5}
                      options={telType}
                      value={telType.filter(option => {
                        return option.value === tel?.typeTel;
                      })}
                      onChange={(e) => {
                        const newValue = e?.value;
                        setTels((old) => {
                          const currentTel = old.find(val => val.id === tel.id);
                          if (!currentTel) return old;
                          currentTel.typeTel = newValue;
                          return [...old];
                        });
                      }}
                    />
                  </div>
                  <div className="col-span-4">
                    <input
                      title={numTel.numTel || ''}
                      value={numTel.numTel || ''}
                      type="tel"
                      onChange={(e) => {
                        const newValue = e.currentTarget.value;
                        setTels((old) => {
                          const currentTel = old.find(val => val.id === tel.id);
                          if (!currentTel) return old;
                          currentTel.numTel = newValue;
                          return [...old];
                        });
                      }}
                      className="h-8 mt-1 block px-2 py-2 bg-white-500 border border-slate-300 rounded-md text-sm placeholder-slate-400 disabled:text-gray-600 disabled:border-slate-200 disabled:bg-white-100 focus:border-store-primary"
                    />
                  </div>
                  <button
                    type="button"
                    className="col-span-1 w-full rounded flex justify-center items-center
                        hover:bg-red-500 hover:text-white-500 focus:bg-red-500 focus:text-white-500 transition"
                    onClick={() => handleDelete('tel', tel.id)}>
                    <Delete />
                  </button>
                </div>
              </label>
            );
          })}
          <div className="flex">
            <button tabIndex={5}
              className="my-2 border border-store-primary hover:bg-store-primary focus:bg-store-primary text-store-primary hover:text-white-500 focus:text-white-500 rounded py-0.5 px-2"
              aria-label={t("bul_nouveau")}
              data-mui-internal-clone-element="true"
              onClick={() => {
                setTels((old) => [...old, {
                  id: generateRandomUint(),
                  typeTel: 'Pro'
                }]);
              }}>Nouveau +</button>
          </div>
        </div>

      </div>
      <ConfirmModal ref={modalRef} layout={ConfirmEnum.delete} />
    </BlockGrid>
  );
};

export default MailTelModal;