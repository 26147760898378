import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { SiteWithTiersViewModel } from "openapi-typescript-codegen";
import { components, OptionProps, ValueContainerProps } from "react-select";
import cs from 'classnames';

import { sitesApi } from "api";
import AccountControls from 'components/AccountControls';
import SelectComplete, { SelectTemplate } from "components/SelectComplete";
import { useSite } from "context/site";

type OptionType = { prefix: string; } & SelectTemplate<number>;

const SideBar: FunctionComponent = () => {
  const { state: site, dispatch: dispatchSite } = useSite();
  const [sites, setSites] = useState<SiteWithTiersViewModel[]>([]);
  const [links] = useState([
    { id: 1, path: '/', label: 'Dashboard' },
    { id: 2, path: '/', label: 'Applications' },
    { id: 3, path: '/', label: 'GED' }
  ]);

  const selectOptions = useMemo(() => {
    return sites.map((sit): OptionType => ({
      value: sit.id!,
      label: `${sit.tier?.nomTiers || sit.tier?.nomCourtTiers}`,
      prefix: `${sit.tier?.numTiersExt}`,
    }));
  }, [sites]);

  const currentTiers = useMemo(() => {
    return sites.find(sit => sit.id === site?.id)?.tier;
  }, [sites, site]);

  useEffect(() => {
    fetchSites();
  }, []);

  async function fetchSites() {
    const { data: { data } } = await sitesApi.v1SitesTiersGet(1, 100);
    setSites(data || []);
  }

  const OptionComponent = useCallback(({ children, ...props }: OptionProps<OptionType>) => {
    return (
      <components.Option {...props} >
        <>
          <span className="opacity-70 text-sm font-bold">{props.data.prefix}</span>
          <span> - </span>
          {children}
        </>
      </components.Option>
    );
  }, []);

  const SelectContainerComponent = useCallback((props: ValueContainerProps<OptionType>) => {
    return (
      <Tooltip title={currentTiers?.nomTiers} placement='top'>
        <div className="w-full h-full">
          <components.ValueContainer {...props} />
        </div>
      </Tooltip>
    );
  }, [currentTiers]);

  return (
    <div className="side-bar">
      <div className="flex flex-col w-full text-xs gap-2">
        <div className="pt-2 w-full flex flex-col items-center justify-end gap-2 transition-all relative mb-6">
          <div
            className="side-bar-title-img"
            style={{ backgroundImage: `url(${currentTiers?.logo || '/assets/images/default.png'})` }}
          >
            {/* Black arrow */}
            {/* <div className="absolute bottom-px left-[18px]" style={{ borderLeft: "5px solid transparent", borderRight: "5px solid transparent", borderTop: "6px solid black" }}></div> */}
          </div>
          <span className="side-bar-site w-full">
            <div className="w-5/6 mx-auto mb-2" >
              <SelectComplete
                tabIndex={1}
                classNameAdd="cursor-pointer"
                options={selectOptions}
                value={selectOptions.find(sit => sit.value === site?.id)}
                indicator={false}
                components={{ Option: OptionComponent, ValueContainer: SelectContainerComponent }}
                onChange={(val) => {
                  const selectedSite = sites.find(sit => sit.id === val?.value);
                  if (selectedSite)
                    dispatchSite(selectedSite);
                }}
                isMulti={false}

              />
            </div>
          </span>
          <div className="absolute w-1/3 mx-auto h-0.5 bottom-0 bg-slate-200"></div>
        </div>
        <div className="side-bar-menu">
          {links.map(link => (
            <NavLink
              key={link.id}
              tabIndex={1}
              to={link.path}
              className={cs(
                "h-16 w-full flex flex-col justify-center items-center gap-1 cursor-not-allowed transition-all",
                "hover:bg-store-primary focus:bg-store-primary text-store-primary hover:text-white-500 focus:text-white-500"
              )}
            >
              <div className="rounded-full h-6 w-6 flex justify-center items-center overflow-hidden border bg-store-primary hover:border-white-100"></div>
              <span className="side-bar-title">{link.label}</span>
            </NavLink>
          ))}
        </div>
      </div>

      <div className="mb-4">
        <AccountControls />
      </div>
    </div>
  );
};

export default SideBar;
