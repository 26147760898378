import { RefCallback, useCallback, useState } from "react";

type OnRefChange<T> = RefCallback<T>;
type FocusRefFunc = () => void;
type HtmlElRef<T> = T | null;

export function useFocus<T extends HTMLElement>(autoFocus = true): [OnRefChange<T>, FocusRefFunc, HtmlElRef<T>] {
    const [htmlElRef, setHtmlElRef] = useState<T | null>(null);

    const onRefChange = useCallback<OnRefChange<T>>((node) => {
        setHtmlElRef((old) => {
            if (old !== node && node && autoFocus) node.focus();
            return node;
        });
    }, []);

    const setFocus = () => { htmlElRef?.focus(); };

    return [onRefChange, setFocus, htmlElRef];
};
