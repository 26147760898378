import { AxiosResponse } from "axios";
import { interpolate, parse, showPrettyError } from "utils";
import { SelectTemplate } from "components/SelectComplete";

export const api = {
  /*
   Get the data object from the promise.
   */
  data: {
    async get<Type>(promise: Promise<AxiosResponse>): Promise<Type> {
      try {
        const { data } = await promise;
        return (data || {}) as Type;
      } catch (error) {
        console.error(error);
        showPrettyError(error);
        return {} as Type;
      }
    },
  },
  /*
   Get the datas array from the promise.
   */
  dataset: {
    async get<Type>(promise: Promise<AxiosResponse>): Promise<Type[]> {
      try {
        const { data } = await promise;
        return (data.hasOwnProperty("data") ? data.data || [] : data || []) as Type[];
      } catch (error) {
        console.error(error);
        showPrettyError(error);
        return [] as Type[];
      }
    }
  },
  options: {
    /*
     Generate the options for a SelectTemplate array, with the configuration.
     The label must be a string, with the fields between brackets with a # in front of it.
     Example: "#{FIELD}".
     */
    generate: (data: any[], configuration: { label: string, value?: string, filterKeys?: string[]; }, nullable?: boolean) => {
      let options: SelectTemplate<any>[] = [];
      if (data && data.length) {
        options = data.map(model => ({
          value: configuration.value ? parse(configuration.value, model) : model.id,
          label: interpolate(configuration.label, model),
          filterKeys: configuration.filterKeys && configuration.filterKeys.map(key => model[key]),
          data: model
        }));
        options = options.sort((option$1, options$2) =>
          String(option$1.label).localeCompare(String(options$2.label)));
        nullable && options.unshift({ value: null, label: "---" });
      }
      return options;
    },
    /*
      Retrieve the data and generate the options in bulk.
     */
    retrieve: async (entities: { key: string, promise: Promise<AxiosResponse>, configuration: { label: string, value?: string, filterKeys?: string[]; }, nullable?: boolean; }[]) => {
      let options: { [key: string]: SelectTemplate[]; } = {};
      let promises: Promise<{ [key: string]: any[]; }>[] = [];
      for (const entity of entities) {
        promises.push(api.dataset.get(entity.promise).then(data => ({ [entity.key]: data })));
      }
      const response = await Promise.all(promises);
      const dataset = response.reduce((base, current) => ({ ...base, ...current }));
      for (const [key, data] of Object.entries(dataset)) {
        const { configuration, nullable } = entities.find(entity => entity.key === key)!;
        options[key] = api.options.generate(data, configuration, nullable);
      }
      return options;
    },
    dataset: {
      /*
      Get the data from the options (a SelectTemplate array).
      */
      get<Type extends { [key: string]: any; }>(options: SelectTemplate[]): Type[] {
        return options.reduce((accumulator, current) =>
          current.data ? [...accumulator, current.data] : accumulator
          , [] as { [key: string]: any; }[]) as Type[];
      }
    }
  }
};