import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import cs from "classnames";

import {
  APPROVISIONNEMENT_MENU,
  REFERENCEMENT_MENU,
} from "app/parameters-routes";

const svgDir = require.context("assets/icons/menu");

interface Props {
  className?: string;
  sectionTitle: string;
}

const sections = [REFERENCEMENT_MENU, APPROVISIONNEMENT_MENU];

const AppMenu: FunctionComponent<Props> = ({ className, sectionTitle }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={cs("flex gap-8 justify-center content-end items-end place-content-end", className)}>
        {sections?.map((section) => {
          return (
            <Link
              key={`${section.title} ${section.url}`}
              to={{
                pathname: `/app/${section.url}`,
              }}
              className={cs("flex flex-row gap-2 pb-1 border-b-2", {
                "border-store-primary text-store-primary": section.title === sectionTitle,
                "border-grey-200 text-grey-500": section.title !== sectionTitle,
              })}>
              <img
                src={svgDir(section.title === sectionTitle ? `./${section.icon}-active.svg` : `./${section.icon}.svg`)}
                alt={section.icon}
              />
              <span className="text-base font-semibold" >
                {t(section.title)}
              </span>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default AppMenu;
