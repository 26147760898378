import { FC, useMemo, useState } from 'react';
import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import { Title } from "components/H2Title";
import { TypeEanInsertionModel, TypeEanViewModel } from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";

import { typeEansApi } from 'api';
import BlockGrid, { PropsBlock, LayoutEnum } from 'components/BlockGrid';
import { NumberCellEditor, SelectorCellEditor } from 'components/AGGride/CellEditor';
import {
  CheckedCellRenderer,
  LinkCellRenderer,
  LinkCellRendererProps,
  TextCellRenderer
} from 'components/AGGride/CellRender';
import { notEmptyCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import { useGridController } from "hooks";
import {CheckboxFilter} from "../../components/AGGride/CellFilter";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

type GridType = Partial<TypeEanViewModel>;

const TypeEan: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);

  const [typeEans, setTypeEans] = useState<TypeEanViewModel[]>([]);

  const options = {
    types: [
      { value: null, label: "..." },
      { value: 'I', label: "Inclu" },
      { value: 'E', label: "Exclu" },
      { value: 'L', label: "Longueur" }
    ]
  };

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.code,
      fetchData: async () => {
        let { data: { data } } = await typeEansApi.v1TypeEansGet(1, 1000);
        data = data?.sort((type$1, type$2) => type$1.code - type$2.code) || [];
        setTypeEans(data);
        return data.sort(function (a, b) { return a.code - b.code; });
      },
      postData: (cleanRow) => typeEansApi.v1TypeEansPost(cleanRow as TypeEanInsertionModel),
      putData: (cleanRow) => typeEansApi.v1TypeEansPut(cleanRow as TypeEanViewModel),
      deleteData: (dataId) => typeEansApi.v1TypeEansIdDelete(dataId),
      onFocusChange: (isFocus) => setActivate(isFocus),
      getNewModel: () => ({
        code: [...Array(10).keys()].splice(1).find((value) => !typeEans.find((typeEan) => typeEan.code === value)),
        longueurMini: 1,
        longueurMaxi: 1,
        flCtrlCle: true
      }),
      columnDefs: [
        {
          field: 'code',
          headerName: t('lib_ean_type_ean'),
          headerTooltip: t('lib_ean_type_ean'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator, numberCellValidator],
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 1,
            max: 9,
          },
          editable: true,
          filter: 'agTextColumnFilter',
          flex: 1
        },
        {
          field: 'nomTypeEan',
          headerName: t('lib_type_ean_nom_type_ean'),
          headerTooltip: t('lib_type_ean_nom_type_ean'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 3
        },
        {
          field: 'longueurMini',
          headerName: t('lib_type_ean_longueur_mini'),
          headerTooltip: t('lib_type_ean_longueur_mini'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator, numberCellValidator],
          cellEditor: NumberCellEditor,
          editable: true,
          filter: 'agTextColumnFilter',
          onCellValueChanged: (params: any) => {
            // Set the maximum value to be at least the minimum one:
            if (Number(params.newValue) > Number(params.data.longueurMaxi)) {
              params.data.longueurMaxi = params.data.longueurMini;
            }
          },
          cellEditorParams: { min: 1, max: 99 },
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'longueurMaxi',
          headerName: t('lib_type_ean_longueur_maxi'),
          headerTooltip: t('lib_type_ean_longueur_maxi'),
          cellEditor: NumberCellEditor,
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator, numberCellValidator],
          editable: true,
          filter: 'agTextColumnFilter',
          onCellValueChanged: (params) => {
            // Set the maximum value to be at least the minimum one:
            if (Number(params.newValue) < Number(params.data.longueurMini)) {
              params.newValue = params.data.longueurMini;
              params.data.longueurMaxi = params.data.longueurMini;
            }
          },
          cellEditorParams: { min: 1, max: 99 },
          cellStyle: {
            textAlign: "left",
          },
          flex: 1
        },
        {
          field: 'flCtrlCle',
          headerName: t('lib_type_ean_fl_ctrl_cle'),
          headerTooltip: t('lib_type_ean_fl_ctrl_cle'),
          cellRenderer: CheckedCellRenderer,
          editable: false,
          filter: 'agTextColumnFilter',
          floatingFilterComponent: CheckboxFilter,
          cellStyle: {
            textAlign: "left",
          },
          flex: 1
        },
        {
          field: 'nbZero',
          headerName: t('lib_type_ean_nb_zero'),
          headerTooltip: t('lib_type_ean_nb_zero'),
          cellEditor: NumberCellEditor,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 1
        },
        {
          field: 'typeCtrlIndicatif',
          headerName: t('lib_type_ean_type_ctrl_indicatif'),
          headerTooltip: t('lib_type_ean_type_ctrl_indicatif'),
          cellRenderer: TextCellRenderer,
          tooltipValueGetter: (params) => {
            return options.types.find((v) => v.value === params.value)?.value;
          },
          valueFormatter: (params) => options.types.find((v) => v.value === params.value)?.label || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: options.types.map((a) => ({ value: a.value, label: a.value ? a.value + ' - ' + a.label : a.label })),
          },
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 2
        },
        {
          headerName: t('lib_type_ean_detail_indicatif'),
          headerTooltip: t('lib_type_ean_detail_indicatif'),
          cellRenderer: LinkCellRenderer,
          valueFormatter: (params) => params.data?.typeCtrlIndicatif === "I" ? "Voir details" : "",
          cellRendererParams: { href: "/app/referencement/articles/parametres/indicatif-ean", target: "_blank" } as LinkCellRendererProps,
          editable: false,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "center",
          },
          flex: 2
        },
      ],
    }), [activate, typeEans, options, t])
  );

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: `${t('men_refe_articles')} - ${t('men_parametre')}`,
      link: `/app/referencement/articles/parametres`,
    },
    {
      name: "tit_type_ean",
    },
  ];

  const title: Title = {
    title: `${t('men_refe_articles')} - ${t('tab_type_ean')}`,
    icon: "articles",
  };

  return (
    <>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <BlockGrid
        sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
        layout={LayoutEnum.settings}
        handleClick={(res: boolean) => {
          setActivate(res);
          setTimeout(gridController.activateContextActionButtons, 50);
        }}
        toActivate={activate}
        // Creation is disabled if there is already 9 types of EAN:
        disableCreate={gridController.hasEmptyLines || typeEans.length >= 9}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-params">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType={"fullRow"}
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            // Disallow to create a new row if one (new one) is already in edition:
            onRowEditingStopped={($event) => gridController.onRowEditingStopped($event, true)}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </>
  );
};

export default TypeEan;
