import { FC, useEffect, useMemo, useState } from 'react';
import { DeviseViewModel, TauxChangeInsertionModel, TauxChangeViewModel } from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";

import { tauxChangesApi, devisesApi } from 'api';
import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import { DateCellRenderer, TextCellRenderer } from 'components/AGGride/CellRender';
import { useGridController } from "hooks";
import { dateCellValidator, notEmptyCellValidator } from "components/AGGride/cellValidator";
import { DatePickerCellEditor, SelectorCellEditor } from "components/AGGride/CellEditor";
import { editableIfNew, editableIfTodayOrFuture } from "components/AGGride/cellEditable";
import { getISOStartDate } from "utils";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

type GridType = Partial<TauxChangeViewModel | TauxChangeInsertionModel>;

const TauxChange: FC<{ onTauxChange?: (deviseDepart: number, deviseArrivee: number) => void; deviseId: number | undefined; }> = ({ onTauxChange, deviseId }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);
  const [devise, setDevise] = useState<Partial<DeviseViewModel>[]>([]);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      onFocusChange: (isFocus) => setActivate(isFocus),
      fetchData: async () => {
        const { data } = await devisesApi.v1DevisesIdTauxChangesGet(deviseId as number);
        return data ?? [];
      },
      getNewModel: () => {
        return {
          fkDeviseDepart: deviseId,
          dateChange: getISOStartDate(),
          base: 1
        };
      },
      postData: (cleanRow) => tauxChangesApi.v1TauxChangesPost(cleanRow as TauxChangeInsertionModel),
      putData: (cleanRow) => tauxChangesApi.v1TauxChangesPut(cleanRow as TauxChangeViewModel),
      deleteData: (dataId) => tauxChangesApi.v1TauxChangesIdDelete(dataId),
      columnDefs: [
        {
          field: 'fkDeviseDepart',
          headerName: t('lib_taux_change_fk_devise_depart'),
          headerTooltip: t('lib_taux_change_fk_devise_depart'),
          editable: editableIfNew,
          tooltipValueGetter: (params) => {
            return devise.find((v) => v.id === params.data?.fkDeviseDepart)?.code;
          },
          valueFormatter: (params) => devise.find((v) => v.id === params.data?.fkDeviseDepart)?.code || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: devise.map((a) => ({ value: a.id, label: a.nomDevise ? a.code + ' - ' + a.nomDevise : a.code })),
          },
          cellRenderer: TextCellRenderer,
          filter: 'agTextColumnFilter',
          valueValidator: [notEmptyCellValidator],
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
        },
        {
          field: 'fkDeviseArrivee',
          headerName: t('lib_taux_change_fk_devise_arrivee'),
          headerTooltip: t('lib_taux_change_fk_devise_arrivee'),
          editable: editableIfNew,
          tooltipValueGetter: (params) => {
            return devise.find((v) => v.id === params.data?.fkDeviseArrivee)?.code;
          },
          valueFormatter: (params) => devise.find((v) => v.id === params.data?.fkDeviseArrivee)?.code || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: devise.map((a) => ({ value: a.id, label: a.nomDevise ? a.code + ' - ' + a.nomDevise : a.code })),
          },
          cellRenderer: TextCellRenderer,
          filter: 'agTextColumnFilter',
          valueValidator: [notEmptyCellValidator],
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
        },
        {
          field: 'dateChange',
          headerName: t('lib_taux_change_date_change'),
          headerTooltip: t('lib_taux_change_date_change'),
          editable: editableIfTodayOrFuture,
          maxWidth: 210,
          cellRenderer: DateCellRenderer,
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
          },
          valueValidator: [notEmptyCellValidator, dateCellValidator],
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
        },
        {
          field: 'tauxConversion',
          tooltipField: 'code',
          headerName: t('lib_taux_change_taux_conversion'),
          headerTooltip: t('lib_taux_change_taux_conversion'),
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          valueValidator: [notEmptyCellValidator],
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
        },
        {
          field: 'base',
          tooltipField: 'code',
          headerName: t('lib_taux_change_base'),
          headerTooltip: t('lib_taux_change_base'),
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          valueValidator: [notEmptyCellValidator],
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
        },
      ],
    }), [activate, t, devise])
  );

  async function fetchDevise() {
    const { data: res } = await devisesApi.v1DevisesGet(1, 1000);
    const newData = res.data?.map(v => ({ ...v, label: `${v.nomDevise} (${v.code})`.toUpperCase() }));
    setDevise([{ code: '...' }, ...(newData || [])]);
  };

  function onRowSelected(params: RowSelectedEvent<GridType>) {
    if (params.node.isSelected() && params.data?.fkDeviseDepart && params.data?.fkDeviseArrivee) {
      onTauxChange?.(params.data.fkDeviseDepart, params.data.fkDeviseArrivee);
    }
  };

  useEffect(() => {
    fetchDevise();
  }, []);

  return (
    <div className="flex-[3]">
      <div className="mb-2 text-grey-500 font-bold">{t('tab_taux_change')}</div>
      <BlockGrid
        layout={LayoutEnum.settings}
        handleClick={(res: boolean) => {
          setActivate(res);
          setTimeout(gridController.activateContextActionButtons, 50);
        }}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-modal">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType="fullRow"
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
            rowSelection="single"
            onRowSelected={onRowSelected}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </div>
  );
};

export default TauxChange;