import { useState, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import cs from "classnames";

import { useKeydown } from "hooks/useKeydown";

export interface NavigationItemProps<GenericViewModel extends { id: number, code: string; }> {
  item: GenericViewModel;
  selectedItem?: GenericViewModel;
  onSelectItem: (id: number) => void;
  deleteItemButton?: ReactNode;
  display: {
    code: keyof GenericViewModel,
    name: keyof GenericViewModel,
    type: keyof GenericViewModel;
  };
  children?: ReactNode;
}

const NavigationItem = <GenericViewModel extends { id: number, code: string; },>(
  {
    item,
    selectedItem,
    onSelectItem,
    deleteItemButton,
    display,
    children
  }: NavigationItemProps<GenericViewModel>
) => {
  const { t } = useTranslation();
  const [hasFocus, setFocus] = useState(false);
  const [hasHover, setHover] = useState(false);
  const isSelected = selectedItem?.id !== undefined && selectedItem?.id === item.id;
  const type = ((c: unknown) => {
    if (c === "M") {
      return (t('enu_lieu_fonction_type_mixte'));
    } else if (c === "R") {
      return (t('enu_lieu_fonction_type_reglement'));
    } else if (c === "C") {
      return (t('enu_lieu_fonction_type_commande'));
    }
  })(item[display.type]);

  const handleKeyDownEnter = () => {
    (hasFocus || hasHover) && onSelectItem(item.id);
  };

  useKeydown("Enter", handleKeyDownEnter);

  return (
    <div className="rounded-lg overflow-hidden bg-white-500 mx-2">
      <Tooltip title={`${item.code} - ${item[display.name]}`} followCursor>
        <div
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onClick={() => { onSelectItem(item.id); }}
          className={cs("relative overflow-hidden transition-all hover:cursor-pointer",
            isSelected ? 'bg-store-primary shadow-lg' : 'bg-white-500'
          )}>
          <div className="p-[12px] gap-[12px] flex flex-row align-baseline">
            <div className="h-[45px] w-[45px] min-w-[45px] ">
              <div className={cs("h-full w-full flex justify-center items-center text-store-primary font-semibold text-sm rounded-full",
                isSelected ? 'bg-white-500' : 'bg-store-primary-light'
              )}>{item.code}</div>
            </div>

            <div className={cs("h-[45px] flex flex-col overflow-hidden",
              isSelected ? 'text-white-500' : 'text-store-primary'
            )}>
              <span className="self-start font-semibold uppercase text-ellipsis whitespace-nowrap w-full overflow-hidden">{String(item[display.name])}</span>
              <span className="self-start text-sm opacity-70 text-ellipsis whitespace-nowrap w-full overflow-hidden">{type}</span>
            </div>

            {isSelected && deleteItemButton}
          </div>
        </div>
      </Tooltip>
      {children && <div>{children}</div>}
    </div>

  );
};

export default NavigationItem;