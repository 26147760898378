import { FunctionComponent, useEffect, useState } from "react";
import { toast } from 'utils';

import BlockGrid, { PropsBlock } from "components/BlockGrid";

import { articlesApi, dangereuxApi } from 'api';
import {DangereuxInsertionModel, DangereuxViewModel} from 'openapi-typescript-codegen';

import { useTranslation } from "react-i18next";
import { useArticle, useCompletudeArticle, useUpdateArticle } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateArticle';
import { CheckInput, isMissing } from 'utils/Article/completude';

import DangerTab from './DangerTab';

const IMDGIcon = '🚢';
const IATAIcon = '✈️';
const ADRIcon = '🚚';

const Dangereux: FunctionComponent<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const uA = useUpdateArticle();
  const { state: controled } = useCompletudeArticle();
  const [completude, setCompletude] = useState<number>(0);
  const { state: article } = useArticle();
  const [activate, setActivate] = useState<boolean>(false);
  const [hasFocus, setFocus] = useState(false);
  const [formIMDG, setFormIMDG] = useState<Partial<DangereuxViewModel>>({});
  const [formIATA, setFormIATA] = useState<Partial<DangereuxViewModel>>({});
  const [formADR, setFormADR] = useState<Partial<DangereuxViewModel>>({});
  const [isSelected, setSelected] = useState<string>('IMDG');
  const [loading, setLoader] = useState<boolean>(true);

  function checkFormCompletude(form: Partial<DangereuxViewModel>) {
    let tmp = 0;
    if (isMissing(form.nomOfficielTransport ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.nom_officiel_transport'))) tmp++;
    if (isMissing(form.fkTechClasseDanger ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.classe_danger'))) tmp++;
    if (isMissing(form.groupeEmballage ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.groupe_emballage'))) tmp++;
    if (isMissing(form.fkTechGrpeSeparation ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.grpe_separation'))) tmp++;
    if (isMissing(form.onu ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.onu'))) tmp++;
    if (isMissing(form.emballageUnitaire ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.emballage_unitaire'))) tmp++;
    if (isMissing(form.nsa ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.nsa'))) tmp++;
    if (isMissing(form.declarationConditionnement ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.declaration_conditionnement'))) tmp++;
    if (isMissing(form.ficheSecurite ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.fiche_securite'))) tmp++;
    if (isMissing(form.pointEclair ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.point_eclair'))) tmp++;
    if (isMissing(form.declarationContenance ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.declaration_contenance'))) tmp++;
    if (isMissing(form.soinsMedicaux ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.soins_medicaux'))) tmp++;
    if (isMissing(form.explosivite ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.explosivite'))) tmp++;
    if (isMissing(form.flQteLimitee ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.fl_qte_limitee'))) tmp++;
    if (isMissing(form.flPolluantMarin ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.fl_polluant_marin'))) tmp++;
    if (isMissing(form.commentaireDangereux ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.commentaire_dangereux'))) tmp++;
    return tmp;
  }

  function checkCompletude() {
    let tmp = 0;
    if (formIMDG.flDangereux) tmp += checkFormCompletude(formIMDG);
    if (formIATA.flDangereux) tmp += checkFormCompletude(formIATA);
    if (formADR.flDangereux) tmp += checkFormCompletude(formADR);
    setCompletude(tmp);
  }

  useEffect(() => {
    checkCompletude();
  }, [formIMDG, formIATA, formADR]);

  const fetchGetData = async (id: number) => {
    const response = await articlesApi.v1ArticlesIdDangereuxGet(id);

    if (response.status >= 200 && response.status < 300) setLoader(false);
    // setForm(response.data)
    setFormIMDG(response.data?.find((item) => item.typeReglement === "IMDG") ?? {
      fkArticle: article?.id,
      typeReglement: "IMDG",
      codeTransport: ''
    });
    setFormIATA(response.data?.find((item) => item.typeReglement === "IATA") ?? {
      fkArticle: article?.id,
      typeReglement: "IATA",
      codeTransport: ''
    });
    setFormADR(response.data?.find((item) => item.typeReglement === "ADR") ?? {
      fkArticle: article?.id,
      typeReglement: "ADR",
      codeTransport: ''
    });
  };

  const fetchUpdateData = async (form: Partial<DangereuxViewModel>[]) => {
    try {
      const dataToSent = form.map((item) => {
        if (!item.id && item.codeTransport === '') return true;
        else if (item.id && item.codeTransport === '') return dangereuxApi.v1DangereuxIdDelete(item.id);
        else if (item.id) return dangereuxApi.v1DangereuxIdPut(item.id, item);
        else return dangereuxApi.v1DangereuxPost(item as DangereuxInsertionModel);
      });
      await Promise.all(dataToSent);
      uA.triggerEvent(UpdateEnum.dangereux);
      toast.success(t('mes_validation_modification'));
      setActivate(false);
    } catch (error: any) {
      toast.error(t('mes_erreur_technique') + ": " + (error?.response?.data?.message || error));
    }
  };

  useEffect(() => {
    if (article?.id) fetchGetData(article.id);
  }, []);

  function validator(form: Partial<DangereuxViewModel>[]) {
    let isValid = true;
    form.forEach((item) => {
      if (item.flDangereux) {
        if (!CheckInput(item.nomOfficielTransport ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.nom_officiel_transport'), 'lib_dangereux_nom_officiel_transport')) isValid = false;
        if (!CheckInput(item.fkTechClasseDanger ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.classe_danger'), 'lib_dangereux_classe_danger')) isValid = false;
        if (!CheckInput(item.groupeEmballage ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.groupe_emballage'), 'lib_dangereux_groupe_emballage')) isValid = false;
        if (!CheckInput(item.fkTechGrpeSeparation ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.grpe_separation'), 'lib_dangereux_grpe_separation')) isValid = false;
        if (!CheckInput(item.onu ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.onu'), 'lib_dangereux_onu')) isValid = false;
        if (!CheckInput(item.emballageUnitaire ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.emballage_unitaire'), 'lib_dangereux_emballage_unitaire')) isValid = false;
        if (!CheckInput(item.nsa ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.nsa'), 'lib_dangereux_nsa')) isValid = false;
        if (!CheckInput(item.declarationConditionnement ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.declaration_conditionnement'), 'lib_dangereux_declaration_conditionnement')) isValid = false;
        if (!CheckInput(item.ficheSecurite ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.fiche_securite'), 'lib_dangereux_fiche_securite')) isValid = false;
        if (!CheckInput(item.pointEclair ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.point_eclair'), 'lib_dangereux_point_eclair')) isValid = false;
        if (!CheckInput(item.declarationContenance ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.declaration_contenance'), 'lib_dangereux_declaration_contenance')) isValid = false;
        if (!CheckInput(item.soinsMedicaux ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.soins_medicaux'), 'lib_dangereux_soins_medicaux')) isValid = false;
        if (!CheckInput(item.explosivite ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.explosivite'), 'lib_dangereux_explosivite')) isValid = false;
        if (!CheckInput(item.flQteLimitee ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.fl_qte_limitee'), 'lib_dangereux_fl_qte_limitee')) isValid = false;
        if (!CheckInput(item.flPolluantMarin ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.fl_polluant_marin'), 'lib_dangereux_fl_polluant_marin')) isValid = false;
        if (!CheckInput(item.commentaireDangereux ?? '', controled?.find((i) => i.colonneArticle === 'dangereux.commentaire_dangereux'), 'lib_dangereux_commentaire_dangereux')) isValid = false;
      }
    });
    return isValid;
  }

  function handleUpdate() {
    let tmp = true;
    if (needCodeTransport(formIMDG) && formIMDG.codeTransport === '') {
      toast.error(t('err_champ_obligatoire') + ":\n" + t('enu_article_transport_export_type_reglementation_IMDG'));
      tmp = false;
    }
    if (needCodeTransport(formIATA) && formIATA.codeTransport === '') {
      toast.error(t('err_champ_obligatoire') + ":\n" + t('enu_article_transport_export_type_reglementation_IATA'));
      tmp = false;
    }
    if (needCodeTransport(formADR) && formADR.codeTransport === '') {
      toast.error(t('err_champ_obligatoire') + ":\n" + t('enu_article_transport_export_type_reglementation_ADR'));
      tmp = false;
    }
    if (validator([formIMDG, formIATA, formADR]) && tmp
      // && checker()
      && formIMDG && formIATA && formADR) fetchUpdateData([formIMDG, formIATA, formADR]);
  }

  function handleCancel() {
    if (article?.id) fetchGetData(article.id);
    setActivate(false);
  }

  function handleFocus(focus: boolean) {
    setFocus(focus);
  }

  function needCodeTransport(object: Partial<DangereuxViewModel>) {
    if (object.codeTransport === '') {
      if (object.flDangereux === true) return true;
      if (typeof object?.nomOfficielTransport === "string" && object?.nomOfficielTransport?.length > 0) return true;
      if (typeof object?.groupeEmballage === "string" && object?.groupeEmballage?.length > 0) return true;
      if (typeof object?.onu === "string" && object?.onu?.length > 0) return true;
      if (typeof object?.emballageUnitaire === "string" && object?.emballageUnitaire?.length > 0) return true;
      if (typeof object?.nsa === "string" && object?.nsa?.length > 0) return true;
      if (typeof object?.declarationConditionnement === "string" && object?.declarationConditionnement?.length > 0) return true;
      if (typeof object?.declarationContenance === "string" && object?.declarationContenance?.length > 0) return true;
      if (typeof object?.ficheSecurite === "string" && object?.ficheSecurite?.length > 0) return true;
      if (typeof object?.pointEclair === "string" && object?.pointEclair?.length > 0) return true;
      if (typeof object?.soinsMedicaux === "string" && object?.soinsMedicaux?.length > 0) return true;
      if (typeof object?.explosivite === "string" && object?.explosivite?.length > 0) return true;
      if (typeof object?.commentaireDangereux === "string" && object?.commentaireDangereux?.length > 0) return true;
      if (object.flPolluantMarin === true) return true;
      if (object.flQteLimitee === true) return true;
      if (typeof object?.fkTechClasseDanger === "number" && object?.fkTechClasseDanger !== null) return true;
      if (typeof object?.fkTechGrpeSeparation === "number" && object?.fkTechGrpeSeparation !== null) return true;
    }
    return false;
  }

  const activeTab = 'bg-slate-100';
  const inactiveTab = `ml-2 ${activate ? 'bg-slate-300' : 'bg-white-500'}`;

  return (
    <BlockGrid
      title={t('tab_article_dangereux')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      completude={completude}
      loading={loading}
      handleClick={(res: boolean) => setActivate(res)}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
      handleFocus={handleFocus}
    >
      <form className="flex justify-between flex-wrap">
        <div
          className="flex mb-1 flex-col items-end gap-px"
          style={{ width: '200px' }}
        >
          <button type="button" onClick={() => setSelected('IMDG')}
            className={`flex-1 rounded-l p-4 transition-all border border-r-0 border-slate-300
              ${isSelected === 'IMDG' ? activeTab : inactiveTab}`}
            style={{ maxHeight: "88px", width: isSelected === 'IMDG' ? '200px' : '190px' }}
          >
            <label className="block mb-2">
              <span className="text-xs font-medium w-full flex justify-between">
                <span>{t('enu_article_transport_export_type_reglementation_IMDG')}</span>
                <span>{IMDGIcon}</span>
              </span>
              <input
                type="text"
                tabIndex={5}
                value={formIMDG?.codeTransport ?? ""}
                onChange={(e) => setFormIMDG({ ...formIMDG, codeTransport: e.currentTarget.value })}
                disabled={!activate}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!needCodeTransport(formIMDG) && 'border-red-500 bg-red-500/10 disabled:border-red-500'}
                `} />
            </label>
          </button>
          <button type="button" onClick={() => setSelected('IATA')}
            className={`flex-1 rounded-l p-4 transition-all border border-r-0 border-slate-300
            ${isSelected === 'IATA' ? activeTab : inactiveTab}`}
            style={{ maxHeight: "88px", width: isSelected === 'IATA' ? '200px' : '190px' }}
          >
            <label className="block mb-2">
              <span className="text-xs font-medium w-full flex justify-between">
                <span>{t('enu_article_transport_export_type_reglementation_IATA')}</span>
                <span>{IATAIcon}</span>
              </span>
              <input
                type="text"
                tabIndex={5}
                value={formIATA?.codeTransport ?? ""}
                onChange={(e) => setFormIATA({ ...formIATA, codeTransport: e.currentTarget.value })}
                disabled={!activate}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!needCodeTransport(formIATA) && 'border-red-500 bg-red-500/10 disabled:border-red-500'}
                `} />
            </label>
          </button>
          <button type="button" onClick={() => setSelected('ADR')}
            className={`flex-1 rounded-l p-4 transition-all border border-r-0 border-slate-300
            ${isSelected === 'ADR' ? activeTab : inactiveTab}`}
            style={{ maxHeight: "88px", width: isSelected === 'ADR' ? '200px' : '190px' }}
          >
            <label className="block mb-2">
              <span className="text-xs font-medium w-full flex justify-between">
                <span>{t('enu_article_transport_export_type_reglementation_ADR')}</span>
                <span>{ADRIcon}</span>
              </span>
              <input
                type="text"
                tabIndex={5}
                value={formADR?.codeTransport ?? ""}
                onChange={(e) => setFormADR({ ...formADR, codeTransport: e.currentTarget.value })}
                disabled={!activate}
                className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
                  ${!!needCodeTransport(formADR) && 'border-red-500 bg-red-500/10 disabled:border-red-500'}
                `} />
            </label>
          </button>
        </div>

        {isSelected === 'IMDG' && <DangerTab form={formIMDG} setForm={setFormIMDG} activate={activate} title={IMDGIcon} hasFocus={hasFocus} loading={loading} />}
        {isSelected === 'IATA' && <DangerTab form={formIATA} setForm={setFormIATA} activate={activate} title={IATAIcon} hasFocus={hasFocus} loading={loading} />}
        {isSelected === 'ADR' && <DangerTab form={formADR} setForm={setFormADR} activate={activate} title={ADRIcon} hasFocus={hasFocus} loading={loading} />}
        {/* {isSelected === '' && <DangerTab form={} setForm={} activate={activate} title={} />} */}

      </form>
    </BlockGrid>
  );
};

export default Dangereux;
