import { useEffect, useRef } from 'react';

export const useKeydown = (key: string, callback: (event: Event) => void) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const keyboardEvt = event;
      // console.log(keyboardEvt.key); // uncomment to know to key name
      if (keyboardEvt.key === key && !keyboardEvt.repeat) {
        callbackRef.current(event);
      }
    };

    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, [key]);
};

export const useNotKeydown = (key: string, callback: (event: Event) => void) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const keyboardEvt = event;
      // console.log(keyboardEvt.key) // uncomment to know to key name
      if (keyboardEvt.key !== key && !keyboardEvt.repeat) {
        callbackRef.current(event);
      }
    };

    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, [key]);
};