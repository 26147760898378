import { useLayoutEffect, useState } from "react";

export function useResize(elementListen?: HTMLElement) {
  const [size, setSize] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    function updateSize() {
      const newSize = elementListen
        ? { x: elementListen.clientWidth, y: elementListen.clientHeight }
        : { x: globalThis.innerWidth, y: globalThis.innerHeight };
      setSize(old => newSize.x !== old.x || newSize.y !== old.y ? newSize : old);
    }

    updateSize();
    const listen = elementListen || window;
    listen.addEventListener('resize', updateSize);

    return () => listen.removeEventListener('resize', updateSize);
  }, []);

  return size;
}