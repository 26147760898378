import hotToast, { ToastOptions } from 'react-hot-toast';
import i18n from 'i18next';

const saveToast = (_text: string) => {
  // Save text, date-heure, from, open|read = false, etc
};

export const toast = {
  info: (text: string, opts?: ToastOptions, keep: boolean = true) => {
    hotToast(i18n.t(text), opts);
    if (keep) saveToast(text);
  },
  error: (text: string, opts?: ToastOptions, keep: boolean = true) => {
    hotToast.error(i18n.t(text), opts);
    if (keep) saveToast(text);
  },
  success: (text: string, opts?: ToastOptions, keep: boolean = true) => {
    hotToast.success(i18n.t(text), opts);
    if (keep) saveToast(text);
  },
  warning: (text: string, opts?: ToastOptions, keep: boolean = true) => {
    hotToast(i18n.t(text), {...opts, icon: "⚠️"});
    if (keep) saveToast(text);
  },
  custom: (text: string, opts?: ToastOptions, keep: boolean = true) => {
    hotToast.custom(i18n.t(text), opts);
    if (keep) saveToast(text);
  },
  // loading: loading,
  // dismiss(toastId?: string | undefined): void,
  // remove(toastId?: string | undefined): void,
  // promise<T>(promise: Promise<T>, msgs: {
  //     loading: Renderable,
  //     success: ValueOrFunction<Renderable, T>,
  //     error: ValueOrFunction<Renderable, any>,
  // }, opts?: DefaultToastOptions | undefined): Promise<T>,
};