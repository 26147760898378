import { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cs from 'classnames';

import H2Title, { Title } from "components/H2Title";
import { APPROVISIONNEMENT_MENU } from "app/parameters-routes";
import AppMenu from "components/AppMenu";

import approvisionnement from "assets/icons/menu/appro-icon-active.svg";

const Approvisionnement: FunctionComponent = () => {
  const { t } = useTranslation();
  const [parameters, setParameters] = useState(false);

  function onClickParameter(): void {
    setParameters(old => !old);
  }

  const title: Title = {
    title: "Appro, stock, précompt",
  };
  return (
    <>
      <div className="mt-16 flex justify-center">
        <div className="w-full max-w-lg border border-gray-300 bg-white">
          <input
            type="search"
            className="form-control relative block w-full px-3 py-1.5 text-base font-normal
            text-gray-700 bg-white bg-clip-padding rounded border-0 transition ease-in-out
              border-transparent focus:border-transparent focus:ring-0"
            placeholder="Recherche"
          />
        </div>
      </div>

      <AppMenu className="mt-16" sectionTitle="tab_appro" />

      <div className="self-center flex flex-col p-8 w-11/12 rounded-lg bg-white shadow-lg mt-4 mb-8">
        <div className="flex gap-1">
          <img src={approvisionnement} alt="Appro, stock, précompt" className="h-8 w-8 mr-1" />

          <H2Title content={title} />

          <div className="relative ml-1 cursor-pointer" onClick={onClickParameter}>
            <img
              alt="parametre"
              className="h-8 w-8 "
              src="/assets/icons/parametre.png"
            />
            <img
              alt="selected"
              className={cs("h-4 w-4 absolute top-4 left-4", {
                'hidden': !parameters,
              })}
              src="/assets/icons/selected.png"
            />
          </div>
        </div>

        <div className="flex flex-wrap gap-4 mt-6">
          {APPROVISIONNEMENT_MENU.items.map((element) => {
            const link = parameters && element.parametersUrl ? element.parametersUrl : element.url;
            const icon = parameters && element.parametersUrl ? 'parametre' : element.icon;

            const content = (<>
              <div className="p-2 mx-auto bg-purple-100 rounded-full group-hover:rounded-lg transition-[borderRadius] duration-200">
                <img alt={icon} className="h-10 w-10" src={`/assets/icons/${icon}.png`} />
              </div>
              <div className="relative text-xs text-center break-words group-hover:underline">
                {t(`${element.name}`)}
              </div>
            </>);

            return (
              link
                ? <Link to={link} key={element.name} className="w-24 flex flex-col gap-2 group">{content}</Link>
                : <div key={element.name} className="w-24 flex flex-col gap-2 grayscale">{content}</div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Approvisionnement;
