import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { OptionProps } from 'react-select';
import { ArticlePermanentHeadbandViewModel } from 'openapi-typescript-codegen';
import cs from 'classnames';

import { articlesApi } from 'api';
import { useDebounce } from "hooks";
import SelectComplete, { SelectTemplate } from "components/SelectComplete";
import HighlightSearch from "components/HighlightSearch";

type OptionType = SelectTemplate<string> & { article: ArticlePermanentHeadbandViewModel; };

const SearchBarPresentation: FunctionComponent = () => {
  let { code } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [articleOptions, setArticleOptions] = useState<OptionType[]>([]);
  const [searchArticle, setSearchArticle] = useState('');
  const debouceSearchArticle = useDebounce(searchArticle, 300);

  useEffect(() => {
    fetchGetArticles(debouceSearchArticle);
  }, [debouceSearchArticle]);

  const fetchGetArticles = async (search: string) => {
    const { data: { data } } = await articlesApi.v1ArticlesPermanentHeadbandSearchGet(search, 1, 20);
    const options = (data || [])
      .filter((item) => !code || item.code !== code)
      .map((item): OptionType => ({
        article: item,
        value: item.code,
        filterKeys: [
          item.codeExterne,
          item.code,
          item.designationStandard,
          item.designationLong,
          item.designationCaisse,
          item?.marque?.nomMarque,
          item?.typology?.nomTypologie,
          item?.eans?.find(i => i.flEanMaitre === true)?.code,
          ...(item?.relationFournisseurConditionnements?.map(i => i.refFour ? ' - ' + i.refFour : '') || [])
        ]
      }));
    setArticleOptions(options);
  };

  const searchWidth = "calc(100vw - calc(65px + 170px + calc(100vw / 24 * 2)) + 4px)";

  function handleNavigate(code: string) {
    navigate(location.pathname.split('/').slice(0, -2).join('/') + `/${code}`);
  }

  const MyOption = useCallback((props: OptionProps<OptionType>) => {
    const { innerProps, innerRef, isFocused, data: { article } } = props;
    return (
      <div ref={innerRef} {...innerProps}
        className={cs(
          "px-4 py-2 flex z-full top-8 left-0 bg-white-500 mb-px cursor-pointer",
          "focus:bg-store-primary/25 hover:bg-store-primary-light",
          { "bg-store-primary-light": isFocused }
        )}
        onClick={() => { handleNavigate(article.code); }}
        style={{
          width: searchWidth,
          minWidth: searchWidth,
          maxWidth: searchWidth,
          height: '42px'
        }}>
        <div className="h-full w-full">
          <div className="grid grid-cols-24 text-left self-center">
            <div className="col-span-3 px-2 text-ellipsis overflow-hidden whitespace-nowrap">
              <HighlightSearch str={article.codeExterne ?? ''} searchStr={debouceSearchArticle} />
            </div>
            {/* <div className="h-full px-2 overflow-hidden">
              <div className="aspect-square">
                <div
                  className="bg-local h-full w-full max-w-full bg-center bg-no-repeat bg-contain"
                  style={{ backgroundImage: `url(${article?.image ?? defaultImage})` }}
                  ></div>
              </div>
            </div> */}
            <div
              className="col-span-7 px-2 text-ellipsis overflow-hidden whitespace-nowrap"
              title={article?.designationStandard || article?.designationLong || article?.designationCaisse || ''}
            >
              <HighlightSearch str={(article?.designationStandard || article?.designationLong || article?.designationCaisse) ?? '...'} searchStr={debouceSearchArticle} />
            </div>
            <div
              className="col-span-4 px-2 text-ellipsis overflow-hidden whitespace-nowrap"
              title={article?.marque?.nomMarque || ''}
            >
              <HighlightSearch str={article?.marque?.nomMarque ?? '...'} searchStr={debouceSearchArticle} />
            </div>
            <div
              className="col-span-2 px-2 text-ellipsis overflow-hidden whitespace-nowrap"
              title={article?.typology?.nomTypologie || ''}
            >
              ({article?.typology?.code ?? '...'})
            </div>
            <div
              className="col-span-4 px-2 text-ellipsis overflow-hidden whitespace-nowrap"
              title={article?.eans?.find(i => i.flEanMaitre === true)?.code || ''}
            >
              <HighlightSearch str={article.eans?.find(i => i.flEanMaitre === true)?.code ?? '...'} searchStr={debouceSearchArticle} />
            </div>
            <div
              className="col-span-4 px-2 text-ellipsis overflow-hidden whitespace-nowrap"
              title={article?.relationFournisseurConditionnements?.[0]?.refFour || ''}
            >
              <HighlightSearch str={article?.relationFournisseurConditionnements?.[0]?.refFour ?? '...'} searchStr={debouceSearchArticle} />
            </div>
          </div>
        </div>
      </div>
    );
  }, [debouceSearchArticle]);

  return (
    <div className="col-span-3 self-center h-full bg-white-500 flex items-center">
      <SelectComplete
        tabIndex={3}
        closeMenuOnSelect={true}
        options={articleOptions}
        placeholder={code}
        placeholderStyles={{ color: "#000000" }}
        menuStyles={{
          marginLeft: "-8px",
          marginTop: "2px",
          zIndex: 3,
          overflow: 'hidden',
          backgroundColor: 'rgb(225 226 229 / 1)',
        }}
        className={cs(
          "w-full h-full px-2 bg-transparent text-center cursor-text",
          "focus:border-2 focus:border-store-primary",
          "hover:outline-1"
        )}
        indicator={false}
        components={{ Option: MyOption }}
        onInputChange={(newVal) => setSearchArticle(newVal)}
        onChange={(newVal) => newVal && handleNavigate(newVal.article.code)}
        isMulti={false}
      />
    </div>
  );
};

export default SearchBarPresentation;