import { FC, useMemo } from 'react';
import { AgGridReact } from "ag-grid-react";
import { useTranslation } from "react-i18next";
import { ColDef } from "ag-grid-community";
import { PrixVenteViewModel } from "openapi-typescript-codegen";
import { DateTime } from "luxon";

import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { DateCellRenderer } from "components/AGGride/CellRender";
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { useArticle } from "context/Referencement";
import { useGridController } from "hooks/useGridController";
import { prixVentesApi } from "api/";

import 'app/ag-grid-style.css';

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 90,
};

type GridType = { isCurrentPrice?: boolean; } & PrixVenteViewModel;

const PrixVentePermanent: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {
        floatingAction: 'none',
      },
      autoFetch: true,
      fetchData: async () => {
        if (!article?.id) return [];
        const { data: { data } } = await prixVentesApi.v1PrixVentesSearchPost(false, 1, 500, { fkArticle: article.id });
        const sortedData: GridType[] = (data || [])
          .sort((a, b) => (b.datePxVente || '').localeCompare(a.datePxVente || ''));

        // TODO: Add route for current article prix achat
        let currentPrice: { idx: number, date: DateTime; } | null = null;
        for (const [idx, val] of sortedData.entries()) {
          const date = DateTime.fromISO(val.datePxVente!);
          if (
            date.toSeconds() <= DateTime.now().toSeconds() &&
            (!currentPrice || currentPrice.date.toSeconds() < date.toSeconds())
          ) {
            currentPrice = { idx, date };
          }
        };
        if (currentPrice && sortedData[currentPrice.idx])
          sortedData[currentPrice.idx].isCurrentPrice = true;

        return sortedData;
      },
      rowClassRules: {
        'ag-row-success': (params) => params.data?.isCurrentPrice === true,
      },
      columnDefs: [
        {
          field: "datePxVente",
          headerName: t('lib_prix_vente_date_prix_vente'),
          headerTooltip: t('lib_prix_vente_date_prix_vente'),
          cellRenderer: DateCellRenderer,
        },
        {
          field: "pxVente",
          headerName: t('lib_prix_vente_px_vente'),
          headerTooltip: t('lib_prix_vente_px_vente'),
        },
      ],
    }), [article, t])
  );

  return (
    <BlockGrid
      title={t('tab_prix_vente_permanent')} sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      toActivate={true}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          onGridReady={gridController.onGridReady}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default PrixVentePermanent;