import { FunctionComponent } from "react";
// import RelationFournisseurConditionnement from "../RelationFournisseurConditionnement";

interface Props {
}

const TabApprovisionnement: FunctionComponent<Props> = () => {
  return (
    <div id="approvisionnement" className="flex flex-wrap justify-between bg-grey-150 gap-px p-px">
      {/* <RelationFournisseurConditionnement
        sm={100}
        md={100}
        lg={100}
        xl={100}
        doubleXl={100}
      /> */}
      <div className="text-2xl font-bold">Approvisionnement</div>
    </div>
  );
};

export default TabApprovisionnement;
