import { useState, useEffect } from 'react';

import { CircuitLogistiqueViewModel } from "openapi-typescript-codegen";
import { fournisseursApi } from 'api';

import { useFournisseur } from "context/Referencement";

import DivisionGrid from 'components/BlockGrid/DivisionGrid';
import LieuEmbarquement from 'components/Fournisseur/TabConditions/LieuEmbarquement';
import ConditionAchat from "components/Fournisseur/TabConditions/ConditionAchat";
import LateralNavigation from 'components/Fournisseur/LateralNavigation';

import { showPrettyError } from "utils";

const TabCondition = () => {
  const [circuits, setCircuits] = useState<CircuitLogistiqueViewModel[]>([]);
  const [selectedCircuit, setSelectedCircuit] = useState<CircuitLogistiqueViewModel>();
  const [circuitId, setCircuitId] = useState<number>();
  const { state: fournisseur } = useFournisseur();

  const getCircuits = async (id: number) => {
    let { data } = await fournisseursApi.v1FournisseursIdCircuitLogistiquesGet(id);
    // Sort data by code:
    data = data.sort((circuit$1, circuit$2) => circuit$1.code?.localeCompare(circuit$2.code || '') || 0);
    setCircuits(data);
  };

  useEffect(() => {
    if (!fournisseur?.id) return;
    getCircuits(fournisseur.id).catch(error => {
      console.error(error);
      showPrettyError(error);
    });
  }, [fournisseur?.id]);

  useEffect(() => {
    setCircuitId(circuits[0]?.id);
  }, [circuits]);

  useEffect(() => {
    setSelectedCircuit(circuits.find(circuit => circuit.id === circuitId));
  }, [circuitId]);

  const handleSelectCircuit = (id: number) => {
    setCircuitId(id);
  };

  return (
    <div id="conditions" className="h-full flex flex-wrap justify-between bg-grey-150 gap-px p-px">
      <LateralNavigation
        dataSet={circuits}
        selectedItem={selectedCircuit}
        onSelectItem={handleSelectCircuit}
        width={'280px'}
        display={{
          code: 'code',
          name: 'nomCircuit',
          type: 'nomCircuit'
        }}
      />
      <DivisionGrid width={'calc(100% - 280px)'}>
        {circuitId !== undefined && <>
          <ConditionAchat circuitLogiId={circuitId} />
          <LieuEmbarquement identifier={circuitId} />
        </>}
      </DivisionGrid>
    </div>
  );
};

export default TabCondition;