import { FC, useEffect, useMemo, useState } from 'react';
import {
  EnumNomenclatureDouaniereNiveau,
  NomenclatureDouaniereInsertionModel,
  NomenclatureDouaniereViewModel,
  PayViewModel
} from "openapi-typescript-codegen";
import { useTranslation } from "react-i18next";

import { nomenclatureDouanieresApi, paysApi } from 'api';
import { api } from "utils";
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { GridControllerProps, useGridController } from "hooks/useGridControllerV2";
import { CustomColDef } from "components/AGGride/gridTypes";
import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import { Title } from "components/H2Title";
import BlockGrid, { PropsBlock, LayoutEnum } from 'components/BlockGrid';
import {
  AutoCompleteCellEditor,
  AutoCompleteCellEditorProps,
  SelectorCellEditor,
  TextCellEditor
} from 'components/AGGride/CellEditor';
import { TextCellRenderer } from 'components/AGGride/CellRender';
import { notEmptyCellValidator, notNilCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import { SelectTemplate } from "components/SelectComplete";
import { CheckBoxColumnDef } from "components/AGGride/columnDef";
import ClientSideGrid from "components/AGGride/ClientSideGrid";

const defaultColDef: CustomColDef = {
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

type GridType = Partial<NomenclatureDouaniereInsertionModel | NomenclatureDouaniereViewModel>;

const NomenclatureDouaniere: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);
  const [pays, setPays] = useState<{ data: PayViewModel[], options: SelectTemplate[]; }>({ data: [], options: [] });

  const niveaux: SelectTemplate[] = [
    { value: null, label: "..." },
    { value: EnumNomenclatureDouaniereNiveau.International, label: t("enu_lib_nomenclature_douaniere_niveau0") },
    { value: EnumNomenclatureDouaniereNiveau.Combinee, label: t("enu_lib_nomenclature_douaniere_niveau1") },
    { value: EnumNomenclatureDouaniereNiveau.CodeTarif, label: t("enu_lib_nomenclature_douaniere_niveau2") },
    { value: EnumNomenclatureDouaniereNiveau.CodeTarifComplement, label: t("enu_lib_nomenclature_douaniere_niveau3") }
  ];

  const gridControllerOptions = useMemo((): GridControllerProps<GridType> => ({
    floatingAction: activate ? 'hover' : 'none',
    emptyRowCheck: (rowData) => !rowData.niveau,
    fetchData: async () => {
      const { data: { data } } = await nomenclatureDouanieresApi.v1NomenclatureDouanieresGet(1, 1000);
      return data.sort((a, b) => a.codeNomenclatureSh.localeCompare(b.codeNomenclatureSh));
    },
    postData: (cleanRow) => nomenclatureDouanieresApi.v1NomenclatureDouanieresPost(cleanRow as NomenclatureDouaniereInsertionModel),
    putData: (cleanRow) => nomenclatureDouanieresApi.v1NomenclatureDouanieresPut(cleanRow as NomenclatureDouaniereViewModel),
    deleteData: (dataId) => nomenclatureDouanieresApi.v1NomenclatureDouanieresIdDelete(dataId),
    onEditingStopped: () => setActivate(false),
  }), [activate]);
  const gridController = useGridController(gridControllerOptions);

  const columnDefs = useMemo((): CustomColDef<GridType>[] => [
    {
      field: 'niveau',
      headerName: t('lib_nomenclature_douaniere_niveau'),
      headerTooltip: t('lib_nomenclature_douaniere_niveau'),
      headerClass: [...AG_GRID_DEFAULT_COLUMN_NEW.headerClass, 'required'],
      cellRenderer: TextCellRenderer,
      cellEditor: SelectorCellEditor,
      cellEditorParams: { values: niveaux },
      valueValidator: [notNilCellValidator, numberCellValidator],
      editable: params => !!params.data?._customDataProps?.isNew,
      tooltipValueGetter: (params) => niveaux.find((v) => v.value === params.value)?.value,
      valueFormatter: (params) => niveaux.find((v) => v.value === params.value)?.label || String(),
      filter: 'agTextColumnFilter',
      flex: 3,
    },
    {
      field: 'codeNomenclatureSh',
      headerName: t('lib_code_nomenclature_sh'),
      headerTooltip: t('lib_code_nomenclature_sh'),
      headerClass: [...AG_GRID_DEFAULT_COLUMN_NEW.headerClass, 'required'],
      cellRenderer: TextCellRenderer,
      cellEditor: TextCellEditor,
      editable: true,
      cellEditorParams: { maxLength: 6, toUpperCase: true },
      valueValidator: [notNilCellValidator, notEmptyCellValidator],
      filter: 'agTextColumnFilter',
      flex: 1
    },
    {
      field: 'fkPays',
      headerName: t('lib_nomenclature_douaniere_fk_pays'),
      headerTooltip: t('lib_nomenclature_douaniere_fk_pays'),
      editable: true,
      valueFormatter: params => pays.options.find(option => option.value === params.data?.fkPays)?.label || String(),
      cellRenderer: TextCellRenderer,
      cellEditor: AutoCompleteCellEditor,
      cellEditorPopup: true,
      cellEditorParams: {
        searchData: (async (search) => {
          const { data: response } = await paysApi.v1PaysSearchPost(
            true, 1, 10000, { nomPays: search, codePaysAlpha2: search, codePaysAlpha3: search, nomIso: search }
          );
          const data = response.data || [];
          return data.map(val => ({
            value: val.id,
            label: `${val.codePaysAlpha2} - ${val.nomPays} `,
            filterKeyArr: [val.nomPays, val.codePaysAlpha2, val.codePaysAlpha3, val.nomIso]
          }));
        }),
      } as AutoCompleteCellEditorProps,
      filter: 'agTextColumnFilter',
      flex: 2
    },
    {
      field: 'codeNomenclatureCombinee',
      headerName: t('lib_nomenclature_douaniere_code_nomenclature_combinee'),
      headerTooltip: t('lib_nomenclature_douaniere_code_nomenclature_combinee'),
      cellRenderer: TextCellRenderer,
      cellEditor: TextCellEditor,
      cellEditorParams: (params: any) =>  {
        console.info(params);
        return { maxLength: 2, toUpperCase: true, editable: true }
      },
      editable: true,
      filter: 'agTextColumnFilter',
      valueValidator: [
        (value, rowData) => {
          const niveau = rowData.niveau;
          if (niveau === EnumNomenclatureDouaniereNiveau.Combinee
            || niveau === EnumNomenclatureDouaniereNiveau.CodeTarif
            || niveau === EnumNomenclatureDouaniereNiveau.CodeTarifComplement)
            return notEmptyCellValidator(value, rowData);
          return { success: true };
        }
      ],
      flex: 1
    },
    {
      field: 'codeTarifIntegre',
      headerName: t('lib_nomenclature_douaniere_code_tarif_integre'),
      headerTooltip: t('lib_nomenclature_douaniere_code_tarif_integre'),
      cellRenderer: TextCellRenderer,
      cellEditor: TextCellEditor,
      cellEditorParams: { maxLength: 2, toUpperCase: true },
      editable: true,
      filter: 'agTextColumnFilter',
      valueValidator: [
        (value, rowData) => {
          const niveau = rowData.niveau;
          if (niveau === EnumNomenclatureDouaniereNiveau.CodeTarif
            || niveau === EnumNomenclatureDouaniereNiveau.CodeTarifComplement)
            return notEmptyCellValidator(value, rowData);
          return { success: true };
        }
      ],
      flex: 1
    },
    {
      field: 'codeComplement',
      headerName: t('lib_nomenclature_douaniere_code_complement'),
      headerTooltip: t('lib_nomenclature_douaniere_code_complement'),
      cellRenderer: TextCellRenderer,
      cellEditor: TextCellEditor,
      cellEditorParams: { maxLength: 3, toUpperCase: true },
      editable: true,
      filter: 'agTextColumnFilter',
      valueValidator: [
        (value, rowData) => {
          const niveau = rowData.niveau;
          if (niveau === EnumNomenclatureDouaniereNiveau.CodeTarifComplement)
            return notEmptyCellValidator(value, rowData);
          return { success: true };
        }
      ],
      flex: 1
    },
    {
      field: 'nomNomenclature',
      headerName: t('lib_nomenclature_douaniere_nom_nomenclature'),
      headerTooltip: t('lib_nomenclature_douaniere_nom_nomenclature'),
      cellRenderer: TextCellRenderer,
      editable: true,
      headerClass: [...AG_GRID_DEFAULT_COLUMN_NEW.headerClass, 'required'],
      valueValidator: [notEmptyCellValidator],
      filter: 'agTextColumnFilter',
      flex: 2
    },
    CheckBoxColumnDef<GridType>("flCertificatObligatoire", "lib_nomenclature_douaniere_fl_certificat_obligatoire"),
  ], [pays, t]);

  useEffect(() => {
    getPays().then(data => {
      const options = api.options.generate(data, { label: "#{codePaysAlpha2} - #{nomPays}" }, false);
      setPays({ data, options });
    });
  }, []);

  async function getPays() {
    return await api.dataset.get<PayViewModel>(paysApi.v1PaysGet(1, 300));
  }

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: `${t('men_refe_articles')} - ${t('men_parametre')}`,
      link: `/app/referencement/articles/parametres`,
    },
    {
      name: "tit_nomenclature_douaniere",
    },
  ];

  const title: Title = {
    title: `${t('men_refe_articles')} - ${t('tab_nomenclature_douaniere')}`,
    icon: "articles",
  };

  return (
    <>
      <BreadCrumb content={breadcrumb} />

      <div className="my-5">
        <H1Title content={title} />
      </div>

      <BlockGrid
        sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
        layout={LayoutEnum.settings}
        handleClick={setActivate}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <ClientSideGrid
          className="block-grid-params"
          gridController={gridController}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          editType="fullRow"
        />
      </BlockGrid>
    </>
  );
};

export default NomenclatureDouaniere;