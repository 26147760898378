import { FC, useState, useMemo, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useGridController } from "hooks";
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { useTranslation } from "react-i18next";
import { interfaceFournisseursApi, lieuFonctionApi, interfaceFluxApi } from 'api';
import {
  InterfaceFournisseurViewModel,
  InterfaceFluxViewModel,
  InterfaceFournisseurInsertionModel
} from "openapi-typescript-codegen";
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { ColDef } from 'ag-grid-community';
import { TextCellRenderer } from "components/AGGride/CellRender";
import { SelectorCellEditor } from "components/AGGride/CellEditor";
import {SelectTemplate} from "components/SelectComplete";
import {showPrettyError} from "utils";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 90,
};

type GridType = Partial<InterfaceFournisseurViewModel | InterfaceFournisseurInsertionModel>;

interface Props extends PropsBlock {
  selected: number;
}

const Interface: FC<Props> = ({ sm, md, lg, xl, doubleXl, selected }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);
  const [interfaceFournisseurs, setInterfaceFournisseurs] = useState<Partial<InterfaceFournisseurViewModel>[]>([]);
  const [interfaceFlux, setInterfaceFlux] = useState< { data: InterfaceFluxViewModel[], options: SelectTemplate[] }>({ data: [], options: [] });

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      getNewModel: () => {
        return {
          fkLieuFonction: selected,
        };
      },
      emptyRowCheck: (rowData) => !rowData.id,
      fetchData: async () => {
        const { data } = await lieuFonctionApi.v1LieuFonctionsIdInterfaceFournisseursGet(selected);
        setInterfaceFournisseurs(data || []);
        return data || [];
      },
      postData: (cleanRow) => interfaceFournisseursApi.v1InterfaceFournisseursPost(cleanRow as InterfaceFournisseurInsertionModel),
      putData: (cleanRow) => interfaceFournisseursApi.v1InterfaceFournisseursPut(cleanRow as InterfaceFournisseurViewModel),
      deleteData: (dataId) => interfaceFournisseursApi.v1InterfaceFournisseursIdDelete(dataId),
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'fkInterface',
          headerName: t('lib_interface_nom_interface'),
          headerTooltip: t('lib_interface_nom_interface'),
          editable: true,
          valueFormatter: (params) => interfaceFlux.options.find(option => option.value === params.data?.fkInterfaceFlux)?.label || String(),
          cellRenderer: TextCellRenderer,
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: interfaceFlux.options.filter(option => !option.isDisabled)
          },
          onCellValueChanged: (params) => {
            // The interfaces are updated in order to update the available option (with the useEffect):
            params.data.fkInterfaceFlux = params.newValue;
            setInterfaceFournisseurs([interfaceFournisseurs.filter(interfaceFournisseur => interfaceFournisseur.id !== params.data.id), params.data as Partial<InterfaceFournisseurViewModel>].flat());
            params.api.refreshCells({ rowNodes: [params.node!], force: true });
          },
          onCellClicked: (params) => {
            // The current option and the available options must be displayed:
            const current: SelectTemplate = interfaceFlux.options.find(option => option.value === params.data?.fkInterfaceFlux)!;
            if (current && current.value) {
              params.colDef.cellEditorParams = {
                values: [current, ...interfaceFlux.options.filter(option => !option.isDisabled)
                  .sort((option$1, option$2) =>
                    String(option$1.label).localeCompare(String(option$2.label)))]
              };
            }
          },
          filter: 'agTextColumnFilter',
        },
        {
          field: 'codeInterface',
          headerName: t('lib_interface_fournisseur_code_interface'),
          headerTooltip: t('lib_interface_fournisseur_code_interface'),
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter'
        }
      ]
    }), [t, interfaceFournisseurs, interfaceFlux]),
  );

  useEffect(() => {
    const options = interfaceFlux.options.map(option => {
      option.isDisabled = !!option.value && interfaceFournisseurs.some(interfaceFournisseur => interfaceFournisseur.fkInterfaceFlux === option.value);
      return option;
    });
    setInterfaceFlux({ ...interfaceFlux, options });
  }, [interfaceFournisseurs]);

  useEffect(() => {
    getInterfaceFlux().then((data: InterfaceFluxViewModel[]) => {
      let options: SelectTemplate[] = data ? data.map(interfaceFlux => ({
        value: interfaceFlux.id,
        label: interfaceFlux.nomInterfaceFlux || String()
      })) : [];
      options.unshift({ value:null, label: "..." });
      options = options.sort((option$1, option$2) =>
        String(option$1.label).localeCompare(String(option$2.label)));
      setInterfaceFlux({ data, options });
    })
  }, []);

  async function getInterfaceFlux() {
    try {
      const { data: { data } } = await interfaceFluxApi.v1InterfaceFluxGet(1, 1000);
      return data || [];
    } catch (error) {
      console.error(error);
      showPrettyError(error);
      return [];
    }
  }

  return (
    <BlockGrid
      title={t('tab_interface_fournisseur')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      handleClick={(res: boolean) => {
        setActivate(res);
        setTimeout(gridController.activateContextActionButtons, 50);
      }}
      toActivate={activate}
      loading={gridController.isLoading}
      disableCreate={gridController.hasEmptyLines}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default Interface;