import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  CircuitLogistiqueViewModel,
  ConditionnementViewModel,
  ModeApprovisionnementViewModel,
  RelationFournisseurConditionnementInsertionModel,
  RelationFournisseurConditionnementViewModel
} from "openapi-typescript-codegen";
import { DateTime, Interval } from "luxon";

import { useArticle } from "context/Referencement";
import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import { articlesApi, circuitLogistiquesApi, conditionnementsApi, fluxFournisseursApi, modeApprovisionnementsApi, prixAchatsApi, relationFournisseurConditionnementApi, viewFournisseursApi } from "api/";
import { showPrettyError, toast, getISOStartDate, getISOEndDate } from "utils/";
import { DateInput, NumberInput, SelectInput, TextInput } from "components/InputWrapper";

interface FournisseurEditProps {
  setIsOpen: (v: boolean) => void;
  onUpdate: () => void | Promise<void>;
  relationFournisseurConditionnementId?: number;
}

const FournisseurEdit: FC<FournisseurEditProps> = ({ setIsOpen, onUpdate, relationFournisseurConditionnementId: relationFourId }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const [form, setForm] = useState<Partial<RelationFournisseurConditionnementInsertionModel>>({
    dateDebut: getISOStartDate(),
    dateFin: getISOEndDate(),
    priorite: 1,
    fkArticle: article?.id || -1,
    fkCircuitLogistique: -1
  });
  const [relaFkFour, setRelaFkFour] = useState<number | null>(null);
  const [relaCircuitLogis, setRelaCircuitLogis] = useState<CircuitLogistiqueViewModel | null>(null);
  const [relaStatus, setRelaStatus] = useState(false);
  const [relaCondSite, setRelaCondSite] = useState<ConditionnementViewModel | null>(null);
  const [relaModeAppro, setRelaModeAppro] = useState<ModeApprovisionnementViewModel | null>(null);
  const [pxAchat, setPxAchat] = useState(0);
  const [relaWithSameArticleNb, setRelaWithSameArticleNb] = useState<number | null>(null);
  const [isRelaFutur, setIsRelaFutur] = useState(false);
  const isNew = !relationFourId;

  useEffect(() => {
    handleFetch();
  }, [relationFourId]);

  useEffect(() => {
    handleFetch();
  }, [article?.id]);

  useEffect(() => {
    setForm(old => ({ ...old, nomRelationFournisseurConditionnement: old.nomRelationFournisseurConditionnement || relaCircuitLogis?.nomCircuit }));
  }, [relaCircuitLogis]);

  /* Auto fetch on change */

  useEffect(() => {
    (async () => {
      if (!form.fkCircuitLogistique) return;
      const { data } = await circuitLogistiquesApi.v1CircuitLogistiquesIdGet(form.fkCircuitLogistique);
      setRelaCircuitLogis(data);
      setRelaFkFour(data.fkFournisseur ?? null);
    })();
  }, [form.fkCircuitLogistique]);

  useEffect(() => {
    (async () => {
      if (!form.fkCircuitLogistique) return;
      const { data: { data } } = await fluxFournisseursApi.v1FluxFournisseursSearchPost(false, 1, 1, { fkCircuitLogistique: form.fkCircuitLogistique, flFluxDefaut: true });
      const defaultModeApproId = data?.[0].fkModeApprovisionnement ?? null;
      if (!defaultModeApproId) return;
      const { data: modeAppro } = await modeApprovisionnementsApi.v1ModeApprovisionnementsIdGet(defaultModeApproId);
      setRelaModeAppro(modeAppro);
    })();
  }, [form.fkCircuitLogistique]);

  useEffect(() => {
    const inter = Interval.fromDateTimes(DateTime.fromISO(form.dateDebut || ''), DateTime.fromISO(form.dateFin || ''));
    const status = inter.contains(DateTime.now());
    setRelaStatus(status);

    const isFuture = DateTime.fromISO(form.dateDebut || '').startOf('day') >= DateTime.now().startOf('day');
    setIsRelaFutur(isFuture || !form.dateDebut);
  }, [form.dateDebut, form.dateFin]);

  useEffect(() => {
    (async () => {
      if (!form.fkConditionnementSite) return;
      const { data } = await conditionnementsApi.v1ConditionnementsIdGet(form.fkConditionnementSite);
      setRelaCondSite(data);
    })();
  }, [form.fkCircuitLogistique]);

  /* Search callback for autocomplete */

  const fkFournisseurSearchData = useCallback(async (search: string) => {
    const { data: { data } } = await viewFournisseursApi.v1ViewFournisseursSearchPost(
      true, 1, 20, { raisonSociale: search, code: search }
    );
    return (data || []).map(val => ({
      value: val.id,
      label: `${val.raisonSociale} (${val.code})`,
      filterKeyArr: [val.raisonSociale, val.code]
    }));
  }, []);

  const fkCircuitLogistiqueSearchData = useCallback(async (search: string) => {
    const { data: res } = await circuitLogistiquesApi.v1CircuitLogistiquesSearchPost(
      true, 1, 20, { nomCircuit: search, code: search, fkFournisseur: relaFkFour ?? undefined }
    );
    const data = res.data || [];
    return data.map(val => ({
      value: val.id,
      label: `${val.nomCircuit} (${val.code})`,
      filterKeyArr: [val.nomCircuit, val.code]
    }));
  }, [relaFkFour]);

  const fkConditionnementSearchData = useCallback(async (_search: string) => {
    const select = [{ value: null, label: '...' }];
    if (!article?.id)
      return select;

    const { data: res } = await articlesApi.v1ArticlesIdConditionnementsGet(article?.id);
    const data = res.map(val => ({
      value: val.id,
      label: `${val.nomConditionnement} (${val.code})`,
      filterKeyArr: [val.nomConditionnement, val.code]
    }));
    return [...select, ...data];
  }, [article?.id]);

  /* Handle callback */

  async function handleFetch() {
    const promises = [
      relationFournisseurConditionnementApi.v1RelationFournisseurConditionnementsSearchPost(false, 1, 0, { fkArticle: article?.id })
        .then(({ data }) => setRelaWithSameArticleNb(data.total ?? null))
    ];
    if (relationFourId) {
      promises.push(
        relationFournisseurConditionnementApi.v1RelationFournisseurConditionnementsIdGet(relationFourId)
          .then(({ data: relation }) => setForm(relation))
      );
      promises.push(
        relationFournisseurConditionnementApi.v1RelationFournisseurConditionnementsIdPrixAchatGet(relationFourId)
          .then(({ data: prix }) => setPxAchat(prix.pxAchat))
      );
    }
    await Promise.allSettled(promises);
  }

  async function handleUpdate() {
    let success = false;
    try {
      if (isNew) {
        const { data } = await relationFournisseurConditionnementApi.v1RelationFournisseurConditionnementsPost(form as RelationFournisseurConditionnementInsertionModel);
        if (pxAchat) {
          await prixAchatsApi.v1PrixAchatsPost({
            datePxAchat: data.dateDebut ?? undefined,
            fkDevise: undefined,
            fkRelationFournisseurConditionnement: data.id,
            pxAchat: pxAchat
          });
        }
        toast.success(t('mes_validation_creation'));
      } else {
        await relationFournisseurConditionnementApi.v1RelationFournisseurConditionnementsPut(form as RelationFournisseurConditionnementViewModel);
        toast.success(t('mes_validation_modification'));
      }
      success = true;
    } catch (err) {
      console.error(err);
      showPrettyError(err);
    }
    if (success) {
      handleCancel();
      setTimeout(() => onUpdate(), 50);
    }
  }

  function handleCancel() {
    setIsOpen(false);
  }

  return (
    <BlockGrid
      title={t('tit_modale_approvisionnement')}
      layout={LayoutEnum.modal}
      toActivate={true}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
    >
      <form onSubmit={ev => ev.preventDefault()} className="flex flex-row gap-4 mt-3">
        <div className="flex flex-col flex-1" style={{ flexGrow: 0.9 }}>
          <div className="text-grey-500 font-bold mb-2">{t('lib_modale_relation_fournisseur_conditionnement_fournisseur')}</div>
          <div className="grid grid-cols-4 gap-4">
            <SelectInput className="col-span-2" label={t("lib_relation_fournisseur_conditionnement_fk_fournisseur")}
              value={relaFkFour} setValue={setRelaFkFour}
              disabled={!isNew}
              onChange={() => setForm(old => ({ ...old, fkCircuitLogistique: -1 }))}
              getPlaceholder={async (id) => {
                const { data } = await viewFournisseursApi.v1ViewFournisseursIdGet(id);
                return `${data.raisonSociale} (${data.code})`;
              }}
              searchData={fkFournisseurSearchData} required
            />
            <SelectInput className="col-span-2" label={t("lib_relation_fournisseur_conditionnement_fk_circuit_logistique")}
              value={form} field="fkCircuitLogistique" setValue={setForm}
              disabled={typeof relaFkFour !== 'number' || !isNew}
              getPlaceholder={async (id) => {
                const { data } = await circuitLogistiquesApi.v1CircuitLogistiquesIdGet(id);
                return `${data.nomCircuit} (${data.code})`;
              }}
              searchData={fkCircuitLogistiqueSearchData} required
            />

            <TextInput labelClassAdd="col-span-4" label={t("lib_relation_fournisseur_conditionnement_nom_relation_fournisseur")}
              value={form} field="nomRelationFournisseurConditionnement" setValue={setForm} required />

            <DateInput labelClassAdd="col-span-2" label={t("lib_relation_fournisseur_conditionnement_date_debut")}
              disabled={!isNew && !isRelaFutur}
              value={form} field="dateDebut" setValue={setForm} required />
            <DateInput labelClassAdd="col-span-2" label={t("lib_relation_fournisseur_conditionnement_date_fin")}
              value={form} field="dateFin" setValue={setForm} required />

            <TextInput labelClassAdd="col-span-2" label={t("lib_flux_circuit")}
              value={relaModeAppro ? `${relaModeAppro.nomModeApprovisionnement} (${relaModeAppro.code})` : ''}
              disabled />
            <NumberInput label={t("lib_relation_fournisseur_conditionnement_priorite")}
              disabled={relaWithSameArticleNb === 0}
              value={form} field="priorite" setValue={setForm}
              min={1} required
            />
            <TextInput label={t("lib_relation_fournisseur_statut")}
              value={relaStatus ? 'Actif' : 'Non Actif'} disabled />
          </div>
        </div>

        <div className="w-0.5 bg-gray-300"></div>

        <div className="flex flex-col flex-1">
          <div className="text-grey-500 font-bold mb-2">{t('lib_modale_relation_fournisseur_conditionnement_conditionnement')}</div>
          <div className="grid grid-cols-3 gap-4">
            <SelectInput label={t("lib_relation_fournisseur_conditionnement_fk_conditionnement_site")}
              value={form} field="fkConditionnementSite" setValue={setForm}
              disabled={!isNew}
              onChange={() => setForm(old => ({ ...old, fkConditionnementFournisseur: old.fkConditionnementFournisseur ?? old.fkConditionnementSite }))}
              getPlaceholder={async (id) => {
                const { data } = await conditionnementsApi.v1ConditionnementsIdGet(id);
                return `${data.nomConditionnement} (${data.code})`;
              }}
              searchData={fkConditionnementSearchData}
            />
            <SelectInput label={t("lib_relation_fournisseur_conditionnement_fk_conditionnement_fournisseur")}
              value={form} field="fkConditionnementFournisseur" setValue={setForm}
              getPlaceholder={async (id) => {
                const { data } = await conditionnementsApi.v1ConditionnementsIdGet(id);
                return `${data.nomConditionnement} (${data.code})`;
              }}
              searchData={fkConditionnementSearchData}
            />
            <TextInput label={t("lib_conditionnement_pcb")}
              value={relaCondSite?.pcb} disabled />

            <TextInput label={t("lib_relation_fournisseur_conditionnement_unite_commande")}
              value={form} field="uniteCommande" setValue={setForm} />
            <TextInput label={t("lib_relation_fournisseur_conditionnement_unite_facturation")}
              value={form} field="uniteFacturation" setValue={setForm} />
            <NumberInput label={t("lib_relation_fournisseur_conditionnement_coef_unite_vente")}
              value={form} field="coefUniteVente" setValue={setForm} />

            <TextInput label={t("lib_relation_fournisseur_conditionnement_ref_four")}
              value={form} field="refFour" setValue={setForm} />
            <NumberInput label={t("lib_prix_achat_px_achat")}
              value={pxAchat} setValue={setPxAchat}
              min={0} disabled={!isNew}
            />
            <div></div>

            <NumberInput label={t("lib_relation_fournisseur_conditionnement_qte_mini_commandable")}
              onChange={() => setForm(old => ({ ...old, qteMaxiCommandable: Math.max(old.qteMiniCommandable ?? 0, old.qteMaxiCommandable ?? 0) }))}
              value={form} field="qteMiniCommandable" setValue={setForm} min={0} />
            <NumberInput label={t("lib_relation_fournisseur_conditionnement_qte_maxi_commandable")}
              value={form} field="qteMaxiCommandable" setValue={setForm} min={form.qteMiniCommandable as number} />
          </div>
        </div>

        <div className="w-0.5 bg-gray-300"></div>

        <div className="flex flex-col flex-1">
          <div className="text-grey-500 font-bold mb-2">{t('lib_modale_relation_fournisseur_conditionnement_complements')}</div>
          <div className="grid grid-cols-2 gap-4">
            <SelectInput label={t("lib_relation_fournisseur_conditionnement_fk_fournisseur_industriel")}
              value={form} field="fkFournisseurIndustriel" setValue={setForm}
              getPlaceholder={async (id) => {
                const { data } = await viewFournisseursApi.v1ViewFournisseursIdGet(id);
                return `${data.raisonSociale} (${data.code})`;
              }}
              searchData={fkFournisseurSearchData}
            />
            <TextInput label={t("lib_relation_fournisseur_conditionnement_code_rfa")}
              value={form} field="codeRfa" setValue={setForm}
              disabled={!isNew}
            />

            <NumberInput label={t("lib_relation_fournisseur_conditionnement_dlc_annoncee")}
              value={form} field="dlcAnnoncee" setValue={setForm} min={0} />
            <NumberInput label={t("lib_relation_fournisseur_conditionnement_dlc_constatee")}
              value={form} field="dlcConstatee" setValue={setForm} min={0} />

            <NumberInput label={t("lib_relation_fournisseur_conditionnement_nb_article_bareme")}
              value={form} field="nbArticleBareme" setValue={setForm} min={0} />
            <NumberInput label={t("lib_relation_fournisseur_conditionnement_nb_point_bareme")}
              value={form} field="nbPointBareme" setValue={setForm} min={0} />
          </div>
        </div>

      </form>
    </BlockGrid>
  );
};

export default FournisseurEdit;