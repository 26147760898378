import { FC, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { FluxFournisseurWithModeApprovisionnementViewModel, MarqueViewModel, TypeArticleViewModel } from "openapi-typescript-codegen";
import { ColDef } from "ag-grid-community";
import { AxiosResponse } from "axios";

import BlockGrid, { CancelType, LayoutEnum } from 'components/BlockGrid';
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { GridType as DataProps } from "./index";
import { AgGridReact } from "ag-grid-react";
import { useGridController } from "hooks";
import { ModalCellRendererChildrenProps } from "components/AGGride/CellRender";
import { AutoCompleteCellEditor, AutoCompleteCellEditorProps, AutoCompleteOption } from "components/AGGride/CellEditor";
import { notNilCellValidator } from "components/AGGride/cellValidator";
import { editableIfNew } from "components/AGGride/cellEditable";
import { conditionAchatsApi, fluxConditionAchatApi, fluxFournisseursApi, marqueConditionAchatsApi, marquesApi, modeApprovisionnementsApi, typeArticleConditionAchatsApi, typeArticlesApi } from "api";
import { GridController } from "hooks/useGridController";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
};

type GridType = {
  model?: MarqueViewModel | TypeArticleViewModel | FluxFournisseurWithModeApprovisionnementViewModel;
  id?: number;
};

type ConfigTemplate<T> = {
  field: string,
  headerName: string,
  headerTooltip: string,
  valueFormatter: (model?: T) => string,
  get: (dataId: number) => Promise<AxiosResponse<T>>,
  fetchData: () => Promise<GridType[]>,
  postData: (row: GridType) => Promise<unknown>,
  deleteData: (dataId: number) => Promise<unknown>,
  search: (search: string) => Promise<AutoCompleteOption[]>,
};

const DetailFiltre: FC<ModalCellRendererChildrenProps<DataProps>> = ({ setIsOpen, agGridRow }) => {
  const { t } = useTranslation();
  const parentGridController: GridController | undefined = agGridRow.context.gridController;
  const circuitLogiId: number = agGridRow.context.circuitLogiId;
  const conditionAchatId = agGridRow.data?.id!;

  const config = useMemo(() => {
    const field = agGridRow.colDef?.field as keyof DataProps;

    if (field === 'marques') {

      const res: ConfigTemplate<MarqueViewModel> = {
        field: "fksMarques",
        headerName: t('lib_marque_condition_achat_fk_marque'),
        headerTooltip: t('lib_marque_condition_achat_fk_marque'),
        valueFormatter: (model) => `${model?.nomMarque} (${model?.code})`,
        get: (dataId) => marquesApi.v1MarquesIdGet(dataId),
        fetchData: async () => {
          const { data } = await conditionAchatsApi.v1ConditionAchatsIdMarqueGet(conditionAchatId);
          return data.map(val => ({ id: val.id, model: val.fkMarqueNavigation }));
        },
        postData: (row) => marqueConditionAchatsApi.v1MarqueConditionAchatsPost({ fkConditionAchat: conditionAchatId, fkMarque: row.model?.id! }),
        deleteData: (dataId) => marqueConditionAchatsApi.v1MarqueConditionAchatsIdDelete(dataId),
        search: async (search) => {
          const { data: { data } } = await marquesApi.v1MarquesSearchPost(true, 1, 20, { nomMarque: search, code: search });
          return (data || []).map(val => {
            return {
              value: val.id,
              prefix: val.code || undefined,
              label: val.nomMarque || undefined,
              filterKeyArr: [val.nomMarque, val.code]
            };
          });
        },
      };
      return res;

    } else if (field === 'typeArticles') {

      const res: ConfigTemplate<TypeArticleViewModel> = {
        field: "fksTypesArticle",
        headerName: t('lib_type_article_condition_achat_fk_type_article'),
        headerTooltip: t('lib_type_article_condition_achat_fk_type_article'),
        valueFormatter: (model) => `${model?.nomTypeArticle} (${model?.code})`,
        get: (dataId) => typeArticlesApi.v1TypeArticlesIdGet(dataId),
        fetchData: async () => {
          const { data } = await conditionAchatsApi.v1ConditionAchatsIdTypeArticleGet(conditionAchatId);
          return data.map(val => ({ id: val.id, model: val.fkTypeArticleNavigation }));
        },
        postData: async (row) => {
          typeArticleConditionAchatsApi.v1TypeArticleConditionAchatsPost({ fkConditionAchat: conditionAchatId, fkTypeArticle: row.model?.id! });
        },
        deleteData: (dataId) => typeArticleConditionAchatsApi.v1TypeArticleConditionAchatsIdDelete(dataId),
        search: async (search) => {
          const { data: { data } } = await typeArticlesApi.v1TypeArticlesSearchPost(true, 1, 20, { code: search, nomTypeArticle: search });
          return (data || []).map(val => {
            return {
              value: val.id,
              prefix: val.code || undefined,
              label: val.nomTypeArticle || undefined,
              filterKeyArr: [val.code, val.nomTypeArticle]
            };
          });
        },
      };
      return res;
    }

    const res: ConfigTemplate<FluxFournisseurWithModeApprovisionnementViewModel> = {
      field: "fksFluxFournisseur",
      headerName: t('lib_flux_condition_achat_fk_flux_fournisseur'),
      headerTooltip: t('lib_flux_condition_achat_fk_flux_fournisseur'),
      valueFormatter: (model) => `${model?.modeApprovisionnement?.nomModeApprovisionnement} (${model?.modeApprovisionnement?.code})`,
      get: async (dataId) => {
        const res = await fluxFournisseursApi.v1FluxFournisseursIdGet(dataId);
        const { data: mode } = await modeApprovisionnementsApi.v1ModeApprovisionnementsIdGet(res.data.fkModeApprovisionnement);
        return { ...res, data: { ...res.data, modeApprovisionnement: mode } };
      },
      fetchData: async () => {
        const { data } = await conditionAchatsApi.v1ConditionAchatsIdFluxGet(conditionAchatId);
        return data.map(val => ({ id: val.id, model: val.fluxFournisseur }));
      },
      postData: (row) => fluxConditionAchatApi.v1FluxConditionAchatPost({ fkConditionAchat: conditionAchatId, fkFluxFournisseur: row.model?.id! }),
      deleteData: (dataId) => fluxConditionAchatApi.v1FluxConditionAchatIdDelete(dataId),
      search: async (search) => {
        const { data: { data } } = await fluxFournisseursApi.v1FluxFournisseursSearchViaCircuitAndModeApproPost(
          { page: 1, amount: 20, circuitLogistiqueId: circuitLogiId, codeApprovisionnement: search, nomModeApprovisionnement: search }
        );
        return (data || []).map(val => {
          return {
            value: val.id,
            prefix: val.modeApprovisionnement?.code || undefined,
            label: val.modeApprovisionnement?.nomModeApprovisionnement || undefined,
            filterKeyArr: [val.modeApprovisionnement?.code, val.modeApprovisionnement?.nomModeApprovisionnement]
          };
        });
      },
    };
    return res;
  }, [agGridRow]);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData,
      fetchData: async () => config.fetchData(),
      postData: config.postData,
      putData: async () => { },
      deleteData: config.deleteData,
      columnDefs: [
        {
          field: 'foreignKey',
          flex: 1,
          headerName: config.headerName,
          headerTooltip: config.headerTooltip,
          tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
          valueFormatter: (params) => config.valueFormatter(params.data?.model as any),
          editable: editableIfNew,
          cellEditor: AutoCompleteCellEditor,
          cellEditorPopup: true,
          cellEditorParams: {
            canBeEmpty: false,
            searchData: config.search,
          } as AutoCompleteCellEditorProps,
          onCellValueChanged: async (event) => {
            let newModel;
            if (event.newValue) {
              ({ data: newModel } = await config.get(event.newValue));
            }
            event.data.model = newModel;
            event.api.refreshCells({ rowNodes: [event.node!], force: true });
          },
          valueValidator: [notNilCellValidator]
        },
        {
          field: "__expand__",
          headerName: '',
          flex: 2,
        },
      ],
    }), [config, t])
  );

  async function handleCancel(type: CancelType) {
    if (type === 'btn') {
      await gridController.handleCancel();
      setTimeout(gridController.activateContextActionButtons, 50);
    } else {
      setTimeout(() => parentGridController?.fetchData?.(), 50);
      setIsOpen(false);
    }
  }

  return (
    <BlockGrid
      title={t('tit_conditions_achats') + ' - ' + config.headerName}
      layout={LayoutEnum.modal}
      toActivate={true}
      loading={gridController.isLoading}
      disableCreate={gridController.hasEmptyLines}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-modal mt-3">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={(ev) => {
            gridController.onFirstDataRendered(ev);
            setTimeout(gridController.activateContextActionButtons, 50);
          }}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default DetailFiltre;