import { FunctionComponent, useState, useMemo, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import {
  ConditionAchatInsertionModel,
  ConditionAchatViewModel,
  ConditionAchatWithRelationsViewModel,
  EnumConditionAchatApplication,
  RemiseViewModel,
} from "openapi-typescript-codegen";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import BlockGrid, { PropsBlock } from 'components/BlockGrid';

import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { useGridController } from "hooks";
import {
  circuitLogistiquesApi,
  conditionAchatsApi,
  remisesApi,
} from 'api';
import { DateCellRenderer, ModalCellRenderer, ModalCellRendererProps, NumberCellRenderer, TextCellRenderer } from "components/AGGride/CellRender";
import { AutoCompleteCellEditor, AutoCompleteCellEditorProps, DatePickerCellEditor, NumberCellEditor, NumberCellEditorProps, SelectorCellEditor, SelectorCellEditorProps } from "components/AGGride/CellEditor";
import { dateCellValidator, foreignKeyCellValidator, notEmptyCellValidator, notNilCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import { editableIfTodayOrFuture, editableIfNew } from "components/AGGride/cellEditable";
import DetailRemisePalier from "components/Fournisseur/TabConditions/ConditionAchat/DetailRemisePalier";
import DetailRemiseProduit from "components/Fournisseur/TabConditions/ConditionAchat/DetailRemiseProduit";
import DetailFiltre from "components/Fournisseur/TabConditions/ConditionAchat/DetailFiltre";
import { getISOEndDate, getISOStartDate, isNumberOrNaN } from "utils";

import 'app/ag-grid-style.css';

interface PropsBlockExt extends PropsBlock {
  circuitLogiId?: number;
}
export type GridType = {
  remise?: RemiseViewModel;
} & Partial<ConditionAchatWithRelationsViewModel>;

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 70,
};

const ConditionAchat: FunctionComponent<PropsBlockExt> = ({ sm, md, lg, xl, doubleXl, circuitLogiId }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);
  const [typeAccord] = useState<{ value: string | null, label: string; }[]>([
    { value: 'A', label: t('enu_remise_type_accord_arriere') },
    { value: 'C', label: t('enu_remise_type_accord_arriere_conditionne') },
    { value: 'F', label: t('enu_remise_type_accord_sur_facture') },
  ]);
  const [typeSens] = useState<{ value: string | null, label: string; }[]>([
    { value: 'R', label: t('enu_remise_sens_remise') },
    { value: 'M', label: t('enu_remise_sens_majoration') },
  ]);
  const [typeApplication] = useState<{ value: number | null, label: string; }[]>([
    { value: EnumConditionAchatApplication.PourcentageSurPrix, label: t('enu_condition_achat_application_pourcentage') },
    { value: EnumConditionAchatApplication.ParProduit, label: t('enu_condition_achat_application_produit') },
    { value: EnumConditionAchatApplication.ParPalier, label: t('enu_condition_achat_application_palier') },
    { value: EnumConditionAchatApplication.ParNomenclature, label: t('enu_condition_achat_application_nomenclature') },
  ]);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      fetchData: async () => {
        if (!circuitLogiId) return [];
        const { data: res } = await circuitLogistiquesApi.v1CircuitLogistiquesIdConditionAchatsGet(circuitLogiId);

        const remiseIds = new Set(
          res.map(val => val.fkRemise).filter(isNumberOrNaN)
        );
        const remises = await Promise.all(
          [...remiseIds].map(async (remiseId) => {
            const { data: remise } = await remisesApi.v1RemisesIdGet(remiseId);
            return remise;
          })
        );

        return res
          .map((val) => ({
            ...val,
            remise: remises.find(rem => rem.id && rem.id === val.fkRemise),
          }))
          .sort((a, b) => (a.ordreAchat || 0) - (b.ordreAchat || 0));
      },
      postData: async (cleanRow) => {
        const { remise: _, marques: __, typeArticles: ___, fluxFournisseurs: ____, ...conditionAchat } = cleanRow;
        await conditionAchatsApi.v1ConditionAchatsPost(conditionAchat as ConditionAchatInsertionModel);
        // TODO: create ParPalier in backend if application === EnumConditionAchatApplication.ParPalier
      },
      putData: (cleanRow) => conditionAchatsApi.v1ConditionAchatsPut(cleanRow as ConditionAchatViewModel),
      deleteData: (dataId) => conditionAchatsApi.v1ConditionAchatsIdDelete(dataId),
      getNewModel: () => ({
        fkCircuitLogistique: circuitLogiId,
        dtdebutCondition: getISOStartDate(),
        dtfinCondition: getISOEndDate(),
        nomMarques: [],
        assiette: 0,
        ordreAchat: 0,
        fkRemise: -1,
      }),
      onFocusChange: (isFocus) => setActivate(isFocus),
      rowClassRules: {
        "hide-actions": params => !editableIfNew(params) && !editableIfTodayOrFuture(params, 'dtdebutCondition'),
      },
      columnDefs: [
        {
          field: "dtdebutCondition",
          headerName: t('lib_condition_achat_dtdebut_condition'),
          headerTooltip: t('lib_condition_achat_dtdebut_condition'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          cellRenderer: DateCellRenderer,
          editable: (params) => editableIfNew(params) || editableIfTodayOrFuture(params),
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
            min: DateTime.now(),
          },
          valueValidator: [notEmptyCellValidator, dateCellValidator],
        },
        {
          field: "fkRemise",
          headerName: t('lib_condition_achat_fk_remise'),
          headerTooltip: t('lib_condition_achat_fk_remise'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          maxWidth: 70,
          tooltipValueGetter: (params) => `${params.data?.remise?.nomRemise} (${params.data?.remise?.code})`,
          valueFormatter: (params) => params.data?.remise?.code || '',
          editable: (params) => editableIfNew(params) || editableIfTodayOrFuture(params, 'dtdebutCondition'),
          cellEditor: AutoCompleteCellEditor,
          cellEditorPopup: true,
          cellEditorParams: {
            canBeEmpty: false,
            searchData: (async (search) => {
              const { data: { data } } = await remisesApi.v1RemisesSearchPost(true, 1, 20, { nomRemise: search, code: search });
              return (data || []).map(val => {
                return {
                  value: val.id,
                  prefix: val.code,
                  label: val.nomRemise,
                  filterKeyArr: [val.nomRemise, val.code]
                };
              });
            }),
          } as AutoCompleteCellEditorProps,
          onCellValueChanged: async (event) => {
            let remise;
            if (event.newValue) {
              ({ data: remise } = await remisesApi.v1RemisesIdGet(event.newValue));
            }
            event.data.remise = remise;
            event.api.refreshCells({ rowNodes: [event.node!], force: true });
          },
          valueValidator: [notNilCellValidator, foreignKeyCellValidator],
        },
        {
          field: "remise.nomRemise",
          headerName: t('lib_remise_nom_remise'),
          headerTooltip: t('lib_remise_nom_remise'),
          tooltipValueGetter: (params) => params.value,
        },
        {
          field: "remise.typeAccord",
          headerName: t('lib_remise_type_accord'),
          headerTooltip: t('lib_remise_type_accord'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueFormatter: (params) => typeAccord.find(val => val.value === params.value)?.label || params.value
        },
        {
          field: "remise.sens",
          headerName: t('lib_remise_sens'),
          headerTooltip: t('lib_remise_sens'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueFormatter: (params) => typeSens.find(val => val.value === params.value)?.label || params.value
        },
        {
          field: "ordreAchat",
          headerName: t('lib_condition_achat_ordre_achat'),
          headerTooltip: t('lib_condition_achat_ordre_achat'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          minWidth: 30,
          maxWidth: 50,
          cellRenderer: NumberCellRenderer,
          editable: (params) => editableIfNew(params) || editableIfTodayOrFuture(params, 'dtdebutCondition'),
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 999999,
            floating: 0,
          } as NumberCellEditorProps,
          valueValidator: [notNilCellValidator, numberCellValidator],
        },
        {
          field: "assiette",
          headerName: t('lib_condition_achat_assiette'),
          headerTooltip: t('lib_condition_achat_assiette'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          minWidth: 30,
          maxWidth: 50,
          cellRenderer: NumberCellRenderer,
          editable: (params) => editableIfNew(params) || editableIfTodayOrFuture(params, 'dtdebutCondition'),
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 999999,
            floating: 0,
          } as NumberCellEditorProps,
          valueValidator: [notNilCellValidator, numberCellValidator],
        },
        {
          field: "marques",
          headerName: t('lib_marque_condition_achat_fk_marque'),
          headerTooltip: t('lib_marque_condition_achat_fk_marque'),
          tooltipValueGetter: (params) => params.data?.marques?.map(v => v.nomMarque)?.join("; ") || '',
          valueFormatter: (params) => params.data?.marques?.map(v => v.nomMarque)?.join("; ") || '',
          cellRendererSelector: (params) => {
            if (params.data?._customDataProps?.isNew) return undefined;
            return {
              component: ModalCellRenderer,
              params: {
                children: DetailFiltre,
              } as ModalCellRendererProps,
            };
          },
        },
        {
          field: "typeArticles",
          headerName: t('lib_type_article_condition_achat_fk_type_article'),
          headerTooltip: t('lib_type_article_condition_achat_fk_type_article'),
          tooltipValueGetter: (params) => params.data?.typeArticles?.map(v => v.nomTypeArticle)?.join("; ") || '',
          valueFormatter: (params) => params.data?.typeArticles?.map(v => v.nomTypeArticle)?.join("; ") || '',
          cellRendererSelector: (params) => {
            if (params.data?._customDataProps?.isNew) return undefined;
            return {
              component: ModalCellRenderer,
              params: {
                children: DetailFiltre,
              } as ModalCellRendererProps,
            };
          },
        },
        {
          field: "fluxFournisseurs",
          headerName: t('lib_flux_condition_achat_fk_flux_fournisseur'),
          headerTooltip: t('lib_flux_condition_achat_fk_flux_fournisseur'),
          tooltipValueGetter: (params) =>
            params.data?.fluxFournisseurs?.map(v => v.modeApprovisionnement?.nomModeApprovisionnement || v.fkModeApprovisionnement)?.join("; ") || '',
          valueFormatter: (params) =>
            params.data?.fluxFournisseurs?.map(v => v.modeApprovisionnement?.nomModeApprovisionnement || v.fkModeApprovisionnement)?.join("; ") || '',
          cellRendererSelector: (params) => {
            if (params.data?._customDataProps?.isNew) return undefined;
            return {
              component: ModalCellRenderer,
              params: {
                children: DetailFiltre,
              } as ModalCellRendererProps,
            };
          },
        },
        {
          field: "application",
          headerName: t('lib_condition_achat_application'),
          headerTooltip: t('lib_condition_achat_application'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueFormatter: (params) => typeApplication.find(val => val.value === params.value)?.label || params.value,
          editable: (params) => editableIfNew(params) || editableIfTodayOrFuture(params, 'dtdebutCondition'),
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: typeApplication,
          } as SelectorCellEditorProps
        },
        {
          field: "pourcentageRemise",
          headerName: t('lib_condition_achat_pourcentage_remise'),
          headerTooltip: t('lib_condition_achat_pourcentage_remise'),
          maxWidth: 70,
          editable: (params) =>
            editableIfNew(params)
            || (params.data?.application === EnumConditionAchatApplication.PourcentageSurPrix && editableIfTodayOrFuture(params, 'dtdebutCondition')),
          valueFormatter: (params) =>
            params.data?.application === EnumConditionAchatApplication.PourcentageSurPrix ? `${params.value}%` : t('tit_detail'),
          cellRendererSelector: (params) => {
            const isNew = params.data?._customDataProps?.isNew;
            if (params.data?.application === EnumConditionAchatApplication.PourcentageSurPrix)
              return { component: NumberCellRenderer };

            if (!isNew) {
              if (params.data?.application === EnumConditionAchatApplication.ParProduit)
                return {
                  component: ModalCellRenderer,
                  params: { children: DetailRemiseProduit } as ModalCellRendererProps
                };
              if (params.data?.application === EnumConditionAchatApplication.ParPalier)
                return {
                  component: ModalCellRenderer,
                  params: { children: DetailRemisePalier } as ModalCellRendererProps
                };
              // TODO: Out of scope
              // if (params.data?.application === EnumConditionAchatApplication.ParNomenclature)
              //   return {
              //     component: ModalCellRenderer,
              //     params: { children: DetailRemiseNomenclature } as ModalCellRendererProps
              //   };
            }

            return { component: TextCellRenderer };
          },
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 100,
          } as NumberCellEditorProps,
        },
        {
          field: "dtfinCondition",
          headerName: t('lib_condition_achat_dtfin_condition'),
          headerTooltip: t('lib_condition_achat_dtfin_condition'),
          cellRenderer: DateCellRenderer,
          editable: (params) => editableIfNew(params) || editableIfTodayOrFuture(params),
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
            min: DateTime.now(),
          },
          valueValidator: [notEmptyCellValidator, dateCellValidator],
        },
      ],
    }), [circuitLogiId, t])
  );

  useEffect(() => {
    gridController.fetchData();
  }, [circuitLogiId]);

  return (
    <BlockGrid
      title={t('tab_condition_achat')} sm={sm} md={md} lg={lg} xl={xl}
      doubleXl={doubleXl}
      handleClick={(res: boolean) => {
        setActivate(res);
        setTimeout(gridController.activateContextActionButtons, 50);
      }}
      toActivate={activate}
      loading={gridController.isLoading}
      disableCreate={gridController.hasEmptyLines}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          rowClassRules={gridController.rowClassRules}
          context={{ gridController, circuitLogiId }}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        />
      </div>
    </BlockGrid>
  );
};

export default ConditionAchat;