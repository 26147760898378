import { ColDef, ColGroupDef, RowNode } from "ag-grid-community";
import { ISimpleFilterModelType } from "ag-grid-community/dist/lib/filter/provided/simpleFilter";
import { Method, Order } from "openapi-typescript-codegen";

import { ValidateCellFn } from "components/AGGride/cellValidator";

/* Ag grid types override */

interface RowDataProps {
    _customDataProps?: {
        isModified?: boolean;
        isNew?: boolean;
        modified?: { [key: string]: boolean | undefined; };
        validationSuccess?: { [key: string]: boolean | undefined; };
        isValidating?: boolean;
        serverError?: boolean;
    };
    id?: number | null; // FIXME: need assert defined id but w8 backend model
};
export type RowDataType<T = unknown> = RowDataProps & T;
export type RowDataAny = RowDataType<{ [key: string]: any; }>;

export interface RowNodeType<T = unknown> extends RowNode<RowDataType<T>> { }
// export class RowNodeType<T = unknown> extends RowNode { public data!: RowDataType<T>; }

export interface CustomColDef<T = unknown> extends ColDef<RowDataType<T>> {
    valueValidator?: ValidateCellFn<T>[]; // TODO: move valueValidator in customDefs
    customDefs?: {
        defaultServerSort?: Order,
        serverSortAlgo?: ServerSortAlgoType;
        serverFilterMethod?: Method;
    };
}
export interface CustomColGroupDef<T = unknown> extends ColGroupDef<RowDataType<T>> { }

// Missing type from IServerSideGetRowsParams AG Grid
export type FilterModel = Record<string, { filter: string, filterType: 'date' | 'number' | 'text' | 'multi' | 'set', type: ISimpleFilterModelType; }>;

/* Server types */

export const ServerSortAlgo = {
    'Number': { 'asc': Order.AscNbr, 'desc': Order.DescNbr },
    'String': { 'asc': Order.Asc, 'desc': Order.Desc },
};
export declare type ServerSortAlgoType = typeof ServerSortAlgo[keyof typeof ServerSortAlgo];
