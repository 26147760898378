import React, {FC, useEffect, useState} from 'react';

import { useTranslation } from "react-i18next";

import { AxiosResponse } from "axios";

import { ContactViewModel } from "openapi-typescript-codegen";
import {contactsApi } from "api";

import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import SelectComplete, { SelectTemplate } from 'components/SelectComplete';
import ContactListElement from "components/Fournisseur/Contacts/ContactListElement";

import { useUpdateFournisseur } from "context/Referencement";
import { UpdateEnum } from "context/Referencement/updateFournisseur";

import { api, showPrettyError } from "utils";

interface ContactSearchAndSelectProps {
  setIsOpen: (v: boolean) => void;
  isOpen: boolean;
  keys: number[];
  onUpdate: (id: number) => Promise<AxiosResponse<any>>;
}

const ContactSearchAndSelect: FC<ContactSearchAndSelectProps> = ({ setIsOpen, isOpen, keys, onUpdate }) => {
  const { t } = useTranslation();
  const updater = useUpdateFournisseur();
  const [contacts, setContacts] = useState<SelectTemplate<number | null>[]>([]);
  const [selectedContact, setSelectedContact] = useState<SelectTemplate<number | null>>();
  const [selectedContacts, setSelectedContacts] = useState<SelectTemplate<number | null>[]>([]);

  const getContacts = async () => {
    let data = await api.dataset.get<ContactViewModel>(contactsApi.v1ContactsGet(1, 1000));
    data = data.filter(contact => !(keys.some(key => key === contact.id)));
    const $contacts = data.map(contact => {
      let label = `${contact.prenomContact} ${contact.nomContact}`;
      let filterKeys = [contact.prenomContact, contact.nomContact];
      if (contact.fonction) {
        label += ` [${contact.fonction}] `;
        filterKeys.push(contact.fonction);
      }
      if (contact.contactTels?.length) {
        label += ` (${contact.contactTels[0].numTel}) `;
        filterKeys.push(contact.contactTels[0].numTel);
      }
      if (contact.contactMails?.length) {
        label += ` <${contact.contactMails[0].email}> `;
        filterKeys.push(contact.contactMails[0].email);
      }
      return ({
        label,
        value: contact.id,
        filterKeys,
        data: contact
      });
    });
    setContacts($contacts);
  }

  useEffect(() => {
    isOpen && getContacts();
  }, [isOpen]);


  async function handleCreate() {
    try {
      const promises = [];
      for (const contact of selectedContacts) {
        contact.data?.id && promises.push(onUpdate(contact.data.id));
      }
      await Promise.all(promises);
      updater.triggerEvent(UpdateEnum.contacts);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      showPrettyError(error);
    }
  }

  return (
      <BlockGrid
        layout={LayoutEnum.modal}
        title={t('lib_tiers_contact_creation')}
        informations={''}
        toActivate={true}
        handleUpdate={handleCreate}
        handleCancel={() => { setIsOpen(false); }}
      >
        <hr className="my-2" />
        <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
        <span>{t('lib_contact_recherche_selection')}
          <span className="text-red-500">**</span>
        </span>
      </span>
        <div className="grid grid-cols-8 gap-4">
          <label className="block col-span-7 flex-1">
            <SelectComplete
              options={contacts.filter(contact => !selectedContacts.find($contact => $contact.value === contact.value))}
              value={contacts.filter(option => option.value === selectedContact?.value)}
              onChange={(option: any) => { setSelectedContact(option); }}
              placeholder={t('tab_recherche')}
            />
          </label>
          <button type="button" tabIndex={5}
                  disabled={false}
                  className={`col-span-1 h-8 py-0.5 px-2 rounded bg-white-500 border border-store-primary text-store-primary text-xs whitespace-nowrap
              hover:bg-store-primary mt-1 hover:text-white-500 focus:bg-store-primary focus:text-white-500`}
                  onClick={() => {
                    if (selectedContact?.value) {
                      const element = selectedContacts.find(contact => contact.value === selectedContact.value);
                      if (!element) { setSelectedContacts([...selectedContacts, selectedContact]); }
                      setSelectedContact({ value: 0 });
                    }
                  }}>
            +
          </button>
        </div>
            { !!selectedContacts.length &&
                <div className="grid grid-cols-16 gap-4 mt-2">
                  <label className="col-span-3">
                  <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_contact_prenom_contact")}
                    </span>
                  </span>
                  </label>
                  <label className="col-span-3">
                  <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_contact_nom_contact")}
                    </span>
                  </span>
                  </label>
                  <label className="col-span-3">
                  <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_contact_fonction")}
                    </span>
                  </span>
                  </label>
                  <label className="col-span-3">
                  <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_contact_tel_num_tel")}
                    </span>
                  </span>
                  </label>
                  <label className="col-span-3">
                  <span className=" text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_contact_mail_email")}
                    </span>
                  </span>
                  </label>
                </div>
            }
            {
              selectedContacts
                .map(item => {
                  return (<ContactListElement item={item} onDelete={element => {
                    const $contacts = selectedContacts.filter(contact => contact.value !== element.value);
                    setSelectedContacts([...$contacts]);
                  }} key={item.value}/>);
                })
            }
      </BlockGrid>
  );
};

export default ContactSearchAndSelect;