import {
  FormEvent,
  forwardRef,
  useImperativeHandle, useRef,
} from 'react';
import { IFloatingFilterParams, TextFilterModel } from 'ag-grid-community';
import { useTranslation } from "react-i18next";

import reloadIconSrc from 'assets/icons/reload.svg';

export const CheckboxFilter = forwardRef((props: IFloatingFilterParams, ref) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel: TextFilterModel) {
        if (parentModel) {
          inputRef.current!.checked = parentModel.filter === "true";
          inputRef.current!.className = "w-4 h-4 bg-gray-100 ring-2";
        } else {
          inputRef.current!.checked = false;
          inputRef.current!.className = "";
        }
      },
    };
  });

  const onInputBoxChanged = ($event: FormEvent<HTMLInputElement>) => {
    if ($event.currentTarget.checked) {
      props.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged("Equals", "true");
      });
    } else {
      props.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged("Equals", "false");
      });
    }
  };

  const reset = () => {
    props.parentFilterInstance((instance) => {
      instance.onFloatingFilterChanged("Equals", "");
    });
  };

  return (
    <div className="w-full flex justify-center items-center gap-2">
      <input
        ref={inputRef}
        type="checkbox"
        onInput={onInputBoxChanged}
        className={"d-block"}
      />
      <button onClick={() => reset()} className="d-block" title={t('btn_reinitialiser')}>
        <img src={reloadIconSrc} alt="reload icon" className="h-4 opacity-75" />
      </button>
    </div>
  );
});