import { FC, useEffect, useMemo, useState } from 'react';
import {TauxTvaInsertionModel, TauxTvaViewModel} from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";

import { tauxTvasApi, tvasApi } from 'api';
import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import { useGridController } from "hooks";
import { TextCellRenderer, DateCellRenderer } from "components/AGGride/CellRender";
import { DatePickerCellEditor, NumberCellEditor } from 'components/AGGride/CellEditor';
import { dateCellValidator, notEmptyCellValidator } from "components/AGGride/cellValidator";
import { DateTime } from "luxon";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

type GridTauxTvaType = Partial<TauxTvaViewModel | TauxTvaInsertionModel>;

const TableauxTauxTva: FC<{ tvaId: number | null; }> = ({ tvaId }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);

  const gridController = useGridController<GridTauxTvaType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: false,
      emptyRowCheck: (rowData) => !rowData.id,
      fetchData: async () => {
        if (!tvaId) return [];
        const { data } = await tvasApi.v1TvasIdTauxtvasGet(tvaId);
        return (data);
      },
      onFocusChange: (isFocus) => setActivate(isFocus),
      getNewModel: () => {
        return {
          dateApplication: DateTime.now().toISO(),
          fkTva: tvaId!,
        };
      },
      postData: (cleanRow) => {
        cleanRow.dateApplication = cleanRow.dateApplication && cleanRow.dateApplication.split('T')[0];
        return tauxTvasApi.v1TauxTvasPost(cleanRow as TauxTvaInsertionModel);
      },
      putData: (cleanRow) => tauxTvasApi.v1TauxTvasPut(cleanRow as TauxTvaViewModel),
      deleteData: (dataId) => tauxTvasApi.v1TauxTvasIdDelete(dataId),
      columnDefs: [
        {
          field: 'dateApplication',
          headerName: t('lib_taux_tva_date_application'),
          headerTooltip: t('lib_taux_tva_date_application'),
          editable: true,
          maxWidth: 210,
          cellRenderer: DateCellRenderer,
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
            min: DateTime.now(),
            max: DateTime.fromISO('2999-12-31T00:00:00')
          },
          valueValidator: [notEmptyCellValidator, dateCellValidator],
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
        },
        {
          field: 'taux',
          tooltipField: 'code',
          headerName: t('lib_taux_tva_taux'),
          headerTooltip: t('lib_taux_tva_taux'),
          cellRenderer: TextCellRenderer,
          cellEditor: NumberCellEditor,
          cellEditorParams: { min: 0, max: 100 },
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
      ],
    }), [activate, t, tvaId])
  );

  useEffect(() => {
    if (tvaId)
      gridController.fetchData();
  }, [tvaId]);

  return (
    <div className="flex-[2]">
      <div className="mb-2 text-grey-500 font-bold">{t('tab_tableau_taux_tva')}</div>
      <BlockGrid
        layout={LayoutEnum.settings}
        handleClick={(res: boolean) => {
          setActivate(res);
          setTimeout(gridController.activateContextActionButtons, 50);
        }}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-params">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType={"fullRow"}
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </div>
  );
};

export default TableauxTauxTva;