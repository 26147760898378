import { FC, useMemo, useState } from 'react';
import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import { Title } from "components/H2Title";
import { ContactInsertionModel, ContactViewModel } from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { notEmptyCellValidator } from "components/AGGride/cellValidator";

import { contactsApi } from 'api';
import BlockGrid, { PropsBlock, LayoutEnum } from 'components/BlockGrid';
import { ModalCellRenderer, TextCellRenderer } from 'components/AGGride/CellRender';
import { useGridController } from "hooks";
import MailTelModal from "./MailTelModal";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

export type GridType = Partial<ContactViewModel>;

const Contact: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.nomContact,
      fetchData: async () => {
        const { data: { data } } = await contactsApi.v1ContactsGet(1, 1000);
        return data.sort((a, b) => a.nomContact.localeCompare(b.nomContact));
      },
      postData: (row) => contactsApi.v1ContactsPost(row as ContactInsertionModel),
      putData: (row) => contactsApi.v1ContactsPut(row as ContactViewModel),
      deleteData: (id) => contactsApi.v1ContactsIdDelete(id),
      getNewModel: () => ({
        contactMails: [],
        contactTels: [],
      }),
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'nomContact',
          cellRenderer: TextCellRenderer,
          headerName: t('lib_contact_nom_contact'),
          headerTooltip: t('lib_contact_nom_contact'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          editable: true,
          filter: 'agTextColumnFilter',
          flex: 2
        },
        {
          field: 'prenomContact',
          headerName: t('lib_contact_prenom_contact'),
          headerTooltip: t('lib_contact_prenom_contact'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          flex: 2
        },
        {
          field: 'fonction',
          headerName: t('lib_contact_fonction'),
          headerTooltip: t('lib_contact_fonction'),
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          flex: 2
        },
        {
          field: 'contactMails',
          headerName: t('lib_contact_mail_email'),
          headerTooltip: t('lib_contact_mail_email'),
          valueFormatter: (params) => {
            const mails = (params.value as GridType['contactMails']) || [];
            if (params.data?._customDataProps?.isNew) { return ""; }
            return mails.length ? `${mails[0]?.email} ${mails.length - 1 ? `[+${mails.length - 1}]` : String()}` : `[+] ${t("btn_ajouter")}`;
          },
          cellRendererSelector: (params) => {
            if (params.data?._customDataProps?.isNew) return undefined;
            return {
              component: ModalCellRenderer,
              params: {
                children: MailTelModal,
              },
            };
          },
          filter: 'agTextColumnFilter',
          flex: 3
        },
        {
          field: 'contactTels',
          headerName: t('lib_contact_tel_num_tel'),
          headerTooltip: t('lib_contact_tel_num_tel'),
          valueFormatter: (params) => {
            const telephones = (params.value as GridType['contactTels']) || [];
            if (params.data?._customDataProps?.isNew) { return ""; }
            return telephones.length ? `${telephones[0]?.numTel} ${telephones.length - 1 ? `[+${telephones.length - 1}]` : String()}` : `[+] ${t("btn_ajouter")}`;
          },
          cellRendererSelector: (params) => {
            if (params.data?._customDataProps?.isNew) return undefined;
            return {
              component: ModalCellRenderer,
              params: {
                children: MailTelModal,
              },
            };
          },
          filter: 'agTextColumnFilter',
          flex: 2
        },
      ],
    }), [activate, t])
  );

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: `${t('men_refe_fournisseurs')} - ${t('men_parametre')}`,
      link: `/app/referencement/fournisseurs/parametres`,
    },
    {
      name: "tit_contact",
    },
  ];

  const title: Title = {
    title: `${t('men_refe_fournisseurs')} - ${t('tab_contact')}`,
    icon: "fournisseurs",
  };

  return (
    <>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <BlockGrid
        sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
        layout={LayoutEnum.settings}
        handleClick={(res: boolean) => {
          setActivate(res);
          setTimeout(gridController.activateContextActionButtons, 50);
        }}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-params">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType={"fullRow"}
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
            context={{ gridController }}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </>
  );
};

export default Contact;