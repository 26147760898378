import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import BlockGrid, { PropsBlock } from 'components/BlockGrid';

import { articlesApi } from "api";
import { toast } from 'utils';
import { useArticle, useUpdateArticle } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateArticle';

interface FormArray {
  id?: number,
  flPrixVenteCaisse: boolean,
  flQuantiteCaisse: boolean,
  flPeseeCaisse: boolean,
  flTicketRestaurant: boolean;
}

const Caisses: FunctionComponent<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const uA = useUpdateArticle();
  const [activate, setActivate] = useState<boolean>(false);
  const [form, setForm] = useState<FormArray>({
    flPrixVenteCaisse: false,
    flQuantiteCaisse: false,
    flPeseeCaisse: false,
    flTicketRestaurant: false
  });
  const [loading, setLoader] = useState<boolean>(true);

  const fetchGetData = async (id: number) => {
    const response = await articlesApi.v1ArticlesIdGet(id);
    let tmp = {
      id: response.data.id,
      flPrixVenteCaisse: response.data.flPrixVenteCaisse ?? false,
      flQuantiteCaisse: response.data.flQuantiteCaisse ?? false,
      flPeseeCaisse: response.data.flPeseeCaisse ?? false,
      flTicketRestaurant: response.data.flTicketRestaurant ?? false
    };
    if (response.status >= 200 && response.status < 300) setLoader(false);
    setForm(tmp);
  };

  const fetchUpdateData = async (id: number) => {
    const tmp: string = JSON.stringify({
      flPrixVenteCaisse: form.flPrixVenteCaisse,
      flQuantiteCaisse: form.flQuantiteCaisse,
      flPeseeCaisse: form.flPeseeCaisse,
      flTicketRestaurant: form.flTicketRestaurant
    });
    const response = await articlesApi.v1ArticlesIdPut(id, tmp);

    if (response.status >= 200 && response.status < 300) { //success
      toast.success(t('mes_validation_modification'));
      // reset dat with response.data ?
      uA.triggerEvent(UpdateEnum.caisse);
      setActivate(false);
    }
    else
      toast.error(t('mes_erreur_technique') + ": " + response.status);
  };

  useEffect(() => {
    if (article?.id) fetchGetData(article?.id);
  }, [article?.id]);

  function handleUpdate() {
    if (form.id) fetchUpdateData(form.id);
  }

  function handleCancel() {
    if (article?.id) fetchGetData(article?.id);
    setActivate(false);
  }

  return (
    <BlockGrid
      title={t('tab_article_caisse')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      loading={loading}
      handleClick={(res: boolean) => setActivate(res)}
      toActivate={activate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
    >
      <form className="flex flex-col justify-between">
        <div className="mt-4">
          <label className="mb-2 flex gap-4 pl-1">
            <input type="checkbox" tabIndex={5}
              checked={form.flPrixVenteCaisse}
              onChange={e => setForm({ ...form, flPrixVenteCaisse: e.currentTarget.checked })}
              disabled={!activate}
              className={`h-4 w-4 mt-1 block bg-white border border-slate-300 rounded-md
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}`} />
            <span className="font-medium text-grey-500 flex justify-between"><span>{t('lib_article_fl_prix_vente_caisse')}</span></span>
          </label>
          <label className="mb-2 flex gap-4 pl-1">
            <input type="checkbox" tabIndex={5}
              checked={form.flQuantiteCaisse}
              onChange={e => setForm({ ...form, flQuantiteCaisse: e.currentTarget.checked })}
              disabled={!activate}
              className={`h-4 w-4 mt-1 block bg-white border border-slate-300 rounded-md
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}`} />
            <span className="font-medium text-grey-500 flex justify-between"><span>{t('lib_article_fl_quantite_caisse')}</span></span>
          </label>
          <label className="mb-2 flex gap-4 pl-1">
            <input type="checkbox" tabIndex={5}
              checked={form.flPeseeCaisse}
              onChange={e => setForm({ ...form, flPeseeCaisse: e.currentTarget.checked })}
              disabled={!activate}
              className={`h-4 w-4 mt-1 block bg-white border border-slate-300 rounded-md
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}`} />
            <span className="font-medium text-grey-500 flex justify-between"><span>{t('lib_article_fl_pese_caisse')}</span></span>
          </label>
          <label className="mb-2 flex gap-4 pl-1">
            <input type="checkbox" tabIndex={5}
              checked={form.flTicketRestaurant}
              onChange={e => setForm({ ...form, flTicketRestaurant: e.currentTarget.checked })}
              disabled={!activate}
              className={`h-4 w-4 mt-1 block bg-white border border-slate-300 rounded-md
                  disabled:border-slate-200 disabled:bg-white-500
                  focus:border-store-primary
                  ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}`} />
            <span className="font-medium text-grey-500 flex justify-between"><span>{t('lib_article_fl_ticket_restaurant')}</span></span>
          </label>
        </div>
      </form>
    </BlockGrid>
  );
};

export default Caisses;