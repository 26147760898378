import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { useTranslation } from "react-i18next";

import { cadencierFournisseurDetailsApi, cadencierPlanningApi } from "api";
import {
  CadencierFournisseurDetailViewModel,
  CadencierPlanningViewModel,
  EnumJourSemaine
} from "openapi-typescript-codegen";

import BlockGrid, { PropsBlock } from "components/BlockGrid";
import { SelectTemplate } from "components/SelectComplete";
import { numberCellValidator } from "components/AGGride/cellValidator";

import { showPrettyError } from "utils";

import {
  NumberCellRenderer,
  TextCellRenderer
} from "components/AGGride/CellRender";

import { NumberCellEditor, SelectorCellEditor, TimeCellEditor } from "components/AGGride/CellEditor";

import { useGridController } from "hooks/useGridController";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";


interface Props extends PropsBlock {
  selectedDetail: CadencierFournisseurDetailViewModel | { id?: number };
}

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
};

type GridType = CadencierPlanningViewModel;

const PlanningHebdomadaire: FunctionComponent<Props> = ({ selectedDetail }) => {
  // Translation utility:
  const { t } = useTranslation();

  // Grid related states:
  const [activate, setActivate] = useState(false);

  // Options for the days :
  const jours = [
    { value: EnumJourSemaine.Lundi, label: t("enu_cadencier_semaine_lundi") },
    { value: EnumJourSemaine.Mardi, label: t("enu_cadencier_semaine_mardi") },
    { value: EnumJourSemaine.Mercredi, label: t("enu_cadencier_semaine_mercredi") },
    { value: EnumJourSemaine.Jeudi, label: t("enu_cadencier_semaine_jeudi") },
    { value: EnumJourSemaine.Vendredi, label: t("enu_cadencier_semaine_vendredi") },
    { value: EnumJourSemaine.Samedi, label: t("enu_cadencier_semaine_samedi") },
    { value: EnumJourSemaine.Dimanche, label: t("enu_cadencier_semaine_dimanche") }
  ];

  // State to keep track of the days not yet selected:
  const [joursAvailable, setJoursAvailable] = useState<SelectTemplate[]>([]);

  // Plannings keep the state synced with the server:
  const [plannings, setPlannings] = useState<CadencierPlanningViewModel[]>([]);


  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      // @ts-ignore
      fetchData: async () => {
        if (selectedDetail.id) {
          try {
            const { data } = await cadencierFournisseurDetailsApi.v1CadencierFournisseurDetailsIdCadencierPlanningsGet(selectedDetail.id as number);
            // The plannings are set and sorted by day:
            const $plannings = data.sort((planning$1, planning$2) => (planning$1.jrCommande as number) - (planning$2.jrCommande as number)) || [];
            setPlannings($plannings);
            setJoursAvailable($plannings.length ? [...jours.filter(jour => $plannings.every(planning => planning.jrCommande !== jour.value))] : jours);
            gridController.activateContextActionButtons();
            return $plannings || [];
          } catch (error) {
            console.error(error);
            showPrettyError(error);
          }
        } else { return []; }
      },
      postData: async (row) => cadencierPlanningApi.v1CadencierPlanningsPost({ ...row, fkCadencierFournisseurDetail: selectedDetail.id as number}),
      putData: async (row) => cadencierPlanningApi.v1CadencierPlanningsPut(row),
      deleteData: async (id) => cadencierPlanningApi.v1CadencierPlanningsIdDelete(id),
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: "jrCommande",
          headerName: t('col_cadencier_jour_commande'),
          headerTooltip: t('col_cadencier_jour_commande'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          cellRenderer: TextCellRenderer,
          cellEditor: SelectorCellEditor,
          valueFormatter: (params) => {
            const jour = jours.find(jour => jour.value === params.value);
            return jour?.label || "";
          },
          cellEditorParams: { values: joursAvailable },
          editable: (params) => !!params.data?._customDataProps?.isNew
        },
        {
          field: 'heureLimiteCde',
          headerName: t('col_cadencier_heure_limite_commande'),
          headerTooltip: t('col_cadencier_heure_limite_commande'),
          // @TODO: Change the cell renderer to a time cell renderer:
          cellRenderer: TextCellRenderer,
          cellEditor: TimeCellEditor,
          valueFormatter: (params) => params.value ? String(params.value).slice(0, 5) : "",
          onCellValueChanged: (params) => { !params.newValue && (params.data.heureLimiteCde = null); },
          editable: true,
        },
        {
          field: "jrLivraison",
          headerName: t('col_cadencier_jour_livraison'),
          headerTooltip: t('col_cadencier_jour_livraison'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          cellRenderer: TextCellRenderer,
          cellEditor: SelectorCellEditor,
          valueFormatter: (params) => {
            const jour = jours.find(jour => jour.value === params.value);
            return jour?.label || "";
          },
          cellEditorParams: { values: jours },
          editable: true,
        },
        {
          field: 'heureLivraison',
          headerName: t('col_cadencier_heure_livraison'),
          headerTooltip: t('col_cadencier_heure_livraison'),
          // @TODO: Change the cell renderer to a time cell renderer:
          cellRenderer: TextCellRenderer,
          cellEditor: TimeCellEditor,
          valueFormatter: (params) => params.value ? String(params.value).slice(0, 5) : "",
          onCellValueChanged: (params) => { !params.newValue && (params.data.heureLivraison = null); },
          editable: true,
        },
        {
          field: 'semaineDecalage',
          headerName: t('col_cadencier_nb_semaine_dec'),
          headerTooltip: t('col_cadencier_nb_semaine_dec'),
          valueValidator: [numberCellValidator],
          cellRenderer: NumberCellRenderer,
          cellEditor: NumberCellEditor,
          editable: true,
          cellEditorParams: {
            min: 0,
            max: 52,
          },
        },
      ],
    }), [selectedDetail, joursAvailable]),
  );

  useEffect(() => {
    gridController.fetchData();
  }, [selectedDetail]);

  return (
    <BlockGrid
      classNameAdd={"rounded-lg-bottom "}
      loading={gridController.isLoading}
      disableCreate={plannings.length === jours.length || gridController.hasEmptyLines}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
      handleClick={(activation: boolean) => { setActivate(activation); }}
      toActivate={activate}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={($event) => gridController.onRowEditingStopped($event, true)}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        />
      </div>
    </BlockGrid>
  );
};

export default PlanningHebdomadaire;