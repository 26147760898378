import { cloneElement, ReactNode } from 'react';

import DivisionGrid from 'components/BlockGrid/DivisionGrid';
import NavigationItem, { NavigationItemProps } from "./NavigationItem";

interface LateralNavigationProps<GenericViewModel extends { id: number; code: string; }>
  extends Omit<NavigationItemProps<GenericViewModel>, 'item'> {
  dataSet: GenericViewModel[];
  createItemButton?: ReactNode;
  width?: string;
}

const LateralNavigation = <GenericViewModel extends { id: number; code: string; }>(
  { dataSet, createItemButton, width, children, ...props }: LateralNavigationProps<GenericViewModel>
) => {
  return (
    <DivisionGrid width={width}>
      <div className="flex flex-col w-full h-full rounded-lg bg-store-primary-light">
        {createItemButton}
        <div className={`w-full h-full flex flex-col gap-1 pb-2 overflow-auto ${!createItemButton && 'mt-2'}`}>
          {
            dataSet
              .filter(item => typeof item.id === 'number')
              .map((item) => (
                <NavigationItem
                  {...props}
                  key={item.id}
                  item={item}
                >
                  {children && cloneElement(children as any, { parentItem: item })}
                </NavigationItem>
              ))
          }
        </div>
      </div>
    </DivisionGrid >
  );
};

export default LateralNavigation;