import {
  Configuration,
  AuthApi,
  EnseignesApi,
  ArticlesApi,
  CircuitLogistiquesApi,
  ClassesApi,
  CodeEtatsApi,
  ConditionAchatsApi,
  EansApi,
  EspecesApi,
  RelationFournisseurConditionnementsApi,
  LiaisonsApi,
  SectionsApi,
  TypeEansApi,
  FluxFournisseursApi,
  AlgorithmeApprovisionnementsApi,
  TiersApi,
  PaysApi,
  FournisseursApi,
  TypeLiaisonsApi,
  CompletudeArticlesApi,
  ModeTransmissionsApi,
  NomenclatureArticlesApi,
  LieuFonctionsApi,
  TypologiesApi,
  SurMarquesApi,
  MarquesApi,
  SousMarquesApi,
  TvasApi,
  TypeArticlesApi,
  UniteVentesApi,
  UniteMesuresApi,
  ReglementairesApi,
  TechNatureExportsApi,
  TechOriginesApi,
  TechGrpeSeparationsApi,
  TechClasseDangersApi,
  DangereuxApi,
  NomenclatureDouanieresApi,
  ArticleNomenclatureDouanieresApi,
  DepartementsApi,
  RayonsApi,
  FamillesApi,
  SousFamillesApi,
  UniteBesoinsApi,
  SitesApi,
  TauxTvasApi,
  PrixVentesApi,
  HistoPrixVentesApi,
  ArticleConstituantsApi,
  ConstituantsApi,
  ArticleTraitementChimiquesApi,
  TraitementChimiquesApi,
  TaxeAchatsApi,
  TaxeVentesApi,
  TaxesApi,
  ArticleCertificatsApi,
  CertificatsApi,
  ViewFournisseursApi,
  ArticleAgrementsApi,
  ConditionnementsApi,
  ModeApprovisionnementsApi,
  ConditionReglementsApi,
  ModeReglementsApi,
  TypePiecesApi,
  DevisesApi,
  PrixAchatsApi,
  TypeFournisseursApi,
  SequenceDePrixApi,
  IncotermsApi,
  IndicatifEansApi,
  OriginesApi,
  TypeConditionnementsApi,
  VarietesApi,
  ContactsApi,
  TiersContactsApi,
  TemperaturesApi,
  RemisesApi,
  TypeTransportsApi,
  TailleContainersApi,
  TaxeIndirectesApi,
  CommunesApi,
  TemperatureFournisseursApi,
  ConditionAchatParArticlesApi,
  ViewArticlesApi,
  ConditionAchatParPaliersApi,
  ConditionAchatParPalierDetailsApi,
  InterfaceFournisseursApi,
  TauxChangesApi,
  CadencierFournisseursApi,
  CadencierFournisseurDetailsApi,
  CadencierSitesApi,
  CadencierNomenclaturesApi,
  CadencierPlanningsApi,
  MarqueConditionAchatsApi,
  TypeArticleConditionAchatsApi,
  LieuEmbarquementCircuitsApi,
  MatriceFraisApi,
  InterfaceFluxApi,
  FluxConditionAchatApi,
  LieuFonctionSitesApi,
  SiteEnseignesApi,
  ConditionAchatParNomenclaturesApi,
  LieuFonctionContactsApi,
} from "openapi-typescript-codegen";
import axios from "axios";

import { env } from "utils";
import { Jwt } from "./jwt.wrapper";

export const BASE_URL_API = env.BACK_REFERENCE_URL;

const configuration: Configuration | undefined = undefined;

const axiosWithInterceptors = axios.create({
  baseURL: BASE_URL_API,
  withCredentials: true,
});

// Request
axiosWithInterceptors.interceptors.request.use(
  (config) => Jwt.Helpers.InjectCsrfTokenInHeader(config),
  (error) => Promise.reject(error)
);

// Response
axiosWithInterceptors.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === Jwt.UnauthorizedResponseCode && globalThis.location.pathname !== '/login') {
      globalThis.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

export const enseignesApi = new EnseignesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const sitesApi = new SitesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const articlesApi = new ArticlesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const departementsApi = new DepartementsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const rayonsApi = new RayonsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const famillesApi = new FamillesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const sousFamillesApi = new SousFamillesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const uniteBesoinsApi = new UniteBesoinsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const circuitLogistiquesApi = new CircuitLogistiquesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const eansApi = new EansApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const relationFournisseurConditionnementApi = new RelationFournisseurConditionnementsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const classeApi = new ClassesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const codeEtatApi = new CodeEtatsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const especeApi = new EspecesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const liaisonsApi = new LiaisonsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const sectionApi = new SectionsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const typeEansApi = new TypeEansApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const fluxFournisseursApi = new FluxFournisseursApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const algorithmeApprovisionnementsApi = new AlgorithmeApprovisionnementsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const tiersApi = new TiersApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const paysApi = new PaysApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const fournisseursApi = new FournisseursApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const typeLiaisonsApi = new TypeLiaisonsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const completudeArticlesApi = new CompletudeArticlesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const modeTransmissionApi = new ModeTransmissionsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const nomenclatureArticlesApi = new NomenclatureArticlesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const lieuFonctionApi = new LieuFonctionsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const typologiesApi = new TypologiesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const surMarquesApi = new SurMarquesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const marquesApi = new MarquesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const sousMarquesApi = new SousMarquesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const tvasApi = new TvasApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const tauxTvasApi = new TauxTvasApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const typeArticlesApi = new TypeArticlesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const uniteVentesApi = new UniteVentesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const uniteMesuresApi = new UniteMesuresApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const reglementairesApi = new ReglementairesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const techNatureExportsApi = new TechNatureExportsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const techOriginesApi = new TechOriginesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const dangereuxApi = new DangereuxApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const techGrpeSeparationsApi = new TechGrpeSeparationsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const techClasseDangersApi = new TechClasseDangersApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const nomenclatureDouanieresApi = new NomenclatureDouanieresApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const articleNomenclatureDouanieresApi = new ArticleNomenclatureDouanieresApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const prixVentesApi = new PrixVentesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const histoPrixVentesApi = new HistoPrixVentesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const articleConstituantsApi = new ArticleConstituantsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const constituantsApi = new ConstituantsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const articleTraitementChimiquesApi = new ArticleTraitementChimiquesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const traitementChimiquesApi = new TraitementChimiquesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const articleCertificatsApi = new ArticleCertificatsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const certificatsApi = new CertificatsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const sectionsApi = new SectionsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const taxeAchatsApi = new TaxeAchatsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const taxeVentesApi = new TaxeVentesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const taxesApi = new TaxesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const viewFournisseursApi = new ViewFournisseursApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const articleAgrementsApi = new ArticleAgrementsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const conditionnementsApi = new ConditionnementsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const modeApprovisionnementsApi = new ModeApprovisionnementsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const conditionReglementApi = new ConditionReglementsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const modeReglementApi = new ModeReglementsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const typePiecesApi = new TypePiecesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const devisesApi = new DevisesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const prixAchatsApi = new PrixAchatsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const typeFournisseursApi = new TypeFournisseursApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const incotermsApi = new IncotermsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const indicatifEansApi = new IndicatifEansApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const sequenceDePrixApi = new SequenceDePrixApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const originesApi = new OriginesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const typeConditionnementsApi = new TypeConditionnementsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const varieteApi = new VarietesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const contactsApi = new ContactsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const tiersContactsApi = new TiersContactsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const temperaturesApi = new TemperaturesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const remisesApi = new RemisesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const typeTransportsApi = new TypeTransportsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const tailleContainersApi = new TailleContainersApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const taxeIndirectesApi = new TaxeIndirectesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const communesApi = new CommunesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const temperatureFournisseursApi = new TemperatureFournisseursApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const conditionAchatsApi = new ConditionAchatsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const conditionAchatParArticlesApi = new ConditionAchatParArticlesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const viewArticlesApi = new ViewArticlesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const conditionAchatParPaliersApi = new ConditionAchatParPaliersApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const conditionAchatParPalierDetailsApi = new ConditionAchatParPalierDetailsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const interfaceFournisseursApi = new InterfaceFournisseursApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const tauxChangesApi = new TauxChangesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const cadencierFournisseursApi = new CadencierFournisseursApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const lieuEmbarquementCircuitsApi = new LieuEmbarquementCircuitsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const cadencierFournisseurDetailsApi = new CadencierFournisseurDetailsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const cadencierSitesApi = new CadencierSitesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const cadencierNomenclatureApi = new CadencierNomenclaturesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const cadencierPlanningApi = new CadencierPlanningsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const marqueConditionAchatsApi = new MarqueConditionAchatsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const typeArticleConditionAchatsApi = new TypeArticleConditionAchatsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const matriceFraisApi = new MatriceFraisApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const interfaceFluxApi = new InterfaceFluxApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const fluxConditionAchatApi = new FluxConditionAchatApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const lieuFonctionSiteApi = new LieuFonctionSitesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const siteEnseignesApi = new SiteEnseignesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const conditionAchatParNomenclaturesApi = new ConditionAchatParNomenclaturesApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const lieuFonctionsContactsApi = new LieuFonctionContactsApi(configuration, BASE_URL_API, axiosWithInterceptors);
export const authApi = new AuthApi(configuration, BASE_URL_API, axiosWithInterceptors);
