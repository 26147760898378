import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Toaster } from 'react-hot-toast';
import { LicenseManager } from 'ag-grid-enterprise';

import "index.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import App from "./App";
import AppContextProvider from './context';
import * as serviceWorker from "./serviceWorker";
import { i18nInit } from "./i18n";
import { env } from "./utils";
import { Settings } from "luxon";

if (env.NODE_ENV === 'production') {
  console.log('%c Welcome to Store1! ', 'background: #EEE; color: #1D6ACC; font-size: 16px; font-weight: bold; text-decoration: underline');
  console.log(`%c Version: ${env.VERSION_INFO} `, 'background: #EEE; color: #222; font-size: 14px');
}

i18nInit();

LicenseManager.setLicenseKey(env.AG_GRID_LICENSE_KEY);

Settings.defaultZone = env.TIME_ZONE || 'utc';

// Remove ag-grid license error log
var oldConsoleError = console.error;
var oldConsoleWarning = console.warn;

console.error = (message?: any, ...optionalParams: any[]) => {
  if (typeof message === 'string' &&
    (message.charAt(0) === '*' || message.startsWith("Warning: Invalid aria prop"))) return;
  oldConsoleError(message, ...optionalParams);
};
console.warn = (message?: any, ...optionalParams: any[]) => {
  if (typeof message === 'string' &&
    (message.startsWith("ag-grid: invalid colDef property 'valueValidator'") ||
      message.startsWith("ag-grid: invalid colDef property 'customDefs'") ||
      message.startsWith("ag-grid: to see all the valid colDef"))) return;
  oldConsoleWarning(message, ...optionalParams);
};

ReactDOM.render(
  <StrictMode>
    <AppContextProvider>
      <Toaster
        position="top-right"
        reverseOrder={true}
        containerStyle={{
          marginTop: '30px',
          zIndex: 1900
        }}
      />
      <App />
    </AppContextProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
