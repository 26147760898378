import { FC, useState, useMemo, useEffect } from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { ColDef } from "ag-grid-community";
import { TaxeAchatViewModelTypeEnum, TaxeVenteViewModel, TaxeViewModel } from "openapi-typescript-codegen";

import { findTypeTaxeAchatTranslationKey } from "pages/Taxe/type";
import BlockGrid, { PropsBlock } from "components/BlockGrid";
import { DateCellRenderer, TextCellRenderer } from "components/AGGride/CellRender";
import { editableIfTodayOrFuture, editableIfNew, editableIfFuture } from "components/AGGride/cellEditable";
import { DatePickerCellEditor, DatePickerCellEditorProps, NumberCellEditor, SelectorCellEditor } from "components/AGGride/CellEditor";
import { dateCellValidator, notEmptyCellValidator, notNilCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import ClientSideGrid from "components/AGGride/ClientSideGrid";
import { CustomColDef } from "components/AGGride/gridTypes";
import { useArticle } from "context/Referencement";
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { useGridController, GridControllerProps } from "hooks/useGridControllerV2";
import { articlesApi, taxesApi, taxeVentesApi } from "api/";
import { getISOEndDate, getISOStartDate } from "utils";

import 'app/ag-grid-style.css';

const defaultColDef: ColDef = {
  flex: 1,
  minWidth: 90,
};

type GridType = TaxeVenteViewModel;

const TaxesVente: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const [activate, setActivate] = useState(false);
  const [taxeTypes, setTaxeTypes] = useState<TaxeViewModel[]>([]);

  const gridControllerOptions = useMemo((): GridControllerProps<GridType> => ({
    floatingAction: activate ? 'hover' : 'none',
    emptyRowCheck: (rowData) => !rowData.fkTaxe,
    fetchData: async () => {
      if (!article?.id) return [];
      const { data: res } = await articlesApi.v1ArticlesIdTaxeVentesGet(article.id);
      return res.sort((a, b) => a.dateDebut.localeCompare(b.dateDebut));
    },
    postData: (cleanRow) => taxeVentesApi.v1TaxeVentesPost(cleanRow),
    putData: (cleanRow) => taxeVentesApi.v1TaxeVentesPut(cleanRow),
    deleteData: (dataId) => taxeVentesApi.v1TaxeVentesIdWithStatusDelete(dataId),
    getNewModel: () => ({
      fkArticle: article!.id,
      fkTaxe: -1,
      type: 'M',
      valeur: 0,
      dateDebut: getISOStartDate(),
      dateFin: getISOEndDate(),
    }),
    onEditingStopped: () => setActivate(false),
  }), [activate, article]);
  const gridController = useGridController(gridControllerOptions);

  const columnDefs = useMemo((): CustomColDef<GridType>[] => [
    {
      field: "fkTaxe",
      headerName: t('lib_taxe_vente_fk_taxe'),
      headerTooltip: t('lib_taxe_vente_fk_taxe'),
      headerClass: [...AG_GRID_DEFAULT_COLUMN_NEW.headerClass, 'required'],
      editable: editableIfNew,
      tooltipValueGetter: (params) => {
        const taxe = taxeTypes.find(
          (v) => typeof v.id === 'number' && v.id === params.data?.fkTaxe
        );
        return taxe ? taxe?.code + ' - ' + taxe?.nomTaxe : ' ';
      },
      valueFormatter: (params) => taxeTypes.find(
        (v) => typeof v.id === 'number' && v.id === params.data?.fkTaxe
      )?.code || " ",
      cellRenderer: TextCellRenderer,
      cellEditor: SelectorCellEditor,
      cellEditorParams: {
        values: taxeTypes.map((val) => ({
          value: val.id,
          label: val.nomTaxe ? val.code + ' - ' + val.nomTaxe : val.code,
          type: val.typeTaxe,
        })),
      },
      valueValidator: [notEmptyCellValidator],
    },
    {
      field: "taxeName",
      headerName: t('lib_taxe_nom_taxe'),
      headerTooltip: t('lib_taxe_nom_taxe'),
      headerClass: [...AG_GRID_DEFAULT_COLUMN_NEW.headerClass, 'required'],
      valueFormatter: (params) => {
        const taxe = taxeTypes.find(
          (v) => typeof v.id === 'number' && v.id === params.data?.fkTaxe
        );
        return taxe?.nomTaxe || taxe?.nomCourtTaxe || "";
      },
      cellRenderer: TextCellRenderer,
    },
    {
      field: "type",
      headerName: t('lib_taxe_vente_type'),
      headerTooltip: t('lib_taxe_vente_type'),
      editable: editableIfNew,
      valueFormatter: (params) => t(findTypeTaxeAchatTranslationKey(params.value)),
      cellRenderer: TextCellRenderer,
      cellEditor: SelectorCellEditor,
      cellEditorParams: {
        values: Object.values(TaxeAchatViewModelTypeEnum).map((typeTaxe) => ({
          value: typeTaxe,
          label: t(findTypeTaxeAchatTranslationKey(typeTaxe)),
        })),
      },
    },
    {
      field: "valeur",
      headerName: t('lib_taxe_vente_valeur_montant'),
      headerTooltip: t('lib_taxe_vente_valeur_montant'),
      editable: (params) => (editableIfNew(params) || editableIfTodayOrFuture(params, 'dateDebut')) && params.data?.type === "M",
      valueFormatter: (params) => params.data?.type === "M" ? params.value : "",
      cellRenderer: TextCellRenderer,
      cellEditor: NumberCellEditor,
      cellEditorParams: {
        min: 0,
        max: 999999,
      },
      valueValidator: [notNilCellValidator, numberCellValidator],
    },
    {
      field: "valeur",
      headerName: t('lib_taxe_vente_valeur_pourcentage'),
      headerTooltip: t('lib_taxe_vente_valeur_pourcentage'),
      editable: (params) => (editableIfNew(params) || editableIfTodayOrFuture(params, 'dateDebut')) && params.data?.type === "P",
      valueFormatter: (params) => params.data?.type === "P" ? `${params.value}%` : "",
      cellRenderer: TextCellRenderer,
      cellEditor: NumberCellEditor,
      cellEditorParams: {
        min: 0,
        max: 100,
      },
      valueValidator: [notNilCellValidator, numberCellValidator],
    },
    {
      field: "dateDebut",
      headerName: t('lib_taxe_vente_date_debut'),
      headerTooltip: t('lib_taxe_vente_date_debut'),
      tooltipField: "dateDebut",
      editable: editableIfNew,
      cellRenderer: DateCellRenderer,
      cellEditor: DatePickerCellEditor,
      cellEditorParams: {
        min: DateTime.now(),
        roundCurrentTime: true,
      } as DatePickerCellEditorProps,
      valueValidator: [notEmptyCellValidator, dateCellValidator],
    },
    {
      field: "dateFin",
      headerName: t('lib_taxe_vente_date_fin'),
      headerTooltip: t('lib_taxe_vente_date_fin'),
      tooltipField: "dateFin",
      editable: (params) => editableIfNew(params) || editableIfFuture(params, 'dateDebut'),
      cellRenderer: DateCellRenderer,
      cellEditor: DatePickerCellEditor,
      cellEditorParams: {
        min: DateTime.now(),
      } as DatePickerCellEditorProps,
      valueValidator: [notEmptyCellValidator, dateCellValidator],
    },
  ], [taxeTypes, t]);

  useEffect(() => {
    fetchTaxeTypes();
  }, []);

  async function fetchTaxeTypes() {
    const { data: res } = await taxesApi.v1TaxesSearchPost(false, 1, 1000, { typeTaxe: 'V' });
    setTaxeTypes(res.data || []);
  };

  return (
    <BlockGrid
      title={t('tab_taxes_vente')} sm={sm} md={md} lg={lg} xl={xl}
      doubleXl={doubleXl}
      handleClick={(res: boolean) => {
        setActivate(res);
        setTimeout(gridController.activateContextActionButtons, 50);
      }}
      toActivate={activate}
      loading={gridController.isLoading}
      disableCreate={gridController.hasEmptyLines}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <ClientSideGrid
        className="block-grid-md"
        gridController={gridController}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}

        rowClassRules={{
          "hide-actions": params => !editableIfNew(params) && !editableIfFuture(params, 'dateFin'),
        }}
      />
    </BlockGrid>
  );
};

export default TaxesVente;
