import { FC, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { CellStyle, ColDef } from 'ag-grid-community';

import { useFournisseur } from "context/Referencement";
import { useSite } from "context/site";

import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { useTranslation } from "react-i18next";
import {
  circuitLogistiquesApi,
  fluxFournisseursApi,
  lieuFonctionSiteApi,
  modeApprovisionnementsApi,
  modeTransmissionApi,
} from 'api';
import { FluxFournisseurViewModel } from 'openapi-typescript-codegen';
import { AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { useGridController } from "hooks";
import { SelectorCellEditor } from "components/AGGride/CellEditor";
import {
  TextCellRenderer, UniqueRadioCellRenderer
} from 'components/AGGride/CellRender';
import { notEmptyCellValidator } from "components/AGGride/cellValidator";
import { SelectTemplate } from "components/SelectComplete";

import { api } from "utils";

interface Props extends PropsBlock {
  identifier: number;
}

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 90,
};

type GridType = FluxFournisseurViewModel;

const Flux: FC<Props> = ({ sm, md, lg, xl, doubleXl, identifier }) => {
  const { t } = useTranslation();
  const { state: fournisseur } = useFournisseur();
  const { state: site } = useSite();
  const [activate, setActivate] = useState(false);

  const [fluxFournisseurs, setFluxFournisseurs] = useState<FluxFournisseurViewModel[]>([]);

  // Options for the selects:
  const [options, setOptions] = useState<{ [key: string]: SelectTemplate[] }>({
    modeApprovisionnement: [], modeTransmission: [], lieuFonctionSite: []
  });

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {
        sortColId: "modeApprovisionnement",
      },
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.id,
      fetchData: async () => {
        const { data } = await circuitLogistiquesApi.v1CircuitLogistiquesIdFluxFournisseursGet(identifier);
        setFluxFournisseurs(data);
        return data;
      },
      postData: async (flux) => fluxFournisseursApi.v1FluxFournisseursPost({ ...flux, fkCircuitLogistique: identifier }),
      putData: async (flux) => fluxFournisseursApi.v1FluxFournisseursPut(flux),
      deleteData: async (id) => await fluxFournisseursApi.v1FluxFournisseursIdDelete(id),
      onFocusChange: (isFocus) => setActivate(isFocus),
      rowClassRules: { "hide-actions": params => !!params.data?.flFluxDefaut },
      columnDefs: [
        {
          field: 'fkModeApprovisionnement',
          headerName: t('lib_flux_circuit'),
          headerTooltip: t('lib_flux_circuit'),
          flex: 2,
          editable: !!options.modeApprovisionnement.filter(option => !option.isDisabled).length,
          valueFormatter: (params) => options.modeApprovisionnement.find(option => option.value === params.value)?.label || String(),
          cellRenderer: TextCellRenderer,
          cellEditor: SelectorCellEditor,
          cellEditorParams: { values: options.modeApprovisionnement.filter(option => !option.isDisabled) },
          valueValidator: [notEmptyCellValidator],
          onCellValueChanged: (params) => {
            // The flux are updated in order to update the available option (with the useEffect):
            params.data.fkModeApprovisionnement = params.newValue;
            setFluxFournisseurs([fluxFournisseurs.filter(flux => flux.id !== params.data.id), params.data].flat());
            params.api.refreshCells({ rowNodes: [params.node!], force: true });
          },
          onCellClicked: (params) => {
            // The current option and the available options must be displayed:
            const current: SelectTemplate = options.modeApprovisionnement.find(option => option.value === params.data?.fkModeApprovisionnement)!;
            if (current && current.value) {
              params.colDef.cellEditorParams = {
                values: [current, ...options.modeApprovisionnement.filter(option => !option.isDisabled)
                  .sort((option$1, option$2) =>
                    String(option$1.label).localeCompare(String(option$2.label)))]
              };
            }
          },
          filter: 'agTextColumnFilter',
        },
        {
          field: 'fkLieuFonctionSiteLivraison',
          headerName: t('lib_flux_fournisseur_fk_lieu_fonction_livraison'),
          headerTooltip: t('lib_flux_fournisseur_fk_lieu_fonction_livraison'),
          flex: 3,
          cellRenderer: TextCellRenderer,
          cellEditor: SelectorCellEditor,
          cellEditorParams: { values: options.lieuFonctionSite },
          valueFormatter: (params) => options.lieuFonctionSite.find(option => option.value === params.value)?.label || String(),
          editable: options.lieuFonctionSite.length > 1,
        },
        {
          field: 'fkModeTransmission',
          headerName: t('lib_flux_fournisseur_fk_mode_transmission'),
          headerTooltip: t('lib_flux_fournisseur_fk_mode_transmission'),
          flex: 2,
          cellRenderer: TextCellRenderer,
          cellEditor: SelectorCellEditor,
          cellEditorParams: { values: options.modeTransmission },
          valueFormatter: (params) => options.modeTransmission.find(option => option.value === params.value)?.label || String(),
          editable: options.modeTransmission.length > 1,
        },
        {
          field: 'flFluxDefaut',
          headerName: t('lib_flux_fournisseur_fl_flux_defaut'),
          headerTooltip: t('lib_flux_fournisseur_fl_flux_defaut'),
          flex: 2,
          editable: false,
          initialSort: 'desc',
          cellRenderer: UniqueRadioCellRenderer,
          cellStyle: {
            paddingLeft: "0",
            paddingRight: "0",
          } as CellStyle,
        },
      ],
    }), [fournisseur, fluxFournisseurs, options, activate, t])
  );

  // The available options are updated when the flux are updated:
  useEffect(() => {
    const $options = options.modeApprovisionnement.map(option => {
      option.isDisabled = fluxFournisseurs.some(flux => flux.fkModeApprovisionnement === option.value);
      return option;
    });
    setOptions({ ...options, modeApprovisionnement: $options });
  }, [fluxFournisseurs]);

  // The options are all fetched at the first render:
  useEffect(() => {
    api.options.retrieve([
      {
        key: "modeApprovisionnement",
        promise: modeApprovisionnementsApi.v1ModeApprovisionnementsGet(1, 1000),
        configuration: { label: "#{code} - #{nomModeApprovisionnement}" },
        nullable: false
      },
      {
        key: "modeTransmission",
        promise: modeTransmissionApi.v1ModeTransmissionsGet(1, 1000),
        configuration: { label: "#{code} - #{nomModeTransmission}" },
        nullable: true
      },
      {
        key: "lieuFonctionSite",
        promise: lieuFonctionSiteApi.v1LieuFonctionSitesSearchPost(false, 1, 1000, { fkSite: site?.id }),
        configuration: { label: "#{nom} - #{cdEanSite}" },
        nullable: true
      },
    ]).then($options => {
      $options.lieuFonctionSite = $options.lieuFonctionSite.filter(option =>
        !option.data || option.data.typeLieuFonction === "L"
      );
      setOptions($options);
    });
  }, []);

  return (
    <BlockGrid
      title={t('tab_flux_marchandises')}
      sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      handleClick={(activation: boolean) => {
        if (identifier) {
          setActivate(activation);
          setTimeout(gridController.activateContextActionButtons, 50);
        }
      }}
      toActivate={activate}
      loading={gridController.isLoading}
      disableCreate={gridController.hasEmptyLines || !options.modeApprovisionnement.filter(option => !option.isDisabled).length}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-adaptive">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={($event) => gridController.onRowEditingStopped($event, true)}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default Flux;