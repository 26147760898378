import { useState, useEffect, FC, useMemo } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Modal from 'components/Modal';
import BlockGrid, { LayoutEnum } from 'components/BlockGrid';
import { useSite } from 'context/site';
import { useEnseigne } from 'context/Referencement';
import SelectComplete, { SelectTemplate } from 'components/SelectComplete';
import { api, toast } from 'utils';
import { normalizeDecimal } from 'utils/normalize';
import { eanValidator, ColumnControlArticle } from 'validators';
import { Tooltip } from "@mui/material";

import {
  SiteEnseigneWithEnseigneViewModel,
  ViewNomenclatureArticleViewModel,
  ArticleInsertionModel,
  EanViewModel,
  TypeEanViewModel,
  EanInsertionModel,
  UniteBesoinViewModel,
  SousFamilleViewModel,
  FamilleViewModel,
  DepartementViewModel,
  RayonViewModel,
  MarqueViewModel,
  SousMarqueViewModel,
  SurMarqueViewModel,
  CompletudeArticleViewModel,
} from 'openapi-typescript-codegen';
import {
  articlesApi,
  eansApi,
  sitesApi,
  enseignesApi,
  departementsApi,
  rayonsApi,
  famillesApi,
  sousFamillesApi,
  typologiesApi,
  surMarquesApi,
  marquesApi, typeEansApi, tvasApi, typeArticlesApi, uniteMesuresApi, uniteVentesApi, completudeArticlesApi
} from 'api';

import { useTranslation } from "react-i18next";

const defaultImage = "/assets/images/default.png";
interface Props {
}

const CreationArticle: FC<Props> = () => {
  // Translation utility:
  const { t } = useTranslation();

  // Context:
  const { state: site } = useSite();
  const { state: enseigne } = useEnseigne();

  // Navigation utilities:
  const navigate = useNavigate();
  const location = useLocation();

  // States for the models:
  const [article, setArticle] = useState<Partial<ArticleInsertionModel>>({});
  const [ean, setEan] = useState<Partial<EanViewModel>>({});
  const [typeEans, setTypeEans] = useState<TypeEanViewModel[]>([]);
  const [nomenclature, setNomenclature] = useState<Partial<ViewNomenclatureArticleViewModel>>({});
  const [siteEnseignes, setSiteEnseignes] = useState<SiteEnseigneWithEnseigneViewModel[]>([]);

  // State for the modal:
  const [open, setOpen] = useState<boolean>(false);

  // State for the completude:
  const [controls, setControls] = useState<ColumnControlArticle[]>([]);

  // Dynamic options for the selection of the marques:
  const [surMarques, setSurMarques] = useState<SelectTemplate[]>([]);
  const [marques, setMarques] = useState<SelectTemplate[]>([]);
  const [sousMarques, setSousMarques] = useState<SelectTemplate[]>([]);

  // Dynamic options for the selection of the nomenclature:
  const [enseignes, setEnseignes] = useState<SelectTemplate[]>([]);
  const [departements, setDepartements] = useState<SelectTemplate[]>([]);
  const [rayons, setRayons] = useState<SelectTemplate[]>([]);
  const [familles, setFamilles] = useState<SelectTemplate[]>([]);
  const [sousFamilles, setSousFamilles] = useState<SelectTemplate[]>([]);
  const [uniteBesoins, setUniteBesoins] = useState<SelectTemplate[]>([]);

  // Static options for the selects:
  const [options, setOptions] = useState<{ [key: string]: SelectTemplate[]; }>({
    tva: [], typeArticle: [], typeEan: [], typologie: [], uniteMesure: [], uniteVente: []
  });

  const { parsedEAN, errorMsg: eanError, isRevised } = useMemo(() => {
    return typeEans.length ?
      eanValidator(ean.code ?? String(), ean.fkTypeEan ?? -1, typeEans) :
      { parsedEAN: "", errorMsg: "", isRevised: false };
  }, [ean, typeEans]);

  // The static options are all fetched at the first render:
  useEffect(() => {
    if (open) {
      refresh.siteEnseignes();
      api.options.retrieve([
        {
          key: "tva",
          promise: tvasApi.v1TvasGet(1, 200),
          configuration: { label: "#{code} - #{nomTva}" },
          nullable: true
        },
        {
          key: "typeArticle",
          promise: typeArticlesApi.v1TypeArticlesGet(1, 200),
          configuration: { label: "#{code} - #{nomTypeArticle}" },
          nullable: true
        },
        {
          key: "typeEan",
          promise: typeEansApi.v1TypeEansGet(1, 200),
          configuration: { label: "#{code} - #{nomTypeEan}" },
          nullable: true
        },
        {
          key: "typologie",
          promise: typologiesApi.v1TypologiesGet(1, 200),
          configuration: { label: "#{code} - #{nomTypologie}" },
          nullable: true
        },
        {
          key: "uniteMesure",
          promise: uniteMesuresApi.v1UniteMesuresGet(1, 200),
          configuration: { label: "#{code} - #{nomUniteMesure}" },
          nullable: true
        },
        {
          key: "uniteVente",
          promise: uniteVentesApi.v1UniteVentesGet(1, 200),
          configuration: { label: "#{code} - #{nomUniteVente}" },
          nullable: true
        }
      ]).then(setOptions);
    }
  }, [open]);

  useEffect(() => {
    setTypeEans(api.options.dataset.get<TypeEanViewModel>(options.typeEan));
  }, [options.typeEan]);

  useEffect(() => { open && refresh.siteEnseignes(); }, [site, open]);

  const refresh = {
    surMarques: async () => {
      if (article.fkTypologie) {
        const data = await api.dataset.get<SurMarqueViewModel>(typologiesApi.v1TypologiesIdSurmarquesGet(article.fkTypologie));
        setSurMarques(api.options.generate(data, { label: "#{code} - #{nomSurMarque}" }, true));
      } else { setSurMarques([]); }
    },
    marques: async () => {
      if (article.fkSurMarque) {
        const data = await api.dataset.get<MarqueViewModel>(surMarquesApi.v1SurMarquesIdMarquesGet(article.fkSurMarque));
        setMarques(api.options.generate(data, { label: "#{code} - #{nomMarque}" }, true));
      } else { setMarques([]); }
    },
    sousMarques: async () => {
      if (article.fkMarque) {
        const data = await api.dataset.get<SousMarqueViewModel>(marquesApi.v1MarquesIdSousmarquesGet(article.fkMarque));
        setSousMarques(api.options.generate(data, { label: "#{code} - #{nonSousMarque}" }, true));
      } else { setSousMarques([]); }
    },
    siteEnseignes: async () => {
      if (site && site.id) {
        setSiteEnseignes(await api.dataset.get<SiteEnseigneWithEnseigneViewModel>(sitesApi.v1SitesIdSiteEnseignesGet(site.id)));
      } else { setSiteEnseignes([]); }
    },
    enseignes: async () => {
      if (siteEnseignes && siteEnseignes.length) {
        const $enseignes = siteEnseignes.map(siteEnseigne => siteEnseigne.enseigne);
        setEnseignes(api.options.generate($enseignes, { label: "#{code} - #{nomEnseigne}" }, false));
      } else { setEnseignes([]); }
    },
    departements: async () => {
      if (nomenclature.fkEnseigne) {
        const data = await api.dataset.get<DepartementViewModel>(enseignesApi.v1EnseignesIdDepartementsGet(nomenclature.fkEnseigne));
        setDepartements(api.options.generate(data, { label: "#{code} - #{nomDepartement}" }, true));
      } else { setDepartements([]); }
    },
    rayons: async () => {
      if (nomenclature.fkDepartement) {
        const data = await api.dataset.get<RayonViewModel>(departementsApi.v1DepartementsIdRayonsGet(nomenclature.fkDepartement));
        setRayons(api.options.generate(data, { label: "#{code} - #{nomRayon}" }, true));
      } else { setRayons([]); }
    },
    familles: async () => {
      if (nomenclature.fkRayon) {
        const data = await api.dataset.get<FamilleViewModel>(rayonsApi.v1RayonsIdFamillesGet(nomenclature.fkRayon));
        setFamilles(api.options.generate(data, { label: "#{code} - #{nomFamille}" }, true));
      } else { setFamilles([]); }
    },
    sousFamilles: async () => {
      if (nomenclature.fkFamille) {
        const data = await api.dataset.get<SousFamilleViewModel>(famillesApi.v1FamillesIdSousfamillesGet(nomenclature.fkFamille));
        setSousFamilles(api.options.generate(data, { label: "#{code} - #{nomSf}" }, true));
      } else { setSousFamilles([]); }
    },
    uniteBesoins: async () => {
      if (nomenclature.fkSousFamille) {
        const data = await api.dataset.get<UniteBesoinViewModel>(sousFamillesApi.v1SousFamillesIdUnitebesoinGet(nomenclature.fkSousFamille));
        setUniteBesoins(api.options.generate(data, { label: "#{code} - #{nomUb}" }, true));
      } else { setUniteBesoins([]); }
    }
  };

  useEffect(() => {
    setArticle({ ...article, fkSousMarque: null });
    refresh.sousMarques();
  }, [article.fkMarque]);

  useEffect(() => {
    setArticle({ ...article, fkMarque: null, fkSousMarque: null });
    refresh.marques();
  }, [article.fkSurMarque]);

  useEffect(() => { refresh.surMarques(); }, [article.fkTypologie]);

  // Update the options for the nomenclature when it changes:
  useEffect(() => { refresh.uniteBesoins(); }, [nomenclature?.fkSousFamille]);
  useEffect(() => { refresh.sousFamilles(); }, [nomenclature?.fkFamille]);
  useEffect(() => { refresh.familles(); }, [nomenclature?.fkRayon]);
  useEffect(() => { refresh.rayons(); }, [nomenclature?.fkDepartement]);
  useEffect(() => { refresh.departements(); }, [nomenclature?.fkEnseigne]);
  useEffect(() => { refresh.enseignes(); }, [siteEnseignes]);

  useEffect(() => { enseigne?.id && setNomenclature({ fkEnseigne: enseigne.id }); }, [enseigne?.id]);

  useEffect(() => {
    api.dataset.get<CompletudeArticleViewModel>(completudeArticlesApi.v1CompletudeArticlesGet(1, 1000)).then(data => {
      // Getting the data for the current nomenclature:
      data = data.reduce((accumulator: CompletudeArticleViewModel[], item: CompletudeArticleViewModel) => {
        if (!accumulator.find($item => item.colonneArticle === $item.colonneArticle)) {
          const columns = data.filter(column => column.colonneArticle === item.colonneArticle);

          columnsLbl: for (const column of columns) {
            const keys = ["fkUniteBesoin", "fkSousFamille", "fkFamille", "fkRayon", "fkDepartement", "fkEnseigne"] as const;
            for (const key of keys) {
              if (!column[key] && key !== "fkEnseigne") {
                continue;
              } else if ((column[key] && nomenclature[key]) && nomenclature[key] !== column[key]) {
                break;
              } else if ((nomenclature[key] && column[key]) && nomenclature[key] === column[key]) {
                accumulator.push(column);
                break columnsLbl;
              }
              accumulator.push(column);
            }
          }
        }
        return accumulator;
      }, []);
      setControls(ColumnControlArticle.initialize(data));
    });
    // @TODO: Get the completude rules for the selected nomenclature directly through the API~
    // @TODO: Initialize ColumnControlArticle with the completude rules~
    // @TODO: Update the completude rules on the inputs according to the ColumnControlArticles~
  }, [nomenclature]);

  function validate() {
    let isValid = true;
    if (!nomenclature.fkEnseigne) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_article_enseigne')}`);
      isValid = false;
    }
    if (!nomenclature.fkDepartement) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_article_departement')}`);
      isValid = false;
    }
    if (!nomenclature.fkRayon) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_article_rayon')}`);
      isValid = false;
    }
    if (!nomenclature.fkFamille) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_article_famille')}`);
      isValid = false;
    }
    if (!nomenclature.fkSousFamille) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_article_sous_famille')}`);
      isValid = false;
    }
    if (!article.designationStandard) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_article_designation_standard')}`);
      isValid = false;
    }
    if (!ean.code && ean.fkTypeEan) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_ean_code')}`);
      isValid = false;
    } else if (ean.code && !ean.fkTypeEan) {
      toast.error(`${t('mes_completude_champ_manquant')}: ${t('lib_ean_type_ean')}`);
      isValid = false;
    } else if (ean.code && ean.fkTypeEan) {
      const { errorMsg } = eanValidator(ean.code, ean.fkTypeEan, typeEans);
      if (errorMsg) {
        toast.error(t(errorMsg));
        isValid = false;
      }
    }
    isValid = isValid || ColumnControlArticle.validateMany([
      "article.fk_typologie",
      "article.fk_sur_marque",
      "article.fk_marque",
      "article.fk_sous_marque",
      "article.code_externe",
      "article.fk_tva",
      "article.fk_type_article",
      "article.fk_unite_vente",
      "article.valeur_mesure",
      "article.poids_egoutte",
      "article.duree_vie_article",
      "article.longueur",
      "article.largeur",
      "article.hauteur",
    ], article);
    return isValid;
  }

  const createArticle = async () => {
    // @TODO: Refactorize this function~
    const responseArticle = await articlesApi.v1ArticlesPost({
      ...article,
      sousFamilleId: nomenclature.fkSousFamille as number,
      uniteBesoinId: nomenclature.fkUniteBesoin as number,
    } as ArticleInsertionModel);
    if (responseArticle.status >= 200 && responseArticle.status < 300) {
      if (!eanError) {
        const responseEan = await eansApi.v1EansPost({ ...ean, fkArticle: responseArticle.data.id, flEanMaitre: true } as EanInsertionModel);
        if (responseEan.status >= 200 && responseEan.status < 300) {
          toast.success(t('mes_validation_modification'));
          setOpen(false);
          if (responseArticle.data?.code) handleNavigate(responseArticle.data.code);
        } else toast.error(t('mes_erreur_technique') + ": " + responseEan.status);
      } else {
        toast.success(t('mes_validation_modification'));
        setOpen(false);
        if (responseArticle.data?.code) handleNavigate(responseArticle.data.code);
      }
    } else toast.error(t('mes_erreur_technique') + ": " + responseArticle.status);
  };

  function handleNavigate(code: string) {
    navigate(`${location.pathname}/${code}`);
  }

  function toCreate() { setOpen(true); }

  function handleCreate() {
    validate() && createArticle();
  }

  function handleCancel() {
    setArticle({});
    enseigne?.id && setNomenclature({ fkEnseigne: enseigne.id });
    setEan({});
    setOpen(false);
  }

  return (
    <>
      <Tooltip title={t('bul_nouveau')} followCursor>
        <button
          onClick={() => toCreate()}
          className="border border-store-primary hover:bg-store-primary focus:bg-store-primary text-store-primary hover:text-white-500 focus:text-white-500 rounded py-0.5 px-2">{t('btn_nouveau')} +</button>
      </Tooltip>
      <Modal
        open={open} tabIndex={-1}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <BlockGrid
          layout={LayoutEnum.modal}
          title={t('tit_creation_fiche_article')}
          toActivate={open}
          handleUpdate={handleCreate}
          handleCancel={handleCancel}
        >
          <form className="flex flex-col justify-between">
            <div className="">
              <div className="grid grid-cols-12 gap-x-4">
                <div className="col-span-12 items-center pt-3 pb-1 h-10">
                  <div className="text-grey-500 font-bold text-sm">{t('tab_article_nomenclature')}</div>
                </div>
                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_enseigne')}<span className="text-red-500">**</span>
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    isRequired={!nomenclature?.fkEnseigne}
                    options={enseignes}
                    value={enseignes.filter(option => option.value === nomenclature?.fkEnseigne)}
                    onChange={(option: any): void => setNomenclature({
                      fkEnseigne: option.value,
                      fkDepartement: null,
                      fkRayon: null,
                      fkFamille: null,
                      fkSousFamille: null,
                      fkUniteBesoin: null
                    })}
                    classNameAdd={``}
                  />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_departement')}<span className="text-red-500">**</span>
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    isRequired={!nomenclature?.fkDepartement}
                    options={departements}
                    value={departements.filter(option => option.value === nomenclature?.fkDepartement)}
                    onChange={(option: any): void => setNomenclature({
                      ...nomenclature,
                      fkDepartement: option.value,
                      fkRayon: null,
                      fkFamille: null,
                      fkSousFamille: null,
                      fkUniteBesoin: null
                    })}
                    classNameAdd={``}
                  />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_rayon')}<span className="text-red-500">**</span>
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    isRequired={!nomenclature?.fkRayon}
                    options={rayons}
                    value={rayons.filter(option => option.value === nomenclature?.fkRayon)}
                    onChange={(option: any): void => setNomenclature({
                      ...nomenclature,
                      fkRayon: option.value,
                      fkFamille: null,
                      fkSousFamille: null,
                      fkUniteBesoin: null
                    })}
                    classNameAdd={``}
                  />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_famille')}<span className="text-red-500">**</span>
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    isRequired={!nomenclature?.fkFamille}
                    options={familles}
                    value={familles.filter(option => option.value === nomenclature?.fkFamille)}
                    onChange={(option: any): void => setNomenclature({
                      ...nomenclature,
                      fkFamille: option.value,
                      fkSousFamille: null,
                      fkUniteBesoin: null
                    })}
                    classNameAdd={``}
                  />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_sous_famille')}<span className="text-red-500">**</span>
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    isRequired={!nomenclature?.fkSousFamille}
                    options={sousFamilles}
                    value={sousFamilles.filter(option => option.value === nomenclature?.fkSousFamille)}
                    onChange={(option: any): void => setNomenclature({
                      ...nomenclature,
                      fkSousFamille: option.value,
                      fkUniteBesoin: null
                    })}
                    classNameAdd={``}
                  />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_unite_besoin')}
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    options={uniteBesoins}
                    value={uniteBesoins.filter(option => option.value === nomenclature?.fkUniteBesoin)}
                    onChange={(option: any): void => setNomenclature({ ...nomenclature, fkUniteBesoin: option.value })}
                    classNameAdd={``}
                  />
                </label>

              </div>
              <div className="grid grid-cols-12 gap-x-4">
                <div className="col-span-12 items-center pt-3 pb-1 h-10">
                  <div className="text-grey-500 font-bold text-sm">{`${t('tab_article_designation')} & ${t('tab_article_marque')}`}</div>
                </div>
                <label className="block mb-2 col-span-4">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t("lib_article_designation_standard")}
                      {ColumnControlArticle.get("article.designation_standard", controls)?.getValidationLabelElement()}
                    </span>
                    {/* <span>{form?.designationStandard.length}/{controled?.find((i) => i.colonneArticle === 'designation_standard')?.typeValidation ?? 200}</span> */}
                    <span>{article?.designationStandard?.length}/{200}</span>
                  </span>
                  <input type="text" tabIndex={5}
                    value={article?.designationStandard ?? ''}
                    // maxLength={controled?.find((i) => i.colonneArticle === 'designation_standard')?.typeValidation ?? 200}
                    maxLength={200}
                    onChange={e => setArticle({ ...article, designationStandard: e.currentTarget.value })}
                    className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                    disabled:border-slate-200 disabled:bg-white-500
                    focus:border-store-primary 
                    ${ColumnControlArticle.get("article.designation_standard", controls)?.getValidationInputClass(true, true, article)}
                    `}
                  />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_fk_typologie')}
                      {ColumnControlArticle.get("article.fk_typologie", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    options={options.typologie}
                    value={options.typologie.filter(option => option.value === article.fkTypologie)}
                    onChange={(option: any): void => setArticle({ ...article, fkTypologie: option.value })}
                    classNameAdd={``}
                    isRequired={ColumnControlArticle.get("article.fk_typologie", controls)?.required}
                  />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_fk_sur_marque')}
                      {ColumnControlArticle.get("article.fk_sur_marque", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    options={surMarques}
                    value={surMarques.filter(option => option.value === article.fkSurMarque)}
                    onChange={(option: any): void => setArticle({ ...article, fkSurMarque: option.value })}
                    classNameAdd={``}
                    isRequired={ColumnControlArticle.get("article.fk_sur_marque", controls)?.required}
                  />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_fk_marque')}
                      {ColumnControlArticle.get("article.fk_marque", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    options={marques}
                    value={marques.filter(option => option.value === article.fkMarque)}
                    onChange={(option: any): void => setArticle({ ...article, fkMarque: option.value })}
                    classNameAdd={``}
                    isRequired={ColumnControlArticle.get("article.fk_marque", controls)?.required}
                  />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_fk_sous_marque')}
                      {ColumnControlArticle.get("article.fk_sous_marque", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    options={sousMarques}
                    value={sousMarques.filter(option => option.value === article.fkSousMarque)}
                    onChange={(option: any): void => setArticle({ ...article, fkSousMarque: option.value })}
                    classNameAdd={``}
                    isRequired={ColumnControlArticle.get("article.fk_sous_marque", controls)?.required}
                  />
                </label>

              </div>

              <div className="grid grid-cols-12 gap-x-4">
                <div className="col-span-12 items-center pt-3 pb-1 h-10">
                  <div className="text-grey-500 font-bold text-sm">{t('tab_article_produit')}</div>
                </div>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_fk_tva')}
                      {ColumnControlArticle.get("article.fk_tva", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    options={options.tva}
                    value={options.tva.filter(option => option.value === article.fkTva)}
                    onChange={(option: any): void => setArticle({ ...article, fkTva: option.value })}
                    classNameAdd={``}
                    isRequired={ColumnControlArticle.get("article.fk_tva", controls)?.required}
                  />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_fk_type_article')}
                      {ColumnControlArticle.get("article.fk_type_article", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    options={options.typeArticle}
                    value={options.typeArticle.filter(option => option.value === article.fkTypeArticle)}
                    onChange={(option: any): void => setArticle({ ...article, fkTypeArticle: option.value })}
                    classNameAdd={``}
                    isRequired={ColumnControlArticle.get("article.fk_type_article", controls)?.required}
                  />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_fk_unite_vente')}
                      {ColumnControlArticle.get("article.fk_unite_vente", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    options={options.uniteVente}
                    value={options.uniteVente.filter(option => option.value === article.fkUniteVente)}
                    onChange={(option: any): void => setArticle({ ...article, fkUniteVente: option.value })}
                    classNameAdd={``}
                    isRequired={ColumnControlArticle.get("article.fk_unite_vente", controls)?.required}
                  />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_poids_unitaire')}
                      {ColumnControlArticle.get("article.poids_unitaire", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <input type="number" tabIndex={5}
                    min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                    value={article.poidsUnitaire?.toString() ?? ''}
                    onChange={e => setArticle({ ...article, poidsUnitaire: parseFloat(normalizeDecimal(e.currentTarget.value, 2)) })}
                    className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ColumnControlArticle.get("article.poids_unitaire", controls)?.getValidationInputClass(true, true, article)}
                      `} />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_fk_unite_mesure')}
                      {ColumnControlArticle.get("article.fk_unite_mesure", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    options={options.uniteMesure}
                    value={options.uniteMesure.filter(option => option.value === article.fkUniteMesure)}
                    onChange={(option: any): void => setArticle({ ...article, fkUniteMesure: option.value })}
                    classNameAdd={``}
                    isRequired={ColumnControlArticle.get("article.fk_unite_mesure", controls)?.required}
                  />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_valeur_mesure')}
                      {ColumnControlArticle.get("article.valeur_mesure", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <input type="number" tabIndex={5}
                    min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                    value={article.valeurMesure?.toString() ?? ''}
                    onChange={e => setArticle({ ...article, valeurMesure: parseFloat(normalizeDecimal(e.currentTarget.value, 2)) })}
                    className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ColumnControlArticle.get("article.valeur_mesure", controls)?.getValidationInputClass(true, true, article)}
                      `} />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_code_externe')}
                      {ColumnControlArticle.get("article.code_externe", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <input type="string" tabIndex={5}
                    value={article.codeExterne?.toString() ?? ''}
                    onChange={$event => setArticle({ ...article, codeExterne: $event.currentTarget.value })}
                    className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                       ${ColumnControlArticle.get("article.code_externe", controls)?.getValidationInputClass(true, true, article)}
                      `} />
                </label>


                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_longueur')}
                      {ColumnControlArticle.get("article.longueur", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <input type="number" tabIndex={5}
                    min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                    value={article.longueur?.toString() ?? ''}
                    onChange={e => setArticle({ ...article, longueur: parseFloat(normalizeDecimal(e.currentTarget.value, 2)) })}
                    className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ColumnControlArticle.get("article.longueur", controls)?.getValidationInputClass(true, true, article)}
                      `} />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_largeur')}
                      {ColumnControlArticle.get("article.largeur", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <input type="number" tabIndex={5}
                    min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                    value={article.largeur?.toString() ?? ''}
                    onChange={e => setArticle({ ...article, largeur: parseFloat(normalizeDecimal(e.currentTarget.value, 2)) })}
                    className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ColumnControlArticle.get("article.largeur", controls)?.getValidationInputClass(true, true, article)}
                      `} />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_hauteur')}
                      {ColumnControlArticle.get("article.hauteur", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <input type="number" tabIndex={5}
                    min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                    value={article.hauteur?.toString() ?? ''}
                    onChange={e => setArticle({ ...article, hauteur: parseFloat(normalizeDecimal(e.currentTarget.value, 2)) })}
                    className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ColumnControlArticle.get("article.hauteur", controls)?.getValidationInputClass(true, true, article)}
                  `} />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_poids_egoutte')}
                      {ColumnControlArticle.get("article.poids_egoutte", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <input type="number" tabIndex={5}
                    min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                    value={article.poidsEgoutte?.toString() ?? ''}
                    onChange={e => setArticle({ ...article, poidsEgoutte: parseFloat(normalizeDecimal(e.currentTarget.value, 2)) })}
                    className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ColumnControlArticle.get("article.poids_egoutte", controls)?.getValidationInputClass(true, true, article)}
                      `} />
                </label>

                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_article_duree_vie_article')}
                      {ColumnControlArticle.get("article.duree_vie_article", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <input type="number" tabIndex={5}
                    min={0} step={0.01} pattern="^\d*(\.\d{0,2})?$"
                    value={article.dureeVieArticle?.toString() ?? ''}
                    onChange={e => setArticle({ ...article, dureeVieArticle: parseFloat(normalizeDecimal(e.currentTarget.value, 2)) })}
                    className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary
                      ${ColumnControlArticle.get("article.duree_vie_article", controls)?.getValidationInputClass(true, true, article)}
                      `} />
                </label>

              </div>

              <div className="grid grid-cols-12 gap-x-4">
                <div className="col-span-12 items-center pt-3 pb-1 h-10">
                  <div className="text-grey-500 font-bold text-sm">{t('tab_liste_eans')}</div>
                </div>
                <label className="block mb-2 col-span-2">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_ean_type_ean')}
                      {ColumnControlArticle.get("ean.fk_type_ean", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <SelectComplete tabIndex={5}
                    options={options.typeEan}
                    value={options.typeEan.filter(option => option.value === ean.fkTypeEan)}
                    onChange={(option: any): void => setEan({ ...ean, fkTypeEan: option.value })}
                    classNameAdd={``}
                    isRequired={ColumnControlArticle.get("ean.fk_type_ean", controls)?.required}
                  />
                </label>

                <label className="block mb-2 col-span-4">
                  <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
                    <span>
                      {t('lib_ean_code')}
                      {ColumnControlArticle.get("ean.code", controls)?.getValidationLabelElement()}
                    </span>
                  </span>
                  <Tooltip
                    title={
                      eanError && ean?.fkTypeEan
                        ? <div className="flex flex-col text-xs">
                          <span>{eanError || ''}</span>
                          {isRevised && <button
                            className="bg-blue-store text-white-500 rounded py-1 my-1"
                            onClick={() => setEan({ ...ean, code: parsedEAN as string })}>
                            Changer l'EAN
                          </button>}
                        </div>
                        : ''
                    }
                    open={true}
                    arrow={true}
                  >
                    <input type="text" tabIndex={5}
                      value={ean?.code ?? ''}
                      onChange={e => setEan({ ...ean, code: e.currentTarget.value })}
                      className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
                        disabled:border-slate-200 disabled:bg-white-500
                        focus:border-store-primary
                        ${ColumnControlArticle.get("ean.code", controls)?.getValidationInputClass(true, true, ean)}
                        `} />
                  </Tooltip>
                </label>

                <label className="mb-2 col-span-4 flex gap-2 items-center justify-end">
                  {/* <span className="text-xs font-medium text-grey-500 w-full flex">{t('lib_article_image')}</span> */}
                  <div
                    className="h-[54px] w-[54px] border border-slate-300 bg-[length:90%_90%] bg-no-repeat bg-center
                        focus:border-store-primary hover:border-store-primary rounded-md cursor-pointer relative"
                    style={{ backgroundImage: `url(${defaultImage})` }}
                  >
                    <input type="file" tabIndex={5} disabled
                      onChange={e => setArticle({ ...article, image: e.currentTarget.value })}
                      className={`block w-full h-full rounded-md bg-white opacity-0`} />
                    <div className="h-full w-full cursor-pointer absolute top-0 left-0"></div>
                  </div>
                </label>
              </div>

              <div className="grid grid-cols-12 gap-x-4">
                <div className="col-span-12 items-center pt-3 pb-1 h-10">
                  <div className="text-grey-500 font-bold text-sm">{t('tab_article_caisse')}</div>
                </div>
                <label className="mb-2 flex gap-x-4 pl-1 col-span-2">
                  <input type="checkbox" tabIndex={5}
                    checked={article.flPrixVenteCaisse}
                    onChange={e => setArticle({ ...article, flPrixVenteCaisse: e.currentTarget.checked })}
                    className={`h-4 w-4 mt-1 block bg-white border border-slate-300 rounded-md
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary`} />
                  <span className="font-medium text-grey-500 flex justify-between text-sm"><span>{t('lib_article_fl_prix_vente_caisse')}</span></span>
                </label>
                <label className="mb-2 flex gap-x-4 pl-1 col-span-2">
                  <input type="checkbox" tabIndex={5}
                    checked={article.flQuantiteCaisse}
                    onChange={e => setArticle({ ...article, flQuantiteCaisse: e.currentTarget.checked })}
                    className={`h-4 w-4 mt-1 block bg-white border border-slate-300 rounded-md
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary`} />
                  <span className="font-medium text-grey-500 flex justify-between text-sm"><span>{t('lib_article_fl_quantite_caisse')}</span></span>
                </label>
                <label className="mb-2 flex gap-x-4 pl-1 col-span-2">
                  <input type="checkbox" tabIndex={5}
                    checked={article.flPeseeCaisse}
                    onChange={e => setArticle({ ...article, flPeseeCaisse: e.currentTarget.checked })}
                    className={`h-4 w-4 mt-1 block bg-white border border-slate-300 rounded-md
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary`} />
                  <span className="font-medium text-grey-500 flex justify-between text-sm"><span>{t('lib_article_fl_pese_caisse')}</span></span>
                </label>
                <label className="mb-2 flex gap-x-4 pl-1 col-span-2">
                  <input type="checkbox" tabIndex={5}
                    checked={article.flTicketRestaurant}
                    onChange={e => setArticle({ ...article, flTicketRestaurant: e.currentTarget.checked })}
                    className={`h-4 w-4 mt-1 block bg-white border border-slate-300 rounded-md
                      disabled:border-slate-200 disabled:bg-white-500
                      focus:border-store-primary`} />
                  <span className="font-medium text-grey-500 flex justify-between text-sm"><span>{t('lib_article_fl_ticket_restaurant')}</span></span>
                </label>

              </div>
            </div>
          </form>

        </BlockGrid>
      </Modal>
    </>
  );
};

export default CreationArticle;