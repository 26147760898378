import { Dispatch, FunctionComponent, useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";

import { enseignesApi, departementsApi, rayonsApi, famillesApi } from 'api';
import {
  CadencierNomenclatureInsertionModel,
  DepartementWithRayonsViewModel,
  FamilleWithSousFamillesViewModel,
  RayonWithFamillesViewModel,
  SousFamilleWithUniteBesoinsViewModel
} from 'openapi-typescript-codegen';

import SelectComplete, { SelectTemplate } from 'components/SelectComplete';

import { showPrettyError } from "utils";


interface Props {
  onUpdate: (nomenclature: CadencierNomenclatureInsertionModel) => void;
}

const NomenclatureSelection: FunctionComponent<Props> = ({ onUpdate }) => {
  const { t } = useTranslation();
  const [nomenclature, setNomenclature] = useState<Partial<CadencierNomenclatureInsertionModel>>({
    fkDepartement: null,
    fkRayon: null,
    fkFamille: null,
    fkSousFamille: null
  });
  const [enseignes, setEnseignes] = useState<SelectTemplate<number>[]>([]);
  const [departements, setDepartements] = useState<(DepartementWithRayonsViewModel & SelectTemplate<number>)[]>([]);
  const [rayons, setRayons] = useState<(RayonWithFamillesViewModel & SelectTemplate<number>)[]>([]);
  const [familles, setFamilles] = useState<(FamilleWithSousFamillesViewModel & SelectTemplate<number>)[]>([]);
  const [sousFamilles, setSousFamilles] = useState<(SousFamilleWithUniteBesoinsViewModel & SelectTemplate<number>)[]>([]);

  const [selectedEnseigne, setSelectedEnseigne] = useState<number | null | undefined>(null);
  const [selectedDepartement, setSelectedDepartement] = useState<number | null | undefined>(null);
  const [selectedRayon, setSelectedRayon] = useState<number | null | undefined>(null);
  const [selectedFamille, setSelectedFamille] = useState<number | null | undefined>(null);
  const [selectedSousFamille, setSelectedSousFamille] = useState<number | null | undefined>(null);

  const getEnseignes = async () => {
    try {
      const { data } = await enseignesApi.v1EnseignesDepartementsGet();
      if (!data || !data.length) { setEnseignes([]); }
      else {
        setEnseignes(data.map(enseigne =>
          ({ value: enseigne.id, label: `${enseigne.code} - ${enseigne.nomEnseigne} (${enseigne.departements?.length || 0})` }))
        );
      }
    } catch (error) {
      console.error(error);
      showPrettyError(error);
    }
  };

  const setOptions = <T extends { id: number; code: number; },>(data: T[], dispatch: Dispatch<any>, name: keyof T, subSet?: keyof T) => {
    if (!data || !data.length) {
      dispatch([]);
    } else {
      dispatch([
        { value: 0, label: "..." },
        ...data.map(item =>
        ({
          value: item.id,
          label: `${item.code} - ${item[name]} ${subSet ? `(${(item[subSet] as any)?.length || 0})` : ''}`
        }))
      ]
      );
    }
  };

  const getDepartements = async () => {
    if (selectedEnseigne) {
      try {
        const { data } = await enseignesApi.v1EnseignesIdDepartementsRayonsGet(selectedEnseigne);
        setOptions(data, setDepartements, "nomDepartement", "rayons");
      }
      catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    } else { setDepartements([]); }
  };

  const getRayons = async () => {
    if (selectedDepartement) {
      try {
        const { data } = await departementsApi.v1DepartementsIdRayonsFamillesGet(selectedDepartement);
        setOptions(data, setRayons, "nomRayon", "familles");
      }
      catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    } else { setRayons([]); }
  };

  const getFamilles = async () => {
    if (selectedRayon) {
      try {
        const { data } = await rayonsApi.v1RayonsIdFamillesSousfamillesGet(selectedRayon);
        setOptions(data, setFamilles, "nomFamille", "sousFamilles");
      }
      catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    } else { setFamilles([]); }
  };

  const getSousFamilles = async () => {
    if (selectedFamille) {
      try {
        const { data } = await famillesApi.v1FamillesIdSousfamillesUnitebesoinsGet(selectedFamille);
        setOptions(data, setSousFamilles, "nomSf", "uniteBesoins");
      }
      catch (error) {
        console.error(error);
        showPrettyError(error);
      }
    } else { setSousFamilles([]); }
  };

  useEffect(() => {
    getEnseignes().then(() => { });
  }, []);

  useEffect(() => {
    setSelectedEnseigne(enseignes.length ? enseignes[0].value : null);
  }, [enseignes]);

  useEffect(() => {
    getDepartements().then(() => {
      setSelectedDepartement(null);
      setNomenclature({ ...nomenclature, fkEnseigne: selectedEnseigne ?? undefined, fkDepartement: null });
    });
  }, [selectedEnseigne]);

  useEffect(() => {
    getRayons().then(() => {
      setSelectedRayon(null);
      setNomenclature({ ...nomenclature, fkDepartement: selectedDepartement || null, fkRayon: null });
    });
  }, [selectedDepartement]);

  useEffect(() => {
    getFamilles().then(() => {
      setSelectedFamille(null);
      setNomenclature({ ...nomenclature, fkRayon: selectedRayon || null, fkFamille: null });
    });
  }, [selectedRayon]);

  useEffect(() => {
    getSousFamilles().then(() => {
      setSelectedSousFamille(null);
      setNomenclature({ ...nomenclature, fkFamille: selectedFamille || null, fkSousFamille: null });
    });
  }, [selectedFamille]);

  useEffect(() => {
    setNomenclature({ ...nomenclature, fkSousFamille: selectedSousFamille || null });
  }, [selectedSousFamille]);

  useEffect(() => {
    // Update the parent component with the selected nomenclature:
    onUpdate(nomenclature as CadencierNomenclatureInsertionModel);
  }, [nomenclature]);

  const renderSelectComplete = (options: SelectTemplate<number>[], selected: number | null | undefined, setSelected: Dispatch<any>, label: string, required = false) => {
    return !!options.length && (
      <label className="block col-span-1 flex-1">
        <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
          <span>{t(label)}
            {required && <span className="text-red-500">**</span>}
          </span>
        </span>
        <SelectComplete
          onChange={option => option && setSelected(option.value)}
          options={options}
          value={options.find(option => option.value === selected) || options[0]}
        />
      </label>
    );
  };

  return (
    <>
      <hr className="my-2" />
      <div className="col-span-12 items-center pt-3 pb-1 h-10">
        <div className="text-grey-500 font-bold text-sm">{t('lib_cadencier_fournisseur_nomenclature')}</div>
      </div>
      <div className="grid grid-cols-5 gap-4">
        {renderSelectComplete(enseignes, selectedEnseigne, setSelectedEnseigne, 'lib_cadencier_enseigne', true)}
        {renderSelectComplete(departements, selectedDepartement, setSelectedDepartement, 'lib_cadencier_departement')}
        {renderSelectComplete(rayons, selectedRayon, setSelectedRayon, 'lib_cadencier_rayon')}
        {renderSelectComplete(familles, selectedFamille, setSelectedFamille, 'lib_cadencier_famille')}
        {renderSelectComplete(sousFamilles, selectedSousFamille, setSelectedSousFamille, 'lib_cadencier_sous_famille')}
      </div>
    </>
  );
};

export default NomenclatureSelection;