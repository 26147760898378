import { FunctionComponent, useEffect, useState, Dispatch, SetStateAction } from "react";

import SelectComplete, { SelectTemplate } from 'components/SelectComplete';

import { techGrpeSeparationsApi, techClasseDangersApi } from 'api';
import { DangereuxViewModel, DangereuxViewModelEmballageUnitaireEnum, DangereuxViewModelGroupeEmballageEnum } from 'openapi-typescript-codegen';

import { useTranslation } from "react-i18next";
import { useCompletudeArticle } from 'context/Referencement';
import { ColumnControlArticle } from "validators";

interface Props {
  form: Partial<DangereuxViewModel>;
  setForm: Dispatch<SetStateAction<Partial<DangereuxViewModel>>>;
  activate: boolean;
  title?: string;
  hasFocus: boolean;
  loading: boolean;
}

const DangerTab: FunctionComponent<Props> = ({ form, setForm, activate, title, hasFocus, loading }) => {
  const { t } = useTranslation();
  const [GrpsSep, setGrpsSep] = useState<SelectTemplate<number | null>[]>([]);
  const [Classe, setClasse] = useState<SelectTemplate<number | null>[]>([]);
  const [grpsEmballage] = useState<SelectTemplate<DangereuxViewModelGroupeEmballageEnum>[]>(
    Object.values(DangereuxViewModelGroupeEmballageEnum).map(value => ({ value: value, label: value }))
  );
  const [emballageUnitaire] = useState<SelectTemplate<DangereuxViewModelEmballageUnitaireEnum>[]>(
    Object.values(DangereuxViewModelEmballageUnitaireEnum).map(value => ({ value: value, label: value }))
  );

  // Controls for validation and completion:
  const { state: controls } = useCompletudeArticle();

  useEffect(() => {
    controls && controls.length && ColumnControlArticle.initialize(controls);
  }, [controls]);

  const fetchGrpsSep = async (search: string) => {
    const { data: response } = await techGrpeSeparationsApi.v1TechGrpeSeparationsSearchGet(search, 1, 200);
    setGrpsSep([
      { value: null, label: "..." },
      ...response.data.map(
        (item) => ({ value: item.id, label: item.code + " - " + item.nomTechGrpeSeparation })
      )
    ]);
  };

  const fetchClass = async (search: string) => {
    const { data: response } = await techClasseDangersApi.v1TechClasseDangersSearchGet(search, 1, 200);
    setClasse([
      { value: null, label: "..." },
      ...response.data.map(
        (item) => ({ value: item.id, label: item.code + " - " + item.nomTechClasseDanger })
      )
    ]);
  };

  const initGrpsSep = async (id: number) => {
    const { data: response } = await techGrpeSeparationsApi.v1TechGrpeSeparationsIdGet(id);
    setGrpsSep([
      { value: null, label: "..." },
      { ...response, value: response.id, label: response.code + " - " + response.nomTechGrpeSeparation }
    ]);
  };

  const initClass = async (id: number) => {
    const { data: response } = await techClasseDangersApi.v1TechClasseDangersIdGet(id);
    setGrpsSep([
      { value: null, label: "..." },
      { ...response, value: response.id, label: response.code + " - " + response.nomTechClasseDanger }
    ]);
  };

  useEffect(() => {
    if (form.fkTechGrpeSeparation) initGrpsSep(form.fkTechGrpeSeparation);
    else fetchGrpsSep('');

    if (form.fkTechClasseDanger) initClass(form.fkTechClasseDanger);
    else fetchClass('');
  }, []);

  return (
    <div
      className="flex mb-1 justify-between flex-wrap border border-slate-300 rounded rounded-tl-none py-2 px-4"
      style={{ width: "calc(100% - 200px)" }}
    >
      <label className="mb-2 gap-2 w-fit bg-red-100 rounded-md pl-1.5 pr-3 h-8 flex items-center">
        <input
          type="checkbox"
          tabIndex={5}
          checked={form.flDangereux ?? false}
          onChange={e =>
            setForm({ ...form, flDangereux: e.currentTarget.checked })
          }
          disabled={!activate}
          className={`
            h-5 w-5 block bg-white-500 border accent-red-500 appearance-none border-red-500 rounded-md checkbox-danger
            ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
          `}
        />
        <span className="text-grey-500 flex justify-between font-bold">
          <span className="text-red-500">{t('lib_dangereux_fl_dangereux')}</span>
        </span>
      </label>
      <label className="mb-2 flex gap-4 w-1/2 justify-end">{title}</label>
      <div className="w-full gap-2 flex">
        <label className="block mb-2 flex-1">
          <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
            <span>
              {t('lib_dangereux_nom_officiel_transport')}
              {ColumnControlArticle.get("dangereux.nom_officiel_transport")?.getValidationLabelElement()}
            </span>
          </span>
          <input
            type="text"
            tabIndex={5}
            value={form.nomOfficielTransport ?? ''}
            onChange={e =>
              setForm({ ...form, nomOfficielTransport: e.currentTarget.value })
            }
            disabled={!activate}
            className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
              ${loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${ColumnControlArticle.get("dangereux.nom_officiel_transport")?.getValidationInputClass(activate, hasFocus, form)}
            `} />
        </label>

        <label className="block mb-2 flex-1">
          <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
            <span>
              {t('lib_dangereux_classe_danger')}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.classe_danger')?.required && <span className="text-red-500">**</span>}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.classe_danger')?.recommended && <span className="text-orange-500">*</span>}
            </span>
          </span>
          <SelectComplete tabIndex={5} isDisabled={!activate}
            options={Classe}
            value={Classe.filter(option => {
              return option.value === form.fkTechClasseDanger;
            })}
            onChange={(e) => {
              setForm({ ...form, fkTechClasseDanger: e?.value });
              fetchClass('');
            }}
            onInputChange={(e) => { fetchClass(e); }}
            classNameAdd={`
              ${!!loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.classe_danger')?.required && form.fkTechClasseDanger === null) && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.classe_danger')?.recommended && form.fkTechClasseDanger === null) && 'border-orange-500 bg-orange-500/10'}
            `}
          />
        </label>

        <label className="block mb-2 flex-1">
          <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
            <span>
              {t('lib_dangereux_groupe_emballage')}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.groupe_emballage')?.required && <span className="text-red-500">**</span>}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.groupe_emballage')?.recommended && <span className="text-orange-500">*</span>}
            </span>
          </span>
          <SelectComplete tabIndex={5} isDisabled={!activate}
            options={grpsEmballage}
            value={grpsEmballage.filter(option => {
              return option.value === form.groupeEmballage;
            })}
            onChange={(ev) => {
              setForm({ ...form, groupeEmballage: ev?.value });
            }}
            classNameAdd={`
              ${!!loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.groupe_emballage')?.required && !form.groupeEmballage) && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.groupe_emballage')?.recommended && !form.groupeEmballage) && 'border-orange-500 bg-orange-500/10'}
            `}
          />
        </label>
      </div>
      <div className="w-full gap-2 flex">
        <label className="block mb-2 flex-1">
          <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
            <span>
              {t('lib_dangereux_grpe_separation')}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.grpe_separation')?.required && <span className="text-red-500">**</span>}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.grpe_separation')?.recommended && <span className="text-orange-500">*</span>}
            </span>
          </span>
          <SelectComplete tabIndex={5} isDisabled={!activate}
            options={GrpsSep}
            value={GrpsSep.filter(option => {
              return option.value === form.fkTechGrpeSeparation;
            })}
            onChange={(e) => {
              setForm({ ...form, fkTechGrpeSeparation: e?.value });
              fetchGrpsSep('');
            }}
            onInputChange={(e) => { fetchGrpsSep(e); }}
            classNameAdd={`
              ${!!loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.grpe_separation')?.required && form.fkTechGrpeSeparation === null) && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.grpe_separation')?.recommended && form.fkTechGrpeSeparation === null) && 'border-orange-500 bg-orange-500/10'}
            `}
          />
        </label>

        <label className="block mb-2 flex-1">
          <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
            <span>
              {t('lib_dangereux_onu')}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.onu')?.required && <span className="text-red-500">**</span>}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.onu')?.recommended && <span className="text-orange-500">*</span>}
            </span>
          </span>
          <input
            type="text"
            tabIndex={5}
            value={form.onu ?? ''}
            onChange={e =>
              setForm({ ...form, onu: e.currentTarget.value })
            }
            disabled={!activate}
            className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
              ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.onu')?.required && form.onu === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.onu')?.recommended && form.onu === '') && 'border-orange-500 bg-orange-500/10'}
            `} />
        </label>

        <label className="block mb-2 flex-1">
          <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
            <span>
              {t('lib_dangereux_emballage_unitaire')}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.emballage_unitaire')?.required && <span className="text-red-500">**</span>}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.emballage_unitaire')?.recommended && <span className="text-orange-500">*</span>}
            </span>
          </span>
          <SelectComplete tabIndex={5} isDisabled={!activate}
            options={emballageUnitaire}
            value={emballageUnitaire.filter(option => {
              return option.value === form.emballageUnitaire;
            })}
            onChange={(ev) => {
              setForm({ ...form, emballageUnitaire: ev?.value });
            }}
            classNameAdd={`
              ${!!loading && ' animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.groupe_emballage')?.required && !form.emballageUnitaire) && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.groupe_emballage')?.recommended && !form.emballageUnitaire) && 'border-orange-500 bg-orange-500/10'}
            `}
          />
        </label>
      </div>
      <div className="w-full gap-2 flex">
        <label className="block mb-2 flex-1">
          <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
            <span>
              {t('lib_dangereux_nsa')}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.nsa')?.required && <span className="text-red-500">**</span>}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.nsa')?.recommended && <span className="text-orange-500">*</span>}
            </span>
          </span>
          <input
            type="text"
            tabIndex={5}
            value={form.nsa ?? ''}
            onChange={e =>
              setForm({ ...form, nsa: e.currentTarget.value })
            }
            disabled={!activate}
            className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
              ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.nsa')?.required && form.nsa === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.nsa')?.recommended && form.nsa === '') && 'border-orange-500 bg-orange-500/10'}
            `} />
        </label>

        <label className="block mb-2 flex-1">
          <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
            <span>
              {t('lib_dangereux_declaration_conditionnement')}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.declaration_conditionnement')?.required && <span className="text-red-500">**</span>}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.declaration_conditionnement')?.recommended && <span className="text-orange-500">*</span>}
            </span>
          </span>
          <input
            type="text"
            tabIndex={5}
            value={form.declarationConditionnement ?? ''}
            onChange={e =>
              setForm({
                ...form,
                declarationConditionnement: e.currentTarget.value,
              })
            }
            disabled={!activate}
            className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
              ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.declaration_conditionnement')?.required && form.declarationConditionnement === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.declaration_conditionnement')?.recommended && form.declarationConditionnement === '') && 'border-orange-500 bg-orange-500/10'}
            `} />
        </label>
        <label className="block mb-2 flex-1">
          <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
            <span>
              {t('lib_dangereux_fiche_securite')}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.fiche_securite')?.required && <span className="text-red-500">**</span>}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.fiche_securite')?.recommended && <span className="text-orange-500">*</span>}
            </span>
          </span>
          <input
            type="text"
            tabIndex={5}
            value={form.ficheSecurite ?? ''}
            onChange={e =>
              setForm({ ...form, ficheSecurite: e.currentTarget.value })
            }
            disabled={!activate}
            className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
              ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.fiche_securite')?.required && form.ficheSecurite === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.fiche_securite')?.recommended && form.ficheSecurite === '') && 'border-orange-500 bg-orange-500/10'}
            `} />
        </label>
      </div>
      <div className="w-full gap-2 flex">
        <label className="block mb-2 flex-1">
          <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
            <span>
              {t('lib_dangereux_point_eclair')}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.point_eclair')?.required && <span className="text-red-500">**</span>}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.point_eclair')?.recommended && <span className="text-orange-500">*</span>}
            </span>
          </span>
          <input
            type="text"
            tabIndex={5}
            value={form.pointEclair ?? ''}
            onChange={e =>
              setForm({ ...form, pointEclair: e.currentTarget.value })
            }
            disabled={!activate}
            className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
              ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.point_eclair')?.required && form.pointEclair === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.point_eclair')?.recommended && form.pointEclair === '') && 'border-orange-500 bg-orange-500/10'}
            `} />
        </label>

        <label className="block mb-2 flex-1">
          <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
            <span>
              {t('lib_dangereux_declaration_contenance')}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.declaration_contenance')?.required && <span className="text-red-500">**</span>}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.declaration_contenance')?.recommended && <span className="text-orange-500">*</span>}
            </span>
          </span>
          <input
            type="text"
            tabIndex={5}
            value={form.declarationContenance ?? ''}
            onChange={e =>
              setForm({
                ...form,
                declarationContenance: e.currentTarget.value,
              })
            }
            disabled={!activate}
            className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
              ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.declaration_contenance')?.required && form.declarationContenance === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.declaration_contenance')?.recommended && form.declarationContenance === '') && 'border-orange-500 bg-orange-500/10'}
            `} />
        </label>
        <label className="block mb-2 flex-1">
          <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
            <span>
              {t('lib_dangereux_soins_medicaux')}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.soins_medicaux')?.required && <span className="text-red-500">**</span>}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.soins_medicaux')?.recommended && <span className="text-orange-500">*</span>}
            </span>
          </span>
          <input
            type="text"
            tabIndex={5}
            value={form.soinsMedicaux ?? ''}
            onChange={e =>
              setForm({ ...form, soinsMedicaux: e.currentTarget.value })
            }
            disabled={!activate}
            className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
              ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.soins_medicaux')?.required && form.soinsMedicaux === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.soins_medicaux')?.recommended && form.soinsMedicaux === '') && 'border-orange-500 bg-orange-500/10'}
            `} />
        </label>
      </div>
      <div className="w-full gap-2 flex">
        <label className="block mb-2 flex-1">
          <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
            <span>
              {t('lib_dangereux_explosivite')}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.explosivite')?.required && <span className="text-red-500">**</span>}
              {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.explosivite')?.recommended && <span className="text-orange-500">*</span>}
            </span>
          </span>
          <input
            type="text"
            tabIndex={5}
            value={form.explosivite ?? ''}
            onChange={e =>
              setForm({ ...form, explosivite: e.currentTarget.value })
            }
            disabled={!activate}
            className={`h-8 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
              disabled:border-slate-200 disabled:bg-white-500
              focus:border-store-primary
              ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.explosivite')?.required && form.explosivite === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
              ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.explosivite')?.recommended && form.explosivite === '') && 'border-orange-500 bg-orange-500/10'}
            `} />
        </label>
        <span className="flex-1"></span>

        <div className="flex flex-col flex-1" >
          <label className="mb-2 flex gap-4 pl-1">
            <input
              type="checkbox"
              tabIndex={5}
              checked={form.flPolluantMarin ?? false}
              onChange={e =>
                setForm({ ...form, flPolluantMarin: e.currentTarget.checked })
              }
              disabled={!activate}
              className={`h-4 w-4 mt-1 block bg-white border border-slate-300 rounded-md
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              `}
            />
            <span className="font-medium text-grey-500 flex justify-between">
              <span>{t('lib_dangereux_fl_polluant_marin')}</span>
            </span>
          </label>
          <label className="mb-2 flex gap-4 pl-1">
            <input
              type="checkbox"
              tabIndex={5}
              checked={form.flQteLimitee ?? false}
              onChange={e =>
                setForm({ ...form, flQteLimitee: e.currentTarget.checked })
              }
              disabled={!activate}
              className={`h-4 w-4 mt-1 block bg-white border border-slate-300 rounded-md
                disabled:border-slate-200 disabled:bg-white-500
                focus:border-store-primary
                ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
              `}
            />
            <span className="font-medium text-grey-500 flex justify-between">
              <span>{t('lib_dangereux_fl_qte_limitee')}</span>
            </span>
          </label>
        </div>
      </div>
      <label className="block mb-2 w-full">
        <span className="text-xs font-medium text-grey-500 w-full flex justify-between">
          <span>
            {t('lib_dangereux_commentaire_dangereux')}
            {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.commentaire_dangereux')?.required && <span className="text-red-500">**</span>}
            {form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.commentaire_dangereux')?.recommended && <span className="text-orange-500">*</span>}
          </span>
        </span>
        <textarea
          tabIndex={5}
          value={form.commentaireDangereux ?? ''}
          onChange={e =>
            setForm({ ...form, commentaireDangereux: e.currentTarget.value })
          }
          disabled={!activate}
          className={`h-10 mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm placeholder-slate-400
            disabled:border-slate-200 disabled:bg-white-500
            focus:border-store-primary
            ${!!loading && 'animate-pulse border-slate-200 bg-gray-200 disabled:bg-gray-200'}
            ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.commentaire_dangereux')?.required && form.commentaireDangereux === '') && `${!!(activate || (!activate && hasFocus)) && 'border-red-500'} ${!!activate && 'bg-red-500/10'} ${!!hasFocus && 'disabled:border-red-500'}`}
            ${!!(form.flDangereux && controls?.find((i) => i.colonneArticle === 'dangereux.commentaire_dangereux')?.recommended && form.commentaireDangereux === '') && 'border-orange-500 bg-orange-500/10'}
          `}
        />
      </label>
    </div>
  );
};

export default DangerTab;