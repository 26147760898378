import { FC, ReactNode, createContext, useContext, useState, useEffect, useCallback } from 'react';
import {ResultatCompletudeArticlePropertyViewModel, ViewNomenclatureArticleViewModel} from "openapi-typescript-codegen";
import { articlesApi } from "api";
import { useEnseigne, useArticle, useUpdateArticle } from 'context/Referencement';
import { UpdateEnum } from 'context/Referencement/updateArticle';
import {CompletudeArticleResultModel} from "openapi-typescript-codegen/types/models";
import { api } from "utils";
import { ColumnControlArticle } from "validators";
type State = ResultatCompletudeArticlePropertyViewModel[] | null;

const CompletudeArticleContext = createContext<{ state: State; } | undefined>(undefined);

const CompletudeArticleProvider: FC<{ children?: ReactNode; }> = ({ children }) => {
  const [state, dispatch] = useState<State>(null);
  const value = { state };
  const { state: enseigne } = useEnseigne();
  const { state: article } = useArticle();
  const [nomenclatureArticle, setNomenclatureArticle] = useState<ViewNomenclatureArticleViewModel>();
  const [completudeArticle, setCompletudeArticle] = useState<Partial<CompletudeArticleResultModel>>({});

  const refreshNomenclatures = async () => {
    let $nomenclaturesArticles: ViewNomenclatureArticleViewModel[] = [];
    if (article && article.id) {
      $nomenclaturesArticles = await api.dataset.get(articlesApi.v1ArticlesIdNomenclatureArticlesGet(article?.id));
    }
    const $nomenclatureArticle = $nomenclaturesArticles.find((nomenclature) => nomenclature.fkEnseigne === enseigne?.id) || $nomenclaturesArticles[0];
    setNomenclatureArticle($nomenclatureArticle);
  };

  const patchCompletude = async (id: number) => {
    const enseigneId = nomenclatureArticle?.fkEnseigne;
    if (enseigneId) {
      const $completudeArticle = await api.data.get<CompletudeArticleResultModel>(articlesApi.v1ArticlesIdCompletudePatch(id, { enseigneId }));
      setCompletudeArticle($completudeArticle);
    }
  };

  const initializeControls = async (id: number) => {
    const enseigneId = nomenclatureArticle?.fkEnseigne;
    if (enseigneId) {
      const results = await api.dataset.get<ResultatCompletudeArticlePropertyViewModel>(articlesApi.v1ArticlesIdEnseignesEnseigneIdCompletudePropertiesGet(id, enseigneId));
      ColumnControlArticle.initialize(results);
      dispatch(results);
    }
  };

  useEffect(() => {
    if (Object.entries(completudeArticle).length) { article?.id &&  initializeControls(article.id); }
  }, [completudeArticle]);

  useEffect(() => {
    article?.id && patchCompletude(article.id);
  }, [nomenclatureArticle]);

  useEffect(() => {
    article?.id && refreshNomenclatures();
  }, [article?.id, enseigne?.id]);

  const refreshCompletude = useCallback(($event: any) => {
    // We refresh the nomenclature only if an event for it is triggered. In any case, we patch and refresh the completude.
    $event === UpdateEnum.nomenclature ?  article?.id && refreshNomenclatures() : article?.id && patchCompletude(article.id);
  }, [article?.id, enseigne?.id]);
  useUpdateArticle(refreshCompletude);

  return <CompletudeArticleContext.Provider value={value}>{children}</CompletudeArticleContext.Provider>;
};

function useCompletudeArticle() {
  const context = useContext(CompletudeArticleContext);
  if (context === undefined) {
    throw new Error('useCompletudeArticle must be used within a CompletudeArticleProvider');
  }

  return context;
}

export { CompletudeArticleProvider, useCompletudeArticle };