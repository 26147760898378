import { FunctionComponent, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { Person } from '@mui/icons-material'

import { useAuth } from 'hooks'

interface Props {
  children?: ReactNode
  className?: string
}

const AccountControls: FunctionComponent<Props> = ({children, className}) => {
  const location = useLocation()
  const auth = useAuth()

  const defaultClass = "rounded-lg h-12 w-12 flex justify-center items-center overflow-hidden border transition-all hover:scale-110 hover:shadow focus:scale-110 focus:shadow"

  return (
    <>
      {!auth.isLoggedIn && (
        <button className={className || `${defaultClass} border-store-primary`}
          onClick={() => auth.logIn(location.pathname)}>
          { children || <Person /> }
        </button>
      )}
      {auth.isLoggedIn && (
        <button className={className || `${defaultClass} border-white-500 hover:border-store-primary`}
          onClick={auth.logOut}>
          { children ||
            <img
              className="object-cover h-12 w-12"
              src="/assets/images/profile.png"
              alt=""
            />
          }
        </button>
      )}
    </>
  );
};

export default AccountControls