import { CellValueChangedEvent, ColDef, Column, ColumnApi, GridApi, GridOptionsWrapper, ICellEditor, ICellEditorParams, RowNode, RowValueChangedEvent } from "ag-grid-community";
import { ComponentType, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { TypeEanViewModel } from "openapi-typescript-codegen";
import { Tooltip } from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { DateTime } from "luxon";
import { components, OptionProps } from "react-select";

import { eanValidator } from "validators";
import { useResize } from "hooks";
import { getPrettyError, fixFloating, trimString, isArray } from "utils";
import SelectComplete, { SelectTemplate } from "components/SelectComplete";

/* Types */

type EditorCallbackFunction<R = string, TData = any, TValue = any> =
  R |
  ((cellValue: TValue, params: ICellEditorParams<TData, TValue>) => R);

/* CellEditors */

export type NumberCellEditorProps<TData = any> = {
  min?: EditorCallbackFunction<number, TData>,
  max?: EditorCallbackFunction<number, TData>;
  floating?: EditorCallbackFunction<number, TData>;
};

export const NumberCellEditor = forwardRef((props: NumberCellEditorProps & ICellEditorParams, ref) => {
  const [value, setValue] = useState(clearRawNumber(props.value));
  const refInput = useRef<HTMLInputElement | null>(null);
  const focus = () => {
    refInput.current?.focus();
    refInput.current?.select();
  };
  const getMin = () => typeof props.min === 'function' ? props.min(props.value, props) : props.min;
  const getMax = () => typeof props.max === 'function' ? props.max(props.value, props) : props.max;

  useEffect(() => {
    if (props.cellStartedEdit) focus();
  }, []);

  useImperativeHandle(ref, (): ICellEditor => {
    return {
      getValue: () => getNumberFromValue(value),
      focusIn: () => focus(),
    };
  });

  function getNumberFromValue(_value: string): number {
    return parseFloat(_value.replaceAll(',', '.'));
  }

  function clearRawNumber(rawNumber: unknown): string {
    let val = (rawNumber ? String(rawNumber) : '').trim().replaceAll(',', '.');
    if (val === '0-') val = '-0';
    const match = /(-){0,1}(?:0*)(\d+([,.]\d*){0,1})/mi.exec(val);
    return match?.[2] ? (match?.[1] ?? '') + match?.[2] : '0';
  }

  function addToValue(num: number) {
    const newVal = getNumberFromValue(value);
    onChange(newVal + num);
    focus();
  }

  function onChange(event: unknown) {
    let eventValue = clearRawNumber(event);
    const newVal = getNumberFromValue(eventValue);
    const min = getMin();
    const max = getMax();
    if (typeof min === 'number' && newVal < min) eventValue = clearRawNumber(min);
    if (typeof max === 'number' && newVal > max) eventValue = clearRawNumber(max);
    if (typeof props.floating === 'number') {
      const newFloating = fixFloating(newVal, props.floating);
      if (newFloating !== newVal) eventValue = clearRawNumber(newFloating);
    }
    setValue(eventValue);
  }

  return (
    <div className="w-full relative">
      <input type="text" ref={refInput} className="w-full outline-none pl-2"
        inputMode="decimal"
        formNoValidate
        min={getMin()}
        max={getMax()}
        value={value}
        onChange={event => onChange(event.target.value)}
      />
      <div className="absolute top-1/2 right-0 w-4 h-7 flex flex-col opacity-90 overflow-hidden rounded-sm" style={{ transform: 'translateY(-50%)' }}>
        <div className="w-full h-1/2 flex items-center justify-center bg-gray-300 hover:bg-gray-400 cursor-pointer"
          onClick={() => addToValue(1)}
        >
          <ArrowDropUp className="text-gray-500" />
        </div>
        <div className="w-full h-1/2 flex items-center justify-center bg-gray-300 hover:bg-gray-400 cursor-pointer"
          onClick={() => addToValue(-1)}
        >
          <ArrowDropDown className="text-gray-500" />
        </div>
      </div>
    </div>
  );
});


export type TextCellEditorProps = { maxLength?: number; toUpperCase?: boolean; };

export const TextCellEditor = forwardRef((props: TextCellEditorProps & ICellEditorParams, ref) => {
  const [value, setValue] = useState(clearRawText(props.value));
  const refInput = useRef<HTMLInputElement | null>(null);
  const focus = () => {
    refInput.current?.focus();
    refInput.current?.select();
  };

  useEffect(() => {
    if (props.cellStartedEdit) focus();
  }, []);

  useImperativeHandle(ref, (): ICellEditor => {
    return {
      getValue: () => value,
      focusIn: () => focus(),
    };
  });

  function clearRawText(rawText: unknown): string {
    return (rawText ? String(rawText) : '').trim();
  }

  function onChange($value: string) {
    $value = clearRawText($value);
    $value = typeof props.maxLength === 'number' && $value.length > props.maxLength ? $value.slice(0, props.maxLength) : $value;
    setValue(props.toUpperCase ? $value.toUpperCase() : $value);
  }

  return (
    <div className="w-full relative">
      <input type="text" ref={refInput} className="w-full outline-none pl-2"
        formNoValidate
        value={value}
        onChange={event => onChange(event.target.value)}
      />
    </div>
  );
});

export type DatePickerCellEditorProps = { min?: string | DateTime, max?: string | DateTime; };

export const DatePickerCellEditor = forwardRef((props: DatePickerCellEditorProps & ICellEditorParams, ref) => {
  const defaultValue = DateTime.fromISO(props.value).toISODate() || '';
  const [value, setValue] = useState(defaultValue);
  const refInput = useRef<HTMLInputElement>(null);
  const min = (DateTime.isDateTime(props.min)) ? props.min.toISODate() : props.min;
  const max = (DateTime.isDateTime(props.max)) ? props.max.toISODate() : props.max;

  const focus = () => {
    refInput.current?.focus();
    refInput.current?.select();
  };

  useEffect(() => {
    if (props.cellStartedEdit) focus();
  }, []);

  useImperativeHandle(ref, (): ICellEditor => {
    return {
      getValue: getValue,
      focusIn: () => focus(),
    };
  });

  const getValue = () => {
    if (value === defaultValue)
      return props.value;

    return DateTime.fromISO(value).toISODate();
    // return DateTime.fromISO(value).toUTC().toISO({ suppressMilliseconds: true }); // ?.replace('Z', '+00:00');
  };

  return (
    <input type="date" ref={refInput} className="w-full h-full text-center px-1"
      value={value} onChange={event => setValue(event.target.value || '')}
      min={min} max={max}
    />
  );
});

export type TimeCellEditorProps = {};

export const TimeCellEditor = forwardRef((props: TimeCellEditorProps & ICellEditorParams, ref) => {
  const defaultValue = props.value ? String(props.value).slice(0, 5) || '' : '';
  const [value, setValue] = useState(defaultValue);
  const refInput = useRef<HTMLInputElement>(null);
  const focus = () => {
    refInput.current?.focus();
    refInput.current?.select();
  };

  useEffect(() => {
    if (props.cellStartedEdit) refInput.current?.focus();
  }, []);

  useImperativeHandle(ref, (): ICellEditor => {
    return {
      getValue: getValue,
      focusIn: () => focus(),
    };
  });

  const getValue = () => String(value).slice(0, 5);

  return (
    <input type="time" ref={refInput} className="w-full h-full text-center px-1"
      value={value} onChange={event => setValue(event.target.value || '')}
    />
  );
});


export type SelectorCellEditorProps = { values?: EditorCallbackFunction<{ value: any, label: string; }[]>; };

export const SelectorCellEditor = forwardRef((props: SelectorCellEditorProps & ICellEditorParams, ref) => {
  const [optionsList] = useState(typeof props.values === 'function' ? props.values(props.value, props) : props.values || []);
  const defaultValue = optionsList.findIndex(v => v.value === props.value);
  const [localIndex, setLocalIndex] = useState(defaultValue >= 0 ? defaultValue : 0);
  const refInput = useRef<HTMLSelectElement | null>(null);

  const focus = () => {
    refInput.current?.focus();
  };

  useEffect(() => {
    if (props.cellStartedEdit) focus();
  }, []);

  useEffect(() => {
    if (defaultValue === localIndex) return;
    const newValue = optionsList[localIndex]?.value;
    overwriteSetCellData(newValue, props);
  }, [localIndex]);

  useImperativeHandle(ref, (): ICellEditor => {
    return {
      getValue: () => null,
      isCancelAfterEnd: () => true,
      focusIn: () => focus(),
    };
  });

  return (
    <div className="w-full h-full">
      <select required
        className="block w-full h-full text-sm leading-normal bg-white border border-slate-300 rounded-[2px] placeholder-slate-400 focus:border-store-primary"
        ref={refInput}
        value={localIndex}
        onChange={event => {
          if (event.target.value) setLocalIndex(parseInt(event.target.value, 10) || 0);
        }}>
        {
          optionsList.map((choice, index) => <option key={index} value={index}>{choice.label}</option>)
        }
      </select>
    </div>
  );
});

export interface AutoCompleteOption extends SelectTemplate {
  filterKeyArr?: (string | number | null | undefined)[];
  prefix?: string;
  [key: string]: any;
}
export type AutoCompleteCellEditorProps = {
  localSearchOnly?: boolean;
  canBeEmpty?: boolean;
  isMulti?: boolean;
  searchData: (search: string) => Promise<AutoCompleteOption[]>;
  render?: ComponentType<OptionProps<AutoCompleteOption>>;
};

export const AutoCompleteCellEditor = forwardRef((props: AutoCompleteCellEditorProps & ICellEditorParams, ref) => {
  const componentMounted = useRef(true);
  const currentFetch = useRef<NodeJS.Timeout | null>(null);
  const [value, setValue] = useState(props.value);
  const [options, setOptions] = useState<AutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(0);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const parentSize = useResize(props.eGridCell);

  useEffect(() => {
    fetchData();
    return () => { componentMounted.current = false; };
  }, []);

  useEffect(() => {
    onResize(parentSize);
  }, [parentSize]);

  const selectOptions = useMemo((): AutoCompleteOption[] => {
    const parsedOptions = options.map((option): AutoCompleteOption => {
      if (Array.isArray(option.filterKeyArr)) {
        const filterValuesSet = new Set(option.filterKeyArr.filter(val => val != null).map(String).map(trimString));
        option.filterKey = [...filterValuesSet].join(' ');
      }
      return { ...option };
    });
    if (props.canBeEmpty !== false) parsedOptions.unshift({ value: null, label: "..." });
    return parsedOptions;
  }, [options]);

  useImperativeHandle(ref, (): ICellEditor => {
    return {
      getValue: () => value,
      isPopup: () => true,
      // focusIn: () => focus(), // TODO
    };
  });

  async function fetchData(search?: string) {
    setIsLoading(old => old + 1);
    try {
      const data = await props.searchData(search || '');
      if (!componentMounted.current) return;
      if (data.length) setOptions(data);
    } catch (err) {
      console.warn(getPrettyError(err));
    }
    setIsLoading(old => old - 1);
  }

  function onResize(newSize: { x: number, y: number; }) {
    if (!wrapperRef.current) return;
    wrapperRef.current.style.width = newSize.x.toString() + 'px';
  }

  function onChange(selectOption: readonly AutoCompleteOption[] | AutoCompleteOption | null) {
    if (!selectOption) return;
    let newValue;
    if (isArray(selectOption)) {
      newValue = selectOption.map(v => v.value);
    } else {
      newValue = selectOption.value;
    }
    setValue(newValue);
    overwriteSetCellData(newValue, props);
  }

  const OptionComponent = useCallback(({ children, ...locProps }: OptionProps<AutoCompleteOption>) => {
    if (props.render)
      return (
        <components.Option {...locProps} >
          <props.render children={children} {...locProps}></props.render>
        </components.Option>
      );
    return (
      <components.Option {...locProps} >
        <>
          {!!locProps.data.prefix &&
            <>
              <span className="opacity-70 text-sm font-bold">{locProps.data.prefix}</span>
              <span> - </span>
            </>
          }
          {children}
        </>
      </components.Option>
    );
  }, [props.render]);

  return (
    <div ref={wrapperRef} className="w-28">
      <SelectComplete
        options={selectOptions}
        value={selectOptions.find(option => option.value === value)}
        onChange={(e) => onChange(e)}
        onInputChange={(newValue, actionMeta) => {
          if (props.localSearchOnly || actionMeta.action !== 'input-change') return;
          if (currentFetch.current !== null) {
            clearTimeout(currentFetch.current);
          }
          currentFetch.current = setTimeout(() => fetchData(newValue), 500);
        }}
        isMulti={props.isMulti}
        components={{ Option: OptionComponent }}
        classNameEdit="h-10"
        isLoading={!!isLoading}
        menuShouldBlockScroll={true}
        menuPlacement="top"
        menuPosition="fixed"
        menuPortalTarget={globalThis.document.body}
      // https://github.com/JedWatson/react-select/issues/4680
      />
    </div>
  );
});

type EANCellEditorProps = { eanTypes: TypeEanViewModel[]; };

export const EANCellEditor = forwardRef((props: EANCellEditorProps & ICellEditorParams, ref) => {
  const [value, setValue] = useState<string>(props.value || '');
  const { parsedEAN, errorMsg: eanError, isRevised } = eanValidator(value, props.data.fkTypeEan, props.eanTypes);

  const refInput = useRef<HTMLInputElement | null>(null);
  const focus = () => {
    refInput.current?.focus();
    refInput.current?.select();
  };

  useEffect(() => {
    if (props.cellStartedEdit) refInput.current?.focus();
  }, []);

  useImperativeHandle(ref, (): ICellEditor => {
    return {
      getValue: () => value,
      focusIn: () => focus(),
    };
  });

  return (
    <Tooltip
      title={
        eanError
          ? <div className="flex flex-col text-xs">
            <span>{eanError || ''}</span>
            {isRevised && <button
              className="bg-blue-store text-white-500 rounded py-1 my-1"
              onClick={() => setValue(parsedEAN!)}>
              Changer l'EAN
            </button>}
          </div>
          : ''
      }
      open={true}
      arrow={true}
    >
      <input type="text" ref={refInput} className={`w-full h-full outline-none pl-2 border rounded ${eanError ? 'border-red-600' : 'border-transparent'}`}
        value={value} onChange={event => setValue(event.target.value)}
      />
    </Tooltip>
  );
});

/* Utils */

interface ICustomCellChangedParams {
  api: GridApi;
  columnApi: ColumnApi;
  context: unknown;
  value?: unknown;
  column: Column;
  colDef: ColDef;
  node: RowNode;
  data?: unknown;
  rowIndex?: number | null;
}

export function overwriteSetCellData(newValue: unknown, params: ICustomCellChangedParams) {
  const data = params.data ?? params.node.data;
  const oldValue = params.colDef.field ? data[params.colDef.field] : params.value;
  data[params.colDef.field!] = newValue;

  const cellValueChangedEvent: CellValueChangedEvent = {
    api: params.api,
    colDef: params.colDef,
    column: params.column,
    columnApi: params.columnApi,
    context: params.context,
    data: data ?? params.node.data,
    event: null,
    newValue: newValue,
    node: params.node,
    oldValue: oldValue,
    rowIndex: params.rowIndex ?? params.node.rowIndex,
    rowPinned: params.node.rowPinned ?? null,
    source: undefined,
    type: 'cellValueChanged',
    value: newValue,
  };
  params.api.dispatchEvent(cellValueChangedEvent);

  const gridOptionsWrapper = (params.api as any).gridOptionsWrapper as GridOptionsWrapper;
  if (gridOptionsWrapper.isFullRowEdit()) {
    const rowValueChangedEvent: RowValueChangedEvent = {
      api: params.api,
      columnApi: params.columnApi,
      context: params.context,
      data: data ?? params.node.data,
      event: null,
      node: params.node,
      rowIndex: params.rowIndex ?? params.node.rowIndex,
      rowPinned: params.node.rowPinned ?? null,
      type: 'rowValueChanged',
    };
    params.api.dispatchEvent(rowValueChangedEvent);
  }

  params.api.refreshCells({ rowNodes: [params.node], force: true, suppressFlash: true });

  // Use this if you are 'au bout du rouleau' and don't find other solution.
  // setTimeout(() => {
  //   params.api.stopEditing(false);
  //   setTimeout(() => {
  //     params.api.startEditingCell({ colKey: params.column, rowIndex: params.rowIndex });
  //   }, 10);
  // }, 10);

  /// Events flow on rowEditingStopped
  /// PER CELL
  // cellValueChanged
  // cellEditingStopped

  // rowValueChanged
  // rowEditingStopped

  /// PER CELL
  // modelUpdated
  // paginationChanged
  // displayedRowsChanged
  // rowDataUpdated
  // displayedRowsChanged
}