
import { ReactElement, useMemo } from "react";
import Select, { Props, components as selectComponents, StylesConfig, CSSObjectWithLabel, GroupBase, createFilter } from 'react-select';
import cs from 'classnames';

import { trimString } from "utils/index";

import Icon from 'assets/icons/ChevronBottom.svg';

export interface SelectTemplate<T = unknown> {
  value: T;
  label?: string;
  filterKey?: string;
  filterKeys?: (string | number | null | undefined)[];
  isDisabled?: boolean;
  data?: { [key: string]: any };
}

type CutomeSelectProps = {
  indicator?: boolean;
  placeholderStyles?: CSSObjectWithLabel;
  menuStyles?: CSSObjectWithLabel;
  isRequired?: boolean;
  classNameAdd?: string;
  classNameEdit?: string;
};

function SelectComplete<
  Option extends SelectTemplate,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group> & CutomeSelectProps): ReactElement {
  const { options, indicator = true, placeholderStyles, menuStyles, components, isRequired, classNameAdd, classNameEdit, ...otherProps } = props;

  const customStyles: StylesConfig<Option, IsMulti, Group> = {
    container: (base, _state) => ({
      ...base,
      borderColor: isRequired ? 'red' : undefined
    }),
    control: (_base, _state) => ({
      width: '100%',
      margin: '0',
      padding: '0',
      height: '100%',
      display: 'flex'
    }),
    valueContainer: (base, _state) => ({
      ...base,
      margin: '0',
      padding: '0',
      height: '100%'
    }),
    singleValue: (base, _state) => ({
      ...base,
      marginLeft: '0',
      marginRight: '0',
      color: 'black',
    }),
    multiValue: (base, _state) => ({
      ...base,
      marginLeft: '0',
      marginRight: '0',
    }),
    input: (base, _state) => ({
      ...base,
      margin: '0',
      padding: '0',
      height: '100%'
    }),
    placeholder: (base, _state) => ({
      ...base,
      marginLeft: '0',
      marginRight: '0',
      ...placeholderStyles
    }),
    indicatorSeparator: (_base, _state) => ({
      display: 'none',
    }),
    indicatorsContainer: (base, _state) => ({
      ...base,
      display: !indicator ? 'none' : 'flex',
      alignItems: 'center',
      "&> div": {
        paddingRight: 0,
      }
    }),
    menu: (base, _state) => ({
      ...base,
      margin: '0',
      marginLeft: '-12px',
      padding: '0',
      width: 'fit-content',
      minWidth: '100%',
      ...menuStyles
    }),
    menuList: (base, _state) => ({
      ...base,
      margin: '0',
      padding: '0',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis'
    }),
    menuPortal: (base, _state) => ({
      ...base,
      zIndex: 9999,
    }),
    option: (base, _state) => ({
      ...base,
      cursor: 'pointer',
    }),
  };

  const DropdownIndicator = (props: any) => {
    return (
      <selectComponents.DropdownIndicator {...props}>
        <img src={Icon} alt="chevron bottom" className="w-3 h-3" />
      </selectComponents.DropdownIndicator>
    );
  };

  const wrapOptions = useMemo(() => {
    if (!options || !options.length) return options;

    return options.map((optOrGroup) => {
      const opt = optOrGroup as SelectTemplate;

      if (!Array.isArray(opt.filterKeys))
        return optOrGroup;

      const filterValuesSet = new Set(opt.filterKeys.filter(val => val != null).map(String).map(trimString));
      return { ...optOrGroup, filterKey: [...filterValuesSet].join(' ') };
    });
  }, [options]);

  return (
    <div className="w-full block relative h-fit">
      <Select
        styles={customStyles}
        className={cs(
          'block w-full px-3 border border-slate-300 rounded-md text-sm placeholder-slate-400 disabled:border-slate-200 disabled:bg-white-500 bg-white-500 focus:border-store-primary',
          classNameEdit || 'h-8 mt-1',
          classNameAdd
        )}
        placeholder="..."
        noOptionsMessage={() => 'Aucun résultat'}
        isSearchable={true}
        filterOption={createFilter({
          ignoreCase: true,
          ignoreAccents: true,
          stringify: (opt) => opt.data.filterKey || `${opt.label} ${opt.value}`,
          trim: true,
          matchFrom: 'any',
        })}
        {...otherProps}
        components={{ ...components, DropdownIndicator }}
        options={wrapOptions}
      />
    </div>
  );
}

export default SelectComplete;