import { FC, useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GroupCellRenderer } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import {
  ConditionnementInsertionModel,
  ConditionnementViewModel,
  ConditionnementWithTypeViewModel
} from 'openapi-typescript-codegen';

import { articlesApi, conditionnementsApi, typeConditionnementsApi } from 'api';
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { useGridController } from "hooks/";
import { useArticle } from "context/Referencement";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { IconCellRenderer, NumberCellRenderer } from "components/AGGride/CellRender";
import { AutoCompleteCellEditor, AutoCompleteCellEditorProps, NumberCellEditor } from "components/AGGride/CellEditor";
import { generateRandomUint } from "utils/random";
import { notEmptyCellValidator, notNilCellValidator } from "components/AGGride/cellValidator";
import { editableIfNew } from "components/AGGride/cellEditable";

// import articleIcon from "assets/icons/article-logistics/article.svg";
// import cartonIcon from "assets/icons/article-logistics/carton.svg";
// import colisIcon from "assets/icons/article-logistics/colis.svg";
// import couchePaletteIcon from "assets/icons/article-logistics/couche-palette.svg";
import paletteIcon from "assets/icons/article-logistics/palette.svg";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 90,
};

type GridType = Partial<ConditionnementWithTypeViewModel> & {
  path: string[];
  code_tarif?: number;
  lieux_stockage?: { name: string, color: string; }[];
};

const Logistique: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const [activate, setActivate] = useState(false);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {
        // autoResize: true,
      },
      autoFetch: true,
      emptyRowCheck: (params) => !params.code || !params.fkTypeConditionnement,
      fetchData: async () => {
        if (!article?.id) return [];
        const { data } = await articlesApi.v1ArticlesIdConditionnementsGet(article.id);
        const fullHierarchie = [...new Set(data.map(v => v.typeConditionnement?.hierarchie || 0))].sort();

        return (data ?? []).map(val => {
          const path = fullHierarchie.slice(0, fullHierarchie.indexOf(val.typeConditionnement?.hierarchie || 0) + 1).map(String);
          return { ...val, path: path };
        });
      },
      postData: (cleanRow) => conditionnementsApi.v1ConditionnementsPost(cleanRow as ConditionnementInsertionModel),
      putData: (cleanRow) => conditionnementsApi.v1ConditionnementsPut(cleanRow as ConditionnementViewModel),
      deleteData: (dataId) => conditionnementsApi.v1ConditionnementsIdDelete(dataId),
      getNewModel: () => ({
        path: [generateRandomUint().toString()],
        fkArticle: article?.id,
      }),
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'fkTypeConditionnement',
          headerName: t('lib_conditionnement_type'),
          headerTooltip: t('lib_conditionnement_type'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          pinned: 'left',
          minWidth: 250,
          showRowGroup: true,
          valueFormatter: (params) => `${params.data?.typeConditionnement?.nomTypeConditionnement} (${params.data?.typeConditionnement?.code})`,
          cellRenderer: GroupCellRenderer,
          cellRendererParams: {
            suppressCount: true,
            innerRenderer: IconCellRenderer,
            innerRendererParams: {
              href: paletteIcon,
            }
          },
          editable: (params) => editableIfNew(params),
          cellEditorPopup: true,
          cellEditor: AutoCompleteCellEditor,
          cellEditorParams: {
            searchData: (async (search) => {
              const code = parseFloat(search) || undefined;
              const { data: res } = await typeConditionnementsApi.v1TypeConditionnementsSearchPost(
                true, 1, 20, { nomTypeConditionnement: search, code: code }
              );
              const data = res.data || [];
              return data.map(val => ({
                value: val.id,
                prefix: val.code?.toString(),
                label: val.nomTypeConditionnement,
                filterKeyArr: [val.code, val.nomTypeConditionnement]
              }));
            }),
          } as AutoCompleteCellEditorProps,
          valueValidator: [notNilCellValidator],
        },
        // {
        //   field: 'ean',
        //   headerName: t('lib_conditionnement_ean'),
        //   headerTooltip: t('lib_conditionnement_ean'),
        //   pinned: 'left',
        //   minWidth: 270,
        //   cellStyle: {
        //     'textAlign': 'left',
        //   }
        // },
        {
          field: 'code',
          headerName: t('lib_conditionnement_code'),
          headerTooltip: t('lib_conditionnement_code'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          editable: true,
          valueValidator: [notEmptyCellValidator],
        },
        {
          field: 'uniteConso',
          headerName: t('lib_conditionnement_unite_conso'),
          headerTooltip: t('lib_conditionnement_unite_conso'),
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
          }
        },
        {
          field: 'hauteur',
          headerName: t('lib_conditionnement_hauteur'),
          headerTooltip: t('lib_conditionnement_hauteur'),
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
          }
        },
        {
          field: 'largeur',
          headerName: t('lib_conditionnement_largeur'),
          headerTooltip: t('lib_conditionnement_largeur'),
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
          }
        },
        {
          field: 'longueur',
          headerName: t('lib_conditionnement_longueur'),
          headerTooltip: t('lib_conditionnement_longueur'),
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
          }
        },
        {
          field: 'poidsNet',
          headerName: t('lib_conditionnement_poids_net'),
          headerTooltip: t('lib_conditionnement_poids_net'),
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
          }
        },
        {
          field: 'poidsBrut',
          headerName: t('lib_conditionnement_poids_brut'),
          headerTooltip: t('lib_conditionnement_poids_brut'),
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
          }
        },
        {
          field: 'volume',
          headerName: t('lib_conditionnement_volume'),
          headerTooltip: t('lib_conditionnement_volume'),
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
          }
        },
        {
          field: 'profondeur',
          headerName: t('lib_conditionnement_profondeur'),
          headerTooltip: t('lib_conditionnement_profondeur'),
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
          }
        },
        {
          field: 'surface',
          headerName: t('lib_conditionnement_surface'),
          headerTooltip: t('lib_conditionnement_surface'),
          cellRenderer: NumberCellRenderer,
          editable: true,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
          }
        },
        // {
        //   field: 'code_tarif',
        //   headerName: t('lib_conditionnement_code_tarif'),
        //   headerTooltip: t('lib_conditionnement_code_tarif'),
        // },
        // {
        //   field: 'lieux_stockage',
        //   headerName: t('lib_conditionnement_lieux_stockage'),
        //   headerTooltip: t('lib_conditionnement_lieux_stockage'),
        //   minWidth: 250,
        //   cellRenderer: (props: ICellRendererParams) => {
        //     const tags: { name: string, color: string; }[] = props.value || [];
        //     const tagsJSX = tags.map(tag => (
        //       <span className='rounded-md p-1 mr-1 text-white-500' style={{ backgroundColor: tag.color }} key={tag.name}>
        //         {tag.name}
        //       </span>
        //     ));
        //     return (<>{tagsJSX}</>);
        //   }
        // }
      ],
    }), [activate, article, t])
  );

  return (
    <BlockGrid
      title={t('tab_decomposition_logistique')} sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
      handleClick={(res) => {
        setActivate(res);
        setTimeout(gridController.activateContextActionButtons, 50);
      }}
      toActivate={activate}
      loading={gridController.isLoading}
      disableCreate={gridController.hasEmptyLines}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          onGridReady={gridController.onGridReady}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
          treeData={true}
          groupDefaultExpanded={-1}
          groupDisplayType="custom"
          getDataPath={(rowData) => rowData.path}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default Logistique;