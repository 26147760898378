import { createContext, Dispatch, SetStateAction } from "react";

const MarquesContext = createContext<{
  refresh: boolean,
  setRefresh: Dispatch<SetStateAction<boolean>>,
  selection: {
    typologie: number | null,
    surMarque : number | null,
    marque: number | null,
    sousMarque: number | null
  },
  setSelection: Dispatch<SetStateAction<{
    typologie: number | null,
    surMarque : number | null,
    marque: number | null,
    sousMarque: number | null,
  }>>
}>({
  refresh: false,
  setRefresh: () => {},
  selection: {
    typologie: null,
    surMarque : null,
    marque: null,
    sousMarque: null
  },
  setSelection: () => {}
});

export default MarquesContext;