import { ReactNode, FC, Suspense } from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import Loader from 'context/loader';
import AuthProvider from 'context/auth';
import { SiteProvider } from 'context/site';
import { EnseigneProvider } from 'context/Referencement/enseigne';

const AppContextProvider: FC<{ children: ReactNode; }> = ({ children }) => {
  return (
    <Suspense fallback={<Loader />}>
      <AuthProvider>
        <Router>
          <SiteProvider>
            <EnseigneProvider>
              {children}
            </EnseigneProvider>
          </SiteProvider>
        </Router>
      </AuthProvider>
    </Suspense>
  );
};

export default AppContextProvider;