import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { DeviseViewModel, PrixAchatViewModel } from "openapi-typescript-codegen";
import { ColDef } from "ag-grid-community";
import { DateTime } from "luxon";

import BlockGrid, { CancelType, LayoutEnum } from 'components/BlockGrid';
import { AG_GRID_DEFAULT_COLUMN_NEW, AG_GRID_DEFAULT_COLUMN_HEADER_CLASS } from "app/ag-grid-options";
import { GridType as DataProps } from ".";
import { AgGridReact } from "ag-grid-react";
import { useGridController } from "hooks/";
import { DateCellRenderer, ModalCellRendererChildrenProps, NumberCellRenderer, NumberCellRendererProps, TextCellRenderer } from "components/AGGride/CellRender";
import { DatePickerCellEditor, NumberCellEditor, NumberCellEditorProps } from "components/AGGride/CellEditor";
import { dateCellValidator, notEmptyCellValidator, notNilCellValidator, numberCellValidator } from "components/AGGride/cellValidator";
import { editableIfNew, editableIfTodayOrFuture } from "components/AGGride/cellEditable";
import { toast, getISOStartDate, showPrettyError } from "utils/";
import { devisesApi, fournisseursApi, prixAchatsApi, relationFournisseurConditionnementApi, tiersApi } from "api/";
import { GridController } from "hooks/useGridController";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
};

type GridType = { deviseNomCourt?: string; } & PrixAchatViewModel;

const PrixAchatModal: FC<ModalCellRendererChildrenProps<DataProps>> = ({ setIsOpen, agGridRow }) => {
  const { t } = useTranslation();
  const [devises, setDevises] = useState<DeviseViewModel[]>([]);
  const [defaultDevise, setDefaultDevise] = useState<DeviseViewModel>();
  const [currentPrixAchat, setCurrentPrixAchat] = useState<PrixAchatViewModel>();
  const relationFour = agGridRow.data!.approTarifaire;
  const parentGridController: GridController | undefined = agGridRow.context.gridController;

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData,
      fetchData: async () => {
        try {
          const { data: currentPx } = await relationFournisseurConditionnementApi.v1RelationFournisseurConditionnementsIdPrixAchatGet(relationFour.id);
          setCurrentPrixAchat(currentPx);
        } catch (err) { }
        const { data: res } = await prixAchatsApi.v1PrixAchatsSearchPost(false, 1, 100, { fkRelationFournisseurConditionnement: relationFour.id });
        return (res.data || []).sort((px1, px2) => px2.datePxAchat!.localeCompare(px1.datePxAchat!));
      },
      postData: (cleanRow) => prixAchatsApi.v1PrixAchatsPost({ ...cleanRow, fkRelationFournisseurConditionnement: relationFour.id }),
      putData: (cleanRow) => prixAchatsApi.v1PrixAchatsPut(cleanRow),
      deleteData: (dataId) => prixAchatsApi.v1PrixAchatsIdDelete(dataId),
      getNewModel: () => ({
        pxAchat: 0,
        datePxAchat: getISOStartDate(),
        fkDevise: defaultDevise?.id,
        fkRelationFournisseurConditionnement: -1,
      }),
      rowClassRules: {
        'ag-row-success': (params) => !!params.data?.id && params.data?.id === currentPrixAchat?.id,
      },
      columnDefs: [
        {
          field: "datePxAchat",
          headerName: t('lib_prix_achat_date_px_achat'),
          headerTooltip: t('lib_prix_achat_date_px_achat'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          editable: (params) => editableIfNew(params) || editableIfTodayOrFuture(params),
          cellRenderer: DateCellRenderer,
          cellEditor: DatePickerCellEditor,
          cellEditorParams: {
            min: DateTime.now(),
          },
          valueValidator: [notEmptyCellValidator, dateCellValidator],
        },
        {
          field: "pxAchat",
          headerName: t('lib_prix_achat_px_achat'),
          headerTooltip: t('lib_prix_achat_px_achat'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          editable: (params) => editableIfNew(params) || editableIfTodayOrFuture(params, 'datePxAchat'),
          tooltipValueGetter: (params) => params.value + ' ' + devises.find((v) => v.id === params.data?.fkDevise)?.nomCourt,
          cellRenderer: NumberCellRenderer,
          cellRendererParams: {
            floating: (_, params) => devises.find((v) => v.id === params.data?.fkDevise)?.decPxAchat,
          } as NumberCellRendererProps,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            floating: (_, params) => devises.find((v) => v.id === params.data?.fkDevise)?.decPxAchat,
          } as NumberCellEditorProps,
          valueValidator: [notNilCellValidator, numberCellValidator],
        },
        {
          field: "fkDevise",
          headerName: t('lib_prix_achat_fk_devise'),
          headerTooltip: t('lib_prix_achat_fk_devise'),
          // editable: (params) => editableIfNew(params),
          cellRenderer: TextCellRenderer,
          valueFormatter: (params) => devises.find(
            (v) => v.id === params.data?.fkDevise
          )?.nomCourt || '',
          // cellEditor: SelectorCellEditor,
          // cellEditorParams: {
          //   values: devises.map((a) => ({ value: a.id, label: `${a.nomDevise} (${a.nomCourt})` })),
          // },
          // valueValidator: [notNilCellValidator],
        },
        {
          field: "__expand__",
          headerName: '',
          flex: 1,
        },
      ],
    }), [relationFour, devises, defaultDevise, currentPrixAchat, t])
  );

  useEffect(() => {
    Promise.all([
      fetchTier(),
      fetchDevises(),
    ])
      .catch((err) => showPrettyError(err));
  }, []);

  async function fetchTier() {
    const { data: four } = await fournisseursApi.v1FournisseursIdGet(relationFour.fournisseur.id);
    if (!four.fkTiers) return;
    const { data: tier } = await tiersApi.v1TiersIdGet(four.fkTiers);
    if (!tier.fkDevise) return;
    const { data: defaultVal } = await devisesApi.v1DevisesIdGet(tier.fkDevise);
    setDefaultDevise(defaultVal);
  }

  async function fetchDevises() {
    const { data: { data: devisesPag } } = await devisesApi.v1DevisesGet(1, 1000);
    const devis = devisesPag.sort((v1, v2) => v1.nomDevise.localeCompare(v2.nomDevise));
    setDevises(devis);
  }

  async function handleCreate() {
    if (!defaultDevise) {
      toast.error(t('err_prix_achat_devise_absente'));
      return;
    }
    gridController.handleCreate();
  }

  async function handleUpdate() {
    const success = await gridController.handleUpdate();
    if (success) {
      setTimeout(() => parentGridController?.fetchData?.(), 50);
    };
  }

  async function handleCancel(type: CancelType) {
    if (type === 'btn') {
      await gridController.handleCancel();
      setTimeout(gridController.activateContextActionButtons, 50);
    } else {
      setIsOpen(false);
    }
  }

  return (
    <BlockGrid
      title={t('tab_prix_achat')}
      layout={LayoutEnum.modal}
      toActivate={true}
      disableCreate={gridController.hasEmptyLines}
      handleCreate={handleCreate}
      handleUpdate={handleUpdate}
      handleCancel={handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-modal mt-3">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={(ev) => {
            gridController.onFirstDataRendered(ev);
            setTimeout(gridController.activateContextActionButtons, 50);
          }}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default PrixAchatModal;