import { FC, useState } from 'react';
import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import { Title } from "components/H2Title";
import { useTranslation } from "react-i18next";
import TableauxTva from "./TableauxTva";
import TableauxTauxTva from "./TableauxTauxTva";

const TvaGeneral: FC = () => {
  const [tvaId, setTvaId] = useState<number | null>(null);

  const { t } = useTranslation();

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: `${t('men_refe_referentiel')} - ${t('men_parametre')}`,
      link: `/app/referencement/referentiel/parametres`,
    },
    {
      name: `${t('tit_modification_tva')}`
    },
  ];

  const title: Title = {
    title: `${t('men_refe_referentiel')} - ${t('tab_tva')}`,
    icon: "referentiel",
  };

  return (
    <>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <div className="flex gap-2">
        <TableauxTva onTvaChange={(tva) => {
          setTvaId(tva);
        }} />
        <TableauxTauxTva tvaId={tvaId} />
      </div>
    </>
  );
};

export default TvaGeneral;