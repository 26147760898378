import { FC, useMemo, useState } from 'react';
import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import { Title } from "components/H2Title";
import { TaxeInsertionModel, TaxeViewModel } from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";

import { taxesApi } from 'api';
import BlockGrid, { PropsBlock, LayoutEnum } from 'components/BlockGrid';
import { SelectorCellEditor, TextCellEditor } from 'components/AGGride/CellEditor';
import { TextCellRenderer } from 'components/AGGride/CellRender';
import { notEmptyCellValidator } from "components/AGGride/cellValidator";
import { useGridController } from "hooks";
import { CheckBoxColumnDef } from "../../components/AGGride/columnDef";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

type GridType = Partial<TaxeViewModel>;

const TaxeGeneral: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);

  // Options for the selects:
  const options = {
    typesTaxes: [
      { value: null, label: "..." },
      { value: 'N', label: t('enu_taxe_type_taxe_normal') },
      { value: 'V', label: t('enu_taxe_type_taxe_vente') },
      { value: 'I', label: t('enu_taxe_type_taxe_import') },
      { value: 'T', label: t('enu_taxe_type_taxe_import_indirect') },
      { value: 'A', label: t('enu_taxe_type_taxe_alcool') }
    ],
    exploitations: [
      { value: null, label: "..." },
      { value: 'L', label: t('enu_taxe_exploitation_ligne_facture') },
      { value: 'B', label: t('enu_taxe_exploitation_lbase_facture') },
      { value: 'C', label: t('enu_taxe_exploitation_compris_ttc') }
    ]
  };

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      emptyRowCheck: (rowData) => !rowData.code,
      autoFetch: true,
      fetchData: async () => {
        const { data: { data } } = await taxesApi.v1TaxesGet(1, 1000);
        return data.sort((a, b) => a.code.localeCompare(b.code));
      },
      postData: (cleanRow) => taxesApi.v1TaxesPost(cleanRow as TaxeInsertionModel),
      putData: (cleanRow) => taxesApi.v1TaxesPut(cleanRow as TaxeViewModel),
      deleteData: (dataId) => taxesApi.v1TaxesIdDelete(dataId),
      getNewModel: () => {
        return {
          typeTaxe: 'N',
          exploitation: 'L'
        };
      },
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'code',
          headerName: t('lib_taxe_code'),
          headerTooltip: t('lib_taxe_code'),
          cellRenderer: TextCellRenderer,
          cellEditor: TextCellEditor,
          cellEditorParams: {
            maxLength: 4,
            toUpperCase: true
          },
          editable: true,
          filter: 'agTextColumnFilter',
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          flex: 1,
        },
        {
          field: 'nomTaxe',
          headerName: t('lib_taxe_nom_taxe'),
          headerTooltip: t('lib_taxe_nom_taxe'),
          cellRenderer: TextCellRenderer,
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          minWidth: 200,
          flex: 4,
        },
        {
          field: 'nomCourtTaxe',
          headerName: t('lib_taxe_nom_court_taxe'),
          headerTooltip: t('lib_taxe_nom_court_taxe'),
          cellRenderer: TextCellRenderer,
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 2,
        },
        {
          field: 'gtin',
          headerName: t('lib_taxe_gtin'),
          headerTooltip: t('lib_taxe_gtin'),
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          flex: 1
        },
        {
          field: 'typeTaxe',
          headerName: t('lib_taxe_type_taxe'),
          headerTooltip: t('lib_taxe_type_taxe'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          cellRenderer: TextCellRenderer,
          tooltipValueGetter: (params) => options.typesTaxes.find(option => option.value === params.value)?.value,
          valueFormatter: (params) => options.typesTaxes.find(option => option.value === params.value)?.label || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: { values: options.typesTaxes },
          valueValidator: [notEmptyCellValidator],
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          minWidth: 150,
          flex: 3,
        },
        CheckBoxColumnDef<GridType>("flPx1", "lib_taxe_fl_px1", { maxWidth: 50 }),
        CheckBoxColumnDef<GridType>("flPx2", "lib_taxe_fl_px2", { maxWidth: 50 }),
        CheckBoxColumnDef<GridType>("flPx3", "lib_taxe_fl_px3", { maxWidth: 50 }),
        CheckBoxColumnDef<GridType>("flPx4", "lib_taxe_fl_px4", { maxWidth: 50 }),
        CheckBoxColumnDef<GridType>("flPx5", "lib_taxe_fl_px5", { maxWidth: 50 }),
        CheckBoxColumnDef<GridType>("flPx6", "lib_taxe_fl_px6", { maxWidth: 50 }),
        CheckBoxColumnDef<GridType>("flPx7", "lib_taxe_fl_px7", { maxWidth: 50 }),
        CheckBoxColumnDef<GridType>("flPx8", "lib_taxe_fl_px8", { maxWidth: 50 }),
        CheckBoxColumnDef<GridType>("flPx9", "lib_taxe_fl_px9", { maxWidth: 50 }),
        CheckBoxColumnDef<GridType>("flPx10", "lib_taxe_fl_px10", { maxWidth: 50 }),
        CheckBoxColumnDef<GridType>("flPx11", "lib_taxe_fl_px11", { maxWidth: 50 }),
        {
          field: 'exploitation',
          headerName: t('lib_taxe_exploitation'),
          headerTooltip: t('lib_taxe_exploitation'),
          cellRenderer: TextCellRenderer,
          tooltipValueGetter: (params) => options.exploitations.find(option => option.value === params.value)?.value,
          valueFormatter: (params) => options.exploitations.find(option => option.value === params.value)?.label || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: { values: options.exploitations },
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
          minWidth: 150,
          flex: 3
        }
      ],
    }), [activate, t, options])
  );

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: `${t('men_refe_articles')} - ${t('men_parametre')}`,
      link: `/app/referencement/articles/parametres`,
    },
    {
      name: "tit_taxe_generale",
    },
  ];

  const title: Title = {
    title: `${t('men_refe_articles')} - ${t('tab_taxe_generale')}`,
    icon: "articles",
  };

  return (
    <>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <BlockGrid
        sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
        layout={LayoutEnum.settings}
        handleClick={(res: boolean) => {
          setActivate(res);
          setTimeout(gridController.activateContextActionButtons, 50);
        }}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-params">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType={"fullRow"}
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={false}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </>
  );
};

export default TaxeGeneral;