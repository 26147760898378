import { FunctionComponent} from 'react';

interface Props {
  
}

const TabNomemclature: FunctionComponent<Props> = () => {

  return (
    <div id="nomenclature" className="flex flex-wrap justify-between bg-grey-150 gap-px p-px">
      
      
      <div className="text-2xl font-bold">Nomemclature</div>


    </div>
  )
}

export default TabNomemclature