import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Url {
  name: string;
  link?: string;
  translate?: boolean;
}

interface Props {
  content: Url[];
}

const BreadCrumb: FunctionComponent<Props> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <ul
      aria-label="fil d'Ariane"
      className="col-span-full h-4 w-full items-end mb-2 flex gap-4 font-medium text-sm"
      style={{ marginTop: "1em" }}
    >
      {content.map((item, key, row) => {
        if (key + 1 !== row.length) {
          return (
            <li key={key}>
              <div className="gap-4 flex" key={key}>
                <Link to={`${item?.link}`} tabIndex={2}>
                  <div className="hover:text-store-primary focus:text-store-primary">
                    {item.translate === false ? `${item.name}` : t(`${item.name}`)}
                  </div>
                </Link>
                <div>/</div>
              </div>
            </li>
          );
        } else {
          return (
            <li key={key}>
              <div className="text-store-primary" key={key}>
                {item.translate === false ? `${item.name}` : t(`${item.name}`)}
              </div>
            </li>
          );
        }
      })}
    </ul>
  );
};

export default BreadCrumb;
