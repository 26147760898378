import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  ArticleCertificatInsertionModel,
  ArticleCertificatViewModel,
  CertificatViewModel,
  SectionViewModel
} from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { useTranslation } from "react-i18next";

import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { articleCertificatsApi, articlesApi, certificatsApi, sectionApi } from 'api';
import BlockGrid, { PropsBlock } from 'components/BlockGrid';
import { SelectorCellEditor } from 'components/AGGride/CellEditor';
import { LinkCellRenderer, LinkCellRendererProps } from 'components/AGGride/CellRender';
import { editableIfNew } from "components/AGGride/cellEditable";
import { notEmptyCellValidator } from "components/AGGride/cellValidator";
import { useArticle } from "context/Referencement";
import { useGridController } from "hooks";

import 'app/ag-grid-style.css';
import { getISOEndDate, getISOStartDate } from "utils";

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  flex: 1,
  minWidth: 90,
};

type GridType = Partial<ArticleCertificatViewModel>;

const Certificats: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const { state: article } = useArticle();
  const [activate, setActivate] = useState(false);
  const [articleCertificatsOptions, setArticleCertificatsOptions] = useState<CertificatViewModel[]>([]);
  const [sections, setSections] = useState<Partial<SectionViewModel>[]>([]);

  const certificatsOptions = useRef<CertificatViewModel[]>([]);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {
        sortColId: "fkCertificat",
        startEditingColId: "fkCertificat",
      },
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.fkCertificat,
      fetchData: async () => {
        if (!article?.id) return [];
        const data = await articlesApi.v1ArticlesIdCertificatsGet(article?.id).then(response => response.data);
        const options = certificatsOptions.current
          .filter(option => !data.some(certificat => certificat.fkCertificat === option.id))
          .map(option => ({ ...option, label: `${option.nomCertificat} (${option.code})`.toUpperCase() })) || [];
        setArticleCertificatsOptions(options);
        return data;
      },
      postData: (cleanRow) => articleCertificatsApi.v1ArticleCertificatsPost({ ...cleanRow, fkArticle: article?.id as number } as ArticleCertificatInsertionModel),
      putData: (cleanRow) => articleCertificatsApi.v1ArticleCertificatsPut(cleanRow as ArticleCertificatViewModel),
      deleteData: (dataId) => articleCertificatsApi.v1ArticleCertificatsIdDelete(dataId),
      getNewModel: () => ({
        fkCertificat: articleCertificatsOptions.find(v => v.code === 'LOT')?.id || articleCertificatsOptions[0].id,
        fkSection: sections.find(v => v.code === 'LOT')?.id || sections[0].id,
        dateDebut: getISOStartDate(),
        dateFin: getISOEndDate(),
      }),
      onFocusChange: (isFocus) => setActivate(isFocus),
      columnDefs: [
        {
          field: 'fkCertificat',
          headerName: t('lib_article_certificat_fk_certificat'),
          headerTooltip: t('lib_article_certificat_fk_certificat'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          editable: editableIfNew,
          tooltipValueGetter: (params) => certificatsOptions.current.find(
            option => typeof option.id === 'number' && option.id === params.data?.fkCertificat)?.code,
          valueFormatter: (params) => certificatsOptions.current.find(
            option => typeof option.id === 'number' && option.id === params.data?.fkCertificat)?.code || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: articleCertificatsOptions.map(option => ({
              value: option.id,
              label: option.nomCertificat ? option.code + ' - ' + option.nomCertificat : option.code
            })),
          },
          valueValidator: [notEmptyCellValidator],
        },
        {
          field: 'CertificatName',
          headerName: t('lib_article_certificat_nom_certificat'),
          headerTooltip: t('lib_article_certificat_nom_certificat'),
          cellRenderer: LinkCellRenderer,
          cellRendererParams: {
            href: (cellValue: string) => /^\d+/gmi.test(cellValue) ? `/app/referencement/articles/${cellValue}` : null,
            target: '_blank'
          } as LinkCellRendererProps,
          valueGetter: (param: ValueGetterParams) => param.data.fkCertificat,
          valueFormatter: (params) => certificatsOptions.current.find(
            option => typeof option.id === 'number' && option.id === params.value)?.nomCertificat || "",
        },
        {
          field: 'fkSection',
          headerName: t('lib_article_certificat_fk_section'),
          headerTooltip: t('lib_article_certificat_fk_section'),
          editable: true,
          tooltipValueGetter: (params) => {
            return sections.find(
              (v) => typeof v.id === 'number' && v.id === params.data?.fkSection
            )?.code;
          },
          valueFormatter: (params) => sections.find(
            (v) => typeof v.id === 'number' && v.id === params.data?.fkSection
          )?.code || "",
          cellEditor: SelectorCellEditor,
          cellEditorParams: {
            values: sections.map((a) => ({ value: a.id, label: a.nomSection ? a.code + ' - ' + a.nomSection : a.code })),
          },
        },
      ],
    }), [activate, articleCertificatsOptions, article, sections, t])
  );

  useEffect(() => {
    loadOptions();
    fetchSections();
  }, []);

  async function loadOptions() {
    const { data: response } = await certificatsApi.v1CertificatsGet(1, 100);
    const { data } = response;
    certificatsOptions.current = data || [];
  }

  async function fetchSections() {
    const { data: res } = await sectionApi.v1SectionsGet(1, 100);
    const newData = res.data?.map(v => ({ ...v, label: `${v.nomSection} (${v.code})`.toUpperCase() }));
    setSections([{ code: '...' }, ...(newData || [])]);
  }

  return (
    <BlockGrid
      title={t('tab_article_certificat')} sm={sm} md={md} lg={lg} xl={xl}
      doubleXl={doubleXl}
      loading={gridController.isLoading}
      handleClick={(res: boolean) => {
        setActivate(res);
        setTimeout(gridController.activateContextActionButtons, 50);
      }}
      toActivate={activate}
      disableCreate={gridController.hasEmptyLines || !articleCertificatsOptions.length}
      handleCreate={gridController.handleCreate}
      handleUpdate={gridController.handleUpdate}
      handleCancel={gridController.handleCancel}
    >
      <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-md">
        <AgGridReact
          ref={gridController.gridRef}
          rowData={gridController.defaultData}
          columnDefs={gridController.columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          animateRows={true}
          editType={"fullRow"}
          getRowId={(params) => `${params.data.id}`}
          suppressHorizontalScroll={true}
          onGridReady={gridController.onGridReady}
          onFirstDataRendered={gridController.onFirstDataRendered}
          onCellValueChanged={gridController.onCellValueChanged}
          onRowValueChanged={gridController.onRowValueChanged}
          onRowEditingStopped={gridController.onRowEditingStopped}
          rowClassRules={gridController.rowClassRules}
          enableBrowserTooltips={true}
          tooltipShowDelay={0}
        ></AgGridReact>
      </div>
    </BlockGrid>
  );
};

export default Certificats;