import { useEffect, useState, Dispatch, SetStateAction } from "react";

export function useLocalStorage<T>(storageKey: string, fallbackState?: T, isSession = false): [NonNullable<T> | null, Dispatch<SetStateAction<NonNullable<T> | null>>] {
  const storage = isSession ? globalThis.sessionStorage : globalThis.localStorage

  const rawData = storage.getItem(storageKey)
  let data = null
  try {
    data = JSON.parse(rawData as any);
  } catch (e) {
    // NOTE: This is fine, we return the raw value if we cannot deserialize it
  }

  const [value, setValue] = useState((data as T) ?? fallbackState ?? null);

  useEffect(() => {
    storage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};
