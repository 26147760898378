import { FC, useMemo, useState } from 'react';
import BreadCrumb from "components/Breadcrumb";
import H1Title from "components/H1Title";
import { Title } from "components/H2Title";
import { DeviseInsertionModel, DeviseViewModel } from "openapi-typescript-codegen";
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from "react-i18next";
import { AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, AG_GRID_DEFAULT_COLUMN_NEW } from "app/ag-grid-options";
import { notEmptyCellValidator } from "components/AGGride/cellValidator";
import { NumberCellEditor, TextCellEditor } from 'components/AGGride/CellEditor';

import { devisesApi } from 'api';
import BlockGrid, { PropsBlock, LayoutEnum } from 'components/BlockGrid';
import { CheckedCellRenderer, TextCellRenderer, ModalCellRenderer, ModalCellRendererProps, ImageCellRenderer, ImageCellRendererProps } from 'components/AGGride/CellRender';
import { useGridController } from "hooks";
import ModalDevise from "./ModalDevise";
import EditIcon from '@mui/icons-material/Edit';

export type GridType = Partial<DeviseViewModel | DeviseInsertionModel>;

const defaultColDef: ColDef = {
  ...AG_GRID_DEFAULT_COLUMN_NEW,
  sortable: true,
  floatingFilter: true,
  flex: 1,
  minWidth: 90,
};

const Devise: FC<PropsBlock> = ({ sm, md, lg, xl, doubleXl }) => {
  const { t } = useTranslation();
  const [activate, setActivate] = useState(false);

  const gridController = useGridController<GridType>(
    useMemo(() => ({
      colConfig: {},
      autoFetch: true,
      emptyRowCheck: (rowData) => !rowData.nomDevise,
      fetchData: async () => {
        const { data: { data } } = await devisesApi.v1DevisesGet(1, 1000);
        return data.sort((a, b) => a.code.localeCompare(b.code));
      },
      postData: (cleanRow) => devisesApi.v1DevisesPost(cleanRow as DeviseInsertionModel),
      putData: (cleanRow) => devisesApi.v1DevisesPut(cleanRow as DeviseViewModel),
      deleteData: (dataId) => devisesApi.v1DevisesIdDelete(dataId),
      onFocusChange: (isFocus) => setActivate(isFocus),
      getNewModel: () => ({
        decPxAchat: 2,
        decPxVente: 2,
        decMontant: 2,
        decMarge: 2,
        decMtAchat: 2,
        decMtVente: 2,
      }),
      columnDefs: [
        {
          floatingFilter: false,
          sortable: false,
          filter: false,
          field: 'contactTels',
          headerName: t('tx_change'),
          headerTooltip: t('tx_change'),
          valueFormatter: (params) => {
            const devise = params.value || [];
            return `${devise}`;
          },
          cellRenderer: ModalCellRenderer,
          cellRendererParams: {
            children: ModalDevise,
            innerRenderer: ImageCellRenderer,
            innerRendererParams: {
              icon: EditIcon,
            } as ImageCellRendererProps,
            cellStyle: {
              cursor: "pointer"
            }
          } as ModalCellRendererProps,
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'code',
          headerName: t('lib_devise_code'),
          headerTooltip: t('lib_devise_code'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          cellEditor: TextCellEditor,
          cellEditorParams: {
            maxLength: 3,
            toUpperCase: true,
          },
          editable: true,
          filter: 'agTextColumnFilter',
        },
        {
          field: 'nomDevise',
          headerName: t('lib_devise_nom_devise'),
          headerTooltip: t('lib_devise_nom_devise'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'nomCourt',
          headerName: t('lib_devise_nom_court'),
          headerTooltip: t('lib_devise_nom_court'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS, 'required'],
          valueValidator: [notEmptyCellValidator],
          cellRenderer: TextCellRenderer,
          cellEditor: TextCellEditor,
          cellEditorParams: {
            maxLength: 3,
            toUpperCase: true,
          },
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'codeNumerique',
          headerName: t('lib_devise_code_numerique'),
          headerTooltip: t('lib_devise_code_numerique'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS],
          valueValidator: [],
          cellRenderer: TextCellRenderer,
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 9999,
          },
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'flDecimale',
          headerName: t('lib_devise_fl_decimale'),
          headerTooltip: t('lib_devise_fl_decimale'),
          cellRenderer: CheckedCellRenderer,
          editable: false,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'flDeviseUnique',
          headerName: t('lib_devise_fl_devise_unique'),
          headerTooltip: t('lib_devise_fl_devise_unique'),
          cellRenderer: CheckedCellRenderer,
          editable: false,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'decPxAchat',
          headerName: t('lib_devise_dec_px_achat'),
          headerTooltip: t('lib_devise_dec_px_achat'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS],
          valueValidator: [],
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 4,
          },
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'decPxVente',
          headerName: t('lib_devise_dec_px_vente'),
          headerTooltip: t('lib_devise_dec_px_vente'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS],
          valueValidator: [],
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 3,
          },
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'decMontant',
          headerName: t('lib_devise_dec_montant'),
          headerTooltip: t('lib_devise_dec_montant'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS],
          valueValidator: [],
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 3,
          },
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'decMarge',
          headerName: t('lib_devise_dec_marge'),
          headerTooltip: t('lib_devise_dec_marge'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS],
          valueValidator: [],
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 9,
          },
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'decMtAchat',
          headerName: t('lib_devise_dec_mt_achat'),
          headerTooltip: t('lib_devise_dec_mt_achat'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS],
          valueValidator: [],
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 3,
          },
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
        {
          field: 'decMtVente',
          headerName: t('lib_devise_dec_mt_vente'),
          headerTooltip: t('lib_devise_dec_mt_vente'),
          headerClass: [...AG_GRID_DEFAULT_COLUMN_HEADER_CLASS],
          valueValidator: [],
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            min: 0,
            max: 3,
          },
          cellRenderer: TextCellRenderer,
          editable: true,
          filter: 'agTextColumnFilter',
          cellStyle: {
            textAlign: "left",
          },
        },
      ],
    }), [activate, t])
  );

  const breadcrumb = [
    {
      name: "tit_applications",
      link: `/app`,
    },
    {
      name: "tab_referencement",
      link: `/app/referencement/`,
    },
    {
      name: `${t('men_refe_referentiel')} - ${t('men_parametre')}`,
      link: `/app/referencement/referentiel/parametres`,
    },
    {
      name: "tit_devise",
    },
  ];

  const title: Title = {
    title: `${t('men_refe_referentiel')} - ${t('tab_devise')}`,
    icon: "referentiel",
  };

  return (
    <>
      <BreadCrumb content={breadcrumb} />
      <div className="my-5">{<H1Title content={title} />}</div>
      <BlockGrid
        sm={sm} md={md} lg={lg} xl={xl} doubleXl={doubleXl}
        layout={LayoutEnum.settings}
        handleClick={(res: boolean) => {
          setActivate(res);
          setTimeout(gridController.activateContextActionButtons, 50);
        }}
        toActivate={activate}
        disableCreate={gridController.hasEmptyLines}
        handleCreate={gridController.handleCreate}
        handleUpdate={gridController.handleUpdate}
        handleCancel={gridController.handleCancel}
      >
        <div className="ag-theme-alpine ag-theme-custom pinned-right-actions block-grid-params">
          <AgGridReact
            ref={gridController.gridRef}
            rowData={gridController.defaultData}
            columnDefs={gridController.columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={40}
            animateRows={true}
            editType={"fullRow"}
            getRowId={(params) => `${params.data.id}`}
            suppressHorizontalScroll={true}
            onGridReady={gridController.onGridReady}
            onFirstDataRendered={gridController.onFirstDataRendered}
            onCellValueChanged={gridController.onCellValueChanged}
            onRowValueChanged={gridController.onRowValueChanged}
            onRowEditingStopped={gridController.onRowEditingStopped}
            rowClassRules={gridController.rowClassRules}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
            context={{ gridController }}
          ></AgGridReact>
        </div>
      </BlockGrid>
    </>
  );
};

export default Devise;